import { gql } from "@outschool/ui-apollo";

import { SaveButtonActivityFragment } from "../../Saving/graphql/fragments";

export const LightActivityFragment = gql`
  fragment LightActivityFragment on Activity {
    uid
    user_uid
    slug_id
    title
    titleTranslated
    url
    path
    summary
    summaryTranslated
    is_ongoing_weekly
    details {
      photo
    }
    isClub
  }
`;

export const LightListingCardFragment = gql`
  fragment LightListingCard_listing on Listing {
    leader {
      uid
      name
      photo
      averageActivityStarRating
      reviewCount
      leader_link
      video {
        uid
        file {
          filename
          mimetype
          size
          url
          thumbUrl
          fileSize
        }
        conversionStatus
        created_at
      }
      credentials {
        uid
        degreeType
        degreeSubject
      }
    }
    activity {
      ...LightActivityFragment
      ...SaveButton_activity
      reviewCount
      clubSection {
        filledSpaceCount
        clubPostCount
      }
      isClub
      details {
        photo
      }
      uid
      averageStarRating @skip(if: $skipRating)
      age_min
      age_max
      duration_minutes
      duration_weeks
      weekly_meetings
      price_cents
      isFlexSchedule
      isSelfPaced
      isOneTime
      hasTeacherSchedule
      size_max
      recentReviews(offset: 0, limit: 4) @include(if: $withRecentReviews) {
        uid
        activity_star_rating
        details {
          publicReview
        }
        parent {
          uid
          publicName
        }
      }
    }
    insertionId
  }
  ${LightActivityFragment}
  ${SaveButtonActivityFragment}
`;

export const PitchListingFragment = gql`
  fragment PitchListingCard_listing on Listing {
    leader {
      uid
      name
      photo
      leader_link
      averageActivityStarRating
      reviewCount
      video {
        uid
        file {
          url
          thumbUrl
        }
      }
    }
    activity {
      uid
      slug_id
      title
      titleTranslated
      url
      path
      details {
        photo
      }
      video {
        uid
        file {
          url
          thumbUrl
        }
      }
      videoForLearners {
        uid
        file {
          url
          thumbUrl
        }
      }
      averageStarRating
      reviewCount
      recentReviews(offset: $reviewsOffset, limit: $reviewsLimit) {
        uid
        parent {
          uid
          publicName
        }
        details {
          publicReview
        }
        activity_star_rating
      }
      isSelfPaced
      isFlexSchedule
      size_max
      isClub
      weekly_meetings
      duration_weeks
      duration_minutes
      price_cents
      is_ongoing_weekly
      hasTeacherSchedule
      completedEnrollmentCount
    }
  }
`;
export const ListingCardFragment = gql`
  fragment ListingCard_listing on Listing {
    leader {
      uid
      name
      photo
      leader_link
      averageActivityStarRating
      reviewCount
      video {
        uid
        file {
          filename
          mimetype
          size
          url
          thumbUrl
          fileSize
        }
        conversionStatus
        created_at
      }
      credentials {
        uid
        degreeType
        degreeSubject
      }
    }
    activity {
      ...LightActivityFragment
      ...SaveButton_activity
      uid
      averageStarRating @skip(if: $skipRating)
      reviewCount
      recentReviews(offset: 0, limit: 4) @include(if: $withRecentReviews) {
        uid
        activity_star_rating
        details {
          publicReview
        }
        parent {
          uid
          publicName
        }
      }
      age_min
      age_max
      duration_minutes
      duration_weeks
      weekly_meetings
      price_cents
      isClub
      isFlexSchedule
      isSelfPaced
      isOneTime
      clubSection {
        filledSpaceCount
        clubPostCount
      }
      hasTeacherSchedule
      size_max
    }
    insertionId
    sectionCount
    openSectionCount
    upcomingSectionCount
    nextSection {
      uid
      size_max
      filledSpaceCount(exact: false)
      uid
      start_time
      end_time
    }
  }
  ${LightActivityFragment}
  ${SaveButtonActivityFragment}
`;

export const ActivityListingFragment = gql`
  fragment ActivityListing_listing on Listing {
    activity {
      uid
      user_uid
      title
      url
      path
      summary
      summaryTranslated
      weekly_meetings
      duration_weeks
      is_ongoing_weekly
      isClub
      published_at
      size_max
      size_min
      hasTeacherSchedule
      allows_recurring_payment
      averageStarRating @skip(if: $skipRating)
      reviewCount
      age_min
      age_max
      duration_minutes
      duration_weeks
      price_cents
      priceCreditsPerMeeting
      isFlexSchedule
      isSelfPaced
      canEnroll
      syllabus {
        uid
        lessons {
          uid
        }
      }
      isOneTime
      clubSection {
        filledSpaceCount
        clubPostCount
      }
      details {
        photo
      }
      ...SaveButton_activity
    }
    nextSection {
      uid
      size_max
      filledSpaceCount(exact: false)
      start_time
      end_time
      nextOngoingMeeting {
        uid
        start_time
        end_time
      }
    }
    leader {
      uid
      name
      photo
      leader_link
      averageActivityStarRating
      reviewCount
      ownerOfSellerOrg {
        uid
      }
    }
    insertionId
  }
  ${SaveButtonActivityFragment}
`;
