/*
 * Contains routes for the support site
 */

import { addParamsToUrl } from "./utils";

export function supportUrl(locale?: string | null) {
  return locale
    ? "https://support.outschool.com/" + locale
    : "https://support.outschool.com/";
}

export function supportOngoingClassesForLearnersUrl(locale?: string | null) {
  return locale
    ? "https://support.outschool.com/" + locale + "/articles/2272596"
    : "https://support.outschool.com/learning-with-outschool/choosing-classes/ongoing-classes-for-learners";
}

export function groupsSupportUrl(locale?: string | null) {
  return locale
    ? "https://support.outschool.com/" + locale + "/articles/5680775"
    : "https://support.outschool.com/en/articles/5680775-outschool-groups-for-learners";
}

export function createYourProfileVideoSupportUrl() {
  return "https://support.outschool.com/en/articles/8365607-outschool-educator-profile-picture-and-video-guidelines#:~:text=Creating%20Your%20Profile%20Video";
}

export function sampleTeacherApplicationsSupportUrl() {
  return "https://support.outschool.com/en/articles/6968614";
}

export function howToPrepareForYourFirstClassUrl(locale?: string | null) {
  return locale
    ? "https://support.outschool.com/" + locale + "/articles/802757"
    : "https://support.outschool.com/en/articles/802757-how-to-prepare-for-your-first-class";
}

export function selfPacedClassesForFamiliesUrl(locale?: string | null) {
  return locale
    ? `https://support.outschool.com/${locale}/articles/3196046`
    : "https://support.outschool.com/en/articles/3196046-self-paced-classes-for-families";
}

export function contentModerationGuidelinesForLearnersUrl(
  locale?: string | null
) {
  return locale
    ? "https://support.outschool.com/" + locale + "/articles/5536590"
    : "https://support.outschool.com/en/articles/5536590-content-moderation-guidelines-for-learners";
}

export function learnerModeFAQUrl(locale?: string | null) {
  return locale
    ? "https://support.outschool.com/" + locale + "/articles/4715673"
    : "https://support.outschool.com/en/articles/4715673-learner-spaces";
}

export function learnerModeMultiplicationProblemUrl(locale?: string | null) {
  return learnerModeFAQUrl(locale) + "#h_e9d59b5aa2";
}

export function learnerModePasswordProtectionUrl(locale?: string | null) {
  return learnerModeFAQUrl(locale) + "#optional-password-protection";
}

export function teacherRestrictionsUrl() {
  return "https://support.outschool.com/en/articles/2057395-teacher-restriction-and-removal";
}

export function keepCommunicationOnOutschoolUrl() {
  return "https://support.outschool.com/en/articles/2934935-keep-communication-on-outschool";
}

export function extenuatingCircumstancesUrl() {
  return "https://support.outschool.com/en/articles/2417882-extenuating-circumstances-for-teachers";
}

export function classesWithFewerThanMinimumEnrollmentsArticleUrl() {
  return "https://support.outschool.com/en/articles/1259629-classes-with-fewer-than-minimum-enrollments";
}

export function schedulingUrl() {
  return "https://support.outschool.com/en/articles/3003281-auto-scheduled-sections";
}

export function giftCardTermsAndConditionsUrl(locale = "en") {
  return "https://support.outschool.com/" + locale + "/articles/4659390";
}
export function clubsForLearnersUrl() {
  return "https://support.outschool.com/en/articles/5680775-outschool-groups-for-learners";
}

export function helpGetEnrollmentsUrl() {
  return "https://support.outschool.com/en/articles/398250-tips-for-getting-enrollments";
}

export function giftEnrollmentsSupportUrl(locale?: string | null) {
  return locale
    ? "https://support.outschool.com/" + locale + "/articles/4488267"
    : "https://support.outschool.com/en/articles/4488267-gift-enrollments-for-parents";
}

export function recordingClassesUrl() {
  return "https://support.outschool.com/en/articles/452568-class-recordings";
}

export function metricsHelpUrl() {
  return "https://support.outschool.com/en/articles/3538973-stats-on-the-teacher-dashboard";
}

export function supportTeacherReferralProgramUrl() {
  return "https://support.outschool.com/en/articles/3088367-teacher-referral-program";
}

export function supportParentReferralProgramUrl() {
  return "https://support.outschool.com/en/articles/2087332-parent-referral-program";
}

export function privacyForParentsUrl() {
  return "https://support.outschool.com/en/articles/579976-learner-safety-and-privacy-for-parents";
}

export function learnerSafetyAndPrivacyForTeachersUrl() {
  return "https://support.outschool.com/en/articles/2612466-learner-safety-and-privacy-for-teachers";
}

export function teachingLiveClassesWithZoomUrl() {
  return "https://support.outschool.com/en/articles/16400-teaching-live-classes-with-zoom";
}

export function communityStandardsUrl() {
  return "https://support.outschool.com/en/articles/16039-community-standards";
}

export function sampleClassesUrl() {
  return "https://support.outschool.com/en/articles/2420442-sample-class-listings-for-new-teachers";
}

export function creatingApplicationUrl() {
  return "https://support.outschool.com/en/articles/4216449-creating-a-great-teacher-application";
}

export function contentPolicyUrl() {
  return "https://support.outschool.com/teacher-guide/class-formats-and-standards/class-content-policy";
}

export function becomingTeacherUrl() {
  return "https://support.outschool.com/teacher-guide/getting-started/becoming-a-teacher-on-outschool";
}

export function safetyAndPrivacyUrl() {
  return "https://support.outschool.com/en/articles/2612466-learner-safety-and-privacy-for-teachers";
}

export function refundPolicyUrl(locale?: string | null) {
  return locale
    ? "https://support.outschool.com/" + locale + "/articles/4626920"
    : "https://support.outschool.com/en/articles/4626920-standard-refund-policy-and-automatic-refunds";
}

export function refundPolicyCreditsUrl(locale?: string | null) {
  return locale
    ? "https://support.outschool.com/" + locale + "/articles/10421711"
    : "https://support.outschool.com/en/articles/10421711-membership-cancellation-policies";
}

export function managingMembershipUrl(locale?: string | null) {
  return locale
    ? "https://support.outschool.com/" + locale + "/articles/10421801"
    : "https://support.outschool.com/en/articles/10421801-purchasing-and-managing-membership-plans";
}

export function learnerVerificationUrl() {
  return "https://support.outschool.com/en/articles/4063624-learner-verification";
}

export function zoomFeaturesForTeachersUrl() {
  return "https://support.outschool.com/en/articles/2334575-zoom-features-for-teachers";
}

export function teacherBackgroundChecksUrl() {
  return "https://support.outschool.com/en/articles/2701506-teacher-background-checks";
}

export function teacherPaymentTermsSupportUrl() {
  return "https://support.outschool.com/en/articles/2382129-educator-earnings-and-payments";
}

export function teacherThirdPartyToolsUrl() {
  return "https://support.outschool.com/en/articles/5729439-third-party-tools-available-for-outschool-educators-to-use-with-learners";
}

export function strictAgeRangeHelpUrl() {
  return "https://support.outschool.com/en/articles/5232633-preventing-enrollments-from-learners-outside-your-class-s-age-range";
}

export function teacherCouponSupportUrl() {
  return "http://support.outschool.com/en/articles/5578246-educator-coupons";
}

export function outschoolOrganizationGuide() {
  return "https://support.outschool.com/en/articles/4451081-organizations-on-outschool";
}

export function privateClassesParentHelpUrl() {
  return "https://support.outschool.com/en/articles/4530445";
}

export function privateClassesTeacherHelpUrl() {
  return "https://support.outschool.com/en/articles/4157437";
}

export function enrollmentManagementPoliciesForEducatorsUrl() {
  return "https://support.outschool.com/en/articles/579553-enrollment-management-policies-for-outschool-educators";
}

export function learningExperiencePledgeUrl() {
  return "https://support.outschool.com/en/articles/16379-learning-experience-pledge";
}

export function howToCancelSectionUrl(locale?: string | null) {
  return `https://support.outschool.com/${
    locale || `en`
  }/articles/2332042-how-to-cancel-a-section`;
}

export function managingSectionsAndEnrollmentsUrl() {
  return "https://support.outschool.com/en/articles/579553-managing-sections-and-enrollments";
}

export function cookiesAndSimilarForAnalyticsAndAdvertisingUrl() {
  return "https://support.outschool.com/en/articles/2663595-cookies-and-similar-technologies-for-analytics-and-advertising-on-outschool";
}

export function educatorApplicationRequirementsUrl() {
  return "https://support.outschool.com/en/articles/1638033-becoming-an-educator-on-outschool";
}

export function privateClassesForEducatorsUrl(locale?: string | null) {
  return `https://support.outschool.com/${locale || "en"}/articles/4157437`;
}

export function classSpecificRefundPolicies(locale?: string | null) {
  return `https://support.outschool.com/${locale || "en"}/articles/4626920`;
}

export function migratingClassesToOneOnOneUrl(locale?: string | null) {
  return locale
    ? "https://support.outschool.com/" + locale + "/articles/8121254"
    : "https://support.outschool.com/en/articles/8121254-migrating-your-classes-to-1-on-1";
}

export function teacherDrilldownUrl(userUid: string) {
  return addParamsToUrl("https://metabase.outschool.dev/dashboard/22", {
    user_uid: userUid,
  });
}

export function parentDrilldownUrl(userUid: string) {
  return addParamsToUrl("https://metabase.outschool.dev/dashboard/24", {
    uid: userUid,
  });
}

export function membershipCancellationPoliciesUrl(locale?: string | null) {
  return locale
    ? "https://support.outschool.com/" + locale + "/articles/10421711"
    : "https://support.outschool.com/en/articles/10421711-membership-cancellation-policies";
}
