import { Box, Icon } from "@outschool/backpack";
import { faEnvelope } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { TrackedButton } from "@outschool/ui-components-shared";
import React from "react";

import JoinParentAccountForm from "./JoinParentAccountForm";

interface EmailSignupWithinModalFormProps {
  showEmailSignupForm: boolean;
  setShowEmailSignupForm: (value: boolean) => void;
  setDisableSignupButtons: (loading: boolean) => void;
  searchParams: { [key: string]: any };
  trackingUniqueId?: string;
  onUserLoggedIn: (sessionToken: string, refreshToken: string) => void;
  onUserRefresh: () => void;
  isTeacherFlow?: boolean;
  setIsRedirecting: (isRedirecting: boolean) => void;
}

/**
 * This const is used to conditionally show the
 * "Sign Up with Email" button or the email signup form.
 */
const EmailSignupWithinModalForm = ({
  showEmailSignupForm,
  setShowEmailSignupForm,
  setDisableSignupButtons,
  searchParams,
  trackingUniqueId,
  onUserLoggedIn,
  onUserRefresh,
  isTeacherFlow = false,
  setIsRedirecting,
}: EmailSignupWithinModalFormProps) => {
  const { t } = useTranslation("ui-auth\\src\\EmailSignupWithinModalForm");

  return (
    <>
      {!showEmailSignupForm ? (
        <TrackedButton
          onClick={() => {
            setShowEmailSignupForm(true);
          }}
          data-test-id="signup-flow-link"
          trackingName="signup-with-email"
          trackingUniqueId={trackingUniqueId}
          sx={{
            padding: "0 !important",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              fontSize: "1.5em",
              padding: "10px",
              width: "44px",
            }}
          >
            <Icon
              icon={faEnvelope}
              sx={{
                display: "block",
              }}
            />
          </Box>
          <Box
            flex
            sx={{
              flex: 1,
              padding: "12.5px",
              minWidth: "215px",
              justifyContent: "center",
            }}
          >
            {t("Sign Up with Email")}
          </Box>
        </TrackedButton>
      ) : (
        <JoinParentAccountForm
          searchParams={searchParams}
          setDisableSignupButtons={setDisableSignupButtons}
          onUserLoggedIn={onUserLoggedIn}
          onUserRefresh={onUserRefresh}
          isTeacherFlow={isTeacherFlow}
          setIsRedirecting={setIsRedirecting}
        />
      )}
    </>
  );
};

export default EmailSignupWithinModalForm;
