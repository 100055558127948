import {
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryOwnProps as AccordionSummaryProps,
  accordionClasses,
  accordionDetailsClasses,
  accordionSummaryClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import { faCaretDown } from "@outschool/icons";
import React from "react";

import Icon from "../Icon/Icon";

export {
  Accordion,
  AccordionActions,
  accordionActionsClasses,
  AccordionActionsProps,
  accordionClasses,
  AccordionDetails,
  accordionDetailsClasses,
  AccordionDetailsProps,
  AccordionProps,
  accordionSummaryClasses,
} from "@mui/material";

const ExpandIcon = () => <Icon icon={faCaretDown} />;

export const AccordionSummary = React.forwardRef(
  (
    {
      // Provide default expand icon
      expandIcon = <ExpandIcon />,
      ...props
    }: AccordionSummaryProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return <MuiAccordionSummary ref={ref} {...props} expandIcon={expandIcon} />;
  }
);

export const AccordionUnstyled = styled(Accordion)({
  // Accordion root
  padding: 0,
  margin: 0,
  backgroundColor: "unset",

  [`&.${accordionClasses.disabled}`]: {
    backgroundColor: "unset",
  },
  "::before": {
    // hide top border
    opacity: 0,
  },
  [`&.${accordionClasses.expanded}`]: {
    margin: 0,
    "::before": {
      opacity: 0,
    },
    "& + &": {
      "&::before": {
        display: "unset",
        opacity: 0,
      },
    },
  },
  // Summary
  [`& .${accordionSummaryClasses.root}`]: {
    padding: 0,
    margin: 0,
    minHeight: "unset",
    backgroundColor: "unset",
    [`.${accordionSummaryClasses.content}`]: {
      padding: 0,
      margin: 0,
    },
    [`.${accordionSummaryClasses.expanded}`]: {
      padding: 0,
      margin: 0,
    },
  },
  // Details
  [`& .${accordionDetailsClasses.root}`]: {
    padding: 0,
    margin: 0,
  },
}) as typeof Accordion;
