import { useSession } from "@outschool/ui-auth";
import Cookies from "js-cookie";
import React from "react";

import { COOKIE_SEEN_JOIN_SUBSCRIPTION } from "../Modals/JoinSubscriptionModal";

type UseSubscriptionCredit = {
  hasSubscription: boolean | undefined;
  subscriptionEnabled: boolean;
  joinSubscriptionEnabled: boolean;
  joinSubscriptionModalEnabled: boolean;
  setSeenJoinSubscriptionModal: () => void;
  canPayWithSubscriptionCredits: boolean | undefined;
};

export default function useSubscriptionCredit(): UseSubscriptionCredit {
  const { currentUser, isEsaSession } = useSession();
  const subscriptionEnabled = true;

  const seenJoinSubscriptionModal =
    Cookies.get(COOKIE_SEEN_JOIN_SUBSCRIPTION) === "true";

  const setSeenJoinSubscriptionModal = React.useCallback(() => {
    Cookies.set(COOKIE_SEEN_JOIN_SUBSCRIPTION, "true", { expires: 1 });
  }, []);

  const joinSubscriptionEnabled =
    subscriptionEnabled && !isEsaSession && !currentUser?.hasSubscription;

  const joinSubscriptionModalEnabled =
    joinSubscriptionEnabled && !seenJoinSubscriptionModal;

  return {
    hasSubscription: subscriptionEnabled && currentUser?.hasSubscription,
    canPayWithSubscriptionCredits: currentUser?.canPayWithSubscriptionCredits,
    subscriptionEnabled,
    joinSubscriptionEnabled,
    joinSubscriptionModalEnabled, //This is explicitly for showing the join modal popup that we want to limit to once per day
    setSeenJoinSubscriptionModal,
  };
}
