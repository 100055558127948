import { I18nLocale, LineLocales, KakaoLocales } from "@outschool/localization";
import { addParamsToUrl } from "./utils";

export function pressUrl() {
  return "https://press.outschool.com";
}

export function privacyForLearnersUrl(locale?: string) {
  return locale
    ? "https://support.outschool.com/" + locale + "/articles/5195573"
    : "https://support.outschool.com/en/articles/5195573-learner-privacy-guide";
}

export function teachTipsBaseUrl() {
  return "https://teach.outschool.com";
}

export function teachTipsEducatorHandbookUrl() {
  return `${teachTipsBaseUrl()}/educator-handbook`;
}

export function teachTipsEducatorInsightsUrl() {
  return `${teachTipsBaseUrl()}/all-insights`;
}

export function teachTipsGettingStartedUrl() {
  return `${teachTipsBaseUrl()}/getting-started/`;
}

export function teachTipsStartTutoringUrl() {
  return `${teachTipsBaseUrl()}/handbook/how-to-start-an-online-tutoring-business-onoutschool/`;
}

export function teachTipsUrl() {
  return addParamsToUrl(teachTipsBaseUrl(), {
    utm_source: "product",
    utm_medium: "teacher_dashboard",
    utm_campaign: "teacher_resources_link",
  });
}

export function participationGuidelinesUrl() {
  return "https://static.outschool.com/Outschool+Participation+Guidelines.pdf";
}

export function twitterPageUrl() {
  return "https://www.twitter.com/outschool";
}

export function facebookPageUrl() {
  return "https://www.facebook.com/outschool";
}

export function instagramPageUrl() {
  return "https://www.instagram.com/outschool";
}

export function pinterestPageUrl() {
  return "https://www.pinterest.com/outschool/";
}

export function youtubePageUrl() {
  return "https://www.youtube.com/outschool";
}

export function teachTipsPricingUrl() {
  return `${teachTipsBaseUrl()}/insights/pricing/`;
}

export function approvedUnapprovedResourcesUrl() {
  return addParamsToUrl(
    `${teachTipsBaseUrl()}/handbook/approved-unapproved-external-teaching-tools`,
    {
      utm_source: "product",
      utm_medium: "class_edit",
      utm_campaign: "product_link",
    }
  );
}

export function cancelSectionsVideoUrl() {
  return "https://www.loom.com/share/fd6e7df398c44368a25fd573059d9ac6";
}

export function jobsUrl() {
  return "https://boards.greenhouse.io/outschool";
}

export function codeOfConductVideoUrl() {
  return "https://stream.mux.com/kmrBIYzF02O19LGGTnpdYemo02xLL2rJ3Z/high.mp4";
}

export function learnerContentModerationPolicyForParentsUrl() {
  return "https://support.outschool.com/en/articles/6527121-learner-content-moderation-for-parents";
}

export function joiningAndManagingClassWaitlistsUrl() {
  return "https://support.outschool.com/en/articles/8802240-joining-and-managing-class-waitlists";
}

export function facebookProspectiveTeachersGroupUrl() {
  return "https://www.facebook.com/groups/OnlineTeachingwithOutschool";
}

export function facebookCohortGroupUrl() {
  return "https://www.facebook.com/groups/newosteachers2021q4/";
}

export function statusPageUrl() {
  return "https://status.outschool.com";
}

export function outschoolForEmployersResourcesUrl() {
  return "https://info.outschool.com/employer-resources";
}

export function outschoolForSchoolsResourcesUrl() {
  return "https://info.outschool.com/schools-resources";
}

export function outschoolForSchoolsUrl() {
  return "https://info.outschool.com/for-schools-districts";
}

export function teacherBehaviorBreakdownUrl(teacherUid: string) {
  return `https://metabase.outschool.dev/dashboard/654?teacher_uid=${teacherUid}`;
}

export function trustosUrl(userUidOrEmail?: string) {
  return `https://retool.outschool.dev/apps/TrustOS/TrustOS%20Control%20Panel${
    userUidOrEmail ? "?useruid=" + encodeURIComponent(userUidOrEmail) : ""
  }`;
}

// Outschool locales do not map to info.outschool locales. Only 3 locales currently supported.
export function outschoolEastAsiaReferralUrl(locale: I18nLocale, usid: string) {
  const localeUrlMap: { [key in I18nLocale]?: string } = {
    [I18nLocale.Ko]: "https://info.outschool.com/kr/24-referral",
    [I18nLocale.Ja]: "https://info.outschool.com/jp/24-referral",
    [I18nLocale.ZhTw]: "https://info.outschool.com/twhk/24-referral",
  };
  if (!LineLocales.includes(locale) && !KakaoLocales.includes(locale)) {
    return undefined;
  }

  const baseUrl = localeUrlMap[locale];
  if (!baseUrl) {
    return undefined;
  }

  const url = new URL(baseUrl);

  if (!!usid) {
    url.searchParams.set("usid", usid);
  }

  return url.toString();
}

export function googleAuthUrl(
  clientId: string,
  redirectUrl: string,
  osRef: any,
  disallowWebview?: boolean
) {
  return addParamsToUrl("https://accounts.google.com/o/oauth2/v2/auth", {
    response_type: "code",
    access_type: "offline",
    include_granted_scopes: true,
    client_id: clientId,
    scope: "openid profile email",
    redirect_uri: redirectUrl,
    state: JSON.stringify({ osRef }),
    ...(disallowWebview ? { disallow_webview: true } : {}),
  });
}

export function facebookAuthUrl(
  clientId: string,
  redirectUrl: string,
  osRef: any
) {
  return addParamsToUrl("https://www.facebook.com/v12.0/dialog/oauth", {
    response_type: "code",
    client_id: clientId,
    scope: "public_profile email",
    redirect_uri: redirectUrl,
    state: JSON.stringify({ osRef }),
  });
}
