import type { OrganizationType } from "@outschool/gql-backend-generated";

import jwtDecode from "jwt-decode";

export enum AuthProviders {
  Password = "Password",
  Facebook = "Facebook",
  Google = "Google",
  Apple = "Apple",
  Line = "Line",
  Kakao = "Kakao",
}

// Minimum session token lifetime
export const ACCESS_JWT_MIN_LIFETIME_MS = 60 * 5 * 1000; // five minutes

// How early to refresh our tokens
export const ACCESS_JWT_EXPIRE_EARLY_MS = 30 * 1000; // thirty seconds

// How long session tokens last
export const ACCESS_JWT_SIGN_LIFETIME_MS = Math.max(
  process?.env?.SESSION_TOKEN_LIFETIME_MS
    ? Number.parseInt(process.env.SESSION_TOKEN_LIFETIME_MS)
    : 90 * 60 * 1000, // 90 minutes
  ACCESS_JWT_MIN_LIFETIME_MS
);

// Refresh interval constant
// (only used in learnerApp, in website interval is dynamic)
export const ACCESS_JWT_REFRESH_INTERVAL_MS =
  ACCESS_JWT_SIGN_LIFETIME_MS - ACCESS_JWT_EXPIRE_EARLY_MS;

export interface Auth {
  uid: string;
  hasFacebook: boolean;
  hasGoogle: boolean;
  hasPassword: boolean;
  adminForOrganizationType?: OrganizationType;
  providers: AuthProviders[];
  is_admin: boolean;
  isLoggedIn?: boolean;
  leader_subscribed_at?: Date | null;
  roles?: Roles[];
  exp?: number;
  iat?: number;
  isLearnerLogin?: boolean;
  learnerUid?: string;
  isImpersonating?: boolean;
  issuerEndpoint?: string;
}

export interface LearnerAuth {
  uid?: string; //duplicate of userUid
  userUid: string;
  learnerUid: string;
  isLoggedIn: boolean;
  doNotTrack?: boolean;
  isLearnerLogin?: boolean;
  providers: AuthProviders[];
  exp?: number;
  iat?: number;
  issuerEndpoint?: string;
}

export interface LearnerAuthTransfer {
  userUid: string;
  isLoggedIn: boolean;
  learnerUid: string;
  createdAt: Date;
  isLearnerTransfer: boolean;
  exp?: number;
  iat?: number;
  issuerEndpoint?: string;
}

export function isLearnerAuth(auth: unknown): auth is LearnerAuth {
  return !!auth && typeof (auth as LearnerAuth).learnerUid === "string";
}

export function isLearnerAuthTransfer(
  auth: unknown
): auth is LearnerAuthTransfer {
  return (
    !!auth &&
    typeof (auth as LearnerAuthTransfer).isLearnerTransfer === "boolean"
  );
}

export function isParentAuthTransfer(auth: unknown): boolean {
  return (
    !!auth &&
    typeof (auth as LearnerAuthTransfer).isLearnerTransfer === "boolean" &&
    (auth as LearnerAuthTransfer).isLearnerTransfer &&
    typeof (auth as LearnerAuthTransfer).learnerUid === "undefined"
  );
}

export function decodeToken(
  token: Auth | LearnerAuth | string | null
): Auth | LearnerAuth | LearnerAuthTransfer | null {
  if (typeof token === "string") {
    return jwtDecode<Auth | LearnerAuth | LearnerAuthTransfer>(token);
  }
  return token;
}

export function decodeLearnerToken(token: string): LearnerAuth {
  return jwtDecode<LearnerAuth>(token);
}

export function hasAccount(
  auth: Pick<Auth, "hasPassword" | "hasFacebook" | "hasGoogle" | "providers">
) {
  return !!(
    auth &&
    /* TODO remove uses of hasPassword hasFacebook, hasGoogle */
    (auth.hasPassword ||
      auth.hasFacebook ||
      auth.hasGoogle ||
      auth.providers?.length > 0)
  );
}

export function isAdmin(auth: Auth | null) {
  return !!(auth && isLoggedIn(auth) && auth.is_admin);
}

// TODO: Refactor this once we have all users with isLoggedIn flag
export function isLoggedIn(auth: Auth) {
  return (
    !!auth &&
    !hasExpired(auth) &&
    hasAccount(auth) &&
    (auth.isLoggedIn === undefined ? true : auth.isLoggedIn)
  );
}

type Token = Auth | LearnerAuth | string | null;
type DecodedToken = ReturnType<typeof decodeToken>;

export function hasExpired(token: Token) {
  if (!token) {
    return false;
  }

  const decodedToken = decodeToken(token);

  return hasDecodedTokenExpiredSince(decodedToken);
}

/**
 * Check if your token should be refreshed
 * A token should be refreshed _ahead_ of its expiry time
 */
export function shouldRefresh(token: Token) {
  if (!token) {
    return false;
  }

  const decodedToken = decodeToken(token);

  // We "fast-forward" the current date to simulate early expiry
  const fastForwardedDatetime = Date.now() + ACCESS_JWT_EXPIRE_EARLY_MS / 2; // This timestamp is BEFORE expiry, but AFTER expected refresh

  return hasDecodedTokenExpiredSince(
    decodedToken,
    new Date(fastForwardedDatetime)
  );
}

export function getAuthTokenIssuerEndpoint(token: Token) {
  if (!token) {
    return null;
  }

  const decodedToken = decodeToken(token);
  if (!decodedToken) {
    return null;
  }

  if (!decodedToken.issuerEndpoint) {
    return "none";
  }

  return decodedToken.issuerEndpoint;
}

export function getAuthTokenIat(token: Token) {
  if (!token) {
    return null;
  }

  const decodedToken = decodeToken(token);
  if (!decodedToken) {
    return null;
  }

  if (Number.isNaN(decodedToken.iat)) {
    return -1;
  }

  return decodedToken.iat ?? null;
}

/**
 * Check if the decoded token has expired.
 * @param decodedToken token to verify
 * @param date object to compare to decodedToken expiry
 */
function hasDecodedTokenExpiredSince(
  decodedToken: DecodedToken,
  date = new Date()
) {
  if (!decodedToken || !decodedToken.exp) {
    return false;
  }

  // `.exp` is the expiration timestamp (seconds since Unix epoch)
  const expirationTimeMs = decodedToken.exp * 1000; // Date constructor expects milliseconds so we multiply by 1000.

  return new Date(expirationTimeMs) < date;
}

export function isLeader(auth: Auth) {
  return !!(auth && auth.leader_subscribed_at) && hasAccount(auth);
}

export function hasProvider(auth: Auth, provider: AuthProviders) {
  return Boolean(auth?.providers?.includes(provider));
}

export function hasFacebook(auth: Auth) {
  return Boolean(
    hasProvider(auth, AuthProviders.Facebook) || auth?.hasFacebook
  );
}

export function hasGoogle(auth: Auth) {
  return Boolean(hasProvider(auth, AuthProviders.Google) || auth?.hasGoogle);
}

export function hasPassword(auth: Auth) {
  return Boolean(
    hasProvider(auth, AuthProviders.Password) || auth?.hasPassword
  );
}

export function hasApple(auth: Auth) {
  return hasProvider(auth, AuthProviders.Apple);
}

export function hasLine(auth: Auth) {
  return hasProvider(auth, AuthProviders.Line);
}

export function hasKakao(auth: Auth) {
  return hasProvider(auth, AuthProviders.Kakao);
}

export function getRoles(auth: Auth): Roles[] {
  return auth && auth.roles ? auth.roles : [];
}

/**
 * This enum is for user-facing roles only (parents, educators, and other
 * customers). Admins (Outschool employees, contractors) should be assigned
 * permissions through the access-control system.
 */
export enum Roles {
  CanOrganizationEnroll = "can_organization_enroll",

  // Leftover admin roles from before we migrated to access-control.
  ClassApproval = "class_approval",
  OnboardTeachers = "onboard_teachers",
  OrganizationAdmin = "organization_admin",
}

export function requireRoles(
  auth: Auth,
  roles: Roles[],
  inherit: boolean = true
) {
  return roles.every(role => hasRole(auth, role, inherit));
}

export function hasRole(
  auth: Auth | null,
  role: Roles,
  inherit: boolean = true
) {
  return (inherit && isAdmin(auth)) || auth?.roles?.includes(role) || false;
}

export * from "./OAuth2";
export * from "./learnerMode";
export { EsaSessionData } from "./esa";
