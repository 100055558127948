import { gql } from "@outschool/ui-apollo";

export const SavedActivityFragment = gql`
  fragment SavedActivityFragment on SavedActivity {
    uid
    activity_uid
    created_at
  }
`;

export const SaveButtonActivityFragment = gql`
  fragment AddToCollectionModal_activity on Activity {
    uid
    savedClassLists {
      uid
      lastAddedToAt
    }
  }

  fragment TrackSaveClass_activity on Activity {
    uid
    slug_id
    title
    titleTranslated
    price_cents
    subject
  }

  fragment SaveButton_activity on Activity {
    uid
    title
    titleTranslated
    isClub
    details {
      photo
    }
    ...AddToCollectionModal_activity
    ...TrackSaveClass_activity
  }
`;
