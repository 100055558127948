export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type SearchListingsChatGptQueryVariables = Exact<{
  searchFilters: SearchFilters;
  offset?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  cursor?: InputMaybe<Scalars["String"]>;
  skipRating: Scalars["Boolean"];
  matchNames?: InputMaybe<Scalars["Boolean"]>;
  referrer?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["Boolean"]>;
  isFuzzySearchEnabled?: InputMaybe<Scalars["Boolean"]>;
  pageLocation?: InputMaybe<Scalars["String"]>;
  useCase?: InputMaybe<SearchUseCase>;
  searchObjective?: InputMaybe<SearchObjective>;
}>;

export type SearchListingsChatGptQuery = {
  __typename?: "Query";
  searchListings: {
    __typename?: "SearchListingsConnection";
    cursor: string | null;
    listings: Array<{
      __typename?: "Listing";
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      isAvailable: boolean | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        user_uid: string;
        slug_id: string;
        title: string;
        summary: string | null;
        subject: string | null;
        weekly_meetings: number | null;
        duration_weeks: number | null;
        duration_minutes: number | null;
        price_cents: number | null;
        is_ongoing_weekly: boolean;
        isClub: boolean;
        age_min: number | null;
        age_max: number | null;
        averageStarRating?: number | null;
        reviewCount?: number;
        details: { __typename?: "ActivityDetails"; photo: string | null };
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        size_max: number | null;
        filledSpaceCount: number;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
        } | null;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        leader_link: string | null;
      };
    }>;
  };
};

export type SearchTeacherSuggestionsForChatGptQueryVariables = Exact<{
  name: Scalars["String"];
  size?: InputMaybe<Scalars["Int"]>;
}>;

export type SearchTeacherSuggestionsForChatGptQuery = {
  __typename?: "Query";
  searchSuggestions: {
    __typename?: "SearchSuggestions";
    teacherSuggestions: Array<{
      __typename?: "Leader";
      uid: string;
      name: string | null;
    }>;
  };
};

export type LandingPageClassCarouselFragment = {
  __typename?: "ContentfulLandingPageClassCarousel";
  title: string | null;
  searchFilters: {
    __typename?: "ContentfulSearchFilters";
    adminTags: Array<string | null> | null;
    ages: Array<string | null> | null;
    capacityMax: number | null;
    capacityMin: number | null;
    deliveryTypes: Array<string | null> | null;
    durationWeeksMax: number | null;
    durationWeeksMin: number | null;
    endBy: any | null;
    formats: Array<string | null> | null;
    fundingPrograms: Array<string | null> | null;
    gradeLevel: Array<string | null> | null;
    hasFilledOutUniqueLearningNeeds: boolean | null;
    pricePerCourseMax: number | null;
    pricePerCourseMin: number | null;
    pricePerMeetingMax: number | null;
    pricePerMeetingMin: number | null;
    multiTermQuery: string | null;
    query: string | null;
    startAfter: any | null;
    startBefore: any | null;
    subject: string | null;
    uniqueLearningNeeds: string | null;
    weeklyMeetingsMin: number | null;
    weeklyMeetingsMax: number | null;
  } | null;
};

export type ContentfulLandingPageClassCarouselQueryVariables = Exact<{
  ID: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview: Scalars["Boolean"];
}>;

export type ContentfulLandingPageClassCarouselQuery = {
  __typename?: "Query";
  contentfulLandingPageClassCarouselCollection: {
    __typename?: "ContentfulLandingPageClassCarouselCollection";
    items: Array<{
      __typename?: "ContentfulLandingPageClassCarousel";
      title: string | null;
      searchFilters: {
        __typename?: "ContentfulSearchFilters";
        adminTags: Array<string | null> | null;
        ages: Array<string | null> | null;
        capacityMax: number | null;
        capacityMin: number | null;
        deliveryTypes: Array<string | null> | null;
        durationWeeksMax: number | null;
        durationWeeksMin: number | null;
        endBy: any | null;
        formats: Array<string | null> | null;
        fundingPrograms: Array<string | null> | null;
        gradeLevel: Array<string | null> | null;
        hasFilledOutUniqueLearningNeeds: boolean | null;
        pricePerCourseMax: number | null;
        pricePerCourseMin: number | null;
        pricePerMeetingMax: number | null;
        pricePerMeetingMin: number | null;
        multiTermQuery: string | null;
        query: string | null;
        startAfter: any | null;
        startBefore: any | null;
        subject: string | null;
        uniqueLearningNeeds: string | null;
        weeklyMeetingsMin: number | null;
        weeklyMeetingsMax: number | null;
      } | null;
    } | null>;
  } | null;
};

export type LandingPageHeroSectionFragment = {
  __typename?: "ContentfulLandingPageHeroSection";
  title: string | null;
  subtitle: string | null;
  shouldShowCta: boolean | null;
  buttonCtaText: string | null;
  buttonUrl: string | null;
  buttonUrlLoggedIn: string | null;
  shouldShowTrustPilot: boolean | null;
  animationUrl: string | null;
  animationCssDesktop: any | null;
  animationCssMobile: any | null;
  backgroundImage: {
    __typename?: "ContentfulAsset";
    url: string | null;
  } | null;
  mainImage: { __typename?: "ContentfulAsset"; url: string | null } | null;
};

export type ContentfulLandingPageHeroSectionQueryVariables = Exact<{
  ID: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview: Scalars["Boolean"];
}>;

export type ContentfulLandingPageHeroSectionQuery = {
  __typename?: "Query";
  contentfulLandingPageHeroSectionCollection: {
    __typename?: "ContentfulLandingPageHeroSectionCollection";
    items: Array<{
      __typename?: "ContentfulLandingPageHeroSection";
      title: string | null;
      subtitle: string | null;
      shouldShowCta: boolean | null;
      buttonCtaText: string | null;
      buttonUrl: string | null;
      buttonUrlLoggedIn: string | null;
      shouldShowTrustPilot: boolean | null;
      animationUrl: string | null;
      animationCssDesktop: any | null;
      animationCssMobile: any | null;
      backgroundImage: {
        __typename?: "ContentfulAsset";
        url: string | null;
      } | null;
      mainImage: { __typename?: "ContentfulAsset"; url: string | null } | null;
    } | null>;
  } | null;
};

export type LandingPageIconInfoFragment = {
  __typename?: "ContentfulLandingPageIconInfo";
  subtitle: string | null;
  iconImage: { __typename?: "ContentfulAsset"; url: string | null } | null;
};

export type LandingPageIconInfoSectionFragment = {
  __typename?: "ContentfulLandingPageIconInfoSection";
  displayOnMobile: boolean | null;
  iconInfosCollection: {
    __typename?: "ContentfulLandingPageIconInfoSectionIconInfosCollection";
    items: Array<{
      __typename?: "ContentfulLandingPageIconInfo";
      subtitle: string | null;
      iconImage: { __typename?: "ContentfulAsset"; url: string | null } | null;
    } | null>;
  } | null;
};

export type ContentfulLandingPageIconInfoSectionQueryVariables = Exact<{
  ID: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview: Scalars["Boolean"];
}>;

export type ContentfulLandingPageIconInfoSectionQuery = {
  __typename?: "Query";
  contentfulLandingPageIconInfoSectionCollection: {
    __typename?: "ContentfulLandingPageIconInfoSectionCollection";
    items: Array<{
      __typename?: "ContentfulLandingPageIconInfoSection";
      displayOnMobile: boolean | null;
      iconInfosCollection: {
        __typename?: "ContentfulLandingPageIconInfoSectionIconInfosCollection";
        items: Array<{
          __typename?: "ContentfulLandingPageIconInfo";
          subtitle: string | null;
          iconImage: {
            __typename?: "ContentfulAsset";
            url: string | null;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type LandingPageImageTextCtaSectionFragment = {
  __typename?: "ContentfulLandingPageImageTextCtaSection";
  titleStartText: string | null;
  titleStartColor: string | null;
  titleEndText: string | null;
  titleEndColor: string | null;
  buttonCtaText: string | null;
  buttonUrl: string | null;
  buttonUrlLoggedIn: string | null;
  image: { __typename?: "ContentfulAsset"; url: string | null } | null;
  subtitleRt: {
    __typename?: "ContentfulLandingPageImageTextCtaSectionSubtitleRt";
    json: any;
  } | null;
};

export type ContentfulLandingPageImageTextCtaSectionQueryVariables = Exact<{
  ID: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview: Scalars["Boolean"];
}>;

export type ContentfulLandingPageImageTextCtaSectionQuery = {
  __typename?: "Query";
  contentfulLandingPageImageTextCtaSectionCollection: {
    __typename?: "ContentfulLandingPageImageTextCtaSectionCollection";
    items: Array<{
      __typename?: "ContentfulLandingPageImageTextCtaSection";
      titleStartText: string | null;
      titleStartColor: string | null;
      titleEndText: string | null;
      titleEndColor: string | null;
      buttonCtaText: string | null;
      buttonUrl: string | null;
      buttonUrlLoggedIn: string | null;
      image: { __typename?: "ContentfulAsset"; url: string | null } | null;
      subtitleRt: {
        __typename?: "ContentfulLandingPageImageTextCtaSectionSubtitleRt";
        json: any;
      } | null;
    } | null>;
  } | null;
};

export type LandingPageClpEmailCaptureInfoFragment = {
  __typename?: "ContentfulClpEmailCapture";
  name: string | null;
  title: string | null;
  callToActionText: string | null;
  successMessage: string | null;
  errorMessage: string | null;
  prodIterableAudienceId: string | null;
  stagingIterableAudienceId: string | null;
  bodyContent: {
    __typename?: "ContentfulClpEmailCaptureBodyContent";
    json: any;
  } | null;
  clpPagesCollection: {
    __typename?: "ContentfulClpEmailCaptureClpPagesCollection";
    items: Array<{
      __typename?: "ContentfulCategoryLabel";
      value: string | null;
    } | null>;
  } | null;
};

export type ContentfulLandingPageClpEmailCaptureInfoSectionQueryVariables =
  Exact<{
    ID: Scalars["String"];
    locale?: InputMaybe<Scalars["String"]>;
    preview: Scalars["Boolean"];
  }>;

export type ContentfulLandingPageClpEmailCaptureInfoSectionQuery = {
  __typename?: "Query";
  contentfulClpEmailCaptureCollection: {
    __typename?: "ContentfulClpEmailCaptureCollection";
    items: Array<{
      __typename?: "ContentfulClpEmailCapture";
      name: string | null;
      title: string | null;
      callToActionText: string | null;
      successMessage: string | null;
      errorMessage: string | null;
      prodIterableAudienceId: string | null;
      stagingIterableAudienceId: string | null;
      bodyContent: {
        __typename?: "ContentfulClpEmailCaptureBodyContent";
        json: any;
      } | null;
      clpPagesCollection: {
        __typename?: "ContentfulClpEmailCaptureClpPagesCollection";
        items: Array<{
          __typename?: "ContentfulCategoryLabel";
          value: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type LandingPageJourneyItemFragment = {
  __typename?: "ContentfulLandingPageJourneyItem";
  title: string | null;
  numberColor: string | null;
  animationUrl: string | null;
  image: { __typename?: "ContentfulAsset"; url: string | null } | null;
  backgroundImage: {
    __typename?: "ContentfulAsset";
    url: string | null;
  } | null;
};

export type LandingPageJourneySectionFragment = {
  __typename?: "ContentfulLandingPageJourneySection";
  title: string | null;
  journeyItemsCollection: {
    __typename?: "ContentfulLandingPageJourneySectionJourneyItemsCollection";
    items: Array<{
      __typename?: "ContentfulLandingPageJourneyItem";
      title: string | null;
      numberColor: string | null;
      animationUrl: string | null;
      image: { __typename?: "ContentfulAsset"; url: string | null } | null;
      backgroundImage: {
        __typename?: "ContentfulAsset";
        url: string | null;
      } | null;
    } | null>;
  } | null;
};

export type ContentfulLandingPageJourneySectionQueryVariables = Exact<{
  ID: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview: Scalars["Boolean"];
}>;

export type ContentfulLandingPageJourneySectionQuery = {
  __typename?: "Query";
  contentfulLandingPageJourneySectionCollection: {
    __typename?: "ContentfulLandingPageJourneySectionCollection";
    items: Array<{
      __typename?: "ContentfulLandingPageJourneySection";
      title: string | null;
      journeyItemsCollection: {
        __typename?: "ContentfulLandingPageJourneySectionJourneyItemsCollection";
        items: Array<{
          __typename?: "ContentfulLandingPageJourneyItem";
          title: string | null;
          numberColor: string | null;
          animationUrl: string | null;
          image: { __typename?: "ContentfulAsset"; url: string | null } | null;
          backgroundImage: {
            __typename?: "ContentfulAsset";
            url: string | null;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type LandingPageLessonPreviewSectionFragment = {
  __typename?: "ContentfulLandingPageLessonPreviewSection";
  title: string | null;
  ctaUrl: string | null;
  ctaText: string | null;
  description: {
    __typename?: "ContentfulLandingPageLessonPreviewSectionDescription";
    json: any;
  } | null;
  video: { __typename?: "ContentfulAsset"; url: string | null } | null;
};

export type ContentfulLandingPageLessonPreviewSectionQueryVariables = Exact<{
  ID: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview: Scalars["Boolean"];
}>;

export type ContentfulLandingPageLessonPreviewSectionQuery = {
  __typename?: "Query";
  contentfulLandingPageLessonPreviewSectionCollection: {
    __typename?: "ContentfulLandingPageLessonPreviewSectionCollection";
    items: Array<{
      __typename?: "ContentfulLandingPageLessonPreviewSection";
      title: string | null;
      ctaUrl: string | null;
      ctaText: string | null;
      description: {
        __typename?: "ContentfulLandingPageLessonPreviewSectionDescription";
        json: any;
      } | null;
      video: { __typename?: "ContentfulAsset"; url: string | null } | null;
    } | null>;
  } | null;
};

export type LandingPageSingleReviewSectionFragment = {
  __typename?: "ContentfulLandingPageSingleReviewSection";
  reviewerName: string | null;
  className: string | null;
  classUrl: string | null;
  reviewText: {
    __typename?: "ContentfulLandingPageSingleReviewSectionReviewText";
    json: any;
  } | null;
  image: { __typename?: "ContentfulAsset"; url: string | null } | null;
  imageMobile: { __typename?: "ContentfulAsset"; url: string | null } | null;
  reviewImageBackgroundImage: {
    __typename?: "ContentfulAsset";
    url: string | null;
  } | null;
  reviewTextBackgroundImage: {
    __typename?: "ContentfulAsset";
    url: string | null;
  } | null;
};

export type ContentfulLandingPageSingleReviewSectionQueryVariables = Exact<{
  ID: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview: Scalars["Boolean"];
}>;

export type ContentfulLandingPageSingleReviewSectionQuery = {
  __typename?: "Query";
  contentfulLandingPageSingleReviewSectionCollection: {
    __typename?: "ContentfulLandingPageSingleReviewSectionCollection";
    items: Array<{
      __typename?: "ContentfulLandingPageSingleReviewSection";
      reviewerName: string | null;
      className: string | null;
      classUrl: string | null;
      reviewText: {
        __typename?: "ContentfulLandingPageSingleReviewSectionReviewText";
        json: any;
      } | null;
      image: { __typename?: "ContentfulAsset"; url: string | null } | null;
      imageMobile: {
        __typename?: "ContentfulAsset";
        url: string | null;
      } | null;
      reviewImageBackgroundImage: {
        __typename?: "ContentfulAsset";
        url: string | null;
      } | null;
      reviewTextBackgroundImage: {
        __typename?: "ContentfulAsset";
        url: string | null;
      } | null;
    } | null>;
  } | null;
};

export type LandingPageStatBoxFragment = {
  __typename?: "ContentfulLandingPageStatBox";
  title: string | null;
  subtitle: string | null;
  backgroundImage: {
    __typename?: "ContentfulAsset";
    url: string | null;
  } | null;
  hoverBackgroundImage: {
    __typename?: "ContentfulAsset";
    url: string | null;
  } | null;
};

export type LandingPageStatBoxSectionFragment = {
  __typename?: "ContentfulLandingPageStatBoxSection";
  displayOnMobile: boolean | null;
  statBoxesCollection: {
    __typename?: "ContentfulLandingPageStatBoxSectionStatBoxesCollection";
    items: Array<{
      __typename?: "ContentfulLandingPageStatBox";
      title: string | null;
      subtitle: string | null;
      backgroundImage: {
        __typename?: "ContentfulAsset";
        url: string | null;
      } | null;
      hoverBackgroundImage: {
        __typename?: "ContentfulAsset";
        url: string | null;
      } | null;
    } | null>;
  } | null;
};

export type ContentfulLandingPageStatBoxSectionQueryVariables = Exact<{
  ID: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview: Scalars["Boolean"];
}>;

export type ContentfulLandingPageStatBoxSectionQuery = {
  __typename?: "Query";
  contentfulLandingPageStatBoxSectionCollection: {
    __typename?: "ContentfulLandingPageStatBoxSectionCollection";
    items: Array<{
      __typename?: "ContentfulLandingPageStatBoxSection";
      displayOnMobile: boolean | null;
      statBoxesCollection: {
        __typename?: "ContentfulLandingPageStatBoxSectionStatBoxesCollection";
        items: Array<{
          __typename?: "ContentfulLandingPageStatBox";
          title: string | null;
          subtitle: string | null;
          backgroundImage: {
            __typename?: "ContentfulAsset";
            url: string | null;
          } | null;
          hoverBackgroundImage: {
            __typename?: "ContentfulAsset";
            url: string | null;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type LandingPageTextOnlyStackedCtaSectionFragment = {
  __typename?: "ContentfulLandingPageTextOnlyStackedCtaSection";
  title: string | null;
  buttonCtaText: string | null;
  buttonUrl: string | null;
  buttonUrlLoggedIn: string | null;
  includeGradientBackground: boolean | null;
};

export type ContentfulLandingPageTextOnlyStackedCtaSectionQueryVariables =
  Exact<{
    ID: Scalars["String"];
    locale?: InputMaybe<Scalars["String"]>;
    preview: Scalars["Boolean"];
  }>;

export type ContentfulLandingPageTextOnlyStackedCtaSectionQuery = {
  __typename?: "Query";
  contentfulLandingPageTextOnlyStackedCtaSectionCollection: {
    __typename?: "ContentfulLandingPageTextOnlyStackedCtaSectionCollection";
    items: Array<{
      __typename?: "ContentfulLandingPageTextOnlyStackedCtaSection";
      title: string | null;
      buttonCtaText: string | null;
      buttonUrl: string | null;
      buttonUrlLoggedIn: string | null;
      includeGradientBackground: boolean | null;
    } | null>;
  } | null;
};

export type ContentfulLandingPageQueryVariables = Exact<{
  slug?: InputMaybe<Scalars["String"]>;
  ID?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
  preview: Scalars["Boolean"];
}>;

export type ContentfulLandingPageQuery = {
  __typename?: "Query";
  contentfulLandingPageCollection: {
    __typename?: "ContentfulLandingPageCollection";
    items: Array<{
      __typename?: "ContentfulLandingPage";
      title: string | null;
      metaTagCanonicalUrl: string | null;
      metaTagDescription: string | null;
      navbarCtaUrl: string | null;
      navbarCtaUrlLoggedIn: string | null;
      navbarCtaText: string | null;
      metaTagSharedLinkRichPreviewImage: {
        __typename?: "ContentfulAsset";
        url: string | null;
      } | null;
      sectionsCollection: {
        __typename?: "ContentfulLandingPageSectionsCollection";
        items: Array<
          | {
              __typename: "ContentfulClpEmailCapture";
              name: string | null;
              title: string | null;
              callToActionText: string | null;
              successMessage: string | null;
              errorMessage: string | null;
              prodIterableAudienceId: string | null;
              stagingIterableAudienceId: string | null;
              bodyContent: {
                __typename?: "ContentfulClpEmailCaptureBodyContent";
                json: any;
              } | null;
              clpPagesCollection: {
                __typename?: "ContentfulClpEmailCaptureClpPagesCollection";
                items: Array<{
                  __typename?: "ContentfulCategoryLabel";
                  value: string | null;
                } | null>;
              } | null;
            }
          | {
              __typename: "ContentfulLandingPageClassCarousel";
              title: string | null;
              searchFilters: {
                __typename?: "ContentfulSearchFilters";
                adminTags: Array<string | null> | null;
                ages: Array<string | null> | null;
                capacityMax: number | null;
                capacityMin: number | null;
                deliveryTypes: Array<string | null> | null;
                durationWeeksMax: number | null;
                durationWeeksMin: number | null;
                endBy: any | null;
                formats: Array<string | null> | null;
                fundingPrograms: Array<string | null> | null;
                gradeLevel: Array<string | null> | null;
                hasFilledOutUniqueLearningNeeds: boolean | null;
                pricePerCourseMax: number | null;
                pricePerCourseMin: number | null;
                pricePerMeetingMax: number | null;
                pricePerMeetingMin: number | null;
                multiTermQuery: string | null;
                query: string | null;
                startAfter: any | null;
                startBefore: any | null;
                subject: string | null;
                uniqueLearningNeeds: string | null;
                weeklyMeetingsMin: number | null;
                weeklyMeetingsMax: number | null;
              } | null;
            }
          | {
              __typename: "ContentfulLandingPageHeroSection";
              title: string | null;
              subtitle: string | null;
              shouldShowCta: boolean | null;
              buttonCtaText: string | null;
              buttonUrl: string | null;
              buttonUrlLoggedIn: string | null;
              shouldShowTrustPilot: boolean | null;
              animationUrl: string | null;
              animationCssDesktop: any | null;
              animationCssMobile: any | null;
              backgroundImage: {
                __typename?: "ContentfulAsset";
                url: string | null;
              } | null;
              mainImage: {
                __typename?: "ContentfulAsset";
                url: string | null;
              } | null;
            }
          | {
              __typename: "ContentfulLandingPageIconInfoSection";
              displayOnMobile: boolean | null;
              iconInfosCollection: {
                __typename?: "ContentfulLandingPageIconInfoSectionIconInfosCollection";
                items: Array<{
                  __typename?: "ContentfulLandingPageIconInfo";
                  subtitle: string | null;
                  iconImage: {
                    __typename?: "ContentfulAsset";
                    url: string | null;
                  } | null;
                } | null>;
              } | null;
            }
          | {
              __typename: "ContentfulLandingPageImageTextCtaSection";
              titleStartText: string | null;
              titleStartColor: string | null;
              titleEndText: string | null;
              titleEndColor: string | null;
              buttonCtaText: string | null;
              buttonUrl: string | null;
              buttonUrlLoggedIn: string | null;
              image: {
                __typename?: "ContentfulAsset";
                url: string | null;
              } | null;
              subtitleRt: {
                __typename?: "ContentfulLandingPageImageTextCtaSectionSubtitleRt";
                json: any;
              } | null;
            }
          | {
              __typename: "ContentfulLandingPageJourneySection";
              title: string | null;
              journeyItemsCollection: {
                __typename?: "ContentfulLandingPageJourneySectionJourneyItemsCollection";
                items: Array<{
                  __typename?: "ContentfulLandingPageJourneyItem";
                  title: string | null;
                  numberColor: string | null;
                  animationUrl: string | null;
                  image: {
                    __typename?: "ContentfulAsset";
                    url: string | null;
                  } | null;
                  backgroundImage: {
                    __typename?: "ContentfulAsset";
                    url: string | null;
                  } | null;
                } | null>;
              } | null;
            }
          | {
              __typename: "ContentfulLandingPageLessonPreviewSection";
              title: string | null;
              ctaUrl: string | null;
              ctaText: string | null;
              description: {
                __typename?: "ContentfulLandingPageLessonPreviewSectionDescription";
                json: any;
              } | null;
              video: {
                __typename?: "ContentfulAsset";
                url: string | null;
              } | null;
            }
          | {
              __typename: "ContentfulLandingPageSingleReviewSection";
              reviewerName: string | null;
              className: string | null;
              classUrl: string | null;
              reviewText: {
                __typename?: "ContentfulLandingPageSingleReviewSectionReviewText";
                json: any;
              } | null;
              image: {
                __typename?: "ContentfulAsset";
                url: string | null;
              } | null;
              imageMobile: {
                __typename?: "ContentfulAsset";
                url: string | null;
              } | null;
              reviewImageBackgroundImage: {
                __typename?: "ContentfulAsset";
                url: string | null;
              } | null;
              reviewTextBackgroundImage: {
                __typename?: "ContentfulAsset";
                url: string | null;
              } | null;
            }
          | {
              __typename: "ContentfulLandingPageStatBoxSection";
              displayOnMobile: boolean | null;
              statBoxesCollection: {
                __typename?: "ContentfulLandingPageStatBoxSectionStatBoxesCollection";
                items: Array<{
                  __typename?: "ContentfulLandingPageStatBox";
                  title: string | null;
                  subtitle: string | null;
                  backgroundImage: {
                    __typename?: "ContentfulAsset";
                    url: string | null;
                  } | null;
                  hoverBackgroundImage: {
                    __typename?: "ContentfulAsset";
                    url: string | null;
                  } | null;
                } | null>;
              } | null;
            }
          | {
              __typename: "ContentfulLandingPageTextOnlyStackedCtaSection";
              title: string | null;
              buttonCtaText: string | null;
              buttonUrl: string | null;
              buttonUrlLoggedIn: string | null;
              includeGradientBackground: boolean | null;
            }
          | null
        >;
      } | null;
    } | null>;
  } | null;
};

export type BrowseGridQueryVariables = Exact<{
  subject?: InputMaybe<Scalars["String"]>;
}>;

export type BrowseGridQuery = {
  __typename?: "Query";
  trendingSearchTopics: Array<{
    __typename?: "TrendingSearchTopic";
    topic: string;
    translatedTopic: string;
  }>;
};

export type CategoryPagesByPageQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type CategoryPagesByPageQuery = {
  __typename?: "Query";
  categoryPagesByPage: {
    __typename?: "PaginatedCategoryPages";
    total: number | null;
    limit: number | null;
    count: number | null;
    offset: number | null;
    categoryPages: Array<{
      __typename?: "CategoryPage";
      uid: string;
      basePath: string;
      name: string;
      slug: string;
    } | null> | null;
  } | null;
};

export type ContentfulAccordionPageQueryVariables = Exact<{
  slug: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview: Scalars["Boolean"];
}>;

export type ContentfulAccordionPageQuery = {
  __typename?: "Query";
  contentfulAccordionPageCollection: {
    __typename?: "ContentfulAccordionPageCollection";
    items: Array<{
      __typename?: "ContentfulAccordionPage";
      title: string | null;
      showToc: boolean | null;
      publishedAt: any | null;
      headerImage: {
        __typename?: "ContentfulAsset";
        url: string | null;
        description: string | null;
        width: number | null;
        height: number | null;
      } | null;
      preamble: {
        __typename?: "ContentfulAccordionPagePreamble";
        json: any;
      } | null;
      sectionsCollection: {
        __typename?: "ContentfulAccordionPageSectionsCollection";
        items: Array<{
          __typename?: "ContentfulAccordionSection";
          heading: string | null;
          body: {
            __typename?: "ContentfulAccordionSectionBody";
            json: any;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type LoadContentfulBannersQueryVariables = Exact<{
  preview: Scalars["Boolean"];
  locale?: InputMaybe<Scalars["String"]>;
  placement: Scalars["String"];
}>;

export type LoadContentfulBannersQuery = {
  __typename?: "Query";
  contentfulBannerPlacementCollection: {
    __typename?: "ContentfulBannerPlacementCollection";
    total: number;
    items: Array<{
      __typename?: "ContentfulBannerPlacement";
      name: string | null;
      bannersCollection: {
        __typename?: "ContentfulBannerPlacementBannersCollection";
        total: number;
        items: Array<{
          __typename?: "ContentfulBanner";
          name: string | null;
          title: string | null;
          buttonText: string | null;
          url: string | null;
          campaign: {
            __typename?: "ContentfulCampaign";
            name: string | null;
            startAt: any | null;
            endAt: any | null;
            schoolingApproaches: Array<string | null> | null;
            minAge: number | null;
            maxAge: number | null;
            onlyForTreatmentInExperiment: string | null;
            countryCollection: {
              __typename?: "ContentfulCampaignCountryCollection";
              items: Array<{
                __typename?: "ContentfulCampaignCountry";
                name: string | null;
                code: string | null;
                matchingText: Array<string | null> | null;
              } | null>;
            } | null;
            stateOrProvinceCollection: {
              __typename?: "ContentfulCampaignStateOrProvinceCollection";
              items: Array<{
                __typename?: "ContentfulCampaignStateOrProvince";
                name: string | null;
                code: string | null;
              } | null>;
            } | null;
          } | null;
          content: { __typename?: "ContentfulBannerContent"; json: any } | null;
          image: {
            __typename?: "ContentfulAsset";
            title: string | null;
            description: string | null;
            url: string | null;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type CampaignFragment = {
  __typename?: "ContentfulCampaign";
  name: string | null;
  startAt: any | null;
  endAt: any | null;
  schoolingApproaches: Array<string | null> | null;
  minAge: number | null;
  maxAge: number | null;
  onlyForTreatmentInExperiment: string | null;
  countryCollection: {
    __typename?: "ContentfulCampaignCountryCollection";
    items: Array<{
      __typename?: "ContentfulCampaignCountry";
      name: string | null;
      code: string | null;
      matchingText: Array<string | null> | null;
    } | null>;
  } | null;
  stateOrProvinceCollection: {
    __typename?: "ContentfulCampaignStateOrProvinceCollection";
    items: Array<{
      __typename?: "ContentfulCampaignStateOrProvince";
      name: string | null;
      code: string | null;
    } | null>;
  } | null;
};

export type ContentfulBasicPageQueryVariables = Exact<{
  slug: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview: Scalars["Boolean"];
}>;

export type ContentfulBasicPageQuery = {
  __typename?: "Query";
  contentfulBasicPageCollection: {
    __typename?: "ContentfulBasicPageCollection";
    items: Array<{
      __typename?: "ContentfulBasicPage";
      title: string | null;
      publishedAt: any | null;
      headerImage: {
        __typename?: "ContentfulAsset";
        url: string | null;
        width: number | null;
        height: number | null;
      } | null;
      body: {
        __typename?: "ContentfulBasicPageBody";
        json: any;
        links: {
          __typename?: "ContentfulBasicPageBodyLinks";
          assets: {
            __typename?: "ContentfulBasicPageBodyAssets";
            block: Array<{
              __typename?: "ContentfulAsset";
              url: string | null;
              title: string | null;
              width: number | null;
              height: number | null;
              description: string | null;
              sys: { __typename?: "ContentfulSys"; id: string };
            } | null>;
          };
        };
      } | null;
    } | null>;
  } | null;
};

export type ContentfulMagnetPageQueryVariables = Exact<{
  slug: Scalars["String"];
  skipRating: Scalars["Boolean"];
  locale: Scalars["String"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type ContentfulMagnetPageQuery = {
  __typename?: "Query";
  contentfulMagnetPageCollection: {
    __typename?: "ContentfulMagnetPageCollection";
    items: Array<{
      __typename?: "ContentfulMagnetPage";
      title: string | null;
      magnetPageSlug: string | null;
      categoryLabel: {
        __typename?: "ContentfulCategoryLabel";
        value: string | null;
      } | null;
      author: {
        __typename?: "ContentfulAuthor";
        uid: string | null;
        name: string | null;
        description: string | null;
        isTeacher: boolean | null;
        profileImage: {
          __typename?: "ContentfulAsset";
          url: string | null;
        } | null;
      } | null;
      summary: { __typename?: "ContentfulMagnetPageSummary"; json: any } | null;
      headerImage: {
        __typename?: "ContentfulAsset";
        url: string | null;
      } | null;
      upsellClassCarousel: {
        __typename?: "ContentfulUpsellClassCard";
        listings: {
          __typename?: "SearchListingsConnection";
          listings: Array<{
            __typename?: "Listing";
            insertionId: string | null;
            sectionCount: number;
            openSectionCount: number;
            upcomingSectionCount: number;
            leader: {
              __typename?: "Leader";
              uid: string;
              name: string | null;
              photo: string | null;
              leader_link: string | null;
              averageActivityStarRating: number | null;
              reviewCount: number;
              video: {
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                  fileSize: number | null;
                };
              } | null;
              credentials: Array<{
                __typename?: "TeacherCredential";
                uid: string;
                degreeType: DegreeType | null;
                degreeSubject: DegreeSubject | null;
              }>;
            };
            activity: {
              __typename?: "Activity";
              uid: string;
              averageStarRating?: number | null;
              reviewCount: number;
              age_min: number | null;
              age_max: number | null;
              duration_minutes: number | null;
              duration_weeks: number | null;
              weekly_meetings: number | null;
              price_cents: number | null;
              isClub: boolean;
              isFlexSchedule: boolean;
              isSelfPaced: boolean;
              isOneTime: boolean;
              hasTeacherSchedule: boolean;
              size_max: number | null;
              user_uid: string;
              slug_id: string;
              title: string;
              titleTranslated: string | null;
              url: string;
              path: string;
              summary: string | null;
              summaryTranslated: string | null;
              is_ongoing_weekly: boolean;
              subject: string | null;
              recentReviews?: Array<{
                __typename?: "PublicReview";
                uid: string;
                activity_star_rating: number | null;
                details: {
                  __typename?: "PublicReviewDetails";
                  publicReview: string | null;
                } | null;
                parent: {
                  __typename?: "Parent";
                  uid: string;
                  publicName: string | null;
                };
              }>;
              clubSection: {
                __typename?: "Section";
                filledSpaceCount: number;
                clubPostCount: number;
              } | null;
              details: { __typename?: "ActivityDetails"; photo: string | null };
              savedClassLists: Array<{
                __typename?: "SavedClassList";
                uid: string;
                lastAddedToAt: any | null;
              }>;
            };
            nextSection: {
              __typename?: "Section";
              uid: string;
              size_max: number | null;
              filledSpaceCount: number;
              start_time: any | null;
              end_time: any | null;
            } | null;
          }>;
        };
      } | null;
      body: {
        __typename?: "ContentfulMagnetPageBody";
        json: any;
        links: {
          __typename?: "ContentfulMagnetPageBodyLinks";
          assets: {
            __typename?: "ContentfulMagnetPageBodyAssets";
            block: Array<{
              __typename?: "ContentfulAsset";
              url: string | null;
              title: string | null;
              width: number | null;
              height: number | null;
              description: string | null;
              sys: { __typename?: "ContentfulSys"; id: string };
            } | null>;
          };
        };
      } | null;
      category: {
        __typename?: "CategoryPage";
        nameTitleCased: string;
        slug: string;
        related: Array<{
          __typename?: "CategoryPage";
          nameTitleCased: string;
          slug: string;
        } | null> | null;
        siblings: Array<{
          __typename?: "CategoryPage";
          nameTitleCased: string;
          slug: string;
        } | null> | null;
        children: Array<{
          __typename?: "CategoryPage";
          nameTitleCased: string;
          slug: string;
        } | null> | null;
      };
      relatedMagnetPages: {
        __typename?: "ContentfulMagnetPageCollection";
        items: Array<{
          __typename?: "ContentfulMagnetPage";
          magnetPageSlug: string | null;
          title: string | null;
          categoryLabel: {
            __typename?: "ContentfulCategoryLabel";
            value: string | null;
          } | null;
          author: {
            __typename?: "ContentfulAuthor";
            name: string | null;
            profileImage: {
              __typename?: "ContentfulAsset";
              url: string | null;
            } | null;
          } | null;
          headerImage: {
            __typename?: "ContentfulAsset";
            url: string | null;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type ActivityEnrollableTimes_SsrQueryVariables = Exact<{
  uid: Scalars["ID"];
  esaState?: InputMaybe<Scalars["String"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  endBy?: InputMaybe<Scalars["DateTime"]>;
  filterByDow?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]>> | InputMaybe<Scalars["Int"]>
  >;
  recurringOnly?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ActivityEnrollableTimes_SsrQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    hasTeacherSchedule: boolean;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    esaStatus: EsaStatus;
    is_ongoing_weekly: boolean;
    isClub: boolean;
    isSelfPaced: boolean;
    duration_weeks: number | null;
    enrollableTimes: Array<{
      __typename?: "EnrollableTime";
      startTime: any;
      endTime: any;
      teacher: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        hasBackgroundCheckExpired: boolean | null;
        teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      };
    }>;
  };
};

export type PopularListingsSsrDevHomepageQueryVariables = Exact<{
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type PopularListingsSsrDevHomepageQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    popularListings: Array<{
      __typename: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        hasTeacherSchedule: boolean;
        size_max: number | null;
        user_uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
      } | null;
    }> | null;
  };
};

export type ExploreMoreCategoryPagesQueryVariables = Exact<{
  taxonomy?: InputMaybe<Scalars["String"]>;
}>;

export type ExploreMoreCategoryPagesQuery = {
  __typename?: "Query";
  topLevelCategoryPages: Array<{
    __typename?: "CategoryPage";
    uid: string;
    nameTitleCased: string;
    slug: string;
    basePath: string;
  } | null> | null;
};

export type ExperimentOverridesQueryVariables = Exact<{ [key: string]: never }>;

export type ExperimentOverridesQuery = {
  __typename?: "Query";
  experimentOverrides: Array<{
    __typename?: "ExperimentOverride";
    name: string;
    variants: Array<string>;
  }>;
};

export type ClassRequestQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type ClassRequestQuery = {
  __typename?: "Query";
  classRequest: {
    __typename?: "ClassRequest";
    uid: string;
    age: number;
    content: string;
  } | null;
};

export type CreateActivityMutationVariables = Exact<{
  title: Scalars["String"];
  classRequestUid?: InputMaybe<Scalars["ID"]>;
  attribution?: InputMaybe<Attribution>;
  activityType?: InputMaybe<CreateActivityType>;
  classroomType?: InputMaybe<ClassroomType>;
  format?: InputMaybe<EducatorSelectedFormat>;
}>;

export type CreateActivityMutation = {
  __typename?: "Mutation";
  createActivity: {
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    user_uid: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    learningGoals: Array<string>;
    assessment: string | null;
    homework: string | null;
    grading_policy: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    english_proficiency_level: EnglishProficiencyLevel | null;
    grade_level_min: string | null;
    grade_level_max: string | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    priceCredits: number | null;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    allowWaitlist: boolean | null;
    isClub: boolean;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    allows_recurring_payment: boolean | null;
    allows_late_enrollments_for_fixed_length_classes: boolean;
    has_building_content: boolean | null;
    hasTeacherSchedule: boolean;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isOneTime: boolean;
    classFormat: EducatorSelectedFormat;
    classroomType: ClassroomType;
    saveCount: number | null;
    archived_at: any | null;
    upsell_activity_uid: string | null;
    why_me: string | null;
    parental_guidance: string | null;
    sources: string | null;
    materials: string | null;
    hasSections: boolean;
    has_external_resources: boolean | null;
    attributes: Array<{
      __typename?: "EntityAttribute";
      key: string;
      value: string;
      entity_type: string;
    }>;
    strictAgeRange: {
      __typename?: "AgeRangeType";
      min: number;
      max: number;
    } | null;
    proficiency_level: {
      __typename?: "ProficiencyLevel";
      level: Array<string | null> | null;
      text: string | null;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      description: string;
      type: RefundPolicyType;
    };
    details: {
      __typename?: "ActivityDetails";
      photo: string | null;
      photoSquare: string | null;
      asyncTime: string | null;
    };
    leaderDetails: {
      __typename?: "ActivityLeaderDetails";
      uid: string;
      requestedListingAt: any | null;
      welcomeMessage: {
        __typename?: "WelcomeMessage";
        text: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        }> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        } | null;
      } | null;
    };
    externalResources: Array<{
      __typename?: "ExternalResource";
      uid: string;
      name: string;
      url: string | null;
    }>;
  };
};

export type CreateSyllabusMutationVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type CreateSyllabusMutation = {
  __typename?: "Mutation";
  createSyllabus: { __typename?: "Syllabus"; uid: string };
};

export type DeleteSyllabusMutationVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type DeleteSyllabusMutation = {
  __typename?: "Mutation";
  deleteSyllabus: { __typename?: "Syllabus"; uid: string };
};

export type DeleteSectionsMutationVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type DeleteSectionsMutation = {
  __typename?: "Mutation";
  deleteSections: {
    __typename?: "Activity";
    uid: string;
    hasSections: boolean;
  };
};

export type FormatEditModalActiveSectionsQueryVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type FormatEditModalActiveSectionsQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    enrollmentCount: number;
    paginatedFilteredSections: {
      __typename?: "SectionList";
      totalCount: number;
      data: Array<{
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        filledSpaceCount: number;
        meetings: Array<{ __typename?: "Meeting"; uid: string; end_time: any }>;
      }>;
    };
  };
};

export type ApprovedExternalResourcesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ApprovedExternalResourcesQuery = {
  __typename?: "Query";
  approvedExternalResources: Array<{
    __typename?: "ExternalResource";
    name: string;
    uid: string;
    url: string | null;
    category: string | null;
  } | null>;
};

export type DeleteExternalResourceMutationVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type DeleteExternalResourceMutation = {
  __typename?: "Mutation";
  deleteExternalResource: boolean;
};

export type ActivityExternalResourcesQueryVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type ActivityExternalResourcesQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    age_min: number | null;
    externalResources: Array<{
      __typename?: "ExternalResource";
      uid: string;
      name: string;
      url: string | null;
      policy_type: ExternalResourcePolicy | null;
    }>;
  };
};

export type IsExternalResourceAllowedQueryVariables = Exact<{
  resourceUrl: Scalars["String"];
}>;

export type IsExternalResourceAllowedQuery = {
  __typename?: "Query";
  policyForExternalResource: ExternalResourcePolicy | null;
};

export type AddExternalResource2MutationVariables = Exact<{
  activityUid: Scalars["ID"];
  input: ExternalResourceInput;
}>;

export type AddExternalResource2Mutation = {
  __typename?: "Mutation";
  addExternalResource: { __typename?: "ExternalResource"; uid: string };
};

export type ActivityStatusLabel_ActivityFragment = {
  __typename?: "Activity";
  archived_at: any | null;
  published_at: any | null;
  requested_listing_at: any | null;
  approvalStatus: {
    __typename?: "ActivityApprovalStatus";
    action: string;
    message_to_teacher: string | null;
  } | null;
};

export type CreateNewBundleMutationVariables = Exact<{
  createBundleInput: CreateBundleInput;
}>;

export type CreateNewBundleMutation = {
  __typename?: "Mutation";
  createNewBundle: { __typename?: "Bundle"; uid: string };
};

export type ActivityForCalendarFragmentFragment = {
  __typename?: "Activity";
  uid: string;
  slug_id: string;
  published_at: any | null;
  title: string;
  titleTranslated: string | null;
  url: string;
  path: string;
  hasTeacherSchedule: boolean;
  isClub: boolean;
  isFlexSchedule: boolean;
  isSelfPaced: boolean;
  isOneTime: boolean;
  duration_minutes: number | null;
  duration_weeks: number | null;
  weekly_meetings: number | null;
  is_ongoing_weekly: boolean;
  size_min: number | null;
  size_max: number | null;
  details: { __typename?: "ActivityDetails"; photo: string | null };
};

export type ResendChildEmailConfirmationMutationVariables = Exact<{
  childUid: Scalars["ID"];
}>;

export type ResendChildEmailConfirmationMutation = {
  __typename?: "Mutation";
  resendChildEmailConfirmation: {
    __typename?: "Child";
    uid: string;
    name: string | null;
    age: number | null;
    birthMonth: number | null;
    date_of_birth: any | null;
    birthYear: number | null;
    email: string | null;
    avatar: string | null;
    unlockedAvatars: Array<string>;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    email_confirmed_at: any | null;
    optedOutOfPersonalizedRecommendationsAt: any | null;
    isLoginEnabled: boolean;
    username: string | null;
    hasPassword: boolean;
    attributes: Array<{
      __typename?: "EntityAttribute";
      uid: string;
      key: string;
      value: string;
    }>;
    entityAvailabilitySchedule: {
      __typename?: "EntityAvailabilitySchedule";
      entityAvailabilities: Array<{
        __typename?: "EntityAvailability";
        startHour: number;
        startMinute: number;
        day: number;
        endHour: number;
        endMinute: number;
        uid: string;
      }>;
    } | null;
  };
};

export type EnrollmentStatusChip_EnrollmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  isPaused: boolean;
  isEnded: boolean;
  ongoingEndTime: any | null;
  withdrawnAt: any | null;
  isWithdrawn: boolean;
  pauseWindow: {
    __typename?: "EnrollmentPauseWindow";
    uid: string;
    pauseReason: string;
  } | null;
};

export type EnrollmentStatusChip_SectionFragment = {
  __typename?: "Section";
  uid: string;
  is_self_paced: boolean;
  end_time: any | null;
  start_time: any | null;
  deleted_at: any | null;
  canceled_at: any | null;
  published_at: any | null;
};

export type CreateClassRequestMutationVariables = Exact<{
  age: Scalars["Int"];
  content: Scalars["String"];
}>;

export type CreateClassRequestMutation = {
  __typename?: "Mutation";
  createClassRequest: {
    __typename?: "ClassRequest";
    uid: string;
    age: number;
    content: string;
  } | null;
};

export type ClassScheduleRequest_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  slug_id: string;
  title: string;
  titleTranslated: string | null;
};

export type LearnerScheduleEventsQueryVariables = Exact<{
  start: Scalars["DateTime"];
  limit?: InputMaybe<Scalars["Int"]>;
  learnerUid: Scalars["ID"];
}>;

export type LearnerScheduleEventsQuery = {
  __typename?: "Query";
  learnerScheduleEvents: {
    __typename?: "ScheduleEvents";
    events: Array<{ __typename?: "ScheduleEvent"; uid: string }>;
  };
};

export type EnrollmentManagerCard_EnrollmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  isPaused: boolean;
  isEnded: boolean;
  ongoingEndTime: any | null;
  withdrawnAt: any | null;
  isWithdrawn: boolean;
  learner: {
    __typename?: "Learner";
    uid: string;
    avatar: string | null;
    name: string | null;
    age: number | null;
    isBlocked: boolean | null;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      timeZone: string | null;
    };
  } | null;
  pauseWindow: {
    __typename?: "EnrollmentPauseWindow";
    uid: string;
    pauseReason: string;
  } | null;
};

export type EnrollmentManagerCard_SectionFragment = {
  __typename?: "Section";
  uid: string;
  is_self_paced: boolean;
  end_time: any | null;
  start_time: any | null;
  deleted_at: any | null;
  canceled_at: any | null;
  published_at: any | null;
  leader: { __typename?: "Leader"; uid: string };
  activity: {
    __typename?: "Activity";
    hasTeacherSchedule: boolean;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
  };
};

export type MeetingRescheduleRequestBanner_MeetingsFragment = {
  __typename: "Meeting";
  uid: string;
  start_time: any;
  end_time: any;
  rescheduleRequests: Array<{
    __typename: "MeetingRequest";
    uid: string;
    created_at: any;
    expires_at: any;
    start_time: any;
    end_time: any;
    status: MeetingRequestStatus;
    message: string | null;
    is_recurring: boolean;
  }>;
};

export type AcceptMeetingRescheduleMutationVariables = Exact<{
  requestUid: Scalars["ID"];
}>;

export type AcceptMeetingRescheduleMutation = {
  __typename?: "Mutation";
  acceptMeetingReschedule: Array<{
    __typename: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
    rescheduleRequests: Array<{
      __typename: "MeetingRequest";
      uid: string;
      created_at: any;
      expires_at: any;
      start_time: any;
      end_time: any;
      status: MeetingRequestStatus;
      message: string | null;
      is_recurring: boolean;
    }>;
  }>;
};

export type NewMeetingRequestBanner_MeetingRequestsFragment = {
  __typename: "MeetingRequest";
  uid: string;
  created_at: any;
  start_time: any;
  end_time: any;
  expires_at: any;
  status: MeetingRequestStatus;
  is_recurring: boolean;
};

export type NewMeetingRequestBanner_EnrollmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  price_cents: number | null;
  section: {
    __typename?: "Section";
    uid: string;
    leader: {
      __typename?: "Leader";
      uid: string;
      teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    };
  } | null;
};

export type DeclineMeetingRequestsMutationVariables = Exact<{
  requestUids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type DeclineMeetingRequestsMutation = {
  __typename?: "Mutation";
  declineMeetingRequests: Array<{
    __typename: "MeetingRequest";
    uid: string;
    created_at: any;
    start_time: any;
    end_time: any;
    expires_at: any;
    status: MeetingRequestStatus;
    is_recurring: boolean;
  }>;
};

export type OneOnOneRescheduleModal_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  hasTeacherSchedule: boolean;
  price_cents: number | null;
  esaStatus: EsaStatus;
  is_ongoing_weekly: boolean;
  isClub: boolean;
  title: string;
  titleTranslated: string | null;
  weekly_meetings: number | null;
  duration_weeks: number | null;
  isFlexSchedule: boolean;
  isSelfPaced: boolean;
  published_at: any | null;
  size_max: number | null;
  allows_recurring_payment: boolean | null;
  enrollableTimes: Array<{
    __typename?: "EnrollableTime";
    startTime: any;
    endTime: any;
    teacher: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      hasBackgroundCheckExpired: boolean | null;
      teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    };
  }>;
  details: { __typename?: "ActivityDetails"; photo: string | null };
  refundPolicy: {
    __typename?: "RefundPolicy";
    name: string;
    description: string;
    type: RefundPolicyType;
  };
};

export type OneOnOneUpcomingMeetings_MeetingsFragment = {
  __typename?: "Meeting";
  uid: string;
  start_time: any;
  end_time: any;
  created_at: any | null;
  deleted_at: any | null;
  paidAt: any | null;
  oneOnOneTenderType: Array<TenderType> | null;
  canParentUpdate: boolean | null;
  billedOn: any;
  recurring: boolean | null;
  refundableAmountCents: number;
  refundableAmountCredits: number | null;
  recurringSchedule: {
    __typename?: "RecurringSchedule";
    uid: string;
    end_at: any | null;
  } | null;
};

export type OneOnOneUpcomingMeetings_PendingMeetingsFragment = {
  __typename?: "EnrollmentMeetingPurchase";
  startTime: any;
  endTime: any;
  uid: string;
  activityUid: string | null;
  enrollmentUid: string | null;
  meetingUid: string | null;
  orderSlugId: string;
  confirmedAt: any | null;
};

export type OneOnOneUpcomingMeetings_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  slug_id: string;
  hasTeacherSchedule: boolean;
  price_cents: number | null;
  esaStatus: EsaStatus;
  is_ongoing_weekly: boolean;
  isClub: boolean;
  title: string;
  titleTranslated: string | null;
  weekly_meetings: number | null;
  duration_weeks: number | null;
  isFlexSchedule: boolean;
  isSelfPaced: boolean;
  published_at: any | null;
  size_max: number | null;
  allows_recurring_payment: boolean | null;
  refundPolicy: {
    __typename?: "RefundPolicy";
    name: string;
    description: string;
    type: RefundPolicyType;
    creditDescription: string | null;
  };
  enrollableTimes: Array<{
    __typename?: "EnrollableTime";
    startTime: any;
    endTime: any;
    teacher: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      hasBackgroundCheckExpired: boolean | null;
      teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    };
  }>;
  details: { __typename?: "ActivityDetails"; photo: string | null };
};

export type TeacherCard_LeaderFragment = {
  __typename?: "Leader";
  uid: string;
  leader_link: string | null;
  name: string | null;
  photo: string | null;
  timeZone: string | null;
  details: { __typename?: "LeaderDetails"; headline: string | null };
};

export type TeacherCard_SectionFragment = {
  __typename?: "Section";
  uid: string;
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    leader: {
      __typename?: "Leader";
      uid: string;
      leader_link: string | null;
      name: string | null;
      photo: string | null;
      timeZone: string | null;
      details: { __typename?: "LeaderDetails"; headline: string | null };
    };
  };
  leader: {
    __typename?: "Leader";
    uid: string;
    leader_link: string | null;
    name: string | null;
    photo: string | null;
    timeZone: string | null;
    details: { __typename?: "LeaderDetails"; headline: string | null };
  };
};

export type EnrollmentAdminRow_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  is_ongoing_weekly: boolean;
  isClub: boolean;
};

export type EnrollmentAdminRow_EnrollmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  ongoingStartTime: any | null;
  ongoing_start_time: any | null;
  ongoingEndTime: any | null;
  ongoing_end_time: any | null;
  ongoingStoppedAt: any | null;
  ongoing_stopped_at: any | null;
  orderSlugId: string | null;
  isEnded: boolean;
  isPaused: boolean;
  isPrepay: boolean;
  created_at: any | null;
  createdAt: any;
  withdrawn_at: any | null;
  withdrawnAt: any | null;
  confirmed_at: any | null;
  confirmedAt: any | null;
  currency_code: CurrencyCode;
  exchange_rate: number;
  refundableAmountCents: number;
  hasPaymentSchedule: boolean;
  isPendingEsaOrder: boolean;
  learner: { __typename?: "Learner"; uid: string } | null;
  parent: { __typename?: "Parent"; uid: string; name: string | null };
  payments: Array<{
    __typename?: "EnrollmentPayment";
    uid: string;
    paid_at: any | null;
    purchase_order_id: string | null;
    esa_order_id: string | null;
  }>;
  refundPolicy: { __typename?: "RefundPolicy"; name: string } | null;
  details: { __typename?: "EnrollmentDetails"; message: string | null };
  lastSectionTransfer: {
    __typename?: "SectionTransfer";
    timestamp: any;
    fromSection: {
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
    } | null;
  } | null;
  ongoingSectionTransferredFrom: {
    __typename?: "SectionTransfer";
    timestamp: any;
    fromSection: {
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
    } | null;
  } | null;
  fundingProgramInvoice: {
    __typename?: "FundingProgramInvoice";
    uid: string;
    awardId: string;
  } | null;
};

export type EnrollmentAdminRow_GiftEnrollmentFragment = {
  __typename?: "GiftEnrollment";
  uid: string;
  isPaused: boolean;
  isEnded: boolean;
  giftClaimedAt: any | null;
  createdAt: any;
  confirmedAt: any | null;
  withdrawnAt: any | null;
  withdrawn_at: any | null;
  ongoingStoppedAt: any | null;
  ongoingStartTime: any | null;
  ongoingEndTime: any | null;
  ongoing_stopped_at: any | null;
  currency_code: CurrencyCode;
  exchange_rate: number;
  amount_in_local_currency: number;
  ongoing_start_time: any | null;
  ongoing_end_time: any | null;
  hasPaymentSchedule: boolean;
  orderSlugId: string | null;
  canBeWithdrawn: boolean;
  canBeStopped: boolean;
  canBePaused: boolean;
  priceCents: number | null;
  currentUserIsPurchaser: boolean;
  refundableAmountCents: number;
  lastSectionTransfer: {
    __typename?: "SectionTransfer";
    timestamp: any;
    fromSection: {
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
    } | null;
  } | null;
  ongoingSectionTransferredFrom: {
    __typename?: "SectionTransfer";
    timestamp: any;
    fromSection: {
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
    } | null;
  } | null;
  parent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  } | null;
  learner: {
    __typename?: "Learner";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    isVerified: boolean;
    isBlocked: boolean | null;
    blockedByUserUid: string | null;
    blockedAt: any | null;
  } | null;
  buyer: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  };
  pricingOffer: {
    __typename?: "PricingOffer";
    name: string;
    buyerOrgName: string | null;
    isFinancialAid: boolean;
  } | null;
  details: {
    __typename?: "EnrollmentDetails";
    name: string | null;
    age: string | null;
    attendedAt: any | null;
    message: string | null;
  };
  payments: Array<{
    __typename?: "EnrollmentPayment";
    uid: string;
    amount_cents: number | null;
    order_slug_id: string | null;
    stripe_charge_id: string | null;
    purchase_order_id: string | null;
    esa_order_id: string | null;
    intercom_url: string | null;
    last4: string | null;
    created_at: any | null;
    paid_at: any | null;
    disbursed_at: any | null;
    payout_uid: string | null;
    start_time: any | null;
    end_time: any | null;
    unrefundedCents: number;
    isOngoingRefundable: boolean;
    voucher_name: string | null;
    pricing_offer_uid: string | null;
    credit_transaction_uid: string | null;
    coupon_code_uid: string | null;
    currency_code: CurrencyCode | null;
    exchange_rate: number | null;
    invoice_uid: string | null;
    funding_program_invoice_uid: string | null;
    service_fee_percentage: number | null;
    couponCode: {
      __typename?: "CouponCode";
      code: string;
      coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
  }>;
  refundPolicy: {
    __typename?: "RefundPolicy";
    type: RefundPolicyType;
    name: string;
    description: string;
  } | null;
  paymentSchedule: {
    __typename?: "PaymentSchedule";
    uid: string;
    stoppedAt: any | null;
    endTime: any | null;
  } | null;
  pauseWindow: {
    __typename?: "EnrollmentPauseWindow";
    uid: string;
    startDate: any;
    endDate: any;
    canBeCanceled: boolean;
    pauseReason: string;
  } | null;
};

export type EnrollmentAdminRow_SectionFragment = {
  __typename?: "Section";
  uid: string;
  start_time: any | null;
  end_time: any | null;
  leader: { __typename?: "Leader"; name: string | null };
  activity: {
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    subject: string | null;
    title: string;
    titleTranslated: string | null;
    price_cents: number | null;
    isClub: boolean;
    duration_weeks: number | null;
    is_ongoing_weekly: boolean;
    weekly_meetings: number | null;
    details: { __typename?: "ActivityDetails"; photo: string | null };
  };
};

export type EnrollmentAdminRow_EnrollingParentFragment = {
  __typename?: "Parent";
  uid: string;
};

export type EnrollmentAdminRow_PaymentFragment = {
  __typename?: "EnrollmentPayment";
  uid: string;
  amount_cents: number | null;
  amount_credits: number | null;
  order_slug_id: string | null;
  intercom_url: string | null;
  paid_at: any | null;
  disbursed_at: any | null;
  payout_uid: string | null;
  start_time: any | null;
  end_time: any | null;
  unrefundedCents: number;
  unrefundedCredits: number | null;
  voucher_name: string | null;
  last4: string | null;
  stripe_charge_id: string | null;
  purchase_order_id: string | null;
  credit_transaction_uid: string | null;
  credit_account_type: string | null;
  coupon_code_uid: string | null;
  pricing_offer_uid: string | null;
  esa_order_id: string | null;
  invoice_uid: string | null;
  currency_code: CurrencyCode | null;
  exchange_rate: number | null;
  funding_program_invoice_uid: string | null;
  service_fee_percentage: number | null;
  oneOnOneMeeting: {
    __typename?: "Meeting";
    uid: string;
    start_time: any;
  } | null;
  organization: {
    __typename?: "Organization";
    uid: string;
    name: string;
    state: string | null;
    logoUrl: string | null;
    urlName: string | null;
  } | null;
  refunds: Array<{
    __typename?: "EnrollmentPaymentRefund";
    uid: string;
    user_uid: string;
    amount_cents: number;
    amount_credits: number | null;
    refunded_at: any | null;
    reason: string | null;
    user_role: string | null;
    note: string | null;
    reduces_teacher_payout: boolean;
    amount_in_local_currency: number | null;
    currency_code: CurrencyCode | null;
    exchange_rate: number | null;
    addOutschoolCreditTransaction: {
      __typename?: "CreditTransaction";
      uid: string;
      amount_cents: number;
      expiresAt: any | null;
      unspentCents: number | null;
    } | null;
  }>;
  couponCode: {
    __typename?: "CouponCode";
    code: string;
    coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
  } | null;
};

export type SendMessageToUserMutationVariables = Exact<{
  messageInput: MessageInput;
  recipientUids: Array<Scalars["ID"]> | Scalars["ID"];
  previousMessageUid?: InputMaybe<Scalars["ID"]>;
}>;

export type SendMessageToUserMutation = {
  __typename?: "Mutation";
  sendMessage: {
    __typename?: "Message";
    uid: string;
    subject: string | null;
    action: string | null;
    content: string | null;
    created_at: any;
    activity_uid: string | null;
    section_uid: string | null;
    thread_uid: string;
    sender: {
      __typename?: "Sender";
      uid: string;
      name: string | null;
      photo: string | null;
    };
    participants: Array<{
      __typename?: "Participant";
      uid: string;
      name: string | null;
      photo: string | null;
      timeZone: string | null;
      headline: string | null;
      leader_link: string | null;
      isLearner: boolean;
      role: ParticipantRole | null;
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    }>;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
  };
};

export type EducatorSendMessageToUserMutationVariables = Exact<{
  messageInput: MessageInput;
  recipientUids: Array<Scalars["ID"]> | Scalars["ID"];
  previousMessageUid?: InputMaybe<Scalars["ID"]>;
}>;

export type EducatorSendMessageToUserMutation = {
  __typename?: "Mutation";
  sendMessage: {
    __typename?: "Message";
    uid: string;
    subject: string | null;
    action: string | null;
    content: string | null;
    created_at: any;
    activity_uid: string | null;
    section_uid: string | null;
    thread_uid: string;
    sender: {
      __typename?: "Sender";
      uid: string;
      name: string | null;
      photo: string | null;
    };
    participants: Array<{
      __typename?: "Participant";
      uid: string;
      name: string | null;
      photo: string | null;
      timeZone: string | null;
      headline: string | null;
      leader_link: string | null;
      isLearner: boolean;
      role: ParticipantRole | null;
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    }>;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
  };
};

export type SubscribeToCreationEmailsMutationVariables = Exact<{
  attribution?: InputMaybe<Attribution>;
}>;

export type SubscribeToCreationEmailsMutation = {
  __typename?: "Mutation";
  subscribeToCreationEmails: boolean;
};

export type OrganizationEditForm_OrganizationFragment = {
  __typename?: "Organization";
  deletedAt: any | null;
  name: string;
  type: OrganizationType;
  address: string | null;
  state: string | null;
  notes: string | null;
  urlName: string | null;
  logoUrl: string | null;
  invoiceEmail: string | null;
  techSupportEmail: string | null;
  trustSafetyEmail: string | null;
  totalCapCents: number | null;
};

export type SavedClassListsForParentProfileQueryVariables = Exact<{
  uid: Scalars["ID"];
  profileLinkUid?: InputMaybe<Scalars["String"]>;
  publicView?: InputMaybe<Scalars["Boolean"]>;
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type SavedClassListsForParentProfileQuery = {
  __typename?: "Query";
  savedClassListsForParentProfile: Array<{
    __typename?: "SavedClassList";
    uid: string;
    name: string;
    privacyStatus: SavedClassListStatus;
    listings: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        hasTeacherSchedule: boolean;
        size_max: number | null;
        user_uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
      } | null;
    } | null>;
  }>;
};

export type ReviewableSectionFragment = {
  __typename?: "ReviewableSection";
  activity_uid: string;
  section_uid: string;
  feedback_uid: string | null;
  can_review: boolean;
};

export type SectionsForReviewQueryVariables = Exact<{ [key: string]: never }>;

export type SectionsForReviewQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    enrolledSectionsThatNeedReview: Array<{
      __typename?: "ReviewableSection";
      activity_uid: string;
      section_uid: string;
      feedback_uid: string | null;
      can_review: boolean;
    } | null>;
  } | null;
};

export type ParentScheduleCalendar_EnrollmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  confirmedAt: any | null;
  withdrawnAt: any | null;
  ongoingStartTime: any | null;
  ongoingEndTime: any | null;
  learner: {
    __typename?: "Learner";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    created_at: any;
    isVerified: boolean;
  } | null;
  section: {
    __typename?: "Section";
    uid: string;
    start_time: any | null;
    end_time: any | null;
    meetingCount: number;
    deleted_at: any | null;
    published_at: any | null;
    canceled_at: any | null;
    filledSpaceCount: number;
    validEnrollmentCount: number;
    activity: {
      __typename?: "Activity";
      uid: string;
      slug_id: string;
      published_at: any | null;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      hasTeacherSchedule: boolean;
      isClub: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      is_ongoing_weekly: boolean;
      size_min: number | null;
      size_max: number | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
      };
      details: { __typename?: "ActivityDetails"; photo: string | null };
    };
    meetings: Array<{
      __typename?: "Meeting";
      sectionSequenceNumber: number;
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    }>;
  } | null;
  pauseWindow: {
    __typename?: "EnrollmentPauseWindow";
    uid: string;
    startDate: any;
    endDate: any;
  } | null;
};

export type RecentlyCompletedClassesQueryQueryVariables = Exact<{
  end?: InputMaybe<Scalars["DateTime"]>;
  learnerUid?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type RecentlyCompletedClassesQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    learners: Array<{
      __typename?: "Learner";
      uid: string;
      name: string | null;
    }>;
    enrollmentsForTranscript: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
      withdrawn_at: any | null;
      ongoing_start_time: any | null;
      ongoingEndTime: any | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        avatar: string | null;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        canceled_at: any | null;
        start_time: any | null;
        end_time: any | null;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          leader_link: string | null;
          photo: string | null;
        };
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          details: { __typename?: "ActivityDetails"; photo: string | null };
        };
        meetings: Array<{
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
        }>;
        currentUserPublicReview: {
          __typename?: "PublicReview";
          uid: string;
          currentUserCanEdit: boolean;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type ReferralsQueryVariables = Exact<{ [key: string]: never }>;

export type ReferralsQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    totalReferralEarnings: number;
    referredParents: Array<{
      __typename?: "ParentReferral";
      firstName: string;
      status: ParentReferralStatus;
    }>;
    referredTeachers: Array<{
      __typename?: "TeacherReferral";
      firstName: string;
      status: TeacherReferralStatus;
    }>;
  } | null;
};

export type RequestListingMutationVariables = Exact<{
  activityUid: Scalars["ID"];
  note?: InputMaybe<Scalars["String"]>;
}>;

export type RequestListingMutation = {
  __typename?: "Mutation";
  requestListing: {
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    user_uid: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    learningGoals: Array<string>;
    assessment: string | null;
    homework: string | null;
    grading_policy: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    english_proficiency_level: EnglishProficiencyLevel | null;
    grade_level_min: string | null;
    grade_level_max: string | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    priceCredits: number | null;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    allowWaitlist: boolean | null;
    isClub: boolean;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    allows_recurring_payment: boolean | null;
    allows_late_enrollments_for_fixed_length_classes: boolean;
    has_building_content: boolean | null;
    hasTeacherSchedule: boolean;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isOneTime: boolean;
    classFormat: EducatorSelectedFormat;
    classroomType: ClassroomType;
    strictAgeRange: {
      __typename?: "AgeRangeType";
      min: number;
      max: number;
    } | null;
    proficiency_level: {
      __typename?: "ProficiencyLevel";
      level: Array<string | null> | null;
      text: string | null;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      description: string;
      type: RefundPolicyType;
    };
    details: {
      __typename?: "ActivityDetails";
      photo: string | null;
      photoSquare: string | null;
    };
  };
};

export type ReviewReminderTopNoticeBarQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ReviewReminderTopNoticeBarQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    enrolledSectionThatNeedsReview: {
      __typename?: "Section";
      uid: string;
      leader: {
        __typename?: "Leader";
        uid: string;
        photo: string | null;
        name: string | null;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        slug_id: string;
        titleTranslated: string | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
      };
    } | null;
  } | null;
};

export type TeacherOnboardingStatsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TeacherOnboardingStatsQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    publicSectionCountIncludingUnpublished: number;
  } | null;
};

export type FilePreviewUrlQueryVariables = Exact<{
  uid: Scalars["String"];
}>;

export type FilePreviewUrlQuery = {
  __typename?: "Query";
  teacherCredentialFilePreviewURL: string;
};

export type TeacherEditProfileClassesListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TeacherEditProfileClassesListQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    activities: Array<{
      __typename?: "Activity";
      uid: string;
      title: string;
      hasTeacherSchedule: boolean;
    }>;
  } | null;
};

export type ClassesListQueryVariables = Exact<{ [key: string]: never }>;

export type ClassesListQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    activities: Array<{
      __typename?: "Activity";
      uid: string;
      slug_id: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      class_experience: string | null;
      learning_goals: string | null;
      learningGoals: Array<string>;
      assessment: string | null;
      homework: string | null;
      grading_policy: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      grade_level_min: string | null;
      grade_level_max: string | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      priceCredits: number | null;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      allowWaitlist: boolean | null;
      isClub: boolean;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      allows_recurring_payment: boolean | null;
      allows_late_enrollments_for_fixed_length_classes: boolean;
      has_building_content: boolean | null;
      hasTeacherSchedule: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      classFormat: EducatorSelectedFormat;
      classroomType: ClassroomType;
      saveCount: number | null;
      archived_at: any | null;
      upsell_activity_uid: string | null;
      why_me: string | null;
      parental_guidance: string | null;
      sources: string | null;
      materials: string | null;
      hasSections: boolean;
      has_external_resources: boolean | null;
      strictAgeRange: {
        __typename?: "AgeRangeType";
        min: number;
        max: number;
      } | null;
      proficiency_level: {
        __typename?: "ProficiencyLevel";
        level: Array<string | null> | null;
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
      };
      details: {
        __typename?: "ActivityDetails";
        photo: string | null;
        photoSquare: string | null;
        asyncTime: string | null;
      };
      leaderDetails: {
        __typename?: "ActivityLeaderDetails";
        uid: string;
        requestedListingAt: any | null;
        welcomeMessage: {
          __typename?: "WelcomeMessage";
          text: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
            };
          }> | null;
          video: {
            __typename?: "Attachment";
            uid: string;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
            };
          } | null;
        } | null;
      };
      externalResources: Array<{
        __typename?: "ExternalResource";
        uid: string;
        name: string;
        url: string | null;
      }>;
    }>;
  } | null;
};

export type GetTnsIncidentsQueryVariables = Exact<{
  userUid: Scalars["ID"];
  categoryOrActionFilter?: InputMaybe<Scalars["String"]>;
}>;

export type GetTnsIncidentsQuery = {
  __typename?: "Query";
  getTnsIncidentsSummary: {
    __typename?: "TnsIncidentsSummary";
    uid: string;
    totalStrikes: number;
    accountStatus: TnsAccountStatus;
    incidents: Array<{
      __typename?: "TnsIncident";
      uid: string;
      createdAt: any;
      strike: number;
      action: TnsAction;
      category: TnsActionCategory | null;
      notes: string | null;
      link: string | null;
      adminName: string;
      cumulativeStrikes: number;
    }>;
  };
};

export type GetEnrollmentPaymentRefundQueryVariables = Exact<{
  enrollmentPaymentRefundUid: Scalars["ID"];
}>;

export type GetEnrollmentPaymentRefundQuery = {
  __typename?: "Query";
  enrollmentPaymentRefund: {
    __typename?: "EnrollmentPaymentRefund";
    uid: string;
    reason: string | null;
    reduces_teacher_payout: boolean;
  };
};

export type UpdateEnrollmentPaymentRefundMutationMutationVariables = Exact<{
  userUid: Scalars["ID"];
  enrollmentPaymentRefundUid: Scalars["ID"];
  updates: EnrollmentPaymentRefundInput;
  adminUpdates: AdminEnrollmentPaymentRefundInput;
}>;

export type UpdateEnrollmentPaymentRefundMutationMutation = {
  __typename?: "Mutation";
  updateEnrollmentPaymentRefund: {
    __typename?: "EnrollmentPaymentRefund";
    uid: string;
    reduces_teacher_payout: boolean;
    reason: string | null;
  };
};

export type ParentWaitlistedClassesQueryVariables = Exact<{
  parentUid: Scalars["ID"];
}>;

export type ParentWaitlistedClassesQuery = {
  __typename?: "Query";
  waitlistSeatsForParent: Array<{
    __typename?: "WaitlistSeat";
    uid: string;
    enrollment: { __typename?: "Enrollment"; uid: string } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      avatar: string | null;
      age: number | null;
    };
    section: {
      __typename?: "Section";
      uid: string;
      canceled_at: any | null;
      deleted_at: any | null;
      end_time: any | null;
      price_cents: number | null;
      availableSpaces: number;
      published_at: any | null;
      start_time: any | null;
      waitlistSeatsCount: number | null;
      meetingDays: string | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        duration_weeks: number | null;
        size_max: number | null;
        hasTeacherSchedule: boolean;
        isClub: boolean;
        is_ongoing_weekly: boolean;
        price_cents: number | null;
        published_at: any | null;
        title: string;
        titleTranslated: string | null;
        slug_id: string;
        weekly_meetings: number | null;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          leader_link: string | null;
          timeZone: string | null;
        };
      };
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        timeZone: string | null;
      };
      meetings: Array<{
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
      }>;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
      } | null;
    };
  } | null>;
};

export type ChangeUserEmailMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type ChangeUserEmailMutation = {
  __typename?: "Mutation";
  changeCurrentUserEmail: {
    __typename?: "User";
    uid: string;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    latestLegalName: string | null;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    teacher_approved_at: any | null;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    roles: Array<string> | null;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    consented_to_coppa_notice_at: any | null;
    hasVerifiedIdentity: boolean | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    upgradablePackages: Array<number | null> | null;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    details: {
      __typename?: "UserDetails";
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    location: {
      __typename?: "Location";
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      timeZone: string | null;
      state: string | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
    } | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
    } | null;
  };
};

export type ChangeCurrentUserOtherEmailMutationVariables = Exact<{
  otherEmail: Scalars["String"];
}>;

export type ChangeCurrentUserOtherEmailMutation = {
  __typename?: "Mutation";
  changeCurrentUserOtherEmail: {
    __typename?: "User";
    uid: string;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    latestLegalName: string | null;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    teacher_approved_at: any | null;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    roles: Array<string> | null;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    consented_to_coppa_notice_at: any | null;
    hasVerifiedIdentity: boolean | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    upgradablePackages: Array<number | null> | null;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    details: {
      __typename?: "UserDetails";
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    location: {
      __typename?: "Location";
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      timeZone: string | null;
      state: string | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
    } | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
    } | null;
  };
};

export type DeleteCurrentUserOtherEmailMutationVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type DeleteCurrentUserOtherEmailMutation = {
  __typename?: "Mutation";
  deleteCurrentUserOtherEmail: {
    __typename?: "User";
    uid: string;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    latestLegalName: string | null;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    teacher_approved_at: any | null;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    roles: Array<string> | null;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    consented_to_coppa_notice_at: any | null;
    hasVerifiedIdentity: boolean | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    upgradablePackages: Array<number | null> | null;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    details: {
      __typename?: "UserDetails";
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    location: {
      __typename?: "Location";
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      timeZone: string | null;
      state: string | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
    } | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
    } | null;
  };
};

export type PaidContentfulCategoryPageQueryVariables = Exact<{
  categorySlug: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
}>;

export type PaidContentfulCategoryPageQuery = {
  __typename?: "Query";
  contentfulCategoryPageCollection: {
    __typename?: "ContentfulCategoryPageCollection";
    items: Array<{
      __typename?: "ContentfulCategoryPage";
      featuredClassesOverrideSlug: string | null;
      heroTitle: string | null;
      heroImage: { __typename?: "ContentfulAsset"; url: string | null } | null;
    } | null>;
  } | null;
};

export type PaidCategoryPageQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type PaidCategoryPageQuery = {
  __typename?: "Query";
  categoryPage: {
    __typename?: "CategoryPage";
    title: string | null;
    searchFilters: {
      __typename?: "SavedSearchFilters";
      adminTags: Array<string> | null;
      age: string | null;
      capacityMax: number | null;
      capacityMin: number | null;
      delivery: string | null;
      dow: string | null;
      enabledBooleanFilters: string | null;
      endBy: string | null;
      endByTime: number | null;
      format: string | null;
      fundingPrograms: Array<string> | null;
      hasFilledOutUniqueLearningNeeds: boolean | null;
      languageOfInstruction: string | null;
      multiTermQuery: Array<string> | null;
      order: string | null;
      pricePerMeetingMax: number | null;
      pricePerMeetingMin: number | null;
      q: string | null;
      startAfter: string | null;
      startAfterTime: number | null;
      startBefore: string | null;
      theme: string | null;
      time: string | null;
      timeOfDay: string | null;
      timeZone: string | null;
      uniqueLearningNeeds: string | null;
    } | null;
  } | null;
};

export type GetSectionsForCopyClassPostModalQueryQueryVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type GetSectionsForCopyClassPostModalQueryQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    sections: Array<{
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
      deleted_at: any | null;
      canceled_at: any | null;
      published_at: any | null;
      ongoing_stopped_at: any | null;
    }>;
  };
};

export type GetClassPostsBySectionForCopyClassPostModalQueryQueryVariables =
  Exact<{
    sectionUid: Scalars["ID"];
    plainText?: InputMaybe<Scalars["Boolean"]>;
  }>;

export type GetClassPostsBySectionForCopyClassPostModalQueryQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    posts: Array<{
      __typename?: "ClassPost";
      uid: string;
      isWelcomePost: boolean;
      publishAt: any;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    }>;
    unpublishedPosts: Array<{
      __typename?: "ClassPost";
      uid: string;
      isWelcomePost: boolean;
      publishAt: any;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    }>;
  } | null;
};

export type OrgAdminViewTeacherCard_SectionFragment = {
  __typename?: "Section";
  uid: string;
  activity: { __typename?: "Activity"; uid: string };
  leader: { __typename?: "Leader"; uid: string; name: string | null };
};

export type OrgAdminViewTeacherCard_EnrollmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  learner: { __typename?: "Learner"; uid: string; name: string | null } | null;
};

export type OneOnOneClassroomEducatorViewSidebarQueryVariables = Exact<{
  uid: Scalars["ID"];
  startAfter: Scalars["DateTime"];
}>;

export type OneOnOneClassroomEducatorViewSidebarQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    is_self_paced: boolean;
    end_time: any | null;
    start_time: any | null;
    deleted_at: any | null;
    canceled_at: any | null;
    published_at: any | null;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
    }>;
    enrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      isPaused: boolean;
      isEnded: boolean;
      ongoingEndTime: any | null;
      withdrawnAt: any | null;
      isWithdrawn: boolean;
      learner: {
        __typename?: "Learner";
        uid: string;
        avatar: string | null;
        name: string | null;
        age: number | null;
        isBlocked: boolean | null;
        parent: {
          __typename?: "Parent";
          uid: string;
          name: string | null;
          timeZone: string | null;
        };
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        pauseReason: string;
      } | null;
    }>;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
    activity: {
      __typename?: "Activity";
      hasTeacherSchedule: boolean;
      uid: string;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
    };
  } | null;
};

export type SellerOrgSectionReassignModal_SectionFragment = {
  __typename?: "Section";
  uid: string;
  leader: { __typename?: "Leader"; uid: string; name: string | null };
};

export type SellerOrgSectionReassignModal_SectionQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type SellerOrgSectionReassignModal_SectionQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
  } | null;
};

export type SellerOrgSectionReassignModal_UpdateSectionMutationVariables =
  Exact<{
    sectionUid: Scalars["ID"];
    updates?: InputMaybe<LeaderSectionInput>;
  }>;

export type SellerOrgSectionReassignModal_UpdateSectionMutation = {
  __typename?: "Mutation";
  updateSection: {
    __typename?: "Section";
    uid: string;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
  };
};

export type MessageThreadQueryQueryVariables = Exact<{
  threadUid: Scalars["ID"];
}>;

export type MessageThreadQueryQuery = {
  __typename?: "Query";
  messageThread: {
    __typename?: "MessageThread";
    uid: string;
    action: MessageThreadAction;
    isRead: boolean;
    isMarkedSpam: boolean;
    participants: Array<{
      __typename?: "Participant";
      uid: string;
      name: string | null;
      photo: string | null;
      timeZone: string | null;
      headline: string | null;
      leader_link: string | null;
      isLearner: boolean;
      role: ParticipantRole | null;
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    }>;
    activity: {
      __typename?: "Activity";
      uid: string;
      slug_id: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      class_experience: string | null;
      learning_goals: string | null;
      learningGoals: Array<string>;
      assessment: string | null;
      homework: string | null;
      grading_policy: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      grade_level_min: string | null;
      grade_level_max: string | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      priceCredits: number | null;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      allowWaitlist: boolean | null;
      isClub: boolean;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      allows_recurring_payment: boolean | null;
      allows_late_enrollments_for_fixed_length_classes: boolean;
      has_building_content: boolean | null;
      hasTeacherSchedule: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      classFormat: EducatorSelectedFormat;
      classroomType: ClassroomType;
      strictAgeRange: {
        __typename?: "AgeRangeType";
        min: number;
        max: number;
      } | null;
      proficiency_level: {
        __typename?: "ProficiencyLevel";
        level: Array<string | null> | null;
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
      };
      details: {
        __typename?: "ActivityDetails";
        photo: string | null;
        photoSquare: string | null;
      };
    } | null;
    section: {
      __typename?: "Section";
      filledSpaceCount: number;
      uid: string;
      activity_uid: string;
      start_time: any | null;
      end_time: any | null;
      startsAtNight: boolean | null;
      is_cross_listed: boolean;
      size_max: number | null;
      size_min: number | null;
      isPublished: boolean | null;
      price_cents: number | null;
      priceCredits: number | null;
      published_at: any | null;
      updated_at: any;
      canceled_at: any | null;
      deleted_at: any | null;
      ongoing_stopped_at: any | null;
      duration_minutes: number | null;
      usesOutschoolVideoChat: boolean;
      usersCanSendPrivateMessage: boolean;
      currentUserCanManage: boolean;
      isBuyable: boolean;
      allowsTeacherTransfer: boolean;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        deleted_at: any | null;
        created_at: any | null;
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      details: {
        __typename?: "SectionDetails";
        autoScheduledDraft: boolean | null;
      };
      prepaidOngoingPeriodOptions: Array<{
        __typename?: "Period";
        startOfIsoWeek: any;
        endOfIsoWeek: any;
      }> | null;
    } | null;
    messages: Array<{
      __typename?: "Message";
      uid: string;
      subject: string | null;
      action: string | null;
      content: string | null;
      created_at: any;
      activity_uid: string | null;
      section_uid: string | null;
      thread_uid: string;
      sender: {
        __typename?: "Sender";
        uid: string;
        name: string | null;
        photo: string | null;
      };
      participants: Array<{
        __typename?: "Participant";
        uid: string;
        name: string | null;
        photo: string | null;
        timeZone: string | null;
        headline: string | null;
        leader_link: string | null;
        isLearner: boolean;
        role: ParticipantRole | null;
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      }>;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      } | null;
    }>;
  };
};

export type MarkMessageThreadAsReadMutationVariables = Exact<{
  threadUid: Scalars["ID"];
}>;

export type MarkMessageThreadAsReadMutation = {
  __typename?: "Mutation";
  markMessageThreadAsRead: boolean;
};

export type MessageThreadOnlyMessagesQueryQueryVariables = Exact<{
  threadUid: Scalars["ID"];
}>;

export type MessageThreadOnlyMessagesQueryQuery = {
  __typename?: "Query";
  messageThread: {
    __typename?: "MessageThread";
    uid: string;
    messages: Array<{
      __typename?: "Message";
      uid: string;
      subject: string | null;
      action: string | null;
      content: string | null;
      created_at: any;
      activity_uid: string | null;
      section_uid: string | null;
      thread_uid: string;
      sender: {
        __typename?: "Sender";
        uid: string;
        name: string | null;
        photo: string | null;
      };
      participants: Array<{
        __typename?: "Participant";
        uid: string;
        name: string | null;
        photo: string | null;
        timeZone: string | null;
        headline: string | null;
        leader_link: string | null;
        isLearner: boolean;
        role: ParticipantRole | null;
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      }>;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      } | null;
    }>;
  };
};

export type MarkMessageThreadsAsUnreadMutationVariables = Exact<{
  threadUids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type MarkMessageThreadsAsUnreadMutation = {
  __typename?: "Mutation";
  markMessageThreadsAsUnread: boolean;
};

export type MarkPrivateClassMessageThreadsAsUnreadMutationVariables = Exact<{
  threadUids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type MarkPrivateClassMessageThreadsAsUnreadMutation = {
  __typename?: "Mutation";
  markPrivateClassMessageThreadsAsUnread: boolean;
};

export type MarkMessageThreadsAsSpamMutationVariables = Exact<{
  threadUids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type MarkMessageThreadsAsSpamMutation = {
  __typename?: "Mutation";
  markMessageThreadsAsSpam: boolean;
};

export type UnmarkMessageThreadsAsSpamMutationVariables = Exact<{
  threadUids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type UnmarkMessageThreadsAsSpamMutation = {
  __typename?: "Mutation";
  unmarkMessageThreadsAsSpam: boolean;
};

type MessageIsReadFragment_MessageThread_Fragment = {
  __typename: "MessageThread";
  isRead: boolean;
};

type MessageIsReadFragment_PrivateClassThread_Fragment = {
  __typename: "PrivateClassThread";
  isRead: boolean;
};

export type MessageIsReadFragmentFragment =
  | MessageIsReadFragment_MessageThread_Fragment
  | MessageIsReadFragment_PrivateClassThread_Fragment;

export type PrivateMessageThreadQueryQueryVariables = Exact<{
  threadUid: Scalars["ID"];
}>;

export type PrivateMessageThreadQueryQuery = {
  __typename?: "Query";
  privateMessageThread: {
    __typename?: "PrivateClassThread";
    uid: string;
    isRead: boolean;
    participants: Array<{
      __typename?: "Participant";
      uid: string;
      name: string | null;
      photo: string | null;
      timeZone: string | null;
      headline: string | null;
      leader_link: string | null;
      isLearner: boolean;
      role: ParticipantRole | null;
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    }>;
    activity: {
      __typename?: "Activity";
      uid: string;
      slug_id: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      class_experience: string | null;
      learning_goals: string | null;
      learningGoals: Array<string>;
      assessment: string | null;
      homework: string | null;
      grading_policy: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      grade_level_min: string | null;
      grade_level_max: string | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      priceCredits: number | null;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      allowWaitlist: boolean | null;
      isClub: boolean;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      allows_recurring_payment: boolean | null;
      allows_late_enrollments_for_fixed_length_classes: boolean;
      has_building_content: boolean | null;
      hasTeacherSchedule: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      classFormat: EducatorSelectedFormat;
      classroomType: ClassroomType;
      strictAgeRange: {
        __typename?: "AgeRangeType";
        min: number;
        max: number;
      } | null;
      proficiency_level: {
        __typename?: "ProficiencyLevel";
        level: Array<string | null> | null;
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
      };
      details: {
        __typename?: "ActivityDetails";
        photo: string | null;
        photoSquare: string | null;
      };
    } | null;
    section: {
      __typename?: "Section";
      filledSpaceCount: number;
      uid: string;
      activity_uid: string;
      start_time: any | null;
      end_time: any | null;
      startsAtNight: boolean | null;
      is_cross_listed: boolean;
      size_max: number | null;
      size_min: number | null;
      isPublished: boolean | null;
      price_cents: number | null;
      priceCredits: number | null;
      published_at: any | null;
      updated_at: any;
      canceled_at: any | null;
      deleted_at: any | null;
      ongoing_stopped_at: any | null;
      duration_minutes: number | null;
      usesOutschoolVideoChat: boolean;
      usersCanSendPrivateMessage: boolean;
      currentUserCanManage: boolean;
      isBuyable: boolean;
      allowsTeacherTransfer: boolean;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        deleted_at: any | null;
        created_at: any | null;
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      details: {
        __typename?: "SectionDetails";
        autoScheduledDraft: boolean | null;
      };
      prepaidOngoingPeriodOptions: Array<{
        __typename?: "Period";
        startOfIsoWeek: any;
        endOfIsoWeek: any;
      }> | null;
    } | null;
    enrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
      withdrawn_at: any | null;
      usersCanCommunicate: boolean;
    } | null;
    messages: Array<{
      __typename?: "PrivateClassMessage";
      sentAt: any;
      content: string | null;
      uid: string;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      participants: Array<{
        __typename?: "Participant";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      }>;
    }>;
  };
};

export type ReadReceiptQueryQueryVariables = Exact<{
  messageUid: Scalars["ID"];
  audience: Audience;
}>;

export type ReadReceiptQueryQuery = {
  __typename?: "Query";
  isMessageRead: boolean;
};

export type UserDetailsQueryVariables = Exact<{
  userOneUid: Scalars["ID"];
  userTwoUid: Scalars["ID"];
}>;

export type UserDetailsQuery = {
  __typename?: "Query";
  userOneAsParent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    timeZone: string | null;
    enrollmentsWithTeacher: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
      withdrawn_at: any | null;
      ongoing_stopped_at: any | null;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      isEnded: boolean;
      isPaused: boolean;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
          isClub: boolean;
          published_at: any | null;
          is_ongoing_weekly: boolean;
          weekly_meetings: number | null;
          duration_weeks: number | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
        timeZone: string;
        privateNote: string | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
      } | null;
    }>;
  } | null;
  userTwoAsParent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    timeZone: string | null;
    enrollmentsWithTeacher: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
      withdrawn_at: any | null;
      ongoing_stopped_at: any | null;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      isEnded: boolean;
      isPaused: boolean;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
          isClub: boolean;
          published_at: any | null;
          is_ongoing_weekly: boolean;
          weekly_meetings: number | null;
          duration_weeks: number | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
        timeZone: string;
        privateNote: string | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
      } | null;
    }>;
  } | null;
};

export type ParentDetailsQueryQueryVariables = Exact<{
  parentUid: Scalars["ID"];
  teacherUid: Scalars["ID"];
}>;

export type ParentDetailsQueryQuery = {
  __typename?: "Query";
  parent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    timeZone: string | null;
    hasSubscription: boolean;
    hasEnrollment: boolean;
    enrollmentsWithTeacher: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
      withdrawn_at: any | null;
      ongoing_stopped_at: any | null;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      isEnded: boolean;
      isPaused: boolean;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
          isClub: boolean;
          published_at: any | null;
          is_ongoing_weekly: boolean;
          weekly_meetings: number | null;
          duration_weeks: number | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
        timeZone: string;
        privateNote: string | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
      } | null;
    }>;
  } | null;
};

export type PrivateMessageThreadOnlyMessagesQueryQueryVariables = Exact<{
  threadUid: Scalars["ID"];
}>;

export type PrivateMessageThreadOnlyMessagesQueryQuery = {
  __typename?: "Query";
  privateMessageThread: {
    __typename?: "PrivateClassThread";
    uid: string;
    messages: Array<{
      __typename?: "PrivateClassMessage";
      uid: string;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
      };
      participants: Array<{
        __typename?: "Participant";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      }>;
    }>;
  };
};

export type AcceptScheduleSuggestionMutationVariables = Exact<{
  activityUid: Scalars["ID"];
  suggestedMeetings: Array<MeetingSuggestionInput> | MeetingSuggestionInput;
}>;

export type AcceptScheduleSuggestionMutation = {
  __typename?: "Mutation";
  acceptScheduleSuggestion: {
    __typename?: "Section";
    filledSpaceCount: number;
    uid: string;
    activity_uid: string;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_cross_listed: boolean;
    size_max: number | null;
    size_min: number | null;
    isPublished: boolean | null;
    price_cents: number | null;
    priceCredits: number | null;
    published_at: any | null;
    updated_at: any;
    canceled_at: any | null;
    deleted_at: any | null;
    ongoing_stopped_at: any | null;
    duration_minutes: number | null;
    usesOutschoolVideoChat: boolean;
    usersCanSendPrivateMessage: boolean;
    currentUserCanManage: boolean;
    isBuyable: boolean;
    allowsTeacherTransfer: boolean;
    activity: {
      __typename?: "Activity";
      uid: string;
      user_uid: string;
      slug_id: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      isClub: boolean;
      details: { __typename?: "ActivityDetails"; photo: string | null };
    };
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
    details: {
      __typename?: "SectionDetails";
      autoScheduledDraft: boolean | null;
    };
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
  };
};

export type ResendEmailConfirmationMutationVariables = Exact<{
  userUid: Scalars["ID"];
}>;

export type ResendEmailConfirmationMutation = {
  __typename?: "Mutation";
  resendEmailConfirmation: boolean;
};

export type ResendOtherEmailConfirmationMutationVariables = Exact<{
  userUid: Scalars["ID"];
  otherEmail: Scalars["String"];
}>;

export type ResendOtherEmailConfirmationMutation = {
  __typename?: "Mutation";
  resendOtherEmailConfirmation: boolean;
};

export type ActivityEnrollWithCharterPurchaseOrder_EnrollmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  section_uid: string | null;
  confirmed_at: any | null;
  withdrawn_at: any | null;
  isPrepay: boolean;
  confirmedAt: any | null;
  withdrawnAt: any | null;
  ongoingEndTime: any | null;
  isPaused: boolean;
  isEnded: boolean;
  section: {
    __typename?: "Section";
    uid: string;
    activity_uid: string;
    start_time: any | null;
    end_time: any | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      titleTranslated: string | null;
    };
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
    } | null;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
  } | null;
  learner: {
    __typename?: "Learner";
    uid: string;
    age: number | null;
    name: string | null;
  } | null;
};

export type ActivitySummary_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  title: string;
  titleTranslated: string | null;
  weekly_meetings: number | null;
  duration_weeks: number | null;
  is_ongoing_weekly: boolean;
  isClub: boolean;
  isFlexSchedule: boolean;
  isSelfPaced: boolean;
  published_at: any | null;
  hasTeacherSchedule: boolean;
  size_max: number | null;
  details: { __typename?: "ActivityDetails"; photo: string | null };
};

export type ActivitySummary_SectionFragment = {
  __typename?: "Section";
  uid: string;
  start_time: any | null;
  end_time: any | null;
  allowsLateEnrollment: boolean;
  nextOngoingMeeting: {
    __typename?: "Meeting";
    uid: string;
    start_time: any;
  } | null;
  progress: {
    __typename?: "CourseProgress";
    pastMeetingCount: number;
    remainingMeetingCount: number;
    totalMeetingCount: number;
    nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
  } | null;
};

export type EnrollmentMeetingPurchaseDuration_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  title: string;
  titleTranslated: string | null;
  weekly_meetings: number | null;
  duration_weeks: number | null;
  price_cents: number | null;
  isClub: boolean;
  is_ongoing_weekly: boolean;
  allows_recurring_payment: boolean | null;
  hasTeacherSchedule: boolean;
  size_max: number | null;
  refundPolicy: {
    __typename?: "RefundPolicy";
    name: string;
    description: string;
    type: RefundPolicyType;
  };
};

export type ValidateMeetingRequestsForPurchaseMutationVariables = Exact<{
  requestUids: Array<Scalars["String"]> | Scalars["String"];
  activityUid: Scalars["String"];
  enrollmentMeetingPurchaseUid: Scalars["String"];
  sectionUid?: InputMaybe<Scalars["String"]>;
}>;

export type ValidateMeetingRequestsForPurchaseMutation = {
  __typename?: "Mutation";
  validateMeetingRequestsForPurchase: {
    __typename?: "ValidateMeetingRequestsForPurchaseResponse";
    isValid: boolean;
    error: ValidateMeetingRequestsForPurchaseError | null;
  };
};

export type MultipleMeetingsModal_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  title: string;
  titleTranslated: string | null;
  suggestedWeeklyMeetings: number | null;
  details: { __typename?: "ActivityDetails"; photo: string | null };
};

export type AllOrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type AllOrganizationsQuery = {
  __typename?: "Query";
  organizations: Array<{
    __typename?: "Organization";
    uid: string;
    name: string;
    state: string | null;
    logoUrl: string | null;
    urlName: string | null;
  }>;
};

export type ResumeOngoingEnrollmentMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
}>;

export type ResumeOngoingEnrollmentMutation = {
  __typename?: "Mutation";
  resumeOngoingEnrollment: {
    __typename?: "Enrollment";
    uid: string;
    section_uid: string | null;
    price_cents: number | null;
    priceCents: number | null;
    created_at: any | null;
    confirmed_at: any | null;
    confirmedAt: any | null;
    withdrawn_at: any | null;
    ongoing_stopped_at: any | null;
    currency_code: CurrencyCode;
    exchange_rate: number;
    amount_in_local_currency: number;
    ongoing_start_time: any | null;
    ongoing_end_time: any | null;
    withdrawnAt: any | null;
    ongoingStoppedAt: any | null;
    ongoingStartTime: any | null;
    ongoingEndTime: any | null;
    isPaused: boolean;
    isEnded: boolean;
    isPrepay: boolean;
    refundableAmountCents: number;
    canBeWithdrawn: boolean;
    canBeStopped: boolean;
    canBePaused: boolean;
    usersCanCommunicate: boolean;
    purchasedAsGift: boolean;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      type: RefundPolicyType;
      name: string;
      description: string;
    } | null;
    details: {
      __typename?: "EnrollmentDetails";
      name: string | null;
      age: string | null;
      attendedAt: any | null;
      message: string | null;
    };
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    buyer: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    siblings: Array<{
      __typename?: "Sibling";
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
      currentOrLastSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
      };
    }>;
    paymentSchedule: {
      __typename?: "PaymentSchedule";
      uid: string;
      stoppedAt: any | null;
      endTime: any | null;
      scheduleType: PaymentScheduleTypes;
      amountCents: number | null;
    } | null;
    pauseWindow: {
      __typename?: "EnrollmentPauseWindow";
      uid: string;
      startDate: any;
      endDate: any;
      canBeCanceled: boolean;
      pauseReason: string;
    } | null;
  };
};

export type ActivityPromotableSectionsQueryVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type ActivityPromotableSectionsQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    promotableSections: Array<{ __typename?: "Section"; uid: string }>;
  };
};

export type GetLastUnrefundedEnrollmentPaymentQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
}>;

export type GetLastUnrefundedEnrollmentPaymentQuery = {
  __typename?: "Query";
  lastUnrefundedEnrollmentPayment: {
    __typename?: "EnrollmentPayment";
    uid: string;
    amount_cents: number | null;
    order_slug_id: string | null;
    stripe_charge_id: string | null;
    purchase_order_id: string | null;
    esa_order_id: string | null;
    intercom_url: string | null;
    last4: string | null;
    created_at: any | null;
    paid_at: any | null;
    disbursed_at: any | null;
    payout_uid: string | null;
    start_time: any | null;
    end_time: any | null;
    unrefundedCents: number;
    isOngoingRefundable: boolean;
    voucher_name: string | null;
    pricing_offer_uid: string | null;
    credit_transaction_uid: string | null;
    coupon_code_uid: string | null;
    currency_code: CurrencyCode | null;
    exchange_rate: number | null;
    invoice_uid: string | null;
    funding_program_invoice_uid: string | null;
    service_fee_percentage: number | null;
    couponCode: {
      __typename?: "CouponCode";
      code: string;
      coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
  } | null;
};

export type RefundPolicy_ActivityFragment = {
  __typename?: "Activity";
  isClub: boolean;
  is_ongoing_weekly: boolean;
  allows_recurring_payment: boolean | null;
  hasTeacherSchedule: boolean;
  size_max: number | null;
  duration_weeks: number | null;
  weekly_meetings: number | null;
  refundPolicy: {
    __typename?: "RefundPolicy";
    name: string;
    description: string;
    type: RefundPolicyType;
  };
};

export type AgeWarning_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  age_min: number | null;
  age_max: number | null;
  strictAgeRange: {
    __typename?: "AgeRangeType";
    min: number;
    max: number;
  } | null;
};

export type AgeWarning_LearnerFragment = {
  __typename?: "Learner";
  uid: string;
  age: number | null;
};

export type AgeWarning_SectionFragment = {
  __typename?: "Section";
  uid: string;
  isPublished: boolean | null;
  details: {
    __typename?: "SectionDetails";
    autoScheduledDraft: boolean | null;
  };
};

export type ConflictWarning_EnrollmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  section: {
    __typename?: "Section";
    uid: string;
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      titleTranslated: string | null;
    };
  } | null;
};

export type ConflictWarning_LearnerFragment = {
  __typename?: "Learner";
  uid: string;
  name: string | null;
};

export type EnrolledInOtherSectionWarning_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  weekly_meetings: number | null;
  duration_weeks: number | null;
  is_ongoing_weekly: boolean;
  size_max: number | null;
  published_at: any | null;
  isClub: boolean;
  hasTeacherSchedule: boolean;
};

export type EnrolledInOtherSectionWarning_EnrollmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  section: {
    __typename?: "Section";
    uid: string;
    start_time: any | null;
    end_time: any | null;
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
    } | null;
  } | null;
};

export type EnrolledInOtherSectionWarning_LearnerFragment = {
  __typename?: "Learner";
  uid: string;
  name: string | null;
};

export type OneOnOneEnrolledInOtherSectionWarning_EnrollmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  section: {
    __typename?: "Section";
    uid: string;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
  } | null;
};

export type EnrollmentWarnings_ActivityFragment = {
  __typename?: "Activity";
  hasTeacherSchedule: boolean;
  size_max: number | null;
  size_min: number | null;
  uid: string;
  age_min: number | null;
  age_max: number | null;
  weekly_meetings: number | null;
  duration_weeks: number | null;
  is_ongoing_weekly: boolean;
  published_at: any | null;
  isClub: boolean;
  strictAgeRange: {
    __typename?: "AgeRangeType";
    min: number;
    max: number;
  } | null;
};

export type EnrollmentWarnings_SectionFragment = {
  __typename?: "Section";
  uid: string;
  isPublished: boolean | null;
  details: {
    __typename?: "SectionDetails";
    autoScheduledDraft: boolean | null;
  };
};

export type EnrollmentWarnings_EnrollmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  section: {
    __typename?: "Section";
    uid: string;
    start_time: any | null;
    end_time: any | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      titleTranslated: string | null;
    };
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
    } | null;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
  } | null;
};

export type EnrollmentWarnings_LearnerFragment = {
  __typename?: "Learner";
  uid: string;
  age: number | null;
  name: string | null;
};

export type LearnerItem_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  hasTeacherSchedule: boolean;
  size_max: number | null;
  size_min: number | null;
  age_min: number | null;
  age_max: number | null;
  weekly_meetings: number | null;
  duration_weeks: number | null;
  is_ongoing_weekly: boolean;
  published_at: any | null;
  isClub: boolean;
  strictAgeRange: {
    __typename?: "AgeRangeType";
    min: number;
    max: number;
  } | null;
};

export type LearnerItem_SectionFragment = {
  __typename?: "Section";
  uid: string;
  isPublished: boolean | null;
  details: {
    __typename?: "SectionDetails";
    autoScheduledDraft: boolean | null;
  };
};

export type LearnerItem_LearnerFragment = {
  __typename?: "Learner";
  uid: string;
  name: string | null;
  age: number | null;
};

export type GetLearnerItems_EnrollmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  confirmedAt: any | null;
  withdrawnAt: any | null;
  ongoingEndTime: any | null;
  isPaused: boolean;
  isEnded: boolean;
  learner: {
    __typename?: "Learner";
    uid: string;
    age: number | null;
    name: string | null;
  } | null;
  section: {
    __typename?: "Section";
    uid: string;
    start_time: any | null;
    end_time: any | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      titleTranslated: string | null;
    };
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
    } | null;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
  } | null;
};

export type GetLearnerItems_EnrollmentWithMeetingPurchasesFragment = {
  __typename?: "Enrollment";
  uid: string;
  confirmedAt: any | null;
  withdrawnAt: any | null;
  ongoingEndTime: any | null;
  isPaused: boolean;
  isEnded: boolean;
  hasOverlappingPendingEnrollmentMeetingPurchase: boolean;
  learner: {
    __typename?: "Learner";
    uid: string;
    age: number | null;
    name: string | null;
  } | null;
  section: {
    __typename?: "Section";
    uid: string;
    start_time: any | null;
    end_time: any | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      titleTranslated: string | null;
    };
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
    } | null;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
  } | null;
};

export type GetAdminEnrollmentPaymentRefundActionsQueryVariables = Exact<{
  enrollmentPaymentRefundUid: Scalars["ID"];
}>;

export type GetAdminEnrollmentPaymentRefundActionsQuery = {
  __typename?: "Query";
  adminEnrollmentPaymentRefundActions: Array<{
    __typename?: "AdminEnrollmentPaymentRefundAction";
    uid: string;
    notes: string;
  }> | null;
};

export type GiftCardModalQueryVariables = Exact<{
  activityUid?: InputMaybe<Scalars["ID"]>;
  activitySlugId?: InputMaybe<Scalars["String"]>;
}>;

export type GiftCardModalQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    title: string;
    details: { __typename?: "ActivityDetails"; photo: string | null };
  };
};

export type UpdateLearnerNoteMutationVariables = Exact<{
  learnerUid: Scalars["ID"];
  note: Scalars["String"];
}>;

export type UpdateLearnerNoteMutation = {
  __typename?: "Mutation";
  updateLearnerNote: {
    __typename?: "Learner";
    uid: string;
    privateNote: string | null;
  };
};

export type LearnerPrivateNote_LearnerFragment = {
  __typename?: "Learner";
  uid: string;
  timeZone: string;
  privateNote: string | null;
};

export type AggregatedUpsellQueryQueryVariables = Exact<{
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type AggregatedUpsellQueryQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    aggregatedUpsell: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        hasTeacherSchedule: boolean;
        size_max: number | null;
        user_uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
      } | null;
    }>;
  };
};

export type FavouriteListingsQueryVariables = Exact<{
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type FavouriteListingsQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    favoriteListings: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        hasTeacherSchedule: boolean;
        size_max: number | null;
        user_uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
      } | null;
    }> | null;
  };
};

export type StartingSoonQueryVariables = Exact<{
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type StartingSoonQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    startingSoon: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        hasTeacherSchedule: boolean;
        size_max: number | null;
        user_uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
      } | null;
    }> | null;
  };
};

export type PersonalizedRecommendationsQueryVariables = Exact<{
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type PersonalizedRecommendationsQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    personalizedRecommendations: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        hasTeacherSchedule: boolean;
        size_max: number | null;
        user_uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
      } | null;
    }>;
  };
};

export type ClassesUnderTwentyDollarsQueryVariables = Exact<{
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type ClassesUnderTwentyDollarsQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    underTwentyDollars: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        hasTeacherSchedule: boolean;
        size_max: number | null;
        user_uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
      } | null;
    }>;
  };
};

export type ClassesUnderSomeUsdQueryVariables = Exact<{
  skipRating: Scalars["Boolean"];
  amountCents: Scalars["Int"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type ClassesUnderSomeUsdQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    underSomeCents: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        hasTeacherSchedule: boolean;
        size_max: number | null;
        user_uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
      } | null;
    }>;
  };
};

export type BackToOutschoolQueryQueryVariables = Exact<{
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type BackToOutschoolQueryQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    backToOutschool: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        hasTeacherSchedule: boolean;
        size_max: number | null;
        user_uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
      } | null;
    }>;
  };
};

export type PersonalizedPopularListingsQueryVariables = Exact<{
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type PersonalizedPopularListingsQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    personalizedPopularListings: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        hasTeacherSchedule: boolean;
        size_max: number | null;
        user_uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
      } | null;
    }>;
  };
};

export type PopularTutorListingsQueryVariables = Exact<{
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type PopularTutorListingsQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    popularTutorListings: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        hasTeacherSchedule: boolean;
        size_max: number | null;
        user_uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
      } | null;
    }>;
  };
};

export type InterestTopicsListingsQueryQueryVariables = Exact<{
  skipRating: Scalars["Boolean"];
  topicUids: Array<Scalars["ID"]> | Scalars["ID"];
  learnerAge: Scalars["Int"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type InterestTopicsListingsQueryQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    interestTopics: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        hasTeacherSchedule: boolean;
        size_max: number | null;
        user_uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
      } | null;
    }>;
  };
};

export type CancelOneOnOneModal_SelectedMeetingFragment = {
  __typename?: "Meeting";
  uid: string;
  start_time: any;
  end_time: any;
  recurring: boolean | null;
  paidAt: any | null;
  refundableAmountCents: number;
  refundableAmountCredits: number | null;
};

export type CancelOneOnOneModal_RefundPolicyFragment = {
  __typename?: "RefundPolicy";
  name: string;
  description: string;
  creditDescription: string | null;
};

export type ActivityManageToolbarQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type ActivityManageToolbarQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    user_uid: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    learningGoals: Array<string>;
    assessment: string | null;
    homework: string | null;
    grading_policy: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    english_proficiency_level: EnglishProficiencyLevel | null;
    grade_level_min: string | null;
    grade_level_max: string | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    priceCredits: number | null;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    allowWaitlist: boolean | null;
    isClub: boolean;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    allows_recurring_payment: boolean | null;
    allows_late_enrollments_for_fixed_length_classes: boolean;
    has_building_content: boolean | null;
    hasTeacherSchedule: boolean;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isOneTime: boolean;
    classFormat: EducatorSelectedFormat;
    classroomType: ClassroomType;
    saveCount: number | null;
    archived_at: any | null;
    upsell_activity_uid: string | null;
    why_me: string | null;
    parental_guidance: string | null;
    sources: string | null;
    materials: string | null;
    hasSections: boolean;
    requested_listing_at: any | null;
    has_external_resources: boolean | null;
    selfPacedMigration: {
      __typename?: "SelfPacedMigrationStatus";
      hasConsented: boolean;
    } | null;
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      published_at: string | null;
      lessons: Array<{
        __typename?: "Lesson";
        uid: string;
        week_number: number;
        order_within_week: number;
        title: string | null;
        description: string | null;
        lessonNumber: number | null;
        unit: { __typename?: "Unit"; uid: string; title: string | null } | null;
      }>;
    } | null;
    strictAgeRange: {
      __typename?: "AgeRangeType";
      min: number;
      max: number;
    } | null;
    proficiency_level: {
      __typename?: "ProficiencyLevel";
      level: Array<string | null> | null;
      text: string | null;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      description: string;
      type: RefundPolicyType;
    };
    details: {
      __typename?: "ActivityDetails";
      photo: string | null;
      photoSquare: string | null;
      asyncTime: string | null;
    };
    leaderDetails: {
      __typename?: "ActivityLeaderDetails";
      uid: string;
      requestedListingAt: any | null;
      welcomeMessage: {
        __typename?: "WelcomeMessage";
        text: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        }> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        } | null;
      } | null;
    };
    approvalStatus: {
      __typename?: "ActivityApprovalStatus";
      action: string;
      message_to_teacher: string | null;
    } | null;
    externalResources: Array<{
      __typename?: "ExternalResource";
      uid: string;
      name: string;
      url: string | null;
    }>;
  };
};

export type ClubManageToolbar_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  user_uid: string;
  title: string;
  titleTranslated: string | null;
  isClub: boolean;
};

export type ClubManageToolbar_SectionFragment = {
  __typename?: "Section";
  uid: string;
  published_at: any | null;
  deleted_at: any | null;
  canceled_at: any | null;
  start_time: any | null;
  end_time: any | null;
  size_max: number | null;
  currentUserCanManage: boolean;
};

export type OneOnOneManageToolbar_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  user_uid: string;
  title: string;
  titleTranslated: string | null;
  isClub: boolean;
  hasTeacherSchedule: boolean;
  archived_at: any | null;
  published_at: any | null;
  requested_listing_at: any | null;
  approvalStatus: {
    __typename?: "ActivityApprovalStatus";
    action: string;
    message_to_teacher: string | null;
  } | null;
};

export type SectionManageToolbar_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  user_uid: string;
  title: string;
  titleTranslated: string | null;
  is_ongoing_weekly: boolean;
  weekly_meetings: number | null;
  duration_weeks: number | null;
  isClub: boolean;
  published_at: any | null;
  hasTeacherSchedule: boolean;
};

export type SectionManageToolbar_SectionFragment = {
  __typename?: "Section";
  uid: string;
  published_at: any | null;
  deleted_at: any | null;
  canceled_at: any | null;
  start_time: any | null;
  end_time: any | null;
  startsAtNight: boolean | null;
  is_self_paced: boolean;
  currentUserCanManage: boolean;
  nextOngoingMeeting: {
    __typename?: "Meeting";
    uid: string;
    start_time: any;
  } | null;
  assignmentClassPosts: Array<{
    __typename?: "AssignmentClassPost";
    uid: string;
  }>;
  syllabus: {
    __typename?: "Syllabus";
    uid: string;
    published_at: string | null;
  } | null;
};

export type UpcomingChargesQueryQueryVariables = Exact<{
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
}>;

export type UpcomingChargesQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    activeEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      upcomingCharges: Array<{
        __typename?: "UpcomingCharge";
        date: any;
        priceCents: number;
        priceCredits: number;
      }>;
    } | null>;
  } | null;
};

export type AddUserQuizAnswersMutationMutationVariables = Exact<{
  anonymousId: Scalars["String"];
  answers: Array<UserQuizTypeAndValueInput> | UserQuizTypeAndValueInput;
}>;

export type AddUserQuizAnswersMutationMutation = {
  __typename?: "Mutation";
  addQuizAnswers: Array<{ __typename?: "QuizAnswer"; uid: string } | null>;
};

export type ParentDashboardListingsByActivityUidsQueryVariables = Exact<{
  activityUids: Array<Scalars["ID"]> | Scalars["ID"];
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type ParentDashboardListingsByActivityUidsQuery = {
  __typename?: "Query";
  listingsByActivityUids: Array<{
    __typename?: "Listing";
    insertionId: string | null;
    sectionCount: number;
    openSectionCount: number;
    upcomingSectionCount: number;
    activity: {
      __typename?: "Activity";
      uid: string;
      averageStarRating?: number | null;
      reviewCount: number;
      age_min: number | null;
      age_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      isClub: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      hasTeacherSchedule: boolean;
      size_max: number | null;
      user_uid: string;
      slug_id: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      paginatedFilteredSections: {
        __typename?: "SectionList";
        data: Array<{
          __typename?: "Section";
          filledSpaceCount: number;
          uid: string;
          activity_uid: string;
          start_time: any | null;
          end_time: any | null;
          startsAtNight: boolean | null;
          is_cross_listed: boolean;
          size_max: number | null;
          size_min: number | null;
          isPublished: boolean | null;
          price_cents: number | null;
          priceCredits: number | null;
          published_at: any | null;
          updated_at: any;
          canceled_at: any | null;
          deleted_at: any | null;
          ongoing_stopped_at: any | null;
          duration_minutes: number | null;
          usesOutschoolVideoChat: boolean;
          usersCanSendPrivateMessage: boolean;
          currentUserCanManage: boolean;
          isBuyable: boolean;
          allowsTeacherTransfer: boolean;
          nextOngoingMeeting: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
            deleted_at: any | null;
            created_at: any | null;
          } | null;
          leader: { __typename?: "Leader"; uid: string; name: string | null };
          details: {
            __typename?: "SectionDetails";
            autoScheduledDraft: boolean | null;
          };
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
        }>;
      };
      recentReviews?: Array<{
        __typename?: "PublicReview";
        uid: string;
        activity_star_rating: number | null;
        details: {
          __typename?: "PublicReviewDetails";
          publicReview: string | null;
        } | null;
        parent: {
          __typename?: "Parent";
          uid: string;
          publicName: string | null;
        };
      }>;
      clubSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        clubPostCount: number;
      } | null;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      savedClassLists: Array<{
        __typename?: "SavedClassList";
        uid: string;
        lastAddedToAt: any | null;
      }>;
    };
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      averageActivityStarRating: number | null;
      reviewCount: number;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
          fileSize: number | null;
        };
      } | null;
      credentials: Array<{
        __typename?: "TeacherCredential";
        uid: string;
        degreeType: DegreeType | null;
        degreeSubject: DegreeSubject | null;
      }>;
    };
    nextSection: {
      __typename?: "Section";
      uid: string;
      size_max: number | null;
      filledSpaceCount: number;
      start_time: any | null;
      end_time: any | null;
    } | null;
  }>;
};

export type LearnerNameQueryQueryVariables = Exact<{
  learnerUid: Scalars["ID"];
}>;

export type LearnerNameQueryQuery = {
  __typename?: "Query";
  learner: { __typename?: "Learner"; name: string | null } | null;
};

export type MobileParentDashboardLearnerInterestListingsQueryQueryVariables =
  Exact<{
    skipRating: Scalars["Boolean"];
    learnerUid: Scalars["String"];
    searchFilters?: InputMaybe<InterestTopicSearchFilters>;
    withRecentReviews: Scalars["Boolean"];
  }>;

export type MobileParentDashboardLearnerInterestListingsQueryQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    learnerInterestTopicListings: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        hasTeacherSchedule: boolean;
        size_max: number | null;
        user_uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
      } | null;
    }>;
  };
};

export type ListingsByTeacherUidsQueryVariables = Exact<{
  teacherUids: Array<Scalars["ID"]> | Scalars["ID"];
  userTimeZone?: InputMaybe<Scalars["String"]>;
  learnerAges?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]>> | InputMaybe<Scalars["Int"]>
  >;
}>;

export type ListingsByTeacherUidsQuery = {
  __typename?: "Query";
  listingsByTeacherUids: Array<{
    __typename?: "ListingsByTeacher";
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      leader_link: string | null;
    };
    listings: Array<{
      __typename?: "Listing";
      activity: {
        __typename?: "Activity";
        uid: string;
        slug_id: string;
        averageStarRating: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        title: string;
        titleTranslated: string | null;
        weekly_meetings: number | null;
        duration_weeks: number | null;
        is_ongoing_weekly: boolean;
        isClub: boolean;
        details: { __typename?: "ActivityDetails"; photo: string | null };
      };
    }>;
  }>;
};

export type RecentCategoryFragmentFragment = {
  __typename?: "CategoryPage";
  uid: string;
  nameTitleCased: string;
  nameSentenceCased: string;
  slug: string;
  title: string | null;
  description: string | null;
  searchFilters: {
    __typename?: "SavedSearchFilters";
    adminTags: Array<string> | null;
    age: string | null;
    delivery: string | null;
    dow: string | null;
    enabledBooleanFilters: string | null;
    endBy: string | null;
    endByTime: number | null;
    format: string | null;
    fundingPrograms: Array<string> | null;
    multiTermQuery: Array<string> | null;
    order: string | null;
    pricePerMeetingMax: number | null;
    pricePerMeetingMin: number | null;
    q: string | null;
    startAfter: string | null;
    startAfterTime: number | null;
    theme: string | null;
    time: string | null;
    timeOfDay: string | null;
    timeZone: string | null;
  } | null;
};

export type CategoryPagesQueryVariables = Exact<{
  slugs?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type CategoryPagesQuery = {
  __typename?: "Query";
  categoryPages: Array<{
    __typename?: "CategoryPage";
    uid: string;
    nameTitleCased: string;
    nameSentenceCased: string;
    slug: string;
    title: string | null;
    description: string | null;
    searchFilters: {
      __typename?: "SavedSearchFilters";
      adminTags: Array<string> | null;
      age: string | null;
      delivery: string | null;
      dow: string | null;
      enabledBooleanFilters: string | null;
      endBy: string | null;
      endByTime: number | null;
      format: string | null;
      fundingPrograms: Array<string> | null;
      multiTermQuery: Array<string> | null;
      order: string | null;
      pricePerMeetingMax: number | null;
      pricePerMeetingMin: number | null;
      q: string | null;
      startAfter: string | null;
      startAfterTime: number | null;
      theme: string | null;
      time: string | null;
      timeOfDay: string | null;
      timeZone: string | null;
    } | null;
  }> | null;
};

export type ParentScheduleQueryQueryVariables = Exact<{
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
}>;

export type ParentScheduleQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    activeEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        created_at: any;
        isVerified: boolean;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        meetingCount: number;
        deleted_at: any | null;
        published_at: any | null;
        canceled_at: any | null;
        filledSpaceCount: number;
        validEnrollmentCount: number;
        activity: {
          __typename?: "Activity";
          uid: string;
          slug_id: string;
          published_at: any | null;
          title: string;
          titleTranslated: string | null;
          url: string;
          path: string;
          hasTeacherSchedule: boolean;
          isClub: boolean;
          isFlexSchedule: boolean;
          isSelfPaced: boolean;
          isOneTime: boolean;
          duration_minutes: number | null;
          duration_weeks: number | null;
          weekly_meetings: number | null;
          is_ongoing_weekly: boolean;
          size_min: number | null;
          size_max: number | null;
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            averageActivityStarRating: number | null;
            reviewCount: number;
          };
          details: { __typename?: "ActivityDetails"; photo: string | null };
        };
        meetings: Array<{
          __typename?: "Meeting";
          sectionSequenceNumber: number;
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        }>;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
      } | null;
    } | null>;
  } | null;
};

export type SearchPitchListingsQueryVariables = Exact<{
  searchFilters: SearchFilters;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  customSearchUseCase?: InputMaybe<CustomSearchUseCase>;
  useCase?: InputMaybe<SearchUseCase>;
  optionalSearchFilters?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>
  >;
  reviewsOffset?: InputMaybe<Scalars["Int"]>;
  reviewsLimit?: InputMaybe<Scalars["Int"]>;
}>;

export type SearchPitchListingsQuery = {
  __typename?: "Query";
  searchListings: {
    __typename?: "SearchListingsConnection";
    listings: Array<{
      __typename?: "Listing";
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          file: { __typename?: "File"; url: string; thumbUrl: string | null };
        } | null;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        averageStarRating: number | null;
        reviewCount: number;
        isSelfPaced: boolean;
        isFlexSchedule: boolean;
        size_max: number | null;
        isClub: boolean;
        weekly_meetings: number | null;
        duration_weeks: number | null;
        duration_minutes: number | null;
        price_cents: number | null;
        is_ongoing_weekly: boolean;
        hasTeacherSchedule: boolean;
        completedEnrollmentCount: number;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        video: {
          __typename?: "Attachment";
          uid: string;
          file: { __typename?: "File"; url: string; thumbUrl: string | null };
        } | null;
        videoForLearners: {
          __typename?: "Attachment";
          uid: string;
          file: { __typename?: "File"; url: string; thumbUrl: string | null };
        } | null;
        recentReviews: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
        }>;
      };
    }>;
  };
};

export type CountPricingOffersOnOrgForEmailQueryVariables = Exact<{
  emailAddress: Scalars["String"];
  organizationUid: Scalars["ID"];
}>;

export type CountPricingOffersOnOrgForEmailQuery = {
  __typename?: "Query";
  countPricingOffersOnOrgForEmail: number;
};

export type PricingOfferEditForm_PricingOfferFragment = {
  __typename?: "PricingOffer";
  buyerOrgName: string | null;
  totalCapCents: number;
  userLimitCents: number;
  startAfter: any;
  endBy: any;
  requirePaymentMethod: boolean;
  discount: number | null;
  isFinancialAid: boolean;
  eligibleEmailDomains: Array<string>;
  deletedAt: any | null;
  includedEmailAddresses: Array<string>;
  excludedEmailAddresses: Array<string>;
  organizationUid: string | null;
};

export type PhotoFeedbackByUserQueryQueryVariables = Exact<{
  userUid: Scalars["ID"];
}>;

export type PhotoFeedbackByUserQueryQuery = {
  __typename?: "Query";
  photoFeedbackByUser: Array<{
    __typename?: "PublicReview";
    uid: string;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
  }>;
};

export type PublicReviewsByParent_PublicReviewFragment = {
  __typename?: "PublicReview";
  uid: string;
  section_uid: string;
  currentUserCanEdit: boolean;
  currentUserCanRespond: boolean;
  activity_star_rating: number | null;
  currentUserMarkedHelpful: boolean | null;
  markedHelpfulCount: number;
  created_at: any;
  teacher_response: string | null;
  teacher_responded_at: any | null;
  activity: {
    __typename?: "Activity";
    uid: string;
    isClub: boolean;
    slug_id: string;
    title: string;
    user_uid: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    learningGoals: Array<string>;
    assessment: string | null;
    homework: string | null;
    grading_policy: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    english_proficiency_level: EnglishProficiencyLevel | null;
    grade_level_min: string | null;
    grade_level_max: string | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    priceCredits: number | null;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    allowWaitlist: boolean | null;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    allows_recurring_payment: boolean | null;
    allows_late_enrollments_for_fixed_length_classes: boolean;
    has_building_content: boolean | null;
    hasTeacherSchedule: boolean;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isOneTime: boolean;
    classFormat: EducatorSelectedFormat;
    classroomType: ClassroomType;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      leader_link: string | null;
      photo: string | null;
      created_at: any | null;
      suspended_at: any | null;
      cityState: string | null;
      socialOptOut: boolean;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      hasBackgroundCheckExpired: boolean | null;
      reviewCount: number;
      averageActivityStarRating: number | null;
      hasListings: boolean;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        headlineTranslated: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
    };
    strictAgeRange: {
      __typename?: "AgeRangeType";
      min: number;
      max: number;
    } | null;
    proficiency_level: {
      __typename?: "ProficiencyLevel";
      level: Array<string | null> | null;
      text: string | null;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      description: string;
      type: RefundPolicyType;
    };
    details: {
      __typename?: "ActivityDetails";
      photo: string | null;
      photoSquare: string | null;
    };
  } | null;
  details: {
    __typename?: "PublicReviewDetails";
    publicReview: string | null;
  } | null;
  attachments: Array<{
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  }>;
  parent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  };
};

export type RespondToReviewMutationVariables = Exact<{
  feedbackUid: Scalars["ID"];
  response: Scalars["String"];
}>;

export type RespondToReviewMutation = {
  __typename?: "Mutation";
  respondToReview: {
    __typename?: "PublicReview";
    uid: string;
    section_uid: string;
    currentUserCanEdit: boolean;
    currentUserCanRespond: boolean;
    activity_star_rating: number | null;
    currentUserMarkedHelpful: boolean | null;
    markedHelpfulCount: number;
    created_at: any;
    teacher_response: string | null;
    teacher_responded_at: any | null;
    details: {
      __typename?: "PublicReviewDetails";
      publicReview: string | null;
    } | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      isClub: boolean;
      slug_id: string;
      title: string;
      user_uid: string;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
        photo: string | null;
      };
    } | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
  };
};

export type TextFeedbackByUserQueryQueryVariables = Exact<{
  userUid: Scalars["ID"];
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type TextFeedbackByUserQueryQuery = {
  __typename?: "Query";
  textFeedbackByUser: Array<{
    __typename?: "PublicReview";
    uid: string;
    activity_star_rating: number | null;
    created_at: any;
    details: {
      __typename?: "PublicReviewDetails";
      publicReview: string | null;
    } | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      isClub: boolean;
      slug_id: string;
      title: string;
      titleTranslated: string | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
        created_at: any | null;
        photo: string | null;
        suspended_at: any | null;
        cityState: string | null;
        socialOptOut: boolean;
        is_crosslister: boolean | null;
        timeZone: string | null;
        hasAvailabilitySet: boolean;
        approved: boolean;
        hasBackgroundCheckExpired: boolean | null;
        reviewCount: number;
        averageActivityStarRating: number | null;
        hasListings: boolean;
        details: {
          __typename?: "LeaderDetails";
          about: string | null;
          headline: string | null;
          headlineTranslated: string | null;
          countryOfResidence: string | null;
        };
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        teacherForSellerOrg: {
          __typename?: "SellerOrg";
          uid: string;
          name: string;
        } | null;
      };
    } | null;
  }>;
};

export type SearchCounselorSuggestionQueryVariables = Exact<{
  activityUids: Array<Scalars["ID"]> | Scalars["ID"];
  searchFilters?: InputMaybe<SearchFilters>;
  userPreferencesPrompt: Scalars["String"];
}>;

export type SearchCounselorSuggestionQuery = {
  __typename?: "Query";
  searchCounselorSuggestion: {
    __typename?: "SearchCounselorSuggestion";
    activityUid: string | null;
    reason: string;
  };
};

export type SearchActivityListingsByActivityUidsQueryVariables = Exact<{
  activityUids: Array<Scalars["ID"]> | Scalars["ID"];
  skipRating: Scalars["Boolean"];
}>;

export type SearchActivityListingsByActivityUidsQuery = {
  __typename?: "Query";
  listingsByActivityUids: Array<{
    __typename?: "Listing";
    insertionId: string | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      user_uid: string;
      title: string;
      url: string;
      path: string;
      summary: string | null;
      summaryTranslated: string | null;
      weekly_meetings: number | null;
      duration_weeks: number | null;
      is_ongoing_weekly: boolean;
      isClub: boolean;
      published_at: any | null;
      size_max: number | null;
      size_min: number | null;
      hasTeacherSchedule: boolean;
      allows_recurring_payment: boolean | null;
      averageStarRating?: number | null;
      reviewCount: number;
      age_min: number | null;
      age_max: number | null;
      duration_minutes: number | null;
      price_cents: number | null;
      priceCreditsPerMeeting: number | null;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      canEnroll: boolean;
      isOneTime: boolean;
      titleTranslated: string | null;
      slug_id: string;
      subject: string | null;
      syllabus: {
        __typename?: "Syllabus";
        uid: string;
        lessons: Array<{ __typename?: "Lesson"; uid: string }>;
      } | null;
      clubSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        clubPostCount: number;
      } | null;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      savedClassLists: Array<{
        __typename?: "SavedClassList";
        uid: string;
        lastAddedToAt: any | null;
      }>;
    };
    nextSection: {
      __typename?: "Section";
      uid: string;
      size_max: number | null;
      filledSpaceCount: number;
      start_time: any | null;
      end_time: any | null;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      averageActivityStarRating: number | null;
      reviewCount: number;
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    };
  }>;
};

export type SearchSelectedTeacherHeaderQueryVariables = Exact<{
  uid?: InputMaybe<Scalars["ID"]>;
}>;

export type SearchSelectedTeacherHeaderQuery = {
  __typename?: "Query";
  publicProfile: {
    __typename?: "PublicProfile";
    uid: string;
    leader: {
      __typename?: "Leader";
      reviewCount: number;
      averageActivityStarRating: number | null;
      educatorTiers: Array<EducatorTier>;
      uid: string;
      name: string | null;
      leader_link: string | null;
      created_at: any | null;
      photo: string | null;
      suspended_at: any | null;
      cityState: string | null;
      socialOptOut: boolean;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      hasBackgroundCheckExpired: boolean | null;
      hasListings: boolean;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        headlineTranslated: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
    } | null;
  } | null;
};

export type SectionTeaser_SectionFragment = {
  __typename?: "Section";
  uid: string;
  start_time: any | null;
  end_time: any | null;
  nextOngoingMeeting: {
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
  } | null;
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    isClub: boolean;
    weekly_meetings: number | null;
    duration_weeks: number | null;
    is_ongoing_weekly: boolean;
    hasTeacherSchedule: boolean;
    published_at: any | null;
  };
};

export type VerifySellerOrgPinMutationVariables = Exact<{
  sellerOrgUid: Scalars["ID"];
  pin: Scalars["String"];
}>;

export type VerifySellerOrgPinMutation = {
  __typename?: "Mutation";
  verifySellerOrgPin: { __typename?: "VerifyPinResult"; success: boolean };
};

export type ChangePinMutationVariables = Exact<{
  userUid: Scalars["ID"];
  sellerOrgUid: Scalars["ID"];
  token: Scalars["String"];
  pin?: InputMaybe<Scalars["String"]>;
}>;

export type ChangePinMutation = {
  __typename?: "Mutation";
  changePin: { __typename?: "ChangePinResult"; hasPinSet: boolean };
};

export type CreateSellerOrgPinMutationVariables = Exact<{
  sellerOrgUid: Scalars["ID"];
  pin: Scalars["String"];
}>;

export type CreateSellerOrgPinMutation = {
  __typename?: "Mutation";
  createSellerOrgPin: boolean;
};

export type ActivityMetaTags_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  slug_id: string;
  title: string;
  titleTranslated: string | null;
  summary: string | null;
  age_min: number | null;
  age_max: number | null;
  published_at: any | null;
  price_cents: number | null;
  averageStarRating: number | null;
  reviewCount: number;
  duration_minutes: number | null;
  is_ongoing_weekly: boolean;
  isClub: boolean;
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    leader_link: string | null;
  };
  details: { __typename?: "ActivityDetails"; photo: string | null };
};

export type ActivityMetaTags_SectionFragment = {
  __typename?: "Section";
  uid: string;
  updated_at: any;
  canceled_at: any | null;
  filledSpaceCount: number;
  price_cents: number | null;
  size_max: number | null;
  start_time: any | null;
  end_time: any | null;
  meetings: Array<{
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
  }>;
};

export type EmailPreviewQueryVariables = Exact<{
  activityUid?: InputMaybe<Scalars["ID"]>;
  slugId?: InputMaybe<Scalars["String"]>;
  sectionUid: Scalars["ID"];
  skipSection: Scalars["Boolean"];
}>;

export type EmailPreviewQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    user_uid: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    learningGoals: Array<string>;
    assessment: string | null;
    homework: string | null;
    grading_policy: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    english_proficiency_level: EnglishProficiencyLevel | null;
    grade_level_min: string | null;
    grade_level_max: string | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    priceCredits: number | null;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    allowWaitlist: boolean | null;
    isClub: boolean;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    allows_recurring_payment: boolean | null;
    allows_late_enrollments_for_fixed_length_classes: boolean;
    has_building_content: boolean | null;
    hasTeacherSchedule: boolean;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isOneTime: boolean;
    classFormat: EducatorSelectedFormat;
    classroomType: ClassroomType;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      leader_link: string | null;
      created_at: any | null;
      photo: string | null;
      suspended_at: any | null;
      cityState: string | null;
      socialOptOut: boolean;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      hasBackgroundCheckExpired: boolean | null;
      reviewCount: number;
      averageActivityStarRating: number | null;
      hasListings: boolean;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        headlineTranslated: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
    };
    strictAgeRange: {
      __typename?: "AgeRangeType";
      min: number;
      max: number;
    } | null;
    proficiency_level: {
      __typename?: "ProficiencyLevel";
      level: Array<string | null> | null;
      text: string | null;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      description: string;
      type: RefundPolicyType;
    };
    details: {
      __typename?: "ActivityDetails";
      photo: string | null;
      photoSquare: string | null;
    };
  };
  section?: {
    __typename?: "Section";
    filledSpaceCount: number;
    uid: string;
    activity_uid: string;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_cross_listed: boolean;
    size_max: number | null;
    size_min: number | null;
    isPublished: boolean | null;
    price_cents: number | null;
    priceCredits: number | null;
    published_at: any | null;
    updated_at: any;
    canceled_at: any | null;
    deleted_at: any | null;
    ongoing_stopped_at: any | null;
    duration_minutes: number | null;
    usesOutschoolVideoChat: boolean;
    usersCanSendPrivateMessage: boolean;
    currentUserCanManage: boolean;
    isBuyable: boolean;
    allowsTeacherTransfer: boolean;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    }>;
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
    details: {
      __typename?: "SectionDetails";
      autoScheduledDraft: boolean | null;
      onlineClassroom: {
        __typename?: "OnlineClassroom";
        type: string | null;
        url: string | null;
      } | null;
    };
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
  } | null;
};

export type SendActivityInviteEmailMutationVariables = Exact<{
  activityUid: Scalars["ID"];
  recipientEmails: Scalars["String"];
  isEnrolled?: InputMaybe<Scalars["Boolean"]>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
  customMessage?: InputMaybe<Scalars["String"]>;
  campaignPrefix?: InputMaybe<Scalars["String"]>;
}>;

export type SendActivityInviteEmailMutation = {
  __typename?: "Mutation";
  sendActivityInviteEmail: boolean;
};

export type ShareContainer_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  slug_id: string;
  title: string;
  titleTranslated: string | null;
};

export type CreateSubscriptionCreditPurchaseMutationVariables = Exact<{
  numCredits: Scalars["Int"];
  priceCents: Scalars["Int"];
  creditValue: Scalars["Int"];
}>;

export type CreateSubscriptionCreditPurchaseMutation = {
  __typename?: "Mutation";
  createSubscriptionCreditPurchaseOrder: {
    __typename?: "OrderType";
    orderSlugId: string;
  };
};

export type UpdateSubscriptionMutationVariables = Exact<{
  selectedPackage: Scalars["String"];
  paymentMethod?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateSubscriptionMutation = {
  __typename?: "Mutation";
  updateSubscription: {
    __typename?: "SubscriptionInformation";
    subscriptionId: string | null;
    packageName: string | null;
    numCredits: number | null;
  } | null;
};

export type OngoingEarningsReportQueryVariables = Exact<{
  excludeOrgTeachers?: InputMaybe<Scalars["Boolean"]>;
}>;

export type OngoingEarningsReportQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      currentUserIsOwner: boolean | null;
    } | null;
    leaderSections: Array<{
      __typename?: "Section";
      uid: string;
      paidAmount: number | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        titleTranslated: string | null;
        price_cents: number | null;
        is_ongoing_weekly: boolean;
      };
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      payouts: Array<{
        __typename?: "Payout";
        uid: string | null;
        amount_cents: number | null;
        created_at: any | null;
        startTime: any | null;
        numberOfEnrollments: number | null;
      }>;
    }>;
  } | null;
};

export type EarningsReportNewQueryVariables = Exact<{
  excludeOrgTeachers?: InputMaybe<Scalars["Boolean"]>;
  minDate?: InputMaybe<Scalars["DateTime"]>;
  maxDate?: InputMaybe<Scalars["DateTime"]>;
}>;

export type EarningsReportNewQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      currentUserIsOwner: boolean | null;
    } | null;
    leaderSections: Array<{
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
      price_cents: number | null;
      paidAmount: number | null;
      standardPayoutCents: number;
      enrollments: Array<{ __typename?: "Enrollment"; uid: string }>;
      unclaimedGiftEnrollments: Array<{
        __typename?: "GiftEnrollment";
        uid: string;
      }>;
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        titleTranslated: string | null;
        price_cents: number | null;
      };
      leader: { __typename?: "Leader"; uid: string; name: string | null };
    }>;
  } | null;
};

export type PastReviewedTeachersQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type PastReviewedTeachersQuery = {
  __typename?: "Query";
  pastReviewedTeachers: Array<{
    __typename?: "Leader";
    uid: string;
    name: string | null;
    leader_link: string | null;
    created_at: any | null;
    photo: string | null;
    suspended_at: any | null;
    cityState: string | null;
    socialOptOut: boolean;
    is_crosslister: boolean | null;
    timeZone: string | null;
    hasAvailabilitySet: boolean;
    approved: boolean;
    hasBackgroundCheckExpired: boolean | null;
    reviewCount: number;
    averageActivityStarRating: number | null;
    hasListings: boolean;
    details: {
      __typename?: "LeaderDetails";
      about: string | null;
      headline: string | null;
      headlineTranslated: string | null;
      countryOfResidence: string | null;
    };
    ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    teacherForSellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
    } | null;
  }>;
};

export type CurrentUserFeedbackQueryQueryVariables = Exact<{
  offset: Scalars["Int"];
  limit: Scalars["Int"];
}>;

export type CurrentUserFeedbackQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    leader: {
      __typename?: "Leader";
      uid: string;
      reviewCount: number;
      reviews: Array<{
        __typename?: "Feedback";
        uid: string;
        publicReview: string | null;
        leaderFeedback: string | null;
        activity_star_rating: number | null;
        createdAt: any;
        section: {
          __typename?: "Section";
          uid: string;
          activity: {
            __typename?: "Activity";
            uid: string;
            title: string;
            titleTranslated: string | null;
          };
          leader: { __typename?: "Leader"; uid: string; name: string | null };
          enrollments: Array<{
            __typename?: "Enrollment";
            uid: string;
            learner: {
              __typename?: "Learner";
              uid: string;
              name: string | null;
            } | null;
          }>;
        };
        parent: { __typename?: "Parent"; uid: string; name: string | null };
        learners: Array<{
          __typename?: "Learner";
          uid: string;
          name: string | null;
        }>;
      }>;
    } | null;
  } | null;
};

export type TeacherHeader_LeaderFragment = {
  __typename?: "Leader";
  uid: string;
  reviewCount: number;
  averageActivityStarRating: number | null;
  educatorTiers: Array<EducatorTier>;
  name: string | null;
  leader_link: string | null;
  photo: string | null;
  video: {
    __typename?: "Attachment";
    file: { __typename?: "File"; url: string; thumbUrl: string | null };
  } | null;
  details: { __typename?: "LeaderDetails"; headline: string | null };
};

export type MarketingPageQueryQueryVariables = Exact<{ [key: string]: never }>;

export type MarketingPageQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    activities: Array<{
      __typename?: "Activity";
      uid: string;
      title: string;
      subject: string | null;
    }>;
  } | null;
};

export type UpdateAbandonedCartAutoMessageSettingsMutationVariables = Exact<{
  abandoned_cart_auto_message_template?: InputMaybe<Scalars["String"]>;
  abandoned_cart_auto_message_is_on: Scalars["Boolean"];
}>;

export type UpdateAbandonedCartAutoMessageSettingsMutation = {
  __typename?: "Mutation";
  updateAbandonedCartAutoMessageSettings: {
    __typename?: "TeacherMarketingSettings";
    abandoned_cart_auto_message_is_on: boolean;
    abandoned_cart_auto_message_template: string | null;
    abandoned_cart_auto_message_default: string;
  };
};

export type UpdateTeacherMarketingSettingsMutationVariables = Exact<{
  uid?: InputMaybe<Scalars["ID"]>;
  ai_review_summary_is_on?: InputMaybe<Scalars["Boolean"]>;
  ai_meeting_summary_is_on?: InputMaybe<Scalars["Boolean"]>;
  abandoned_cart_auto_message_template?: InputMaybe<Scalars["String"]>;
  abandoned_cart_auto_message_is_on?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateTeacherMarketingSettingsMutation = {
  __typename?: "Mutation";
  updateTeacherMarketingSettings: {
    __typename?: "TeacherMarketingSettings";
    uid: string;
    ai_review_summary_is_on: boolean;
    ai_meeting_summary_is_on: boolean;
    abandoned_cart_auto_message_is_on: boolean;
    abandoned_cart_auto_message_template: string | null;
    abandoned_cart_auto_message_default: string;
  };
};

export type TeacherMarketingSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TeacherMarketingSettingsQuery = {
  __typename?: "Query";
  teacherMarketingSettings: {
    __typename?: "TeacherMarketingSettings";
    uid: string;
    ai_review_summary_is_on: boolean;
    ai_meeting_summary_is_on: boolean;
    abandoned_cart_auto_message_is_on: boolean;
    abandoned_cart_auto_message_template: string | null;
    abandoned_cart_auto_message_default: string;
  } | null;
};

export type TeacherMessage_MessageFragment = {
  __typename?: "Message";
  uid: string;
  subject: string | null;
  content: string | null;
  created_at: any;
  sender: {
    __typename?: "Sender";
    uid: string;
    name: string | null;
    photo: string | null;
    headline: string | null;
    leader_link: string | null;
    timeZone: string | null;
  };
};

export type TeacherMessages_MessageThreadFragment = {
  __typename?: "MessageThread";
  uid: string;
  messages: Array<{
    __typename?: "Message";
    uid: string;
    created_at: any;
    subject: string | null;
    content: string | null;
    sender: {
      __typename?: "Sender";
      uid: string;
      name: string | null;
      photo: string | null;
      headline: string | null;
      leader_link: string | null;
      timeZone: string | null;
    };
  }>;
};

export type TeacherMetrics_UserFragment = {
  __typename?: "User";
  uid: string;
  followerCount: number;
  leader: {
    __typename?: "Leader";
    uid: string;
    metrics: {
      __typename?: "LeaderMetrics";
      activeSections: number;
      repurchaseRate: number | null;
      averageActivityStarRating: number | null;
      learnersTaught: number;
    };
    listings: Array<{
      __typename?: "Listing";
      activity: { __typename?: "Activity"; uid: string; canEnroll: boolean };
    }>;
  } | null;
  sellerOrg: {
    __typename?: "SellerOrg";
    uid: string;
    currentUserIsTeacher: boolean | null;
    currentUserIsOwner: boolean | null;
  } | null;
};

export type TeacherProfileCard_UserFragment = {
  __typename?: "User";
  uid: string;
  name: string | null;
  introduction: string | null;
  created_at: any;
  photo: string | null;
  leader_link: string | null;
};

export type TeacherPublicScheduleCalendar_MeetingFragment = {
  __typename?: "Meeting";
  uid: string;
  start_time: any;
  end_time: any;
  sectionSequenceNumber: number;
  section: {
    __typename?: "Section";
    uid: string;
    size_min: number | null;
    size_max: number | null;
    filledSpaceCount: number;
    deleted_at: any | null;
    canceled_at: any | null;
    published_at: any | null;
    start_time: any | null;
    end_time: any | null;
    ongoing_stopped_at: any | null;
    meetingCount: number;
    isBuyable: boolean;
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
    } | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      slug_id: string;
      title: string;
      titleTranslated: string | null;
      weekly_meetings: number | null;
      is_ongoing_weekly: boolean;
      duration_weeks: number | null;
      duration_minutes: number | null;
    };
  };
};

export type TeacherPublicScheduleCalendar_SectionFragment = {
  __typename?: "Section";
  uid: string;
  start_time: any | null;
  end_time: any | null;
  size_min: number | null;
  size_max: number | null;
  filledSpaceCount: number;
  ongoing_stopped_at: any | null;
  deleted_at: any | null;
  canceled_at: any | null;
  published_at: any | null;
  isBuyable: boolean;
  activity: {
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    title: string;
    titleTranslated: string | null;
    weekly_meetings: number | null;
    is_ongoing_weekly: boolean;
    duration_weeks: number | null;
    duration_minutes: number | null;
  };
};

export type TeacherPublicScheduleCalendarQueryVariables = Exact<{
  uid?: InputMaybe<Scalars["ID"]>;
  startAfter: Scalars["DateTime"];
  startBefore: Scalars["DateTime"];
}>;

export type TeacherPublicScheduleCalendarQuery = {
  __typename?: "Query";
  publicProfile: {
    __typename?: "PublicProfile";
    uid: string;
    leader: {
      __typename?: "Leader";
      uid: string;
      flexSections: {
        __typename?: "SectionConnection";
        edges: Array<{
          __typename?: "SectionEdge";
          node: {
            __typename?: "Section";
            uid: string;
            start_time: any | null;
            end_time: any | null;
            size_min: number | null;
            size_max: number | null;
            filledSpaceCount: number;
            ongoing_stopped_at: any | null;
            deleted_at: any | null;
            canceled_at: any | null;
            published_at: any | null;
            isBuyable: boolean;
            activity: {
              __typename?: "Activity";
              uid: string;
              slug_id: string;
              title: string;
              titleTranslated: string | null;
              weekly_meetings: number | null;
              is_ongoing_weekly: boolean;
              duration_weeks: number | null;
              duration_minutes: number | null;
            };
          };
        }>;
      };
      meetings: {
        __typename?: "MeetingConnection";
        edges: Array<{
          __typename?: "MeetingEdge";
          node: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
            sectionSequenceNumber: number;
            section: {
              __typename?: "Section";
              uid: string;
              size_min: number | null;
              size_max: number | null;
              filledSpaceCount: number;
              deleted_at: any | null;
              canceled_at: any | null;
              published_at: any | null;
              start_time: any | null;
              end_time: any | null;
              ongoing_stopped_at: any | null;
              meetingCount: number;
              isBuyable: boolean;
              nextOngoingMeeting: {
                __typename?: "Meeting";
                uid: string;
                start_time: any;
              } | null;
              activity: {
                __typename?: "Activity";
                uid: string;
                slug_id: string;
                title: string;
                titleTranslated: string | null;
                weekly_meetings: number | null;
                is_ongoing_weekly: boolean;
                duration_weeks: number | null;
                duration_minutes: number | null;
              };
            };
          };
        }>;
      };
    } | null;
  } | null;
};

export type CreateSectionWithMeetingsMutationVariables = Exact<{
  activityUid: Scalars["ID"];
  suggestedMeetings: Array<MeetingSuggestionInput> | MeetingSuggestionInput;
  makePublic: Scalars["Boolean"];
}>;

export type CreateSectionWithMeetingsMutation = {
  __typename?: "Mutation";
  createSectionWithMeetings: { __typename?: "Section"; uid: string };
};

export type TeacherStatsQueryQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherStatsQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    publicSectionCount: number;
    leader: {
      __typename?: "Leader";
      uid: string;
      metrics: {
        __typename?: "LeaderMetrics";
        hoursTaught: number;
        learnersTaught: number;
        lifetimeEarningsCents: number;
        monthlySummary: Array<{
          __typename?: "MonthlyLeaderMetric";
          month: string;
          students: number;
          payCents: number;
          hours: number;
        } | null>;
        classBreakdown: Array<{
          __typename?: "ActivityMetric";
          activityUid: string | null;
          activityTitle: string;
          classFormat: ClassFormat;
          sectionsDuringTimeFrame: number;
          uniqueStudents: number;
          hoursTaught: number;
          paidOutCents: number;
          reenrollmentPercentage: number;
          averageStarRating: number;
          numReviews: number;
          numSaves: number;
          numViewers90: number | null;
          numEnrollmentsUnwithdrawn90: number | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type GetPayoutsCsvUrlQueryVariables = Exact<{
  input: GetPayoutsCsvUrlInput;
}>;

export type GetPayoutsCsvUrlQuery = {
  __typename?: "Query";
  getPayoutsCsvUrl: string;
};

export type TeacherTagsQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherTagsQuery = {
  __typename?: "Query";
  teacherTags: {
    __typename?: "TeacherTagsConnection";
    edges: Array<{
      __typename?: "TeacherTagEdge";
      node: { __typename?: "Tag"; uid: string; key: string; value: string };
    }>;
  };
};

export type AssignTeacherTagsMutationMutationVariables = Exact<{
  userUid: Scalars["ID"];
  tags: Array<TeacherTagInput> | TeacherTagInput;
}>;

export type AssignTeacherTagsMutationMutation = {
  __typename?: "Mutation";
  assignTeacherTags: {
    __typename?: "TeacherTagsConnection";
    edges: Array<{
      __typename?: "TeacherTagEdge";
      node: { __typename?: "Tag"; uid: string; key: string; value: string };
    }>;
  };
};

export type TeacherTags_LeaderFragment = {
  __typename?: "Leader";
  uid: string;
  tags: {
    __typename?: "TeacherTagsConnection";
    edges: Array<{
      __typename?: "TeacherTagEdge";
      node: { __typename?: "Tag"; uid: string; key: string; value: string };
    }>;
  };
};

export type ClassWalletEnrollmentTransactionListingFragment = {
  __typename?: "ClassWalletTransactionListing";
  id: string;
  enrollments: Array<
    | {
        __typename?: "Enrollment";
        uid: string;
        purchasedAsGift: boolean;
        confirmedAt: any | null;
        withdrawnAt: any | null;
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
        } | null;
        section: { __typename?: "Section"; uid: string } | null;
      }
    | {
        __typename?: "GiftEnrollment";
        uid: string;
        purchasedAsGift: boolean;
        confirmedAt: any | null;
        withdrawnAt: any | null;
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
        } | null;
        section: { __typename?: "Section"; uid: string };
      }
  > | null;
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    titleTranslated: string | null;
    is_ongoing_weekly: boolean;
  } | null;
  pendingOrderPayments: Array<{
    __typename?: "PendingOrderPayment";
    uid: string;
    amount_cents: number | null;
    exchange_rate: number | null;
    currency_code: string | null;
    coupon_code_uid: string | null;
  }> | null;
};

export type CreditPurchaseTransactionListingFragment = {
  __typename?: "OrderTransactionListing";
  payments: Array<{
    __typename?: "OrderPayment";
    uid: string;
    amount_cents: number;
    amount_credits: number | null;
    tender_type: string | null;
  }> | null;
  creditPurchase: {
    __typename?: "CreditPurchase";
    amount_cents: number;
    amount_granted_cents: number;
    amount_granted_credits: number | null;
    creditAccount: {
      __typename?: "CreditAccount";
      accountType: CreditAccountType | null;
    } | null;
  } | null;
};

export type EnrollmentTransactionListingFragment = {
  __typename?: "OrderTransactionListing";
  donation: {
    __typename?: "Donation";
    uid: string;
    amountCents: number;
    payments: Array<{
      __typename?: "OrderPayment";
      amount_cents: number;
    }> | null;
  } | null;
  payments: Array<{
    __typename?: "OrderPayment";
    uid: string;
    amount_cents: number;
    amount_credits: number | null;
    tender_type: string | null;
  }> | null;
  enrollments: Array<
    | {
        __typename?: "Enrollment";
        uid: string;
        purchasedAsGift: boolean;
        confirmedAt: any | null;
        withdrawnAt: any | null;
        hasPaymentSchedule: boolean;
        orderSlugId: string | null;
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
        } | null;
        section: {
          __typename?: "Section";
          uid: string;
          activity: {
            __typename?: "Activity";
            uid: string;
            title: string;
            titleTranslated: string | null;
            is_ongoing_weekly: boolean;
          };
        } | null;
      }
    | {
        __typename?: "GiftEnrollment";
        uid: string;
        purchasedAsGift: boolean;
        confirmedAt: any | null;
        withdrawnAt: any | null;
        hasPaymentSchedule: boolean;
        orderSlugId: string | null;
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
        } | null;
        section: {
          __typename?: "Section";
          uid: string;
          activity: {
            __typename?: "Activity";
            uid: string;
            title: string;
            titleTranslated: string | null;
            is_ongoing_weekly: boolean;
          };
        };
      }
  > | null;
  enrollmentPayments: Array<{
    __typename?: "EnrollmentPayment";
    amount_cents: number | null;
    exchange_rate: number | null;
    currency_code: CurrencyCode | null;
    credit_transaction_uid: string | null;
    pricing_offer_uid: string | null;
    esa_order_id: string | null;
    start_time: any | null;
    end_time: any | null;
    service_fee_percentage: number | null;
    refunds: Array<{
      __typename?: "EnrollmentPaymentRefund";
      uid: string;
      amount_cents: number;
      amount_credits: number | null;
      addOutschoolCreditTransaction: {
        __typename?: "CreditTransaction";
        uid: string;
        amount_cents: number;
        expiresAt: any | null;
        unspentCents: number | null;
      } | null;
    }>;
    couponCode: { __typename?: "CouponCode"; uid: string } | null;
  }> | null;
};

export type GiftCardPurchaseTransactionListingFragment = {
  __typename?: "OrderTransactionListing";
  giftCard: {
    __typename?: "GiftCard";
    uid: string;
    recipientName: string;
    note: string | null;
    payments: Array<{ __typename?: "OrderPayment"; amount_cents: number }>;
  } | null;
};

export type GiftCardRedemptionTransactionListingFragment = {
  __typename?: "CreditTransactionListing";
  creditTransaction: {
    __typename?: "CreditTransaction";
    uid: string;
    amount_cents: number;
    transaction_type: CreditTransactionType;
    referralTransactionType: CreditReferralTransactionType | null;
    expiresAt: any | null;
    unspentCents: number | null;
    redeemedGiftCard: {
      __typename?: "GiftCard";
      purchaserName: string;
      note: string | null;
      redemptionCode: string | null;
    } | null;
  };
};

export type LearnerAllowancePaymentTransactionListingFragment = {
  __typename?: "OrderTransactionListing";
  learnerAllowancePayments: Array<{
    __typename?: "LearnerAllowancePayment";
    uid: string;
    learnerName: string | null;
    confirmedAt: any | null;
    amountCents: number | null;
    isRecurringPayment: boolean | null;
  }> | null;
};

export type LearnerAllowanceWithdrawalTransactionListingFragment = {
  __typename?: "CreditTransactionListing";
  creditTransaction: {
    __typename?: "CreditTransaction";
    uid: string;
    transaction_type: CreditTransactionType;
    amount_cents: number;
    referralTransactionType: CreditReferralTransactionType | null;
    expiresAt: any | null;
    unspentCents: number | null;
    referredUser: { __typename?: "User"; name: string | null } | null;
    referringUser: { __typename?: "User"; name: string | null } | null;
  };
};

export type ReferralTransactionListingFragment = {
  __typename?: "CreditTransactionListing";
  creditTransaction: {
    __typename?: "CreditTransaction";
    uid: string;
    amount_cents: number;
    transaction_type: CreditTransactionType;
    referralTransactionType: CreditReferralTransactionType | null;
    expiresAt: any | null;
    unspentCents: number | null;
    referredUser: { __typename?: "User"; name: string | null } | null;
    referringUser: { __typename?: "User"; name: string | null } | null;
  };
};

export type SubscriptionCreditDepositTransactionListingFragment = {
  __typename?: "CreditTransactionListing";
  creditTransaction: {
    __typename?: "CreditTransaction";
    transaction_type: CreditTransactionType;
    amount_credits: number;
  };
  subscriptionInvoice: {
    __typename?: "SubscriptionInvoice";
    period_start: any;
    period_end: any;
    amount_paid: number;
    currency_code: string;
    invoice_url: string | null;
    wasRefunded: boolean | null;
  } | null;
};

export type OrderTransactionListingFragmentFragment = {
  __typename?: "OrderTransactionListing";
  giftCard: {
    __typename?: "GiftCard";
    uid: string;
    recipientName: string;
    note: string | null;
    payments: Array<{ __typename?: "OrderPayment"; amount_cents: number }>;
  } | null;
  donation: {
    __typename?: "Donation";
    uid: string;
    amountCents: number;
    payments: Array<{
      __typename?: "OrderPayment";
      amount_cents: number;
    }> | null;
  } | null;
  payments: Array<{
    __typename?: "OrderPayment";
    uid: string;
    amount_cents: number;
    amount_credits: number | null;
    tender_type: string | null;
  }> | null;
  enrollments: Array<
    | {
        __typename?: "Enrollment";
        uid: string;
        purchasedAsGift: boolean;
        confirmedAt: any | null;
        withdrawnAt: any | null;
        hasPaymentSchedule: boolean;
        orderSlugId: string | null;
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
        } | null;
        section: {
          __typename?: "Section";
          uid: string;
          activity: {
            __typename?: "Activity";
            uid: string;
            title: string;
            titleTranslated: string | null;
            is_ongoing_weekly: boolean;
          };
        } | null;
      }
    | {
        __typename?: "GiftEnrollment";
        uid: string;
        purchasedAsGift: boolean;
        confirmedAt: any | null;
        withdrawnAt: any | null;
        hasPaymentSchedule: boolean;
        orderSlugId: string | null;
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
        } | null;
        section: {
          __typename?: "Section";
          uid: string;
          activity: {
            __typename?: "Activity";
            uid: string;
            title: string;
            titleTranslated: string | null;
            is_ongoing_weekly: boolean;
          };
        };
      }
  > | null;
  enrollmentPayments: Array<{
    __typename?: "EnrollmentPayment";
    amount_cents: number | null;
    exchange_rate: number | null;
    currency_code: CurrencyCode | null;
    credit_transaction_uid: string | null;
    pricing_offer_uid: string | null;
    esa_order_id: string | null;
    start_time: any | null;
    end_time: any | null;
    service_fee_percentage: number | null;
    refunds: Array<{
      __typename?: "EnrollmentPaymentRefund";
      uid: string;
      amount_cents: number;
      amount_credits: number | null;
      addOutschoolCreditTransaction: {
        __typename?: "CreditTransaction";
        uid: string;
        amount_cents: number;
        expiresAt: any | null;
        unspentCents: number | null;
      } | null;
    }>;
    couponCode: { __typename?: "CouponCode"; uid: string } | null;
  }> | null;
  learnerAllowancePayments: Array<{
    __typename?: "LearnerAllowancePayment";
    uid: string;
    learnerName: string | null;
    confirmedAt: any | null;
    amountCents: number | null;
    isRecurringPayment: boolean | null;
  }> | null;
  creditPurchase: {
    __typename?: "CreditPurchase";
    amount_cents: number;
    amount_granted_cents: number;
    amount_granted_credits: number | null;
    creditAccount: {
      __typename?: "CreditAccount";
      accountType: CreditAccountType | null;
    } | null;
  } | null;
};

export type CreditTransactionListingFragmentFragment = {
  __typename?: "CreditTransactionListing";
  creditTransaction: {
    __typename?: "CreditTransaction";
    uid: string;
    amount_cents: number;
    transaction_type: CreditTransactionType;
    referralTransactionType: CreditReferralTransactionType | null;
    expiresAt: any | null;
    unspentCents: number | null;
    admin_note: string | null;
    amount_credits: number;
    redeemedGiftCard: {
      __typename?: "GiftCard";
      purchaserName: string;
      note: string | null;
      redemptionCode: string | null;
    } | null;
    referredUser: { __typename?: "User"; name: string | null } | null;
    referringUser: { __typename?: "User"; name: string | null } | null;
  };
  subscriptionInvoice: {
    __typename?: "SubscriptionInvoice";
    period_start: any;
    period_end: any;
    amount_paid: number;
    currency_code: string;
    invoice_url: string | null;
    wasRefunded: boolean | null;
  } | null;
};

export type ClassWalletTransactionListingFragmentFragment = {
  __typename?: "ClassWalletTransactionListing";
  id: string;
  enrollments: Array<
    | {
        __typename?: "Enrollment";
        uid: string;
        purchasedAsGift: boolean;
        confirmedAt: any | null;
        withdrawnAt: any | null;
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
        } | null;
        section: { __typename?: "Section"; uid: string } | null;
      }
    | {
        __typename?: "GiftEnrollment";
        uid: string;
        purchasedAsGift: boolean;
        confirmedAt: any | null;
        withdrawnAt: any | null;
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
        } | null;
        section: { __typename?: "Section"; uid: string };
      }
  > | null;
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    titleTranslated: string | null;
    is_ongoing_weekly: boolean;
  } | null;
  pendingOrderPayments: Array<{
    __typename?: "PendingOrderPayment";
    uid: string;
    amount_cents: number | null;
    exchange_rate: number | null;
    currency_code: string | null;
    coupon_code_uid: string | null;
  }> | null;
};

export type TransactionListingsQueryVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
}>;

export type TransactionListingsQuery = {
  __typename?: "Query";
  transactionListings: {
    __typename?: "TransactionListings";
    totalCount: number;
    data: Array<
      | {
          __typename?: "ClassWalletTransactionListing";
          id: string;
          date: any;
          type: TransactionType;
          enrollments: Array<
            | {
                __typename?: "Enrollment";
                uid: string;
                purchasedAsGift: boolean;
                confirmedAt: any | null;
                withdrawnAt: any | null;
                learner: {
                  __typename?: "Learner";
                  uid: string;
                  name: string | null;
                } | null;
                section: { __typename?: "Section"; uid: string } | null;
              }
            | {
                __typename?: "GiftEnrollment";
                uid: string;
                purchasedAsGift: boolean;
                confirmedAt: any | null;
                withdrawnAt: any | null;
                learner: {
                  __typename?: "Learner";
                  uid: string;
                  name: string | null;
                } | null;
                section: { __typename?: "Section"; uid: string };
              }
          > | null;
          activity: {
            __typename?: "Activity";
            uid: string;
            title: string;
            titleTranslated: string | null;
            is_ongoing_weekly: boolean;
          } | null;
          pendingOrderPayments: Array<{
            __typename?: "PendingOrderPayment";
            uid: string;
            amount_cents: number | null;
            exchange_rate: number | null;
            currency_code: string | null;
            coupon_code_uid: string | null;
          }> | null;
        }
      | {
          __typename?: "CreditTransactionListing";
          id: string;
          date: any;
          type: TransactionType;
          creditTransaction: {
            __typename?: "CreditTransaction";
            uid: string;
            amount_cents: number;
            transaction_type: CreditTransactionType;
            referralTransactionType: CreditReferralTransactionType | null;
            expiresAt: any | null;
            unspentCents: number | null;
            admin_note: string | null;
            amount_credits: number;
            redeemedGiftCard: {
              __typename?: "GiftCard";
              purchaserName: string;
              note: string | null;
              redemptionCode: string | null;
            } | null;
            referredUser: { __typename?: "User"; name: string | null } | null;
            referringUser: { __typename?: "User"; name: string | null } | null;
          };
          subscriptionInvoice: {
            __typename?: "SubscriptionInvoice";
            period_start: any;
            period_end: any;
            amount_paid: number;
            currency_code: string;
            invoice_url: string | null;
            wasRefunded: boolean | null;
          } | null;
        }
      | {
          __typename?: "OrderTransactionListing";
          id: string;
          date: any;
          type: TransactionType;
          giftCard: {
            __typename?: "GiftCard";
            uid: string;
            recipientName: string;
            note: string | null;
            payments: Array<{
              __typename?: "OrderPayment";
              amount_cents: number;
            }>;
          } | null;
          donation: {
            __typename?: "Donation";
            uid: string;
            amountCents: number;
            payments: Array<{
              __typename?: "OrderPayment";
              amount_cents: number;
            }> | null;
          } | null;
          payments: Array<{
            __typename?: "OrderPayment";
            uid: string;
            amount_cents: number;
            amount_credits: number | null;
            tender_type: string | null;
          }> | null;
          enrollments: Array<
            | {
                __typename?: "Enrollment";
                uid: string;
                purchasedAsGift: boolean;
                confirmedAt: any | null;
                withdrawnAt: any | null;
                hasPaymentSchedule: boolean;
                orderSlugId: string | null;
                learner: {
                  __typename?: "Learner";
                  uid: string;
                  name: string | null;
                } | null;
                section: {
                  __typename?: "Section";
                  uid: string;
                  activity: {
                    __typename?: "Activity";
                    uid: string;
                    title: string;
                    titleTranslated: string | null;
                    is_ongoing_weekly: boolean;
                  };
                } | null;
              }
            | {
                __typename?: "GiftEnrollment";
                uid: string;
                purchasedAsGift: boolean;
                confirmedAt: any | null;
                withdrawnAt: any | null;
                hasPaymentSchedule: boolean;
                orderSlugId: string | null;
                learner: {
                  __typename?: "Learner";
                  uid: string;
                  name: string | null;
                } | null;
                section: {
                  __typename?: "Section";
                  uid: string;
                  activity: {
                    __typename?: "Activity";
                    uid: string;
                    title: string;
                    titleTranslated: string | null;
                    is_ongoing_weekly: boolean;
                  };
                };
              }
          > | null;
          enrollmentPayments: Array<{
            __typename?: "EnrollmentPayment";
            amount_cents: number | null;
            exchange_rate: number | null;
            currency_code: CurrencyCode | null;
            credit_transaction_uid: string | null;
            pricing_offer_uid: string | null;
            esa_order_id: string | null;
            start_time: any | null;
            end_time: any | null;
            service_fee_percentage: number | null;
            refunds: Array<{
              __typename?: "EnrollmentPaymentRefund";
              uid: string;
              amount_cents: number;
              amount_credits: number | null;
              addOutschoolCreditTransaction: {
                __typename?: "CreditTransaction";
                uid: string;
                amount_cents: number;
                expiresAt: any | null;
                unspentCents: number | null;
              } | null;
            }>;
            couponCode: { __typename?: "CouponCode"; uid: string } | null;
          }> | null;
          learnerAllowancePayments: Array<{
            __typename?: "LearnerAllowancePayment";
            uid: string;
            learnerName: string | null;
            confirmedAt: any | null;
            amountCents: number | null;
            isRecurringPayment: boolean | null;
          }> | null;
          creditPurchase: {
            __typename?: "CreditPurchase";
            amount_cents: number;
            amount_granted_cents: number;
            amount_granted_credits: number | null;
            creditAccount: {
              __typename?: "CreditAccount";
              accountType: CreditAccountType | null;
            } | null;
          } | null;
        }
    >;
  };
};

export type UrbanSitterCreditTransactionListingFragment = {
  __typename?: "CreditTransactionListing";
  creditTransaction: {
    __typename?: "CreditTransaction";
    uid: string;
    amount_cents: number;
    transaction_type: CreditTransactionType;
    expiresAt: any | null;
    unspentCents: number | null;
  };
};

export type UseOtherFundsForMembershipsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UseOtherFundsForMembershipsQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    useOtherFundsForMemberships: boolean;
  } | null;
};

export type UpdateUseOtherFundsForMembershipsMutationVariables = Exact<{
  useOtherFundsForMemberships: Scalars["Boolean"];
}>;

export type UpdateUseOtherFundsForMembershipsMutation = {
  __typename?: "Mutation";
  updateCurrentUser: {
    __typename?: "User";
    uid: string;
    useOtherFundsForMemberships: boolean;
  };
};

export type VoucherTransactionListingFragment = {
  __typename?: "CreditTransactionListing";
  creditTransaction: {
    __typename?: "CreditTransaction";
    uid: string;
    amount_cents: number;
    transaction_type: CreditTransactionType;
    admin_note: string | null;
    expiresAt: any | null;
    unspentCents: number | null;
  };
};

export type CategoryPageGradeQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CategoryPageGradeQueryQuery = {
  __typename?: "Query";
  categoryPages: Array<{
    __typename?: "CategoryPage";
    nameTitleCased: string;
    basePath: string;
    slug: string;
  }> | null;
};

export type ContentfulTutoringPageSubjectSubcategoriesQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
  subject: Scalars["String"];
}>;

export type ContentfulTutoringPageSubjectSubcategoriesQuery = {
  __typename?: "Query";
  contentfulTutoringPageCollection: {
    __typename?: "ContentfulTutoringPageCollection";
    items: Array<{
      __typename?: "ContentfulTutoringPage";
      carouselSubcategoriesCollection: {
        __typename?: "ContentfulTutoringPageCarouselSubcategoriesCollection";
        items: Array<{
          __typename?: "ContentfulTopic";
          nameTitleCased: string | null;
          slug: string | null;
          subject: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type ContentfulTutoringPageQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ContentfulTutoringPageQuery = {
  __typename?: "Query";
  contentfulTutoringPageCollection: {
    __typename?: "ContentfulTutoringPageCollection";
    items: Array<{
      __typename?: "ContentfulTutoringPage";
      title: string | null;
      visualLinkGridsCollection: {
        __typename?: "ContentfulTutoringPageVisualLinkGridsCollection";
        items: Array<{
          __typename?: "ContentfulVisualLinkGrid";
          title: string | null;
          style: string | null;
          visualLinksCollection: {
            __typename?: "ContentfulVisualLinkGridVisualLinksCollection";
            items: Array<{
              __typename?: "ContentfulVisualLink";
              text: string | null;
              path: string | null;
              imageAltText: string | null;
              imageAspectRatio: string | null;
              image: {
                __typename?: "ContentfulAsset";
                url: string | null;
              } | null;
            } | null>;
          } | null;
        } | null>;
      } | null;
      articlesCollection: {
        __typename?: "ContentfulTutoringPageArticlesCollection";
        items: Array<{
          __typename?: "ContentfulMagnetPage";
          magnetPageSlug: string | null;
          title: string | null;
          categoryLabel: {
            __typename?: "ContentfulCategoryLabel";
            value: string | null;
          } | null;
          author: {
            __typename?: "ContentfulAuthor";
            name: string | null;
            profileImage: {
              __typename?: "ContentfulAsset";
              url: string | null;
            } | null;
          } | null;
          headerImage: {
            __typename?: "ContentfulAsset";
            url: string | null;
          } | null;
        } | null>;
      } | null;
      popularTutoringSubjectsCollection: {
        __typename?: "ContentfulTutoringPagePopularTutoringSubjectsCollection";
        items: Array<{
          __typename?: "ContentfulVisualLink";
          text: string | null;
          path: string | null;
          pathB: string | null;
          image: { __typename?: "ContentfulAsset"; url: string | null } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type ContentfulPopularSearchesQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ContentfulPopularSearchesQuery = {
  __typename?: "Query";
  contentfulTutoringPageCollection: {
    __typename?: "ContentfulTutoringPageCollection";
    items: Array<{
      __typename?: "ContentfulTutoringPage";
      popularSearchLinksCollection: {
        __typename?: "ContentfulTutoringPagePopularSearchLinksCollection";
        items: Array<{
          __typename?: "ContentfulVisualLink";
          text: string | null;
          path: string | null;
          pathB: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type FeaturedTutorsQueryVariables = Exact<{
  activityFormat?: InputMaybe<ClassFormat>;
}>;

export type FeaturedTutorsQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    popularTeachers: Array<{
      __typename?: "Leader";
      uid: string;
      name: string | null;
      leader_link: string | null;
      created_at: any | null;
      photo: string | null;
      suspended_at: any | null;
      cityState: string | null;
      socialOptOut: boolean;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      hasBackgroundCheckExpired: boolean | null;
      reviewCount: number;
      averageActivityStarRating: number | null;
      hasListings: boolean;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        headlineTranslated: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
    }> | null;
  };
};

export type TutoringRelatedCategoriesQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TutoringRelatedCategoriesQueryQuery = {
  __typename?: "Query";
  categoryPage: {
    __typename?: "CategoryPage";
    related: Array<{
      __typename?: "CategoryPage";
      nameTitleCased: string;
      slug: string;
      basePath: string;
    } | null> | null;
    children: Array<{
      __typename?: "CategoryPage";
      nameTitleCased: string;
      slug: string;
      basePath: string;
    } | null> | null;
  } | null;
};

export type Dashboard_EnrollmentsSummary_ByUserFragment = {
  __typename?: "PricingOfferEnrollmentsSummary";
  byUser: Array<{
    __typename?: "PricingOffersSummaryByUser";
    name: string;
    email: string;
    secondaryEmail: string | null;
    totalLimit: number | null;
    totalSpend: number;
    totalEnrollments: number;
    totalLearnerMinutes: number;
  } | null>;
};

export type Dashboard_EnrollmentsSummaryFragment = {
  __typename?: "PricingOfferEnrollmentsSummary";
  learnerCount: number;
  enrollmentCount: number;
  bySubject: Array<{
    __typename?: "PricingOffersSummaryGroup";
    label: string;
    count: number;
  } | null>;
  byClassType: Array<{
    __typename?: "PricingOffersSummaryGroup";
    label: string;
    count: number;
  } | null>;
  byLearnerAge: Array<{
    __typename?: "PricingOffersSummaryGroup";
    label: string;
    count: number;
  } | null>;
  learnersByUser: Array<{
    __typename?: "PricingOffersSummaryGroup";
    label: string;
    count: number;
  } | null>;
  byActivity: Array<{
    __typename?: "PricingOffersSummaryByActivity";
    title: string;
    subject: string;
    classType: string;
    priceCents: number;
    totalEnrollments: number;
    totalSpend: number;
  } | null>;
  byUser: Array<{
    __typename?: "PricingOffersSummaryByUser";
    name: string;
    email: string;
    secondaryEmail: string | null;
    totalLimit: number | null;
    totalSpend: number;
    totalEnrollments: number;
    totalLearnerMinutes: number;
  } | null>;
};

export type Dashboard_PaymentsByWeekFragment = {
  __typename?: "PricingOffersPaymentsByWeek";
  weekStart: any;
  userCount: number;
  newUserCount: number;
  totalSpend: number;
};

export type Dashboard_TransactionsFragment = {
  __typename?: "PricingOffersTransaction";
  name: string;
  email: string;
  secondaryEmail: string | null;
  day: any;
  amountCents: number | null;
  amountRefundedCents: number | null;
};

export type PricingOfferDashboardQueryVariables = Exact<{
  uid: Scalars["String"];
}>;

export type PricingOfferDashboardQuery = {
  __typename?: "Query";
  pricingOffer: {
    __typename?: "PricingOffer";
    uid: string;
    name: string;
    buyerOrgName: string | null;
    offerRemainingCapCents: number | null;
    totalCapCents: number;
    userLimitCents: number;
  };
  pricingOfferEnrollmentsSummary: {
    __typename?: "PricingOfferEnrollmentsSummary";
    learnerCount: number;
    enrollmentCount: number;
    bySubject: Array<{
      __typename?: "PricingOffersSummaryGroup";
      label: string;
      count: number;
    } | null>;
    byClassType: Array<{
      __typename?: "PricingOffersSummaryGroup";
      label: string;
      count: number;
    } | null>;
    byLearnerAge: Array<{
      __typename?: "PricingOffersSummaryGroup";
      label: string;
      count: number;
    } | null>;
    learnersByUser: Array<{
      __typename?: "PricingOffersSummaryGroup";
      label: string;
      count: number;
    } | null>;
    byActivity: Array<{
      __typename?: "PricingOffersSummaryByActivity";
      title: string;
      subject: string;
      classType: string;
      priceCents: number;
      totalEnrollments: number;
      totalSpend: number;
    } | null>;
    byUser: Array<{
      __typename?: "PricingOffersSummaryByUser";
      name: string;
      email: string;
      secondaryEmail: string | null;
      totalLimit: number | null;
      totalSpend: number;
      totalEnrollments: number;
      totalLearnerMinutes: number;
    } | null>;
  };
  pricingOfferPaymentsByWeek: Array<{
    __typename?: "PricingOffersPaymentsByWeek";
    weekStart: any;
    userCount: number;
    newUserCount: number;
    totalSpend: number;
  } | null>;
};

export type PricingOfferOrgDashboardQueryVariables = Exact<{
  organizationUid: Scalars["String"];
}>;

export type PricingOfferOrgDashboardQuery = {
  __typename?: "Query";
  organization: {
    __typename?: "Organization";
    uid: string;
    name: string;
    totalCapCents: number | null;
    pricingOffers: Array<{
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      buyerOrgName: string | null;
      offerRemainingCapCents: number | null;
      totalCapCents: number;
      userLimitCents: number;
      deletedAt: any | null;
    }>;
  } | null;
  pricingOfferEnrollmentsSummary: {
    __typename?: "PricingOfferEnrollmentsSummary";
    learnerCount: number;
    enrollmentCount: number;
    bySubject: Array<{
      __typename?: "PricingOffersSummaryGroup";
      label: string;
      count: number;
    } | null>;
    byClassType: Array<{
      __typename?: "PricingOffersSummaryGroup";
      label: string;
      count: number;
    } | null>;
    byLearnerAge: Array<{
      __typename?: "PricingOffersSummaryGroup";
      label: string;
      count: number;
    } | null>;
    learnersByUser: Array<{
      __typename?: "PricingOffersSummaryGroup";
      label: string;
      count: number;
    } | null>;
    byActivity: Array<{
      __typename?: "PricingOffersSummaryByActivity";
      title: string;
      subject: string;
      classType: string;
      priceCents: number;
      totalEnrollments: number;
      totalSpend: number;
    } | null>;
    byUser: Array<{
      __typename?: "PricingOffersSummaryByUser";
      name: string;
      email: string;
      secondaryEmail: string | null;
      totalLimit: number | null;
      totalSpend: number;
      totalEnrollments: number;
      totalLearnerMinutes: number;
    } | null>;
  };
  pricingOfferPaymentsByWeek: Array<{
    __typename?: "PricingOffersPaymentsByWeek";
    weekStart: any;
    userCount: number;
    newUserCount: number;
    totalSpend: number;
  } | null>;
};

export type PricingOfferEnrollmentsSummaryByUserQueryVariables = Exact<{
  uid: Scalars["String"];
  dateFilter?: InputMaybe<DateFilterInput>;
}>;

export type PricingOfferEnrollmentsSummaryByUserQuery = {
  __typename?: "Query";
  pricingOfferEnrollmentsSummary: {
    __typename?: "PricingOfferEnrollmentsSummary";
    byUser: Array<{
      __typename?: "PricingOffersSummaryByUser";
      name: string;
      email: string;
      secondaryEmail: string | null;
      totalLimit: number | null;
      totalSpend: number;
      totalEnrollments: number;
      totalLearnerMinutes: number;
    } | null>;
  };
};

export type PricingOfferOrgEnrollmentsSummaryByUserQueryVariables = Exact<{
  organizationUid: Scalars["String"];
  dateFilter?: InputMaybe<DateFilterInput>;
}>;

export type PricingOfferOrgEnrollmentsSummaryByUserQuery = {
  __typename?: "Query";
  pricingOfferEnrollmentsSummary: {
    __typename?: "PricingOfferEnrollmentsSummary";
    byUser: Array<{
      __typename?: "PricingOffersSummaryByUser";
      name: string;
      email: string;
      secondaryEmail: string | null;
      totalLimit: number | null;
      totalSpend: number;
      totalEnrollments: number;
      totalLearnerMinutes: number;
    } | null>;
  };
};

export type PricingOfferTransactionsQueryVariables = Exact<{
  uid: Scalars["String"];
  dateFilter?: InputMaybe<DateFilterInput>;
}>;

export type PricingOfferTransactionsQuery = {
  __typename?: "Query";
  pricingOfferTransactions: Array<{
    __typename?: "PricingOffersTransaction";
    name: string;
    email: string;
    secondaryEmail: string | null;
    day: any;
    amountCents: number | null;
    amountRefundedCents: number | null;
  } | null>;
};

export type PricingOfferOrgTransactionsQueryVariables = Exact<{
  organizationUid: Scalars["String"];
  dateFilter?: InputMaybe<DateFilterInput>;
}>;

export type PricingOfferOrgTransactionsQuery = {
  __typename?: "Query";
  pricingOfferTransactions: Array<{
    __typename?: "PricingOffersTransaction";
    name: string;
    email: string;
    secondaryEmail: string | null;
    day: any;
    amountCents: number | null;
    amountRefundedCents: number | null;
  } | null>;
};

export type CurrentUserHasEnrolledQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentUserHasEnrolledQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    hasEnrolled: boolean;
  } | null;
};

export type CurrentUserPricingOfferAdminQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentUserPricingOfferAdminQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    pricingOfferAdmin: {
      __typename?: "OrganizationAdmin";
      organization: { __typename?: "Organization"; uid: string };
    } | null;
  } | null;
};

export type CurrentUserPaymentMethodsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentUserPaymentMethodsQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    paymentMethods: Array<{
      __typename?: "PaymentMethod";
      uid: string;
      type: PaymentMethodType;
      brand: string;
      last4: string;
      expirationMonth: number;
      expirationYear: number;
    }>;
  } | null;
};

export type DeletePaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars["ID"];
}>;

export type DeletePaymentMethodMutation = {
  __typename?: "Mutation";
  deletePaymentMethod: boolean;
};

export type CreateSetupIntentMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateSetupIntentMutation = {
  __typename?: "Mutation";
  createSetupIntent: {
    __typename?: "SetupIntent";
    setupIntentClientId: string;
  };
};

export type CurrentUserPricingOffersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentUserPricingOffersQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    pricingOffers: Array<{
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      organizationUid: string | null;
      remainingCapCents: number | null;
      discount: number | null;
      isFinancialAid: boolean;
      deletedAt: any | null;
    }> | null;
  } | null;
};

export type EntityAvailabilityFragmentFragment = {
  __typename?: "EntityAvailability";
  uid: string;
  day: number;
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
};

export type EntityAvailabilityScheduleQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type EntityAvailabilityScheduleQuery = {
  __typename?: "Query";
  entityAvailabilitySchedule: {
    __typename?: "EntityAvailabilitySchedule";
    uid: string;
    entityAvailabilities: Array<{
      __typename?: "EntityAvailability";
      uid: string;
      day: number;
      startHour: number;
      startMinute: number;
      endHour: number;
      endMinute: number;
    }>;
  };
};

export type CreateLearnerPlanScheduleMutationVariables = Exact<{
  input: CreateLearnerPlanScheduleInput;
}>;

export type CreateLearnerPlanScheduleMutation = {
  __typename?: "Mutation";
  createLearnerPlanSchedule: {
    __typename?: "EntityAvailabilitySchedule";
    uid: string;
    entityAvailabilities: Array<{
      __typename?: "EntityAvailability";
      uid: string;
      day: number;
      startHour: number;
      startMinute: number;
      endHour: number;
      endMinute: number;
    }>;
  };
};

export type UpdateEntityAvailabilityScheduleMutationVariables = Exact<{
  input: UpdateEntityAvailabilityScheduleInput;
}>;

export type UpdateEntityAvailabilityScheduleMutation = {
  __typename?: "Mutation";
  updateEntityAvailabilitySchedule: {
    __typename?: "EntityAvailabilitySchedule";
    uid: string;
    entityAvailabilities: Array<{
      __typename?: "EntityAvailability";
      uid: string;
      day: number;
      startHour: number;
      startMinute: number;
      endHour: number;
      endMinute: number;
    }>;
  };
};

export type GiftCardFragmentFragment = {
  __typename?: "GiftCard";
  uid: string;
  orderSlugId: string;
  amountCents: number;
  note: string | null;
  recipientEmail: string;
  confirmedAt: any | null;
  redemptionCode: string | null;
  deliverAfter: any;
  deliveryTimezone: string;
  purchaser: { __typename?: "GiftCardPurchaser"; name: string; email: string };
  payments: Array<{ __typename?: "OrderPayment"; last4: string | null }>;
};

export type GiftCardAdminFragmentFragment = {
  __typename?: "GiftCard";
  uid: string;
  createdAt: any;
  orderSlugId: string;
  amountCents: number;
  note: string | null;
  recipientName: string;
  recipientEmail: string;
  purchaserName: string;
  purchaserEmail: string;
  confirmedAt: any | null;
  redeemedAt: any | null;
  deliveredAt: any | null;
  deletedAt: any | null;
  redemptionCode: string | null;
  deliverAfter: any;
  deliveryTimezone: string;
  status: GiftCardStatus;
  isCancelable: boolean;
  isEditable: boolean;
};

export type GiftCardQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type GiftCardQuery = {
  __typename?: "Query";
  giftCard: {
    __typename?: "GiftCard";
    uid: string;
    orderSlugId: string;
    amountCents: number;
    note: string | null;
    recipientEmail: string;
    confirmedAt: any | null;
    redemptionCode: string | null;
    deliverAfter: any;
    deliveryTimezone: string;
    purchaser: {
      __typename?: "GiftCardPurchaser";
      name: string;
      email: string;
    };
    payments: Array<{ __typename?: "OrderPayment"; last4: string | null }>;
  };
};

export type RedeemGiftCardMutationVariables = Exact<{
  redemptionCode: Scalars["String"];
}>;

export type RedeemGiftCardMutation = {
  __typename?: "Mutation";
  redeemGiftCard: {
    __typename?: "GiftCard";
    uid: string;
    orderSlugId: string;
    amountCents: number;
    note: string | null;
    recipientEmail: string;
    confirmedAt: any | null;
    redemptionCode: string | null;
    deliverAfter: any;
    deliveryTimezone: string;
    purchaser: {
      __typename?: "GiftCardPurchaser";
      name: string;
      email: string;
    };
    payments: Array<{ __typename?: "OrderPayment"; last4: string | null }>;
  };
};

export type AddGiftCardItemWithDeliveryDateToOrderMutationMutationVariables =
  Exact<{
    giftCardInput: GiftCardInputWithDeliveryDate;
  }>;

export type AddGiftCardItemWithDeliveryDateToOrderMutationMutation = {
  __typename?: "Mutation";
  addGiftCardItemWithDeliveryDateToOrder: {
    __typename?: "GiftCard";
    uid: string;
  };
};

export type UpdateGiftCardMutationVariables = Exact<{
  uid: Scalars["ID"];
  giftCardInput: GiftCardUpdateInput;
}>;

export type UpdateGiftCardMutation = {
  __typename?: "Mutation";
  updateGiftCard: {
    __typename?: "GiftCard";
    uid: string;
    createdAt: any;
    orderSlugId: string;
    amountCents: number;
    note: string | null;
    recipientName: string;
    recipientEmail: string;
    purchaserName: string;
    purchaserEmail: string;
    confirmedAt: any | null;
    redeemedAt: any | null;
    deliveredAt: any | null;
    deletedAt: any | null;
    redemptionCode: string | null;
    deliverAfter: any;
    deliveryTimezone: string;
    status: GiftCardStatus;
    isCancelable: boolean;
    isEditable: boolean;
  } | null;
};

export type CancelGiftCardMutationVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type CancelGiftCardMutation = {
  __typename?: "Mutation";
  cancelGiftCard: { __typename?: "GiftCard"; uid: string } | null;
};

export type StartIdVerificationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type StartIdVerificationMutation = {
  __typename?: "Mutation";
  startIdVerification: any | null;
};

export type StartPersonaIdVerificationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type StartPersonaIdVerificationMutation = {
  __typename?: "Mutation";
  startPersonaIdVerification: any | null;
};

export type IdVerificationStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type IdVerificationStatusQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    idVerificationStatus: IdvStatus;
  } | null;
};

export type NextMeetingReminderQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type NextMeetingReminderQueryQuery = {
  __typename?: "Query";
  nextMeetingReminder: {
    __typename?: "NextMeetingReminder";
    uid: string;
    startTime: any | null;
    sectionUid: string;
    activityTitle: string;
  } | null;
};

export type PickLearnerQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type PickLearnerQuery = {
  __typename?: "Query";
  classroom: {
    __typename?: "Classroom";
    uid: string;
    section: {
      __typename?: "Section";
      uid: string;
      currentUserCanManage: boolean;
    };
  };
  learnersToPick: Array<{
    __typename?: "LearnerToPick";
    uid: string;
    name: string;
    age: number;
    enrollmentUid: string;
    isEnrollmentPaused: boolean;
  }>;
};

export type AddChildMutationVariables = Exact<{
  childInput: ChildInput;
}>;

export type AddChildMutation = {
  __typename?: "Mutation";
  addChild: {
    __typename?: "Child";
    uid: string;
    name: string | null;
    age: number | null;
    birthMonth: number | null;
    date_of_birth: any | null;
    birthYear: number | null;
    email: string | null;
    avatar: string | null;
    unlockedAvatars: Array<string>;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    email_confirmed_at: any | null;
    optedOutOfPersonalizedRecommendationsAt: any | null;
    isLoginEnabled: boolean;
    username: string | null;
    hasPassword: boolean;
    attributes: Array<{
      __typename?: "EntityAttribute";
      uid: string;
      key: string;
      value: string;
    }>;
    entityAvailabilitySchedule: {
      __typename?: "EntityAvailabilitySchedule";
      entityAvailabilities: Array<{
        __typename?: "EntityAvailability";
        startHour: number;
        startMinute: number;
        day: number;
        endHour: number;
        endMinute: number;
        uid: string;
      }>;
    } | null;
  };
};

export type UpdateChildMutationVariables = Exact<{
  uid: Scalars["ID"];
  childInput: ChildInput;
}>;

export type UpdateChildMutation = {
  __typename?: "Mutation";
  updateChild: {
    __typename?: "Child";
    uid: string;
    name: string | null;
    age: number | null;
    birthMonth: number | null;
    date_of_birth: any | null;
    birthYear: number | null;
    email: string | null;
    avatar: string | null;
    unlockedAvatars: Array<string>;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    email_confirmed_at: any | null;
    optedOutOfPersonalizedRecommendationsAt: any | null;
    isLoginEnabled: boolean;
    username: string | null;
    hasPassword: boolean;
    attributes: Array<{
      __typename?: "EntityAttribute";
      uid: string;
      key: string;
      value: string;
    }>;
    entityAvailabilitySchedule: {
      __typename?: "EntityAvailabilitySchedule";
      entityAvailabilities: Array<{
        __typename?: "EntityAvailability";
        startHour: number;
        startMinute: number;
        day: number;
        endHour: number;
        endMinute: number;
        uid: string;
      }>;
    } | null;
  };
};

export type DeleteChildMutationVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type DeleteChildMutation = {
  __typename?: "Mutation";
  deleteChild: boolean;
};

export type CheckMeetingConflictsQueryVariables = Exact<{
  query: Array<MeetingConflictsQueryInput> | MeetingConflictsQueryInput;
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
}>;

export type CheckMeetingConflictsQuery = {
  __typename?: "Query";
  checkMeetingConflicts: Array<{
    __typename?: "MeetingConflictResult";
    rangeUid: string;
    section: {
      __typename?: "Section";
      uid: string;
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        titleTranslated: string | null;
        hasTeacherSchedule: boolean;
      };
    };
  }>;
};

export type EnrollmentsQueryVariables = Exact<{
  userOneUid: Scalars["ID"];
  userTwoUid: Scalars["ID"];
}>;

export type EnrollmentsQuery = {
  __typename?: "Query";
  userOneAsParent: {
    __typename?: "Parent";
    uid: string;
    enrollmentsWithTeacher: Array<{ __typename?: "Enrollment"; uid: string }>;
  } | null;
  userTwoAsParent: {
    __typename?: "Parent";
    uid: string;
    enrollmentsWithTeacher: Array<{ __typename?: "Enrollment"; uid: string }>;
  } | null;
};

export type CreateOrderMutationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateOrderMutationMutation = {
  __typename?: "Mutation";
  createOrder: { __typename?: "OrderType"; orderSlugId: string };
};

export type PricingBreakdownQueryQueryVariables = Exact<{
  pricingBreakdownInput: PricingBreakdownInput;
}>;

export type PricingBreakdownQueryQuery = {
  __typename?: "Query";
  pricingBreakdown: {
    __typename?: "PricingBreakdown";
    originalTotalCents: number;
    totalCents: number;
    pricingOfferCents: number;
    pricingOfferState: PricingOfferState;
    storedCreditCents: number;
    creditAccountState: CreditAccountState;
    stripeCents: number;
    couponCents: number;
    couponState: CouponState;
    enrollmentMeetingTotalCents: number | null;
    perEnrollmentCurrentWeekCents: number | null;
    perEnrollmentUpfrontCents: number | null;
    perEnrollmentWeeklyCents: number | null;
    perEnrollmentTotalWeeklyCents: number | null;
    hasProratedEnrollments: boolean | null;
    perEnrollmentUpfrontCredits: number | null;
    perEnrollmentWeeklyCredits: number | null;
    perEnrollmentTotalWeeklyCredits: number | null;
    perEnrollmentCurrentWeekCredits: number | null;
    totalCredits: number | null;
    serviceFeePercentage: number | null;
    subscriptionCredits: number | null;
    subscriptionAccountState: CreditAccountState | null;
    subscriptionOutstandingBalanceCents: number | null;
    subscriptionOutstandingBalanceCredits: number | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userOfferEmail: string | null;
      userLimitCents: number;
      requirePaymentMethod: boolean;
      buyerOrgName: string | null;
      remainingCapCents: number | null;
      organization: { __typename?: "Organization"; name: string } | null;
    } | null;
  };
};

export type BulkPricingBreakdownQueryVariables = Exact<{
  pricingBreakdownInputs: Array<PricingBreakdownInput> | PricingBreakdownInput;
  outschoolCouponCode?: InputMaybe<Scalars["String"]>;
}>;

export type BulkPricingBreakdownQuery = {
  __typename?: "Query";
  bulkPricingBreakdown: {
    __typename?: "BulkPricingBreakdown";
    originalTotalCents: number;
    totalCents: number;
    pricingOfferCents: number;
    totalPayUpfrontCents: number;
    totalWeeklyFirstPaymentCents: number;
    storedCreditCents: number;
    stripeCents: number;
    subscriptionCredits: number | null;
    subscriptionOutstandingBalanceCents: number | null;
    subscriptionOutstandingBalanceCredits: number | null;
    couponCents: number;
    couponState: CouponState;
    itemizedPricingBreakdown: Array<{
      __typename?: "PricingBreakdown";
      uid: string | null;
      totalCents: number;
      pricingOfferState: PricingOfferState;
      perEnrollmentUpfrontCents: number | null;
      perEnrollmentWeeklyCents: number | null;
      perEnrollmentCurrentWeekCents: number | null;
      perEnrollmentTotalWeeklyCents: number | null;
      hasProratedEnrollments: boolean | null;
      couponCents: number;
      couponState: CouponState;
    }>;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userOfferEmail: string | null;
      userLimitCents: number;
      requirePaymentMethod: boolean;
      buyerOrgName: string | null;
      remainingCapCents: number | null;
      organization: { __typename?: "Organization"; name: string } | null;
    } | null;
    weeklyPaymentsCharges: Array<{
      __typename?: "BulkWeeklyPricingBreakdown";
      priceCents: number;
      startOfWeek: any;
    }>;
  };
};

export type OrganizationAdminFragmentFragment = {
  __typename?: "Organization";
  uid: string;
  deletedAt: any | null;
  name: string;
  type: OrganizationType;
  address: string | null;
  state: string | null;
  notes: string | null;
  urlName: string | null;
  logoUrl: string | null;
  invoiceEmail: string | null;
  techSupportEmail: string | null;
  trustSafetyEmail: string | null;
  totalCapCents: number | null;
  pricingOffers: Array<{
    __typename?: "PricingOffer";
    uid: string;
    name: string;
    buyerOrgName: string | null;
    deletedAt: any | null;
  }>;
};

export type ListOrganizationsQueryVariables = Exact<{
  types?: InputMaybe<
    Array<InputMaybe<OrganizationType>> | InputMaybe<OrganizationType>
  >;
  filter?: InputMaybe<OrganizationAdminFilter>;
}>;

export type ListOrganizationsQuery = {
  __typename?: "Query";
  organizations: Array<{
    __typename?: "Organization";
    uid: string;
    name: string;
    type: OrganizationType;
    deletedAt: any | null;
    isEsaOrMicrogrant: boolean | null;
    isOrgCommunityPartner: boolean | null;
  }>;
};

export type CreateOrganizationMutationVariables = Exact<{
  data: CreateOrganizationInput;
}>;

export type CreateOrganizationMutation = {
  __typename?: "Mutation";
  createOrganization: {
    __typename?: "Organization";
    uid: string;
    deletedAt: any | null;
    name: string;
    type: OrganizationType;
    address: string | null;
    state: string | null;
    notes: string | null;
    urlName: string | null;
    logoUrl: string | null;
    invoiceEmail: string | null;
    techSupportEmail: string | null;
    trustSafetyEmail: string | null;
    totalCapCents: number | null;
    pricingOffers: Array<{
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      buyerOrgName: string | null;
      deletedAt: any | null;
    }>;
  };
};

export type LoadOrganizationQueryVariables = Exact<{
  uidOrLink: Scalars["String"];
  includeDeleted?: InputMaybe<Scalars["Boolean"]>;
}>;

export type LoadOrganizationQuery = {
  __typename?: "Query";
  organization: {
    __typename?: "Organization";
    uid: string;
    deletedAt: any | null;
    name: string;
    type: OrganizationType;
    address: string | null;
    state: string | null;
    notes: string | null;
    urlName: string | null;
    logoUrl: string | null;
    invoiceEmail: string | null;
    techSupportEmail: string | null;
    trustSafetyEmail: string | null;
    totalCapCents: number | null;
    pricingOffers: Array<{
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      buyerOrgName: string | null;
      deletedAt: any | null;
    }>;
  } | null;
};

export type EditOrganizationMutationVariables = Exact<{
  uid: Scalars["ID"];
  data: UpdateOrganizationInput;
}>;

export type EditOrganizationMutation = {
  __typename?: "Mutation";
  updateOrganization: {
    __typename?: "Organization";
    uid: string;
    deletedAt: any | null;
    name: string;
    type: OrganizationType;
    address: string | null;
    state: string | null;
    notes: string | null;
    urlName: string | null;
    logoUrl: string | null;
    invoiceEmail: string | null;
    techSupportEmail: string | null;
    trustSafetyEmail: string | null;
    totalCapCents: number | null;
    pricingOffers: Array<{
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      buyerOrgName: string | null;
      deletedAt: any | null;
    }>;
  };
};

export type DeleteOrganizationMutationVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type DeleteOrganizationMutation = {
  __typename?: "Mutation";
  deleteOrganization: {
    __typename?: "Organization";
    uid: string;
    deletedAt: any | null;
    name: string;
    type: OrganizationType;
    address: string | null;
    state: string | null;
    notes: string | null;
    urlName: string | null;
    logoUrl: string | null;
    invoiceEmail: string | null;
    techSupportEmail: string | null;
    trustSafetyEmail: string | null;
    totalCapCents: number | null;
    pricingOffers: Array<{
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      buyerOrgName: string | null;
      deletedAt: any | null;
    }>;
  };
};

export type CreatePaymentScheduleMutationVariables = Exact<{
  createPaymentScheduleInput: CreatePaymentScheduleInput;
}>;

export type CreatePaymentScheduleMutation = {
  __typename?: "Mutation";
  createPaymentSchedule: {
    __typename?: "PaymentSchedule";
    uid: string;
    amountCents: number | null;
    startTime: any;
    nextBillingDate: any;
  };
};

export type StopPaymentScheduleMutationVariables = Exact<{
  stopPaymentScheduleInput: StopPaymentScheduleInput;
}>;

export type StopPaymentScheduleMutation = {
  __typename?: "Mutation";
  stopPaymentSchedule: boolean;
};

export type AdminPricingOffersQueryVariables = Exact<{
  filter: PricingOfferAdminFilter;
}>;

export type AdminPricingOffersQuery = {
  __typename?: "Query";
  pricingOffers: Array<{
    __typename?: "PricingOffer";
    uid: string;
    name: string;
    buyerOrgName: string | null;
    deletedAt: any | null;
  }>;
};

export type PricingOfferAdminFragmentFragment = {
  __typename?: "PricingOffer";
  uid: string;
  name: string;
  buyerOrgName: string | null;
  totalCapCents: number;
  userLimitCents: number;
  startAfter: any;
  endBy: any;
  requirePaymentMethod: boolean;
  discount: number | null;
  isFinancialAid: boolean;
  eligibleEmailDomains: Array<string>;
  deletedAt: any | null;
  eligibilityLastUpdatedAt: any | null;
  includedEmailAddresses: Array<string>;
  excludedEmailAddresses: Array<string>;
  organizationUid: string | null;
};

export type CreatePricingOfferMutationVariables = Exact<{
  data?: InputMaybe<CreatePricingOfferInput>;
}>;

export type CreatePricingOfferMutation = {
  __typename?: "Mutation";
  createPricingOffer: {
    __typename?: "PricingOffer";
    uid: string;
    name: string;
    buyerOrgName: string | null;
    totalCapCents: number;
    userLimitCents: number;
    startAfter: any;
    endBy: any;
    requirePaymentMethod: boolean;
    discount: number | null;
    isFinancialAid: boolean;
    eligibleEmailDomains: Array<string>;
    deletedAt: any | null;
    eligibilityLastUpdatedAt: any | null;
    includedEmailAddresses: Array<string>;
    excludedEmailAddresses: Array<string>;
    organizationUid: string | null;
  };
};

export type AdminPricingOfferQueryVariables = Exact<{
  uid: Scalars["String"];
}>;

export type AdminPricingOfferQuery = {
  __typename?: "Query";
  pricingOffer: {
    __typename?: "PricingOffer";
    uid: string;
    name: string;
    buyerOrgName: string | null;
    totalCapCents: number;
    userLimitCents: number;
    startAfter: any;
    endBy: any;
    requirePaymentMethod: boolean;
    discount: number | null;
    isFinancialAid: boolean;
    eligibleEmailDomains: Array<string>;
    deletedAt: any | null;
    eligibilityLastUpdatedAt: any | null;
    includedEmailAddresses: Array<string>;
    excludedEmailAddresses: Array<string>;
    organizationUid: string | null;
  };
};

export type EditPricingOfferMutationVariables = Exact<{
  uid: Scalars["String"];
  data?: InputMaybe<UpdatePricingOfferInput>;
}>;

export type EditPricingOfferMutation = {
  __typename?: "Mutation";
  updatePricingOffer: {
    __typename?: "PricingOffer";
    uid: string;
    name: string;
    buyerOrgName: string | null;
    totalCapCents: number;
    userLimitCents: number;
    startAfter: any;
    endBy: any;
    requirePaymentMethod: boolean;
    discount: number | null;
    isFinancialAid: boolean;
    eligibleEmailDomains: Array<string>;
    deletedAt: any | null;
    eligibilityLastUpdatedAt: any | null;
    includedEmailAddresses: Array<string>;
    excludedEmailAddresses: Array<string>;
    organizationUid: string | null;
  };
};

export type DeletePricingOfferMutationVariables = Exact<{
  uid: Scalars["String"];
}>;

export type DeletePricingOfferMutation = {
  __typename?: "Mutation";
  deletePricingOffer: {
    __typename?: "PricingOffer";
    uid: string;
    name: string;
    buyerOrgName: string | null;
    totalCapCents: number;
    userLimitCents: number;
    startAfter: any;
    endBy: any;
    requirePaymentMethod: boolean;
    discount: number | null;
    isFinancialAid: boolean;
    eligibleEmailDomains: Array<string>;
    deletedAt: any | null;
    eligibilityLastUpdatedAt: any | null;
    includedEmailAddresses: Array<string>;
    excludedEmailAddresses: Array<string>;
    organizationUid: string | null;
  };
};

export type SellerOrgAvailabilityScheduleSettingsFragment = {
  __typename?: "ScheduleSettings";
  uid: string;
  teacher: { __typename?: "Leader"; uid: string; name: string | null };
};

export type SellerOrgAvailabilityQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type SellerOrgAvailabilityQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    scheduleSettings: Array<{
      __typename?: "ScheduleSettings";
      uid: string;
      teacher: { __typename?: "Leader"; uid: string; name: string | null };
    }>;
  };
};

export type AssignOrgTeacherToActivityScheduleMutationVariables = Exact<{
  orgTeacherUid: Scalars["String"];
  activityUid: Scalars["String"];
}>;

export type AssignOrgTeacherToActivityScheduleMutation = {
  __typename?: "Mutation";
  assignOrgTeacherToActivitySchedule: {
    __typename?: "ScheduleSettings";
    uid: string;
    teacher: { __typename?: "Leader"; uid: string; name: string | null };
  };
};

export type UnassignOrgTeacherToActivityScheduleMutationVariables = Exact<{
  orgTeacherUid: Scalars["String"];
  activityUid: Scalars["String"];
}>;

export type UnassignOrgTeacherToActivityScheduleMutation = {
  __typename?: "Mutation";
  unassignOrgTeacherToActivitySchedule: {
    __typename?: "ScheduleSettings";
    uid: string;
    teacher: { __typename?: "Leader"; uid: string; name: string | null };
  };
};

export type SpellingSuggestionQueryVariables = Exact<{
  query: Scalars["String"];
  spellingSuggestionType?: InputMaybe<SpellingSuggestionType>;
}>;

export type SpellingSuggestionQuery = {
  __typename?: "Query";
  spellingSuggestion: {
    __typename?: "SpellingSuggestion";
    query: string;
    highConfidence: boolean;
    spellingSuggestion: string | null;
  };
};

export type InterestTopicsQueryVariables = Exact<{
  cursor?: InputMaybe<TopicsConnectionCursorInput>;
}>;

export type InterestTopicsQuery = {
  __typename?: "Query";
  interestTopics: {
    __typename?: "TopicsConnection";
    cursor: {
      __typename?: "TopicsConnectionCursor";
      popularity: number;
      created_at: any;
      uid: string;
    } | null;
    topics: Array<{
      __typename?: "Topic";
      uid: string;
      label: string;
      image_url: string;
    }>;
  };
};

export type LearnerInterestTopicsFragmentFragment = {
  __typename?: "Child";
  uid: string;
  name: string | null;
  age: number | null;
  interestTopics: {
    __typename?: "TopicsConnection";
    topics: Array<{ __typename?: "Topic"; uid: string; label: string }>;
  };
};

export type LearnerInterestTopicsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LearnerInterestTopicsQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      interestTopics: {
        __typename?: "TopicsConnection";
        topics: Array<{ __typename?: "Topic"; uid: string; label: string }>;
      };
    }>;
  } | null;
};

export type LearnerRecommendedCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LearnerRecommendedCategoriesQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      recommendedCategories: Array<{
        __typename?: "CategoryPage";
        slug: string;
        title: string | null;
        nameSentenceCased: string;
        nameTitleCased: string;
        searchFilters: {
          __typename?: "SavedSearchFilters";
          adminTags: Array<string> | null;
          age: string | null;
          delivery: string | null;
          dow: string | null;
          enabledBooleanFilters: string | null;
          endBy: string | null;
          endByTime: number | null;
          format: string | null;
          fundingPrograms: Array<string> | null;
          multiTermQuery: Array<string> | null;
          order: string | null;
          pricePerMeetingMax: number | null;
          pricePerMeetingMin: number | null;
          q: string | null;
          startAfter: string | null;
          startAfterTime: number | null;
          theme: string | null;
          time: string | null;
          timeOfDay: string | null;
          timeZone: string | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type LearnerInterestsAndRecommendedCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LearnerInterestsAndRecommendedCategoriesQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    timeZone: string | null;
    uid: string;
    followedUsers: Array<{ __typename?: "FollowedUser"; followed_uid: string }>;
    savedActivities: Array<{
      __typename?: "SavedActivity";
      activity_uid: string;
      created_at: any;
    }>;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      interestTopics: {
        __typename?: "TopicsConnection";
        topics: Array<{ __typename?: "Topic"; uid: string; label: string }>;
      };
      recommendedCategories: Array<{
        __typename?: "CategoryPage";
        slug: string;
        nameSentenceCased: string;
        nameTitleCased: string;
        searchFilters: {
          __typename?: "SavedSearchFilters";
          age: string | null;
          delivery: string | null;
          dow: string | null;
          enabledBooleanFilters: string | null;
          endBy: string | null;
          endByTime: number | null;
          format: string | null;
          order: string | null;
          pricePerMeetingMax: number | null;
          pricePerMeetingMin: number | null;
          q: string | null;
          startAfter: string | null;
          startAfterTime: number | null;
          theme: string | null;
          time: string | null;
          timeOfDay: string | null;
          timeZone: string | null;
          multiTermQuery: Array<string> | null;
          adminTags: Array<string> | null;
        } | null;
      }>;
    }>;
  } | null;
};

export type ParentChildrenQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type ParentChildrenQuery = {
  __typename?: "Query";
  parentByEmail: {
    __typename?: "User";
    uid: string;
    name: string | null;
    email: string | null;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    enrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      section_uid: string | null;
      confirmed_at: any | null;
      withdrawn_at: any | null;
      isPrepay: boolean;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      section: {
        __typename?: "Section";
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        age: number | null;
        name: string | null;
      } | null;
    } | null>;
  } | null;
};

export type TeacherSearchSuggestionsQueryVariables = Exact<{
  query: Scalars["String"];
  size?: InputMaybe<Scalars["Int"]>;
}>;

export type TeacherSearchSuggestionsQuery = {
  __typename?: "Query";
  teacherSearchSuggestions: {
    __typename?: "TeacherSearchSuggestions";
    term: string;
    teachers: Array<{
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      averageActivityStarRating: number | null;
      reviewCount: number;
      educatorTiers: Array<EducatorTier>;
    }>;
  };
};

export type GetUnsentMeetingSummariesSectionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUnsentMeetingSummariesSectionsQuery = {
  __typename?: "Query";
  unsentMeetingSummariesSections: Array<string>;
};

export type TrackUserAttributionSurveyResponseMutationVariables = Exact<{
  input: TrackUserAttributionSurveyResponseInput;
}>;

export type TrackUserAttributionSurveyResponseMutation = {
  __typename?: "Mutation";
  trackUserAttributionSurveyResponse: boolean | null;
};

export type ConciergeActiveEnrollmentQueryQueryVariables = Exact<{
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
}>;

export type ConciergeActiveEnrollmentQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    activeEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      section: {
        __typename?: "Section";
        uid: string;
        activity: { __typename?: "Activity"; uid: string; title: string };
      } | null;
    } | null>;
  } | null;
};

export type ParentUpcomingClassesQueryQueryVariables = Exact<{
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
}>;

export type ParentUpcomingClassesQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    activeEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      canBeStopped: boolean;
      canBePaused: boolean;
      canBeWithdrawn: boolean;
      isEnded: boolean;
      isPaused: boolean;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      refundableAmountCents: number;
      confirmedAt: any | null;
      withdrawn_at: any | null;
      withdrawnAt: any | null;
      price_cents: number | null;
      priceCredits: number | null;
      service_fee_percentage: number;
      buyer: { __typename?: "Parent"; uid: string; name: string | null } | null;
      details: { __typename?: "EnrollmentDetails"; name: string | null };
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        avatar: string | null;
      } | null;
      parent: { __typename?: "Parent"; uid: string; name: string | null };
      payments: Array<{
        __typename?: "EnrollmentPayment";
        uid: string;
        isOngoingRefundable: boolean;
        paid_at: any | null;
        unrefundedCents: number;
        end_time: any | null;
        service_fee_percentage: number | null;
        credit_transaction_uid: string | null;
        credit_account_type: string | null;
      }>;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        canceled_at: any | null;
        deleted_at: any | null;
        end_time: any | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        start_time: any | null;
        isBuyable: boolean;
        meetingTimes: Array<{
          __typename?: "MeetingTime";
          dayOfWeek: number;
          hour: number;
          minute: number;
        }>;
        activity: {
          __typename?: "Activity";
          uid: string;
          duration_weeks: number | null;
          duration_minutes: number | null;
          size_max: number | null;
          hasTeacherSchedule: boolean;
          isClub: boolean;
          is_ongoing_weekly: boolean;
          price_cents: number | null;
          priceCredits: number | null;
          published_at: any | null;
          title: string;
          titleTranslated: string | null;
          weekly_meetings: number | null;
          subject: string | null;
          averageStarRating: number | null;
          reviewCount: number;
          age_min: number | null;
          age_max: number | null;
          isFlexSchedule: boolean;
          isSelfPaced: boolean;
          details: { __typename?: "ActivityDetails"; photo: string | null };
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            leader_link: string | null;
            timeZone: string | null;
          };
        };
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          leader_link: string | null;
          timeZone: string | null;
        };
        meetings: Array<{
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
        }>;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type SchoolPageQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type SchoolPageQuery = {
  __typename?: "Query";
  schoolPage: {
    __typename?: "SchoolPage";
    uid: string;
    slug: string;
    name: string;
    basePath: string;
    address: string | null;
    city: string | null;
    state: string | null;
    startGrade: string | null;
    endGrade: string | null;
    schoolLevel: string | null;
    searchFilters: {
      __typename?: "SavedSearchFilters";
      age: string | null;
      delivery: string | null;
      dow: string | null;
      endBy: string | null;
      endByTime: number | null;
      format: string | null;
      languageOfInstruction: string | null;
      order: string | null;
      pricePerMeetingMax: number | null;
      pricePerMeetingMin: number | null;
      q: string | null;
      multiTermQuery: Array<string> | null;
      adminTags: Array<string> | null;
      startAfter: string | null;
      startAfterTime: number | null;
      theme: string | null;
      timeZone: string | null;
      capacityMax: number | null;
      gradeLevel: string | null;
    } | null;
  } | null;
};

export type SectionMeetings_RequestFragmentFragment = {
  __typename: "MeetingRequest";
  uid: string;
  start_time: any;
  end_time: any;
  status: MeetingRequestStatus;
  billedOn: any;
  is_recurring: boolean;
};

export type SectionMeetings_MeetingFragmentFragment = {
  __typename: "Meeting";
  uid: string;
  start_time: any;
  end_time: any;
  created_at: any | null;
  deleted_at: any | null;
  paidAt: any | null;
  billedOn: any;
  recurring: boolean | null;
  refundableAmountCents: number;
  refundableAmountCredits: number | null;
  recurringSchedule: {
    __typename: "RecurringSchedule";
    uid: string;
    end_at: any | null;
  } | null;
  rescheduleRequests: Array<{
    __typename: "MeetingRequest";
    uid: string;
    start_time: any;
    end_time: any;
    status: MeetingRequestStatus;
    billedOn: any;
    is_recurring: boolean;
  }>;
};

export type SectionMeetingsQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  startAfter: Scalars["DateTime"];
  startBefore: Scalars["DateTime"];
}>;

export type SectionMeetingsQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    meetingCount: number;
    latestProgressReportDate: any | null;
    uid: string;
    published_at: any | null;
    deleted_at: any | null;
    canceled_at: any | null;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_self_paced: boolean;
    currentUserCanManage: boolean;
    activity: {
      __typename?: "Activity";
      uid: string;
      duration_minutes: number | null;
      price_cents: number | null;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      is_ongoing_weekly: boolean;
      weekly_meetings: number | null;
      duration_weeks: number | null;
      isClub: boolean;
      published_at: any | null;
      hasTeacherSchedule: boolean;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        creditDescription: string | null;
      };
    };
    enrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        avatar: string | null;
        age: number | null;
      } | null;
      payments: Array<{
        __typename?: "EnrollmentPayment";
        paid_at: any | null;
        esa_order_id: string | null;
        order_slug_id: string | null;
        organization: { __typename?: "Organization"; uid: string } | null;
      }>;
      pauseWindow: { __typename?: "EnrollmentPauseWindow"; uid: string } | null;
    }>;
    meetings: Array<{
      __typename: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      created_at: any | null;
      deleted_at: any | null;
      paidAt: any | null;
      billedOn: any;
      recurring: boolean | null;
      refundableAmountCents: number;
      refundableAmountCredits: number | null;
      recurringSchedule: {
        __typename: "RecurringSchedule";
        uid: string;
        end_at: any | null;
      } | null;
      rescheduleRequests: Array<{
        __typename: "MeetingRequest";
        uid: string;
        start_time: any;
        end_time: any;
        status: MeetingRequestStatus;
        billedOn: any;
        is_recurring: boolean;
      }>;
    }>;
    meetingRequests: Array<{
      __typename: "MeetingRequest";
      uid: string;
      start_time: any;
      end_time: any;
      status: MeetingRequestStatus;
      billedOn: any;
      is_recurring: boolean;
    }>;
    meetingConflictInfo: Array<{
      __typename?: "SectionMeetingConflictInfo";
      meetingUid: string;
      conflictingSectionUid: string;
    }> | null;
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
    } | null;
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
    }>;
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      published_at: string | null;
    } | null;
  } | null;
};

export type CancelMeetingRequestMutationVariables = Exact<{
  requestUid: Scalars["ID"];
}>;

export type CancelMeetingRequestMutation = {
  __typename?: "Mutation";
  cancelMeetingRequest: boolean;
};

export type RequestMeetingRescheduleMutationVariables = Exact<{
  meetingUid: Scalars["ID"];
  proposedTime: StartEndTime;
  message: Scalars["String"];
  reschedulesFutureMeetings: Scalars["Boolean"];
}>;

export type RequestMeetingRescheduleMutation = {
  __typename?: "Mutation";
  requestMeetingReschedule: {
    __typename: "MeetingRequest";
    uid: string;
    start_time: any;
    end_time: any;
    status: MeetingRequestStatus;
    billedOn: any;
    is_recurring: boolean;
  };
};

export type RequestMeetingsMutationVariables = Exact<{
  sectionUid: Scalars["ID"];
  proposedTimes: Array<StartEndTime> | StartEndTime;
  isRecurring?: InputMaybe<Scalars["Boolean"]>;
}>;

export type RequestMeetingsMutation = {
  __typename?: "Mutation";
  requestMeetings: Array<{
    __typename: "MeetingRequest";
    uid: string;
    start_time: any;
    end_time: any;
    status: MeetingRequestStatus;
    billedOn: any;
    is_recurring: boolean;
  }>;
};

export type SubscriptionCreditBalanceQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SubscriptionCreditBalanceQuery = {
  __typename?: "Query";
  subscriptionCreditBalance: {
    __typename?: "SubscriptionCreditBalance";
    uid: string;
    status: string | null;
    subscriptionId: string | null;
    checkoutId: string | null;
    balance: number;
    openToBuy: number;
    packageName: string | null;
    numCredits: number | null;
    creditValue: number | null;
    availableCredits: number | null;
    billingCycleStart: any | null;
    billingCycleEnd: any | null;
    cancelAtPeriodEnd: boolean | null;
    amountCents: number | null;
    upcomingPackage: {
      __typename?: "SubscriptionPackageInformation";
      packageName: string | null;
      numCredits: number | null;
      creditValue: number | null;
      amountCents: number | null;
    } | null;
  } | null;
};

export type SubscriptionCreditBalanceFragmentFragment = {
  __typename?: "SubscriptionCreditBalance";
  uid: string;
  status: string | null;
  subscriptionId: string | null;
  checkoutId: string | null;
  balance: number;
  openToBuy: number;
  packageName: string | null;
  numCredits: number | null;
  creditValue: number | null;
  availableCredits: number | null;
  billingCycleStart: any | null;
  billingCycleEnd: any | null;
  cancelAtPeriodEnd: boolean | null;
  amountCents: number | null;
  upcomingPackage: {
    __typename?: "SubscriptionPackageInformation";
    packageName: string | null;
    numCredits: number | null;
    creditValue: number | null;
    amountCents: number | null;
  } | null;
};

export type FeatureFlagsQueryQueryVariables = Exact<{ [key: string]: never }>;

export type FeatureFlagsQueryQuery = {
  __typename?: "Query";
  featureFlags: Array<string>;
};

export type AccountSectionNewQueryVariables = Exact<{
  uid: Scalars["ID"];
  learnerOrEnrollmentUid: Scalars["String"];
  hasSelection: Scalars["Boolean"];
}>;

export type AccountSectionNewQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    filledSpaceCount: number;
    uid: string;
    activity_uid: string;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_cross_listed: boolean;
    size_max: number | null;
    size_min: number | null;
    isPublished: boolean | null;
    price_cents: number | null;
    priceCredits: number | null;
    published_at: any | null;
    updated_at: any;
    canceled_at: any | null;
    deleted_at: any | null;
    ongoing_stopped_at: any | null;
    duration_minutes: number | null;
    usesOutschoolVideoChat: boolean;
    usersCanSendPrivateMessage: boolean;
    currentUserCanManage: boolean;
    isBuyable: boolean;
    allowsTeacherTransfer: boolean;
    created_at: any;
    validEnrollmentCount: number;
    isAutoCanceled: boolean;
    currentUserIsOwner: boolean;
    paidAmount: number | null;
    is_self_paced: boolean;
    meetings: Array<{
      __typename?: "Meeting";
      refundableAmountCents: number;
      paidAt: any | null;
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    }>;
    currentUserGiftEnrollments: Array<{
      __typename?: "GiftEnrollment";
      uid: string;
      giftClaimedAt: any | null;
      createdAt: any;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      withdrawn_at: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      ongoing_stopped_at: any | null;
      currency_code: CurrencyCode;
      exchange_rate: number;
      amount_in_local_currency: number;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      hasPaymentSchedule: boolean;
      isEnded: boolean;
      isPaused: boolean;
      orderSlugId: string | null;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      priceCents: number | null;
      currentUserIsPurchaser: boolean;
      refundableAmountCents: number;
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      payments: Array<{
        __typename?: "EnrollmentPayment";
        uid: string;
        amount_cents: number | null;
        order_slug_id: string | null;
        stripe_charge_id: string | null;
        purchase_order_id: string | null;
        esa_order_id: string | null;
        intercom_url: string | null;
        last4: string | null;
        created_at: any | null;
        paid_at: any | null;
        disbursed_at: any | null;
        payout_uid: string | null;
        start_time: any | null;
        end_time: any | null;
        unrefundedCents: number;
        isOngoingRefundable: boolean;
        voucher_name: string | null;
        pricing_offer_uid: string | null;
        credit_transaction_uid: string | null;
        coupon_code_uid: string | null;
        currency_code: CurrencyCode | null;
        exchange_rate: number | null;
        invoice_uid: string | null;
        funding_program_invoice_uid: string | null;
        service_fee_percentage: number | null;
        couponCode: {
          __typename?: "CouponCode";
          code: string;
          coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
        } | null;
        pricingOffer: {
          __typename?: "PricingOffer";
          name: string;
          buyerOrgName: string | null;
          isFinancialAid: boolean;
        } | null;
      }>;
      refundPolicy: {
        __typename?: "RefundPolicy";
        type: RefundPolicyType;
        name: string;
        description: string;
      } | null;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
        endTime: any | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
    }>;
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      price_cents: number | null;
      titleTranslated: string | null;
      weekly_meetings: number | null;
      duration_weeks: number | null;
      is_ongoing_weekly: boolean;
      isClub: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      published_at: any | null;
      hasTeacherSchedule: boolean;
      size_max: number | null;
      user_uid: string;
      details: { __typename?: "ActivityDetails"; photo: string | null };
    };
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      is_crosslister: boolean | null;
    };
    feedbacks: Array<{
      __typename?: "FeedbackForLeader";
      uid: string;
      activity_star_rating: number | null;
      created_at: any;
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        timeZone: string | null;
      };
      details: {
        __typename?: "FeedbackForLeaderDetails";
        leaderFeedback: string | null;
      };
    }>;
    enrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
      } | null;
    }>;
    selectedEnrollment?:
      | {
          __typename?: "Enrollment";
          hasPaymentSchedule: boolean;
          uid: string;
          isFirstOutschoolClass: boolean;
          isRepeatLearnerOfTeacher: boolean;
          section_uid: string | null;
          created_at: any | null;
          confirmed_at: any | null;
          withdrawn_at: any | null;
          ongoing_stopped_at: any | null;
          ongoing_start_time: any | null;
          ongoing_end_time: any | null;
          price_cents: number | null;
          usersCanCommunicate: boolean;
          purchasedAsGift: boolean;
          isPaused: boolean;
          isEnded: boolean;
          createdAt: any;
          confirmedAt: any | null;
          withdrawnAt: any | null;
          ongoingStoppedAt: any | null;
          ongoingStartTime: any | null;
          ongoingEndTime: any | null;
          canBeWithdrawn: boolean;
          canBeStopped: boolean;
          priceCents: number | null;
          refundableAmountCents: number;
          currency_code: CurrencyCode;
          exchange_rate: number;
          amount_in_local_currency: number;
          learner: {
            __typename?: "Learner";
            uid: string;
            timeZone: string;
            privateNote: string | null;
            name: string | null;
            age: number | null;
            avatar: string | null;
            pronoun: string | null;
            intro: string | null;
            deleted_at: any | null;
            isVerified: boolean;
            isBlocked: boolean | null;
            blockedByUserUid: string | null;
            blockedAt: any | null;
          } | null;
          parent: {
            __typename?: "Parent";
            hasSubscription: boolean;
            canPayWithSubscriptionCredits: boolean;
            uid: string;
            name: string | null;
            photo: string | null;
            publicName: string | null;
            introduction: string | null;
            created_at: any | null;
            deleted_at: any | null;
            timeZone: string | null;
            socialOptOut: boolean;
            consent_to_share_class_history: boolean | null;
            consent_to_share_favourites: boolean | null;
          };
          siblings: Array<{
            __typename?: "Sibling";
            learner: {
              __typename?: "Learner";
              uid: string;
              name: string | null;
              age: number | null;
              avatar: string | null;
              pronoun: string | null;
              intro: string | null;
              deleted_at: any | null;
              isVerified: boolean;
              isBlocked: boolean | null;
              blockedByUserUid: string | null;
              blockedAt: any | null;
            };
            currentOrLastSection: {
              __typename?: "Section";
              filledSpaceCount: number;
              uid: string;
              activity_uid: string;
              start_time: any | null;
              end_time: any | null;
              startsAtNight: boolean | null;
              is_cross_listed: boolean;
              size_max: number | null;
              size_min: number | null;
              isPublished: boolean | null;
              price_cents: number | null;
              priceCredits: number | null;
              published_at: any | null;
              updated_at: any;
              canceled_at: any | null;
              deleted_at: any | null;
              ongoing_stopped_at: any | null;
              duration_minutes: number | null;
              usesOutschoolVideoChat: boolean;
              usersCanSendPrivateMessage: boolean;
              currentUserCanManage: boolean;
              isBuyable: boolean;
              allowsTeacherTransfer: boolean;
              nextOngoingMeeting: {
                __typename?: "Meeting";
                uid: string;
                start_time: any;
                end_time: any;
                deleted_at: any | null;
                created_at: any | null;
              } | null;
              leader: {
                __typename?: "Leader";
                uid: string;
                name: string | null;
              };
              details: {
                __typename?: "SectionDetails";
                autoScheduledDraft: boolean | null;
              };
              prepaidOngoingPeriodOptions: Array<{
                __typename?: "Period";
                startOfIsoWeek: any;
                endOfIsoWeek: any;
              }> | null;
            };
          }>;
          otherSections: Array<{
            __typename?: "Section";
            uid: string;
            filledSpaceCount: number;
            activity_uid: string;
            start_time: any | null;
            end_time: any | null;
            startsAtNight: boolean | null;
            is_cross_listed: boolean;
            size_max: number | null;
            size_min: number | null;
            isPublished: boolean | null;
            price_cents: number | null;
            priceCredits: number | null;
            published_at: any | null;
            updated_at: any;
            canceled_at: any | null;
            deleted_at: any | null;
            ongoing_stopped_at: any | null;
            duration_minutes: number | null;
            usesOutschoolVideoChat: boolean;
            usersCanSendPrivateMessage: boolean;
            currentUserCanManage: boolean;
            isBuyable: boolean;
            allowsTeacherTransfer: boolean;
            nextOngoingMeeting: {
              __typename?: "Meeting";
              uid: string;
              start_time: any;
              end_time: any;
              deleted_at: any | null;
              created_at: any | null;
            } | null;
            leader: { __typename?: "Leader"; uid: string; name: string | null };
            details: {
              __typename?: "SectionDetails";
              autoScheduledDraft: boolean | null;
            };
            prepaidOngoingPeriodOptions: Array<{
              __typename?: "Period";
              startOfIsoWeek: any;
              endOfIsoWeek: any;
            }> | null;
            activity: {
              __typename?: "Activity";
              uid: string;
              title: string;
              isClub: boolean;
              weekly_meetings: number | null;
              duration_weeks: number | null;
              is_ongoing_weekly: boolean;
              hasTeacherSchedule: boolean;
              published_at: any | null;
            };
          }>;
          paymentSchedule: {
            __typename?: "PaymentSchedule";
            uid: string;
          } | null;
          clubVerificationVideo: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
          payments: Array<{
            __typename?: "EnrollmentPayment";
            uid: string;
            unrefundedCredits: number | null;
            amount_cents: number | null;
            order_slug_id: string | null;
            stripe_charge_id: string | null;
            purchase_order_id: string | null;
            esa_order_id: string | null;
            intercom_url: string | null;
            last4: string | null;
            created_at: any | null;
            paid_at: any | null;
            disbursed_at: any | null;
            payout_uid: string | null;
            start_time: any | null;
            end_time: any | null;
            unrefundedCents: number;
            isOngoingRefundable: boolean;
            voucher_name: string | null;
            pricing_offer_uid: string | null;
            credit_transaction_uid: string | null;
            coupon_code_uid: string | null;
            currency_code: CurrencyCode | null;
            exchange_rate: number | null;
            invoice_uid: string | null;
            funding_program_invoice_uid: string | null;
            service_fee_percentage: number | null;
            organization: {
              __typename?: "Organization";
              uid: string;
              name: string;
              state: string | null;
              logoUrl: string | null;
              urlName: string | null;
            } | null;
            refunds: Array<{
              __typename?: "EnrollmentPaymentRefund";
              uid: string;
              user_uid: string;
              amount_cents: number;
              amount_credits: number | null;
              refunded_at: any | null;
              reason: string | null;
              user_role: string | null;
              note: string | null;
              reduces_teacher_payout: boolean;
              amount_in_local_currency: number | null;
              currency_code: CurrencyCode | null;
              exchange_rate: number | null;
              addOutschoolCreditTransaction: {
                __typename?: "CreditTransaction";
                uid: string;
                amount_cents: number;
                expiresAt: any | null;
                unspentCents: number | null;
              } | null;
            }>;
            couponCode: {
              __typename?: "CouponCode";
              code: string;
              coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
            } | null;
            pricingOffer: {
              __typename?: "PricingOffer";
              name: string;
              buyerOrgName: string | null;
              isFinancialAid: boolean;
            } | null;
          }>;
          pauseWindow: {
            __typename?: "EnrollmentPauseWindow";
            uid: string;
            startDate: any;
            endDate: any;
          } | null;
          buyer: {
            __typename?: "Parent";
            uid: string;
            name: string | null;
            photo: string | null;
            publicName: string | null;
            introduction: string | null;
            created_at: any | null;
            deleted_at: any | null;
            timeZone: string | null;
            socialOptOut: boolean;
            consent_to_share_class_history: boolean | null;
            consent_to_share_favourites: boolean | null;
          } | null;
          pricingOffer: {
            __typename?: "PricingOffer";
            name: string;
            buyerOrgName: string | null;
            isFinancialAid: boolean;
          } | null;
          details: {
            __typename?: "EnrollmentDetails";
            name: string | null;
            age: string | null;
            attendedAt: any | null;
            message: string | null;
          };
          refundPolicy: {
            __typename?: "RefundPolicy";
            type: RefundPolicyType;
            name: string;
            description: string;
          } | null;
        }
      | {
          __typename?: "GiftEnrollment";
          hasPaymentSchedule: boolean;
          withdrawn_at: any | null;
          ongoing_stopped_at: any | null;
          ongoing_start_time: any | null;
          ongoing_end_time: any | null;
          giftClaimedAt: any | null;
          currentUserIsPurchaser: boolean;
          isPaused: boolean;
          isEnded: boolean;
          uid: string;
          createdAt: any;
          confirmedAt: any | null;
          withdrawnAt: any | null;
          ongoingStoppedAt: any | null;
          ongoingStartTime: any | null;
          ongoingEndTime: any | null;
          canBeWithdrawn: boolean;
          canBeStopped: boolean;
          priceCents: number | null;
          refundableAmountCents: number;
          currency_code: CurrencyCode;
          exchange_rate: number;
          amount_in_local_currency: number;
          learner: {
            __typename?: "Learner";
            uid: string;
            timeZone: string;
            privateNote: string | null;
            name: string | null;
            age: number | null;
            avatar: string | null;
            pronoun: string | null;
            intro: string | null;
            deleted_at: any | null;
            isVerified: boolean;
            isBlocked: boolean | null;
            blockedByUserUid: string | null;
            blockedAt: any | null;
          } | null;
          parent: {
            __typename?: "Parent";
            hasSubscription: boolean;
            canPayWithSubscriptionCredits: boolean;
            uid: string;
            name: string | null;
            photo: string | null;
            publicName: string | null;
            introduction: string | null;
            created_at: any | null;
            deleted_at: any | null;
            timeZone: string | null;
            socialOptOut: boolean;
            consent_to_share_class_history: boolean | null;
            consent_to_share_favourites: boolean | null;
          } | null;
          paymentSchedule: {
            __typename?: "PaymentSchedule";
            uid: string;
          } | null;
          payments: Array<{
            __typename?: "EnrollmentPayment";
            uid: string;
            unrefundedCredits: number | null;
            amount_cents: number | null;
            order_slug_id: string | null;
            stripe_charge_id: string | null;
            purchase_order_id: string | null;
            esa_order_id: string | null;
            intercom_url: string | null;
            last4: string | null;
            created_at: any | null;
            paid_at: any | null;
            disbursed_at: any | null;
            payout_uid: string | null;
            start_time: any | null;
            end_time: any | null;
            unrefundedCents: number;
            isOngoingRefundable: boolean;
            voucher_name: string | null;
            pricing_offer_uid: string | null;
            credit_transaction_uid: string | null;
            coupon_code_uid: string | null;
            currency_code: CurrencyCode | null;
            exchange_rate: number | null;
            invoice_uid: string | null;
            funding_program_invoice_uid: string | null;
            service_fee_percentage: number | null;
            organization: {
              __typename?: "Organization";
              uid: string;
              name: string;
              state: string | null;
              logoUrl: string | null;
              urlName: string | null;
            } | null;
            refunds: Array<{
              __typename?: "EnrollmentPaymentRefund";
              uid: string;
              user_uid: string;
              amount_cents: number;
              amount_credits: number | null;
              refunded_at: any | null;
              reason: string | null;
              user_role: string | null;
              note: string | null;
              reduces_teacher_payout: boolean;
              amount_in_local_currency: number | null;
              currency_code: CurrencyCode | null;
              exchange_rate: number | null;
              addOutschoolCreditTransaction: {
                __typename?: "CreditTransaction";
                uid: string;
                amount_cents: number;
                expiresAt: any | null;
                unspentCents: number | null;
              } | null;
            }>;
            couponCode: {
              __typename?: "CouponCode";
              code: string;
              coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
            } | null;
            pricingOffer: {
              __typename?: "PricingOffer";
              name: string;
              buyerOrgName: string | null;
              isFinancialAid: boolean;
            } | null;
          }>;
          pauseWindow: {
            __typename?: "EnrollmentPauseWindow";
            uid: string;
            startDate: any;
            endDate: any;
          } | null;
          buyer: {
            __typename?: "Parent";
            uid: string;
            name: string | null;
            photo: string | null;
            publicName: string | null;
            introduction: string | null;
            created_at: any | null;
            deleted_at: any | null;
            timeZone: string | null;
            socialOptOut: boolean;
            consent_to_share_class_history: boolean | null;
            consent_to_share_favourites: boolean | null;
          };
          pricingOffer: {
            __typename?: "PricingOffer";
            name: string;
            buyerOrgName: string | null;
            isFinancialAid: boolean;
          } | null;
          details: {
            __typename?: "EnrollmentDetails";
            name: string | null;
            age: string | null;
            attendedAt: any | null;
            message: string | null;
          };
          refundPolicy: {
            __typename?: "RefundPolicy";
            type: RefundPolicyType;
            name: string;
            description: string;
          } | null;
        }
      | null;
    learnerEnrollmentPayments?: Array<{
      __typename?: "EnrollmentPayment";
      amount_credits: number | null;
      unrefundedCredits: number | null;
      credit_account_type: string | null;
      uid: string;
      amount_cents: number | null;
      order_slug_id: string | null;
      stripe_charge_id: string | null;
      purchase_order_id: string | null;
      esa_order_id: string | null;
      intercom_url: string | null;
      last4: string | null;
      created_at: any | null;
      paid_at: any | null;
      disbursed_at: any | null;
      payout_uid: string | null;
      start_time: any | null;
      end_time: any | null;
      unrefundedCents: number;
      isOngoingRefundable: boolean;
      voucher_name: string | null;
      pricing_offer_uid: string | null;
      credit_transaction_uid: string | null;
      coupon_code_uid: string | null;
      currency_code: CurrencyCode | null;
      exchange_rate: number | null;
      invoice_uid: string | null;
      funding_program_invoice_uid: string | null;
      service_fee_percentage: number | null;
      oneOnOneMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
      } | null;
      refunds: Array<{
        __typename?: "EnrollmentPaymentRefund";
        amount_credits: number | null;
        uid: string;
        user_uid: string;
        amount_cents: number;
        refunded_at: any | null;
        reason: string | null;
        user_role: string | null;
        note: string | null;
        reduces_teacher_payout: boolean;
        amount_in_local_currency: number | null;
        currency_code: CurrencyCode | null;
        exchange_rate: number | null;
        addOutschoolCreditTransaction: {
          __typename?: "CreditTransaction";
          uid: string;
          amount_cents: number;
          expiresAt: any | null;
          unspentCents: number | null;
        } | null;
      }>;
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        state: string | null;
        logoUrl: string | null;
        urlName: string | null;
      } | null;
      couponCode: {
        __typename?: "CouponCode";
        code: string;
        coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
      } | null;
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
    }>;
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    details: {
      __typename?: "SectionDetails";
      autoScheduledDraft: boolean | null;
      onlineClassroom: {
        __typename?: "OnlineClassroom";
        type: string | null;
        url: string | null;
      } | null;
    };
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
    checklist: {
      __typename?: "SectionChecklist";
      autoCanceledAt: any | null;
      grandfatheredAt2017Rate: boolean | null;
    };
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
    }>;
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      published_at: string | null;
    } | null;
  } | null;
};

export type ActivityActionFragmentFragment = {
  __typename?: "ActivityAction";
  uid: string;
  created_at: any;
  action: string;
  notes: string | null;
  message_to_teacher: string | null;
};

export type ActivityEditPageQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type ActivityEditPageQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    isSelfPaced: boolean;
    hasSections: boolean;
    lockedClassFormats: Array<ClassFormat>;
    wasPublishedInThePast: boolean;
    suggestedWeeklyMeetings: number | null;
    uid: string;
    slug_id: string;
    user_uid: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    learningGoals: Array<string>;
    assessment: string | null;
    homework: string | null;
    grading_policy: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    english_proficiency_level: EnglishProficiencyLevel | null;
    grade_level_min: string | null;
    grade_level_max: string | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    priceCredits: number | null;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    allowWaitlist: boolean | null;
    isClub: boolean;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    allows_recurring_payment: boolean | null;
    allows_late_enrollments_for_fixed_length_classes: boolean;
    has_building_content: boolean | null;
    hasTeacherSchedule: boolean;
    isFlexSchedule: boolean;
    isOneTime: boolean;
    classFormat: EducatorSelectedFormat;
    classroomType: ClassroomType;
    tags: string | null;
    archived_at: any | null;
    canRequestListing: boolean;
    isSelfPacedEnrollmentEnabled: boolean;
    saveCount: number | null;
    requested_listing_at: any | null;
    upsell_activity_uid: string | null;
    why_me: string | null;
    parental_guidance: string | null;
    sources: string | null;
    materials: string | null;
    has_external_resources: boolean | null;
    selfPacedMigration: {
      __typename?: "SelfPacedMigrationStatus";
      hasConsented: boolean;
      consentedAt: any | null;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      type: RefundPolicyType;
      description: string;
    };
    attributes: Array<{
      __typename?: "EntityAttribute";
      key: string;
      value: string;
      entity_type: string;
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    videoForLearners: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      publishedClassCount: number;
      hasAvailabilitySet: boolean;
      listings: Array<{
        __typename?: "Listing";
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
        };
      }>;
    };
    leaderDetails: {
      __typename?: "ActivityLeaderDetails";
      uid: string;
      requestedListingAt: any | null;
      classRequest: {
        __typename?: "ClassRequest";
        uid: string;
        age: number;
        content: string;
      } | null;
      welcomeMessage: {
        __typename?: "WelcomeMessage";
        text: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        }> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        } | null;
      } | null;
    };
    strictAgeRange: {
      __typename?: "AgeRangeType";
      min: number;
      max: number;
    } | null;
    pricingAdjustments: {
      __typename?: "PricingAdjustment";
      weeklySurchargePercentage: number | null;
    };
    pricingInfo: {
      __typename?: "PricingInfo";
      totalPriceCentsForUpfrontPayment: number;
      totalPriceCentsForWeeklyPayments: number;
      weeklyPriceCentsForUpfrontPayment: number;
      weeklyPriceCentsForWeeklyPayments: number;
      upfrontDiscountPercentage: number;
    } | null;
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      published_at: string | null;
      lessons: Array<{
        __typename?: "Lesson";
        uid: string;
        week_number: number;
        order_within_week: number;
        title: string | null;
        description: string | null;
        lessonNumber: number | null;
        unit: { __typename?: "Unit"; uid: string; title: string | null } | null;
      }>;
    } | null;
    proficiency_level: {
      __typename?: "ProficiencyLevel";
      level: Array<string | null> | null;
      text: string | null;
    } | null;
    details: {
      __typename?: "ActivityDetails";
      photo: string | null;
      photoSquare: string | null;
      asyncTime: string | null;
    };
    approvalStatus: {
      __typename?: "ActivityApprovalStatus";
      action: string;
      message_to_teacher: string | null;
    } | null;
    externalResources: Array<{
      __typename?: "ExternalResource";
      uid: string;
      name: string;
      url: string | null;
    }>;
  };
  refundPolicies: Array<{
    __typename?: "RefundPolicy";
    name: string;
    type: RefundPolicyType;
  }>;
};

export type CommonEnrollmentFormUserFragmentFragment = {
  __typename?: "User";
  consented_to_coppa_notice_at: any | null;
  uid: string;
  slug_id: string;
  name: string | null;
  email: string | null;
  isLearnerModeEnabled: boolean | null;
  confirmed_at: any | null;
  subscribed_at: any | null;
  email_bounced_at: any | null;
  default_currency: string | null;
  phone: string | null;
  photo: string | null;
  leader_subscribed_at: any | null;
  calendar_uid: string;
  intercomUserHash: string | null;
  payout_email: string | null;
  owner_of_seller_org_uid: string | null;
  admin_for_organization_uid: string | null;
  do_not_sell_my_personal_info: boolean;
  background_checked_at: any | null;
  background_check_started_at: any | null;
  interview_scheduled_at: any | null;
  interviewed_at: any | null;
  classroom_training_scheduled_at: any | null;
  classroom_trained_at: any | null;
  latestLegalName: string | null;
  hasCompletedClassContentPoliciesTraining: boolean;
  hasCompletedSafetyAndPrivacyTraining: boolean;
  hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
  hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
  teacher_approved_at: any | null;
  is_crosslister: boolean | null;
  introduction: string | null;
  created_at: any;
  updated_at: any;
  deleted_at: any | null;
  suspended_at: any | null;
  is_admin: boolean | null;
  learner_uses_parent_mode: boolean;
  leader_link: string | null;
  unreadMessageThreadsCount: number;
  unreadPrivateClassMessageThreadsCount: number;
  roles: Array<string> | null;
  locale: UserLocale;
  isRecordingAutopostEnabled: boolean | null;
  learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
  anonymousId: string | null;
  wasReferred: boolean;
  isEducatorBetaTester: boolean | null;
  hasVerifiedIdentity: boolean | null;
  consent_to_share_class_history: boolean | null;
  consent_to_share_favourites: boolean | null;
  privacy_state: string | null;
  profile_link_uid: string | null;
  hasSubscription: boolean;
  canPayWithSubscriptionCredits: boolean;
  upgradablePackages: Array<number | null> | null;
  children: Array<{
    __typename?: "Child";
    uid: string;
    name: string | null;
    age: number | null;
    birthMonth: number | null;
    date_of_birth: any | null;
    birthYear: number | null;
    email: string | null;
    avatar: string | null;
    unlockedAvatars: Array<string>;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    email_confirmed_at: any | null;
    optedOutOfPersonalizedRecommendationsAt: any | null;
    isLoginEnabled: boolean;
    username: string | null;
    hasPassword: boolean;
    attributes: Array<{
      __typename?: "EntityAttribute";
      uid: string;
      key: string;
      value: string;
    }>;
    entityAvailabilitySchedule: {
      __typename?: "EntityAvailabilitySchedule";
      entityAvailabilities: Array<{
        __typename?: "EntityAvailability";
        startHour: number;
        startMinute: number;
        day: number;
        endHour: number;
        endMinute: number;
        uid: string;
      }>;
    } | null;
  }>;
  lastSubmittedEnrollments: Array<{
    __typename?: "Enrollment";
    uid: string;
    confirmedAt: any | null;
    withdrawnAt: any | null;
    ongoingEndTime: any | null;
    isPaused: boolean;
    isEnded: boolean;
    learner: {
      __typename?: "Learner";
      uid: string;
      age: number | null;
      name: string | null;
    } | null;
    section: {
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        titleTranslated: string | null;
      };
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
    } | null;
  }>;
  otherEmailDetails: Array<{
    __typename?: "OtherEmailDetails";
    uid: string;
    email: string;
    confirmed_at: any | null;
  } | null> | null;
  firstPaidEnrollment: {
    __typename?: "Enrollment";
    uid: string;
    confirmed_at: any | null;
  } | null;
  details: {
    __typename?: "UserDetails";
    about: string | null;
    headline: string | null;
    countryOfResidence: string | null;
    introduction: string | null;
    browserTimeZone: string | null;
    timeRangeAfterSchool: boolean | null;
    timeRangeSchool: boolean | null;
    timeRangeWeekend: boolean | null;
    socialOptOut: boolean | null;
    howFoundOutschool: string | null;
    howFoundOutschoolOther: string | null;
    educationApproach: string | null;
    adminDashboardUrl: string | null;
    experiments: Array<string | null> | null;
  };
  location: {
    __typename?: "Location";
    address: string | null;
    city: string | null;
    lat: number | null;
    lng: number | null;
    timeZone: string | null;
    state: string | null;
  } | null;
  organizationAdmin: {
    __typename?: "OrganizationAdmin";
    organization: {
      __typename?: "Organization";
      uid: string;
      name: string;
      type: OrganizationType;
    };
  } | null;
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
  interfacePreferences: {
    __typename?: "InterfacePreferences";
    activityFormatDescriptionFlexHidden: boolean;
    activityFormatDescriptionOngoingHidden: boolean;
    activityFormatDescriptionOneTimeHidden: boolean;
    activityFormatDescriptionMultiDayHidden: boolean;
    defaultScheduleFilters: {
      __typename?: "UserScheduleFilters";
      startAfter: string | null;
      endBy: string | null;
      startAfterTime: number | null;
      endByTime: number | null;
      dow: string | null;
    };
  };
  schoolClosure: {
    __typename?: "SchoolClosure";
    application_status: FfapApplicationStatus | null;
    needs_assistance: boolean | null;
  } | null;
  pricingOffer: {
    __typename?: "PricingOffer";
    uid: string;
    name: string;
    userLimitCents: number;
    purchaseLimitCents: number | null;
    totalCapCents: number;
    startAfter: any;
    endBy: any;
    buyerOrgName: string | null;
    isTotalCapReached: boolean;
    remainingCapCents: number | null;
    requirePaymentMethod: boolean;
    isOfferLimitReached: boolean;
    userOfferEmail: string | null;
    discount: number | null;
    isFinancialAid: boolean;
  } | null;
  sellerOrg: {
    __typename?: "SellerOrg";
    uid: string;
    name: string;
    leader_link: string | null;
    currentUserIsOwner: boolean | null;
    currentUserIsTeacher: boolean | null;
    hasApprovedTeacherAccountForOwner: boolean | null;
  } | null;
  latestSellerOrgApplication: {
    __typename?: "SellerOrgApplication";
    uid: string;
    status: SellerOrgApplicationStatus;
    approvedAt: any | null;
    rejectedAt: any | null;
    contractSignedAt: any | null;
    adminResponse: string | null;
  } | null;
  latestTeacherApplication: {
    __typename?: "TeacherApplication";
    uid: string;
    legal_name: string | null;
    status: TeacherApplicationStatus;
    submitted_at: any | null;
    reviewed_at: any | null;
    teaching_experience: string | null;
    teaching_credentials: string | null;
    class_types: string | null;
    online_profiles: string | null;
    sample_classes: string | null;
    class_subjects: Array<string | null> | null;
    age_ranges_taught: Array<string | null> | null;
    years_of_experience: string | null;
    how_did_you_hear_about_us: string | null;
    experience_types: Array<string | null> | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  } | null;
  leader: {
    __typename?: "Leader";
    uid: string;
    showCalendar: boolean | null;
    mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
  } | null;
};

export type CommonEnrollmentFormActivityFragmentFragment = {
  __typename?: "Activity";
  uid: string;
  suggestedWeeklyMeetings: number | null;
  reviewCount: number;
  esaStatus: EsaStatus;
  slug_id: string;
  user_uid: string;
  title: string;
  titleTranslated: string | null;
  url: string;
  path: string;
  class_experience: string | null;
  learning_goals: string | null;
  learningGoals: Array<string>;
  assessment: string | null;
  homework: string | null;
  grading_policy: string | null;
  prerequisites: string | null;
  published_at: any | null;
  is_online: boolean;
  age_min: number | null;
  age_max: number | null;
  english_proficiency_level: EnglishProficiencyLevel | null;
  grade_level_min: string | null;
  grade_level_max: string | null;
  size_min: number | null;
  size_max: number | null;
  duration_minutes: number | null;
  duration_weeks: number | null;
  weekly_meetings: number | null;
  price_cents: number | null;
  priceCredits: number | null;
  summary: string | null;
  summaryTranslated: string | null;
  is_ongoing_weekly: boolean;
  subject: string | null;
  languageOfInstruction: any | null;
  summaryForLearners: string | null;
  allowWaitlist: boolean | null;
  isClub: boolean;
  isAutoSchedulingEnabled: boolean | null;
  autoSchedulingDaysNoticeMin: number | null;
  autoSchedulingDaysNoticeMax: number | null;
  allows_recurring_payment: boolean | null;
  allows_late_enrollments_for_fixed_length_classes: boolean;
  has_building_content: boolean | null;
  hasTeacherSchedule: boolean;
  isFlexSchedule: boolean;
  isSelfPaced: boolean;
  isOneTime: boolean;
  classFormat: EducatorSelectedFormat;
  classroomType: ClassroomType;
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    leader_link: string | null;
    created_at: any | null;
    photo: string | null;
    suspended_at: any | null;
    cityState: string | null;
    socialOptOut: boolean;
    is_crosslister: boolean | null;
    timeZone: string | null;
    hasAvailabilitySet: boolean;
    approved: boolean;
    hasBackgroundCheckExpired: boolean | null;
    reviewCount: number;
    averageActivityStarRating: number | null;
    hasListings: boolean;
    details: {
      __typename?: "LeaderDetails";
      about: string | null;
      headline: string | null;
      headlineTranslated: string | null;
      countryOfResidence: string | null;
    };
    ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    teacherForSellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
    } | null;
    video: {
      __typename?: "Attachment";
      file: { __typename?: "File"; url: string; thumbUrl: string | null };
    } | null;
  };
  strictAgeRange: {
    __typename?: "AgeRangeType";
    min: number;
    max: number;
  } | null;
  pricingInfo: {
    __typename?: "PricingInfo";
    totalPriceCentsForUpfrontPayment: number;
    totalPriceCentsForWeeklyPayments: number;
    weeklyPriceCentsForUpfrontPayment: number;
    weeklyPriceCentsForWeeklyPayments: number;
    upfrontDiscountPercentage: number;
    totalPriceCreditsForUpfrontPayment: number;
    weeklyPriceCreditsForUpfrontPayment: number;
    totalPriceCreditsForWeeklyPayments: number;
    weeklyPriceCreditsForWeeklyPayments: number;
  } | null;
  proficiency_level: {
    __typename?: "ProficiencyLevel";
    level: Array<string | null> | null;
    text: string | null;
  } | null;
  refundPolicy: {
    __typename?: "RefundPolicy";
    name: string;
    description: string;
    type: RefundPolicyType;
  };
  details: {
    __typename?: "ActivityDetails";
    photo: string | null;
    photoSquare: string | null;
  };
};

export type CommonEnrollmentFormSectionFragmentFragment = {
  __typename?: "Section";
  uid: string;
  filledSpaceCount: number;
  allowsLateEnrollment: boolean;
  activity_uid: string;
  start_time: any | null;
  end_time: any | null;
  startsAtNight: boolean | null;
  is_cross_listed: boolean;
  size_max: number | null;
  size_min: number | null;
  isPublished: boolean | null;
  price_cents: number | null;
  priceCredits: number | null;
  published_at: any | null;
  updated_at: any;
  canceled_at: any | null;
  deleted_at: any | null;
  ongoing_stopped_at: any | null;
  duration_minutes: number | null;
  usesOutschoolVideoChat: boolean;
  usersCanSendPrivateMessage: boolean;
  currentUserCanManage: boolean;
  isBuyable: boolean;
  allowsTeacherTransfer: boolean;
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    hasBackgroundCheckExpired: boolean | null;
    photo: string | null;
    leader_link: string | null;
    ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    video: {
      __typename?: "Attachment";
      file: { __typename?: "File"; url: string; thumbUrl: string | null };
    } | null;
  };
  progress: {
    __typename?: "CourseProgress";
    pastMeetingCount: number;
    remainingMeetingCount: number;
    totalMeetingCount: number;
    nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
  } | null;
  syllabus: {
    __typename?: "Syllabus";
    uid: string;
    lessons: Array<{ __typename?: "Lesson"; uid: string }>;
  } | null;
  nextOngoingMeeting: {
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
  } | null;
  details: {
    __typename?: "SectionDetails";
    autoScheduledDraft: boolean | null;
  };
  prepaidOngoingPeriodOptions: Array<{
    __typename?: "Period";
    startOfIsoWeek: any;
    endOfIsoWeek: any;
  }> | null;
};

export type ActivityEnrollQueryQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type ActivityEnrollQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    consented_to_coppa_notice_at: any | null;
    uid: string;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    latestLegalName: string | null;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    teacher_approved_at: any | null;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    roles: Array<string> | null;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    hasVerifiedIdentity: boolean | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    upgradablePackages: Array<number | null> | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    lastSubmittedEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      learner: {
        __typename?: "Learner";
        uid: string;
        age: number | null;
        name: string | null;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
    }>;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    details: {
      __typename?: "UserDetails";
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    location: {
      __typename?: "Location";
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      timeZone: string | null;
      state: string | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
    } | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
    } | null;
  } | null;
  enrollmentForm: {
    __typename?: "EnrollmentForm";
    uid: string;
    waitlistSeatsCount: number;
    section: {
      __typename?: "Section";
      uid: string;
      filledSpaceCount: number;
      allowsLateEnrollment: boolean;
      activity_uid: string;
      start_time: any | null;
      end_time: any | null;
      startsAtNight: boolean | null;
      is_cross_listed: boolean;
      size_max: number | null;
      size_min: number | null;
      isPublished: boolean | null;
      price_cents: number | null;
      priceCredits: number | null;
      published_at: any | null;
      updated_at: any;
      canceled_at: any | null;
      deleted_at: any | null;
      ongoing_stopped_at: any | null;
      duration_minutes: number | null;
      usesOutschoolVideoChat: boolean;
      usersCanSendPrivateMessage: boolean;
      currentUserCanManage: boolean;
      isBuyable: boolean;
      allowsTeacherTransfer: boolean;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        hasBackgroundCheckExpired: boolean | null;
        photo: string | null;
        leader_link: string | null;
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        video: {
          __typename?: "Attachment";
          file: { __typename?: "File"; url: string; thumbUrl: string | null };
        } | null;
      };
      progress: {
        __typename?: "CourseProgress";
        pastMeetingCount: number;
        remainingMeetingCount: number;
        totalMeetingCount: number;
        nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
      } | null;
      syllabus: {
        __typename?: "Syllabus";
        uid: string;
        lessons: Array<{ __typename?: "Lesson"; uid: string }>;
      } | null;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        deleted_at: any | null;
        created_at: any | null;
      } | null;
      details: {
        __typename?: "SectionDetails";
        autoScheduledDraft: boolean | null;
      };
      prepaidOngoingPeriodOptions: Array<{
        __typename?: "Period";
        startOfIsoWeek: any;
        endOfIsoWeek: any;
      }> | null;
    };
    activity: {
      __typename?: "Activity";
      esaStatus: EsaStatus;
      uid: string;
      suggestedWeeklyMeetings: number | null;
      reviewCount: number;
      slug_id: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      class_experience: string | null;
      learning_goals: string | null;
      learningGoals: Array<string>;
      assessment: string | null;
      homework: string | null;
      grading_policy: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      grade_level_min: string | null;
      grade_level_max: string | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      priceCredits: number | null;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      allowWaitlist: boolean | null;
      isClub: boolean;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      allows_recurring_payment: boolean | null;
      allows_late_enrollments_for_fixed_length_classes: boolean;
      has_building_content: boolean | null;
      hasTeacherSchedule: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      classFormat: EducatorSelectedFormat;
      classroomType: ClassroomType;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
        created_at: any | null;
        photo: string | null;
        suspended_at: any | null;
        cityState: string | null;
        socialOptOut: boolean;
        is_crosslister: boolean | null;
        timeZone: string | null;
        hasAvailabilitySet: boolean;
        approved: boolean;
        hasBackgroundCheckExpired: boolean | null;
        reviewCount: number;
        averageActivityStarRating: number | null;
        hasListings: boolean;
        details: {
          __typename?: "LeaderDetails";
          about: string | null;
          headline: string | null;
          headlineTranslated: string | null;
          countryOfResidence: string | null;
        };
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        teacherForSellerOrg: {
          __typename?: "SellerOrg";
          uid: string;
          name: string;
        } | null;
        video: {
          __typename?: "Attachment";
          file: { __typename?: "File"; url: string; thumbUrl: string | null };
        } | null;
      };
      strictAgeRange: {
        __typename?: "AgeRangeType";
        min: number;
        max: number;
      } | null;
      pricingInfo: {
        __typename?: "PricingInfo";
        totalPriceCentsForUpfrontPayment: number;
        totalPriceCentsForWeeklyPayments: number;
        weeklyPriceCentsForUpfrontPayment: number;
        weeklyPriceCentsForWeeklyPayments: number;
        upfrontDiscountPercentage: number;
        totalPriceCreditsForUpfrontPayment: number;
        weeklyPriceCreditsForUpfrontPayment: number;
        totalPriceCreditsForWeeklyPayments: number;
        weeklyPriceCreditsForWeeklyPayments: number;
      } | null;
      proficiency_level: {
        __typename?: "ProficiencyLevel";
        level: Array<string | null> | null;
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
      };
      details: {
        __typename?: "ActivityDetails";
        photo: string | null;
        photoSquare: string | null;
      };
    };
    currentUserEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      section_uid: string | null;
      price_cents: number | null;
      priceCents: number | null;
      created_at: any | null;
      confirmed_at: any | null;
      confirmedAt: any | null;
      withdrawn_at: any | null;
      ongoing_stopped_at: any | null;
      currency_code: CurrencyCode;
      exchange_rate: number;
      amount_in_local_currency: number;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      withdrawnAt: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      isPrepay: boolean;
      refundableAmountCents: number;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      usersCanCommunicate: boolean;
      purchasedAsGift: boolean;
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        type: RefundPolicyType;
        name: string;
        description: string;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        age: number | null;
        name: string | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      siblings: Array<{
        __typename?: "Sibling";
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
          age: number | null;
          avatar: string | null;
          pronoun: string | null;
          intro: string | null;
          deleted_at: any | null;
          isVerified: boolean;
          isBlocked: boolean | null;
          blockedByUserUid: string | null;
          blockedAt: any | null;
        };
        currentOrLastSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          uid: string;
          activity_uid: string;
          start_time: any | null;
          end_time: any | null;
          startsAtNight: boolean | null;
          is_cross_listed: boolean;
          size_max: number | null;
          size_min: number | null;
          isPublished: boolean | null;
          price_cents: number | null;
          priceCredits: number | null;
          published_at: any | null;
          updated_at: any;
          canceled_at: any | null;
          deleted_at: any | null;
          ongoing_stopped_at: any | null;
          duration_minutes: number | null;
          usesOutschoolVideoChat: boolean;
          usersCanSendPrivateMessage: boolean;
          currentUserCanManage: boolean;
          isBuyable: boolean;
          allowsTeacherTransfer: boolean;
          nextOngoingMeeting: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
            deleted_at: any | null;
            created_at: any | null;
          } | null;
          leader: { __typename?: "Leader"; uid: string; name: string | null };
          details: {
            __typename?: "SectionDetails";
            autoScheduledDraft: boolean | null;
          };
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
        };
      }>;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
        endTime: any | null;
        scheduleType: PaymentScheduleTypes;
        amountCents: number | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
    }>;
    conflictingEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      learner: {
        __typename?: "Learner";
        uid: string;
        age: number | null;
        name: string | null;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
    }>;
    pendingEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      learner: {
        __typename?: "Learner";
        uid: string;
        age: number | null;
        name: string | null;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
    }>;
  } | null;
};

export type ActivitySelfPacedEnrollQueryQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type ActivitySelfPacedEnrollQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    consented_to_coppa_notice_at: any | null;
    uid: string;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    latestLegalName: string | null;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    teacher_approved_at: any | null;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    roles: Array<string> | null;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    hasVerifiedIdentity: boolean | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    upgradablePackages: Array<number | null> | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    lastSubmittedEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      learner: {
        __typename?: "Learner";
        uid: string;
        age: number | null;
        name: string | null;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
    }>;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    details: {
      __typename?: "UserDetails";
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    location: {
      __typename?: "Location";
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      timeZone: string | null;
      state: string | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
    } | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
    } | null;
  } | null;
  selfPacedEnrollmentForm: {
    __typename?: "SelfPacedEnrollmentForm";
    uid: string;
    activity: {
      __typename?: "Activity";
      esaStatus: EsaStatus;
      uid: string;
      suggestedWeeklyMeetings: number | null;
      reviewCount: number;
      slug_id: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      class_experience: string | null;
      learning_goals: string | null;
      learningGoals: Array<string>;
      assessment: string | null;
      homework: string | null;
      grading_policy: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      grade_level_min: string | null;
      grade_level_max: string | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      priceCredits: number | null;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      allowWaitlist: boolean | null;
      isClub: boolean;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      allows_recurring_payment: boolean | null;
      allows_late_enrollments_for_fixed_length_classes: boolean;
      has_building_content: boolean | null;
      hasTeacherSchedule: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      classFormat: EducatorSelectedFormat;
      classroomType: ClassroomType;
      syllabus: {
        __typename?: "Syllabus";
        uid: string;
        lessons: Array<{ __typename?: "Lesson"; uid: string }>;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
        created_at: any | null;
        photo: string | null;
        suspended_at: any | null;
        cityState: string | null;
        socialOptOut: boolean;
        is_crosslister: boolean | null;
        timeZone: string | null;
        hasAvailabilitySet: boolean;
        approved: boolean;
        hasBackgroundCheckExpired: boolean | null;
        reviewCount: number;
        averageActivityStarRating: number | null;
        hasListings: boolean;
        details: {
          __typename?: "LeaderDetails";
          about: string | null;
          headline: string | null;
          headlineTranslated: string | null;
          countryOfResidence: string | null;
        };
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        teacherForSellerOrg: {
          __typename?: "SellerOrg";
          uid: string;
          name: string;
        } | null;
        video: {
          __typename?: "Attachment";
          file: { __typename?: "File"; url: string; thumbUrl: string | null };
        } | null;
      };
      strictAgeRange: {
        __typename?: "AgeRangeType";
        min: number;
        max: number;
      } | null;
      pricingInfo: {
        __typename?: "PricingInfo";
        totalPriceCentsForUpfrontPayment: number;
        totalPriceCentsForWeeklyPayments: number;
        weeklyPriceCentsForUpfrontPayment: number;
        weeklyPriceCentsForWeeklyPayments: number;
        upfrontDiscountPercentage: number;
        totalPriceCreditsForUpfrontPayment: number;
        weeklyPriceCreditsForUpfrontPayment: number;
        totalPriceCreditsForWeeklyPayments: number;
        weeklyPriceCreditsForWeeklyPayments: number;
      } | null;
      proficiency_level: {
        __typename?: "ProficiencyLevel";
        level: Array<string | null> | null;
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
      };
      details: {
        __typename?: "ActivityDetails";
        photo: string | null;
        photoSquare: string | null;
      };
    };
    currentUserEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      section_uid: string | null;
      price_cents: number | null;
      priceCents: number | null;
      created_at: any | null;
      confirmed_at: any | null;
      confirmedAt: any | null;
      withdrawn_at: any | null;
      ongoing_stopped_at: any | null;
      currency_code: CurrencyCode;
      exchange_rate: number;
      amount_in_local_currency: number;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      withdrawnAt: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      isPrepay: boolean;
      refundableAmountCents: number;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      usersCanCommunicate: boolean;
      purchasedAsGift: boolean;
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        type: RefundPolicyType;
        name: string;
        description: string;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        age: number | null;
        name: string | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      siblings: Array<{
        __typename?: "Sibling";
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
          age: number | null;
          avatar: string | null;
          pronoun: string | null;
          intro: string | null;
          deleted_at: any | null;
          isVerified: boolean;
          isBlocked: boolean | null;
          blockedByUserUid: string | null;
          blockedAt: any | null;
        };
        currentOrLastSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          uid: string;
          activity_uid: string;
          start_time: any | null;
          end_time: any | null;
          startsAtNight: boolean | null;
          is_cross_listed: boolean;
          size_max: number | null;
          size_min: number | null;
          isPublished: boolean | null;
          price_cents: number | null;
          priceCredits: number | null;
          published_at: any | null;
          updated_at: any;
          canceled_at: any | null;
          deleted_at: any | null;
          ongoing_stopped_at: any | null;
          duration_minutes: number | null;
          usesOutschoolVideoChat: boolean;
          usersCanSendPrivateMessage: boolean;
          currentUserCanManage: boolean;
          isBuyable: boolean;
          allowsTeacherTransfer: boolean;
          nextOngoingMeeting: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
            deleted_at: any | null;
            created_at: any | null;
          } | null;
          leader: { __typename?: "Leader"; uid: string; name: string | null };
          details: {
            __typename?: "SectionDetails";
            autoScheduledDraft: boolean | null;
          };
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
        };
      }>;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
        endTime: any | null;
        scheduleType: PaymentScheduleTypes;
        amountCents: number | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
    }>;
    pendingEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      learner: {
        __typename?: "Learner";
        uid: string;
        age: number | null;
        name: string | null;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
    }>;
  };
};

export type ActivityLeaderFragmentFragment = {
  __typename?: "Activity";
  saveCount: number | null;
  archived_at: any | null;
  upsell_activity_uid: string | null;
  why_me: string | null;
  parental_guidance: string | null;
  sources: string | null;
  materials: string | null;
  hasSections: boolean;
  has_external_resources: boolean | null;
  details: { __typename?: "ActivityDetails"; asyncTime: string | null };
  leaderDetails: {
    __typename?: "ActivityLeaderDetails";
    uid: string;
    requestedListingAt: any | null;
    welcomeMessage: {
      __typename?: "WelcomeMessage";
      text: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      }> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      } | null;
    } | null;
  };
  externalResources: Array<{
    __typename?: "ExternalResource";
    uid: string;
    name: string;
    url: string | null;
  }>;
};

export type ActivityAdminPendingOneOnOneEsaFragmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  orderSlugId: string | null;
  createdAt: any;
  withdrawn_at: any | null;
  withdrawnAt: any | null;
  confirmed_at: any | null;
  confirmedAt: any | null;
  parent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    email: string | null;
    photo: string | null;
    timeZone: string | null;
  };
  learner: {
    __typename?: "Learner";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    isVerified: boolean;
    isBlocked: boolean | null;
    blockedByUserUid: string | null;
    blockedAt: any | null;
  } | null;
  details: {
    __typename?: "EnrollmentDetails";
    name: string | null;
    age: string | null;
    attendedAt: any | null;
    message: string | null;
  };
  refundPolicy: { __typename?: "RefundPolicy"; name: string } | null;
};

export type ActivityAdminFragmentFragment = {
  __typename?: "Activity";
  tags: string | null;
  disclaimer: string | null;
  adminChecklist: {
    __typename?: "ActivityAdminChecklist";
    notes: string | null;
  };
  pendingOneOnOneEsaEnrollments: Array<{
    __typename?: "Enrollment";
    uid: string;
    orderSlugId: string | null;
    createdAt: any;
    withdrawn_at: any | null;
    withdrawnAt: any | null;
    confirmed_at: any | null;
    confirmedAt: any | null;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      email: string | null;
      photo: string | null;
      timeZone: string | null;
    };
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    details: {
      __typename?: "EnrollmentDetails";
      name: string | null;
      age: string | null;
      attendedAt: any | null;
      message: string | null;
    };
    refundPolicy: { __typename?: "RefundPolicy"; name: string } | null;
  }>;
};

export type ActivityStatusBannerFragmentFragment = {
  __typename?: "Activity";
  tags: string | null;
  archived_at: any | null;
  published_at: any | null;
  user_uid: string;
  canRequestListing: boolean;
  isSelfPaced: boolean;
  isSelfPacedEnrollmentEnabled: boolean;
  uid: string;
  saveCount: number | null;
  requested_listing_at: any | null;
  approvalStatus: {
    __typename?: "ActivityApprovalStatus";
    action: string;
    message_to_teacher: string | null;
  } | null;
};

export type ActivityAttributesFragmentFragment = {
  __typename?: "EntityAttribute";
  key: string;
  value: string;
  entity_type: string;
};

export type UpdateAutoSchedulingMutationVariables = Exact<{
  uids: Array<Scalars["ID"]> | Scalars["ID"];
  enabled?: InputMaybe<Scalars["Boolean"]>;
  minDaysNotice?: InputMaybe<Scalars["Int"]>;
  maxDaysNotice?: InputMaybe<Scalars["Int"]>;
}>;

export type UpdateAutoSchedulingMutation = {
  __typename?: "Mutation";
  updateActivitiesAutoScheduling: Array<{
    __typename?: "Activity";
    uid: string;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
  }>;
};

export type UpdateActivityMutationVariables = Exact<{
  uid: Scalars["ID"];
  updates?: InputMaybe<ActivityInput>;
  details?: InputMaybe<ActivityDetailsInput>;
}>;

export type UpdateActivityMutation = {
  __typename?: "Mutation";
  updateActivity: {
    __typename?: "Activity";
    isSelfPaced: boolean;
    suggestedWeeklyMeetings: number | null;
    uid: string;
    slug_id: string;
    user_uid: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    learningGoals: Array<string>;
    assessment: string | null;
    homework: string | null;
    grading_policy: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    english_proficiency_level: EnglishProficiencyLevel | null;
    grade_level_min: string | null;
    grade_level_max: string | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    priceCredits: number | null;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    allowWaitlist: boolean | null;
    isClub: boolean;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    allows_recurring_payment: boolean | null;
    allows_late_enrollments_for_fixed_length_classes: boolean;
    has_building_content: boolean | null;
    hasTeacherSchedule: boolean;
    isFlexSchedule: boolean;
    isOneTime: boolean;
    classFormat: EducatorSelectedFormat;
    classroomType: ClassroomType;
    saveCount: number | null;
    archived_at: any | null;
    upsell_activity_uid: string | null;
    why_me: string | null;
    parental_guidance: string | null;
    sources: string | null;
    materials: string | null;
    hasSections: boolean;
    has_external_resources: boolean | null;
    selfPacedMigration: {
      __typename?: "SelfPacedMigrationStatus";
      hasConsented: boolean;
      consentedAt: any | null;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      type: RefundPolicyType;
      description: string;
    };
    attributes: Array<{
      __typename?: "EntityAttribute";
      key: string;
      value: string;
      entity_type: string;
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    videoForLearners: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    strictAgeRange: {
      __typename?: "AgeRangeType";
      min: number;
      max: number;
    } | null;
    clubWelcomeVideo: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    clubBannerImage: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    clubGuidelines: {
      __typename?: "WelcomeMessage";
      text: string | null;
    } | null;
    sampleClubPost: {
      __typename?: "WelcomeMessage";
      text: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      }> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      } | null;
    } | null;
    sampleClubPosts: Array<{
      __typename?: "SampleClubPost";
      text: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      }> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      } | null;
      comments: Array<{
        __typename?: "SampleClubPostComment";
        text: string | null;
      }>;
    }> | null;
    pricingAdjustments: {
      __typename?: "PricingAdjustment";
      weeklySurchargePercentage: number | null;
    };
    pricingInfo: {
      __typename?: "PricingInfo";
      totalPriceCentsForUpfrontPayment: number;
      totalPriceCentsForWeeklyPayments: number;
      weeklyPriceCentsForUpfrontPayment: number;
      weeklyPriceCentsForWeeklyPayments: number;
      upfrontDiscountPercentage: number;
    } | null;
    proficiency_level: {
      __typename?: "ProficiencyLevel";
      level: Array<string | null> | null;
      text: string | null;
    } | null;
    details: {
      __typename?: "ActivityDetails";
      photo: string | null;
      photoSquare: string | null;
      asyncTime: string | null;
    };
    leaderDetails: {
      __typename?: "ActivityLeaderDetails";
      uid: string;
      requestedListingAt: any | null;
      welcomeMessage: {
        __typename?: "WelcomeMessage";
        text: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        }> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        } | null;
      } | null;
    };
    externalResources: Array<{
      __typename?: "ExternalResource";
      uid: string;
      name: string;
      url: string | null;
    }>;
  };
};

export type UpdateTeacherScheduleAvailabilityMutationVariables = Exact<{
  availableTimes: Array<AvailableTimesInput> | AvailableTimesInput;
  uidsToDelete: Array<Scalars["ID"]> | Scalars["ID"];
  activityUid: Scalars["ID"];
}>;

export type UpdateTeacherScheduleAvailabilityMutation = {
  __typename?: "Mutation";
  updateTeacherScheduleAvailability: boolean;
};

export type UpdateScheduleSettingsMutationVariables = Exact<{
  newScheduleSettings?: InputMaybe<ActivityScheduleSettingsInput>;
  activityUid: Scalars["ID"];
}>;

export type UpdateScheduleSettingsMutation = {
  __typename?: "Mutation";
  updateScheduleSettings: boolean;
};

export type UpdateTeacherScheduleMutationVariables = Exact<{
  input: ScheduleInput;
  activityUid: Scalars["ID"];
}>;

export type UpdateTeacherScheduleMutation = {
  __typename?: "Mutation";
  updateTeacherSchedule: boolean;
};

export type ActivityWaitlistQueryQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type ActivityWaitlistQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    latestLegalName: string | null;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    teacher_approved_at: any | null;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    roles: Array<string> | null;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    consented_to_coppa_notice_at: any | null;
    hasVerifiedIdentity: boolean | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    upgradablePackages: Array<number | null> | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    details: {
      __typename?: "UserDetails";
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    location: {
      __typename?: "Location";
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      timeZone: string | null;
      state: string | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
    } | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
    } | null;
  } | null;
  enrollmentForm: {
    __typename?: "EnrollmentForm";
    section: {
      __typename?: "Section";
      filledSpaceCount: number;
      meetingDays: string | null;
      uid: string;
      activity_uid: string;
      start_time: any | null;
      end_time: any | null;
      startsAtNight: boolean | null;
      is_cross_listed: boolean;
      size_max: number | null;
      size_min: number | null;
      isPublished: boolean | null;
      price_cents: number | null;
      priceCredits: number | null;
      published_at: any | null;
      updated_at: any;
      canceled_at: any | null;
      deleted_at: any | null;
      ongoing_stopped_at: any | null;
      duration_minutes: number | null;
      usesOutschoolVideoChat: boolean;
      usersCanSendPrivateMessage: boolean;
      currentUserCanManage: boolean;
      isBuyable: boolean;
      allowsTeacherTransfer: boolean;
      leader: {
        __typename?: "Leader";
        hasBackgroundCheckExpired: boolean | null;
        uid: string;
        name: string | null;
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      };
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        deleted_at: any | null;
        created_at: any | null;
      } | null;
      details: {
        __typename?: "SectionDetails";
        autoScheduledDraft: boolean | null;
      };
      prepaidOngoingPeriodOptions: Array<{
        __typename?: "Period";
        startOfIsoWeek: any;
        endOfIsoWeek: any;
      }> | null;
    };
    activity: {
      __typename?: "Activity";
      uid: string;
      slug_id: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      class_experience: string | null;
      learning_goals: string | null;
      learningGoals: Array<string>;
      assessment: string | null;
      homework: string | null;
      grading_policy: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      grade_level_min: string | null;
      grade_level_max: string | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      priceCredits: number | null;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      allowWaitlist: boolean | null;
      isClub: boolean;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      allows_recurring_payment: boolean | null;
      allows_late_enrollments_for_fixed_length_classes: boolean;
      has_building_content: boolean | null;
      hasTeacherSchedule: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      classFormat: EducatorSelectedFormat;
      classroomType: ClassroomType;
      strictAgeRange: {
        __typename?: "AgeRangeType";
        min: number;
        max: number;
      } | null;
      proficiency_level: {
        __typename?: "ProficiencyLevel";
        level: Array<string | null> | null;
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
      };
      details: {
        __typename?: "ActivityDetails";
        photo: string | null;
        photoSquare: string | null;
      };
    };
    currentUserEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      section_uid: string | null;
      price_cents: number | null;
      priceCents: number | null;
      created_at: any | null;
      confirmed_at: any | null;
      confirmedAt: any | null;
      withdrawn_at: any | null;
      ongoing_stopped_at: any | null;
      currency_code: CurrencyCode;
      exchange_rate: number;
      amount_in_local_currency: number;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      withdrawnAt: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      isPrepay: boolean;
      refundableAmountCents: number;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      usersCanCommunicate: boolean;
      purchasedAsGift: boolean;
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        type: RefundPolicyType;
        name: string;
        description: string;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        age: number | null;
        name: string | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      siblings: Array<{
        __typename?: "Sibling";
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
          age: number | null;
          avatar: string | null;
          pronoun: string | null;
          intro: string | null;
          deleted_at: any | null;
          isVerified: boolean;
          isBlocked: boolean | null;
          blockedByUserUid: string | null;
          blockedAt: any | null;
        };
        currentOrLastSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          uid: string;
          activity_uid: string;
          start_time: any | null;
          end_time: any | null;
          startsAtNight: boolean | null;
          is_cross_listed: boolean;
          size_max: number | null;
          size_min: number | null;
          isPublished: boolean | null;
          price_cents: number | null;
          priceCredits: number | null;
          published_at: any | null;
          updated_at: any;
          canceled_at: any | null;
          deleted_at: any | null;
          ongoing_stopped_at: any | null;
          duration_minutes: number | null;
          usesOutschoolVideoChat: boolean;
          usersCanSendPrivateMessage: boolean;
          currentUserCanManage: boolean;
          isBuyable: boolean;
          allowsTeacherTransfer: boolean;
          nextOngoingMeeting: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
            deleted_at: any | null;
            created_at: any | null;
          } | null;
          leader: { __typename?: "Leader"; uid: string; name: string | null };
          details: {
            __typename?: "SectionDetails";
            autoScheduledDraft: boolean | null;
          };
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
        };
      }>;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
        endTime: any | null;
        scheduleType: PaymentScheduleTypes;
        amountCents: number | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
    }>;
  } | null;
};

export type UpdateSectionAdminMutationVariables = Exact<{
  uid: Scalars["ID"];
  updates: SectionAdminInput;
}>;

export type UpdateSectionAdminMutation = {
  __typename?: "Mutation";
  updateSectionAdmin: {
    __typename?: "Section";
    uid: string;
    notes: string | null;
    standardPayoutCents: number;
    zoomMeeting: { __typename?: "ZoomMeeting"; zoom_id: string | null } | null;
  };
};

export type MarkEnrollmentWithdrawnMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
  withdrawnReason: Scalars["String"];
  withdrawnNote: Scalars["String"];
}>;

export type MarkEnrollmentWithdrawnMutation = {
  __typename?: "Mutation";
  markEnrollmentWithdrawn: {
    __typename?: "Enrollment";
    uid: string;
    section_uid: string | null;
    price_cents: number | null;
    priceCents: number | null;
    created_at: any | null;
    confirmed_at: any | null;
    confirmedAt: any | null;
    withdrawn_at: any | null;
    ongoing_stopped_at: any | null;
    currency_code: CurrencyCode;
    exchange_rate: number;
    amount_in_local_currency: number;
    ongoing_start_time: any | null;
    ongoing_end_time: any | null;
    withdrawnAt: any | null;
    ongoingStoppedAt: any | null;
    ongoingStartTime: any | null;
    ongoingEndTime: any | null;
    isPaused: boolean;
    isEnded: boolean;
    isPrepay: boolean;
    refundableAmountCents: number;
    canBeWithdrawn: boolean;
    canBeStopped: boolean;
    canBePaused: boolean;
    usersCanCommunicate: boolean;
    purchasedAsGift: boolean;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      type: RefundPolicyType;
      name: string;
      description: string;
    } | null;
    details: {
      __typename?: "EnrollmentDetails";
      name: string | null;
      age: string | null;
      attendedAt: any | null;
      message: string | null;
    };
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    buyer: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    siblings: Array<{
      __typename?: "Sibling";
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
      currentOrLastSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
      };
    }>;
    paymentSchedule: {
      __typename?: "PaymentSchedule";
      uid: string;
      stoppedAt: any | null;
      endTime: any | null;
      scheduleType: PaymentScheduleTypes;
      amountCents: number | null;
    } | null;
    pauseWindow: {
      __typename?: "EnrollmentPauseWindow";
      uid: string;
      startDate: any;
      endDate: any;
      canBeCanceled: boolean;
      pauseReason: string;
    } | null;
  };
};

export type MarkUnclaimedGiftWithdrawnMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
  withdrawnReason: Scalars["String"];
  withdrawnNote: Scalars["String"];
}>;

export type MarkUnclaimedGiftWithdrawnMutation = {
  __typename?: "Mutation";
  markUnclaimedGiftWithdrawn: {
    __typename?: "GiftEnrollment";
    uid: string;
    giftClaimedAt: any | null;
    createdAt: any;
    confirmedAt: any | null;
    withdrawnAt: any | null;
    withdrawn_at: any | null;
    ongoingStoppedAt: any | null;
    ongoingStartTime: any | null;
    ongoingEndTime: any | null;
    ongoing_stopped_at: any | null;
    currency_code: CurrencyCode;
    exchange_rate: number;
    amount_in_local_currency: number;
    ongoing_start_time: any | null;
    ongoing_end_time: any | null;
    hasPaymentSchedule: boolean;
    isEnded: boolean;
    isPaused: boolean;
    orderSlugId: string | null;
    canBeWithdrawn: boolean;
    canBeStopped: boolean;
    canBePaused: boolean;
    priceCents: number | null;
    currentUserIsPurchaser: boolean;
    refundableAmountCents: number;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    buyer: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
    details: {
      __typename?: "EnrollmentDetails";
      name: string | null;
      age: string | null;
      attendedAt: any | null;
      message: string | null;
    };
    payments: Array<{
      __typename?: "EnrollmentPayment";
      uid: string;
      amount_cents: number | null;
      order_slug_id: string | null;
      stripe_charge_id: string | null;
      purchase_order_id: string | null;
      esa_order_id: string | null;
      intercom_url: string | null;
      last4: string | null;
      created_at: any | null;
      paid_at: any | null;
      disbursed_at: any | null;
      payout_uid: string | null;
      start_time: any | null;
      end_time: any | null;
      unrefundedCents: number;
      isOngoingRefundable: boolean;
      voucher_name: string | null;
      pricing_offer_uid: string | null;
      credit_transaction_uid: string | null;
      coupon_code_uid: string | null;
      currency_code: CurrencyCode | null;
      exchange_rate: number | null;
      invoice_uid: string | null;
      funding_program_invoice_uid: string | null;
      service_fee_percentage: number | null;
      couponCode: {
        __typename?: "CouponCode";
        code: string;
        coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
      } | null;
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
    }>;
    refundPolicy: {
      __typename?: "RefundPolicy";
      type: RefundPolicyType;
      name: string;
      description: string;
    } | null;
    paymentSchedule: {
      __typename?: "PaymentSchedule";
      uid: string;
      stoppedAt: any | null;
      endTime: any | null;
    } | null;
    pauseWindow: {
      __typename?: "EnrollmentPauseWindow";
      uid: string;
      startDate: any;
      endDate: any;
      canBeCanceled: boolean;
      pauseReason: string;
    } | null;
  };
};

export type MarkEnrollmentSubscriptionStoppedMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
  reason: Scalars["String"];
  note: Scalars["String"];
}>;

export type MarkEnrollmentSubscriptionStoppedMutation = {
  __typename?: "Mutation";
  markEnrollmentSubscriptionStopped: {
    __typename?: "Enrollment";
    uid: string;
    section_uid: string | null;
    price_cents: number | null;
    priceCents: number | null;
    created_at: any | null;
    confirmed_at: any | null;
    confirmedAt: any | null;
    withdrawn_at: any | null;
    ongoing_stopped_at: any | null;
    currency_code: CurrencyCode;
    exchange_rate: number;
    amount_in_local_currency: number;
    ongoing_start_time: any | null;
    ongoing_end_time: any | null;
    withdrawnAt: any | null;
    ongoingStoppedAt: any | null;
    ongoingStartTime: any | null;
    ongoingEndTime: any | null;
    isPaused: boolean;
    isEnded: boolean;
    isPrepay: boolean;
    refundableAmountCents: number;
    canBeWithdrawn: boolean;
    canBeStopped: boolean;
    canBePaused: boolean;
    usersCanCommunicate: boolean;
    purchasedAsGift: boolean;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      type: RefundPolicyType;
      name: string;
      description: string;
    } | null;
    details: {
      __typename?: "EnrollmentDetails";
      name: string | null;
      age: string | null;
      attendedAt: any | null;
      message: string | null;
    };
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    buyer: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    siblings: Array<{
      __typename?: "Sibling";
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
      currentOrLastSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
      };
    }>;
    paymentSchedule: {
      __typename?: "PaymentSchedule";
      uid: string;
      stoppedAt: any | null;
      endTime: any | null;
      scheduleType: PaymentScheduleTypes;
      amountCents: number | null;
    } | null;
    pauseWindow: {
      __typename?: "EnrollmentPauseWindow";
      uid: string;
      startDate: any;
      endDate: any;
      canBeCanceled: boolean;
      pauseReason: string;
    } | null;
  };
};

export type MarkUnclaimedGiftSubscriptionStoppedMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
  reason: Scalars["String"];
  note: Scalars["String"];
}>;

export type MarkUnclaimedGiftSubscriptionStoppedMutation = {
  __typename?: "Mutation";
  markUnclaimedGiftSubscriptionStopped: {
    __typename?: "GiftEnrollment";
    uid: string;
    giftClaimedAt: any | null;
    createdAt: any;
    confirmedAt: any | null;
    withdrawnAt: any | null;
    withdrawn_at: any | null;
    ongoingStoppedAt: any | null;
    ongoingStartTime: any | null;
    ongoingEndTime: any | null;
    ongoing_stopped_at: any | null;
    currency_code: CurrencyCode;
    exchange_rate: number;
    amount_in_local_currency: number;
    ongoing_start_time: any | null;
    ongoing_end_time: any | null;
    hasPaymentSchedule: boolean;
    isEnded: boolean;
    isPaused: boolean;
    orderSlugId: string | null;
    canBeWithdrawn: boolean;
    canBeStopped: boolean;
    canBePaused: boolean;
    priceCents: number | null;
    currentUserIsPurchaser: boolean;
    refundableAmountCents: number;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    buyer: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
    details: {
      __typename?: "EnrollmentDetails";
      name: string | null;
      age: string | null;
      attendedAt: any | null;
      message: string | null;
    };
    payments: Array<{
      __typename?: "EnrollmentPayment";
      uid: string;
      amount_cents: number | null;
      order_slug_id: string | null;
      stripe_charge_id: string | null;
      purchase_order_id: string | null;
      esa_order_id: string | null;
      intercom_url: string | null;
      last4: string | null;
      created_at: any | null;
      paid_at: any | null;
      disbursed_at: any | null;
      payout_uid: string | null;
      start_time: any | null;
      end_time: any | null;
      unrefundedCents: number;
      isOngoingRefundable: boolean;
      voucher_name: string | null;
      pricing_offer_uid: string | null;
      credit_transaction_uid: string | null;
      coupon_code_uid: string | null;
      currency_code: CurrencyCode | null;
      exchange_rate: number | null;
      invoice_uid: string | null;
      funding_program_invoice_uid: string | null;
      service_fee_percentage: number | null;
      couponCode: {
        __typename?: "CouponCode";
        code: string;
        coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
      } | null;
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
    }>;
    refundPolicy: {
      __typename?: "RefundPolicy";
      type: RefundPolicyType;
      name: string;
      description: string;
    } | null;
    paymentSchedule: {
      __typename?: "PaymentSchedule";
      uid: string;
      stoppedAt: any | null;
      endTime: any | null;
    } | null;
    pauseWindow: {
      __typename?: "EnrollmentPauseWindow";
      uid: string;
      startDate: any;
      endDate: any;
      canBeCanceled: boolean;
      pauseReason: string;
    } | null;
  };
};

export type MarkEnrollmentPaidMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
}>;

export type MarkEnrollmentPaidMutation = {
  __typename?: "Mutation";
  markEnrollmentPaid: {
    __typename?: "Enrollment";
    uid: string;
    section_uid: string | null;
    price_cents: number | null;
    priceCents: number | null;
    created_at: any | null;
    confirmed_at: any | null;
    confirmedAt: any | null;
    withdrawn_at: any | null;
    ongoing_stopped_at: any | null;
    currency_code: CurrencyCode;
    exchange_rate: number;
    amount_in_local_currency: number;
    ongoing_start_time: any | null;
    ongoing_end_time: any | null;
    withdrawnAt: any | null;
    ongoingStoppedAt: any | null;
    ongoingStartTime: any | null;
    ongoingEndTime: any | null;
    isPaused: boolean;
    isEnded: boolean;
    isPrepay: boolean;
    refundableAmountCents: number;
    canBeWithdrawn: boolean;
    canBeStopped: boolean;
    canBePaused: boolean;
    usersCanCommunicate: boolean;
    purchasedAsGift: boolean;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      type: RefundPolicyType;
      name: string;
      description: string;
    } | null;
    details: {
      __typename?: "EnrollmentDetails";
      name: string | null;
      age: string | null;
      attendedAt: any | null;
      message: string | null;
    };
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    buyer: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    siblings: Array<{
      __typename?: "Sibling";
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
      currentOrLastSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
      };
    }>;
    paymentSchedule: {
      __typename?: "PaymentSchedule";
      uid: string;
      stoppedAt: any | null;
      endTime: any | null;
      scheduleType: PaymentScheduleTypes;
      amountCents: number | null;
    } | null;
    pauseWindow: {
      __typename?: "EnrollmentPauseWindow";
      uid: string;
      startDate: any;
      endDate: any;
      canBeCanceled: boolean;
      pauseReason: string;
    } | null;
  };
};

export type CancelPendingEsaEnrollmentMutationVariables = Exact<{
  orderSlugId: Scalars["ID"];
}>;

export type CancelPendingEsaEnrollmentMutation = {
  __typename?: "Mutation";
  cancelPendingEsaEnrollment: boolean;
};

export type CancelPendingOneOnOneEsaEnrollmentMutationMutationVariables =
  Exact<{
    enrollmentUid: Scalars["ID"];
  }>;

export type CancelPendingOneOnOneEsaEnrollmentMutationMutation = {
  __typename?: "Mutation";
  cancelPendingOneOnOneEsaEnrollmentMutation: boolean;
};

export type MarkEnrollmentSubscriptionStoppedAtDateMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
  endDate: Scalars["DateTime"];
  reason: Scalars["String"];
  note: Scalars["String"];
}>;

export type MarkEnrollmentSubscriptionStoppedAtDateMutation = {
  __typename?: "Mutation";
  markEnrollmentSubscriptionStoppedAtDate: {
    __typename?: "Enrollment";
    uid: string;
    section_uid: string | null;
    price_cents: number | null;
    priceCents: number | null;
    created_at: any | null;
    confirmed_at: any | null;
    confirmedAt: any | null;
    withdrawn_at: any | null;
    ongoing_stopped_at: any | null;
    currency_code: CurrencyCode;
    exchange_rate: number;
    amount_in_local_currency: number;
    ongoing_start_time: any | null;
    ongoing_end_time: any | null;
    withdrawnAt: any | null;
    ongoingStoppedAt: any | null;
    ongoingStartTime: any | null;
    ongoingEndTime: any | null;
    isPaused: boolean;
    isEnded: boolean;
    isPrepay: boolean;
    refundableAmountCents: number;
    canBeWithdrawn: boolean;
    canBeStopped: boolean;
    canBePaused: boolean;
    usersCanCommunicate: boolean;
    purchasedAsGift: boolean;
    payments: Array<{
      __typename?: "EnrollmentPayment";
      uid: string;
      amount_cents: number | null;
      order_slug_id: string | null;
      stripe_charge_id: string | null;
      purchase_order_id: string | null;
      esa_order_id: string | null;
      intercom_url: string | null;
      last4: string | null;
      created_at: any | null;
      paid_at: any | null;
      disbursed_at: any | null;
      payout_uid: string | null;
      start_time: any | null;
      end_time: any | null;
      unrefundedCents: number;
      isOngoingRefundable: boolean;
      voucher_name: string | null;
      pricing_offer_uid: string | null;
      credit_transaction_uid: string | null;
      coupon_code_uid: string | null;
      currency_code: CurrencyCode | null;
      exchange_rate: number | null;
      invoice_uid: string | null;
      funding_program_invoice_uid: string | null;
      service_fee_percentage: number | null;
      refunds: Array<{
        __typename?: "EnrollmentPaymentRefund";
        uid: string;
        user_uid: string;
        amount_cents: number;
        amount_credits: number | null;
        refunded_at: any | null;
        reason: string | null;
        user_role: string | null;
        note: string | null;
        reduces_teacher_payout: boolean;
        amount_in_local_currency: number | null;
        currency_code: CurrencyCode | null;
        exchange_rate: number | null;
        addOutschoolCreditTransaction: {
          __typename?: "CreditTransaction";
          uid: string;
          amount_cents: number;
          expiresAt: any | null;
          unspentCents: number | null;
        } | null;
      }>;
      couponCode: {
        __typename?: "CouponCode";
        code: string;
        coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
      } | null;
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
    }>;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      type: RefundPolicyType;
      name: string;
      description: string;
    } | null;
    details: {
      __typename?: "EnrollmentDetails";
      name: string | null;
      age: string | null;
      attendedAt: any | null;
      message: string | null;
    };
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    buyer: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    siblings: Array<{
      __typename?: "Sibling";
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
      currentOrLastSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
      };
    }>;
    paymentSchedule: {
      __typename?: "PaymentSchedule";
      uid: string;
      stoppedAt: any | null;
      endTime: any | null;
      scheduleType: PaymentScheduleTypes;
      amountCents: number | null;
    } | null;
    pauseWindow: {
      __typename?: "EnrollmentPauseWindow";
      uid: string;
      startDate: any;
      endDate: any;
      canBeCanceled: boolean;
      pauseReason: string;
    } | null;
  };
};

export type AdminUserActionFragmentFragment = {
  __typename?: "AdminUserAction";
  uid: string;
  created_at: any;
  action: string;
  notes: string | null;
  customValue: string | null;
};

export type DeleteAdminUserIncidentMutationVariables = Exact<{
  incidentUid: Scalars["ID"];
}>;

export type DeleteAdminUserIncidentMutation = {
  __typename?: "Mutation";
  deleteAdminUserIncident: boolean;
};

export type UpdateAdminUserIncidentMutationVariables = Exact<{
  incidentUid: Scalars["ID"];
  category: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateAdminUserIncidentMutation = {
  __typename?: "Mutation";
  updateAdminUserIncident: {
    __typename?: "AdminUserIncident";
    uid: string;
    created_at: any;
    updated_at: any;
    category: string;
    notes: string | null;
    admin: { __typename?: "User"; uid: string; name: string | null };
  };
};

export type AddAdminUserIncidentMutationVariables = Exact<{
  userUid: Scalars["ID"];
  category: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
}>;

export type AddAdminUserIncidentMutation = {
  __typename?: "Mutation";
  addAdminUserIncident: {
    __typename?: "AdminUserIncident";
    uid: string;
    created_at: any;
    updated_at: any;
    category: string;
    notes: string | null;
    admin: { __typename?: "User"; uid: string; name: string | null };
  };
};

export type AddAdminUserActionMutationVariables = Exact<{
  userUid: Scalars["ID"];
  action: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  customValue?: InputMaybe<Scalars["String"]>;
  confirmation?: InputMaybe<Scalars["String"]>;
}>;

export type AddAdminUserActionMutation = {
  __typename?: "Mutation";
  addAdminUserAction: {
    __typename?: "AdminUserAction";
    uid: string;
    created_at: any;
    action: string;
    notes: string | null;
    customValue: string | null;
    admin: { __typename?: "User"; uid: string; name: string | null };
    user: {
      __typename?: "User";
      roles: Array<string> | null;
      teacher_featured_at: any | null;
      hasVerifiedIdentity: boolean | null;
      uid: string;
      slug_id: string;
      name: string | null;
      email: string | null;
      isLearnerModeEnabled: boolean | null;
      confirmed_at: any | null;
      subscribed_at: any | null;
      email_bounced_at: any | null;
      default_currency: string | null;
      phone: string | null;
      photo: string | null;
      leader_subscribed_at: any | null;
      calendar_uid: string;
      intercomUserHash: string | null;
      payout_email: string | null;
      owner_of_seller_org_uid: string | null;
      admin_for_organization_uid: string | null;
      do_not_sell_my_personal_info: boolean;
      background_checked_at: any | null;
      background_check_started_at: any | null;
      interview_scheduled_at: any | null;
      interviewed_at: any | null;
      classroom_training_scheduled_at: any | null;
      classroom_trained_at: any | null;
      latestLegalName: string | null;
      hasCompletedClassContentPoliciesTraining: boolean;
      hasCompletedSafetyAndPrivacyTraining: boolean;
      hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
      hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
      teacher_approved_at: any | null;
      is_crosslister: boolean | null;
      introduction: string | null;
      created_at: any;
      updated_at: any;
      deleted_at: any | null;
      suspended_at: any | null;
      is_admin: boolean | null;
      learner_uses_parent_mode: boolean;
      leader_link: string | null;
      unreadMessageThreadsCount: number;
      unreadPrivateClassMessageThreadsCount: number;
      locale: UserLocale;
      isRecordingAutopostEnabled: boolean | null;
      learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
      anonymousId: string | null;
      wasReferred: boolean;
      isEducatorBetaTester: boolean | null;
      consented_to_coppa_notice_at: any | null;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
      privacy_state: string | null;
      profile_link_uid: string | null;
      hasSubscription: boolean;
      canPayWithSubscriptionCredits: boolean;
      upgradablePackages: Array<number | null> | null;
      details: {
        __typename?: "UserDetails";
        holdMessages: boolean | null;
        about: string | null;
        headline: string | null;
        countryOfResidence: string | null;
        introduction: string | null;
        browserTimeZone: string | null;
        timeRangeAfterSchool: boolean | null;
        timeRangeSchool: boolean | null;
        timeRangeWeekend: boolean | null;
        socialOptOut: boolean | null;
        howFoundOutschool: string | null;
        howFoundOutschoolOther: string | null;
        educationApproach: string | null;
        adminDashboardUrl: string | null;
        experiments: Array<string | null> | null;
      };
      adminActions: Array<{
        __typename?: "AdminUserAction";
        uid: string;
        created_at: any;
        action: string;
        notes: string | null;
        customValue: string | null;
        admin: { __typename?: "User"; uid: string; name: string | null };
      }>;
      otherEmailDetails: Array<{
        __typename?: "OtherEmailDetails";
        uid: string;
        email: string;
        confirmed_at: any | null;
      } | null> | null;
      firstPaidEnrollment: {
        __typename?: "Enrollment";
        uid: string;
        confirmed_at: any | null;
      } | null;
      location: {
        __typename?: "Location";
        address: string | null;
        city: string | null;
        lat: number | null;
        lng: number | null;
        timeZone: string | null;
        state: string | null;
      } | null;
      organizationAdmin: {
        __typename?: "OrganizationAdmin";
        organization: {
          __typename?: "Organization";
          uid: string;
          name: string;
          type: OrganizationType;
        };
      } | null;
      children: Array<{
        __typename?: "Child";
        uid: string;
        name: string | null;
        age: number | null;
        birthMonth: number | null;
        date_of_birth: any | null;
        birthYear: number | null;
        email: string | null;
        avatar: string | null;
        unlockedAvatars: Array<string>;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        email_confirmed_at: any | null;
        optedOutOfPersonalizedRecommendationsAt: any | null;
        isLoginEnabled: boolean;
        username: string | null;
        hasPassword: boolean;
        attributes: Array<{
          __typename?: "EntityAttribute";
          uid: string;
          key: string;
          value: string;
        }>;
        entityAvailabilitySchedule: {
          __typename?: "EntityAvailabilitySchedule";
          entityAvailabilities: Array<{
            __typename?: "EntityAvailability";
            startHour: number;
            startMinute: number;
            day: number;
            endHour: number;
            endMinute: number;
            uid: string;
          }>;
        } | null;
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      interfacePreferences: {
        __typename?: "InterfacePreferences";
        activityFormatDescriptionFlexHidden: boolean;
        activityFormatDescriptionOngoingHidden: boolean;
        activityFormatDescriptionOneTimeHidden: boolean;
        activityFormatDescriptionMultiDayHidden: boolean;
        defaultScheduleFilters: {
          __typename?: "UserScheduleFilters";
          startAfter: string | null;
          endBy: string | null;
          startAfterTime: number | null;
          endByTime: number | null;
          dow: string | null;
        };
      };
      schoolClosure: {
        __typename?: "SchoolClosure";
        application_status: FfapApplicationStatus | null;
        needs_assistance: boolean | null;
      } | null;
      pricingOffer: {
        __typename?: "PricingOffer";
        uid: string;
        name: string;
        userLimitCents: number;
        purchaseLimitCents: number | null;
        totalCapCents: number;
        startAfter: any;
        endBy: any;
        buyerOrgName: string | null;
        isTotalCapReached: boolean;
        remainingCapCents: number | null;
        requirePaymentMethod: boolean;
        isOfferLimitReached: boolean;
        userOfferEmail: string | null;
        discount: number | null;
        isFinancialAid: boolean;
      } | null;
      sellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
        leader_link: string | null;
        currentUserIsOwner: boolean | null;
        currentUserIsTeacher: boolean | null;
        hasApprovedTeacherAccountForOwner: boolean | null;
      } | null;
      latestSellerOrgApplication: {
        __typename?: "SellerOrgApplication";
        uid: string;
        status: SellerOrgApplicationStatus;
        approvedAt: any | null;
        rejectedAt: any | null;
        contractSignedAt: any | null;
        adminResponse: string | null;
      } | null;
      latestTeacherApplication: {
        __typename?: "TeacherApplication";
        uid: string;
        legal_name: string | null;
        status: TeacherApplicationStatus;
        submitted_at: any | null;
        reviewed_at: any | null;
        teaching_experience: string | null;
        teaching_credentials: string | null;
        class_types: string | null;
        online_profiles: string | null;
        sample_classes: string | null;
        class_subjects: Array<string | null> | null;
        age_ranges_taught: Array<string | null> | null;
        years_of_experience: string | null;
        how_did_you_hear_about_us: string | null;
        experience_types: Array<string | null> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        showCalendar: boolean | null;
        mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
      } | null;
    };
  };
};

export type RejectSellerOrgApplicationMutationVariables = Exact<{
  userUid: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
  isSendingEmail: Scalars["Boolean"];
}>;

export type RejectSellerOrgApplicationMutation = {
  __typename?: "Mutation";
  rejectSellerOrgApplication: {
    __typename?: "AdminUserAction";
    uid: string;
    created_at: any;
    action: string;
    notes: string | null;
    customValue: string | null;
    admin: { __typename?: "User"; uid: string; name: string | null };
    user: {
      __typename?: "User";
      roles: Array<string> | null;
      teacher_featured_at: any | null;
      uid: string;
      slug_id: string;
      name: string | null;
      email: string | null;
      isLearnerModeEnabled: boolean | null;
      confirmed_at: any | null;
      subscribed_at: any | null;
      email_bounced_at: any | null;
      default_currency: string | null;
      phone: string | null;
      photo: string | null;
      leader_subscribed_at: any | null;
      calendar_uid: string;
      intercomUserHash: string | null;
      payout_email: string | null;
      owner_of_seller_org_uid: string | null;
      admin_for_organization_uid: string | null;
      do_not_sell_my_personal_info: boolean;
      background_checked_at: any | null;
      background_check_started_at: any | null;
      interview_scheduled_at: any | null;
      interviewed_at: any | null;
      classroom_training_scheduled_at: any | null;
      classroom_trained_at: any | null;
      latestLegalName: string | null;
      hasCompletedClassContentPoliciesTraining: boolean;
      hasCompletedSafetyAndPrivacyTraining: boolean;
      hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
      hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
      teacher_approved_at: any | null;
      is_crosslister: boolean | null;
      introduction: string | null;
      created_at: any;
      updated_at: any;
      deleted_at: any | null;
      suspended_at: any | null;
      is_admin: boolean | null;
      learner_uses_parent_mode: boolean;
      leader_link: string | null;
      unreadMessageThreadsCount: number;
      unreadPrivateClassMessageThreadsCount: number;
      locale: UserLocale;
      isRecordingAutopostEnabled: boolean | null;
      learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
      anonymousId: string | null;
      wasReferred: boolean;
      isEducatorBetaTester: boolean | null;
      consented_to_coppa_notice_at: any | null;
      hasVerifiedIdentity: boolean | null;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
      privacy_state: string | null;
      profile_link_uid: string | null;
      hasSubscription: boolean;
      canPayWithSubscriptionCredits: boolean;
      upgradablePackages: Array<number | null> | null;
      details: {
        __typename?: "UserDetails";
        holdMessages: boolean | null;
        about: string | null;
        headline: string | null;
        countryOfResidence: string | null;
        introduction: string | null;
        browserTimeZone: string | null;
        timeRangeAfterSchool: boolean | null;
        timeRangeSchool: boolean | null;
        timeRangeWeekend: boolean | null;
        socialOptOut: boolean | null;
        howFoundOutschool: string | null;
        howFoundOutschoolOther: string | null;
        educationApproach: string | null;
        adminDashboardUrl: string | null;
        experiments: Array<string | null> | null;
      };
      otherEmailDetails: Array<{
        __typename?: "OtherEmailDetails";
        uid: string;
        email: string;
        confirmed_at: any | null;
      } | null> | null;
      firstPaidEnrollment: {
        __typename?: "Enrollment";
        uid: string;
        confirmed_at: any | null;
      } | null;
      location: {
        __typename?: "Location";
        address: string | null;
        city: string | null;
        lat: number | null;
        lng: number | null;
        timeZone: string | null;
        state: string | null;
      } | null;
      organizationAdmin: {
        __typename?: "OrganizationAdmin";
        organization: {
          __typename?: "Organization";
          uid: string;
          name: string;
          type: OrganizationType;
        };
      } | null;
      children: Array<{
        __typename?: "Child";
        uid: string;
        name: string | null;
        age: number | null;
        birthMonth: number | null;
        date_of_birth: any | null;
        birthYear: number | null;
        email: string | null;
        avatar: string | null;
        unlockedAvatars: Array<string>;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        email_confirmed_at: any | null;
        optedOutOfPersonalizedRecommendationsAt: any | null;
        isLoginEnabled: boolean;
        username: string | null;
        hasPassword: boolean;
        attributes: Array<{
          __typename?: "EntityAttribute";
          uid: string;
          key: string;
          value: string;
        }>;
        entityAvailabilitySchedule: {
          __typename?: "EntityAvailabilitySchedule";
          entityAvailabilities: Array<{
            __typename?: "EntityAvailability";
            startHour: number;
            startMinute: number;
            day: number;
            endHour: number;
            endMinute: number;
            uid: string;
          }>;
        } | null;
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      interfacePreferences: {
        __typename?: "InterfacePreferences";
        activityFormatDescriptionFlexHidden: boolean;
        activityFormatDescriptionOngoingHidden: boolean;
        activityFormatDescriptionOneTimeHidden: boolean;
        activityFormatDescriptionMultiDayHidden: boolean;
        defaultScheduleFilters: {
          __typename?: "UserScheduleFilters";
          startAfter: string | null;
          endBy: string | null;
          startAfterTime: number | null;
          endByTime: number | null;
          dow: string | null;
        };
      };
      schoolClosure: {
        __typename?: "SchoolClosure";
        application_status: FfapApplicationStatus | null;
        needs_assistance: boolean | null;
      } | null;
      pricingOffer: {
        __typename?: "PricingOffer";
        uid: string;
        name: string;
        userLimitCents: number;
        purchaseLimitCents: number | null;
        totalCapCents: number;
        startAfter: any;
        endBy: any;
        buyerOrgName: string | null;
        isTotalCapReached: boolean;
        remainingCapCents: number | null;
        requirePaymentMethod: boolean;
        isOfferLimitReached: boolean;
        userOfferEmail: string | null;
        discount: number | null;
        isFinancialAid: boolean;
      } | null;
      sellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
        leader_link: string | null;
        currentUserIsOwner: boolean | null;
        currentUserIsTeacher: boolean | null;
        hasApprovedTeacherAccountForOwner: boolean | null;
      } | null;
      latestSellerOrgApplication: {
        __typename?: "SellerOrgApplication";
        uid: string;
        status: SellerOrgApplicationStatus;
        approvedAt: any | null;
        rejectedAt: any | null;
        contractSignedAt: any | null;
        adminResponse: string | null;
      } | null;
      latestTeacherApplication: {
        __typename?: "TeacherApplication";
        uid: string;
        legal_name: string | null;
        status: TeacherApplicationStatus;
        submitted_at: any | null;
        reviewed_at: any | null;
        teaching_experience: string | null;
        teaching_credentials: string | null;
        class_types: string | null;
        online_profiles: string | null;
        sample_classes: string | null;
        class_subjects: Array<string | null> | null;
        age_ranges_taught: Array<string | null> | null;
        years_of_experience: string | null;
        how_did_you_hear_about_us: string | null;
        experience_types: Array<string | null> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        showCalendar: boolean | null;
        mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
      } | null;
    };
  };
};

export type AssignTeacherToSellerOrgMutationVariables = Exact<{
  userUid: Scalars["ID"];
  sellerOrgUserUid: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
}>;

export type AssignTeacherToSellerOrgMutation = {
  __typename?: "Mutation";
  assignTeacherToSellerOrg: {
    __typename?: "AdminUserAction";
    uid: string;
    created_at: any;
    action: string;
    notes: string | null;
    customValue: string | null;
    admin: { __typename?: "User"; uid: string; name: string | null };
    user: {
      __typename?: "User";
      roles: Array<string> | null;
      teacher_featured_at: any | null;
      uid: string;
      slug_id: string;
      name: string | null;
      email: string | null;
      isLearnerModeEnabled: boolean | null;
      confirmed_at: any | null;
      subscribed_at: any | null;
      email_bounced_at: any | null;
      default_currency: string | null;
      phone: string | null;
      photo: string | null;
      leader_subscribed_at: any | null;
      calendar_uid: string;
      intercomUserHash: string | null;
      payout_email: string | null;
      owner_of_seller_org_uid: string | null;
      admin_for_organization_uid: string | null;
      do_not_sell_my_personal_info: boolean;
      background_checked_at: any | null;
      background_check_started_at: any | null;
      interview_scheduled_at: any | null;
      interviewed_at: any | null;
      classroom_training_scheduled_at: any | null;
      classroom_trained_at: any | null;
      latestLegalName: string | null;
      hasCompletedClassContentPoliciesTraining: boolean;
      hasCompletedSafetyAndPrivacyTraining: boolean;
      hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
      hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
      teacher_approved_at: any | null;
      is_crosslister: boolean | null;
      introduction: string | null;
      created_at: any;
      updated_at: any;
      deleted_at: any | null;
      suspended_at: any | null;
      is_admin: boolean | null;
      learner_uses_parent_mode: boolean;
      leader_link: string | null;
      unreadMessageThreadsCount: number;
      unreadPrivateClassMessageThreadsCount: number;
      locale: UserLocale;
      isRecordingAutopostEnabled: boolean | null;
      learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
      anonymousId: string | null;
      wasReferred: boolean;
      isEducatorBetaTester: boolean | null;
      consented_to_coppa_notice_at: any | null;
      hasVerifiedIdentity: boolean | null;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
      privacy_state: string | null;
      profile_link_uid: string | null;
      hasSubscription: boolean;
      canPayWithSubscriptionCredits: boolean;
      upgradablePackages: Array<number | null> | null;
      details: {
        __typename?: "UserDetails";
        holdMessages: boolean | null;
        about: string | null;
        headline: string | null;
        countryOfResidence: string | null;
        introduction: string | null;
        browserTimeZone: string | null;
        timeRangeAfterSchool: boolean | null;
        timeRangeSchool: boolean | null;
        timeRangeWeekend: boolean | null;
        socialOptOut: boolean | null;
        howFoundOutschool: string | null;
        howFoundOutschoolOther: string | null;
        educationApproach: string | null;
        adminDashboardUrl: string | null;
        experiments: Array<string | null> | null;
      };
      otherEmailDetails: Array<{
        __typename?: "OtherEmailDetails";
        uid: string;
        email: string;
        confirmed_at: any | null;
      } | null> | null;
      firstPaidEnrollment: {
        __typename?: "Enrollment";
        uid: string;
        confirmed_at: any | null;
      } | null;
      location: {
        __typename?: "Location";
        address: string | null;
        city: string | null;
        lat: number | null;
        lng: number | null;
        timeZone: string | null;
        state: string | null;
      } | null;
      organizationAdmin: {
        __typename?: "OrganizationAdmin";
        organization: {
          __typename?: "Organization";
          uid: string;
          name: string;
          type: OrganizationType;
        };
      } | null;
      children: Array<{
        __typename?: "Child";
        uid: string;
        name: string | null;
        age: number | null;
        birthMonth: number | null;
        date_of_birth: any | null;
        birthYear: number | null;
        email: string | null;
        avatar: string | null;
        unlockedAvatars: Array<string>;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        email_confirmed_at: any | null;
        optedOutOfPersonalizedRecommendationsAt: any | null;
        isLoginEnabled: boolean;
        username: string | null;
        hasPassword: boolean;
        attributes: Array<{
          __typename?: "EntityAttribute";
          uid: string;
          key: string;
          value: string;
        }>;
        entityAvailabilitySchedule: {
          __typename?: "EntityAvailabilitySchedule";
          entityAvailabilities: Array<{
            __typename?: "EntityAvailability";
            startHour: number;
            startMinute: number;
            day: number;
            endHour: number;
            endMinute: number;
            uid: string;
          }>;
        } | null;
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      interfacePreferences: {
        __typename?: "InterfacePreferences";
        activityFormatDescriptionFlexHidden: boolean;
        activityFormatDescriptionOngoingHidden: boolean;
        activityFormatDescriptionOneTimeHidden: boolean;
        activityFormatDescriptionMultiDayHidden: boolean;
        defaultScheduleFilters: {
          __typename?: "UserScheduleFilters";
          startAfter: string | null;
          endBy: string | null;
          startAfterTime: number | null;
          endByTime: number | null;
          dow: string | null;
        };
      };
      schoolClosure: {
        __typename?: "SchoolClosure";
        application_status: FfapApplicationStatus | null;
        needs_assistance: boolean | null;
      } | null;
      pricingOffer: {
        __typename?: "PricingOffer";
        uid: string;
        name: string;
        userLimitCents: number;
        purchaseLimitCents: number | null;
        totalCapCents: number;
        startAfter: any;
        endBy: any;
        buyerOrgName: string | null;
        isTotalCapReached: boolean;
        remainingCapCents: number | null;
        requirePaymentMethod: boolean;
        isOfferLimitReached: boolean;
        userOfferEmail: string | null;
        discount: number | null;
        isFinancialAid: boolean;
      } | null;
      sellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
        leader_link: string | null;
        currentUserIsOwner: boolean | null;
        currentUserIsTeacher: boolean | null;
        hasApprovedTeacherAccountForOwner: boolean | null;
      } | null;
      latestSellerOrgApplication: {
        __typename?: "SellerOrgApplication";
        uid: string;
        status: SellerOrgApplicationStatus;
        approvedAt: any | null;
        rejectedAt: any | null;
        contractSignedAt: any | null;
        adminResponse: string | null;
      } | null;
      latestTeacherApplication: {
        __typename?: "TeacherApplication";
        uid: string;
        legal_name: string | null;
        status: TeacherApplicationStatus;
        submitted_at: any | null;
        reviewed_at: any | null;
        teaching_experience: string | null;
        teaching_credentials: string | null;
        class_types: string | null;
        online_profiles: string | null;
        sample_classes: string | null;
        class_subjects: Array<string | null> | null;
        age_ranges_taught: Array<string | null> | null;
        years_of_experience: string | null;
        how_did_you_hear_about_us: string | null;
        experience_types: Array<string | null> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        showCalendar: boolean | null;
        mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
      } | null;
    };
  };
};

export type MergeUserAccountMutationVariables = Exact<{
  userUid: Scalars["ID"];
  mergeAccountUserUid: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
}>;

export type MergeUserAccountMutation = {
  __typename?: "Mutation";
  mergeUserAccount: {
    __typename?: "AdminUserAction";
    uid: string;
    created_at: any;
    action: string;
    notes: string | null;
    customValue: string | null;
    admin: { __typename?: "User"; uid: string; name: string | null };
    user: {
      __typename?: "User";
      roles: Array<string> | null;
      teacher_featured_at: any | null;
      uid: string;
      slug_id: string;
      name: string | null;
      email: string | null;
      isLearnerModeEnabled: boolean | null;
      confirmed_at: any | null;
      subscribed_at: any | null;
      email_bounced_at: any | null;
      default_currency: string | null;
      phone: string | null;
      photo: string | null;
      leader_subscribed_at: any | null;
      calendar_uid: string;
      intercomUserHash: string | null;
      payout_email: string | null;
      owner_of_seller_org_uid: string | null;
      admin_for_organization_uid: string | null;
      do_not_sell_my_personal_info: boolean;
      background_checked_at: any | null;
      background_check_started_at: any | null;
      interview_scheduled_at: any | null;
      interviewed_at: any | null;
      classroom_training_scheduled_at: any | null;
      classroom_trained_at: any | null;
      latestLegalName: string | null;
      hasCompletedClassContentPoliciesTraining: boolean;
      hasCompletedSafetyAndPrivacyTraining: boolean;
      hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
      hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
      teacher_approved_at: any | null;
      is_crosslister: boolean | null;
      introduction: string | null;
      created_at: any;
      updated_at: any;
      deleted_at: any | null;
      suspended_at: any | null;
      is_admin: boolean | null;
      learner_uses_parent_mode: boolean;
      leader_link: string | null;
      unreadMessageThreadsCount: number;
      unreadPrivateClassMessageThreadsCount: number;
      locale: UserLocale;
      isRecordingAutopostEnabled: boolean | null;
      learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
      anonymousId: string | null;
      wasReferred: boolean;
      isEducatorBetaTester: boolean | null;
      consented_to_coppa_notice_at: any | null;
      hasVerifiedIdentity: boolean | null;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
      privacy_state: string | null;
      profile_link_uid: string | null;
      hasSubscription: boolean;
      canPayWithSubscriptionCredits: boolean;
      upgradablePackages: Array<number | null> | null;
      details: {
        __typename?: "UserDetails";
        holdMessages: boolean | null;
        about: string | null;
        headline: string | null;
        countryOfResidence: string | null;
        introduction: string | null;
        browserTimeZone: string | null;
        timeRangeAfterSchool: boolean | null;
        timeRangeSchool: boolean | null;
        timeRangeWeekend: boolean | null;
        socialOptOut: boolean | null;
        howFoundOutschool: string | null;
        howFoundOutschoolOther: string | null;
        educationApproach: string | null;
        adminDashboardUrl: string | null;
        experiments: Array<string | null> | null;
      };
      otherEmailDetails: Array<{
        __typename?: "OtherEmailDetails";
        uid: string;
        email: string;
        confirmed_at: any | null;
      } | null> | null;
      firstPaidEnrollment: {
        __typename?: "Enrollment";
        uid: string;
        confirmed_at: any | null;
      } | null;
      location: {
        __typename?: "Location";
        address: string | null;
        city: string | null;
        lat: number | null;
        lng: number | null;
        timeZone: string | null;
        state: string | null;
      } | null;
      organizationAdmin: {
        __typename?: "OrganizationAdmin";
        organization: {
          __typename?: "Organization";
          uid: string;
          name: string;
          type: OrganizationType;
        };
      } | null;
      children: Array<{
        __typename?: "Child";
        uid: string;
        name: string | null;
        age: number | null;
        birthMonth: number | null;
        date_of_birth: any | null;
        birthYear: number | null;
        email: string | null;
        avatar: string | null;
        unlockedAvatars: Array<string>;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        email_confirmed_at: any | null;
        optedOutOfPersonalizedRecommendationsAt: any | null;
        isLoginEnabled: boolean;
        username: string | null;
        hasPassword: boolean;
        attributes: Array<{
          __typename?: "EntityAttribute";
          uid: string;
          key: string;
          value: string;
        }>;
        entityAvailabilitySchedule: {
          __typename?: "EntityAvailabilitySchedule";
          entityAvailabilities: Array<{
            __typename?: "EntityAvailability";
            startHour: number;
            startMinute: number;
            day: number;
            endHour: number;
            endMinute: number;
            uid: string;
          }>;
        } | null;
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      interfacePreferences: {
        __typename?: "InterfacePreferences";
        activityFormatDescriptionFlexHidden: boolean;
        activityFormatDescriptionOngoingHidden: boolean;
        activityFormatDescriptionOneTimeHidden: boolean;
        activityFormatDescriptionMultiDayHidden: boolean;
        defaultScheduleFilters: {
          __typename?: "UserScheduleFilters";
          startAfter: string | null;
          endBy: string | null;
          startAfterTime: number | null;
          endByTime: number | null;
          dow: string | null;
        };
      };
      schoolClosure: {
        __typename?: "SchoolClosure";
        application_status: FfapApplicationStatus | null;
        needs_assistance: boolean | null;
      } | null;
      pricingOffer: {
        __typename?: "PricingOffer";
        uid: string;
        name: string;
        userLimitCents: number;
        purchaseLimitCents: number | null;
        totalCapCents: number;
        startAfter: any;
        endBy: any;
        buyerOrgName: string | null;
        isTotalCapReached: boolean;
        remainingCapCents: number | null;
        requirePaymentMethod: boolean;
        isOfferLimitReached: boolean;
        userOfferEmail: string | null;
        discount: number | null;
        isFinancialAid: boolean;
      } | null;
      sellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
        leader_link: string | null;
        currentUserIsOwner: boolean | null;
        currentUserIsTeacher: boolean | null;
        hasApprovedTeacherAccountForOwner: boolean | null;
      } | null;
      latestSellerOrgApplication: {
        __typename?: "SellerOrgApplication";
        uid: string;
        status: SellerOrgApplicationStatus;
        approvedAt: any | null;
        rejectedAt: any | null;
        contractSignedAt: any | null;
        adminResponse: string | null;
      } | null;
      latestTeacherApplication: {
        __typename?: "TeacherApplication";
        uid: string;
        legal_name: string | null;
        status: TeacherApplicationStatus;
        submitted_at: any | null;
        reviewed_at: any | null;
        teaching_experience: string | null;
        teaching_credentials: string | null;
        class_types: string | null;
        online_profiles: string | null;
        sample_classes: string | null;
        class_subjects: Array<string | null> | null;
        age_ranges_taught: Array<string | null> | null;
        years_of_experience: string | null;
        how_did_you_hear_about_us: string | null;
        experience_types: Array<string | null> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        showCalendar: boolean | null;
        mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
      } | null;
    };
  };
};

export type AssignAsOrganizationAdminMutationVariables = Exact<{
  userUid: Scalars["ID"];
  organizationUid: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
}>;

export type AssignAsOrganizationAdminMutation = {
  __typename?: "Mutation";
  assignAsOrganizationAdmin: {
    __typename?: "AdminUserAction";
    uid: string;
    created_at: any;
    action: string;
    notes: string | null;
    customValue: string | null;
    admin: { __typename?: "User"; uid: string; name: string | null };
    user: {
      __typename?: "User";
      uid: string;
      roles: Array<string> | null;
      teacher_featured_at: any | null;
      slug_id: string;
      name: string | null;
      email: string | null;
      isLearnerModeEnabled: boolean | null;
      confirmed_at: any | null;
      subscribed_at: any | null;
      email_bounced_at: any | null;
      default_currency: string | null;
      phone: string | null;
      photo: string | null;
      leader_subscribed_at: any | null;
      calendar_uid: string;
      intercomUserHash: string | null;
      payout_email: string | null;
      owner_of_seller_org_uid: string | null;
      admin_for_organization_uid: string | null;
      do_not_sell_my_personal_info: boolean;
      background_checked_at: any | null;
      background_check_started_at: any | null;
      interview_scheduled_at: any | null;
      interviewed_at: any | null;
      classroom_training_scheduled_at: any | null;
      classroom_trained_at: any | null;
      latestLegalName: string | null;
      hasCompletedClassContentPoliciesTraining: boolean;
      hasCompletedSafetyAndPrivacyTraining: boolean;
      hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
      hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
      teacher_approved_at: any | null;
      is_crosslister: boolean | null;
      introduction: string | null;
      created_at: any;
      updated_at: any;
      deleted_at: any | null;
      suspended_at: any | null;
      is_admin: boolean | null;
      learner_uses_parent_mode: boolean;
      leader_link: string | null;
      unreadMessageThreadsCount: number;
      unreadPrivateClassMessageThreadsCount: number;
      locale: UserLocale;
      isRecordingAutopostEnabled: boolean | null;
      learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
      anonymousId: string | null;
      wasReferred: boolean;
      isEducatorBetaTester: boolean | null;
      consented_to_coppa_notice_at: any | null;
      hasVerifiedIdentity: boolean | null;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
      privacy_state: string | null;
      profile_link_uid: string | null;
      hasSubscription: boolean;
      canPayWithSubscriptionCredits: boolean;
      upgradablePackages: Array<number | null> | null;
      details: {
        __typename?: "UserDetails";
        holdMessages: boolean | null;
        about: string | null;
        headline: string | null;
        countryOfResidence: string | null;
        introduction: string | null;
        browserTimeZone: string | null;
        timeRangeAfterSchool: boolean | null;
        timeRangeSchool: boolean | null;
        timeRangeWeekend: boolean | null;
        socialOptOut: boolean | null;
        howFoundOutschool: string | null;
        howFoundOutschoolOther: string | null;
        educationApproach: string | null;
        adminDashboardUrl: string | null;
        experiments: Array<string | null> | null;
      };
      otherEmailDetails: Array<{
        __typename?: "OtherEmailDetails";
        uid: string;
        email: string;
        confirmed_at: any | null;
      } | null> | null;
      firstPaidEnrollment: {
        __typename?: "Enrollment";
        uid: string;
        confirmed_at: any | null;
      } | null;
      location: {
        __typename?: "Location";
        address: string | null;
        city: string | null;
        lat: number | null;
        lng: number | null;
        timeZone: string | null;
        state: string | null;
      } | null;
      organizationAdmin: {
        __typename?: "OrganizationAdmin";
        organization: {
          __typename?: "Organization";
          uid: string;
          name: string;
          type: OrganizationType;
        };
      } | null;
      children: Array<{
        __typename?: "Child";
        uid: string;
        name: string | null;
        age: number | null;
        birthMonth: number | null;
        date_of_birth: any | null;
        birthYear: number | null;
        email: string | null;
        avatar: string | null;
        unlockedAvatars: Array<string>;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        email_confirmed_at: any | null;
        optedOutOfPersonalizedRecommendationsAt: any | null;
        isLoginEnabled: boolean;
        username: string | null;
        hasPassword: boolean;
        attributes: Array<{
          __typename?: "EntityAttribute";
          uid: string;
          key: string;
          value: string;
        }>;
        entityAvailabilitySchedule: {
          __typename?: "EntityAvailabilitySchedule";
          entityAvailabilities: Array<{
            __typename?: "EntityAvailability";
            startHour: number;
            startMinute: number;
            day: number;
            endHour: number;
            endMinute: number;
            uid: string;
          }>;
        } | null;
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      interfacePreferences: {
        __typename?: "InterfacePreferences";
        activityFormatDescriptionFlexHidden: boolean;
        activityFormatDescriptionOngoingHidden: boolean;
        activityFormatDescriptionOneTimeHidden: boolean;
        activityFormatDescriptionMultiDayHidden: boolean;
        defaultScheduleFilters: {
          __typename?: "UserScheduleFilters";
          startAfter: string | null;
          endBy: string | null;
          startAfterTime: number | null;
          endByTime: number | null;
          dow: string | null;
        };
      };
      schoolClosure: {
        __typename?: "SchoolClosure";
        application_status: FfapApplicationStatus | null;
        needs_assistance: boolean | null;
      } | null;
      pricingOffer: {
        __typename?: "PricingOffer";
        uid: string;
        name: string;
        userLimitCents: number;
        purchaseLimitCents: number | null;
        totalCapCents: number;
        startAfter: any;
        endBy: any;
        buyerOrgName: string | null;
        isTotalCapReached: boolean;
        remainingCapCents: number | null;
        requirePaymentMethod: boolean;
        isOfferLimitReached: boolean;
        userOfferEmail: string | null;
        discount: number | null;
        isFinancialAid: boolean;
      } | null;
      sellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
        leader_link: string | null;
        currentUserIsOwner: boolean | null;
        currentUserIsTeacher: boolean | null;
        hasApprovedTeacherAccountForOwner: boolean | null;
      } | null;
      latestSellerOrgApplication: {
        __typename?: "SellerOrgApplication";
        uid: string;
        status: SellerOrgApplicationStatus;
        approvedAt: any | null;
        rejectedAt: any | null;
        contractSignedAt: any | null;
        adminResponse: string | null;
      } | null;
      latestTeacherApplication: {
        __typename?: "TeacherApplication";
        uid: string;
        legal_name: string | null;
        status: TeacherApplicationStatus;
        submitted_at: any | null;
        reviewed_at: any | null;
        teaching_experience: string | null;
        teaching_credentials: string | null;
        class_types: string | null;
        online_profiles: string | null;
        sample_classes: string | null;
        class_subjects: Array<string | null> | null;
        age_ranges_taught: Array<string | null> | null;
        years_of_experience: string | null;
        how_did_you_hear_about_us: string | null;
        experience_types: Array<string | null> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        showCalendar: boolean | null;
        mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
      } | null;
    };
  };
};

export type AdminUserIncidentFragmentFragment = {
  __typename?: "AdminUserIncident";
  uid: string;
  created_at: any;
  updated_at: any;
  category: string;
  notes: string | null;
};

export type AgreementTemplateQueryQueryVariables = Exact<{
  uid?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
}>;

export type AgreementTemplateQueryQuery = {
  __typename?: "Query";
  agreementTemplate: {
    __typename?: "SellerOrgAgreementTemplate";
    uid: string;
    name: string;
    template: string;
    createdAt: any;
    updatedAt: any;
  };
};

export type CreateAiGuideSupportIssueMutationVariables = Exact<{
  userMessage: Scalars["String"];
}>;

export type CreateAiGuideSupportIssueMutation = {
  __typename?: "Mutation";
  createAiGuideSupportIssue: boolean;
};

export type ReferrerUserNameQueryVariables = Exact<{ [key: string]: never }>;

export type ReferrerUserNameQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    referrerNameOrEmail: string | null;
  } | null;
};

export type UpdateCurrentUserReferrerMutationVariables = Exact<{
  referrerCode: Scalars["String"];
}>;

export type UpdateCurrentUserReferrerMutation = {
  __typename?: "Mutation";
  updateCurrentUserReferrer: string;
};

export type LoginMutationVariables = Exact<{
  emailOrUsername: Scalars["String"];
  password: Scalars["String"];
  postLoginOption?: InputMaybe<PostLoginOption>;
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  login:
    | {
        __typename: "AuthenticationV2";
        refreshToken: string;
        sessionToken: string;
        learnerTransferToken: string | null;
      }
    | {
        __typename: "AuthenticationV1";
        sessionToken: string;
        learnerTransferToken: string | null;
      };
};

export type ChangePasswordMutationVariables = Exact<{
  uid: Scalars["ID"];
  token: Scalars["String"];
  password: Scalars["String"];
}>;

export type ChangePasswordMutation = {
  __typename?: "Mutation";
  changePassword: {
    __typename?: "AuthenticationV2";
    sessionToken: string;
    refreshToken: string;
  };
};

export type ChangeLearnerPasswordMutationVariables = Exact<{
  uid: Scalars["ID"];
  token: Scalars["String"];
  password: Scalars["String"];
}>;

export type ChangeLearnerPasswordMutation = {
  __typename?: "Mutation";
  changeLearnerPassword: {
    __typename?: "AuthTransferToken";
    transferToken: string;
  };
};

export type ConfirmEmailMutationVariables = Exact<{
  uid: Scalars["ID"];
  token: Scalars["String"];
}>;

export type ConfirmEmailMutation = {
  __typename?: "Mutation";
  confirmEmail: {
    __typename?: "AuthenticationV2";
    sessionToken: string;
    refreshToken: string;
  };
};

export type ConfirmOtherEmailMutationVariables = Exact<{
  uid: Scalars["ID"];
  token: Scalars["String"];
}>;

export type ConfirmOtherEmailMutation = {
  __typename?: "Mutation";
  confirmOtherEmail: boolean;
};

export type GetLearnerModeTransferTokenMutationVariables = Exact<{
  learnerUid: Scalars["ID"];
}>;

export type GetLearnerModeTransferTokenMutation = {
  __typename?: "Mutation";
  learnerModeTransferToken: {
    __typename?: "AuthTransferToken";
    transferToken: string;
  };
};

export type AvailabilityFragmentFragment = {
  __typename?: "Availability";
  updated_at: any | null;
  hourlyTimetable: {
    __typename?: "HourlyTimetable";
    mon: Array<number>;
    tue: Array<number>;
    wed: Array<number>;
    thu: Array<number>;
    fri: Array<number>;
    sat: Array<number>;
    sun: Array<number>;
  } | null;
};

type BaseEnrollmentFragment_Enrollment_Fragment = {
  __typename?: "Enrollment";
  uid: string;
  createdAt: any;
  confirmedAt: any | null;
  withdrawnAt: any | null;
  ongoingStoppedAt: any | null;
  ongoingStartTime: any | null;
  ongoingEndTime: any | null;
  canBeWithdrawn: boolean;
  canBeStopped: boolean;
  priceCents: number | null;
  refundableAmountCents: number;
  currency_code: CurrencyCode;
  exchange_rate: number;
  amount_in_local_currency: number;
  parent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  };
  learner: {
    __typename?: "Learner";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    isVerified: boolean;
    isBlocked: boolean | null;
    blockedByUserUid: string | null;
    blockedAt: any | null;
  } | null;
  buyer: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  } | null;
  pricingOffer: {
    __typename?: "PricingOffer";
    name: string;
    buyerOrgName: string | null;
    isFinancialAid: boolean;
  } | null;
  details: {
    __typename?: "EnrollmentDetails";
    name: string | null;
    age: string | null;
    attendedAt: any | null;
    message: string | null;
  };
  payments: Array<{
    __typename?: "EnrollmentPayment";
    uid: string;
    amount_cents: number | null;
    order_slug_id: string | null;
    stripe_charge_id: string | null;
    purchase_order_id: string | null;
    esa_order_id: string | null;
    intercom_url: string | null;
    last4: string | null;
    created_at: any | null;
    paid_at: any | null;
    disbursed_at: any | null;
    payout_uid: string | null;
    start_time: any | null;
    end_time: any | null;
    unrefundedCents: number;
    isOngoingRefundable: boolean;
    voucher_name: string | null;
    pricing_offer_uid: string | null;
    credit_transaction_uid: string | null;
    coupon_code_uid: string | null;
    currency_code: CurrencyCode | null;
    exchange_rate: number | null;
    invoice_uid: string | null;
    funding_program_invoice_uid: string | null;
    service_fee_percentage: number | null;
    organization: {
      __typename?: "Organization";
      uid: string;
      name: string;
      state: string | null;
      logoUrl: string | null;
      urlName: string | null;
    } | null;
    refunds: Array<{
      __typename?: "EnrollmentPaymentRefund";
      uid: string;
      user_uid: string;
      amount_cents: number;
      amount_credits: number | null;
      refunded_at: any | null;
      reason: string | null;
      user_role: string | null;
      note: string | null;
      reduces_teacher_payout: boolean;
      amount_in_local_currency: number | null;
      currency_code: CurrencyCode | null;
      exchange_rate: number | null;
      addOutschoolCreditTransaction: {
        __typename?: "CreditTransaction";
        uid: string;
        amount_cents: number;
        expiresAt: any | null;
        unspentCents: number | null;
      } | null;
    }>;
    couponCode: {
      __typename?: "CouponCode";
      code: string;
      coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
  }>;
  refundPolicy: {
    __typename?: "RefundPolicy";
    type: RefundPolicyType;
    name: string;
    description: string;
  } | null;
};

type BaseEnrollmentFragment_GiftEnrollment_Fragment = {
  __typename?: "GiftEnrollment";
  uid: string;
  createdAt: any;
  confirmedAt: any | null;
  withdrawnAt: any | null;
  ongoingStoppedAt: any | null;
  ongoingStartTime: any | null;
  ongoingEndTime: any | null;
  canBeWithdrawn: boolean;
  canBeStopped: boolean;
  priceCents: number | null;
  refundableAmountCents: number;
  currency_code: CurrencyCode;
  exchange_rate: number;
  amount_in_local_currency: number;
  parent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  } | null;
  learner: {
    __typename?: "Learner";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    isVerified: boolean;
    isBlocked: boolean | null;
    blockedByUserUid: string | null;
    blockedAt: any | null;
  } | null;
  buyer: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  };
  pricingOffer: {
    __typename?: "PricingOffer";
    name: string;
    buyerOrgName: string | null;
    isFinancialAid: boolean;
  } | null;
  details: {
    __typename?: "EnrollmentDetails";
    name: string | null;
    age: string | null;
    attendedAt: any | null;
    message: string | null;
  };
  payments: Array<{
    __typename?: "EnrollmentPayment";
    uid: string;
    amount_cents: number | null;
    order_slug_id: string | null;
    stripe_charge_id: string | null;
    purchase_order_id: string | null;
    esa_order_id: string | null;
    intercom_url: string | null;
    last4: string | null;
    created_at: any | null;
    paid_at: any | null;
    disbursed_at: any | null;
    payout_uid: string | null;
    start_time: any | null;
    end_time: any | null;
    unrefundedCents: number;
    isOngoingRefundable: boolean;
    voucher_name: string | null;
    pricing_offer_uid: string | null;
    credit_transaction_uid: string | null;
    coupon_code_uid: string | null;
    currency_code: CurrencyCode | null;
    exchange_rate: number | null;
    invoice_uid: string | null;
    funding_program_invoice_uid: string | null;
    service_fee_percentage: number | null;
    organization: {
      __typename?: "Organization";
      uid: string;
      name: string;
      state: string | null;
      logoUrl: string | null;
      urlName: string | null;
    } | null;
    refunds: Array<{
      __typename?: "EnrollmentPaymentRefund";
      uid: string;
      user_uid: string;
      amount_cents: number;
      amount_credits: number | null;
      refunded_at: any | null;
      reason: string | null;
      user_role: string | null;
      note: string | null;
      reduces_teacher_payout: boolean;
      amount_in_local_currency: number | null;
      currency_code: CurrencyCode | null;
      exchange_rate: number | null;
      addOutschoolCreditTransaction: {
        __typename?: "CreditTransaction";
        uid: string;
        amount_cents: number;
        expiresAt: any | null;
        unspentCents: number | null;
      } | null;
    }>;
    couponCode: {
      __typename?: "CouponCode";
      code: string;
      coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
  }>;
  refundPolicy: {
    __typename?: "RefundPolicy";
    type: RefundPolicyType;
    name: string;
    description: string;
  } | null;
};

export type BaseEnrollmentFragmentFragment =
  | BaseEnrollmentFragment_Enrollment_Fragment
  | BaseEnrollmentFragment_GiftEnrollment_Fragment;

export type BlockLearnerMutationVariables = Exact<{
  educatorUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
  reason: Scalars["String"];
  note?: InputMaybe<Scalars["String"]>;
}>;

export type BlockLearnerMutation = {
  __typename?: "Mutation";
  blockLearner: boolean;
};

export type UnblockLearnerMutationVariables = Exact<{
  educatorUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
}>;

export type UnblockLearnerMutation = {
  __typename?: "Mutation";
  unblockLearner: boolean;
};

export type CategoryPageQueryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type CategoryPageQuery = {
  __typename?: "Query";
  categoryPage: {
    __typename?: "CategoryPage";
    uid: string;
    nameTitleCased: string;
    nameSentenceCased: string;
    reviewTitle: string;
    reviewTitleWithPhotos: string;
    slug: string;
    basePath: string;
    title: string | null;
    description: string | null;
    searchFilters: {
      __typename?: "SavedSearchFilters";
      adminTags: Array<string> | null;
      age: string | null;
      capacityMax: number | null;
      capacityMin: number | null;
      delivery: string | null;
      dow: string | null;
      enabledBooleanFilters: string | null;
      endBy: string | null;
      endByTime: number | null;
      format: string | null;
      fundingPrograms: Array<string> | null;
      hasFilledOutUniqueLearningNeeds: boolean | null;
      languageOfInstruction: string | null;
      multiTermQuery: Array<string> | null;
      order: string | null;
      pricePerMeetingMax: number | null;
      pricePerMeetingMin: number | null;
      q: string | null;
      startAfter: string | null;
      startAfterTime: number | null;
      startBefore: string | null;
      theme: string | null;
      time: string | null;
      timeOfDay: string | null;
      timeZone: string | null;
      uniqueLearningNeeds: string | null;
    } | null;
    ancestors: Array<{
      __typename?: "CategoryPage";
      uid: string;
      nameTitleCased: string;
      nameSentenceCased: string;
      slug: string;
      basePath: string;
    } | null> | null;
    related: Array<{
      __typename?: "CategoryPage";
      uid: string;
      nameTitleCased: string;
      nameSentenceCased: string;
      slug: string;
      basePath: string;
    } | null> | null;
    parent: {
      __typename?: "CategoryPage";
      uid: string;
      nameTitleCased: string;
      nameSentenceCased: string;
      slug: string;
      basePath: string;
    } | null;
    siblings: Array<{
      __typename?: "CategoryPage";
      uid: string;
      nameTitleCased: string;
      nameSentenceCased: string;
      slug: string;
      basePath: string;
    } | null> | null;
    children: Array<{
      __typename?: "CategoryPage";
      uid: string;
      nameTitleCased: string;
      nameSentenceCased: string;
      slug: string;
      basePath: string;
    } | null> | null;
    grandparent: {
      __typename?: "CategoryPage";
      uid: string;
      nameTitleCased: string;
      nameSentenceCased: string;
      slug: string;
      basePath: string;
    } | null;
    parentalSiblings: Array<{
      __typename?: "CategoryPage";
      uid: string;
      nameTitleCased: string;
      nameSentenceCased: string;
      slug: string;
      basePath: string;
    } | null> | null;
  } | null;
};

export type CharterSchoolFragmentFragment = {
  __typename?: "Organization";
  uid: string;
  name: string;
  state: string | null;
  logoUrl: string | null;
  urlName: string | null;
};

export type ClassInquiriesByUserQueryVariables = Exact<{
  userUid: Scalars["ID"];
}>;

export type ClassInquiriesByUserQuery = {
  __typename?: "Query";
  getClassInquiriesByUser: Array<{
    __typename?: "ClassInquiry";
    uid: string;
    status: string;
  } | null>;
};

export type ClassInquiriesForTeacherQueryVariables = Exact<{
  teacherUid: Scalars["ID"];
}>;

export type ClassInquiriesForTeacherQuery = {
  __typename?: "Query";
  getTeacherClassInquiries: {
    __typename?: "TeacherClassInquiries";
    teacherRepliedCountToday: number;
    classInquiriesWithLearnerAges: Array<{
      __typename?: "ClassInquiryWithLearnerAge";
      uid: string;
      status: string;
      inquiry_text: string;
      learner_age: number | null;
      created_at: any | null;
    } | null>;
  };
};

export type TeacherHasInquiriesButHasNotRepliedTodayQueryVariables = Exact<{
  teacherUid: Scalars["ID"];
}>;

export type TeacherHasInquiriesButHasNotRepliedTodayQuery = {
  __typename?: "Query";
  getTeacherClassInquiries: {
    __typename?: "TeacherClassInquiries";
    teacherHasInquiriesButHasNotRepliedToday: boolean;
  };
};

export type RequestClassInquiryMutationVariables = Exact<{
  userUid: Scalars["ID"];
  inquiryText: Scalars["String"];
  learnerUid?: InputMaybe<Scalars["ID"]>;
  searchFilters?: InputMaybe<SearchFilters>;
  skipRating?: InputMaybe<Scalars["Boolean"]>;
}>;

export type RequestClassInquiryMutation = {
  __typename?: "Mutation";
  requestClassInquiry: {
    __typename?: "RequestClassInquiryResponse";
    searchPath: string | null;
    classInquiries: Array<{
      __typename?: "ClassInquiry";
      uid: string;
      status: string;
    } | null>;
    topRecommendedActivityListing: {
      __typename?: "Listing";
      insertionId: string | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        user_uid: string;
        title: string;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        weekly_meetings: number | null;
        duration_weeks: number | null;
        is_ongoing_weekly: boolean;
        isClub: boolean;
        published_at: any | null;
        size_max: number | null;
        size_min: number | null;
        hasTeacherSchedule: boolean;
        allows_recurring_payment: boolean | null;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        price_cents: number | null;
        priceCreditsPerMeeting: number | null;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        canEnroll: boolean;
        isOneTime: boolean;
        titleTranslated: string | null;
        slug_id: string;
        subject: string | null;
        syllabus: {
          __typename?: "Syllabus";
          uid: string;
          lessons: Array<{ __typename?: "Lesson"; uid: string }>;
        } | null;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
        } | null;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      };
    } | null;
  } | null;
};

export type ReplyParentRequestMutationVariables = Exact<{
  teacherUid: Scalars["ID"];
  classInquiryUid: Scalars["ID"];
  relevantActivityUid: Scalars["ID"];
  replyText: Scalars["String"];
}>;

export type ReplyParentRequestMutation = {
  __typename?: "Mutation";
  replyClassInquiry: {
    __typename?: "ClassInquiryTeacher";
    uid: string;
    reply_message_uid: string;
  };
};

export type UpdateClassPostMutationVariables = Exact<{
  classPostUid: Scalars["ID"];
  messageContentInput?: InputMaybe<MessageContentInput>;
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateClassPostMutation = {
  __typename?: "Mutation";
  updateClassPost: {
    __typename?: "ClassPost";
    uid: string;
    isWelcomePost: boolean;
    publishAt: any;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  };
};

export type ClassRequestFragmentFragment = {
  __typename?: "ClassRequest";
  uid: string;
  age: number;
  content: string;
};

export type ClassScheduleRequestFragmentFragment = {
  __typename?: "ClassScheduleRequest";
  uid: string;
  timeZone: string;
  message: string | null;
  created_at: any;
  parentEnrolledAt: any | null;
  parent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  };
  timetable: {
    __typename?: "Timetable";
    mon: {
      __typename?: "TimetableTimes";
      f8_12: boolean;
      f12_4: boolean;
      f4_8: boolean;
    };
    tue: {
      __typename?: "TimetableTimes";
      f8_12: boolean;
      f12_4: boolean;
      f4_8: boolean;
    };
    wed: {
      __typename?: "TimetableTimes";
      f8_12: boolean;
      f12_4: boolean;
      f4_8: boolean;
    };
    thu: {
      __typename?: "TimetableTimes";
      f8_12: boolean;
      f12_4: boolean;
      f4_8: boolean;
    };
    fri: {
      __typename?: "TimetableTimes";
      f8_12: boolean;
      f12_4: boolean;
      f4_8: boolean;
    };
    sat: {
      __typename?: "TimetableTimes";
      f8_12: boolean;
      f12_4: boolean;
      f4_8: boolean;
    };
    sun: {
      __typename?: "TimetableTimes";
      f8_12: boolean;
      f12_4: boolean;
      f4_8: boolean;
    };
  };
};

export type CreateClassScheduleRequestMutationVariables = Exact<{
  activityUid: Scalars["ID"];
  timetable: TimetableInput;
  timeZone: Scalars["String"];
  message: Scalars["String"];
}>;

export type CreateClassScheduleRequestMutation = {
  __typename?: "Mutation";
  createClassScheduleRequest: {
    __typename?: "ClassScheduleRequest";
    uid: string;
    timeZone: string;
    message: string | null;
    created_at: any;
    parentEnrolledAt: any | null;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    timetable: {
      __typename?: "Timetable";
      mon: {
        __typename?: "TimetableTimes";
        f8_12: boolean;
        f12_4: boolean;
        f4_8: boolean;
      };
      tue: {
        __typename?: "TimetableTimes";
        f8_12: boolean;
        f12_4: boolean;
        f4_8: boolean;
      };
      wed: {
        __typename?: "TimetableTimes";
        f8_12: boolean;
        f12_4: boolean;
        f4_8: boolean;
      };
      thu: {
        __typename?: "TimetableTimes";
        f8_12: boolean;
        f12_4: boolean;
        f4_8: boolean;
      };
      fri: {
        __typename?: "TimetableTimes";
        f8_12: boolean;
        f12_4: boolean;
        f4_8: boolean;
      };
      sat: {
        __typename?: "TimetableTimes";
        f8_12: boolean;
        f12_4: boolean;
        f4_8: boolean;
      };
      sun: {
        __typename?: "TimetableTimes";
        f8_12: boolean;
        f12_4: boolean;
        f4_8: boolean;
      };
    };
  };
};

export type LastClassScheduleRequestQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LastClassScheduleRequestQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    lastClassScheduleRequest: {
      __typename?: "ClassScheduleRequest";
      uid: string;
      timeZone: string;
      message: string | null;
      created_at: any;
      parentEnrolledAt: any | null;
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      timetable: {
        __typename?: "Timetable";
        mon: {
          __typename?: "TimetableTimes";
          f8_12: boolean;
          f12_4: boolean;
          f4_8: boolean;
        };
        tue: {
          __typename?: "TimetableTimes";
          f8_12: boolean;
          f12_4: boolean;
          f4_8: boolean;
        };
        wed: {
          __typename?: "TimetableTimes";
          f8_12: boolean;
          f12_4: boolean;
          f4_8: boolean;
        };
        thu: {
          __typename?: "TimetableTimes";
          f8_12: boolean;
          f12_4: boolean;
          f4_8: boolean;
        };
        fri: {
          __typename?: "TimetableTimes";
          f8_12: boolean;
          f12_4: boolean;
          f4_8: boolean;
        };
        sat: {
          __typename?: "TimetableTimes";
          f8_12: boolean;
          f12_4: boolean;
          f4_8: boolean;
        };
        sun: {
          __typename?: "TimetableTimes";
          f8_12: boolean;
          f12_4: boolean;
          f4_8: boolean;
        };
      };
    } | null;
  } | null;
};

export type ClubEditPageQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type ClubEditPageQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    user_uid: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    learningGoals: Array<string>;
    assessment: string | null;
    homework: string | null;
    grading_policy: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    english_proficiency_level: EnglishProficiencyLevel | null;
    grade_level_min: string | null;
    grade_level_max: string | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    priceCredits: number | null;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    allowWaitlist: boolean | null;
    isClub: boolean;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    allows_recurring_payment: boolean | null;
    allows_late_enrollments_for_fixed_length_classes: boolean;
    has_building_content: boolean | null;
    hasTeacherSchedule: boolean;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isOneTime: boolean;
    classFormat: EducatorSelectedFormat;
    classroomType: ClassroomType;
    tags: string | null;
    archived_at: any | null;
    canRequestListing: boolean;
    isSelfPacedEnrollmentEnabled: boolean;
    saveCount: number | null;
    requested_listing_at: any | null;
    upsell_activity_uid: string | null;
    why_me: string | null;
    parental_guidance: string | null;
    sources: string | null;
    materials: string | null;
    hasSections: boolean;
    has_external_resources: boolean | null;
    attributes: Array<{
      __typename?: "EntityAttribute";
      key: string;
      value: string;
      entity_type: string;
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    videoForLearners: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    clubWelcomeVideo: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    clubBannerImage: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    clubGuidelines: {
      __typename?: "WelcomeMessage";
      text: string | null;
    } | null;
    sampleClubPost: {
      __typename?: "WelcomeMessage";
      text: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      }> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      publishedClassCount: number;
      listings: Array<{
        __typename?: "Listing";
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
        };
      }>;
      ownerOfSellerOrg: {
        __typename?: "SellerOrg";
        userIsOwner: boolean | null;
      } | null;
    };
    leaderDetails: {
      __typename?: "ActivityLeaderDetails";
      uid: string;
      requestedListingAt: any | null;
      classRequest: {
        __typename?: "ClassRequest";
        uid: string;
        age: number;
        content: string;
      } | null;
      welcomeMessage: {
        __typename?: "WelcomeMessage";
        text: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        }> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        } | null;
      } | null;
    };
    strictAgeRange: {
      __typename?: "AgeRangeType";
      min: number;
      max: number;
    } | null;
    clubSection: {
      __typename?: "Section";
      uid: string;
      published_at: any | null;
      deleted_at: any | null;
      canceled_at: any | null;
      start_time: any | null;
      end_time: any | null;
      size_max: number | null;
      currentUserCanManage: boolean;
      moderators: Array<{
        __typename?: "Leader";
        uid: string;
        name: string | null;
      }>;
    } | null;
    sampleClubPosts: Array<{
      __typename?: "SampleClubPost";
      text: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      }> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      } | null;
      comments: Array<{
        __typename?: "SampleClubPostComment";
        text: string | null;
      }>;
    }> | null;
    proficiency_level: {
      __typename?: "ProficiencyLevel";
      level: Array<string | null> | null;
      text: string | null;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      description: string;
      type: RefundPolicyType;
    };
    details: {
      __typename?: "ActivityDetails";
      photo: string | null;
      photoSquare: string | null;
      asyncTime: string | null;
    };
    approvalStatus: {
      __typename?: "ActivityApprovalStatus";
      action: string;
      message_to_teacher: string | null;
    } | null;
    externalResources: Array<{
      __typename?: "ExternalResource";
      uid: string;
      name: string;
      url: string | null;
    }>;
  };
};

export type GetContentfulEmailCaptureQueryVariables = Exact<{
  categorySlug: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetContentfulEmailCaptureQuery = {
  __typename?: "Query";
  contentfulClpEmailCaptureCollection: {
    __typename?: "ContentfulClpEmailCaptureCollection";
    items: Array<{
      __typename?: "ContentfulClpEmailCapture";
      name: string | null;
      title: string | null;
      callToActionText: string | null;
      successMessage: string | null;
      errorMessage: string | null;
      prodIterableAudienceId: string | null;
      stagingIterableAudienceId: string | null;
      bodyContent: {
        __typename?: "ContentfulClpEmailCaptureBodyContent";
        json: any;
      } | null;
      clpPagesCollection: {
        __typename?: "ContentfulClpEmailCaptureClpPagesCollection";
        items: Array<{
          __typename?: "ContentfulCategoryLabel";
          value: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type CouponFieldsFragmentFragment = {
  __typename?: "Coupon";
  name: string;
  isFirstPurchaseOnly: boolean;
  maxUsesPerUser: number;
  maxAmountCentsPerUse: number | null;
  startsAt: any | null;
  expiresAt: any | null;
  description: string | null;
  maxUses: number | null;
};

export type CreateCouponMutationVariables = Exact<{
  couponInput: CouponCreateInput;
  couponActivityConstraintsInput?: InputMaybe<CouponActivityConstraintsInput>;
}>;

export type CreateCouponMutation = {
  __typename?: "Mutation";
  createCoupon: { __typename?: "Coupon"; uid: string };
};

export type CreateCouponWithCodeMutationVariables = Exact<{
  couponInput: CouponCreateInput;
  couponCodeInput: CouponCodeCreateInput;
  couponActivityConstraintsInput?: InputMaybe<CouponActivityConstraintsInput>;
}>;

export type CreateCouponWithCodeMutation = {
  __typename?: "Mutation";
  createCouponWithCode: { __typename?: "Coupon"; uid: string };
};

export type UpdateCouponMutationVariables = Exact<{
  uid: Scalars["ID"];
  input: CouponUpdateInput;
}>;

export type UpdateCouponMutation = {
  __typename?: "Mutation";
  updateCoupon: {
    __typename?: "Coupon";
    uid: string;
    name: string;
    isFirstPurchaseOnly: boolean;
    maxUsesPerUser: number;
    maxAmountCentsPerUse: number | null;
    startsAt: any | null;
    expiresAt: any | null;
    description: string | null;
    maxUses: number | null;
  };
};

export type MultiSelectClassesListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type MultiSelectClassesListQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    activities: Array<{ __typename?: "Activity"; uid: string; title: string }>;
  } | null;
};

export type CouponCodeListQueryVariables = Exact<{
  filter: CouponCodeListFilter;
}>;

export type CouponCodeListQuery = {
  __typename?: "Query";
  couponCodeList: {
    __typename?: "CouponCodeList";
    totalCount: number;
    data: Array<{
      __typename?: "CouponCode";
      uid: string;
      code: string;
      createdBy: string | null;
    } | null>;
  };
};

export type TeacherCouponCodeListQueryVariables = Exact<{
  filter: CouponCodeListFilter;
  offset?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type TeacherCouponCodeListQuery = {
  __typename?: "Query";
  couponCodeList: {
    __typename?: "CouponCodeList";
    totalCount: number;
    data: Array<{
      __typename?: "CouponCode";
      uid: string;
      code: string;
      createdBy: string | null;
      couponUid: string | null;
      totalRedemption: number | null;
      coupon: {
        __typename?: "Coupon";
        uid: string;
        name: string;
        createdBy: string | null;
        description: string | null;
        maxUsesPerUser: number;
        maxAmountCentsPerUse: number | null;
        startsAt: any | null;
        expiresAt: any | null;
        maxUses: number | null;
        activities: Array<{
          __typename?: "Activity";
          uid: string;
          title: string;
          slug_id: string;
          isClub: boolean;
        } | null> | null;
      } | null;
    } | null>;
  };
};

export type CouponCodeRedemptionDetailsFragmentFragment = {
  __typename?: "CouponCodeRedemptionDetails";
  orderSlugId: string | null;
  enrollmentPayments: Array<{
    __typename?: "EnrollmentPayment";
    uid: string;
    order_slug_id: string | null;
    created_at: any | null;
  }>;
  enrollments: Array<{
    __typename?: "Enrollment";
    uid: string;
    confirmedAt: any | null;
    buyer: { __typename?: "Parent"; name: string | null } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
    } | null;
    section: {
      __typename?: "Section";
      uid: string;
      activity: {
        __typename?: "Activity";
        uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
      };
    } | null;
  }>;
};

export type CouponCodeRedemptionDetailsListQueryVariables = Exact<{
  offset?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  couponCodeUid: Scalars["String"];
}>;

export type CouponCodeRedemptionDetailsListQuery = {
  __typename?: "Query";
  couponCodeRedemptionDetailsList: {
    __typename?: "CouponCodeRedemptionDetailsList";
    totalCount: number | null;
    data: Array<{
      __typename?: "CouponCodeRedemptionDetails";
      orderSlugId: string | null;
      enrollmentPayments: Array<{
        __typename?: "EnrollmentPayment";
        uid: string;
        order_slug_id: string | null;
        created_at: any | null;
      }>;
      enrollments: Array<{
        __typename?: "Enrollment";
        uid: string;
        confirmedAt: any | null;
        buyer: { __typename?: "Parent"; name: string | null } | null;
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
        } | null;
        section: {
          __typename?: "Section";
          uid: string;
          activity: {
            __typename?: "Activity";
            uid: string;
            slug_id: string;
            title: string;
            titleTranslated: string | null;
          };
        } | null;
      }>;
    }>;
  } | null;
};

export type CouponListQueryVariables = Exact<{
  offset?: InputMaybe<Scalars["Int"]>;
  filter: CouponListFilter;
}>;

export type CouponListQuery = {
  __typename?: "Query";
  couponList: {
    __typename?: "CouponList";
    totalCount: number;
    data: Array<{
      __typename?: "Coupon";
      uid: string;
      name: string;
      createdBy: string | null;
      description: string | null;
      maxUsesPerUser: number;
      maxAmountCentsPerUse: number | null;
      percentOff: number | null;
      startsAt: any | null;
      expiresAt: any | null;
      activities: Array<{
        __typename?: "Activity";
        title: string;
      } | null> | null;
    } | null>;
  };
};

export type CouponQueryVariables = Exact<{
  uid: Scalars["String"];
}>;

export type CouponQuery = {
  __typename?: "Query";
  coupon: {
    __typename?: "Coupon";
    uid: string;
    name: string;
    isFirstPurchaseOnly: boolean;
    isBuyerFirstPurchaseOfClassFormatOnly: boolean | null;
    maxUsesPerUser: number;
    maxAmountCentsPerUse: number | null;
    percentOff: number | null;
    startsAt: any | null;
    expiresAt: any | null;
    deletedAt: any | null;
    description: string | null;
    maxUses: number | null;
    classFormatsAllowed: Array<CouponClassFormats | null> | null;
    subjectsAllowed: Array<string> | null;
    activities: Array<{
      __typename?: "Activity";
      uid: string;
      title: string;
      slug_id: string;
    } | null> | null;
  };
};

export type CouponCodeExistsQueryVariables = Exact<{
  code: Scalars["String"];
}>;

export type CouponCodeExistsQuery = {
  __typename?: "Query";
  couponCodeExists: boolean;
};

export type CreditAccountFragmentFragment = {
  __typename?: "CreditAccount";
  uid: string;
  openToBuy: number;
  balance: number;
  updatedAt: any | null;
  status: CreditAccountStatus | null;
};

export type CreditAccountQueryVariables = Exact<{ [key: string]: never }>;

export type CreditAccountQuery = {
  __typename?: "Query";
  creditBalance: {
    __typename?: "CreditAccount";
    uid: string;
    openToBuy: number;
    balance: number;
    updatedAt: any | null;
    status: CreditAccountStatus | null;
  } | null;
};

export type AcceptPaymentWithStoredCreditMutationVariables = Exact<{
  orderSlugId: Scalars["ID"];
  amountCents: Scalars["Int"];
  currencyCode?: InputMaybe<CurrencyCode>;
  exchangeRate?: InputMaybe<Scalars["Float"]>;
}>;

export type AcceptPaymentWithStoredCreditMutation = {
  __typename?: "Mutation";
  acceptPaymentWithStoredCredit: string;
};

export type AcceptPaymentWithSubscriptionCreditMutationVariables = Exact<{
  orderSlugId: Scalars["ID"];
  amountCredits: Scalars["Int"];
  currencyCode?: InputMaybe<CurrencyCode>;
  exchangeRate?: InputMaybe<Scalars["Float"]>;
}>;

export type AcceptPaymentWithSubscriptionCreditMutation = {
  __typename?: "Mutation";
  acceptPaymentWithSubscriptionCredit: boolean;
};

export type ListAllCuratedCategoryPagesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListAllCuratedCategoryPagesQuery = {
  __typename?: "Query";
  curatedPages: Array<{
    __typename?: "CategoryPage";
    slug: string;
  } | null> | null;
};

export type ContentfulCategoryPageQueryVariables = Exact<{
  categorySlug: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ContentfulCategoryPageQuery = {
  __typename?: "Query";
  contentfulCategoryPageCollection: {
    __typename?: "ContentfulCategoryPageCollection";
    items: Array<{
      __typename?: "ContentfulCategoryPage";
      featuredClassesOverrideSlug: string | null;
      heroTitle: string | null;
      jumpPillsTitle: string | null;
      belowTheFoldTitle: string | null;
      startAfter: string | null;
      footerCtaTitle: string | null;
      footerCtaPath: string | null;
      heroDescription: {
        __typename?: "ContentfulCategoryPageHeroDescription";
        json: any;
      } | null;
      heroImage: { __typename?: "ContentfulAsset"; url: string | null } | null;
      heroValueProps: {
        __typename?: "ContentfulCategoryPageHeroValueProps";
        json: any;
      } | null;
      jumpPillLinksCollection: {
        __typename?: "ContentfulCategoryPageJumpPillLinksCollection";
        items: Array<{
          __typename?: "ContentfulVisualLink";
          path: string | null;
          text: string | null;
        } | null>;
      } | null;
      belowTheFoldDescription: {
        __typename?: "ContentfulCategoryPageBelowTheFoldDescription";
        json: any;
      } | null;
      magnetPagesCollection: {
        __typename?: "ContentfulCategoryPageMagnetPagesCollection";
        items: Array<{
          __typename?: "ContentfulMagnetPage";
          magnetPageSlug: string | null;
          title: string | null;
          categoryLabel: {
            __typename?: "ContentfulCategoryLabel";
            value: string | null;
          } | null;
          author: {
            __typename?: "ContentfulAuthor";
            name: string | null;
            profileImage: {
              __typename?: "ContentfulAsset";
              url: string | null;
            } | null;
          } | null;
          headerImage: {
            __typename?: "ContentfulAsset";
            url: string | null;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type ContentfulCategoryPageRelatedCarouselsQueryVariables = Exact<{
  categorySlug: Scalars["String"];
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ContentfulCategoryPageRelatedCarouselsQuery = {
  __typename?: "Query";
  contentfulCategoryPageCollection: {
    __typename?: "ContentfulCategoryPageCollection";
    items: Array<{
      __typename?: "ContentfulCategoryPage";
      relatedCategoriesCollection: {
        __typename?: "ContentfulCategoryPageRelatedCategoriesCollection";
        items: Array<{
          __typename?: "ContentfulUpsellClassCard";
          callToActionText: string | null;
          callToActionUrl: string | null;
          subtitle: string | null;
          title: string | null;
          upsellName: string | null;
          adminTags: Array<string | null> | null;
          ages: Array<string | null> | null;
          capacityMax: number | null;
          capacityMin: number | null;
          category: Array<string | null> | null;
          deliveryTypes: Array<string | null> | null;
          formats: Array<string | null> | null;
          fundingPrograms: Array<string | null> | null;
          gradeLevel: Array<string | null> | null;
          maxPriceCents: number | null;
          minPriceCents: number | null;
          multiTermQuery: string | null;
          query: string | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type FeedbackForCategorySearchFiltersQueryVariables = Exact<{
  searchFilters: SearchFilters;
  pageLocation?: InputMaybe<Scalars["String"]>;
}>;

export type FeedbackForCategorySearchFiltersQuery = {
  __typename?: "Query";
  feedbackForCategorySearchFilters: {
    __typename?: "FeedbackForCategoryPageResult";
    hasPhotos: Array<{
      __typename?: "PublicReview";
      uid: string;
      section_uid: string;
      currentUserCanEdit: boolean;
      currentUserCanRespond: boolean;
      activity_star_rating: number | null;
      currentUserMarkedHelpful: boolean | null;
      markedHelpfulCount: number;
      created_at: any;
      teacher_response: string | null;
      teacher_responded_at: any | null;
      activity: {
        __typename?: "Activity";
        title: string;
        titleTranslated: string | null;
        path: string;
        isClub: boolean;
        slug_id: string;
        uid: string;
        user_uid: string;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          leader_link: string | null;
          photo: string | null;
        };
      } | null;
      parent: {
        __typename?: "Parent";
        publicName: string | null;
        photo: string | null;
        uid: string;
        name: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      details: {
        __typename?: "PublicReviewDetails";
        publicReview: string | null;
      } | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
    } | null> | null;
    noPhotos: Array<{
      __typename?: "PublicReview";
      uid: string;
      section_uid: string;
      currentUserCanEdit: boolean;
      currentUserCanRespond: boolean;
      activity_star_rating: number | null;
      currentUserMarkedHelpful: boolean | null;
      markedHelpfulCount: number;
      created_at: any;
      teacher_response: string | null;
      teacher_responded_at: any | null;
      activity: {
        __typename?: "Activity";
        title: string;
        titleTranslated: string | null;
        path: string;
        isClub: boolean;
        slug_id: string;
        uid: string;
        user_uid: string;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          leader_link: string | null;
          photo: string | null;
        };
      } | null;
      parent: {
        __typename?: "Parent";
        publicName: string | null;
        photo: string | null;
        uid: string;
        name: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      details: {
        __typename?: "PublicReviewDetails";
        publicReview: string | null;
      } | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
    } | null> | null;
  } | null;
};

export type UpdateCurrentUserMutationVariables = Exact<{
  name?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  subscribe?: InputMaybe<Scalars["Boolean"]>;
  photo?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  isLeader?: InputMaybe<Scalars["Boolean"]>;
  payout_email?: InputMaybe<Scalars["String"]>;
  video?: InputMaybe<Scalars["ID"]>;
  studentAges?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  ageEmailSubscription?: InputMaybe<Scalars["Boolean"]>;
  details?: InputMaybe<UserDetailsInput>;
  locale?: InputMaybe<UserLocale>;
  location?: InputMaybe<LocationInput>;
  isRecordingAutopostEnabled?: InputMaybe<Scalars["Boolean"]>;
  consented_to_coppa_notice_at?: InputMaybe<Scalars["DateTime"]>;
  default_currency?: InputMaybe<Scalars["String"]>;
  learner_mode_switch_auth_requirement?: InputMaybe<LearnerModeSwitchAuthRequirement>;
  do_not_sell_my_personal_info?: InputMaybe<Scalars["Boolean"]>;
  teacherAttributes?: InputMaybe<TeacherAttributesInput>;
  consent_to_share_class_history?: InputMaybe<Scalars["Boolean"]>;
  consent_to_share_favourites?: InputMaybe<Scalars["Boolean"]>;
  privacy_state?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateCurrentUserMutation = {
  __typename?: "Mutation";
  updateCurrentUser: {
    __typename?: "User";
    uid: string;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    latestLegalName: string | null;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    teacher_approved_at: any | null;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    roles: Array<string> | null;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    consented_to_coppa_notice_at: any | null;
    hasVerifiedIdentity: boolean | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    upgradablePackages: Array<number | null> | null;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    details: {
      __typename?: "UserDetails";
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    location: {
      __typename?: "Location";
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      timeZone: string | null;
      state: string | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
    } | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
    } | null;
  };
};

export type CreateAccountMutationVariables = Exact<{
  name?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  password: Scalars["String"];
  attribution?: InputMaybe<Attribution>;
  browserTimeZone?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<UserLocale>;
  isLeader?: InputMaybe<Scalars["Boolean"]>;
  subscribe?: InputMaybe<Scalars["Boolean"]>;
  isGiftCardSignup?: InputMaybe<Scalars["Boolean"]>;
  shouldNotSellPersonalInfo?: InputMaybe<Scalars["Boolean"]>;
  sellerOrgPrimaryContactName?: InputMaybe<Scalars["String"]>;
  osRef?: InputMaybe<OsRefInput>;
  isOnboardingSignup?: InputMaybe<Scalars["Boolean"]>;
  shouldCreateDefaultLearner?: InputMaybe<Scalars["Boolean"]>;
  defaultLearnerAge?: InputMaybe<Scalars["Int"]>;
}>;

export type CreateAccountMutation = {
  __typename?: "Mutation";
  createAccount: {
    __typename?: "AuthenticationResult";
    uid: string;
    sessionToken: string;
    refreshToken: string;
    currentUser: {
      __typename?: "User";
      uid: string;
      slug_id: string;
      name: string | null;
      email: string | null;
      isLearnerModeEnabled: boolean | null;
      confirmed_at: any | null;
      subscribed_at: any | null;
      email_bounced_at: any | null;
      default_currency: string | null;
      phone: string | null;
      photo: string | null;
      leader_subscribed_at: any | null;
      calendar_uid: string;
      intercomUserHash: string | null;
      payout_email: string | null;
      owner_of_seller_org_uid: string | null;
      admin_for_organization_uid: string | null;
      do_not_sell_my_personal_info: boolean;
      background_checked_at: any | null;
      background_check_started_at: any | null;
      interview_scheduled_at: any | null;
      interviewed_at: any | null;
      classroom_training_scheduled_at: any | null;
      classroom_trained_at: any | null;
      latestLegalName: string | null;
      hasCompletedClassContentPoliciesTraining: boolean;
      hasCompletedSafetyAndPrivacyTraining: boolean;
      hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
      hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
      teacher_approved_at: any | null;
      is_crosslister: boolean | null;
      introduction: string | null;
      created_at: any;
      updated_at: any;
      deleted_at: any | null;
      suspended_at: any | null;
      is_admin: boolean | null;
      learner_uses_parent_mode: boolean;
      leader_link: string | null;
      unreadMessageThreadsCount: number;
      unreadPrivateClassMessageThreadsCount: number;
      roles: Array<string> | null;
      locale: UserLocale;
      isRecordingAutopostEnabled: boolean | null;
      learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
      anonymousId: string | null;
      wasReferred: boolean;
      isEducatorBetaTester: boolean | null;
      consented_to_coppa_notice_at: any | null;
      hasVerifiedIdentity: boolean | null;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
      privacy_state: string | null;
      profile_link_uid: string | null;
      hasSubscription: boolean;
      canPayWithSubscriptionCredits: boolean;
      upgradablePackages: Array<number | null> | null;
      otherEmailDetails: Array<{
        __typename?: "OtherEmailDetails";
        uid: string;
        email: string;
        confirmed_at: any | null;
      } | null> | null;
      firstPaidEnrollment: {
        __typename?: "Enrollment";
        uid: string;
        confirmed_at: any | null;
      } | null;
      details: {
        __typename?: "UserDetails";
        about: string | null;
        headline: string | null;
        countryOfResidence: string | null;
        introduction: string | null;
        browserTimeZone: string | null;
        timeRangeAfterSchool: boolean | null;
        timeRangeSchool: boolean | null;
        timeRangeWeekend: boolean | null;
        socialOptOut: boolean | null;
        howFoundOutschool: string | null;
        howFoundOutschoolOther: string | null;
        educationApproach: string | null;
        adminDashboardUrl: string | null;
        experiments: Array<string | null> | null;
      };
      location: {
        __typename?: "Location";
        address: string | null;
        city: string | null;
        lat: number | null;
        lng: number | null;
        timeZone: string | null;
        state: string | null;
      } | null;
      organizationAdmin: {
        __typename?: "OrganizationAdmin";
        organization: {
          __typename?: "Organization";
          uid: string;
          name: string;
          type: OrganizationType;
        };
      } | null;
      children: Array<{
        __typename?: "Child";
        uid: string;
        name: string | null;
        age: number | null;
        birthMonth: number | null;
        date_of_birth: any | null;
        birthYear: number | null;
        email: string | null;
        avatar: string | null;
        unlockedAvatars: Array<string>;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        email_confirmed_at: any | null;
        optedOutOfPersonalizedRecommendationsAt: any | null;
        isLoginEnabled: boolean;
        username: string | null;
        hasPassword: boolean;
        attributes: Array<{
          __typename?: "EntityAttribute";
          uid: string;
          key: string;
          value: string;
        }>;
        entityAvailabilitySchedule: {
          __typename?: "EntityAvailabilitySchedule";
          entityAvailabilities: Array<{
            __typename?: "EntityAvailability";
            startHour: number;
            startMinute: number;
            day: number;
            endHour: number;
            endMinute: number;
            uid: string;
          }>;
        } | null;
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      interfacePreferences: {
        __typename?: "InterfacePreferences";
        activityFormatDescriptionFlexHidden: boolean;
        activityFormatDescriptionOngoingHidden: boolean;
        activityFormatDescriptionOneTimeHidden: boolean;
        activityFormatDescriptionMultiDayHidden: boolean;
        defaultScheduleFilters: {
          __typename?: "UserScheduleFilters";
          startAfter: string | null;
          endBy: string | null;
          startAfterTime: number | null;
          endByTime: number | null;
          dow: string | null;
        };
      };
      schoolClosure: {
        __typename?: "SchoolClosure";
        application_status: FfapApplicationStatus | null;
        needs_assistance: boolean | null;
      } | null;
      pricingOffer: {
        __typename?: "PricingOffer";
        uid: string;
        name: string;
        userLimitCents: number;
        purchaseLimitCents: number | null;
        totalCapCents: number;
        startAfter: any;
        endBy: any;
        buyerOrgName: string | null;
        isTotalCapReached: boolean;
        remainingCapCents: number | null;
        requirePaymentMethod: boolean;
        isOfferLimitReached: boolean;
        userOfferEmail: string | null;
        discount: number | null;
        isFinancialAid: boolean;
      } | null;
      sellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
        leader_link: string | null;
        currentUserIsOwner: boolean | null;
        currentUserIsTeacher: boolean | null;
        hasApprovedTeacherAccountForOwner: boolean | null;
      } | null;
      latestSellerOrgApplication: {
        __typename?: "SellerOrgApplication";
        uid: string;
        status: SellerOrgApplicationStatus;
        approvedAt: any | null;
        rejectedAt: any | null;
        contractSignedAt: any | null;
        adminResponse: string | null;
      } | null;
      latestTeacherApplication: {
        __typename?: "TeacherApplication";
        uid: string;
        legal_name: string | null;
        status: TeacherApplicationStatus;
        submitted_at: any | null;
        reviewed_at: any | null;
        teaching_experience: string | null;
        teaching_credentials: string | null;
        class_types: string | null;
        online_profiles: string | null;
        sample_classes: string | null;
        class_subjects: Array<string | null> | null;
        age_ranges_taught: Array<string | null> | null;
        years_of_experience: string | null;
        how_did_you_hear_about_us: string | null;
        experience_types: Array<string | null> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        showCalendar: boolean | null;
        mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
      } | null;
    };
  } | null;
};

export type AddDonationItemToOrderMutationVariables = Exact<{
  donationInput: DonationInput;
}>;

export type AddDonationItemToOrderMutation = {
  __typename?: "Mutation";
  addDonationItemToOrder: {
    __typename?: "Donation";
    orderSlugId: string;
    amountCents: number;
  };
};

export type EmailPreferenceFragmentFragment = {
  __typename?: "EmailPreference";
  id: string;
  name: string;
  label: string;
  description: string | null;
  isEnabled: boolean;
};

export type EmailPreferenceGroupFragmentFragment = {
  __typename?: "EmailPreference";
  id: string;
  name: string;
  label: string;
  description: string | null;
  isEnabled: boolean;
  childPreferences: Array<{
    __typename?: "EmailPreference";
    id: string;
    name: string;
    label: string;
    description: string | null;
    isEnabled: boolean;
    childPreferences: Array<{
      __typename?: "EmailPreference";
      id: string;
      name: string;
      label: string;
      description: string | null;
      isEnabled: boolean;
    }>;
  }>;
};

export type SubscribeToEmailMutationVariables = Exact<{
  name: Scalars["String"];
  token?: InputMaybe<Scalars["String"]>;
  attribution?: InputMaybe<Attribution>;
}>;

export type SubscribeToEmailMutation = {
  __typename?: "Mutation";
  subscribeToEmail: {
    __typename?: "EmailPreference";
    id: string;
    name: string;
    label: string;
    description: string | null;
    isEnabled: boolean;
  };
};

export type UnsubscribeFromEmailMutationVariables = Exact<{
  name: Scalars["String"];
  token?: InputMaybe<Scalars["String"]>;
  attribution?: InputMaybe<Attribution>;
}>;

export type UnsubscribeFromEmailMutation = {
  __typename?: "Mutation";
  unsubscribeFromEmail: {
    __typename?: "EmailPreference";
    id: string;
    name: string;
    label: string;
    description: string | null;
    isEnabled: boolean;
  };
};

export type UnsubscribeFromAllEmailsMutationVariables = Exact<{
  token?: InputMaybe<Scalars["String"]>;
  attribution?: InputMaybe<Attribution>;
}>;

export type UnsubscribeFromAllEmailsMutation = {
  __typename?: "Mutation";
  unsubscribeFromAllEmails: Array<{
    __typename?: "EmailPreference";
    id: string;
    name: string;
    label: string;
    description: string | null;
    isEnabled: boolean;
  }>;
};

export type EmailPreferencesQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EmailPreferencesQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    emailPreferences: Array<{
      __typename?: "EmailPreference";
      id: string;
      name: string;
      label: string;
      description: string | null;
      isEnabled: boolean;
      childPreferences: Array<{
        __typename?: "EmailPreference";
        id: string;
        name: string;
        label: string;
        description: string | null;
        isEnabled: boolean;
        childPreferences: Array<{
          __typename?: "EmailPreference";
          id: string;
          name: string;
          label: string;
          description: string | null;
          isEnabled: boolean;
        }>;
      }>;
    }>;
  } | null;
};

export type EmailPreferencesByTokenQueryQueryVariables = Exact<{
  token: Scalars["String"];
}>;

export type EmailPreferencesByTokenQueryQuery = {
  __typename?: "Query";
  emailPreferences: Array<{
    __typename?: "EmailPreference";
    id: string;
    name: string;
    label: string;
    description: string | null;
    isEnabled: boolean;
    childPreferences: Array<{
      __typename?: "EmailPreference";
      id: string;
      name: string;
      label: string;
      description: string | null;
      isEnabled: boolean;
      childPreferences: Array<{
        __typename?: "EmailPreference";
        id: string;
        name: string;
        label: string;
        description: string | null;
        isEnabled: boolean;
      }>;
    }>;
  }>;
};

export type EnrollmentFragmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  section_uid: string | null;
  price_cents: number | null;
  priceCents: number | null;
  created_at: any | null;
  confirmed_at: any | null;
  confirmedAt: any | null;
  withdrawn_at: any | null;
  ongoing_stopped_at: any | null;
  currency_code: CurrencyCode;
  exchange_rate: number;
  amount_in_local_currency: number;
  ongoing_start_time: any | null;
  ongoing_end_time: any | null;
  withdrawnAt: any | null;
  ongoingStoppedAt: any | null;
  ongoingStartTime: any | null;
  ongoingEndTime: any | null;
  isPaused: boolean;
  isEnded: boolean;
  isPrepay: boolean;
  refundableAmountCents: number;
  canBeWithdrawn: boolean;
  canBeStopped: boolean;
  canBePaused: boolean;
  usersCanCommunicate: boolean;
  purchasedAsGift: boolean;
  pricingOffer: {
    __typename?: "PricingOffer";
    name: string;
    buyerOrgName: string | null;
    isFinancialAid: boolean;
  } | null;
  refundPolicy: {
    __typename?: "RefundPolicy";
    type: RefundPolicyType;
    name: string;
    description: string;
  } | null;
  details: {
    __typename?: "EnrollmentDetails";
    name: string | null;
    age: string | null;
    attendedAt: any | null;
    message: string | null;
  };
  parent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  };
  buyer: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  } | null;
  learner: {
    __typename?: "Learner";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    isVerified: boolean;
    isBlocked: boolean | null;
    blockedByUserUid: string | null;
    blockedAt: any | null;
  } | null;
  siblings: Array<{
    __typename?: "Sibling";
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    };
    currentOrLastSection: {
      __typename?: "Section";
      filledSpaceCount: number;
      uid: string;
      activity_uid: string;
      start_time: any | null;
      end_time: any | null;
      startsAtNight: boolean | null;
      is_cross_listed: boolean;
      size_max: number | null;
      size_min: number | null;
      isPublished: boolean | null;
      price_cents: number | null;
      priceCredits: number | null;
      published_at: any | null;
      updated_at: any;
      canceled_at: any | null;
      deleted_at: any | null;
      ongoing_stopped_at: any | null;
      duration_minutes: number | null;
      usesOutschoolVideoChat: boolean;
      usersCanSendPrivateMessage: boolean;
      currentUserCanManage: boolean;
      isBuyable: boolean;
      allowsTeacherTransfer: boolean;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        deleted_at: any | null;
        created_at: any | null;
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      details: {
        __typename?: "SectionDetails";
        autoScheduledDraft: boolean | null;
      };
      prepaidOngoingPeriodOptions: Array<{
        __typename?: "Period";
        startOfIsoWeek: any;
        endOfIsoWeek: any;
      }> | null;
    };
  }>;
  paymentSchedule: {
    __typename?: "PaymentSchedule";
    uid: string;
    stoppedAt: any | null;
    endTime: any | null;
    scheduleType: PaymentScheduleTypes;
    amountCents: number | null;
  } | null;
  pauseWindow: {
    __typename?: "EnrollmentPauseWindow";
    uid: string;
    startDate: any;
    endDate: any;
    canBeCanceled: boolean;
    pauseReason: string;
  } | null;
};

export type EnrollmentLearnerFragmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  section_uid: string | null;
  price_cents: number | null;
  priceCents: number | null;
  created_at: any | null;
  confirmed_at: any | null;
  confirmedAt: any | null;
  withdrawn_at: any | null;
  ongoing_stopped_at: any | null;
  currency_code: CurrencyCode;
  exchange_rate: number;
  amount_in_local_currency: number;
  ongoing_start_time: any | null;
  ongoing_end_time: any | null;
  withdrawnAt: any | null;
  ongoingStoppedAt: any | null;
  ongoingStartTime: any | null;
  ongoingEndTime: any | null;
  isPaused: boolean;
  isEnded: boolean;
  isPrepay: boolean;
  refundableAmountCents: number;
  canBeWithdrawn: boolean;
  canBeStopped: boolean;
  canBePaused: boolean;
  usersCanCommunicate: boolean;
  purchasedAsGift: boolean;
  pricingOffer: {
    __typename?: "PricingOffer";
    name: string;
    buyerOrgName: string | null;
    isFinancialAid: boolean;
  } | null;
  refundPolicy: {
    __typename?: "RefundPolicy";
    type: RefundPolicyType;
    name: string;
    description: string;
  } | null;
  details: {
    __typename?: "EnrollmentDetails";
    name: string | null;
    age: string | null;
    attendedAt: any | null;
    message: string | null;
  };
  parent: {
    __typename?: "Parent";
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  };
  buyer: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  } | null;
  learner: {
    __typename?: "Learner";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    isVerified: boolean;
    isBlocked: boolean | null;
    blockedByUserUid: string | null;
    blockedAt: any | null;
  } | null;
  siblings: Array<{
    __typename?: "Sibling";
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    };
    currentOrLastSection: {
      __typename?: "Section";
      filledSpaceCount: number;
      uid: string;
      activity_uid: string;
      start_time: any | null;
      end_time: any | null;
      startsAtNight: boolean | null;
      is_cross_listed: boolean;
      size_max: number | null;
      size_min: number | null;
      isPublished: boolean | null;
      price_cents: number | null;
      priceCredits: number | null;
      published_at: any | null;
      updated_at: any;
      canceled_at: any | null;
      deleted_at: any | null;
      ongoing_stopped_at: any | null;
      duration_minutes: number | null;
      usesOutschoolVideoChat: boolean;
      usersCanSendPrivateMessage: boolean;
      currentUserCanManage: boolean;
      isBuyable: boolean;
      allowsTeacherTransfer: boolean;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        deleted_at: any | null;
        created_at: any | null;
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      details: {
        __typename?: "SectionDetails";
        autoScheduledDraft: boolean | null;
      };
      prepaidOngoingPeriodOptions: Array<{
        __typename?: "Period";
        startOfIsoWeek: any;
        endOfIsoWeek: any;
      }> | null;
    };
  }>;
  paymentSchedule: {
    __typename?: "PaymentSchedule";
    uid: string;
    stoppedAt: any | null;
    endTime: any | null;
    scheduleType: PaymentScheduleTypes;
    amountCents: number | null;
  } | null;
  pauseWindow: {
    __typename?: "EnrollmentPauseWindow";
    uid: string;
    startDate: any;
    endDate: any;
    canBeCanceled: boolean;
    pauseReason: string;
  } | null;
};

export type EnrollmentHistoryQueryVariables = Exact<{
  uid: Scalars["ID"];
  offset?: InputMaybe<Scalars["Int"]>;
  skipRating: Scalars["Boolean"];
  profileLinkUid?: InputMaybe<Scalars["String"]>;
  publicView?: InputMaybe<Scalars["Boolean"]>;
}>;

export type EnrollmentHistoryQuery = {
  __typename?: "Query";
  pastEnrollmentsForParentProfile: Array<{
    __typename?: "Listing";
    insertionId: string | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      user_uid: string;
      title: string;
      url: string;
      path: string;
      summary: string | null;
      summaryTranslated: string | null;
      weekly_meetings: number | null;
      duration_weeks: number | null;
      is_ongoing_weekly: boolean;
      isClub: boolean;
      published_at: any | null;
      size_max: number | null;
      size_min: number | null;
      hasTeacherSchedule: boolean;
      allows_recurring_payment: boolean | null;
      averageStarRating?: number | null;
      reviewCount: number;
      age_min: number | null;
      age_max: number | null;
      duration_minutes: number | null;
      price_cents: number | null;
      priceCreditsPerMeeting: number | null;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      canEnroll: boolean;
      isOneTime: boolean;
      titleTranslated: string | null;
      slug_id: string;
      subject: string | null;
      syllabus: {
        __typename?: "Syllabus";
        uid: string;
        lessons: Array<{ __typename?: "Lesson"; uid: string }>;
      } | null;
      clubSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        clubPostCount: number;
      } | null;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      savedClassLists: Array<{
        __typename?: "SavedClassList";
        uid: string;
        lastAddedToAt: any | null;
      }>;
    };
    nextSection: {
      __typename?: "Section";
      uid: string;
      size_max: number | null;
      filledSpaceCount: number;
      start_time: any | null;
      end_time: any | null;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      averageActivityStarRating: number | null;
      reviewCount: number;
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    };
  } | null>;
};

export type EnrollmentHistoryV1QueryVariables = Exact<{
  uid: Scalars["ID"];
  offset?: InputMaybe<Scalars["Int"]>;
  skipRating: Scalars["Boolean"];
  profileLinkUid?: InputMaybe<Scalars["String"]>;
}>;

export type EnrollmentHistoryV1Query = {
  __typename?: "Query";
  pastEnrollments: Array<{
    __typename?: "Listing";
    insertionId: string | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      user_uid: string;
      title: string;
      url: string;
      path: string;
      summary: string | null;
      summaryTranslated: string | null;
      weekly_meetings: number | null;
      duration_weeks: number | null;
      is_ongoing_weekly: boolean;
      isClub: boolean;
      published_at: any | null;
      size_max: number | null;
      size_min: number | null;
      hasTeacherSchedule: boolean;
      allows_recurring_payment: boolean | null;
      averageStarRating?: number | null;
      reviewCount: number;
      age_min: number | null;
      age_max: number | null;
      duration_minutes: number | null;
      price_cents: number | null;
      priceCreditsPerMeeting: number | null;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      canEnroll: boolean;
      isOneTime: boolean;
      titleTranslated: string | null;
      slug_id: string;
      subject: string | null;
      syllabus: {
        __typename?: "Syllabus";
        uid: string;
        lessons: Array<{ __typename?: "Lesson"; uid: string }>;
      } | null;
      clubSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        clubPostCount: number;
      } | null;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      savedClassLists: Array<{
        __typename?: "SavedClassList";
        uid: string;
        lastAddedToAt: any | null;
      }>;
    };
    nextSection: {
      __typename?: "Section";
      uid: string;
      size_max: number | null;
      filledSpaceCount: number;
      start_time: any | null;
      end_time: any | null;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      averageActivityStarRating: number | null;
      reviewCount: number;
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    };
  } | null>;
};

export type EnrollmentListByStatusQueryVariables = Exact<{
  uid: Scalars["ID"];
  statusList?: InputMaybe<Array<EnrollmentStatus> | EnrollmentStatus>;
}>;

export type EnrollmentListByStatusQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    size_max: number | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      allowWaitlist: boolean | null;
      is_ongoing_weekly: boolean;
      hasTeacherSchedule: boolean;
    };
    waitlistSeats: Array<{
      __typename?: "WaitlistSeat";
      uid: string;
      previouslyEnrolled: boolean | null;
      formerLearner: boolean | null;
      parent: { __typename?: "Parent"; uid: string; name: string | null };
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        isVerified: boolean;
        newOnOutschool: boolean | null;
      };
    }> | null;
    enrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      isFirstOutschoolClass: boolean;
      isRepeatLearnerOfTeacher: boolean;
      createdAt: any;
      isEnrolled: boolean;
      isEnded: boolean;
      isWithdrawn: boolean;
      isPaused: boolean;
      parent: { __typename?: "Parent"; uid: string; name: string | null };
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        newOnOutschool: boolean | null;
      } | null;
      otherSections: Array<{ __typename?: "Section"; uid: string }>;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        pauseReason: string;
      } | null;
    }>;
    unclaimedGiftEnrollments: Array<{
      __typename?: "GiftEnrollment";
      uid: string;
      createdAt: any;
      isEnrolled: boolean;
      isEnded: boolean;
      isWithdrawn: boolean;
      isPaused: boolean;
      buyer: { __typename?: "Parent"; uid: string; name: string | null };
    }>;
  } | null;
};

export type CreateEnrollmentFutureMeetingsMutationVariables = Exact<{
  activityUid: Scalars["String"];
  sectionUid: Scalars["String"];
  startTime: Scalars["DateTime"];
  endTime?: InputMaybe<Scalars["DateTime"]>;
  durationWeeks?: InputMaybe<Scalars["Int"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]>;
  meetingRequestUids?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
  recurringScheduleUid?: InputMaybe<Scalars["ID"]>;
}>;

export type CreateEnrollmentFutureMeetingsMutation = {
  __typename?: "Mutation";
  createEnrollmentFutureMeetings: Array<{
    __typename?: "Meeting";
    uid: string;
  } | null> | null;
};

export type EnrollmentMeetingPurchaseFragmentFragment = {
  __typename?: "EnrollmentMeetingPurchase";
  uid: string;
  activityUid: string | null;
  enrollmentUid: string | null;
  meetingUid: string | null;
  orderSlugId: string;
  confirmedAt: any | null;
};

export type EnrollmentMeetingPurchaseQueryQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  startTime: Scalars["DateTime"];
  enrollmentUid?: InputMaybe<Scalars["ID"]>;
  meetingRequestUids?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
  orgTeacherUid?: InputMaybe<Scalars["ID"]>;
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type EnrollmentMeetingPurchaseQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    consented_to_coppa_notice_at: any | null;
    uid: string;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    latestLegalName: string | null;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    teacher_approved_at: any | null;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    roles: Array<string> | null;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    hasVerifiedIdentity: boolean | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    upgradablePackages: Array<number | null> | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    lastSubmittedEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      learner: {
        __typename?: "Learner";
        uid: string;
        age: number | null;
        name: string | null;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
    }>;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    details: {
      __typename?: "UserDetails";
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    location: {
      __typename?: "Location";
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      timeZone: string | null;
      state: string | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
    } | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
    } | null;
  } | null;
  enrollmentMeetingPurchaseForm: {
    __typename?: "EnrollmentMeetingPurchaseForm";
    canPayLater: boolean;
    activity: {
      __typename?: "Activity";
      uid: string;
      suggestedWeeklyMeetings: number | null;
      reviewCount: number;
      esaStatus: EsaStatus;
      slug_id: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      class_experience: string | null;
      learning_goals: string | null;
      learningGoals: Array<string>;
      assessment: string | null;
      homework: string | null;
      grading_policy: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      grade_level_min: string | null;
      grade_level_max: string | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      priceCredits: number | null;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      allowWaitlist: boolean | null;
      isClub: boolean;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      allows_recurring_payment: boolean | null;
      allows_late_enrollments_for_fixed_length_classes: boolean;
      has_building_content: boolean | null;
      hasTeacherSchedule: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      classFormat: EducatorSelectedFormat;
      classroomType: ClassroomType;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
        created_at: any | null;
        photo: string | null;
        suspended_at: any | null;
        cityState: string | null;
        socialOptOut: boolean;
        is_crosslister: boolean | null;
        timeZone: string | null;
        hasAvailabilitySet: boolean;
        approved: boolean;
        hasBackgroundCheckExpired: boolean | null;
        reviewCount: number;
        averageActivityStarRating: number | null;
        hasListings: boolean;
        details: {
          __typename?: "LeaderDetails";
          about: string | null;
          headline: string | null;
          headlineTranslated: string | null;
          countryOfResidence: string | null;
        };
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        teacherForSellerOrg: {
          __typename?: "SellerOrg";
          uid: string;
          name: string;
        } | null;
        video: {
          __typename?: "Attachment";
          file: { __typename?: "File"; url: string; thumbUrl: string | null };
        } | null;
      };
      strictAgeRange: {
        __typename?: "AgeRangeType";
        min: number;
        max: number;
      } | null;
      pricingInfo: {
        __typename?: "PricingInfo";
        totalPriceCentsForUpfrontPayment: number;
        totalPriceCentsForWeeklyPayments: number;
        weeklyPriceCentsForUpfrontPayment: number;
        weeklyPriceCentsForWeeklyPayments: number;
        upfrontDiscountPercentage: number;
        totalPriceCreditsForUpfrontPayment: number;
        weeklyPriceCreditsForUpfrontPayment: number;
        totalPriceCreditsForWeeklyPayments: number;
        weeklyPriceCreditsForWeeklyPayments: number;
      } | null;
      proficiency_level: {
        __typename?: "ProficiencyLevel";
        level: Array<string | null> | null;
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
      };
      details: {
        __typename?: "ActivityDetails";
        photo: string | null;
        photoSquare: string | null;
      };
    };
    orgTeacher: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      leader_link: string | null;
      created_at: any | null;
      photo: string | null;
      suspended_at: any | null;
      cityState: string | null;
      socialOptOut: boolean;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      hasBackgroundCheckExpired: boolean | null;
      reviewCount: number;
      averageActivityStarRating: number | null;
      hasListings: boolean;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        headlineTranslated: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
    } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    conflictingEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      learner: {
        __typename?: "Learner";
        uid: string;
        age: number | null;
        name: string | null;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
    }>;
    pendingEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      hasOverlappingPendingEnrollmentMeetingPurchase: boolean;
      learner: {
        __typename?: "Learner";
        uid: string;
        age: number | null;
        name: string | null;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
    }>;
    enrollmentsForActivityAndParent: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      learner: {
        __typename?: "Learner";
        uid: string;
        age: number | null;
        name: string | null;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
    }>;
    meetingRequests: Array<{
      __typename?: "MeetingRequest";
      uid: string;
      start_time: any;
      end_time: any;
      is_recurring: boolean;
      status: MeetingRequestStatus;
    }> | null;
  } | null;
};

export type EnrollmentMeetingPurchaseRecurringQueryQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  startTime: Scalars["DateTime"];
  endTime: Scalars["DateTime"];
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
  includeFirstMeeting?: InputMaybe<Scalars["Boolean"]>;
}>;

export type EnrollmentMeetingPurchaseRecurringQueryQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    canScheduleContinuously: boolean;
    futureEnrollmentMeetingAvailability: Array<{
      __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
      startTime: any;
      endTime: any;
      isAvailable: boolean;
    }>;
  };
};

export type TransferEnrollmentMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
  newSectionUid: Scalars["ID"];
}>;

export type TransferEnrollmentMutation = {
  __typename?: "Mutation";
  transferEnrollment: {
    __typename?: "Enrollment";
    uid: string;
    section_uid: string | null;
    price_cents: number | null;
    priceCents: number | null;
    created_at: any | null;
    confirmed_at: any | null;
    confirmedAt: any | null;
    withdrawn_at: any | null;
    ongoing_stopped_at: any | null;
    currency_code: CurrencyCode;
    exchange_rate: number;
    amount_in_local_currency: number;
    ongoing_start_time: any | null;
    ongoing_end_time: any | null;
    withdrawnAt: any | null;
    ongoingStoppedAt: any | null;
    ongoingStartTime: any | null;
    ongoingEndTime: any | null;
    isPaused: boolean;
    isEnded: boolean;
    isPrepay: boolean;
    refundableAmountCents: number;
    canBeWithdrawn: boolean;
    canBeStopped: boolean;
    canBePaused: boolean;
    usersCanCommunicate: boolean;
    purchasedAsGift: boolean;
    section: { __typename?: "Section"; uid: string } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      type: RefundPolicyType;
      name: string;
      description: string;
    } | null;
    details: {
      __typename?: "EnrollmentDetails";
      name: string | null;
      age: string | null;
      attendedAt: any | null;
      message: string | null;
    };
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    buyer: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    siblings: Array<{
      __typename?: "Sibling";
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
      currentOrLastSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
      };
    }>;
    paymentSchedule: {
      __typename?: "PaymentSchedule";
      uid: string;
      stoppedAt: any | null;
      endTime: any | null;
      scheduleType: PaymentScheduleTypes;
      amountCents: number | null;
    } | null;
    pauseWindow: {
      __typename?: "EnrollmentPauseWindow";
      uid: string;
      startDate: any;
      endDate: any;
      canBeCanceled: boolean;
      pauseReason: string;
    } | null;
  } | null;
};

export type TransferUnclaimedGiftEnrollmentMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
  newSectionUid: Scalars["ID"];
}>;

export type TransferUnclaimedGiftEnrollmentMutation = {
  __typename?: "Mutation";
  transferUnclaimedGiftEnrollment: {
    __typename?: "GiftEnrollment";
    uid: string;
    giftClaimedAt: any | null;
    createdAt: any;
    confirmedAt: any | null;
    withdrawnAt: any | null;
    withdrawn_at: any | null;
    ongoingStoppedAt: any | null;
    ongoingStartTime: any | null;
    ongoingEndTime: any | null;
    ongoing_stopped_at: any | null;
    currency_code: CurrencyCode;
    exchange_rate: number;
    amount_in_local_currency: number;
    ongoing_start_time: any | null;
    ongoing_end_time: any | null;
    hasPaymentSchedule: boolean;
    isEnded: boolean;
    isPaused: boolean;
    orderSlugId: string | null;
    canBeWithdrawn: boolean;
    canBeStopped: boolean;
    canBePaused: boolean;
    priceCents: number | null;
    currentUserIsPurchaser: boolean;
    refundableAmountCents: number;
    section: { __typename?: "Section"; uid: string };
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    buyer: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
    details: {
      __typename?: "EnrollmentDetails";
      name: string | null;
      age: string | null;
      attendedAt: any | null;
      message: string | null;
    };
    payments: Array<{
      __typename?: "EnrollmentPayment";
      uid: string;
      amount_cents: number | null;
      order_slug_id: string | null;
      stripe_charge_id: string | null;
      purchase_order_id: string | null;
      esa_order_id: string | null;
      intercom_url: string | null;
      last4: string | null;
      created_at: any | null;
      paid_at: any | null;
      disbursed_at: any | null;
      payout_uid: string | null;
      start_time: any | null;
      end_time: any | null;
      unrefundedCents: number;
      isOngoingRefundable: boolean;
      voucher_name: string | null;
      pricing_offer_uid: string | null;
      credit_transaction_uid: string | null;
      coupon_code_uid: string | null;
      currency_code: CurrencyCode | null;
      exchange_rate: number | null;
      invoice_uid: string | null;
      funding_program_invoice_uid: string | null;
      service_fee_percentage: number | null;
      couponCode: {
        __typename?: "CouponCode";
        code: string;
        coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
      } | null;
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
    }>;
    refundPolicy: {
      __typename?: "RefundPolicy";
      type: RefundPolicyType;
      name: string;
      description: string;
    } | null;
    paymentSchedule: {
      __typename?: "PaymentSchedule";
      uid: string;
      stoppedAt: any | null;
      endTime: any | null;
    } | null;
    pauseWindow: {
      __typename?: "EnrollmentPauseWindow";
      uid: string;
      startDate: any;
      endDate: any;
      canBeCanceled: boolean;
      pauseReason: string;
    } | null;
  } | null;
};

export type WithdrawAndRefundGiftEnrollmentMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
  refundReason: Scalars["String"];
  refundNote: Scalars["String"];
  refundAllRemaining?: InputMaybe<Scalars["Boolean"]>;
}>;

export type WithdrawAndRefundGiftEnrollmentMutation = {
  __typename?: "Mutation";
  withdrawAndRefundGiftEnrollment: {
    __typename?: "GiftEnrollment";
    section: {
      __typename?: "Section";
      uid: string;
      currentUserGiftEnrollments: Array<{
        __typename?: "GiftEnrollment";
        uid: string;
        giftClaimedAt: any | null;
        createdAt: any;
        confirmedAt: any | null;
        withdrawnAt: any | null;
        withdrawn_at: any | null;
        ongoingStoppedAt: any | null;
        ongoingStartTime: any | null;
        ongoingEndTime: any | null;
        ongoing_stopped_at: any | null;
        currency_code: CurrencyCode;
        exchange_rate: number;
        amount_in_local_currency: number;
        ongoing_start_time: any | null;
        ongoing_end_time: any | null;
        hasPaymentSchedule: boolean;
        isEnded: boolean;
        isPaused: boolean;
        orderSlugId: string | null;
        canBeWithdrawn: boolean;
        canBeStopped: boolean;
        canBePaused: boolean;
        priceCents: number | null;
        currentUserIsPurchaser: boolean;
        refundableAmountCents: number;
        parent: {
          __typename?: "Parent";
          uid: string;
          name: string | null;
          photo: string | null;
          publicName: string | null;
          introduction: string | null;
          created_at: any | null;
          deleted_at: any | null;
          timeZone: string | null;
          socialOptOut: boolean;
          consent_to_share_class_history: boolean | null;
          consent_to_share_favourites: boolean | null;
        } | null;
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
          age: number | null;
          avatar: string | null;
          pronoun: string | null;
          intro: string | null;
          deleted_at: any | null;
          isVerified: boolean;
          isBlocked: boolean | null;
          blockedByUserUid: string | null;
          blockedAt: any | null;
        } | null;
        buyer: {
          __typename?: "Parent";
          uid: string;
          name: string | null;
          photo: string | null;
          publicName: string | null;
          introduction: string | null;
          created_at: any | null;
          deleted_at: any | null;
          timeZone: string | null;
          socialOptOut: boolean;
          consent_to_share_class_history: boolean | null;
          consent_to_share_favourites: boolean | null;
        };
        pricingOffer: {
          __typename?: "PricingOffer";
          name: string;
          buyerOrgName: string | null;
          isFinancialAid: boolean;
        } | null;
        details: {
          __typename?: "EnrollmentDetails";
          name: string | null;
          age: string | null;
          attendedAt: any | null;
          message: string | null;
        };
        payments: Array<{
          __typename?: "EnrollmentPayment";
          uid: string;
          amount_cents: number | null;
          order_slug_id: string | null;
          stripe_charge_id: string | null;
          purchase_order_id: string | null;
          esa_order_id: string | null;
          intercom_url: string | null;
          last4: string | null;
          created_at: any | null;
          paid_at: any | null;
          disbursed_at: any | null;
          payout_uid: string | null;
          start_time: any | null;
          end_time: any | null;
          unrefundedCents: number;
          isOngoingRefundable: boolean;
          voucher_name: string | null;
          pricing_offer_uid: string | null;
          credit_transaction_uid: string | null;
          coupon_code_uid: string | null;
          currency_code: CurrencyCode | null;
          exchange_rate: number | null;
          invoice_uid: string | null;
          funding_program_invoice_uid: string | null;
          service_fee_percentage: number | null;
          couponCode: {
            __typename?: "CouponCode";
            code: string;
            coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
          } | null;
          pricingOffer: {
            __typename?: "PricingOffer";
            name: string;
            buyerOrgName: string | null;
            isFinancialAid: boolean;
          } | null;
        }>;
        refundPolicy: {
          __typename?: "RefundPolicy";
          type: RefundPolicyType;
          name: string;
          description: string;
        } | null;
        paymentSchedule: {
          __typename?: "PaymentSchedule";
          uid: string;
          stoppedAt: any | null;
          endTime: any | null;
        } | null;
        pauseWindow: {
          __typename?: "EnrollmentPauseWindow";
          uid: string;
          startDate: any;
          endDate: any;
          canBeCanceled: boolean;
          pauseReason: string;
        } | null;
      }>;
    };
  };
};

export type WithdrawAndRefundEnrollmentMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
  refundReason: Scalars["String"];
  refundNote: Scalars["String"];
  refundAllRemaining?: InputMaybe<Scalars["Boolean"]>;
}>;

export type WithdrawAndRefundEnrollmentMutation = {
  __typename?: "Mutation";
  withdrawAndRefundEnrollment: {
    __typename?: "Enrollment";
    uid: string;
    section_uid: string | null;
    price_cents: number | null;
    priceCents: number | null;
    created_at: any | null;
    confirmed_at: any | null;
    confirmedAt: any | null;
    withdrawn_at: any | null;
    ongoing_stopped_at: any | null;
    currency_code: CurrencyCode;
    exchange_rate: number;
    amount_in_local_currency: number;
    ongoing_start_time: any | null;
    ongoing_end_time: any | null;
    withdrawnAt: any | null;
    ongoingStoppedAt: any | null;
    ongoingStartTime: any | null;
    ongoingEndTime: any | null;
    isPaused: boolean;
    isEnded: boolean;
    isPrepay: boolean;
    refundableAmountCents: number;
    canBeWithdrawn: boolean;
    canBeStopped: boolean;
    canBePaused: boolean;
    usersCanCommunicate: boolean;
    purchasedAsGift: boolean;
    section: { __typename?: "Section"; uid: string } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      type: RefundPolicyType;
      name: string;
      description: string;
    } | null;
    details: {
      __typename?: "EnrollmentDetails";
      name: string | null;
      age: string | null;
      attendedAt: any | null;
      message: string | null;
    };
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    buyer: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    siblings: Array<{
      __typename?: "Sibling";
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
      currentOrLastSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
      };
    }>;
    paymentSchedule: {
      __typename?: "PaymentSchedule";
      uid: string;
      stoppedAt: any | null;
      endTime: any | null;
      scheduleType: PaymentScheduleTypes;
      amountCents: number | null;
    } | null;
    pauseWindow: {
      __typename?: "EnrollmentPauseWindow";
      uid: string;
      startDate: any;
      endDate: any;
      canBeCanceled: boolean;
      pauseReason: string;
    } | null;
  };
};

export type StopSubscriptionMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
  reason?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  refundPaymentUids?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type StopSubscriptionMutation = {
  __typename?: "Mutation";
  stopSubscription: {
    __typename?: "Enrollment";
    uid: string;
    section_uid: string | null;
    price_cents: number | null;
    priceCents: number | null;
    created_at: any | null;
    confirmed_at: any | null;
    confirmedAt: any | null;
    withdrawn_at: any | null;
    ongoing_stopped_at: any | null;
    currency_code: CurrencyCode;
    exchange_rate: number;
    amount_in_local_currency: number;
    ongoing_start_time: any | null;
    ongoing_end_time: any | null;
    withdrawnAt: any | null;
    ongoingStoppedAt: any | null;
    ongoingStartTime: any | null;
    ongoingEndTime: any | null;
    isPaused: boolean;
    isEnded: boolean;
    isPrepay: boolean;
    refundableAmountCents: number;
    canBeWithdrawn: boolean;
    canBeStopped: boolean;
    canBePaused: boolean;
    usersCanCommunicate: boolean;
    purchasedAsGift: boolean;
    section: { __typename?: "Section"; uid: string } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      type: RefundPolicyType;
      name: string;
      description: string;
    } | null;
    details: {
      __typename?: "EnrollmentDetails";
      name: string | null;
      age: string | null;
      attendedAt: any | null;
      message: string | null;
    };
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    buyer: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    siblings: Array<{
      __typename?: "Sibling";
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
      currentOrLastSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
      };
    }>;
    paymentSchedule: {
      __typename?: "PaymentSchedule";
      uid: string;
      stoppedAt: any | null;
      endTime: any | null;
      scheduleType: PaymentScheduleTypes;
      amountCents: number | null;
    } | null;
    pauseWindow: {
      __typename?: "EnrollmentPauseWindow";
      uid: string;
      startDate: any;
      endDate: any;
      canBeCanceled: boolean;
      pauseReason: string;
    } | null;
  };
};

export type CreateEnrollmentsMutationVariables = Exact<{
  orderInput: OrderInput;
  esaState?: InputMaybe<Scalars["String"]>;
  isPrepay?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CreateEnrollmentsMutation = {
  __typename?: "Mutation";
  createEnrollments: {
    __typename?: "Order";
    orderSlugId: string;
    enrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      section_uid: string | null;
      price_cents: number | null;
      priceCents: number | null;
      created_at: any | null;
      confirmed_at: any | null;
      confirmedAt: any | null;
      withdrawn_at: any | null;
      ongoing_stopped_at: any | null;
      currency_code: CurrencyCode;
      exchange_rate: number;
      amount_in_local_currency: number;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      withdrawnAt: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      isPrepay: boolean;
      refundableAmountCents: number;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      usersCanCommunicate: boolean;
      purchasedAsGift: boolean;
      pricingOffer: {
        __typename?: "PricingOffer";
        uid: string;
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        type: RefundPolicyType;
        name: string;
        description: string;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      siblings: Array<{
        __typename?: "Sibling";
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
          age: number | null;
          avatar: string | null;
          pronoun: string | null;
          intro: string | null;
          deleted_at: any | null;
          isVerified: boolean;
          isBlocked: boolean | null;
          blockedByUserUid: string | null;
          blockedAt: any | null;
        };
        currentOrLastSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          uid: string;
          activity_uid: string;
          start_time: any | null;
          end_time: any | null;
          startsAtNight: boolean | null;
          is_cross_listed: boolean;
          size_max: number | null;
          size_min: number | null;
          isPublished: boolean | null;
          price_cents: number | null;
          priceCredits: number | null;
          published_at: any | null;
          updated_at: any;
          canceled_at: any | null;
          deleted_at: any | null;
          ongoing_stopped_at: any | null;
          duration_minutes: number | null;
          usesOutschoolVideoChat: boolean;
          usersCanSendPrivateMessage: boolean;
          currentUserCanManage: boolean;
          isBuyable: boolean;
          allowsTeacherTransfer: boolean;
          nextOngoingMeeting: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
            deleted_at: any | null;
            created_at: any | null;
          } | null;
          leader: { __typename?: "Leader"; uid: string; name: string | null };
          details: {
            __typename?: "SectionDetails";
            autoScheduledDraft: boolean | null;
          };
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
        };
      }>;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
        endTime: any | null;
        scheduleType: PaymentScheduleTypes;
        amountCents: number | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
    }>;
    giftEnrollments: Array<{
      __typename?: "GiftEnrollment";
      uid: string;
      acceptGiftSlugId: string;
      confirmedAt: any | null;
    }> | null;
  };
};

export type CreateEnrollmentMeetingPurchaseMutationVariables = Exact<{
  orderInput: EnrollmentMeetingPurchaseOrderInput;
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type CreateEnrollmentMeetingPurchaseMutation = {
  __typename?: "Mutation";
  createEnrollmentMeetingPurchase: {
    __typename?: "EnrollmentMeetingPurchaseOrder";
    orderSlugId: string;
    enrollmentMeetingPurchase: {
      __typename?: "EnrollmentMeetingPurchase";
      uid: string;
      activityUid: string | null;
      enrollmentUid: string | null;
      meetingUid: string | null;
      orderSlugId: string;
      confirmedAt: any | null;
    };
  };
};

export type CreateBulkEnrollmentsMutationVariables = Exact<{
  bulkOrderInput: BulkOrderInput;
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type CreateBulkEnrollmentsMutation = {
  __typename?: "Mutation";
  createBulkEnrollments: {
    __typename?: "Order";
    orderSlugId: string;
    enrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      section_uid: string | null;
      price_cents: number | null;
      priceCents: number | null;
      created_at: any | null;
      confirmed_at: any | null;
      confirmedAt: any | null;
      withdrawn_at: any | null;
      ongoing_stopped_at: any | null;
      currency_code: CurrencyCode;
      exchange_rate: number;
      amount_in_local_currency: number;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      withdrawnAt: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      isPrepay: boolean;
      refundableAmountCents: number;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      usersCanCommunicate: boolean;
      purchasedAsGift: boolean;
      pricingOffer: {
        __typename?: "PricingOffer";
        uid: string;
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        type: RefundPolicyType;
        name: string;
        description: string;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      siblings: Array<{
        __typename?: "Sibling";
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
          age: number | null;
          avatar: string | null;
          pronoun: string | null;
          intro: string | null;
          deleted_at: any | null;
          isVerified: boolean;
          isBlocked: boolean | null;
          blockedByUserUid: string | null;
          blockedAt: any | null;
        };
        currentOrLastSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          uid: string;
          activity_uid: string;
          start_time: any | null;
          end_time: any | null;
          startsAtNight: boolean | null;
          is_cross_listed: boolean;
          size_max: number | null;
          size_min: number | null;
          isPublished: boolean | null;
          price_cents: number | null;
          priceCredits: number | null;
          published_at: any | null;
          updated_at: any;
          canceled_at: any | null;
          deleted_at: any | null;
          ongoing_stopped_at: any | null;
          duration_minutes: number | null;
          usesOutschoolVideoChat: boolean;
          usersCanSendPrivateMessage: boolean;
          currentUserCanManage: boolean;
          isBuyable: boolean;
          allowsTeacherTransfer: boolean;
          nextOngoingMeeting: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
            deleted_at: any | null;
            created_at: any | null;
          } | null;
          leader: { __typename?: "Leader"; uid: string; name: string | null };
          details: {
            __typename?: "SectionDetails";
            autoScheduledDraft: boolean | null;
          };
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
        };
      }>;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
        endTime: any | null;
        scheduleType: PaymentScheduleTypes;
        amountCents: number | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
    }>;
    giftEnrollments: Array<{
      __typename?: "GiftEnrollment";
      uid: string;
      acceptGiftSlugId: string;
      confirmedAt: any | null;
    }> | null;
    enrollmentMeetingPurchases: Array<{
      __typename?: "EnrollmentMeetingPurchase";
      uid: string;
      activityUid: string | null;
      enrollmentUid: string | null;
      meetingUid: string | null;
      orderSlugId: string;
      confirmedAt: any | null;
    }> | null;
  };
};

export type ClaimGiftEnrollmentMutationVariables = Exact<{
  acceptGiftSlugId: Scalars["ID"];
  learner: EnrollmentLearnerInput;
  recipientWillPurchaseOngoingExtension?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ClaimGiftEnrollmentMutation = {
  __typename?: "Mutation";
  claimGiftEnrollment: {
    __typename?: "Enrollment";
    uid: string;
    section_uid: string | null;
    price_cents: number | null;
    priceCents: number | null;
    created_at: any | null;
    confirmed_at: any | null;
    confirmedAt: any | null;
    withdrawn_at: any | null;
    ongoing_stopped_at: any | null;
    currency_code: CurrencyCode;
    exchange_rate: number;
    amount_in_local_currency: number;
    ongoing_start_time: any | null;
    ongoing_end_time: any | null;
    withdrawnAt: any | null;
    ongoingStoppedAt: any | null;
    ongoingStartTime: any | null;
    ongoingEndTime: any | null;
    isPaused: boolean;
    isEnded: boolean;
    isPrepay: boolean;
    refundableAmountCents: number;
    canBeWithdrawn: boolean;
    canBeStopped: boolean;
    canBePaused: boolean;
    usersCanCommunicate: boolean;
    purchasedAsGift: boolean;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      type: RefundPolicyType;
      name: string;
      description: string;
    } | null;
    details: {
      __typename?: "EnrollmentDetails";
      name: string | null;
      age: string | null;
      attendedAt: any | null;
      message: string | null;
    };
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    buyer: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    siblings: Array<{
      __typename?: "Sibling";
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
      currentOrLastSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
      };
    }>;
    paymentSchedule: {
      __typename?: "PaymentSchedule";
      uid: string;
      stoppedAt: any | null;
      endTime: any | null;
      scheduleType: PaymentScheduleTypes;
      amountCents: number | null;
    } | null;
    pauseWindow: {
      __typename?: "EnrollmentPauseWindow";
      uid: string;
      startDate: any;
      endDate: any;
      canBeCanceled: boolean;
      pauseReason: string;
    } | null;
  };
};

export type CreateClubEnrollmentMutationVariables = Exact<{
  sectionUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
}>;

export type CreateClubEnrollmentMutation = {
  __typename?: "Mutation";
  createClubEnrollment: {
    __typename?: "Enrollment";
    uid: string;
    section_uid: string | null;
    price_cents: number | null;
    priceCents: number | null;
    created_at: any | null;
    confirmed_at: any | null;
    confirmedAt: any | null;
    withdrawn_at: any | null;
    ongoing_stopped_at: any | null;
    currency_code: CurrencyCode;
    exchange_rate: number;
    amount_in_local_currency: number;
    ongoing_start_time: any | null;
    ongoing_end_time: any | null;
    withdrawnAt: any | null;
    ongoingStoppedAt: any | null;
    ongoingStartTime: any | null;
    ongoingEndTime: any | null;
    isPaused: boolean;
    isEnded: boolean;
    isPrepay: boolean;
    refundableAmountCents: number;
    canBeWithdrawn: boolean;
    canBeStopped: boolean;
    canBePaused: boolean;
    usersCanCommunicate: boolean;
    purchasedAsGift: boolean;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      type: RefundPolicyType;
      name: string;
      description: string;
    } | null;
    details: {
      __typename?: "EnrollmentDetails";
      name: string | null;
      age: string | null;
      attendedAt: any | null;
      message: string | null;
    };
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    buyer: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    siblings: Array<{
      __typename?: "Sibling";
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
      currentOrLastSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
      };
    }>;
    paymentSchedule: {
      __typename?: "PaymentSchedule";
      uid: string;
      stoppedAt: any | null;
      endTime: any | null;
      scheduleType: PaymentScheduleTypes;
      amountCents: number | null;
    } | null;
    pauseWindow: {
      __typename?: "EnrollmentPauseWindow";
      uid: string;
      startDate: any;
      endDate: any;
      canBeCanceled: boolean;
      pauseReason: string;
    } | null;
  };
};

export type PauseOngoingEnrollmentsMutationVariables = Exact<{
  input: Array<PauseOngoingEnrollmentInput> | PauseOngoingEnrollmentInput;
}>;

export type PauseOngoingEnrollmentsMutation = {
  __typename?: "Mutation";
  pauseOngoingEnrollments: Array<{
    __typename?: "Enrollment";
    uid: string;
    section_uid: string | null;
    price_cents: number | null;
    priceCents: number | null;
    created_at: any | null;
    confirmed_at: any | null;
    confirmedAt: any | null;
    withdrawn_at: any | null;
    ongoing_stopped_at: any | null;
    currency_code: CurrencyCode;
    exchange_rate: number;
    amount_in_local_currency: number;
    ongoing_start_time: any | null;
    ongoing_end_time: any | null;
    withdrawnAt: any | null;
    ongoingStoppedAt: any | null;
    ongoingStartTime: any | null;
    ongoingEndTime: any | null;
    isPaused: boolean;
    isEnded: boolean;
    isPrepay: boolean;
    refundableAmountCents: number;
    canBeWithdrawn: boolean;
    canBeStopped: boolean;
    canBePaused: boolean;
    usersCanCommunicate: boolean;
    purchasedAsGift: boolean;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      type: RefundPolicyType;
      name: string;
      description: string;
    } | null;
    details: {
      __typename?: "EnrollmentDetails";
      name: string | null;
      age: string | null;
      attendedAt: any | null;
      message: string | null;
    };
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    buyer: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    } | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    } | null;
    siblings: Array<{
      __typename?: "Sibling";
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
      currentOrLastSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
      };
    }>;
    paymentSchedule: {
      __typename?: "PaymentSchedule";
      uid: string;
      stoppedAt: any | null;
      endTime: any | null;
      scheduleType: PaymentScheduleTypes;
      amountCents: number | null;
    } | null;
    pauseWindow: {
      __typename?: "EnrollmentPauseWindow";
      uid: string;
      startDate: any;
      endDate: any;
      canBeCanceled: boolean;
      pauseReason: string;
    } | null;
  }>;
};

export type OneOnOneEnrollmentRepriceMutationMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
  newPriceCents: Scalars["Int"];
}>;

export type OneOnOneEnrollmentRepriceMutationMutation = {
  __typename?: "Mutation";
  repriceTutoringEnrollment: {
    __typename?: "RepriceTutoringResult";
    canceledMeetingUids: Array<string>;
    enrollment: {
      __typename?: "Enrollment";
      uid: string;
      section_uid: string | null;
      price_cents: number | null;
      priceCents: number | null;
      created_at: any | null;
      confirmed_at: any | null;
      confirmedAt: any | null;
      withdrawn_at: any | null;
      ongoing_stopped_at: any | null;
      currency_code: CurrencyCode;
      exchange_rate: number;
      amount_in_local_currency: number;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      withdrawnAt: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      isPrepay: boolean;
      refundableAmountCents: number;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      usersCanCommunicate: boolean;
      purchasedAsGift: boolean;
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        type: RefundPolicyType;
        name: string;
        description: string;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      siblings: Array<{
        __typename?: "Sibling";
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
          age: number | null;
          avatar: string | null;
          pronoun: string | null;
          intro: string | null;
          deleted_at: any | null;
          isVerified: boolean;
          isBlocked: boolean | null;
          blockedByUserUid: string | null;
          blockedAt: any | null;
        };
        currentOrLastSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          uid: string;
          activity_uid: string;
          start_time: any | null;
          end_time: any | null;
          startsAtNight: boolean | null;
          is_cross_listed: boolean;
          size_max: number | null;
          size_min: number | null;
          isPublished: boolean | null;
          price_cents: number | null;
          priceCredits: number | null;
          published_at: any | null;
          updated_at: any;
          canceled_at: any | null;
          deleted_at: any | null;
          ongoing_stopped_at: any | null;
          duration_minutes: number | null;
          usesOutschoolVideoChat: boolean;
          usersCanSendPrivateMessage: boolean;
          currentUserCanManage: boolean;
          isBuyable: boolean;
          allowsTeacherTransfer: boolean;
          nextOngoingMeeting: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
            deleted_at: any | null;
            created_at: any | null;
          } | null;
          leader: { __typename?: "Leader"; uid: string; name: string | null };
          details: {
            __typename?: "SectionDetails";
            autoScheduledDraft: boolean | null;
          };
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
        };
      }>;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
        endTime: any | null;
        scheduleType: PaymentScheduleTypes;
        amountCents: number | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
    };
  };
};

export type EnrollmentPaymentFragmentFragment = {
  __typename?: "EnrollmentPayment";
  uid: string;
  amount_cents: number | null;
  order_slug_id: string | null;
  stripe_charge_id: string | null;
  purchase_order_id: string | null;
  esa_order_id: string | null;
  intercom_url: string | null;
  last4: string | null;
  created_at: any | null;
  paid_at: any | null;
  disbursed_at: any | null;
  payout_uid: string | null;
  start_time: any | null;
  end_time: any | null;
  unrefundedCents: number;
  isOngoingRefundable: boolean;
  voucher_name: string | null;
  pricing_offer_uid: string | null;
  credit_transaction_uid: string | null;
  coupon_code_uid: string | null;
  currency_code: CurrencyCode | null;
  exchange_rate: number | null;
  invoice_uid: string | null;
  funding_program_invoice_uid: string | null;
  service_fee_percentage: number | null;
  couponCode: {
    __typename?: "CouponCode";
    code: string;
    coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
  } | null;
  pricingOffer: {
    __typename?: "PricingOffer";
    name: string;
    buyerOrgName: string | null;
    isFinancialAid: boolean;
  } | null;
};

export type EnrollmentPaymentReceiptFragmentFragment = {
  __typename?: "EnrollmentPayment";
  uid: string;
  amount_cents: number | null;
  amount_credits: number | null;
  order_slug_id: string | null;
  stripe_charge_id: string | null;
  purchase_order_id: string | null;
  pricing_offer_uid: string | null;
  esa_order_id: string | null;
  invoice_uid: string | null;
  funding_program_invoice_uid: string | null;
  intercom_url: string | null;
  last4: string | null;
  created_at: any | null;
  paid_at: any | null;
  disbursed_at: any | null;
  payout_uid: string | null;
  start_time: any | null;
  end_time: any | null;
  unrefundedCents: number;
  isOngoingRefundable: boolean;
  voucher_name: string | null;
  credit_transaction_uid: string | null;
  credit_account_type: string | null;
  service_fee_percentage: number | null;
  coupon_code_uid: string | null;
  currency_code: CurrencyCode | null;
  exchange_rate: number | null;
  stripeReceiptUrl: string | null;
  stripePaymentMethod: string | null;
  couponCode: {
    __typename?: "CouponCode";
    code: string;
    coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
  } | null;
  pricingOffer: {
    __typename?: "PricingOffer";
    name: string;
    buyerOrgName: string | null;
    isFinancialAid: boolean;
  } | null;
};

export type RefundEnrollmentMutationVariables = Exact<{
  enrollmentPaymentUid: Scalars["ID"];
  refundAmountCents: Scalars["Int"];
  reducesTeacherPayout: Scalars["Boolean"];
  refundReason: Scalars["String"];
  refundNote: Scalars["String"];
  refundType?: InputMaybe<RefundType>;
}>;

export type RefundEnrollmentMutation = {
  __typename?: "Mutation";
  refundEnrollmentPayment: {
    __typename?: "EnrollmentPayment";
    uid: string;
    amount_cents: number | null;
    order_slug_id: string | null;
    stripe_charge_id: string | null;
    purchase_order_id: string | null;
    esa_order_id: string | null;
    intercom_url: string | null;
    last4: string | null;
    created_at: any | null;
    paid_at: any | null;
    disbursed_at: any | null;
    payout_uid: string | null;
    start_time: any | null;
    end_time: any | null;
    unrefundedCents: number;
    isOngoingRefundable: boolean;
    voucher_name: string | null;
    pricing_offer_uid: string | null;
    credit_transaction_uid: string | null;
    coupon_code_uid: string | null;
    currency_code: CurrencyCode | null;
    exchange_rate: number | null;
    invoice_uid: string | null;
    funding_program_invoice_uid: string | null;
    service_fee_percentage: number | null;
    refunds: Array<{
      __typename?: "EnrollmentPaymentRefund";
      uid: string;
      user_uid: string;
      amount_cents: number;
      amount_credits: number | null;
      refunded_at: any | null;
      reason: string | null;
      user_role: string | null;
      note: string | null;
      reduces_teacher_payout: boolean;
      amount_in_local_currency: number | null;
      currency_code: CurrencyCode | null;
      exchange_rate: number | null;
      addOutschoolCreditTransaction: {
        __typename?: "CreditTransaction";
        uid: string;
        amount_cents: number;
        expiresAt: any | null;
        unspentCents: number | null;
      } | null;
    }>;
    couponCode: {
      __typename?: "CouponCode";
      code: string;
      coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
  };
};

export type RefundEnrollmentCreditsMutationVariables = Exact<{
  enrollmentPaymentUid: Scalars["ID"];
  refundAmountCredits: Scalars["Int"];
  reducesTeacherPayout: Scalars["Boolean"];
  refundReason: Scalars["String"];
  refundNote: Scalars["String"];
  refundType?: InputMaybe<RefundType>;
}>;

export type RefundEnrollmentCreditsMutation = {
  __typename?: "Mutation";
  refundEnrollmentPaymentCredits: {
    __typename?: "EnrollmentPayment";
    uid: string;
    amount_cents: number | null;
    order_slug_id: string | null;
    stripe_charge_id: string | null;
    purchase_order_id: string | null;
    esa_order_id: string | null;
    intercom_url: string | null;
    last4: string | null;
    created_at: any | null;
    paid_at: any | null;
    disbursed_at: any | null;
    payout_uid: string | null;
    start_time: any | null;
    end_time: any | null;
    unrefundedCents: number;
    isOngoingRefundable: boolean;
    voucher_name: string | null;
    pricing_offer_uid: string | null;
    credit_transaction_uid: string | null;
    coupon_code_uid: string | null;
    currency_code: CurrencyCode | null;
    exchange_rate: number | null;
    invoice_uid: string | null;
    funding_program_invoice_uid: string | null;
    service_fee_percentage: number | null;
    refunds: Array<{
      __typename?: "EnrollmentPaymentRefund";
      uid: string;
      user_uid: string;
      amount_cents: number;
      amount_credits: number | null;
      refunded_at: any | null;
      reason: string | null;
      user_role: string | null;
      note: string | null;
      reduces_teacher_payout: boolean;
      amount_in_local_currency: number | null;
      currency_code: CurrencyCode | null;
      exchange_rate: number | null;
      addOutschoolCreditTransaction: {
        __typename?: "CreditTransaction";
        uid: string;
        amount_cents: number;
        expiresAt: any | null;
        unspentCents: number | null;
      } | null;
    }>;
    couponCode: {
      __typename?: "CouponCode";
      code: string;
      coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
  };
};

export type EnrollmentPaymentRefundFragmentFragment = {
  __typename?: "EnrollmentPaymentRefund";
  uid: string;
  user_uid: string;
  amount_cents: number;
  amount_credits: number | null;
  refunded_at: any | null;
  reason: string | null;
  user_role: string | null;
  note: string | null;
  reduces_teacher_payout: boolean;
  amount_in_local_currency: number | null;
  currency_code: CurrencyCode | null;
  exchange_rate: number | null;
  addOutschoolCreditTransaction: {
    __typename?: "CreditTransaction";
    uid: string;
    amount_cents: number;
    expiresAt: any | null;
    unspentCents: number | null;
  } | null;
};

export type ExchangeRateQueryVariables = Exact<{
  currencyCode: CurrencyCode;
}>;

export type ExchangeRateQuery = {
  __typename?: "Query";
  exchangeRate: {
    __typename?: "ExchangeRate";
    uid: string;
    currency_code: CurrencyCode;
    exchange_rate: number;
  } | null;
};

export type ExternalResourceFragmentFragment = {
  __typename?: "ExternalResource";
  uid: string;
  name: string;
  url: string | null;
};

export type ActivityExternalResourceFragmentFragment = {
  __typename?: "Activity";
  has_external_resources: boolean | null;
  externalResources: Array<{
    __typename?: "ExternalResource";
    uid: string;
    name: string;
    url: string | null;
  }>;
};

export type FeedbackForLeaderFragmentFragment = {
  __typename?: "FeedbackForLeader";
  uid: string;
  activity_star_rating: number | null;
  created_at: any;
  details: {
    __typename?: "FeedbackForLeaderDetails";
    leaderFeedback: string | null;
  };
};

export type FeedbackFragmentFragment = {
  __typename?: "Feedback";
  uid: string;
  section_uid: string;
  currentUserCanEdit: boolean;
  activity_star_rating: number | null;
  publicReview: string | null;
  outschoolFeedback: string | null;
  leaderFeedback: string | null;
  parent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  };
  attachments: Array<{
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  }>;
};

export type CreateFeedbackMutationVariables = Exact<{
  feedbackInput: FeedbackInput;
}>;

export type CreateFeedbackMutation = {
  __typename?: "Mutation";
  createFeedback: {
    __typename?: "Feedback";
    uid: string;
    section_uid: string;
    currentUserCanEdit: boolean;
    activity_star_rating: number | null;
    publicReview: string | null;
    outschoolFeedback: string | null;
    leaderFeedback: string | null;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
  };
};

export type DeleteReviewMutationVariables = Exact<{
  uid: Scalars["ID"];
  reason: Scalars["String"];
  note?: InputMaybe<Scalars["String"]>;
}>;

export type DeleteReviewMutation = {
  __typename?: "Mutation";
  deleteReview: boolean;
};

export type UnfollowUserMutationVariables = Exact<{
  userUid: Scalars["ID"];
}>;

export type UnfollowUserMutation = {
  __typename?: "Mutation";
  unfollowUser: boolean;
};

export type GiftEnrollmentFragmentFragment = {
  __typename?: "GiftEnrollment";
  uid: string;
  giftClaimedAt: any | null;
  createdAt: any;
  confirmedAt: any | null;
  withdrawnAt: any | null;
  withdrawn_at: any | null;
  ongoingStoppedAt: any | null;
  ongoingStartTime: any | null;
  ongoingEndTime: any | null;
  ongoing_stopped_at: any | null;
  currency_code: CurrencyCode;
  exchange_rate: number;
  amount_in_local_currency: number;
  ongoing_start_time: any | null;
  ongoing_end_time: any | null;
  hasPaymentSchedule: boolean;
  isEnded: boolean;
  isPaused: boolean;
  orderSlugId: string | null;
  canBeWithdrawn: boolean;
  canBeStopped: boolean;
  canBePaused: boolean;
  priceCents: number | null;
  currentUserIsPurchaser: boolean;
  refundableAmountCents: number;
  parent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  } | null;
  learner: {
    __typename?: "Learner";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    isVerified: boolean;
    isBlocked: boolean | null;
    blockedByUserUid: string | null;
    blockedAt: any | null;
  } | null;
  buyer: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  };
  pricingOffer: {
    __typename?: "PricingOffer";
    name: string;
    buyerOrgName: string | null;
    isFinancialAid: boolean;
  } | null;
  details: {
    __typename?: "EnrollmentDetails";
    name: string | null;
    age: string | null;
    attendedAt: any | null;
    message: string | null;
  };
  payments: Array<{
    __typename?: "EnrollmentPayment";
    uid: string;
    amount_cents: number | null;
    order_slug_id: string | null;
    stripe_charge_id: string | null;
    purchase_order_id: string | null;
    esa_order_id: string | null;
    intercom_url: string | null;
    last4: string | null;
    created_at: any | null;
    paid_at: any | null;
    disbursed_at: any | null;
    payout_uid: string | null;
    start_time: any | null;
    end_time: any | null;
    unrefundedCents: number;
    isOngoingRefundable: boolean;
    voucher_name: string | null;
    pricing_offer_uid: string | null;
    credit_transaction_uid: string | null;
    coupon_code_uid: string | null;
    currency_code: CurrencyCode | null;
    exchange_rate: number | null;
    invoice_uid: string | null;
    funding_program_invoice_uid: string | null;
    service_fee_percentage: number | null;
    couponCode: {
      __typename?: "CouponCode";
      code: string;
      coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      name: string;
      buyerOrgName: string | null;
      isFinancialAid: boolean;
    } | null;
  }>;
  refundPolicy: {
    __typename?: "RefundPolicy";
    type: RefundPolicyType;
    name: string;
    description: string;
  } | null;
  paymentSchedule: {
    __typename?: "PaymentSchedule";
    uid: string;
    stoppedAt: any | null;
    endTime: any | null;
  } | null;
  pauseWindow: {
    __typename?: "EnrollmentPauseWindow";
    uid: string;
    startDate: any;
    endDate: any;
    canBeCanceled: boolean;
    pauseReason: string;
  } | null;
};

export type ActiveClassesQueryVariables = Exact<{ [key: string]: never }>;

export type ActiveClassesQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    futureEndingActivities: Array<{
      __typename?: "Activity";
      uid: string;
      age_max: number | null;
      age_min: number | null;
      weekly_meetings: number | null;
      duration_weeks: number | null;
      duration_minutes: number | null;
      title: string;
      classFormat: EducatorSelectedFormat;
      hasTeacherSchedule: boolean;
      details: { __typename?: "ActivityDetails"; photo: string | null };
    }>;
  } | null;
};

export type ActiveEnrollmentsByClassQueryVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type ActiveEnrollmentsByClassQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    currentAndFutureSections: Array<{
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
      filledSpaceCount: number;
      enrollments: Array<{
        __typename?: "Enrollment";
        parent: { __typename?: "Parent"; uid: string };
        learner: { __typename?: "Learner"; name: string | null } | null;
      }>;
    }>;
  };
};

export type ClassesQueryVariables = Exact<{
  filter?: InputMaybe<Scalars["String"]>;
  activityType?: InputMaybe<CreateActivityType>;
}>;

export type ClassesQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    followerCount: number;
    leaderActivities: Array<{
      __typename?: "Activity";
      classFormat: EducatorSelectedFormat;
      uid: string;
      slug_id: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      class_experience: string | null;
      learning_goals: string | null;
      learningGoals: Array<string>;
      assessment: string | null;
      homework: string | null;
      grading_policy: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      grade_level_min: string | null;
      grade_level_max: string | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      priceCredits: number | null;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      allowWaitlist: boolean | null;
      isClub: boolean;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      allows_recurring_payment: boolean | null;
      allows_late_enrollments_for_fixed_length_classes: boolean;
      has_building_content: boolean | null;
      hasTeacherSchedule: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      classroomType: ClassroomType;
      saveCount: number | null;
      archived_at: any | null;
      upsell_activity_uid: string | null;
      why_me: string | null;
      parental_guidance: string | null;
      sources: string | null;
      materials: string | null;
      hasSections: boolean;
      requested_listing_at: any | null;
      has_external_resources: boolean | null;
      leaderDetails: {
        __typename?: "ActivityLeaderDetails";
        uid: string;
        requestedListingAt: any | null;
        classRequest: {
          __typename?: "ClassRequest";
          uid: string;
          age: number;
          content: string;
        } | null;
        welcomeMessage: {
          __typename?: "WelcomeMessage";
          text: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
            };
          }> | null;
          video: {
            __typename?: "Attachment";
            uid: string;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
            };
          } | null;
        } | null;
      };
      strictAgeRange: {
        __typename?: "AgeRangeType";
        min: number;
        max: number;
      } | null;
      proficiency_level: {
        __typename?: "ProficiencyLevel";
        level: Array<string | null> | null;
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
      };
      details: {
        __typename?: "ActivityDetails";
        photo: string | null;
        photoSquare: string | null;
        asyncTime: string | null;
      };
      approvalStatus: {
        __typename?: "ActivityApprovalStatus";
        action: string;
        message_to_teacher: string | null;
      } | null;
      externalResources: Array<{
        __typename?: "ExternalResource";
        uid: string;
        name: string;
        url: string | null;
      }>;
    }>;
  } | null;
};

export type ClassLinksQueryVariables = Exact<{ [key: string]: never }>;

export type ClassLinksQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    leaderClassLinks: Array<{
      __typename?: "Bundle";
      uid: string;
      title: string;
      isOrdered: boolean;
      thumbnail: string | null;
      bundleElements: Array<{
        __typename?: "BundleElement";
        uid: string;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          age_min: number | null;
          age_max: number | null;
          details: { __typename?: "ActivityDetails"; photo: string | null };
        };
      }>;
    }>;
  } | null;
};

export type CreateOrUpdateLearnerSectionReviewMutationVariables = Exact<{
  learnerSectionReviewInput: LearnerSectionReviewInput;
}>;

export type CreateOrUpdateLearnerSectionReviewMutation = {
  __typename?: "Mutation";
  createOrUpdateLearnerSectionReview: {
    __typename?: "LearnerSectionReview";
    uid: string;
    name: ReviewName | null;
    response: {
      __typename?: "LearnerReviewResponse";
      vote: string | null;
    } | null;
  };
};

export type LearnersForParentInSectionQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type LearnersForParentInSectionQuery = {
  __typename?: "Query";
  learnersForParentInSection: Array<{
    __typename?: "Learner";
    uid: string;
  } | null>;
};

export type CreateMeetingsMutationVariables = Exact<{
  sectionUid: Scalars["ID"];
  times: Array<StartEndTime> | StartEndTime;
}>;

export type CreateMeetingsMutation = {
  __typename?: "Mutation";
  createMeetings: Array<{
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
    holiday: { __typename?: "Holiday"; name: string } | null;
  }>;
};

export type CreateMeetingsWithLessonUidsMutationVariables = Exact<{
  sectionUid: Scalars["ID"];
  meetingDetails: Array<MeetingDetails> | MeetingDetails;
}>;

export type CreateMeetingsWithLessonUidsMutation = {
  __typename?: "Mutation";
  createMeetingsWithLessonUids: Array<{
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
    holiday: { __typename?: "Holiday"; name: string } | null;
    lesson: { __typename?: "Lesson"; uid: string; title: string | null } | null;
  }>;
};

export type UpdateMeetingsMutationVariables = Exact<{
  updateMeetingsInput: Array<UpdateMeetingInput> | UpdateMeetingInput;
}>;

export type UpdateMeetingsMutation = {
  __typename?: "Mutation";
  updateMeetings: Array<{
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
    holiday: { __typename?: "Holiday"; name: string } | null;
    lesson: { __typename?: "Lesson"; uid: string; title: string | null } | null;
  }>;
};

export type UpdateMeetingsLessonMutationVariables = Exact<{
  updateMeetingsLessonInput:
    | Array<UpdateMeetingLessonInput>
    | UpdateMeetingLessonInput;
}>;

export type UpdateMeetingsLessonMutation = {
  __typename?: "Mutation";
  updateMeetingsLesson: Array<{
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
    holiday: { __typename?: "Holiday"; name: string } | null;
    lesson: { __typename?: "Lesson"; uid: string; title: string | null } | null;
  }>;
};

export type DeleteMeetingMutationVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type DeleteMeetingMutation = {
  __typename?: "Mutation";
  deleteMeeting: boolean;
};

export type CancelOneOnOneMeetingMutationVariables = Exact<{
  uid: Scalars["ID"];
  cancelReason: Scalars["String"];
  cancelNote?: InputMaybe<Scalars["String"]>;
  refundAmountCents?: InputMaybe<Scalars["Int"]>;
  reducesTeacherPayout?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CancelOneOnOneMeetingMutation = {
  __typename?: "Mutation";
  cancelOneOnOneMeeting: {
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
  };
};

export type CancelAllOneOnOneSectionMeetingsMutationVariables = Exact<{
  sectionUid: Scalars["ID"];
  cancelReason: Scalars["String"];
  cancelNote?: InputMaybe<Scalars["String"]>;
  reducesTeacherPayout?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CancelAllOneOnOneSectionMeetingsMutation = {
  __typename?: "Mutation";
  cancelAllOneOnOneSectionMeetings: boolean;
};

export type CancelOneOnOneRecurringMeetingsMutationVariables = Exact<{
  uid: Scalars["ID"];
  cancelReason: Scalars["String"];
  cancelNote?: InputMaybe<Scalars["String"]>;
  reducesTeacherPayout?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CancelOneOnOneRecurringMeetingsMutation = {
  __typename?: "Mutation";
  cancelOneOnOneRecurringMeetings: Array<string | null>;
};

export type RescheduleMeetingMutationVariables = Exact<{
  uid: Scalars["ID"];
  startTime: Scalars["DateTime"];
  endTime: Scalars["DateTime"];
  rescheduleFutureRecurringMeetings: Scalars["Boolean"];
}>;

export type RescheduleMeetingMutation = {
  __typename?: "Mutation";
  rescheduleMeeting: Array<{
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
  }>;
};

export type RequestMergeAccountMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type RequestMergeAccountMutation = {
  __typename?: "Mutation";
  requestMergeAccount: boolean;
};

export type ConfirmMergeAccountMutationVariables = Exact<{
  email: Scalars["String"];
  token: Scalars["String"];
}>;

export type ConfirmMergeAccountMutation = {
  __typename?: "Mutation";
  confirmMergeAccount: {
    __typename?: "User";
    uid: string;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    latestLegalName: string | null;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    teacher_approved_at: any | null;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    roles: Array<string> | null;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    consented_to_coppa_notice_at: any | null;
    hasVerifiedIdentity: boolean | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    upgradablePackages: Array<number | null> | null;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    details: {
      __typename?: "UserDetails";
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    location: {
      __typename?: "Location";
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      timeZone: string | null;
      state: string | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
    } | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
    } | null;
  };
};

export type SenderFragmentFragment = {
  __typename?: "Sender";
  uid: string;
  name: string | null;
  photo: string | null;
};

export type ParticipantFragmentFragment = {
  __typename?: "Participant";
  uid: string;
  name: string | null;
  photo: string | null;
  timeZone: string | null;
  headline: string | null;
  leader_link: string | null;
  isLearner: boolean;
  role: ParticipantRole | null;
  ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
  teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
};

export type EnrollmentWithTeacherFragmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  confirmed_at: any | null;
  withdrawn_at: any | null;
  ongoing_stopped_at: any | null;
  ongoing_start_time: any | null;
  ongoing_end_time: any | null;
  isEnded: boolean;
  isPaused: boolean;
  section: {
    __typename?: "Section";
    uid: string;
    start_time: any | null;
    end_time: any | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      titleTranslated: string | null;
      isClub: boolean;
      published_at: any | null;
      is_ongoing_weekly: boolean;
      weekly_meetings: number | null;
      duration_weeks: number | null;
    };
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
    } | null;
  } | null;
  learner: {
    __typename?: "Learner";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    isVerified: boolean;
    isBlocked: boolean | null;
    blockedByUserUid: string | null;
    blockedAt: any | null;
    timeZone: string;
    privateNote: string | null;
  } | null;
  pauseWindow: {
    __typename?: "EnrollmentPauseWindow";
    uid: string;
    startDate: any;
    endDate: any;
  } | null;
};

export type MessageFragmentFragment = {
  __typename?: "Message";
  uid: string;
  subject: string | null;
  action: string | null;
  content: string | null;
  created_at: any;
  activity_uid: string | null;
  section_uid: string | null;
  thread_uid: string;
  sender: {
    __typename?: "Sender";
    uid: string;
    name: string | null;
    photo: string | null;
  };
  participants: Array<{
    __typename?: "Participant";
    uid: string;
    name: string | null;
    photo: string | null;
    timeZone: string | null;
    headline: string | null;
    leader_link: string | null;
    isLearner: boolean;
    role: ParticipantRole | null;
    ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
  }>;
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  } | null;
};

type MessageThreadFragment_MessageThread_Fragment = {
  __typename?: "MessageThread";
  uid: string;
  isRead: boolean;
  matchedSearchResults: Array<{
    __typename?: "ConversationSearchResult";
    threadUid: string;
    highlightedMatch: string;
  }> | null;
  participants: Array<{
    __typename?: "Participant";
    avatar: string | null;
    uid: string;
    name: string | null;
    photo: string | null;
    timeZone: string | null;
    headline: string | null;
    leader_link: string | null;
    isLearner: boolean;
    role: ParticipantRole | null;
    activitiesWithCurrentUser: Array<{
      __typename?: "Activity";
      uid: string;
    }> | null;
    parent: {
      __typename?: "Parent";
      uid: string;
      photo: string | null;
      publicName: string | null;
    } | null;
    ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
  }>;
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    titleTranslated: string | null;
  } | null;
  messages: Array<{
    __typename?: "Message";
    created_at: any;
    uid: string;
    activity_uid: string | null;
    section_uid: string | null;
    thread_uid: string;
    subject: string | null;
    content: string | null;
    sender: {
      __typename?: "Sender";
      uid: string;
      name: string | null;
      photo: string | null;
      timeZone: string | null;
      headline: string | null;
      leader_link: string | null;
    };
    participants: Array<{
      __typename?: "Participant";
      isLearner: boolean;
      avatar: string | null;
      uid: string;
      name: string | null;
      photo: string | null;
      timeZone: string | null;
      headline: string | null;
      leader_link: string | null;
      role: ParticipantRole | null;
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    }>;
  }>;
};

type MessageThreadFragment_PrivateClassThread_Fragment = {
  __typename?: "PrivateClassThread";
  uid: string;
  isRead: boolean;
  matchedSearchResults: Array<{
    __typename?: "ConversationSearchResult";
    threadUid: string;
    highlightedMatch: string;
  }> | null;
  participants: Array<{
    __typename?: "Participant";
    avatar: string | null;
    uid: string;
    name: string | null;
    photo: string | null;
    timeZone: string | null;
    headline: string | null;
    leader_link: string | null;
    isLearner: boolean;
    role: ParticipantRole | null;
    activitiesWithCurrentUser: Array<{
      __typename?: "Activity";
      uid: string;
    }> | null;
    parent: {
      __typename?: "Parent";
      uid: string;
      photo: string | null;
      publicName: string | null;
    } | null;
    ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
  }>;
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    titleTranslated: string | null;
  } | null;
  messages: Array<{
    __typename?: "PrivateClassMessage";
    sentAt: any;
    uid: string;
    activity_uid: string;
    section_uid: string;
    thread_uid: string;
    subject: string | null;
    content: string | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    participants: Array<{
      __typename?: "Participant";
      isLearner: boolean;
      avatar: string | null;
      uid: string;
      name: string | null;
      photo: string | null;
      timeZone: string | null;
      headline: string | null;
      leader_link: string | null;
      role: ParticipantRole | null;
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    }>;
  }>;
};

export type MessageThreadFragmentFragment =
  | MessageThreadFragment_MessageThread_Fragment
  | MessageThreadFragment_PrivateClassThread_Fragment;

export type ParentFragmentFragment = {
  __typename?: "Parent";
  uid: string;
  name: string | null;
  photo: string | null;
  publicName: string | null;
  introduction: string | null;
  created_at: any | null;
  deleted_at: any | null;
  timeZone: string | null;
  socialOptOut: boolean;
  consent_to_share_class_history: boolean | null;
  consent_to_share_favourites: boolean | null;
};

export type ParentLeaderFragmentFragment = {
  __typename?: "Parent";
  uid: string;
  name: string | null;
  photo: string | null;
  timeZone: string | null;
};

export type ParentAdminFragmentFragment = {
  __typename?: "Parent";
  uid: string;
  name: string | null;
  email: string | null;
  photo: string | null;
  timeZone: string | null;
};

export type PastTeachersQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type PastTeachersQuery = {
  __typename?: "Query";
  pastTeachers: Array<{
    __typename?: "Leader";
    uid: string;
    name: string | null;
    leader_link: string | null;
    created_at: any | null;
    photo: string | null;
    suspended_at: any | null;
    cityState: string | null;
    socialOptOut: boolean;
    is_crosslister: boolean | null;
    timeZone: string | null;
    hasAvailabilitySet: boolean;
    approved: boolean;
    hasBackgroundCheckExpired: boolean | null;
    reviewCount: number;
    averageActivityStarRating: number | null;
    hasListings: boolean;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    details: {
      __typename?: "LeaderDetails";
      about: string | null;
      headline: string | null;
      headlineTranslated: string | null;
      countryOfResidence: string | null;
    };
    ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    teacherForSellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
    } | null;
  }>;
};

export type PastTeachersForParentProfileQueryVariables = Exact<{
  uid: Scalars["ID"];
  profileLinkUid?: InputMaybe<Scalars["String"]>;
  publicView?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PastTeachersForParentProfileQuery = {
  __typename?: "Query";
  pastTeachersForParentProfile: Array<{
    __typename?: "Leader";
    uid: string;
    name: string | null;
    leader_link: string | null;
    created_at: any | null;
    photo: string | null;
    suspended_at: any | null;
    cityState: string | null;
    socialOptOut: boolean;
    is_crosslister: boolean | null;
    timeZone: string | null;
    hasAvailabilitySet: boolean;
    approved: boolean;
    hasBackgroundCheckExpired: boolean | null;
    reviewCount: number;
    averageActivityStarRating: number | null;
    hasListings: boolean;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    details: {
      __typename?: "LeaderDetails";
      about: string | null;
      headline: string | null;
      headlineTranslated: string | null;
      countryOfResidence: string | null;
    };
    ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    teacherForSellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
    } | null;
  }>;
};

export type SettleOrderMutationVariables = Exact<{
  orderSlugId: Scalars["ID"];
  bulkPurchaseRecurringMeetingsInfo?: InputMaybe<
    Array<BulkPurchaseRecurringMeetingsInfo> | BulkPurchaseRecurringMeetingsInfo
  >;
  prepaidPurchaseStartDate?: InputMaybe<Scalars["DateTime"]>;
}>;

export type SettleOrderMutation = {
  __typename?: "Mutation";
  settleOrder: {
    __typename?: "Order";
    orderSlugId: string;
    isFirstPurchaseByUser: boolean;
    enrollments: Array<{
      __typename?: "Enrollment";
      isFirstPurchaseByUser: boolean;
      uid: string;
      section_uid: string | null;
      price_cents: number | null;
      priceCents: number | null;
      created_at: any | null;
      confirmed_at: any | null;
      confirmedAt: any | null;
      withdrawn_at: any | null;
      ongoing_stopped_at: any | null;
      currency_code: CurrencyCode;
      exchange_rate: number;
      amount_in_local_currency: number;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      withdrawnAt: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      isPrepay: boolean;
      refundableAmountCents: number;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      usersCanCommunicate: boolean;
      purchasedAsGift: boolean;
      pricingOffer: {
        __typename?: "PricingOffer";
        uid: string;
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
      payments: Array<{
        __typename?: "EnrollmentPayment";
        uid: string;
        amount_cents: number | null;
        order_slug_id: string | null;
        stripe_charge_id: string | null;
        purchase_order_id: string | null;
        esa_order_id: string | null;
        intercom_url: string | null;
        last4: string | null;
        created_at: any | null;
        paid_at: any | null;
        disbursed_at: any | null;
        payout_uid: string | null;
        start_time: any | null;
        end_time: any | null;
        unrefundedCents: number;
        isOngoingRefundable: boolean;
        voucher_name: string | null;
        pricing_offer_uid: string | null;
        credit_transaction_uid: string | null;
        coupon_code_uid: string | null;
        currency_code: CurrencyCode | null;
        exchange_rate: number | null;
        invoice_uid: string | null;
        funding_program_invoice_uid: string | null;
        service_fee_percentage: number | null;
        couponCode: {
          __typename?: "CouponCode";
          code: string;
          coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
        } | null;
        pricingOffer: {
          __typename?: "PricingOffer";
          name: string;
          buyerOrgName: string | null;
          isFinancialAid: boolean;
        } | null;
      }>;
      refundPolicy: {
        __typename?: "RefundPolicy";
        type: RefundPolicyType;
        name: string;
        description: string;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      siblings: Array<{
        __typename?: "Sibling";
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
          age: number | null;
          avatar: string | null;
          pronoun: string | null;
          intro: string | null;
          deleted_at: any | null;
          isVerified: boolean;
          isBlocked: boolean | null;
          blockedByUserUid: string | null;
          blockedAt: any | null;
        };
        currentOrLastSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          uid: string;
          activity_uid: string;
          start_time: any | null;
          end_time: any | null;
          startsAtNight: boolean | null;
          is_cross_listed: boolean;
          size_max: number | null;
          size_min: number | null;
          isPublished: boolean | null;
          price_cents: number | null;
          priceCredits: number | null;
          published_at: any | null;
          updated_at: any;
          canceled_at: any | null;
          deleted_at: any | null;
          ongoing_stopped_at: any | null;
          duration_minutes: number | null;
          usesOutschoolVideoChat: boolean;
          usersCanSendPrivateMessage: boolean;
          currentUserCanManage: boolean;
          isBuyable: boolean;
          allowsTeacherTransfer: boolean;
          nextOngoingMeeting: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
            deleted_at: any | null;
            created_at: any | null;
          } | null;
          leader: { __typename?: "Leader"; uid: string; name: string | null };
          details: {
            __typename?: "SectionDetails";
            autoScheduledDraft: boolean | null;
          };
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
        };
      }>;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
        endTime: any | null;
        scheduleType: PaymentScheduleTypes;
        amountCents: number | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
    }>;
    enrollmentMeetingPurchase: {
      __typename?: "EnrollmentMeetingPurchase";
      uid: string;
      activityUid: string | null;
      enrollmentUid: string | null;
      meetingUid: string | null;
      orderSlugId: string;
      confirmedAt: any | null;
    } | null;
    giftEnrollments: Array<{
      __typename?: "GiftEnrollment";
      uid: string;
      acceptGiftSlugId: string;
      price_cents: number | null;
      confirmedAt: any | null;
      currency_code: CurrencyCode;
      payments: Array<{
        __typename?: "EnrollmentPayment";
        uid: string;
        amount_cents: number | null;
        order_slug_id: string | null;
        stripe_charge_id: string | null;
        purchase_order_id: string | null;
        esa_order_id: string | null;
        intercom_url: string | null;
        last4: string | null;
        created_at: any | null;
        paid_at: any | null;
        disbursed_at: any | null;
        payout_uid: string | null;
        start_time: any | null;
        end_time: any | null;
        unrefundedCents: number;
        isOngoingRefundable: boolean;
        voucher_name: string | null;
        pricing_offer_uid: string | null;
        credit_transaction_uid: string | null;
        coupon_code_uid: string | null;
        currency_code: CurrencyCode | null;
        exchange_rate: number | null;
        invoice_uid: string | null;
        funding_program_invoice_uid: string | null;
        service_fee_percentage: number | null;
        couponCode: {
          __typename?: "CouponCode";
          code: string;
          coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
        } | null;
        pricingOffer: {
          __typename?: "PricingOffer";
          name: string;
          buyerOrgName: string | null;
          isFinancialAid: boolean;
        } | null;
      }>;
    }> | null;
    giftCard: {
      __typename?: "GiftCard";
      uid: string;
      orderSlugId: string;
      amountCents: number;
      redemptionCode: string | null;
    } | null;
  };
};

export type SettleOrderEnrollmentMeetingPurchaseMutationVariables = Exact<{
  orderSlugId: Scalars["ID"];
  purchaseRecurringMeetingsInfo?: InputMaybe<PurchaseRecurringMeetingsInfo>;
  meetingRequestUids?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type SettleOrderEnrollmentMeetingPurchaseMutation = {
  __typename?: "Mutation";
  settleOrderEnrollmentMeetingPurchase: {
    __typename?: "Order";
    orderSlugId: string;
    isFirstPurchaseByUser: boolean;
    enrollments: Array<{
      __typename?: "Enrollment";
      isFirstPurchaseByUser: boolean;
      uid: string;
      section_uid: string | null;
      price_cents: number | null;
      priceCents: number | null;
      created_at: any | null;
      confirmed_at: any | null;
      confirmedAt: any | null;
      withdrawn_at: any | null;
      ongoing_stopped_at: any | null;
      currency_code: CurrencyCode;
      exchange_rate: number;
      amount_in_local_currency: number;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      withdrawnAt: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      isPrepay: boolean;
      refundableAmountCents: number;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      usersCanCommunicate: boolean;
      purchasedAsGift: boolean;
      pricingOffer: {
        __typename?: "PricingOffer";
        uid: string;
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
      payments: Array<{
        __typename?: "EnrollmentPayment";
        uid: string;
        amount_cents: number | null;
        order_slug_id: string | null;
        stripe_charge_id: string | null;
        purchase_order_id: string | null;
        esa_order_id: string | null;
        intercom_url: string | null;
        last4: string | null;
        created_at: any | null;
        paid_at: any | null;
        disbursed_at: any | null;
        payout_uid: string | null;
        start_time: any | null;
        end_time: any | null;
        unrefundedCents: number;
        isOngoingRefundable: boolean;
        voucher_name: string | null;
        pricing_offer_uid: string | null;
        credit_transaction_uid: string | null;
        coupon_code_uid: string | null;
        currency_code: CurrencyCode | null;
        exchange_rate: number | null;
        invoice_uid: string | null;
        funding_program_invoice_uid: string | null;
        service_fee_percentage: number | null;
        couponCode: {
          __typename?: "CouponCode";
          code: string;
          coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
        } | null;
        pricingOffer: {
          __typename?: "PricingOffer";
          name: string;
          buyerOrgName: string | null;
          isFinancialAid: boolean;
        } | null;
      }>;
      refundPolicy: {
        __typename?: "RefundPolicy";
        type: RefundPolicyType;
        name: string;
        description: string;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      siblings: Array<{
        __typename?: "Sibling";
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
          age: number | null;
          avatar: string | null;
          pronoun: string | null;
          intro: string | null;
          deleted_at: any | null;
          isVerified: boolean;
          isBlocked: boolean | null;
          blockedByUserUid: string | null;
          blockedAt: any | null;
        };
        currentOrLastSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          uid: string;
          activity_uid: string;
          start_time: any | null;
          end_time: any | null;
          startsAtNight: boolean | null;
          is_cross_listed: boolean;
          size_max: number | null;
          size_min: number | null;
          isPublished: boolean | null;
          price_cents: number | null;
          priceCredits: number | null;
          published_at: any | null;
          updated_at: any;
          canceled_at: any | null;
          deleted_at: any | null;
          ongoing_stopped_at: any | null;
          duration_minutes: number | null;
          usesOutschoolVideoChat: boolean;
          usersCanSendPrivateMessage: boolean;
          currentUserCanManage: boolean;
          isBuyable: boolean;
          allowsTeacherTransfer: boolean;
          nextOngoingMeeting: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
            deleted_at: any | null;
            created_at: any | null;
          } | null;
          leader: { __typename?: "Leader"; uid: string; name: string | null };
          details: {
            __typename?: "SectionDetails";
            autoScheduledDraft: boolean | null;
          };
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
        };
      }>;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
        endTime: any | null;
        scheduleType: PaymentScheduleTypes;
        amountCents: number | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
    }>;
    enrollmentMeetingPurchase: {
      __typename?: "EnrollmentMeetingPurchase";
      uid: string;
      activityUid: string | null;
      enrollmentUid: string | null;
      meetingUid: string | null;
      orderSlugId: string;
      confirmedAt: any | null;
    } | null;
  };
};

export type AcceptPaymentWithStripeMutationVariables = Exact<{
  orderSlugId: Scalars["ID"];
  amountCents: Scalars["Int"];
  orderPaymentUid?: InputMaybe<Scalars["ID"]>;
  charterSchoolUid?: InputMaybe<Scalars["ID"]>;
  purchaserName?: InputMaybe<Scalars["String"]>;
  purchaserEmail?: InputMaybe<Scalars["String"]>;
  exchangeRate?: InputMaybe<Scalars["Float"]>;
  currencyCode?: InputMaybe<CurrencyCode>;
  savePaymentMethod?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AcceptPaymentWithStripeMutation = {
  __typename?: "Mutation";
  acceptPaymentWithStripe: {
    __typename?: "StripePaymentIntent";
    paymentIntentClientId: string;
    orderPaymentUid: string;
  };
};

export type AcceptPaymentWithPricingOfferMutationVariables = Exact<{
  orderSlugId: Scalars["ID"];
  amountCents: Scalars["Int"];
  pricingOfferUid: Scalars["ID"];
  exchangeRate?: InputMaybe<Scalars["Float"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
}>;

export type AcceptPaymentWithPricingOfferMutation = {
  __typename?: "Mutation";
  acceptPaymentWithPricingOffer: boolean;
};

export type AcceptPaymentWithPurchaseOrderMutationVariables = Exact<{
  orderSlugId: Scalars["ID"];
  amountCents: Scalars["Int"];
  charterSchoolUid: Scalars["String"];
  intercomUrl: Scalars["String"];
  purchaseOrderId: Scalars["String"];
}>;

export type AcceptPaymentWithPurchaseOrderMutation = {
  __typename?: "Mutation";
  acceptPaymentWithPurchaseOrder: boolean;
};

export type AcceptPaymentWithCouponCodeMutationVariables = Exact<{
  orderSlugId: Scalars["ID"];
  activityUid: Scalars["ID"];
  amountCents: Scalars["Int"];
  code: Scalars["String"];
  currencyCode?: InputMaybe<CurrencyCode>;
  exchangeRate?: InputMaybe<Scalars["Float"]>;
}>;

export type AcceptPaymentWithCouponCodeMutation = {
  __typename?: "Mutation";
  acceptPaymentWithCouponCode: CouponState;
};

export type AcceptPaymentWithCouponCodeForBulkCheckoutMutationVariables =
  Exact<{
    orderSlugId: Scalars["ID"];
    couponOrderInputs: Array<CouponOrderInput> | CouponOrderInput;
    amountCents: Scalars["Int"];
    code: Scalars["String"];
    currencyCode?: InputMaybe<CurrencyCode>;
    exchangeRate?: InputMaybe<Scalars["Float"]>;
  }>;

export type AcceptPaymentWithCouponCodeForBulkCheckoutMutation = {
  __typename?: "Mutation";
  acceptPaymentWithCouponCode: CouponState;
};

export type AcceptPaymentWithClassWalletMutationVariables = Exact<{
  orderSlugId: Scalars["ID"];
  amountCents: Scalars["Int"];
}>;

export type AcceptPaymentWithClassWalletMutation = {
  __typename?: "Mutation";
  acceptPaymentWithClassWallet: boolean;
};

export type AcceptPaymentWithFundingProgramInvoiceMutationVariables = Exact<{
  orderSlugId: Scalars["ID"];
  amountCents: Scalars["Int"];
  userDetails: FundingProgramInvoiceInput;
}>;

export type AcceptPaymentWithFundingProgramInvoiceMutation = {
  __typename?: "Mutation";
  acceptPaymentWithFundingProgramInvoice: boolean;
};

export type CancelOrderMutationMutationVariables = Exact<{
  orderSlugId: Scalars["String"];
}>;

export type CancelOrderMutationMutation = {
  __typename?: "Mutation";
  cancelOrder: boolean;
};

export type TextFeedbackByUserQueryVariables = Exact<{
  userUid: Scalars["ID"];
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type TextFeedbackByUserQuery = {
  __typename?: "Query";
  textFeedbackByUser: Array<{
    __typename?: "PublicReview";
    uid: string;
    activity_star_rating: number | null;
    created_at: any;
    details: {
      __typename?: "PublicReviewDetails";
      publicReview: string | null;
    } | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      isClub: boolean;
      slug_id: string;
      title: string;
      titleTranslated: string | null;
    } | null;
  }>;
};

export type PubliclyReviewedEnrollmentHistoryQueryVariables = Exact<{
  uid: Scalars["ID"];
  offset?: InputMaybe<Scalars["Int"]>;
  skipRating: Scalars["Boolean"];
}>;

export type PubliclyReviewedEnrollmentHistoryQuery = {
  __typename?: "Query";
  publiclyReviewedPastEnrollments: Array<{
    __typename?: "Listing";
    insertionId: string | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      user_uid: string;
      title: string;
      url: string;
      path: string;
      summary: string | null;
      summaryTranslated: string | null;
      weekly_meetings: number | null;
      duration_weeks: number | null;
      is_ongoing_weekly: boolean;
      isClub: boolean;
      published_at: any | null;
      size_max: number | null;
      size_min: number | null;
      hasTeacherSchedule: boolean;
      allows_recurring_payment: boolean | null;
      averageStarRating?: number | null;
      reviewCount: number;
      age_min: number | null;
      age_max: number | null;
      duration_minutes: number | null;
      price_cents: number | null;
      priceCreditsPerMeeting: number | null;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      canEnroll: boolean;
      isOneTime: boolean;
      titleTranslated: string | null;
      slug_id: string;
      subject: string | null;
      syllabus: {
        __typename?: "Syllabus";
        uid: string;
        lessons: Array<{ __typename?: "Lesson"; uid: string }>;
      } | null;
      clubSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        clubPostCount: number;
      } | null;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      savedClassLists: Array<{
        __typename?: "SavedClassList";
        uid: string;
        lastAddedToAt: any | null;
      }>;
    };
    nextSection: {
      __typename?: "Section";
      uid: string;
      size_max: number | null;
      filledSpaceCount: number;
      start_time: any | null;
      end_time: any | null;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      averageActivityStarRating: number | null;
      reviewCount: number;
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    };
  } | null>;
};

export type ReceiptQueryQueryVariables = Exact<{
  orderSlugId: Scalars["ID"];
}>;

export type ReceiptQueryQuery = {
  __typename?: "Query";
  order: {
    __typename?: "Order";
    orderSlugId: string;
    exchangeRate: number | null;
    currencyCode: CurrencyCode | null;
    totalAmountCents: number;
    subscriptionCreditsApplied: number;
    otherTendersApplied: number;
    payments: Array<{
      __typename?: "OrderPayment";
      uid: string;
      order_slug_id: string | null;
      tender_type: string | null;
      amount_cents: number;
    }>;
    organization: { __typename?: "Organization"; name: string } | null;
    enrollments: Array<{
      __typename?: "Enrollment";
      hasPaymentSchedule: boolean;
      service_fee_percentage: number;
      uid: string;
      section_uid: string | null;
      price_cents: number | null;
      priceCents: number | null;
      created_at: any | null;
      confirmed_at: any | null;
      confirmedAt: any | null;
      withdrawn_at: any | null;
      ongoing_stopped_at: any | null;
      currency_code: CurrencyCode;
      exchange_rate: number;
      amount_in_local_currency: number;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      withdrawnAt: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      isPrepay: boolean;
      refundableAmountCents: number;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      usersCanCommunicate: boolean;
      purchasedAsGift: boolean;
      payments: Array<{
        __typename?: "EnrollmentPayment";
        uid: string;
        amount_cents: number | null;
        amount_credits: number | null;
        order_slug_id: string | null;
        stripe_charge_id: string | null;
        purchase_order_id: string | null;
        pricing_offer_uid: string | null;
        esa_order_id: string | null;
        invoice_uid: string | null;
        funding_program_invoice_uid: string | null;
        intercom_url: string | null;
        last4: string | null;
        created_at: any | null;
        paid_at: any | null;
        disbursed_at: any | null;
        payout_uid: string | null;
        start_time: any | null;
        end_time: any | null;
        unrefundedCents: number;
        isOngoingRefundable: boolean;
        voucher_name: string | null;
        credit_transaction_uid: string | null;
        credit_account_type: string | null;
        service_fee_percentage: number | null;
        coupon_code_uid: string | null;
        currency_code: CurrencyCode | null;
        exchange_rate: number | null;
        stripeReceiptUrl: string | null;
        stripePaymentMethod: string | null;
        organization: {
          __typename?: "Organization";
          uid: string;
          name: string;
          state: string | null;
          logoUrl: string | null;
          urlName: string | null;
        } | null;
        refunds: Array<{
          __typename?: "EnrollmentPaymentRefund";
          uid: string;
          user_uid: string;
          amount_cents: number;
          amount_credits: number | null;
          refunded_at: any | null;
          reason: string | null;
          user_role: string | null;
          note: string | null;
          reduces_teacher_payout: boolean;
          amount_in_local_currency: number | null;
          currency_code: CurrencyCode | null;
          exchange_rate: number | null;
          addOutschoolCreditTransaction: {
            __typename?: "CreditTransaction";
            uid: string;
            amount_cents: number;
            expiresAt: any | null;
            unspentCents: number | null;
          } | null;
        }>;
        couponCode: {
          __typename?: "CouponCode";
          code: string;
          coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
        } | null;
        pricingOffer: {
          __typename?: "PricingOffer";
          name: string;
          buyerOrgName: string | null;
          isFinancialAid: boolean;
        } | null;
      }>;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      section: {
        __typename?: "Section";
        filledSpaceCount: number;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        activity: {
          __typename?: "Activity";
          uid: string;
          slug_id: string;
          user_uid: string;
          title: string;
          titleTranslated: string | null;
          url: string;
          path: string;
          class_experience: string | null;
          learning_goals: string | null;
          learningGoals: Array<string>;
          assessment: string | null;
          homework: string | null;
          grading_policy: string | null;
          prerequisites: string | null;
          published_at: any | null;
          is_online: boolean;
          age_min: number | null;
          age_max: number | null;
          english_proficiency_level: EnglishProficiencyLevel | null;
          grade_level_min: string | null;
          grade_level_max: string | null;
          size_min: number | null;
          size_max: number | null;
          duration_minutes: number | null;
          duration_weeks: number | null;
          weekly_meetings: number | null;
          price_cents: number | null;
          priceCredits: number | null;
          summary: string | null;
          summaryTranslated: string | null;
          is_ongoing_weekly: boolean;
          subject: string | null;
          languageOfInstruction: any | null;
          summaryForLearners: string | null;
          allowWaitlist: boolean | null;
          isClub: boolean;
          isAutoSchedulingEnabled: boolean | null;
          autoSchedulingDaysNoticeMin: number | null;
          autoSchedulingDaysNoticeMax: number | null;
          allows_recurring_payment: boolean | null;
          allows_late_enrollments_for_fixed_length_classes: boolean;
          has_building_content: boolean | null;
          hasTeacherSchedule: boolean;
          isFlexSchedule: boolean;
          isSelfPaced: boolean;
          isOneTime: boolean;
          classFormat: EducatorSelectedFormat;
          classroomType: ClassroomType;
          strictAgeRange: {
            __typename?: "AgeRangeType";
            min: number;
            max: number;
          } | null;
          proficiency_level: {
            __typename?: "ProficiencyLevel";
            level: Array<string | null> | null;
            text: string | null;
          } | null;
          refundPolicy: {
            __typename?: "RefundPolicy";
            name: string;
            description: string;
            type: RefundPolicyType;
          };
          details: {
            __typename?: "ActivityDetails";
            photo: string | null;
            photoSquare: string | null;
          };
        };
        meetings: Array<{
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        }>;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
      } | null;
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        type: RefundPolicyType;
        name: string;
        description: string;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      } | null;
      siblings: Array<{
        __typename?: "Sibling";
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
          age: number | null;
          avatar: string | null;
          pronoun: string | null;
          intro: string | null;
          deleted_at: any | null;
          isVerified: boolean;
          isBlocked: boolean | null;
          blockedByUserUid: string | null;
          blockedAt: any | null;
        };
        currentOrLastSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          uid: string;
          activity_uid: string;
          start_time: any | null;
          end_time: any | null;
          startsAtNight: boolean | null;
          is_cross_listed: boolean;
          size_max: number | null;
          size_min: number | null;
          isPublished: boolean | null;
          price_cents: number | null;
          priceCredits: number | null;
          published_at: any | null;
          updated_at: any;
          canceled_at: any | null;
          deleted_at: any | null;
          ongoing_stopped_at: any | null;
          duration_minutes: number | null;
          usesOutschoolVideoChat: boolean;
          usersCanSendPrivateMessage: boolean;
          currentUserCanManage: boolean;
          isBuyable: boolean;
          allowsTeacherTransfer: boolean;
          nextOngoingMeeting: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
            deleted_at: any | null;
            created_at: any | null;
          } | null;
          leader: { __typename?: "Leader"; uid: string; name: string | null };
          details: {
            __typename?: "SectionDetails";
            autoScheduledDraft: boolean | null;
          };
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
        };
      }>;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
        endTime: any | null;
        scheduleType: PaymentScheduleTypes;
        amountCents: number | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
    }>;
    enrollmentMeetingPurchase: {
      __typename?: "EnrollmentMeetingPurchase";
      uid: string;
      activityUid: string | null;
      enrollmentUid: string | null;
      meetingUid: string | null;
      orderSlugId: string;
      confirmedAt: any | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        slug_id: string;
        user_uid: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        class_experience: string | null;
        learning_goals: string | null;
        learningGoals: Array<string>;
        assessment: string | null;
        homework: string | null;
        grading_policy: string | null;
        prerequisites: string | null;
        published_at: any | null;
        is_online: boolean;
        age_min: number | null;
        age_max: number | null;
        english_proficiency_level: EnglishProficiencyLevel | null;
        grade_level_min: string | null;
        grade_level_max: string | null;
        size_min: number | null;
        size_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        priceCredits: number | null;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        languageOfInstruction: any | null;
        summaryForLearners: string | null;
        allowWaitlist: boolean | null;
        isClub: boolean;
        isAutoSchedulingEnabled: boolean | null;
        autoSchedulingDaysNoticeMin: number | null;
        autoSchedulingDaysNoticeMax: number | null;
        allows_recurring_payment: boolean | null;
        allows_late_enrollments_for_fixed_length_classes: boolean;
        has_building_content: boolean | null;
        hasTeacherSchedule: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        classFormat: EducatorSelectedFormat;
        classroomType: ClassroomType;
        strictAgeRange: {
          __typename?: "AgeRangeType";
          min: number;
          max: number;
        } | null;
        proficiency_level: {
          __typename?: "ProficiencyLevel";
          level: Array<string | null> | null;
          text: string | null;
        } | null;
        refundPolicy: {
          __typename?: "RefundPolicy";
          name: string;
          description: string;
          type: RefundPolicyType;
        };
        details: {
          __typename?: "ActivityDetails";
          photo: string | null;
          photoSquare: string | null;
        };
      } | null;
    } | null;
    enrollmentMeetingPurchases: Array<{
      __typename?: "EnrollmentMeetingPurchase";
      uid: string;
      activityUid: string | null;
      enrollmentUid: string | null;
      meetingUid: string | null;
      orderSlugId: string;
      confirmedAt: any | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        slug_id: string;
        user_uid: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        class_experience: string | null;
        learning_goals: string | null;
        learningGoals: Array<string>;
        assessment: string | null;
        homework: string | null;
        grading_policy: string | null;
        prerequisites: string | null;
        published_at: any | null;
        is_online: boolean;
        age_min: number | null;
        age_max: number | null;
        english_proficiency_level: EnglishProficiencyLevel | null;
        grade_level_min: string | null;
        grade_level_max: string | null;
        size_min: number | null;
        size_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        priceCredits: number | null;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        languageOfInstruction: any | null;
        summaryForLearners: string | null;
        allowWaitlist: boolean | null;
        isClub: boolean;
        isAutoSchedulingEnabled: boolean | null;
        autoSchedulingDaysNoticeMin: number | null;
        autoSchedulingDaysNoticeMax: number | null;
        allows_recurring_payment: boolean | null;
        allows_late_enrollments_for_fixed_length_classes: boolean;
        has_building_content: boolean | null;
        hasTeacherSchedule: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        classFormat: EducatorSelectedFormat;
        classroomType: ClassroomType;
        strictAgeRange: {
          __typename?: "AgeRangeType";
          min: number;
          max: number;
        } | null;
        proficiency_level: {
          __typename?: "ProficiencyLevel";
          level: Array<string | null> | null;
          text: string | null;
        } | null;
        refundPolicy: {
          __typename?: "RefundPolicy";
          name: string;
          description: string;
          type: RefundPolicyType;
        };
        details: {
          __typename?: "ActivityDetails";
          photo: string | null;
          photoSquare: string | null;
        };
      } | null;
    }> | null;
    giftEnrollments: Array<{
      __typename?: "GiftEnrollment";
      withdrawnAt: any | null;
      uid: string;
      giftClaimedAt: any | null;
      createdAt: any;
      confirmedAt: any | null;
      withdrawn_at: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      ongoing_stopped_at: any | null;
      currency_code: CurrencyCode;
      exchange_rate: number;
      amount_in_local_currency: number;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      hasPaymentSchedule: boolean;
      isEnded: boolean;
      isPaused: boolean;
      orderSlugId: string | null;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      priceCents: number | null;
      currentUserIsPurchaser: boolean;
      refundableAmountCents: number;
      payments: Array<{
        __typename?: "EnrollmentPayment";
        uid: string;
        amount_cents: number | null;
        amount_credits: number | null;
        order_slug_id: string | null;
        stripe_charge_id: string | null;
        purchase_order_id: string | null;
        pricing_offer_uid: string | null;
        esa_order_id: string | null;
        invoice_uid: string | null;
        funding_program_invoice_uid: string | null;
        intercom_url: string | null;
        last4: string | null;
        created_at: any | null;
        paid_at: any | null;
        disbursed_at: any | null;
        payout_uid: string | null;
        start_time: any | null;
        end_time: any | null;
        unrefundedCents: number;
        isOngoingRefundable: boolean;
        voucher_name: string | null;
        credit_transaction_uid: string | null;
        credit_account_type: string | null;
        service_fee_percentage: number | null;
        coupon_code_uid: string | null;
        currency_code: CurrencyCode | null;
        exchange_rate: number | null;
        stripeReceiptUrl: string | null;
        stripePaymentMethod: string | null;
        organization: {
          __typename?: "Organization";
          uid: string;
          name: string;
          state: string | null;
          logoUrl: string | null;
          urlName: string | null;
        } | null;
        refunds: Array<{
          __typename?: "EnrollmentPaymentRefund";
          uid: string;
          user_uid: string;
          amount_cents: number;
          amount_credits: number | null;
          refunded_at: any | null;
          reason: string | null;
          user_role: string | null;
          note: string | null;
          reduces_teacher_payout: boolean;
          amount_in_local_currency: number | null;
          currency_code: CurrencyCode | null;
          exchange_rate: number | null;
          addOutschoolCreditTransaction: {
            __typename?: "CreditTransaction";
            uid: string;
            amount_cents: number;
            expiresAt: any | null;
            unspentCents: number | null;
          } | null;
        }>;
        couponCode: {
          __typename?: "CouponCode";
          code: string;
          coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
        } | null;
        pricingOffer: {
          __typename?: "PricingOffer";
          name: string;
          buyerOrgName: string | null;
          isFinancialAid: boolean;
        } | null;
      }>;
      section: {
        __typename?: "Section";
        filledSpaceCount: number;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        activity: {
          __typename?: "Activity";
          uid: string;
          slug_id: string;
          user_uid: string;
          title: string;
          titleTranslated: string | null;
          url: string;
          path: string;
          class_experience: string | null;
          learning_goals: string | null;
          learningGoals: Array<string>;
          assessment: string | null;
          homework: string | null;
          grading_policy: string | null;
          prerequisites: string | null;
          published_at: any | null;
          is_online: boolean;
          age_min: number | null;
          age_max: number | null;
          english_proficiency_level: EnglishProficiencyLevel | null;
          grade_level_min: string | null;
          grade_level_max: string | null;
          size_min: number | null;
          size_max: number | null;
          duration_minutes: number | null;
          duration_weeks: number | null;
          weekly_meetings: number | null;
          price_cents: number | null;
          priceCredits: number | null;
          summary: string | null;
          summaryTranslated: string | null;
          is_ongoing_weekly: boolean;
          subject: string | null;
          languageOfInstruction: any | null;
          summaryForLearners: string | null;
          allowWaitlist: boolean | null;
          isClub: boolean;
          isAutoSchedulingEnabled: boolean | null;
          autoSchedulingDaysNoticeMin: number | null;
          autoSchedulingDaysNoticeMax: number | null;
          allows_recurring_payment: boolean | null;
          allows_late_enrollments_for_fixed_length_classes: boolean;
          has_building_content: boolean | null;
          hasTeacherSchedule: boolean;
          isFlexSchedule: boolean;
          isSelfPaced: boolean;
          isOneTime: boolean;
          classFormat: EducatorSelectedFormat;
          classroomType: ClassroomType;
          strictAgeRange: {
            __typename?: "AgeRangeType";
            min: number;
            max: number;
          } | null;
          proficiency_level: {
            __typename?: "ProficiencyLevel";
            level: Array<string | null> | null;
            text: string | null;
          } | null;
          refundPolicy: {
            __typename?: "RefundPolicy";
            name: string;
            description: string;
            type: RefundPolicyType;
          };
          details: {
            __typename?: "ActivityDetails";
            photo: string | null;
            photoSquare: string | null;
          };
        };
        meetings: Array<{
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        }>;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
      };
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      refundPolicy: {
        __typename?: "RefundPolicy";
        type: RefundPolicyType;
        name: string;
        description: string;
      } | null;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
        endTime: any | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
    }> | null;
    donation: {
      __typename?: "Donation";
      orderSlugId: string;
      amountCents: number;
      confirmedAt: any | null;
    } | null;
    giftCard: {
      __typename?: "GiftCard";
      orderSlugId: string;
      amountCents: number;
      confirmedAt: any | null;
    } | null;
  };
};

export type DeleteClassRecordingMutationVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type DeleteClassRecordingMutation = {
  __typename?: "Mutation";
  deleteClassRecording: boolean;
};

export type SendReferralNotificationsMutationVariables = Exact<{
  recipientEmails: Array<Scalars["String"]> | Scalars["String"];
  isTeacherReferral: Scalars["Boolean"];
}>;

export type SendReferralNotificationsMutation = {
  __typename?: "Mutation";
  sendReferralNotifications: boolean;
};

export type SampleClubPostFragmentFragment = {
  __typename?: "SampleClubPost";
  text: string | null;
  attachments: Array<{
    __typename?: "Attachment";
    uid: string;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
    };
  }> | null;
  video: {
    __typename?: "Attachment";
    uid: string;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
    };
  } | null;
  comments: Array<{
    __typename?: "SampleClubPostComment";
    text: string | null;
  }>;
};

export type UpdatePrivacyStatusMutationVariables = Exact<{
  listUid: Scalars["ID"];
  privacyStatus: SavedClassListStatus;
}>;

export type UpdatePrivacyStatusMutation = {
  __typename?: "Mutation";
  updatePrivacyStatus: {
    __typename?: "SavedClassList";
    uid: string;
    privacyStatus: SavedClassListStatus;
  };
};

export type SectionFragmentFragment = {
  __typename?: "Section";
  uid: string;
  activity_uid: string;
  start_time: any | null;
  end_time: any | null;
  startsAtNight: boolean | null;
  is_cross_listed: boolean;
  size_max: number | null;
  size_min: number | null;
  isPublished: boolean | null;
  price_cents: number | null;
  priceCredits: number | null;
  published_at: any | null;
  updated_at: any;
  canceled_at: any | null;
  deleted_at: any | null;
  ongoing_stopped_at: any | null;
  duration_minutes: number | null;
  usesOutschoolVideoChat: boolean;
  usersCanSendPrivateMessage: boolean;
  currentUserCanManage: boolean;
  isBuyable: boolean;
  allowsTeacherTransfer: boolean;
  nextOngoingMeeting: {
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
  } | null;
  leader: { __typename?: "Leader"; uid: string; name: string | null };
  details: {
    __typename?: "SectionDetails";
    autoScheduledDraft: boolean | null;
  };
  prepaidOngoingPeriodOptions: Array<{
    __typename?: "Period";
    startOfIsoWeek: any;
    endOfIsoWeek: any;
  }> | null;
};

export type SectionEnrolledFragmentFragment = {
  __typename?: "Section";
  details: {
    __typename?: "SectionDetails";
    onlineClassroom: {
      __typename?: "OnlineClassroom";
      type: string | null;
      url: string | null;
    } | null;
  };
};

export type SectionLeaderFragmentFragment = {
  __typename?: "Section";
  created_at: any;
  validEnrollmentCount: number;
  isAutoCanceled: boolean;
  currentUserCanManage: boolean;
  currentUserIsOwner: boolean;
  paidAmount: number | null;
  details: {
    __typename?: "SectionDetails";
    onlineClassroom: {
      __typename?: "OnlineClassroom";
      type: string | null;
      url: string | null;
    } | null;
  };
  checklist: {
    __typename?: "SectionChecklist";
    autoCanceledAt: any | null;
    grandfatheredAt2017Rate: boolean | null;
  };
};

export type SectionAdminFragmentFragment = {
  __typename?: "Section";
  notes: string | null;
  standardPayoutCents: number;
  zoomMeeting: { __typename?: "ZoomMeeting"; zoom_id: string | null } | null;
};

export type CreateSectionMutationVariables = Exact<{
  activityUid: Scalars["ID"];
  attribution?: InputMaybe<Attribution>;
}>;

export type CreateSectionMutation = {
  __typename?: "Mutation";
  createSection: {
    __typename?: "Section";
    filledSpaceCount: number;
    uid: string;
    activity_uid: string;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_cross_listed: boolean;
    size_max: number | null;
    size_min: number | null;
    isPublished: boolean | null;
    price_cents: number | null;
    priceCredits: number | null;
    published_at: any | null;
    updated_at: any;
    canceled_at: any | null;
    deleted_at: any | null;
    ongoing_stopped_at: any | null;
    duration_minutes: number | null;
    usesOutschoolVideoChat: boolean;
    usersCanSendPrivateMessage: boolean;
    currentUserCanManage: boolean;
    isBuyable: boolean;
    allowsTeacherTransfer: boolean;
    created_at: any;
    validEnrollmentCount: number;
    isAutoCanceled: boolean;
    currentUserIsOwner: boolean;
    paidAmount: number | null;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    }>;
    activity: {
      __typename?: "Activity";
      uid: string;
      slug_id: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      class_experience: string | null;
      learning_goals: string | null;
      learningGoals: Array<string>;
      assessment: string | null;
      homework: string | null;
      grading_policy: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      grade_level_min: string | null;
      grade_level_max: string | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      priceCredits: number | null;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      allowWaitlist: boolean | null;
      isClub: boolean;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      allows_recurring_payment: boolean | null;
      allows_late_enrollments_for_fixed_length_classes: boolean;
      has_building_content: boolean | null;
      hasTeacherSchedule: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      classFormat: EducatorSelectedFormat;
      classroomType: ClassroomType;
      strictAgeRange: {
        __typename?: "AgeRangeType";
        min: number;
        max: number;
      } | null;
      proficiency_level: {
        __typename?: "ProficiencyLevel";
        level: Array<string | null> | null;
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
      };
      details: {
        __typename?: "ActivityDetails";
        photo: string | null;
        photoSquare: string | null;
      };
    };
    leader: {
      __typename?: "Leader";
      uid: string;
      is_crosslister: boolean | null;
      name: string | null;
    };
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    details: {
      __typename?: "SectionDetails";
      autoScheduledDraft: boolean | null;
      onlineClassroom: {
        __typename?: "OnlineClassroom";
        type: string | null;
        url: string | null;
      } | null;
    };
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
    checklist: {
      __typename?: "SectionChecklist";
      autoCanceledAt: any | null;
      grandfatheredAt2017Rate: boolean | null;
    };
  };
};

export type UpdateSectionMutationVariables = Exact<{
  uid: Scalars["ID"];
  updates?: InputMaybe<LeaderSectionInput>;
  details?: InputMaybe<LeaderSectionDetailInput>;
  onlineClassroom?: InputMaybe<OnlineClassroomInput>;
}>;

export type UpdateSectionMutation = {
  __typename?: "Mutation";
  updateSection: {
    __typename?: "Section";
    filledSpaceCount: number;
    uid: string;
    activity_uid: string;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_cross_listed: boolean;
    size_max: number | null;
    size_min: number | null;
    isPublished: boolean | null;
    price_cents: number | null;
    priceCredits: number | null;
    published_at: any | null;
    updated_at: any;
    canceled_at: any | null;
    deleted_at: any | null;
    ongoing_stopped_at: any | null;
    duration_minutes: number | null;
    usesOutschoolVideoChat: boolean;
    usersCanSendPrivateMessage: boolean;
    currentUserCanManage: boolean;
    isBuyable: boolean;
    allowsTeacherTransfer: boolean;
    created_at: any;
    validEnrollmentCount: number;
    isAutoCanceled: boolean;
    currentUserIsOwner: boolean;
    paidAmount: number | null;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    }>;
    leader: {
      __typename?: "Leader";
      uid: string;
      is_crosslister: boolean | null;
      name: string | null;
    };
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    details: {
      __typename?: "SectionDetails";
      autoScheduledDraft: boolean | null;
      onlineClassroom: {
        __typename?: "OnlineClassroom";
        type: string | null;
        url: string | null;
      } | null;
    };
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
    checklist: {
      __typename?: "SectionChecklist";
      autoCanceledAt: any | null;
      grandfatheredAt2017Rate: boolean | null;
    };
  };
};

export type StopOngoingSectionMutationMutationVariables = Exact<{
  sectionUid: Scalars["ID"];
  stopAfter?: InputMaybe<Scalars["DateTime"]>;
  cancellationNote?: InputMaybe<Scalars["String"]>;
  refundAmountCents?: InputMaybe<Scalars["Int"]>;
  reducesTeacherPayout?: InputMaybe<Scalars["Boolean"]>;
}>;

export type StopOngoingSectionMutationMutation = {
  __typename?: "Mutation";
  stopOngoingSection: {
    __typename?: "Section";
    filledSpaceCount: number;
    uid: string;
    activity_uid: string;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_cross_listed: boolean;
    size_max: number | null;
    size_min: number | null;
    isPublished: boolean | null;
    price_cents: number | null;
    priceCredits: number | null;
    published_at: any | null;
    updated_at: any;
    canceled_at: any | null;
    deleted_at: any | null;
    ongoing_stopped_at: any | null;
    duration_minutes: number | null;
    usesOutschoolVideoChat: boolean;
    usersCanSendPrivateMessage: boolean;
    currentUserCanManage: boolean;
    isBuyable: boolean;
    allowsTeacherTransfer: boolean;
    created_at: any;
    validEnrollmentCount: number;
    isAutoCanceled: boolean;
    currentUserIsOwner: boolean;
    paidAmount: number | null;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    }>;
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
    details: {
      __typename?: "SectionDetails";
      autoScheduledDraft: boolean | null;
      onlineClassroom: {
        __typename?: "OnlineClassroom";
        type: string | null;
        url: string | null;
      } | null;
    };
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
    checklist: {
      __typename?: "SectionChecklist";
      autoCanceledAt: any | null;
      grandfatheredAt2017Rate: boolean | null;
    };
  };
};

export type CancelSectionMutationMutationVariables = Exact<{
  uid: Scalars["ID"];
  cancellationNote?: InputMaybe<Scalars["String"]>;
  withdraw?: InputMaybe<Scalars["Boolean"]>;
  refundAmountCents?: InputMaybe<Scalars["Int"]>;
  reducesTeacherPayout?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CancelSectionMutationMutation = {
  __typename?: "Mutation";
  cancelSection: {
    __typename?: "Section";
    filledSpaceCount: number;
    uid: string;
    activity_uid: string;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_cross_listed: boolean;
    size_max: number | null;
    size_min: number | null;
    isPublished: boolean | null;
    price_cents: number | null;
    priceCredits: number | null;
    published_at: any | null;
    updated_at: any;
    canceled_at: any | null;
    deleted_at: any | null;
    ongoing_stopped_at: any | null;
    duration_minutes: number | null;
    usesOutschoolVideoChat: boolean;
    usersCanSendPrivateMessage: boolean;
    currentUserCanManage: boolean;
    isBuyable: boolean;
    allowsTeacherTransfer: boolean;
    created_at: any;
    validEnrollmentCount: number;
    isAutoCanceled: boolean;
    currentUserIsOwner: boolean;
    paidAmount: number | null;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    }>;
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
    details: {
      __typename?: "SectionDetails";
      autoScheduledDraft: boolean | null;
      onlineClassroom: {
        __typename?: "OnlineClassroom";
        type: string | null;
        url: string | null;
      } | null;
    };
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
    checklist: {
      __typename?: "SectionChecklist";
      autoCanceledAt: any | null;
      grandfatheredAt2017Rate: boolean | null;
    };
  };
};

export type RefundAllEnrollmentsInSectionMutationVariables = Exact<{
  uid: Scalars["ID"];
  cancellationNote?: InputMaybe<Scalars["String"]>;
  shouldWithdrawEnrollments?: InputMaybe<Scalars["Boolean"]>;
  refundAmountCents?: InputMaybe<Scalars["Int"]>;
  reducesTeacherPayout?: InputMaybe<Scalars["Boolean"]>;
}>;

export type RefundAllEnrollmentsInSectionMutation = {
  __typename?: "Mutation";
  refundAllEnrollmentsInSection: {
    __typename?: "Section";
    filledSpaceCount: number;
    uid: string;
    activity_uid: string;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_cross_listed: boolean;
    size_max: number | null;
    size_min: number | null;
    isPublished: boolean | null;
    price_cents: number | null;
    priceCredits: number | null;
    published_at: any | null;
    updated_at: any;
    canceled_at: any | null;
    deleted_at: any | null;
    ongoing_stopped_at: any | null;
    duration_minutes: number | null;
    usesOutschoolVideoChat: boolean;
    usersCanSendPrivateMessage: boolean;
    currentUserCanManage: boolean;
    isBuyable: boolean;
    allowsTeacherTransfer: boolean;
    created_at: any;
    validEnrollmentCount: number;
    isAutoCanceled: boolean;
    currentUserIsOwner: boolean;
    paidAmount: number | null;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    }>;
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
    details: {
      __typename?: "SectionDetails";
      autoScheduledDraft: boolean | null;
      onlineClassroom: {
        __typename?: "OnlineClassroom";
        type: string | null;
        url: string | null;
      } | null;
    };
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
    checklist: {
      __typename?: "SectionChecklist";
      autoCanceledAt: any | null;
      grandfatheredAt2017Rate: boolean | null;
    };
  };
};

export type GenerateProgressReportMutationVariables = Exact<{
  sectionUid: Scalars["ID"];
  notes: Scalars["String"];
  reportLength?: InputMaybe<ReportLength>;
}>;

export type GenerateProgressReportMutation = {
  __typename?: "Mutation";
  generateProgressReport: string;
};

export type SendProgressReportMutationVariables = Exact<{
  sectionUid: Scalars["ID"];
  learnerUid?: InputMaybe<Scalars["ID"]>;
  text: Scalars["String"];
  details: ProgressReportDetailsInput;
}>;

export type SendProgressReportMutation = {
  __typename?: "Mutation";
  sendProgressReport: Array<{
    __typename?: "Message";
    uid: string;
    subject: string | null;
    action: string | null;
    content: string | null;
    created_at: any;
    activity_uid: string | null;
    section_uid: string | null;
    thread_uid: string;
    sender: {
      __typename?: "Sender";
      uid: string;
      name: string | null;
      photo: string | null;
    };
    participants: Array<{
      __typename?: "Participant";
      uid: string;
      name: string | null;
      photo: string | null;
      timeZone: string | null;
      headline: string | null;
      leader_link: string | null;
      isLearner: boolean;
      role: ParticipantRole | null;
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    }>;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
  }>;
};

export type UpdateClassPostForPostManagementMutationVariables = Exact<{
  classPostUid: Scalars["ID"];
  messageContentInput?: InputMaybe<MessageContentInput>;
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  title?: InputMaybe<Scalars["String"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateClassPostForPostManagementMutation = {
  __typename?: "Mutation";
  updateClassPost: {
    __typename?: "ClassPost";
    uid: string;
    isWelcomePost: boolean;
    publishAt: any;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  };
};

export type DeleteClassPostForLessonMutationVariables = Exact<{
  classPostUid: Scalars["ID"];
}>;

export type DeleteClassPostForLessonMutation = {
  __typename?: "Mutation";
  deleteClassPostForLesson: boolean | null;
};

export type TeacherSectionsQueryVariables = Exact<{
  filter?: InputMaybe<Scalars["String"]>;
  excludeOrgTeachers?: InputMaybe<Scalars["Boolean"]>;
  filterByTeacher?: InputMaybe<Scalars["String"]>;
  sectionsLimit?: InputMaybe<Scalars["Int"]>;
  sectionsOffset?: InputMaybe<Scalars["Int"]>;
  activityType?: InputMaybe<CreateActivityType>;
}>;

export type TeacherSectionsQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    teacherAvailability: {
      __typename?: "Availability";
      updated_at: any | null;
      hourlyTimetable: {
        __typename?: "HourlyTimetable";
        mon: Array<number>;
        tue: Array<number>;
        wed: Array<number>;
        thu: Array<number>;
        fri: Array<number>;
        sat: Array<number>;
        sun: Array<number>;
      } | null;
    } | null;
    leaderSections: Array<{
      __typename?: "Section";
      filledSpaceCount: number;
      pausedLearnerCount: number;
      uid: string;
      activity_uid: string;
      start_time: any | null;
      end_time: any | null;
      startsAtNight: boolean | null;
      is_cross_listed: boolean;
      size_max: number | null;
      size_min: number | null;
      isPublished: boolean | null;
      price_cents: number | null;
      priceCredits: number | null;
      published_at: any | null;
      updated_at: any;
      canceled_at: any | null;
      deleted_at: any | null;
      ongoing_stopped_at: any | null;
      duration_minutes: number | null;
      usesOutschoolVideoChat: boolean;
      usersCanSendPrivateMessage: boolean;
      currentUserCanManage: boolean;
      isBuyable: boolean;
      allowsTeacherTransfer: boolean;
      created_at: any;
      validEnrollmentCount: number;
      isAutoCanceled: boolean;
      currentUserIsOwner: boolean;
      paidAmount: number | null;
      meetings: Array<{
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        deleted_at: any | null;
        created_at: any | null;
      }>;
      activity: {
        __typename?: "Activity";
        waitlistSeatsCount: number | null;
        uid: string;
        slug_id: string;
        user_uid: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        class_experience: string | null;
        learning_goals: string | null;
        learningGoals: Array<string>;
        assessment: string | null;
        homework: string | null;
        grading_policy: string | null;
        prerequisites: string | null;
        published_at: any | null;
        is_online: boolean;
        age_min: number | null;
        age_max: number | null;
        english_proficiency_level: EnglishProficiencyLevel | null;
        grade_level_min: string | null;
        grade_level_max: string | null;
        size_min: number | null;
        size_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        priceCredits: number | null;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        languageOfInstruction: any | null;
        summaryForLearners: string | null;
        allowWaitlist: boolean | null;
        isClub: boolean;
        isAutoSchedulingEnabled: boolean | null;
        autoSchedulingDaysNoticeMin: number | null;
        autoSchedulingDaysNoticeMax: number | null;
        allows_recurring_payment: boolean | null;
        allows_late_enrollments_for_fixed_length_classes: boolean;
        has_building_content: boolean | null;
        hasTeacherSchedule: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        classFormat: EducatorSelectedFormat;
        classroomType: ClassroomType;
        strictAgeRange: {
          __typename?: "AgeRangeType";
          min: number;
          max: number;
        } | null;
        proficiency_level: {
          __typename?: "ProficiencyLevel";
          level: Array<string | null> | null;
          text: string | null;
        } | null;
        refundPolicy: {
          __typename?: "RefundPolicy";
          name: string;
          description: string;
          type: RefundPolicyType;
        };
        details: {
          __typename?: "ActivityDetails";
          photo: string | null;
          photoSquare: string | null;
        };
      };
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        is_crosslister: boolean | null;
      };
      enrollments: Array<{
        __typename?: "Enrollment";
        uid: string;
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
        } | null;
      }>;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        deleted_at: any | null;
        created_at: any | null;
      } | null;
      details: {
        __typename?: "SectionDetails";
        autoScheduledDraft: boolean | null;
        onlineClassroom: {
          __typename?: "OnlineClassroom";
          type: string | null;
          url: string | null;
        } | null;
      };
      prepaidOngoingPeriodOptions: Array<{
        __typename?: "Period";
        startOfIsoWeek: any;
        endOfIsoWeek: any;
      }> | null;
      checklist: {
        __typename?: "SectionChecklist";
        autoCanceledAt: any | null;
        grandfatheredAt2017Rate: boolean | null;
      };
    }>;
  } | null;
};

export type TeacherSectionQueryLessonFragmentFragment = {
  __typename?: "Lesson";
  uid: string;
  title: string | null;
  week_number: number;
  order_within_week: number;
  lessonNumber: number | null;
};

export type TeacherSectionQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type TeacherSectionQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    waitlistSeatsCount: number | null;
    filledSpaceCount: number;
    validEnrollmentCount: number;
    canBeCanceled: boolean;
    uid: string;
    activity_uid: string;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_cross_listed: boolean;
    size_max: number | null;
    size_min: number | null;
    isPublished: boolean | null;
    price_cents: number | null;
    priceCredits: number | null;
    published_at: any | null;
    updated_at: any;
    canceled_at: any | null;
    deleted_at: any | null;
    ongoing_stopped_at: any | null;
    duration_minutes: number | null;
    usesOutschoolVideoChat: boolean;
    usersCanSendPrivateMessage: boolean;
    currentUserCanManage: boolean;
    isBuyable: boolean;
    allowsTeacherTransfer: boolean;
    created_at: any;
    isAutoCanceled: boolean;
    currentUserIsOwner: boolean;
    paidAmount: number | null;
    is_self_paced: boolean;
    details: {
      __typename?: "SectionDetails";
      originalEndTime: any | null;
      autoScheduledDraft: boolean | null;
      onlineClassroom: {
        __typename?: "OnlineClassroom";
        type: string | null;
        url: string | null;
      } | null;
    };
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
      holiday: { __typename?: "Holiday"; name: string } | null;
      lesson: {
        __typename?: "Lesson";
        uid: string;
        title: string | null;
        week_number: number;
        order_within_week: number;
        lessonNumber: number | null;
      } | null;
    }>;
    deletedMeetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
      holiday: { __typename?: "Holiday"; name: string } | null;
      lesson: {
        __typename?: "Lesson";
        uid: string;
        title: string | null;
        week_number: number;
        order_within_week: number;
        lessonNumber: number | null;
      } | null;
    }>;
    activity: {
      __typename?: "Activity";
      uid: string;
      meetingCount: number | null;
      waitlistSeatsCount: number | null;
      isClub: boolean;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      is_ongoing_weekly: boolean;
      weekly_meetings: number | null;
      duration_weeks: number | null;
      published_at: any | null;
      hasTeacherSchedule: boolean;
      slug_id: string;
      url: string;
      path: string;
      class_experience: string | null;
      learning_goals: string | null;
      learningGoals: Array<string>;
      assessment: string | null;
      homework: string | null;
      grading_policy: string | null;
      prerequisites: string | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      grade_level_min: string | null;
      grade_level_max: string | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      price_cents: number | null;
      priceCredits: number | null;
      summary: string | null;
      summaryTranslated: string | null;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      allowWaitlist: boolean | null;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      allows_recurring_payment: boolean | null;
      allows_late_enrollments_for_fixed_length_classes: boolean;
      has_building_content: boolean | null;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      classFormat: EducatorSelectedFormat;
      classroomType: ClassroomType;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      };
      strictAgeRange: {
        __typename?: "AgeRangeType";
        min: number;
        max: number;
      } | null;
      proficiency_level: {
        __typename?: "ProficiencyLevel";
        level: Array<string | null> | null;
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
      };
      details: {
        __typename?: "ActivityDetails";
        photo: string | null;
        photoSquare: string | null;
      };
    };
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      published_at: string | null;
      lessons: Array<{
        __typename?: "Lesson";
        uid: string;
        title: string | null;
        week_number: number;
        order_within_week: number;
        lessonNumber: number | null;
        meetings: Array<{
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
        }> | null;
      }>;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      is_crosslister: boolean | null;
    };
    enrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
      withdrawn_at: any | null;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      isPaused: boolean;
      isEnded: boolean;
      ongoingEndTime: any | null;
      withdrawnAt: any | null;
      isWithdrawn: boolean;
      learner: {
        __typename?: "Learner";
        uid: string;
        avatar: string | null;
        name: string | null;
        age: number | null;
        isBlocked: boolean | null;
        parent: {
          __typename?: "Parent";
          uid: string;
          name: string | null;
          timeZone: string | null;
        };
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        pauseReason: string;
      } | null;
    }>;
    meetingConflictInfo: Array<{
      __typename?: "SectionMeetingConflictInfo";
      meetingUid: string;
      message: string;
      conflictingSectionUid: string;
    }> | null;
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
    checklist: {
      __typename?: "SectionChecklist";
      autoCanceledAt: any | null;
      grandfatheredAt2017Rate: boolean | null;
    };
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
    }>;
  } | null;
};

export type SectionRecordingsPageQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type SectionRecordingsPageQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    activity_uid: string;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_cross_listed: boolean;
    size_max: number | null;
    size_min: number | null;
    isPublished: boolean | null;
    price_cents: number | null;
    priceCredits: number | null;
    published_at: any | null;
    updated_at: any;
    canceled_at: any | null;
    deleted_at: any | null;
    ongoing_stopped_at: any | null;
    duration_minutes: number | null;
    usesOutschoolVideoChat: boolean;
    usersCanSendPrivateMessage: boolean;
    currentUserCanManage: boolean;
    isBuyable: boolean;
    allowsTeacherTransfer: boolean;
    created_at: any;
    validEnrollmentCount: number;
    isAutoCanceled: boolean;
    currentUserIsOwner: boolean;
    paidAmount: number | null;
    is_self_paced: boolean;
    activity: {
      __typename?: "Activity";
      uid: string;
      slug_id: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      class_experience: string | null;
      learning_goals: string | null;
      learningGoals: Array<string>;
      assessment: string | null;
      homework: string | null;
      grading_policy: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      grade_level_min: string | null;
      grade_level_max: string | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      priceCredits: number | null;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      allowWaitlist: boolean | null;
      isClub: boolean;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      allows_recurring_payment: boolean | null;
      allows_late_enrollments_for_fixed_length_classes: boolean;
      has_building_content: boolean | null;
      hasTeacherSchedule: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      classFormat: EducatorSelectedFormat;
      classroomType: ClassroomType;
      strictAgeRange: {
        __typename?: "AgeRangeType";
        min: number;
        max: number;
      } | null;
      proficiency_level: {
        __typename?: "ProficiencyLevel";
        level: Array<string | null> | null;
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
      };
      details: {
        __typename?: "ActivityDetails";
        photo: string | null;
        photoSquare: string | null;
      };
    };
    feedbacks: Array<{
      __typename?: "FeedbackForLeader";
      uid: string;
      activity_star_rating: number | null;
      created_at: any;
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        timeZone: string | null;
      };
      details: {
        __typename?: "FeedbackForLeaderDetails";
        leaderFeedback: string | null;
      };
    }>;
    enrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
      } | null;
    }>;
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
    details: {
      __typename?: "SectionDetails";
      autoScheduledDraft: boolean | null;
      onlineClassroom: {
        __typename?: "OnlineClassroom";
        type: string | null;
        url: string | null;
      } | null;
    };
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
    checklist: {
      __typename?: "SectionChecklist";
      autoCanceledAt: any | null;
      grandfatheredAt2017Rate: boolean | null;
    };
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
    }>;
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      published_at: string | null;
    } | null;
  } | null;
};

export type SellerOrgAgreementFragmentFragment = {
  __typename?: "SellerOrgAgreement";
  uid: string;
  createdAt: any;
  updatedAt: any;
  signedAt: any | null;
  agreement: string;
  legalName: string | null;
  legalEntityType: string | null;
  legalState: string | null;
  legalAddress: string | null;
  ownsBackgroundChecks: boolean | null;
  contractSignedBy: string | null;
  contractSignerTitle: string | null;
};

export type UpdateSellerOrgAgreementMutationVariables = Exact<{
  input: UpdateSellerOrgAgreementInput;
}>;

export type UpdateSellerOrgAgreementMutation = {
  __typename?: "Mutation";
  updateSellerOrgAgreement: {
    __typename?: "SellerOrgAgreement";
    uid: string;
    createdAt: any;
    updatedAt: any;
    signedAt: any | null;
    agreement: string;
    legalName: string | null;
    legalEntityType: string | null;
    legalState: string | null;
    legalAddress: string | null;
    ownsBackgroundChecks: boolean | null;
    contractSignedBy: string | null;
    contractSignerTitle: string | null;
  };
};

export type SignSellerOrgAgreementMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SignSellerOrgAgreementMutation = {
  __typename?: "Mutation";
  signSellerOrgAgreement: {
    __typename?: "SellerOrgAgreement";
    uid: string;
    createdAt: any;
    updatedAt: any;
    signedAt: any | null;
    agreement: string;
    legalName: string | null;
    legalEntityType: string | null;
    legalState: string | null;
    legalAddress: string | null;
    ownsBackgroundChecks: boolean | null;
    contractSignedBy: string | null;
    contractSignerTitle: string | null;
  };
};

export type SellerOrgAgreementQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SellerOrgAgreementQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    latestSellerOrgAgreement: {
      __typename?: "SellerOrgAgreement";
      uid: string;
      createdAt: any;
      updatedAt: any;
      signedAt: any | null;
      agreement: string;
      legalName: string | null;
      legalEntityType: string | null;
      legalState: string | null;
      legalAddress: string | null;
      ownsBackgroundChecks: boolean | null;
      contractSignedBy: string | null;
      contractSignerTitle: string | null;
    } | null;
  } | null;
};

export type SellerOrgAgreementForUserQueryQueryVariables = Exact<{
  userUid: Scalars["String"];
}>;

export type SellerOrgAgreementForUserQueryQuery = {
  __typename?: "Query";
  user: {
    __typename?: "User";
    uid: string;
    latestSellerOrgAgreement: {
      __typename?: "SellerOrgAgreement";
      uid: string;
      createdAt: any;
      updatedAt: any;
      signedAt: any | null;
      agreement: string;
      legalName: string | null;
      legalEntityType: string | null;
      legalState: string | null;
      legalAddress: string | null;
      ownsBackgroundChecks: boolean | null;
      contractSignedBy: string | null;
      contractSignerTitle: string | null;
    } | null;
  } | null;
};

export type SellerOrgApplicationFragmentFragment = {
  __typename?: "SellerOrgApplication";
  uid: string;
  status: SellerOrgApplicationStatus;
  createdAt: any | null;
  updatedAt: any | null;
  appliedAt: any | null;
  contractSignedAt: any | null;
  approvedAt: any | null;
  rejectedAt: any | null;
  adminResponse: string | null;
  name: string | null;
  numberOfTeachers: string | null;
  countries: string | null;
  website: string | null;
  primaryContactName: string | null;
  primaryContactTitle: string | null;
  organizationPhone: string | null;
  whenFounded: string | null;
  teachersInfo: string | null;
  learnersInfo: string | null;
  classesInfo: string | null;
  firstClassDescription: string | null;
  sourcedFrom: string | null;
  publicReviews: string | null;
  additionalInfo: string | null;
};

export type UpdateSellerOrgApplicationMutationVariables = Exact<{
  formInput: SellerOrgApplicationInput;
}>;

export type UpdateSellerOrgApplicationMutation = {
  __typename?: "Mutation";
  updateSellerOrgApplication: {
    __typename?: "SellerOrgApplication";
    uid: string;
    status: SellerOrgApplicationStatus;
    createdAt: any | null;
    updatedAt: any | null;
    appliedAt: any | null;
    contractSignedAt: any | null;
    approvedAt: any | null;
    rejectedAt: any | null;
    adminResponse: string | null;
    name: string | null;
    numberOfTeachers: string | null;
    countries: string | null;
    website: string | null;
    primaryContactName: string | null;
    primaryContactTitle: string | null;
    organizationPhone: string | null;
    whenFounded: string | null;
    teachersInfo: string | null;
    learnersInfo: string | null;
    classesInfo: string | null;
    firstClassDescription: string | null;
    sourcedFrom: string | null;
    publicReviews: string | null;
    additionalInfo: string | null;
  };
};

export type SubmitSellerOrgApplicationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SubmitSellerOrgApplicationMutation = {
  __typename?: "Mutation";
  submitSellerOrgApplication: {
    __typename?: "SellerOrgApplication";
    uid: string;
    status: SellerOrgApplicationStatus;
    createdAt: any | null;
    updatedAt: any | null;
    appliedAt: any | null;
    contractSignedAt: any | null;
    approvedAt: any | null;
    rejectedAt: any | null;
    adminResponse: string | null;
    name: string | null;
    numberOfTeachers: string | null;
    countries: string | null;
    website: string | null;
    primaryContactName: string | null;
    primaryContactTitle: string | null;
    organizationPhone: string | null;
    whenFounded: string | null;
    teachersInfo: string | null;
    learnersInfo: string | null;
    classesInfo: string | null;
    firstClassDescription: string | null;
    sourcedFrom: string | null;
    publicReviews: string | null;
    additionalInfo: string | null;
  };
};

export type SellerOrgApplicationQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SellerOrgApplicationQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      createdAt: any | null;
      updatedAt: any | null;
      appliedAt: any | null;
      contractSignedAt: any | null;
      approvedAt: any | null;
      rejectedAt: any | null;
      adminResponse: string | null;
      name: string | null;
      numberOfTeachers: string | null;
      countries: string | null;
      website: string | null;
      primaryContactName: string | null;
      primaryContactTitle: string | null;
      organizationPhone: string | null;
      whenFounded: string | null;
      teachersInfo: string | null;
      learnersInfo: string | null;
      classesInfo: string | null;
      firstClassDescription: string | null;
      sourcedFrom: string | null;
      publicReviews: string | null;
      additionalInfo: string | null;
    } | null;
  } | null;
};

export type InviteTeacherToSellerOrgMutationVariables = Exact<{
  name: Scalars["String"];
  email: Scalars["String"];
}>;

export type InviteTeacherToSellerOrgMutation = {
  __typename?: "Mutation";
  inviteTeacherToSellerOrg: {
    __typename?: "User";
    uid: string;
    name: string | null;
    email: string | null;
    photo: string | null;
    confirmed_at: any | null;
    teacher_approved_at: any | null;
    backgroundChecked: boolean;
    background_checked_at: any | null;
    completed_trainings: Array<string> | null;
    hasBeenAssignedToSection: boolean;
    hasVerifiedIdentity: boolean | null;
  };
};

export type SellerOrgTeacherFragmentFragment = {
  __typename?: "User";
  uid: string;
  name: string | null;
  email: string | null;
  photo: string | null;
  confirmed_at: any | null;
  teacher_approved_at: any | null;
  backgroundChecked: boolean;
  background_checked_at: any | null;
  completed_trainings: Array<string> | null;
  hasBeenAssignedToSection: boolean;
  hasVerifiedIdentity: boolean | null;
};

export type SellerOrgTeachersQueryVariables = Exact<{ [key: string]: never }>;

export type SellerOrgTeachersQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      teachers: Array<{
        __typename?: "User";
        uid: string;
        name: string | null;
        email: string | null;
        photo: string | null;
        confirmed_at: any | null;
        teacher_approved_at: any | null;
        backgroundChecked: boolean;
        background_checked_at: any | null;
        completed_trainings: Array<string> | null;
        hasBeenAssignedToSection: boolean;
        hasVerifiedIdentity: boolean | null;
      }>;
    } | null;
  } | null;
};

export type AddModeratorToSectionMutationVariables = Exact<{
  sectionUid: Scalars["String"];
  userUid: Scalars["String"];
}>;

export type AddModeratorToSectionMutation = {
  __typename?: "Mutation";
  addSectionModerator: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
  };
};

export type RemoveModeratorFromSectionMutationVariables = Exact<{
  sectionUid: Scalars["String"];
  userUid: Scalars["String"];
}>;

export type RemoveModeratorFromSectionMutation = {
  __typename?: "Mutation";
  removeSectionModerator: boolean;
};

export type SectionModeratorsFragmentFragment = {
  __typename?: "Section";
  uid: string;
  moderators: Array<{
    __typename?: "Leader";
    uid: string;
    name: string | null;
  }>;
};

export type StartBackgroundCheckMutationVariables = Exact<{
  userUid: Scalars["ID"];
  country: Scalars["String"];
}>;

export type StartBackgroundCheckMutation = {
  __typename?: "Mutation";
  startBackgroundCheck: string;
};

export type CreateTeacherApplicationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateTeacherApplicationMutation = {
  __typename?: "Mutation";
  createTeacherApplication: {
    __typename?: "TeacherApplication";
    uid: string;
    legal_name: string | null;
    status: TeacherApplicationStatus;
    submitted_at: any | null;
    reviewed_at: any | null;
    teaching_experience: string | null;
    teaching_credentials: string | null;
    class_types: string | null;
    online_profiles: string | null;
    sample_classes: string | null;
    class_subjects: Array<string | null> | null;
    age_ranges_taught: Array<string | null> | null;
    years_of_experience: string | null;
    how_did_you_hear_about_us: string | null;
    experience_types: Array<string | null> | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type UpdateTeacherApplicationMutationVariables = Exact<{
  teacherApplicationUid: Scalars["ID"];
  teacherApplicationInput: TeacherApplicationInput;
}>;

export type UpdateTeacherApplicationMutation = {
  __typename?: "Mutation";
  updateTeacherApplication: {
    __typename?: "TeacherApplication";
    uid: string;
    legal_name: string | null;
    status: TeacherApplicationStatus;
    submitted_at: any | null;
    reviewed_at: any | null;
    teaching_experience: string | null;
    teaching_credentials: string | null;
    class_types: string | null;
    online_profiles: string | null;
    sample_classes: string | null;
    class_subjects: Array<string | null> | null;
    age_ranges_taught: Array<string | null> | null;
    years_of_experience: string | null;
    how_did_you_hear_about_us: string | null;
    experience_types: Array<string | null> | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type SubmitTeacherApplicationMutationVariables = Exact<{
  teacherApplicationUid: Scalars["ID"];
}>;

export type SubmitTeacherApplicationMutation = {
  __typename?: "Mutation";
  submitTeacherApplication: {
    __typename?: "TeacherApplication";
    uid: string;
    legal_name: string | null;
    status: TeacherApplicationStatus;
    submitted_at: any | null;
    reviewed_at: any | null;
    teaching_experience: string | null;
    teaching_credentials: string | null;
    class_types: string | null;
    online_profiles: string | null;
    sample_classes: string | null;
    class_subjects: Array<string | null> | null;
    age_ranges_taught: Array<string | null> | null;
    years_of_experience: string | null;
    how_did_you_hear_about_us: string | null;
    experience_types: Array<string | null> | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type TeacherApplicationQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TeacherApplicationQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    teacher_approved_at: any | null;
    latestLegalName: string | null;
    canTeacherStartBackgroundCheck: boolean;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
  } | null;
};

export type TeacherOnboardingQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TeacherOnboardingQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    latestLegalName: string | null;
    uid: string;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    teacher_approved_at: any | null;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    roles: Array<string> | null;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    consented_to_coppa_notice_at: any | null;
    hasVerifiedIdentity: boolean | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    upgradablePackages: Array<number | null> | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    teacherAvailability: {
      __typename?: "Availability";
      updated_at: any | null;
      hourlyTimetable: {
        __typename?: "HourlyTimetable";
        mon: Array<number>;
        tue: Array<number>;
        wed: Array<number>;
        thu: Array<number>;
        fri: Array<number>;
        sat: Array<number>;
        sun: Array<number>;
      } | null;
    } | null;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    details: {
      __typename?: "UserDetails";
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    location: {
      __typename?: "Location";
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      timeZone: string | null;
      state: string | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
    } | null;
  } | null;
};

export type TeacherAttendanceFragmentFragment = {
  __typename?: "TeacherAttendance";
  uid: string;
  created_at: any;
};

export type UpdateTeacherAvailabilityMutationVariables = Exact<{
  availability: AvailabilityInput;
}>;

export type UpdateTeacherAvailabilityMutation = {
  __typename?: "Mutation";
  updateTeacherAvailability: {
    __typename?: "User";
    uid: string;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    latestLegalName: string | null;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    teacher_approved_at: any | null;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    roles: Array<string> | null;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    consented_to_coppa_notice_at: any | null;
    hasVerifiedIdentity: boolean | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    upgradablePackages: Array<number | null> | null;
    teacherAvailability: {
      __typename?: "Availability";
      updated_at: any | null;
      hourlyTimetable: {
        __typename?: "HourlyTimetable";
        mon: Array<number>;
        tue: Array<number>;
        wed: Array<number>;
        thu: Array<number>;
        fri: Array<number>;
        sat: Array<number>;
        sun: Array<number>;
      } | null;
    } | null;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    details: {
      __typename?: "UserDetails";
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    location: {
      __typename?: "Location";
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      timeZone: string | null;
      state: string | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
    } | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
    } | null;
  };
};

export type CreateTeacherCredentialMutationVariables = Exact<{
  credentialType: CredentialType;
  file: CredentialFileInput;
  teacherCertificateCredentialInput?: InputMaybe<TeacherCertificateCredentialInput>;
  collegeDegreeCredentialInput?: InputMaybe<CollegeDegreeCredentialInput>;
  otherCredentialInput?: InputMaybe<OtherCredentialInput>;
}>;

export type CreateTeacherCredentialMutation = {
  __typename?: "Mutation";
  createTeacherCredential: { __typename?: "TeacherCredential"; uid: string };
};

export type DeleteTeacherCredentialMutationVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type DeleteTeacherCredentialMutation = {
  __typename?: "Mutation";
  deleteTeacherCredential: boolean;
};

export type TeacherCredentialsQueryVariables = Exact<{ [key: string]: never }>;

export type TeacherCredentialsQuery = {
  __typename?: "Query";
  teacherCredentials: Array<{
    __typename?: "TeacherCredential";
    certificateDiscipline: TeacherCertificateDiscipline | null;
    certificateExpireDate: any | null;
    certificateIssueDate: any | null;
    certificateNumber: string | null;
    certificateSecondaryDiscipline: TeacherCertificateDiscipline | null;
    certificateState: TeacherCertificateState | null;
    createdAt: any | null;
    credentialType: CredentialType;
    degreeInstitution: string | null;
    degreeIssueDate: any | null;
    degreeSubject: DegreeSubject | null;
    degreeType: DegreeType | null;
    otherDetails: string | null;
    otherType: string | null;
    name: string | null;
    uid: string;
  } | null> | null;
};

export type TeacherScheduleSectionFragmentFragment = {
  __typename?: "Section";
  uid: string;
  activity_uid: string;
  start_time: any | null;
  end_time: any | null;
  startsAtNight: boolean | null;
  is_cross_listed: boolean;
  size_max: number | null;
  size_min: number | null;
  isPublished: boolean | null;
  price_cents: number | null;
  priceCredits: number | null;
  published_at: any | null;
  updated_at: any;
  canceled_at: any | null;
  deleted_at: any | null;
  ongoing_stopped_at: any | null;
  duration_minutes: number | null;
  usesOutschoolVideoChat: boolean;
  usersCanSendPrivateMessage: boolean;
  currentUserCanManage: boolean;
  isBuyable: boolean;
  allowsTeacherTransfer: boolean;
  nextOngoingMeeting: {
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
  } | null;
  leader: { __typename?: "Leader"; uid: string; name: string | null };
  details: {
    __typename?: "SectionDetails";
    autoScheduledDraft: boolean | null;
  };
};

export type TeacherScheduleQueryQueryVariables = Exact<{
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
  excludeOrgTeachers?: InputMaybe<Scalars["Boolean"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  filterByTeacher?: InputMaybe<Scalars["ID"]>;
}>;

export type TeacherScheduleQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    calendar_uid: string;
    name: string | null;
    email: string | null;
    completed_trainings: Array<string> | null;
    backgroundChecked: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    hasBeenAssignedToSection: boolean;
    hasVerifiedIdentity: boolean | null;
    teacher_approved_at: any | null;
    canTeacherStartBackgroundCheck: boolean;
    location: { __typename?: "Location"; timeZone: string | null } | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      status: TeacherApplicationStatus;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
    } | null;
    teacherAvailability: {
      __typename?: "Availability";
      updated_at: any | null;
      hourlyTimetable: {
        __typename?: "HourlyTimetable";
        mon: Array<number>;
        tue: Array<number>;
        wed: Array<number>;
        thu: Array<number>;
        fri: Array<number>;
        sat: Array<number>;
        sun: Array<number>;
      } | null;
    } | null;
    teacherFlexSections: Array<{
      __typename?: "Section";
      uid: string;
      filledSpaceCount: number;
      activity_uid: string;
      start_time: any | null;
      end_time: any | null;
      startsAtNight: boolean | null;
      is_cross_listed: boolean;
      size_max: number | null;
      size_min: number | null;
      isPublished: boolean | null;
      price_cents: number | null;
      priceCredits: number | null;
      published_at: any | null;
      updated_at: any;
      canceled_at: any | null;
      deleted_at: any | null;
      ongoing_stopped_at: any | null;
      duration_minutes: number | null;
      usesOutschoolVideoChat: boolean;
      usersCanSendPrivateMessage: boolean;
      currentUserCanManage: boolean;
      isBuyable: boolean;
      allowsTeacherTransfer: boolean;
      activity: {
        __typename?: "Activity";
        uid: string;
        slug_id: string;
        published_at: any | null;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        hasTeacherSchedule: boolean;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        is_ongoing_weekly: boolean;
        size_min: number | null;
        size_max: number | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
      };
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        deleted_at: any | null;
        created_at: any | null;
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      details: {
        __typename?: "SectionDetails";
        autoScheduledDraft: boolean | null;
      };
    }>;
    leaderActivities: Array<{
      __typename?: "Activity";
      uid: string;
      slug_id: string;
      published_at: any | null;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      hasTeacherSchedule: boolean;
      isClub: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      is_ongoing_weekly: boolean;
      size_min: number | null;
      size_max: number | null;
      details: { __typename?: "ActivityDetails"; photo: string | null };
    }>;
    teacherMeetings: Array<{
      __typename?: "Meeting";
      uid: string;
      sectionSequenceNumber: number;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
      section: {
        __typename?: "Section";
        uid: string;
        filledSpaceCount: number;
        meetingCount: number;
        pausedLearnerCount: number;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        activity: {
          __typename?: "Activity";
          uid: string;
          slug_id: string;
          published_at: any | null;
          title: string;
          titleTranslated: string | null;
          url: string;
          path: string;
          hasTeacherSchedule: boolean;
          isClub: boolean;
          isFlexSchedule: boolean;
          isSelfPaced: boolean;
          isOneTime: boolean;
          duration_minutes: number | null;
          duration_weeks: number | null;
          weekly_meetings: number | null;
          is_ongoing_weekly: boolean;
          size_min: number | null;
          size_max: number | null;
          details: { __typename?: "ActivityDetails"; photo: string | null };
        };
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        enrollments: Array<{
          __typename?: "Enrollment";
          uid: string;
          learner: {
            __typename?: "Learner";
            uid: string;
            name: string | null;
          } | null;
        }>;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
      };
    }>;
  } | null;
};

export type SectionAssignmentsPageAssignmentFragment = {
  __typename?: "AssignmentClassPost";
  uid: string;
  title: string;
  created_at: any;
  deleted_at: any | null;
  publishAt: any;
  dueDate: any | null;
  submissionRequirement: SubmissionRequirementType;
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
  lesson: {
    __typename?: "Lesson";
    uid: string;
    title: string | null;
    lessonNumber: number | null;
  } | null;
  comments: Array<{
    __typename?: "AssignmentClassPostComment";
    uid: string;
    sentAt: any;
    threadForLearnerUid: string;
    isSubmission: boolean | null;
    replies: Array<{
      __typename?: "AssignmentClassPostComment";
      uid: string;
      sentAt: any;
      threadForLearnerUid: string;
      isSubmission: boolean | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    }> | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  }>;
};

export type SectionAssignmentsPageQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type SectionAssignmentsPageQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    published_at: any | null;
    deleted_at: any | null;
    canceled_at: any | null;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_self_paced: boolean;
    currentUserCanManage: boolean;
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      published_at: string | null;
      lessons: Array<{
        __typename?: "Lesson";
        uid: string;
        lessonNumber: number | null;
        assignmentClassPosts: Array<{
          __typename?: "AssignmentClassPost";
          uid: string;
          title: string;
          submissionRequirement: SubmissionRequirementType;
          dueDate: any | null;
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
          comments: Array<{
            __typename?: "AssignmentClassPostComment";
            uid: string;
            sentAt: any;
            isSubmission: boolean | null;
            messageContent: {
              __typename?: "MessageContent";
              uid: string;
              text: string | null;
              originalMimeType: TextMimeType;
              editedAt: any | null;
              htmlText: string | null;
              attachments: Array<{
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              }>;
              video: {
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              } | null;
            };
          }>;
        }>;
      }>;
    } | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      is_ongoing_weekly: boolean;
      weekly_meetings: number | null;
      duration_weeks: number | null;
      isClub: boolean;
      published_at: any | null;
      hasTeacherSchedule: boolean;
    };
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
      title: string;
      created_at: any;
      deleted_at: any | null;
      publishAt: any;
      dueDate: any | null;
      submissionRequirement: SubmissionRequirementType;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      lesson: {
        __typename?: "Lesson";
        uid: string;
        title: string | null;
        lessonNumber: number | null;
      } | null;
      comments: Array<{
        __typename?: "AssignmentClassPostComment";
        uid: string;
        sentAt: any;
        threadForLearnerUid: string;
        isSubmission: boolean | null;
        replies: Array<{
          __typename?: "AssignmentClassPostComment";
          uid: string;
          sentAt: any;
          threadForLearnerUid: string;
          isSubmission: boolean | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }> | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    }>;
    enrollments: Array<{ __typename?: "Enrollment"; uid: string }>;
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
    } | null;
  } | null;
};

export type TeacherCommentOnAssignmentMutationVariables = Exact<{
  assignmentClassPostUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
  messageContentInput: MessageContentInput;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type TeacherCommentOnAssignmentMutation = {
  __typename?: "Mutation";
  addAssignmentClassPostComment: {
    __typename?: "AssignmentClassPostComment";
    uid: string;
    sentAt: any;
    threadForLearnerUid: string;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type TimetableFragmentFragment = {
  __typename?: "Timetable";
  mon: {
    __typename?: "TimetableTimes";
    f8_12: boolean;
    f12_4: boolean;
    f4_8: boolean;
  };
  tue: {
    __typename?: "TimetableTimes";
    f8_12: boolean;
    f12_4: boolean;
    f4_8: boolean;
  };
  wed: {
    __typename?: "TimetableTimes";
    f8_12: boolean;
    f12_4: boolean;
    f4_8: boolean;
  };
  thu: {
    __typename?: "TimetableTimes";
    f8_12: boolean;
    f12_4: boolean;
    f4_8: boolean;
  };
  fri: {
    __typename?: "TimetableTimes";
    f8_12: boolean;
    f12_4: boolean;
    f4_8: boolean;
  };
  sat: {
    __typename?: "TimetableTimes";
    f8_12: boolean;
    f12_4: boolean;
    f4_8: boolean;
  };
  sun: {
    __typename?: "TimetableTimes";
    f8_12: boolean;
    f12_4: boolean;
    f4_8: boolean;
  };
};

export type TimetableTimesFragmentFragment = {
  __typename?: "TimetableTimes";
  f8_12: boolean;
  f12_4: boolean;
  f4_8: boolean;
};

export type TopLevelCategoryPagesQueryVariables = Exact<{
  taxonomy?: InputMaybe<Scalars["String"]>;
}>;

export type TopLevelCategoryPagesQuery = {
  __typename?: "Query";
  topLevelCategoryPages: Array<{
    __typename?: "CategoryPage";
    uid: string;
    nameTitleCased: string;
    nameSentenceCased: string;
    slug: string;
    title: string | null;
    description: string | null;
  } | null> | null;
};

export type TrackOrderCompletedMutationVariables = Exact<{
  properties: TrackOrderCompletedProperties;
}>;

export type TrackOrderCompletedMutation = {
  __typename?: "Mutation";
  trackOrderCompleted: boolean | null;
};

export type TrackCheckoutSessionCompletedMutationVariables = Exact<{
  properties: TrackCheckoutSessionCompletedProperties;
}>;

export type TrackCheckoutSessionCompletedMutation = {
  __typename?: "Mutation";
  trackCheckoutSessionCompleted: boolean | null;
};

export type TrackNewBuyerAcquiredMutationVariables = Exact<{
  properties: TrackNewBuyerAcquiredProperties;
}>;

export type TrackNewBuyerAcquiredMutation = {
  __typename?: "Mutation";
  trackNewBuyerAcquired: boolean | null;
};

export type ActivitySectionsQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type ActivitySectionsQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    paginatedFilteredSections: {
      __typename?: "SectionList";
      data: Array<{
        __typename?: "Section";
        filledSpaceCount: number;
        user_uid: string | null;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        created_at: any;
        validEnrollmentCount: number;
        isAutoCanceled: boolean;
        currentUserIsOwner: boolean;
        paidAmount: number | null;
        meetings: Array<{
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        }>;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
          onlineClassroom: {
            __typename?: "OnlineClassroom";
            type: string | null;
            url: string | null;
          } | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
        checklist: {
          __typename?: "SectionChecklist";
          autoCanceledAt: any | null;
          grandfatheredAt2017Rate: boolean | null;
        };
      }>;
    };
  };
};

export type UnavailableBlockFragmentFragment = {
  __typename?: "UnavailableBlock";
  uid: string;
  startTime: any;
  endTime: any;
};

export type UpcomingClassesQueryVariables = Exact<{
  uid?: InputMaybe<Scalars["ID"]>;
  includeSchedule: Scalars["Boolean"];
  flexSectionsOffset: Scalars["String"];
  meetingsOffset: Scalars["String"];
}>;

export type UpcomingClassesQuery = {
  __typename?: "Query";
  publicProfile: {
    __typename?: "PublicProfile";
    uid: string;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      flexSections?: {
        __typename?: "SectionConnection";
        edges: Array<{
          __typename?: "SectionEdge";
          node: {
            __typename?: "Section";
            uid: string;
            start_time: any | null;
            end_time: any | null;
            deleted_at: any | null;
            canceled_at: any | null;
            published_at: any | null;
            isBuyable: boolean;
            meetingCount: number;
            leader: {
              __typename?: "Leader";
              uid: string;
              name: string | null;
              teacherForSellerOrg: {
                __typename?: "SellerOrg";
                uid: string;
              } | null;
            };
            activity: {
              __typename?: "Activity";
              uid: string;
              title: string;
              titleTranslated: string | null;
              age_min: number | null;
              age_max: number | null;
              is_ongoing_weekly: boolean;
              weekly_meetings: number | null;
              duration_weeks: number | null;
              isClub: boolean;
              details: { __typename?: "ActivityDetails"; photo: string | null };
            };
          };
        }>;
      };
      meetings?: {
        __typename?: "MeetingConnection";
        edges: Array<{
          __typename?: "MeetingEdge";
          node: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
            sectionSequenceNumber: number;
            section: {
              __typename?: "Section";
              uid: string;
              start_time: any | null;
              end_time: any | null;
              deleted_at: any | null;
              canceled_at: any | null;
              published_at: any | null;
              isBuyable: boolean;
              meetingCount: number;
              nextOngoingMeeting: {
                __typename?: "Meeting";
                uid: string;
                start_time: any;
              } | null;
              leader: {
                __typename?: "Leader";
                uid: string;
                name: string | null;
                teacherForSellerOrg: {
                  __typename?: "SellerOrg";
                  uid: string;
                  name: string;
                } | null;
              };
              activity: {
                __typename?: "Activity";
                uid: string;
                title: string;
                titleTranslated: string | null;
                age_min: number | null;
                age_max: number | null;
                is_ongoing_weekly: boolean;
                weekly_meetings: number | null;
                duration_weeks: number | null;
                isClub: boolean;
                details: {
                  __typename?: "ActivityDetails";
                  photo: string | null;
                };
              };
            };
          };
        }>;
      };
    } | null;
  } | null;
};

export type MergeLearnerMutationVariables = Exact<{
  targetLearnerUid: Scalars["ID"];
  sourceLearnerUid: Scalars["ID"];
}>;

export type MergeLearnerMutation = {
  __typename?: "Mutation";
  mergeLearner: boolean;
};

export type SetLearnerUnlockableAvatarsMutationVariables = Exact<{
  learnerUid: Scalars["String"];
  value: Array<Scalars["String"]> | Scalars["String"];
}>;

export type SetLearnerUnlockableAvatarsMutation = {
  __typename?: "Mutation";
  setLearnerUnlockableAvatars: boolean;
};

export type UserAdminQueryVariables = Exact<{
  uid: Scalars["String"];
  skipIncidents?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UserAdminQuery = {
  __typename?: "Query";
  user: {
    __typename?: "User";
    completed_trainings: Array<string> | null;
    teacher_featured_at: any | null;
    roles: Array<string> | null;
    hasVerifiedIdentity: boolean | null;
    idVerificationStatus: IdvStatus;
    seller_org_admin_teacher_account_uid: string | null;
    number_of_merges: number;
    uid: string;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    latestLegalName: string | null;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    teacher_approved_at: any | null;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    consented_to_coppa_notice_at: any | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    upgradablePackages: Array<number | null> | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      type: string | null;
      typeDetail: string | null;
      description: string | null;
      timeInBusiness: string | null;
      learnerTypes: string | null;
      alreadyApprovedTeacher: boolean | null;
      alreadyApprovedTeacherEmail: string | null;
      numberOfClassesPerWeek: string | null;
      numberOfStudentsOnAverage: string | null;
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
      createdAt: any | null;
      updatedAt: any | null;
      appliedAt: any | null;
      name: string | null;
      numberOfTeachers: string | null;
      countries: string | null;
      website: string | null;
      primaryContactName: string | null;
      primaryContactTitle: string | null;
      organizationPhone: string | null;
      whenFounded: string | null;
      teachersInfo: string | null;
      learnersInfo: string | null;
      classesInfo: string | null;
      firstClassDescription: string | null;
      sourcedFrom: string | null;
      publicReviews: string | null;
      additionalInfo: string | null;
    } | null;
    latestSellerOrgAgreement: {
      __typename?: "SellerOrgAgreement";
      uid: string;
      createdAt: any;
      updatedAt: any;
      signedAt: any | null;
      agreement: string;
      legalName: string | null;
      legalEntityType: string | null;
      legalState: string | null;
      legalAddress: string | null;
      ownsBackgroundChecks: boolean | null;
      contractSignedBy: string | null;
      contractSignerTitle: string | null;
    } | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    details: {
      __typename?: "UserDetails";
      holdMessages: boolean | null;
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    adminIncidents?: Array<{
      __typename?: "AdminUserIncident";
      uid: string;
      created_at: any;
      updated_at: any;
      category: string;
      notes: string | null;
      admin: { __typename?: "User"; uid: string; name: string | null };
    }>;
    adminActions: Array<{
      __typename?: "AdminUserAction";
      uid: string;
      created_at: any;
      action: string;
      notes: string | null;
      customValue: string | null;
      admin: { __typename?: "User"; uid: string; name: string | null };
    }>;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      adminMetrics: {
        __typename?: "AdminLeaderMetrics";
        totalAttendances: number;
        lateAttendances: number;
        missedAttendances: number;
        lastMinuteCancelledSections: number;
        lastMinuteCancelledEnrollments: number;
        numIncidents: number;
      };
      credentials: Array<{
        __typename?: "TeacherCredential";
        certificateDiscipline: TeacherCertificateDiscipline | null;
        certificateExpireDate: any | null;
        certificateNumber: string | null;
        certificateSecondaryDiscipline: TeacherCertificateDiscipline | null;
        certificateState: TeacherCertificateState | null;
        createdAt: any | null;
        credentialType: CredentialType;
        degreeInstitution: string | null;
        degreeSubject: DegreeSubject | null;
        degreeType: DegreeType | null;
        otherDetails: string | null;
        otherType: string | null;
        uid: string;
      }>;
      tags: {
        __typename?: "TeacherTagsConnection";
        edges: Array<{
          __typename?: "TeacherTagEdge";
          node: { __typename?: "Tag"; uid: string; key: string; value: string };
        }>;
      };
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      ownsBackgroundChecks: boolean;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    location: {
      __typename?: "Location";
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      timeZone: string | null;
      state: string | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
  } | null;
};

export type LearnerQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type LearnerQuery = {
  __typename?: "Query";
  learner: {
    __typename?: "Learner";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    isVerified: boolean;
    isBlocked: boolean | null;
    blockedByUserUid: string | null;
    blockedAt: any | null;
  } | null;
};

export type WaitlistSeatsForParentQueryVariables = Exact<{
  parentUid: Scalars["ID"];
  sectionUid?: InputMaybe<Scalars["ID"]>;
}>;

export type WaitlistSeatsForParentQuery = {
  __typename?: "Query";
  waitlistSeatsForParent: Array<{
    __typename?: "WaitlistSeat";
    uid: string;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    };
    enrollment: { __typename?: "Enrollment"; uid: string } | null;
    section: {
      __typename?: "Section";
      uid: string;
      filledSpaceCount: number;
      size_max: number | null;
      published_at: any | null;
      canceled_at: any | null;
      deleted_at: any | null;
    };
  } | null>;
};

export type WaitlistSummaryForActivityQueryVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type WaitlistSummaryForActivityQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    allowWaitlist: boolean | null;
    isClub: boolean;
    weekly_meetings: number | null;
    duration_weeks: number | null;
    is_ongoing_weekly: boolean;
    published_at: any | null;
    hasTeacherSchedule: boolean;
    sections: Array<{
      __typename?: "Section";
      filledSpaceCount: number;
      waitlistSeatsCount: number | null;
      uid: string;
      activity_uid: string;
      start_time: any | null;
      end_time: any | null;
      startsAtNight: boolean | null;
      is_cross_listed: boolean;
      size_max: number | null;
      size_min: number | null;
      isPublished: boolean | null;
      price_cents: number | null;
      priceCredits: number | null;
      published_at: any | null;
      updated_at: any;
      canceled_at: any | null;
      deleted_at: any | null;
      ongoing_stopped_at: any | null;
      duration_minutes: number | null;
      usesOutschoolVideoChat: boolean;
      usersCanSendPrivateMessage: boolean;
      currentUserCanManage: boolean;
      isBuyable: boolean;
      allowsTeacherTransfer: boolean;
      created_at: any;
      validEnrollmentCount: number;
      isAutoCanceled: boolean;
      currentUserIsOwner: boolean;
      paidAmount: number | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        deleted_at: any | null;
        created_at: any | null;
      } | null;
      details: {
        __typename?: "SectionDetails";
        autoScheduledDraft: boolean | null;
        onlineClassroom: {
          __typename?: "OnlineClassroom";
          type: string | null;
          url: string | null;
        } | null;
      };
      prepaidOngoingPeriodOptions: Array<{
        __typename?: "Period";
        startOfIsoWeek: any;
        endOfIsoWeek: any;
      }> | null;
      checklist: {
        __typename?: "SectionChecklist";
        autoCanceledAt: any | null;
        grandfatheredAt2017Rate: boolean | null;
      };
    }>;
  };
};

export type WaitlistSeatFragmentFragment = {
  __typename?: "WaitlistSeat";
  uid: string;
  createdAt: any | null;
  parent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  };
  learner: { __typename?: "Learner"; uid: string; name: string | null };
  section: { __typename?: "Section"; uid: string };
  enrollment: { __typename?: "Enrollment"; uid: string } | null;
};

export type UpdateWaitlistMutationVariables = Exact<{
  createInputs: Array<CreateWaitlistSeatInput> | CreateWaitlistSeatInput;
  deleteInputs: Array<Scalars["String"]> | Scalars["String"];
}>;

export type UpdateWaitlistMutation = {
  __typename?: "Mutation";
  createWaitlistSeats: Array<{
    __typename?: "WaitlistSeat";
    uid: string;
    createdAt: any | null;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    learner: { __typename?: "Learner"; uid: string; name: string | null };
    section: { __typename?: "Section"; uid: string };
    enrollment: { __typename?: "Enrollment"; uid: string } | null;
  }>;
  deleteWaitlistSeats: Array<{
    __typename?: "WaitlistSeat";
    uid: string;
    createdAt: any | null;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    learner: { __typename?: "Learner"; uid: string; name: string | null };
    section: { __typename?: "Section"; uid: string };
    enrollment: { __typename?: "Enrollment"; uid: string } | null;
  }>;
};

export type AcceptGiftEnrollmentPageQueryQueryVariables = Exact<{
  acceptGiftSlugId: Scalars["ID"];
}>;

export type AcceptGiftEnrollmentPageQueryQuery = {
  __typename?: "Query";
  giftEnrollmentsForAcceptSlug: Array<{
    __typename?: "GiftEnrollment";
    giftClaimedAt: any | null;
    acceptGiftSlugId: string;
    ongoing_start_time: any | null;
    section: {
      __typename?: "Section";
      uid: string;
      activity_uid: string;
      start_time: any | null;
      end_time: any | null;
      startsAtNight: boolean | null;
      is_cross_listed: boolean;
      size_max: number | null;
      size_min: number | null;
      isPublished: boolean | null;
      price_cents: number | null;
      priceCredits: number | null;
      published_at: any | null;
      updated_at: any;
      canceled_at: any | null;
      deleted_at: any | null;
      ongoing_stopped_at: any | null;
      duration_minutes: number | null;
      usesOutschoolVideoChat: boolean;
      usersCanSendPrivateMessage: boolean;
      currentUserCanManage: boolean;
      isBuyable: boolean;
      allowsTeacherTransfer: boolean;
      activity: {
        __typename?: "Activity";
        uid: string;
        slug_id: string;
        user_uid: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        class_experience: string | null;
        learning_goals: string | null;
        learningGoals: Array<string>;
        assessment: string | null;
        homework: string | null;
        grading_policy: string | null;
        prerequisites: string | null;
        published_at: any | null;
        is_online: boolean;
        age_min: number | null;
        age_max: number | null;
        english_proficiency_level: EnglishProficiencyLevel | null;
        grade_level_min: string | null;
        grade_level_max: string | null;
        size_min: number | null;
        size_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        priceCredits: number | null;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        languageOfInstruction: any | null;
        summaryForLearners: string | null;
        allowWaitlist: boolean | null;
        isClub: boolean;
        isAutoSchedulingEnabled: boolean | null;
        autoSchedulingDaysNoticeMin: number | null;
        autoSchedulingDaysNoticeMax: number | null;
        allows_recurring_payment: boolean | null;
        allows_late_enrollments_for_fixed_length_classes: boolean;
        has_building_content: boolean | null;
        hasTeacherSchedule: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        classFormat: EducatorSelectedFormat;
        classroomType: ClassroomType;
        strictAgeRange: {
          __typename?: "AgeRangeType";
          min: number;
          max: number;
        } | null;
        proficiency_level: {
          __typename?: "ProficiencyLevel";
          level: Array<string | null> | null;
          text: string | null;
        } | null;
        refundPolicy: {
          __typename?: "RefundPolicy";
          name: string;
          description: string;
          type: RefundPolicyType;
        };
        details: {
          __typename?: "ActivityDetails";
          photo: string | null;
          photoSquare: string | null;
        };
      };
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        deleted_at: any | null;
        created_at: any | null;
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      details: {
        __typename?: "SectionDetails";
        autoScheduledDraft: boolean | null;
      };
      prepaidOngoingPeriodOptions: Array<{
        __typename?: "Period";
        startOfIsoWeek: any;
        endOfIsoWeek: any;
      }> | null;
    };
    buyer: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
    };
  }>;
};

export type ActivityAdminQueryQueryVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type ActivityAdminQueryQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    user_uid: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    learningGoals: Array<string>;
    assessment: string | null;
    homework: string | null;
    grading_policy: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    english_proficiency_level: EnglishProficiencyLevel | null;
    grade_level_min: string | null;
    grade_level_max: string | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    priceCredits: number | null;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    allowWaitlist: boolean | null;
    isClub: boolean;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    allows_recurring_payment: boolean | null;
    allows_late_enrollments_for_fixed_length_classes: boolean;
    has_building_content: boolean | null;
    hasTeacherSchedule: boolean;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isOneTime: boolean;
    classFormat: EducatorSelectedFormat;
    classroomType: ClassroomType;
    saveCount: number | null;
    archived_at: any | null;
    upsell_activity_uid: string | null;
    why_me: string | null;
    parental_guidance: string | null;
    sources: string | null;
    materials: string | null;
    hasSections: boolean;
    tags: string | null;
    disclaimer: string | null;
    has_external_resources: boolean | null;
    leader: {
      __typename?: "Leader";
      publishedClassCount: number;
      uid: string;
      name: string | null;
      leader_link: string | null;
      created_at: any | null;
      photo: string | null;
      suspended_at: any | null;
      cityState: string | null;
      socialOptOut: boolean;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      hasBackgroundCheckExpired: boolean | null;
      reviewCount: number;
      averageActivityStarRating: number | null;
      hasListings: boolean;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        headlineTranslated: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
    };
    actions: Array<{
      __typename?: "ActivityAction";
      uid: string;
      created_at: any;
      action: string;
      notes: string | null;
      message_to_teacher: string | null;
      user: { __typename?: "User"; uid: string; name: string | null };
    }>;
    sections: Array<{
      __typename?: "Section";
      uid: string;
      published_at: any | null;
      deleted_at: any | null;
      canceled_at: any | null;
      start_time: any | null;
      end_time: any | null;
      size_max: number | null;
      currentUserCanManage: boolean;
    }>;
    strictAgeRange: {
      __typename?: "AgeRangeType";
      min: number;
      max: number;
    } | null;
    proficiency_level: {
      __typename?: "ProficiencyLevel";
      level: Array<string | null> | null;
      text: string | null;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      description: string;
      type: RefundPolicyType;
    };
    details: {
      __typename?: "ActivityDetails";
      photo: string | null;
      photoSquare: string | null;
      asyncTime: string | null;
    };
    leaderDetails: {
      __typename?: "ActivityLeaderDetails";
      uid: string;
      requestedListingAt: any | null;
      welcomeMessage: {
        __typename?: "WelcomeMessage";
        text: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        }> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        } | null;
      } | null;
    };
    adminChecklist: {
      __typename?: "ActivityAdminChecklist";
      notes: string | null;
    };
    pendingOneOnOneEsaEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      orderSlugId: string | null;
      createdAt: any;
      withdrawn_at: any | null;
      withdrawnAt: any | null;
      confirmed_at: any | null;
      confirmedAt: any | null;
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        email: string | null;
        photo: string | null;
        timeZone: string | null;
      };
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      refundPolicy: { __typename?: "RefundPolicy"; name: string } | null;
    }>;
    externalResources: Array<{
      __typename?: "ExternalResource";
      uid: string;
      name: string;
      url: string | null;
    }>;
  };
};

export type AddAdminActivityActionMutationVariables = Exact<{
  activityUid: Scalars["ID"];
  action: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  messageToTeacher?: InputMaybe<Scalars["String"]>;
}>;

export type AddAdminActivityActionMutation = {
  __typename?: "Mutation";
  addAdminActivityAction: {
    __typename?: "ActivityAction";
    uid: string;
    created_at: any;
    action: string;
    notes: string | null;
    message_to_teacher: string | null;
    user: { __typename?: "User"; uid: string; name: string | null };
    activity: {
      __typename?: "Activity";
      uid: string;
      published_at: any | null;
      leaderDetails: {
        __typename?: "ActivityLeaderDetails";
        uid: string;
        requestedListingAt: any | null;
      };
    };
  };
};

export type ActivityApprovalQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type ActivityApprovalQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    disclaimer: string | null;
    esaStatus: EsaStatus;
    first_listed_at: any | null;
    isPublished: boolean;
    isRestricted: boolean;
    priceCredits: number | null;
    perWeeklyCourseCredits: number | null;
    wasPublishedInThePast: boolean;
    welcomeAttachmentCount: number | null;
    meetingCount: number | null;
    suggestedWeeklyMeetings: number | null;
    reviewCount: number;
    averageStarRating: number | null;
    requested_listing_at: any | null;
    canRequestListing: boolean;
    isSoldOut: boolean;
    uid: string;
    slug_id: string;
    user_uid: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    learningGoals: Array<string>;
    assessment: string | null;
    homework: string | null;
    grading_policy: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    english_proficiency_level: EnglishProficiencyLevel | null;
    grade_level_min: string | null;
    grade_level_max: string | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    allowWaitlist: boolean | null;
    isClub: boolean;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    allows_recurring_payment: boolean | null;
    allows_late_enrollments_for_fixed_length_classes: boolean;
    has_building_content: boolean | null;
    hasTeacherSchedule: boolean;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isOneTime: boolean;
    classFormat: EducatorSelectedFormat;
    classroomType: ClassroomType;
    tags: string | null;
    archived_at: any | null;
    isSelfPacedEnrollmentEnabled: boolean;
    saveCount: number | null;
    upsell_activity_uid: string | null;
    why_me: string | null;
    parental_guidance: string | null;
    sources: string | null;
    materials: string | null;
    hasSections: boolean;
    has_external_resources: boolean | null;
    completedEnrollmentCount: number;
    sections: Array<{
      __typename?: "Section";
      validEnrollmentCount: number;
      uid: string;
      filledSpaceCount: number;
    }>;
    attributes: Array<{
      __typename?: "EntityAttribute";
      key: string;
      value: string;
      entity_type: string;
    }>;
    clubSection: {
      __typename?: "Section";
      uid: string;
      validEnrollmentCount: number;
      isBuyable: boolean;
    } | null;
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      published_at: string | null;
      lessons: Array<{
        __typename?: "Lesson";
        uid: string;
        title: string | null;
        description: string | null;
        week_number: number;
        order_within_week: number;
        lessonNumber: number | null;
        videoDurationSeconds: number;
        assignmentsCount: number;
        unit: {
          __typename?: "Unit";
          uid: string;
          title: string | null;
          unitNumber: number | null;
        } | null;
      }>;
    } | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        url: string;
        thumbUrl: string | null;
        filename: string;
        mimetype: string | null;
        size: number;
      };
    } | null;
    videoForLearners: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    leader: {
      __typename?: "Leader";
      averageActivityStarRating: number | null;
      reviewCount: number;
      successfulClassCount: number;
      uid: string;
      name: string | null;
      leader_link: string | null;
      created_at: any | null;
      photo: string | null;
      suspended_at: any | null;
      cityState: string | null;
      socialOptOut: boolean;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      hasBackgroundCheckExpired: boolean | null;
      hasListings: boolean;
      publishedClassCount: number;
      teacher_approved_at: any | null;
      educatorTiers: Array<EducatorTier>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          url: string;
          thumbUrl: string | null;
          filename: string;
          mimetype: string | null;
          size: number;
        };
      } | null;
      credentials: Array<{
        __typename?: "TeacherCredential";
        uid: string;
        certificateIssueDate: any | null;
        degreeIssueDate: any | null;
        certificateDiscipline: TeacherCertificateDiscipline | null;
        certificateExpireDate: any | null;
        certificateDoesNotExpire: boolean | null;
        certificateNumber: string | null;
        certificateSecondaryDiscipline: TeacherCertificateDiscipline | null;
        certificateState: TeacherCertificateState | null;
        createdAt: any | null;
        credentialType: CredentialType;
        degreeInstitution: string | null;
        degreeSubject: DegreeSubject | null;
        degreeType: DegreeType | null;
        otherDetails: string | null;
        otherType: string | null;
        name: string | null;
        degreeAdditionalInfo: string | null;
      }>;
      leaderClassLinks: Array<{
        __typename?: "Bundle";
        uid: string;
        title: string;
        isOrdered: boolean;
        bundleElements: Array<{
          __typename?: "BundleElement";
          orderInBundle: number;
          activity: { __typename?: "Activity"; uid: string };
        }>;
      }>;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        headlineTranslated: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
      tags: {
        __typename?: "TeacherTagsConnection";
        edges: Array<{
          __typename?: "TeacherTagEdge";
          node: { __typename?: "Tag"; uid: string; key: string; value: string };
        }>;
      };
    };
    approvalCriteria: Array<{
      __typename?: "ApprovalCriterion";
      description: string;
      value: ApprovalCriterionValue;
    }>;
    actions: Array<{
      __typename?: "ActivityAction";
      uid: string;
      created_at: any;
      action: string;
      notes: string | null;
      message_to_teacher: string | null;
      user: { __typename?: "User"; uid: string; name: string | null };
    }>;
    sampleClubPost: {
      __typename?: "WelcomeMessage";
      text: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        file: { __typename?: "File"; url: string; mimetype: string | null };
      }> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        file: { __typename?: "File"; url: string; mimetype: string | null };
      } | null;
    } | null;
    clubGuidelines: {
      __typename?: "WelcomeMessage";
      text: string | null;
    } | null;
    strictAgeRange: {
      __typename?: "AgeRangeType";
      min: number;
      max: number;
    } | null;
    proficiency_level: {
      __typename?: "ProficiencyLevel";
      level: Array<string | null> | null;
      text: string | null;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      description: string;
      type: RefundPolicyType;
    };
    details: {
      __typename?: "ActivityDetails";
      photo: string | null;
      photoSquare: string | null;
      asyncTime: string | null;
    };
    approvalStatus: {
      __typename?: "ActivityApprovalStatus";
      action: string;
      message_to_teacher: string | null;
    } | null;
    leaderDetails: {
      __typename?: "ActivityLeaderDetails";
      uid: string;
      requestedListingAt: any | null;
      welcomeMessage: {
        __typename?: "WelcomeMessage";
        text: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        }> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        } | null;
      } | null;
    };
    externalResources: Array<{
      __typename?: "ExternalResource";
      uid: string;
      name: string;
      url: string | null;
    }>;
    savedClassLists: Array<{
      __typename?: "SavedClassList";
      uid: string;
      lastAddedToAt: any | null;
    }>;
  };
};

export type AddApprovalActivityActionMutationVariables = Exact<{
  activityUid: Scalars["ID"];
  action: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  messageToTeacher?: InputMaybe<Scalars["String"]>;
}>;

export type AddApprovalActivityActionMutation = {
  __typename?: "Mutation";
  addApprovalActivityAction: {
    __typename?: "ActivityAction";
    uid: string;
    created_at: any;
    action: string;
    notes: string | null;
    message_to_teacher: string | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      published_at: any | null;
      leaderDetails: {
        __typename?: "ActivityLeaderDetails";
        uid: string;
        requestedListingAt: any | null;
      };
    };
  };
};

export type NextApprovalActivityUidQueryQueryVariables = Exact<{
  mode?: InputMaybe<ActivityReviewMode>;
  subject?: InputMaybe<Scalars["String"]>;
  includeFlex?: InputMaybe<Scalars["Boolean"]>;
  previousActivityUid?: InputMaybe<Scalars["ID"]>;
}>;

export type NextApprovalActivityUidQueryQuery = {
  __typename?: "Query";
  nextApprovalActivityUid: string | null;
};

export type OrgTeacherScheduleSettingsQueryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrgTeacherScheduleSettingsQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    teacherScheduleSettings: Array<{
      __typename?: "ScheduleSettings";
      uid: string;
      mininumNoticeHours: number;
      maximumNoticeHours: number;
      bufferTimeMinutes: number;
      meetingsStartTimeIncrementsInMinutes: number;
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        titleTranslated: string | null;
      };
      availabilities: Array<{
        __typename?: "TeacherScheduleAvailability";
        uid: string | null;
        startTime: any | null;
        endTime: any | null;
        isRecurring: boolean | null;
        recurringEndTime: any | null;
        isUnavailable: boolean | null;
      }>;
    }>;
  } | null;
};

export type PrivateMeetingRequestsQueryVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type PrivateMeetingRequestsQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    isClub: boolean;
    slug_id: string;
    duration_minutes: number | null;
    privateMeetingRequests: Array<{
      __typename?: "MeetingRequest";
      uid: string;
      start_time: any;
      end_time: any;
      is_recurring: boolean;
      orgTeacher: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
      } | null;
    }>;
  };
};

export type AddPrivateMeetingRequestMutationVariables = Exact<{
  activityUid: Scalars["ID"];
  proposedTime: StartEndTime;
  isRecurring: Scalars["Boolean"];
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
}>;

export type AddPrivateMeetingRequestMutation = {
  __typename?: "Mutation";
  addPrivateMeetingRequest: {
    __typename?: "MeetingRequest";
    uid: string;
    start_time: any;
    end_time: any;
    is_recurring: boolean;
    orgTeacher: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
    } | null;
  };
};

export type ActivityAvailability_ScheduleSettingsFragment = {
  __typename?: "ScheduleSettings";
  uid: string;
  mininumNoticeHours: number;
  maximumNoticeHours: number;
  bufferTimeMinutes: number;
  meetingsStartTimeIncrementsInMinutes: number;
  availabilities: Array<{
    __typename?: "TeacherScheduleAvailability";
    uid: string | null;
    startTime: any | null;
    endTime: any | null;
    isRecurring: boolean | null;
    recurringEndTime: any | null;
    isUnavailable: boolean | null;
  }>;
};

export type ActivityAvailability_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  title: string;
  titleTranslated: string | null;
  size_max: number | null;
  isClub: boolean;
  hasTeacherSchedule: boolean;
  autoSchedulingDaysNoticeMin: number | null;
  autoSchedulingDaysNoticeMax: number | null;
  esaStatus: EsaStatus;
  is_ongoing_weekly: boolean;
  isSelfPaced: boolean;
  duration_weeks: number | null;
  user_uid: string;
  archived_at: any | null;
  published_at: any | null;
  requested_listing_at: any | null;
  scheduleSettings: Array<{
    __typename?: "ScheduleSettings";
    uid: string;
    mininumNoticeHours: number;
    maximumNoticeHours: number;
    bufferTimeMinutes: number;
    meetingsStartTimeIncrementsInMinutes: number;
    availabilities: Array<{
      __typename?: "TeacherScheduleAvailability";
      uid: string | null;
      startTime: any | null;
      endTime: any | null;
      isRecurring: boolean | null;
      recurringEndTime: any | null;
      isUnavailable: boolean | null;
    }>;
  }>;
  enrollableTimes: Array<{
    __typename?: "EnrollableTime";
    startTime: any;
    endTime: any;
    teacher: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      hasBackgroundCheckExpired: boolean | null;
      teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    };
  }>;
  approvalStatus: {
    __typename?: "ActivityApprovalStatus";
    action: string;
    message_to_teacher: string | null;
  } | null;
};

export type ActivityAvailabilityQueryVariables = Exact<{
  uid: Scalars["ID"];
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
  esaState?: InputMaybe<Scalars["String"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  endBy?: InputMaybe<Scalars["DateTime"]>;
  filterByDow?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]>> | InputMaybe<Scalars["Int"]>
  >;
  recurringOnly?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ActivityAvailabilityQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    titleTranslated: string | null;
    size_max: number | null;
    isClub: boolean;
    hasTeacherSchedule: boolean;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    esaStatus: EsaStatus;
    is_ongoing_weekly: boolean;
    isSelfPaced: boolean;
    duration_weeks: number | null;
    user_uid: string;
    archived_at: any | null;
    published_at: any | null;
    requested_listing_at: any | null;
    scheduleSettings: Array<{
      __typename?: "ScheduleSettings";
      uid: string;
      mininumNoticeHours: number;
      maximumNoticeHours: number;
      bufferTimeMinutes: number;
      meetingsStartTimeIncrementsInMinutes: number;
      availabilities: Array<{
        __typename?: "TeacherScheduleAvailability";
        uid: string | null;
        startTime: any | null;
        endTime: any | null;
        isRecurring: boolean | null;
        recurringEndTime: any | null;
        isUnavailable: boolean | null;
      }>;
    }>;
    enrollableTimes: Array<{
      __typename?: "EnrollableTime";
      startTime: any;
      endTime: any;
      teacher: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        hasBackgroundCheckExpired: boolean | null;
        teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      };
    }>;
    approvalStatus: {
      __typename?: "ActivityApprovalStatus";
      action: string;
      message_to_teacher: string | null;
    } | null;
  };
};

export type ActivityCharterSchoolEnrollPageQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type ActivityCharterSchoolEnrollPageQuery = {
  __typename?: "Query";
  enrollmentForm: {
    __typename?: "EnrollmentForm";
    section: {
      __typename?: "Section";
      uid: string;
      filledSpaceCount: number;
      allowsLateEnrollment: boolean;
      activity_uid: string;
      start_time: any | null;
      end_time: any | null;
      startsAtNight: boolean | null;
      is_cross_listed: boolean;
      size_max: number | null;
      size_min: number | null;
      isPublished: boolean | null;
      price_cents: number | null;
      priceCredits: number | null;
      published_at: any | null;
      updated_at: any;
      canceled_at: any | null;
      deleted_at: any | null;
      ongoing_stopped_at: any | null;
      duration_minutes: number | null;
      usesOutschoolVideoChat: boolean;
      usersCanSendPrivateMessage: boolean;
      currentUserCanManage: boolean;
      isBuyable: boolean;
      allowsTeacherTransfer: boolean;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        hasBackgroundCheckExpired: boolean | null;
        photo: string | null;
        leader_link: string | null;
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        video: {
          __typename?: "Attachment";
          file: { __typename?: "File"; url: string; thumbUrl: string | null };
        } | null;
      };
      progress: {
        __typename?: "CourseProgress";
        pastMeetingCount: number;
        remainingMeetingCount: number;
        totalMeetingCount: number;
        nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
      } | null;
      syllabus: {
        __typename?: "Syllabus";
        uid: string;
        lessons: Array<{ __typename?: "Lesson"; uid: string }>;
      } | null;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        deleted_at: any | null;
        created_at: any | null;
      } | null;
      details: {
        __typename?: "SectionDetails";
        autoScheduledDraft: boolean | null;
      };
      prepaidOngoingPeriodOptions: Array<{
        __typename?: "Period";
        startOfIsoWeek: any;
        endOfIsoWeek: any;
      }> | null;
    };
    activity: {
      __typename?: "Activity";
      uid: string;
      suggestedWeeklyMeetings: number | null;
      reviewCount: number;
      esaStatus: EsaStatus;
      slug_id: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      class_experience: string | null;
      learning_goals: string | null;
      learningGoals: Array<string>;
      assessment: string | null;
      homework: string | null;
      grading_policy: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      grade_level_min: string | null;
      grade_level_max: string | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      priceCredits: number | null;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      allowWaitlist: boolean | null;
      isClub: boolean;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      allows_recurring_payment: boolean | null;
      allows_late_enrollments_for_fixed_length_classes: boolean;
      has_building_content: boolean | null;
      hasTeacherSchedule: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      classFormat: EducatorSelectedFormat;
      classroomType: ClassroomType;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
        created_at: any | null;
        photo: string | null;
        suspended_at: any | null;
        cityState: string | null;
        socialOptOut: boolean;
        is_crosslister: boolean | null;
        timeZone: string | null;
        hasAvailabilitySet: boolean;
        approved: boolean;
        hasBackgroundCheckExpired: boolean | null;
        reviewCount: number;
        averageActivityStarRating: number | null;
        hasListings: boolean;
        details: {
          __typename?: "LeaderDetails";
          about: string | null;
          headline: string | null;
          headlineTranslated: string | null;
          countryOfResidence: string | null;
        };
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        teacherForSellerOrg: {
          __typename?: "SellerOrg";
          uid: string;
          name: string;
        } | null;
        video: {
          __typename?: "Attachment";
          file: { __typename?: "File"; url: string; thumbUrl: string | null };
        } | null;
      };
      strictAgeRange: {
        __typename?: "AgeRangeType";
        min: number;
        max: number;
      } | null;
      pricingInfo: {
        __typename?: "PricingInfo";
        totalPriceCentsForUpfrontPayment: number;
        totalPriceCentsForWeeklyPayments: number;
        weeklyPriceCentsForUpfrontPayment: number;
        weeklyPriceCentsForWeeklyPayments: number;
        upfrontDiscountPercentage: number;
        totalPriceCreditsForUpfrontPayment: number;
        weeklyPriceCreditsForUpfrontPayment: number;
        totalPriceCreditsForWeeklyPayments: number;
        weeklyPriceCreditsForWeeklyPayments: number;
      } | null;
      proficiency_level: {
        __typename?: "ProficiencyLevel";
        level: Array<string | null> | null;
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
      };
      details: {
        __typename?: "ActivityDetails";
        photo: string | null;
        photoSquare: string | null;
      };
    };
  } | null;
};

export type ActivityCharterSchoolEnrollSelfPacedQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type ActivityCharterSchoolEnrollSelfPacedQuery = {
  __typename?: "Query";
  selfPacedEnrollmentForm: {
    __typename?: "SelfPacedEnrollmentForm";
    activity: {
      __typename?: "Activity";
      uid: string;
      suggestedWeeklyMeetings: number | null;
      reviewCount: number;
      esaStatus: EsaStatus;
      slug_id: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      class_experience: string | null;
      learning_goals: string | null;
      learningGoals: Array<string>;
      assessment: string | null;
      homework: string | null;
      grading_policy: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      grade_level_min: string | null;
      grade_level_max: string | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      priceCredits: number | null;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      allowWaitlist: boolean | null;
      isClub: boolean;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      allows_recurring_payment: boolean | null;
      allows_late_enrollments_for_fixed_length_classes: boolean;
      has_building_content: boolean | null;
      hasTeacherSchedule: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      classFormat: EducatorSelectedFormat;
      classroomType: ClassroomType;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
        created_at: any | null;
        photo: string | null;
        suspended_at: any | null;
        cityState: string | null;
        socialOptOut: boolean;
        is_crosslister: boolean | null;
        timeZone: string | null;
        hasAvailabilitySet: boolean;
        approved: boolean;
        hasBackgroundCheckExpired: boolean | null;
        reviewCount: number;
        averageActivityStarRating: number | null;
        hasListings: boolean;
        details: {
          __typename?: "LeaderDetails";
          about: string | null;
          headline: string | null;
          headlineTranslated: string | null;
          countryOfResidence: string | null;
        };
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        teacherForSellerOrg: {
          __typename?: "SellerOrg";
          uid: string;
          name: string;
        } | null;
        video: {
          __typename?: "Attachment";
          file: { __typename?: "File"; url: string; thumbUrl: string | null };
        } | null;
      };
      strictAgeRange: {
        __typename?: "AgeRangeType";
        min: number;
        max: number;
      } | null;
      pricingInfo: {
        __typename?: "PricingInfo";
        totalPriceCentsForUpfrontPayment: number;
        totalPriceCentsForWeeklyPayments: number;
        weeklyPriceCentsForUpfrontPayment: number;
        weeklyPriceCentsForWeeklyPayments: number;
        upfrontDiscountPercentage: number;
        totalPriceCreditsForUpfrontPayment: number;
        weeklyPriceCreditsForUpfrontPayment: number;
        totalPriceCreditsForWeeklyPayments: number;
        weeklyPriceCreditsForWeeklyPayments: number;
      } | null;
      proficiency_level: {
        __typename?: "ProficiencyLevel";
        level: Array<string | null> | null;
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
      };
      details: {
        __typename?: "ActivityDetails";
        photo: string | null;
        photoSquare: string | null;
      };
    };
  };
};

export type SimilarListingsUnpublishedQueryVariables = Exact<{
  activitySlugId: Scalars["String"];
  withRecentReviews: Scalars["Boolean"];
  skipRating: Scalars["Boolean"];
}>;

export type SimilarListingsUnpublishedQuery = {
  __typename?: "Query";
  similarListingsUnpublished: {
    __typename?: "SimilarListings";
    popularElasticsearch: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        hasTeacherSchedule: boolean;
        size_max: number | null;
        user_uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
      } | null;
    }>;
  };
};

export type ActivityLessonPostFragmentFragment = {
  __typename?: "LessonPost";
  uid: string;
  title: string | null;
  lessonUid: string;
  orderInLesson: number;
  deleted_at: any | null;
  is_posted_for_enrollments: boolean;
  is_posted_for_subscriptions: boolean;
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type AssignmentLessonPostFragmentFragment = {
  __typename?: "AssignmentLessonPost";
  uid: string;
  title: string | null;
  lessonUid: string;
  orderInLesson: number;
  deleted_at: any | null;
  submissionRequirement: SubmissionRequirementType;
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type ActivityPosts_LessonFragment = {
  __typename?: "Lesson";
  uid: string;
  title: string | null;
  description: string | null;
  week_number: number;
  order_within_week: number;
  lessonNumber: number | null;
  unit: {
    __typename?: "Unit";
    uid: string;
    title: string | null;
    unitNumber: number | null;
  } | null;
  lessonPosts: Array<{
    __typename?: "LessonPost";
    uid: string;
    title: string | null;
    lessonUid: string;
    orderInLesson: number;
    deleted_at: any | null;
    is_posted_for_enrollments: boolean;
    is_posted_for_subscriptions: boolean;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  }>;
  assignmentLessonPosts: Array<{
    __typename?: "AssignmentLessonPost";
    uid: string;
    title: string | null;
    lessonUid: string;
    orderInLesson: number;
    deleted_at: any | null;
    submissionRequirement: SubmissionRequirementType;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  }>;
};

export type ActivityLessonPostsQueryQueryVariables = Exact<{
  uid: Scalars["ID"];
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ActivityLessonPostsQueryQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    duration_weeks: number | null;
    isSelfPaced: boolean;
    slug_id: string;
    user_uid: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    learningGoals: Array<string>;
    assessment: string | null;
    homework: string | null;
    grading_policy: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    english_proficiency_level: EnglishProficiencyLevel | null;
    grade_level_min: string | null;
    grade_level_max: string | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    priceCredits: number | null;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    allowWaitlist: boolean | null;
    isClub: boolean;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    allows_recurring_payment: boolean | null;
    allows_late_enrollments_for_fixed_length_classes: boolean;
    has_building_content: boolean | null;
    hasTeacherSchedule: boolean;
    isFlexSchedule: boolean;
    isOneTime: boolean;
    classFormat: EducatorSelectedFormat;
    classroomType: ClassroomType;
    saveCount: number | null;
    archived_at: any | null;
    upsell_activity_uid: string | null;
    why_me: string | null;
    parental_guidance: string | null;
    sources: string | null;
    materials: string | null;
    hasSections: boolean;
    requested_listing_at: any | null;
    has_external_resources: boolean | null;
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      units: Array<{ __typename?: "Unit"; uid: string; title: string | null }>;
      lessons: Array<{
        __typename?: "Lesson";
        uid: string;
        title: string | null;
        description: string | null;
        week_number: number;
        order_within_week: number;
        lessonNumber: number | null;
        unit: {
          __typename?: "Unit";
          uid: string;
          title: string | null;
          unitNumber: number | null;
        } | null;
        lessonPosts: Array<{
          __typename?: "LessonPost";
          uid: string;
          title: string | null;
          lessonUid: string;
          orderInLesson: number;
          deleted_at: any | null;
          is_posted_for_enrollments: boolean;
          is_posted_for_subscriptions: boolean;
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
        assignmentLessonPosts: Array<{
          __typename?: "AssignmentLessonPost";
          uid: string;
          title: string | null;
          lessonUid: string;
          orderInLesson: number;
          deleted_at: any | null;
          submissionRequirement: SubmissionRequirementType;
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      }>;
    } | null;
    selfPacedMigration: {
      __typename?: "SelfPacedMigrationStatus";
      hasConsented: boolean;
      consentedAt: any | null;
    } | null;
    strictAgeRange: {
      __typename?: "AgeRangeType";
      min: number;
      max: number;
    } | null;
    proficiency_level: {
      __typename?: "ProficiencyLevel";
      level: Array<string | null> | null;
      text: string | null;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      description: string;
      type: RefundPolicyType;
    };
    details: {
      __typename?: "ActivityDetails";
      photo: string | null;
      photoSquare: string | null;
      asyncTime: string | null;
    };
    leaderDetails: {
      __typename?: "ActivityLeaderDetails";
      uid: string;
      requestedListingAt: any | null;
      welcomeMessage: {
        __typename?: "WelcomeMessage";
        text: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        }> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        } | null;
      } | null;
    };
    approvalStatus: {
      __typename?: "ActivityApprovalStatus";
      action: string;
      message_to_teacher: string | null;
    } | null;
    externalResources: Array<{
      __typename?: "ExternalResource";
      uid: string;
      name: string;
      url: string | null;
    }>;
  };
};

export type AddAssignmentLessonPostMutationVariables = Exact<{
  createAssignmentLessonPostInput: CreateAssignmentLessonPostInput;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AddAssignmentLessonPostMutation = {
  __typename?: "Mutation";
  addAssignmentLessonPost: {
    __typename?: "AssignmentLessonPost";
    uid: string;
    title: string | null;
    lessonUid: string;
    orderInLesson: number;
    deleted_at: any | null;
    submissionRequirement: SubmissionRequirementType;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type DeleteAssignmentLessonPostMutationVariables = Exact<{
  assignmentLessonPostUid: Scalars["ID"];
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type DeleteAssignmentLessonPostMutation = {
  __typename?: "Mutation";
  deleteAssignmentLessonPost: {
    __typename?: "AssignmentLessonPost";
    uid: string;
    title: string | null;
    lessonUid: string;
    orderInLesson: number;
    deleted_at: any | null;
    submissionRequirement: SubmissionRequirementType;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type UpdateAssignmentLessonPostMutationVariables = Exact<{
  assignmentLessonPostUid: Scalars["ID"];
  updateAssignmentLessonPostInput: UpdateAssignmentLessonPostInput;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateAssignmentLessonPostMutation = {
  __typename?: "Mutation";
  updateAssignmentLessonPost: {
    __typename?: "AssignmentLessonPost";
    uid: string;
    title: string | null;
    lessonUid: string;
    orderInLesson: number;
    deleted_at: any | null;
    submissionRequirement: SubmissionRequirementType;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type ConvertAssignmentLessonPostToAssignmentClassPostMutationVariables =
  Exact<{
    assignmentLessonPostUid: Scalars["ID"];
    sectionUid: Scalars["ID"];
    publishAt?: InputMaybe<Scalars["DateTime"]>;
    plainText?: InputMaybe<Scalars["Boolean"]>;
  }>;

export type ConvertAssignmentLessonPostToAssignmentClassPostMutation = {
  __typename?: "Mutation";
  convertAssignmentLessonPostToAssignmentClassPost: {
    __typename?: "AssignmentClassPost";
    uid: string;
    title: string;
    created_at: any;
    deleted_at: any | null;
    publishAt: any;
    dueDate: any | null;
    submissionRequirement: SubmissionRequirementType;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    lesson: { __typename?: "Lesson"; uid: string; title: string | null } | null;
    lessonPost: {
      __typename?: "AssignmentLessonPost";
      uid: string;
      title: string | null;
      lessonUid: string;
      orderInLesson: number;
      deleted_at: any | null;
      submissionRequirement: SubmissionRequirementType;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    } | null;
  };
};

export type LessonPostFragmentFragment = {
  __typename?: "LessonPost";
  uid: string;
  title: string | null;
  lessonUid: string;
  orderInLesson: number;
  deleted_at: any | null;
  is_posted_for_enrollments: boolean;
  is_posted_for_subscriptions: boolean;
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type AddLessonPostMutationVariables = Exact<{
  createLessonPostInput: CreateLessonPostInput;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AddLessonPostMutation = {
  __typename?: "Mutation";
  addLessonPost: {
    __typename?: "LessonPost";
    uid: string;
    title: string | null;
    lessonUid: string;
    orderInLesson: number;
    deleted_at: any | null;
    is_posted_for_enrollments: boolean;
    is_posted_for_subscriptions: boolean;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type DeleteLessonPostMutationVariables = Exact<{
  lessonPostUid: Scalars["ID"];
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type DeleteLessonPostMutation = {
  __typename?: "Mutation";
  deleteLessonPost: {
    __typename?: "LessonPost";
    uid: string;
    title: string | null;
    lessonUid: string;
    orderInLesson: number;
    deleted_at: any | null;
    is_posted_for_enrollments: boolean;
    is_posted_for_subscriptions: boolean;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type UpdateLessonPostMutationVariables = Exact<{
  lessonPostUid: Scalars["ID"];
  updateLessonPostInput: UpdateLessonPostInput;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateLessonPostMutation = {
  __typename?: "Mutation";
  updateLessonPost: {
    __typename?: "LessonPost";
    uid: string;
    title: string | null;
    lessonUid: string;
    orderInLesson: number;
    deleted_at: any | null;
    is_posted_for_enrollments: boolean;
    is_posted_for_subscriptions: boolean;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type ConvertLessonPostToClassPostMutationVariables = Exact<{
  lessonPostUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ConvertLessonPostToClassPostMutation = {
  __typename?: "Mutation";
  convertLessonPostToClassPost: {
    __typename?: "ClassPost";
    uid: string;
    isWelcomePost: boolean;
    publishAt: any;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  };
};

export type ActivityScheduleQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type ActivityScheduleQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    waitlistSeatsCount: number | null;
    allowWaitlist: boolean | null;
    requested_listing_at: any | null;
    canRequestListing: boolean;
    uid: string;
    slug_id: string;
    user_uid: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    learningGoals: Array<string>;
    assessment: string | null;
    homework: string | null;
    grading_policy: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    english_proficiency_level: EnglishProficiencyLevel | null;
    grade_level_min: string | null;
    grade_level_max: string | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    priceCredits: number | null;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    isClub: boolean;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    allows_recurring_payment: boolean | null;
    allows_late_enrollments_for_fixed_length_classes: boolean;
    has_building_content: boolean | null;
    hasTeacherSchedule: boolean;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isOneTime: boolean;
    classFormat: EducatorSelectedFormat;
    classroomType: ClassroomType;
    saveCount: number | null;
    archived_at: any | null;
    upsell_activity_uid: string | null;
    why_me: string | null;
    parental_guidance: string | null;
    sources: string | null;
    materials: string | null;
    hasSections: boolean;
    tags: string | null;
    isSelfPacedEnrollmentEnabled: boolean;
    has_external_resources: boolean | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    };
    selfPacedMigration: {
      __typename?: "SelfPacedMigrationStatus";
      hasConsented: boolean;
      consentedAt: any | null;
    } | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    bundles: Array<{
      __typename?: "Bundle";
      uid: string;
      title: string;
      thumbnail: string | null;
      isOrdered: boolean;
      bundleElements: Array<{
        __typename?: "BundleElement";
        uid: string;
        orderInBundle: number;
        activity: {
          __typename?: "Activity";
          age_min: number | null;
          age_max: number | null;
          details: { __typename?: "ActivityDetails"; photo: string | null };
        };
      }>;
    }>;
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      published_at: string | null;
      lessons: Array<{
        __typename?: "Lesson";
        uid: string;
        week_number: number;
        order_within_week: number;
        title: string | null;
        description: string | null;
        lessonNumber: number | null;
        unit: { __typename?: "Unit"; uid: string; title: string | null } | null;
      }>;
    } | null;
    strictAgeRange: {
      __typename?: "AgeRangeType";
      min: number;
      max: number;
    } | null;
    proficiency_level: {
      __typename?: "ProficiencyLevel";
      level: Array<string | null> | null;
      text: string | null;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      description: string;
      type: RefundPolicyType;
    };
    details: {
      __typename?: "ActivityDetails";
      photo: string | null;
      photoSquare: string | null;
      asyncTime: string | null;
    };
    leaderDetails: {
      __typename?: "ActivityLeaderDetails";
      uid: string;
      requestedListingAt: any | null;
      welcomeMessage: {
        __typename?: "WelcomeMessage";
        text: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        }> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
          };
        } | null;
      } | null;
    };
    approvalStatus: {
      __typename?: "ActivityApprovalStatus";
      action: string;
      message_to_teacher: string | null;
    } | null;
    classScheduleRequests: Array<{
      __typename?: "ClassScheduleRequest";
      uid: string;
      timeZone: string;
      message: string | null;
      created_at: any;
      parentEnrolledAt: any | null;
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      timetable: {
        __typename?: "Timetable";
        mon: {
          __typename?: "TimetableTimes";
          f8_12: boolean;
          f12_4: boolean;
          f4_8: boolean;
        };
        tue: {
          __typename?: "TimetableTimes";
          f8_12: boolean;
          f12_4: boolean;
          f4_8: boolean;
        };
        wed: {
          __typename?: "TimetableTimes";
          f8_12: boolean;
          f12_4: boolean;
          f4_8: boolean;
        };
        thu: {
          __typename?: "TimetableTimes";
          f8_12: boolean;
          f12_4: boolean;
          f4_8: boolean;
        };
        fri: {
          __typename?: "TimetableTimes";
          f8_12: boolean;
          f12_4: boolean;
          f4_8: boolean;
        };
        sat: {
          __typename?: "TimetableTimes";
          f8_12: boolean;
          f12_4: boolean;
          f4_8: boolean;
        };
        sun: {
          __typename?: "TimetableTimes";
          f8_12: boolean;
          f12_4: boolean;
          f4_8: boolean;
        };
      };
    }>;
    externalResources: Array<{
      __typename?: "ExternalResource";
      uid: string;
      name: string;
      url: string | null;
    }>;
  };
};

export type SectionsForActivityScheduleQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  filter?: InputMaybe<SectionFilter>;
  skipSectionFiltersForClub?: InputMaybe<Scalars["Boolean"]>;
  ignoreSelfPaced?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  forcedOrderBy?: InputMaybe<Scalars["String"]>;
}>;

export type SectionsForActivityScheduleQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    waitlistSeatsCount: number | null;
    paginatedFilteredSections: {
      __typename?: "SectionList";
      totalCount: number;
      data: Array<{
        __typename?: "Section";
        filledSpaceCount: number;
        waitlistSeatsCount: number | null;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        created_at: any;
        validEnrollmentCount: number;
        isAutoCanceled: boolean;
        currentUserIsOwner: boolean;
        paidAmount: number | null;
        meetings: Array<{
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        }>;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
          onlineClassroom: {
            __typename?: "OnlineClassroom";
            type: string | null;
            url: string | null;
          } | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
        checklist: {
          __typename?: "SectionChecklist";
          autoCanceledAt: any | null;
          grandfatheredAt2017Rate: boolean | null;
        };
      }>;
    };
  };
};

export type EnrollmentsForLearnerListQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  enrollmentParams?: InputMaybe<EnrollmentParamsInput>;
}>;

export type EnrollmentsForLearnerListQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    enrollmentCount: number;
    enrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      section_uid: string | null;
      isRepeatLearnerOfTeacher: boolean;
      nextMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        avatar: string | null;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        activity: { __typename?: "Activity"; uid: string };
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
    }>;
  };
};

export type ParentScheduleRequestsSection_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  title: string;
  titleTranslated: string | null;
  classScheduleRequests: Array<{
    __typename?: "ClassScheduleRequest";
    uid: string;
    timeZone: string;
    message: string | null;
    created_at: any;
    parentEnrolledAt: any | null;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
    };
    timetable: {
      __typename?: "Timetable";
      mon: {
        __typename?: "TimetableTimes";
        f8_12: boolean;
        f12_4: boolean;
        f4_8: boolean;
      };
      tue: {
        __typename?: "TimetableTimes";
        f8_12: boolean;
        f12_4: boolean;
        f4_8: boolean;
      };
      wed: {
        __typename?: "TimetableTimes";
        f8_12: boolean;
        f12_4: boolean;
        f4_8: boolean;
      };
      thu: {
        __typename?: "TimetableTimes";
        f8_12: boolean;
        f12_4: boolean;
        f4_8: boolean;
      };
      fri: {
        __typename?: "TimetableTimes";
        f8_12: boolean;
        f12_4: boolean;
        f4_8: boolean;
      };
      sat: {
        __typename?: "TimetableTimes";
        f8_12: boolean;
        f12_4: boolean;
        f4_8: boolean;
      };
      sun: {
        __typename?: "TimetableTimes";
        f8_12: boolean;
        f12_4: boolean;
        f4_8: boolean;
      };
    };
  }>;
};

export type SelfPacedSectionsForLearnerListQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  filter?: InputMaybe<SectionFilter>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sellerOrgTeacherUid?: InputMaybe<Scalars["String"]>;
  forcedOrderBy?: InputMaybe<Scalars["String"]>;
}>;

export type SelfPacedSectionsForLearnerListQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    waitlistSeatsCount: number | null;
    paginatedFilteredSections: {
      __typename?: "SectionList";
      totalCount: number;
      data: Array<{
        __typename?: "Section";
        is_self_paced: boolean;
        isSelfPacedReadOnly: boolean;
        isInProgress: boolean;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        enrollments: Array<{
          __typename?: "Enrollment";
          uid: string;
          section_uid: string | null;
          hasPaymentSchedule: boolean;
          learner: {
            __typename?: "Learner";
            uid: string;
            name: string | null;
            avatar: string | null;
          } | null;
        }>;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
      }>;
    };
  };
};

export type AddTnsIncidentMutationVariables = Exact<{
  userUid: Scalars["ID"];
  action: TnsAction;
  category?: InputMaybe<TnsActionCategory>;
  link?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
}>;

export type AddTnsIncidentMutation = {
  __typename?: "Mutation";
  addUserIncident: boolean;
};

export type AddBulkGiftCardsMutationMutationVariables = Exact<{
  data: BulkGiftCardsInput;
}>;

export type AddBulkGiftCardsMutationMutation = {
  __typename?: "Mutation";
  addBulkGiftCards: {
    __typename?: "BulkGiftCardResponse";
    orderSlugId: string;
    redemptionCodes: Array<string>;
  };
};

export type GetBundleQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type GetBundleQuery = {
  __typename?: "Query";
  bundle: {
    __typename?: "Bundle";
    uid: string;
    title: string;
    description: string | null;
    isOrdered: boolean;
    thumbnail: string | null;
    discount: number | null;
    bundleElements: Array<{
      __typename?: "BundleElement";
      uid: string;
      orderInBundle: number;
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        age_min: number | null;
        age_max: number | null;
        archived_at: any | null;
        published_at: any | null;
        requested_listing_at: any | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        approvalStatus: {
          __typename?: "ActivityApprovalStatus";
          action: string;
          message_to_teacher: string | null;
        } | null;
      };
    }>;
  };
};

export type GetBundleEligibleActivitiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetBundleEligibleActivitiesQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    leaderActivities: Array<{
      __typename?: "Activity";
      uid: string;
      title: string;
      age_min: number | null;
      age_max: number | null;
      archived_at: any | null;
      published_at: any | null;
      requested_listing_at: any | null;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      approvalStatus: {
        __typename?: "ActivityApprovalStatus";
        action: string;
        message_to_teacher: string | null;
      } | null;
    }>;
  } | null;
};

export type UpdateBundleMutationVariables = Exact<{
  bundleUid: Scalars["ID"];
  updateBundleInput: UpdateBundleInput;
}>;

export type UpdateBundleMutation = {
  __typename?: "Mutation";
  updateBundle: { __typename?: "Bundle"; uid: string };
};

export type DeleteBundleMutationVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type DeleteBundleMutation = {
  __typename?: "Mutation";
  deleteBundle: { __typename?: "Bundle"; uid: string };
};

export type HasPinSetQueryVariables = Exact<{ [key: string]: never }>;

export type HasPinSetQuery = {
  __typename?: "Query";
  hasPinSet: boolean | null;
};

export type ClassroomTimeBoundsQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type ClassroomTimeBoundsQuery = {
  __typename?: "Query";
  classroom: {
    __typename?: "Classroom";
    uid: string;
    section: {
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
    };
    currentUserEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
    }>;
  };
};

export type ClassroomQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
  hasLearner: Scalars["Boolean"];
  startBefore: Scalars["DateTime"];
  start?: InputMaybe<Scalars["DateTime"]>;
  end?: InputMaybe<Scalars["DateTime"]>;
  plainText?: InputMaybe<Scalars["Boolean"]>;
  postFilter?: InputMaybe<ClassroomPostFilter>;
  postOrder?: InputMaybe<ClassroomPostOrder>;
}>;

export type ClassroomQuery = {
  __typename?: "Query";
  classroom: {
    __typename?: "Classroom";
    uid: string;
    usersCanHaveLiveMeeting: boolean;
    usersCanPost: boolean;
    usersCanComment: boolean;
    currentUserEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      isEnded: boolean;
      isPaused: boolean;
      usersCanCommunicate: boolean;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
      } | null;
    }>;
    section: {
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
      currentUserCanManage: boolean;
      filledSpaceCount: number;
      usesOutschoolVideoChat: boolean;
      latestProgressReportDate: any | null;
      published_at: any | null;
      deleted_at: any | null;
      canceled_at: any | null;
      startsAtNight: boolean | null;
      is_self_paced: boolean;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
      } | null;
      lastMeeting: Array<{
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
      }>;
      meetings: Array<{
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        recordingAvailability: RecordingAvailability;
        recordingRequests: Array<{
          __typename?: "RecordingRequest";
          requestedAt: any;
          message: string;
          requestedBy: {
            __typename?: "Learner";
            uid: string;
            name: string | null;
          };
        }>;
        classRecordings: Array<
          | {
              __typename?: "ClassRecording";
              uid: string;
              startTime: any;
              endTime: any;
            }
          | {
              __typename?: "ZoomRecording";
              uid: string;
              startTime: any;
              endTime: any;
            }
        >;
        attendances: Array<{
          __typename?: "Attendance";
          learner: { __typename?: "Learner"; name: string | null };
        }>;
      }>;
      assignmentClassPosts: Array<{
        __typename?: "AssignmentClassPost";
        uid: string;
      }>;
      syllabus: {
        __typename?: "Syllabus";
        uid: string;
        published_at: string | null;
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
    };
    activity: {
      __typename?: "Activity";
      uid: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      is_ongoing_weekly: boolean;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      is_online: boolean;
      hasTeacherSchedule: boolean;
      archived_at: any | null;
      published_at: any | null;
      requested_listing_at: any | null;
      isClub: boolean;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      leader: {
        __typename?: "Leader";
        uid: string;
        leader_link: string | null;
        name: string | null;
      };
      approvalStatus: {
        __typename?: "ActivityApprovalStatus";
        action: string;
        message_to_teacher: string | null;
      } | null;
    };
    learners: Array<{
      __typename?: "LearnerProfile";
      uid: string;
      name: string | null;
      avatar: string | null;
    }>;
    posts: Array<{
      __typename?: "ClassPost";
      uid: string;
      isWelcomePost: boolean;
      publishAt: any;
      title: string | null;
      promotedAt: any | null;
      pinnedAt: any | null;
      mentionedEnrollmentUids: Array<string>;
      backgroundColor: string | null;
      score: number | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      commentsPreview: {
        __typename?: "CommentsPreview";
        remainingCommentsCount: number;
        comments: Array<{
          __typename?: "ClassPostComment";
          uid: string;
          sendAt: any;
          mentionedEnrollmentUids: Array<string>;
          mentionedTeacherUid: string | null;
          backgroundColor: string | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      };
    }>;
    unpublishedPosts: Array<{
      __typename?: "ClassPost";
      uid: string;
      isWelcomePost: boolean;
      publishAt: any;
      title: string | null;
      promotedAt: any | null;
      pinnedAt: any | null;
      mentionedEnrollmentUids: Array<string>;
      backgroundColor: string | null;
      score: number | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      commentsPreview: {
        __typename?: "CommentsPreview";
        remainingCommentsCount: number;
        comments: Array<{
          __typename?: "ClassPostComment";
          uid: string;
          sendAt: any;
          mentionedEnrollmentUids: Array<string>;
          mentionedTeacherUid: string | null;
          backgroundColor: string | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      };
    }>;
    mentionNames: Array<{
      __typename?: "MentionName";
      id: string;
      display: string;
      avatar: string | null;
      startTime: any | null;
    }>;
  };
  learner?: { __typename?: "Learner"; uid: string; name: string | null } | null;
};

export type ClubManageListingQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type ClubManageListingQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    saveCount: number | null;
    waitlistSeatsCount: number | null;
    requested_listing_at: any | null;
    canRequestListing: boolean;
    uid: string;
    slug_id: string;
    user_uid: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    learningGoals: Array<string>;
    assessment: string | null;
    homework: string | null;
    grading_policy: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    english_proficiency_level: EnglishProficiencyLevel | null;
    grade_level_min: string | null;
    grade_level_max: string | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    priceCredits: number | null;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    allowWaitlist: boolean | null;
    isClub: boolean;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    allows_recurring_payment: boolean | null;
    allows_late_enrollments_for_fixed_length_classes: boolean;
    has_building_content: boolean | null;
    hasTeacherSchedule: boolean;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isOneTime: boolean;
    classFormat: EducatorSelectedFormat;
    classroomType: ClassroomType;
    tags: string | null;
    archived_at: any | null;
    isSelfPacedEnrollmentEnabled: boolean;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    sections: Array<{
      __typename?: "Section";
      filledSpaceCount: number;
      waitlistSeatsCount: number | null;
      uid: string;
      published_at: any | null;
      deleted_at: any | null;
      canceled_at: any | null;
      start_time: any | null;
      end_time: any | null;
      size_max: number | null;
      currentUserCanManage: boolean;
    }>;
    strictAgeRange: {
      __typename?: "AgeRangeType";
      min: number;
      max: number;
    } | null;
    proficiency_level: {
      __typename?: "ProficiencyLevel";
      level: Array<string | null> | null;
      text: string | null;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      description: string;
      type: RefundPolicyType;
    };
    details: {
      __typename?: "ActivityDetails";
      photo: string | null;
      photoSquare: string | null;
    };
    approvalStatus: {
      __typename?: "ActivityApprovalStatus";
      action: string;
      message_to_teacher: string | null;
    } | null;
  };
};

export type FeaturedClubCard_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  slug_id: string;
  isClub: boolean;
  title: string;
  titleTranslated: string | null;
  summary: string | null;
  age_max: number | null;
  age_min: number | null;
  details: { __typename?: "ActivityDetails"; photo: string | null };
  sampleClubPost: {
    __typename?: "WelcomeMessage";
    text: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      file: { __typename?: "File"; url: string; mimetype: string | null };
    }> | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      file: { __typename?: "File"; url: string; mimetype: string | null };
    } | null;
  } | null;
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    photo: string | null;
  };
};

export type ClubCard_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  slug_id: string;
  isClub: boolean;
  title: string;
  titleTranslated: string | null;
  summary: string | null;
  age_min: number | null;
  age_max: number | null;
  details: { __typename?: "ActivityDetails"; photo: string | null };
};

export type ClubsLandingPageQueryVariables = Exact<{ [key: string]: never }>;

export type ClubsLandingPageQuery = {
  __typename?: "Query";
  featuredClubs: Array<{
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    isClub: boolean;
    title: string;
    titleTranslated: string | null;
    summary: string | null;
    age_max: number | null;
    age_min: number | null;
    details: { __typename?: "ActivityDetails"; photo: string | null };
    sampleClubPost: {
      __typename?: "WelcomeMessage";
      text: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        file: { __typename?: "File"; url: string; mimetype: string | null };
      }> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        file: { __typename?: "File"; url: string; mimetype: string | null };
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
    };
  }>;
  popularClubs: Array<{
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    isClub: boolean;
    title: string;
    titleTranslated: string | null;
    summary: string | null;
    age_min: number | null;
    age_max: number | null;
    details: { __typename?: "ActivityDetails"; photo: string | null };
  }>;
  clubCategories: Array<{
    __typename?: "ClubCategory";
    label: string;
    subject: string;
    activities: Array<{
      __typename?: "Activity";
      uid: string;
      slug_id: string;
      isClub: boolean;
      title: string;
      titleTranslated: string | null;
      summary: string | null;
      age_min: number | null;
      age_max: number | null;
      details: { __typename?: "ActivityDetails"; photo: string | null };
    }>;
  }>;
};

export type ConfirmChildEmailMutationVariables = Exact<{
  childUid: Scalars["ID"];
  token: Scalars["String"];
}>;

export type ConfirmChildEmailMutation = {
  __typename?: "Mutation";
  confirmChildEmail: boolean;
};

export type SearchConversationFiltersQueryVariables = Exact<{
  searchQuery: Scalars["String"];
  audience: Audience;
  existingFilters?: InputMaybe<ConversationFiltersInput>;
}>;

export type SearchConversationFiltersQuery = {
  __typename?: "Query";
  searchConversationFilters: Array<{
    __typename?: "ConversationFilter";
    uid: string;
    type: ConversationFilterType;
    name: string;
    highlight: string | null;
    resultCount: number | null;
  }>;
};

export type ParticipantsQueryVariables = Exact<{
  uids: Array<Scalars["ID"]> | Scalars["ID"];
  audience: Audience;
}>;

export type ParticipantsQuery = {
  __typename?: "Query";
  participants: Array<{
    __typename?: "Participant";
    uid: string;
    name: string | null;
    timeZone: string | null;
  }>;
};

export type ActivityQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type ActivityQuery = {
  __typename?: "Query";
  activity: { __typename?: "Activity"; uid: string; title: string };
};

export type ConversationsQueryVariables = Exact<{
  offset: Scalars["Int"];
  limit: Scalars["Int"];
  filters?: InputMaybe<ConversationFiltersInput>;
  audience?: InputMaybe<Audience>;
  messageSelection?: InputMaybe<MessageSelection>;
  viewAsOrgTeacherUid?: InputMaybe<Scalars["String"]>;
}>;

export type ConversationsQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    conversationsCount: number;
    conversations: Array<
      | {
          __typename?: "MessageThread";
          uid: string;
          isRead: boolean;
          matchedSearchResults: Array<{
            __typename?: "ConversationSearchResult";
            threadUid: string;
            highlightedMatch: string;
          }> | null;
          participants: Array<{
            __typename?: "Participant";
            avatar: string | null;
            uid: string;
            name: string | null;
            photo: string | null;
            timeZone: string | null;
            headline: string | null;
            leader_link: string | null;
            isLearner: boolean;
            role: ParticipantRole | null;
            activitiesWithCurrentUser: Array<{
              __typename?: "Activity";
              uid: string;
            }> | null;
            parent: {
              __typename?: "Parent";
              uid: string;
              photo: string | null;
              publicName: string | null;
            } | null;
            ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
            teacherForSellerOrg: {
              __typename?: "SellerOrg";
              uid: string;
            } | null;
          }>;
          activity: {
            __typename?: "Activity";
            uid: string;
            title: string;
            titleTranslated: string | null;
          } | null;
          messages: Array<{
            __typename?: "Message";
            created_at: any;
            uid: string;
            activity_uid: string | null;
            section_uid: string | null;
            thread_uid: string;
            subject: string | null;
            content: string | null;
            sender: {
              __typename?: "Sender";
              uid: string;
              name: string | null;
              photo: string | null;
              timeZone: string | null;
              headline: string | null;
              leader_link: string | null;
            };
            participants: Array<{
              __typename?: "Participant";
              isLearner: boolean;
              avatar: string | null;
              uid: string;
              name: string | null;
              photo: string | null;
              timeZone: string | null;
              headline: string | null;
              leader_link: string | null;
              role: ParticipantRole | null;
              ownerOfSellerOrg: {
                __typename?: "SellerOrg";
                uid: string;
              } | null;
              teacherForSellerOrg: {
                __typename?: "SellerOrg";
                uid: string;
              } | null;
            }>;
          }>;
        }
      | {
          __typename?: "PrivateClassThread";
          uid: string;
          isRead: boolean;
          matchedSearchResults: Array<{
            __typename?: "ConversationSearchResult";
            threadUid: string;
            highlightedMatch: string;
          }> | null;
          participants: Array<{
            __typename?: "Participant";
            avatar: string | null;
            uid: string;
            name: string | null;
            photo: string | null;
            timeZone: string | null;
            headline: string | null;
            leader_link: string | null;
            isLearner: boolean;
            role: ParticipantRole | null;
            activitiesWithCurrentUser: Array<{
              __typename?: "Activity";
              uid: string;
            }> | null;
            parent: {
              __typename?: "Parent";
              uid: string;
              photo: string | null;
              publicName: string | null;
            } | null;
            ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
            teacherForSellerOrg: {
              __typename?: "SellerOrg";
              uid: string;
            } | null;
          }>;
          activity: {
            __typename?: "Activity";
            uid: string;
            title: string;
            titleTranslated: string | null;
          } | null;
          messages: Array<{
            __typename?: "PrivateClassMessage";
            sentAt: any;
            uid: string;
            activity_uid: string;
            section_uid: string;
            thread_uid: string;
            subject: string | null;
            content: string | null;
            sender: {
              __typename?: "MessageSender";
              uid: string;
              name: string | null;
              photo: string | null;
              avatar: string | null;
              isLearner: boolean;
            };
            participants: Array<{
              __typename?: "Participant";
              isLearner: boolean;
              avatar: string | null;
              uid: string;
              name: string | null;
              photo: string | null;
              timeZone: string | null;
              headline: string | null;
              leader_link: string | null;
              role: ParticipantRole | null;
              ownerOfSellerOrg: {
                __typename?: "SellerOrg";
                uid: string;
              } | null;
              teacherForSellerOrg: {
                __typename?: "SellerOrg";
                uid: string;
              } | null;
            }>;
          }>;
        }
    >;
  } | null;
};

export type EnrolledClassDetailsQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type EnrolledClassDetailsQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    filledSpaceCount: number;
    currentUserGiftSlugId: string | null;
    uid: string;
    activity_uid: string;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_cross_listed: boolean;
    size_max: number | null;
    size_min: number | null;
    isPublished: boolean | null;
    price_cents: number | null;
    priceCredits: number | null;
    published_at: any | null;
    updated_at: any;
    canceled_at: any | null;
    deleted_at: any | null;
    ongoing_stopped_at: any | null;
    duration_minutes: number | null;
    usesOutschoolVideoChat: boolean;
    usersCanSendPrivateMessage: boolean;
    currentUserCanManage: boolean;
    isBuyable: boolean;
    allowsTeacherTransfer: boolean;
    is_self_paced: boolean;
    meetings: Array<{
      __typename: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
      refundableAmountCents: number;
      paidAt: any | null;
      recurring: boolean | null;
      oneOnOneTenderType: Array<TenderType> | null;
      canParentUpdate: boolean | null;
      billedOn: any;
      refundableAmountCredits: number | null;
      rescheduleRequests: Array<{
        __typename: "MeetingRequest";
        uid: string;
        created_at: any;
        expires_at: any;
        start_time: any;
        end_time: any;
        status: MeetingRequestStatus;
        message: string | null;
        is_recurring: boolean;
      }>;
      recurringSchedule: {
        __typename?: "RecurringSchedule";
        uid: string;
        end_at: any | null;
      } | null;
    }>;
    pendingOneOnOneMeetings: Array<{
      __typename?: "EnrollmentMeetingPurchase";
      startTime: any;
      endTime: any;
      uid: string;
      activityUid: string | null;
      enrollmentUid: string | null;
      meetingUid: string | null;
      orderSlugId: string;
      confirmedAt: any | null;
    }> | null;
    meetingRequests: Array<{
      __typename: "MeetingRequest";
      uid: string;
      created_at: any;
      start_time: any;
      end_time: any;
      expires_at: any;
      status: MeetingRequestStatus;
      is_recurring: boolean;
    }>;
    currentUserGiftEnrollments: Array<{
      __typename?: "GiftEnrollment";
      thisWeekPauseDeadline: any | null;
      uid: string;
      giftClaimedAt: any | null;
      createdAt: any;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      withdrawn_at: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      ongoing_stopped_at: any | null;
      currency_code: CurrencyCode;
      exchange_rate: number;
      amount_in_local_currency: number;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      hasPaymentSchedule: boolean;
      isEnded: boolean;
      isPaused: boolean;
      orderSlugId: string | null;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      priceCents: number | null;
      currentUserIsPurchaser: boolean;
      refundableAmountCents: number;
      payments: Array<{
        __typename?: "EnrollmentPayment";
        unrefundedCredits: number | null;
        uid: string;
        amount_cents: number | null;
        order_slug_id: string | null;
        stripe_charge_id: string | null;
        purchase_order_id: string | null;
        esa_order_id: string | null;
        intercom_url: string | null;
        last4: string | null;
        created_at: any | null;
        paid_at: any | null;
        disbursed_at: any | null;
        payout_uid: string | null;
        start_time: any | null;
        end_time: any | null;
        unrefundedCents: number;
        isOngoingRefundable: boolean;
        voucher_name: string | null;
        pricing_offer_uid: string | null;
        credit_transaction_uid: string | null;
        coupon_code_uid: string | null;
        currency_code: CurrencyCode | null;
        exchange_rate: number | null;
        invoice_uid: string | null;
        funding_program_invoice_uid: string | null;
        service_fee_percentage: number | null;
        couponCode: {
          __typename?: "CouponCode";
          code: string;
          coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
        } | null;
        pricingOffer: {
          __typename?: "PricingOffer";
          name: string;
          buyerOrgName: string | null;
          isFinancialAid: boolean;
        } | null;
      }>;
      refundPolicy: {
        __typename?: "RefundPolicy";
        creditDescription: string | null;
        type: RefundPolicyType;
        name: string;
        description: string;
      } | null;
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
        endTime: any | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
    }>;
    currentUserEnrollments: Array<{
      __typename?: "Enrollment";
      thisWeekPauseDeadline: any | null;
      uid: string;
      section_uid: string | null;
      price_cents: number | null;
      priceCents: number | null;
      created_at: any | null;
      confirmed_at: any | null;
      confirmedAt: any | null;
      withdrawn_at: any | null;
      ongoing_stopped_at: any | null;
      currency_code: CurrencyCode;
      exchange_rate: number;
      amount_in_local_currency: number;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      withdrawnAt: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      isPrepay: boolean;
      refundableAmountCents: number;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      usersCanCommunicate: boolean;
      purchasedAsGift: boolean;
      payments: Array<{
        __typename?: "EnrollmentPayment";
        unrefundedCredits: number | null;
        uid: string;
        amount_cents: number | null;
        order_slug_id: string | null;
        stripe_charge_id: string | null;
        purchase_order_id: string | null;
        esa_order_id: string | null;
        intercom_url: string | null;
        last4: string | null;
        created_at: any | null;
        paid_at: any | null;
        disbursed_at: any | null;
        payout_uid: string | null;
        start_time: any | null;
        end_time: any | null;
        unrefundedCents: number;
        isOngoingRefundable: boolean;
        voucher_name: string | null;
        pricing_offer_uid: string | null;
        credit_transaction_uid: string | null;
        coupon_code_uid: string | null;
        currency_code: CurrencyCode | null;
        exchange_rate: number | null;
        invoice_uid: string | null;
        funding_program_invoice_uid: string | null;
        service_fee_percentage: number | null;
        couponCode: {
          __typename?: "CouponCode";
          code: string;
          coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
        } | null;
        pricingOffer: {
          __typename?: "PricingOffer";
          name: string;
          buyerOrgName: string | null;
          isFinancialAid: boolean;
        } | null;
      }>;
      refundPolicy: {
        __typename?: "RefundPolicy";
        creditDescription: string | null;
        type: RefundPolicyType;
        name: string;
        description: string;
      } | null;
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      siblings: Array<{
        __typename?: "Sibling";
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
          age: number | null;
          avatar: string | null;
          pronoun: string | null;
          intro: string | null;
          deleted_at: any | null;
          isVerified: boolean;
          isBlocked: boolean | null;
          blockedByUserUid: string | null;
          blockedAt: any | null;
        };
        currentOrLastSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          uid: string;
          activity_uid: string;
          start_time: any | null;
          end_time: any | null;
          startsAtNight: boolean | null;
          is_cross_listed: boolean;
          size_max: number | null;
          size_min: number | null;
          isPublished: boolean | null;
          price_cents: number | null;
          priceCredits: number | null;
          published_at: any | null;
          updated_at: any;
          canceled_at: any | null;
          deleted_at: any | null;
          ongoing_stopped_at: any | null;
          duration_minutes: number | null;
          usesOutschoolVideoChat: boolean;
          usersCanSendPrivateMessage: boolean;
          currentUserCanManage: boolean;
          isBuyable: boolean;
          allowsTeacherTransfer: boolean;
          nextOngoingMeeting: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
            deleted_at: any | null;
            created_at: any | null;
          } | null;
          leader: { __typename?: "Leader"; uid: string; name: string | null };
          details: {
            __typename?: "SectionDetails";
            autoScheduledDraft: boolean | null;
          };
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
        };
      }>;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
        endTime: any | null;
        scheduleType: PaymentScheduleTypes;
        amountCents: number | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
    }>;
    enrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      price_cents: number | null;
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        leader: {
          __typename?: "Leader";
          uid: string;
          teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        };
      } | null;
    }>;
    activity: {
      __typename?: "Activity";
      allowWaitlist: boolean | null;
      upsell_activity_uid: string | null;
      archived_at: any | null;
      requested_listing_at: any | null;
      hasTeacherSchedule: boolean;
      uid: string;
      assessment: string | null;
      class_experience: string | null;
      has_external_resources: boolean | null;
      homework: string | null;
      learning_goals: string | null;
      materials: string | null;
      parental_guidance: string | null;
      sources: string | null;
      title: string;
      titleTranslated: string | null;
      weekly_meetings: number | null;
      welcomeAttachmentCount: number | null;
      why_me: string | null;
      duration_minutes: number | null;
      suggestedWeeklyMeetings: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      slug_id: string;
      summary: string | null;
      age_min: number | null;
      age_max: number | null;
      published_at: any | null;
      price_cents: number | null;
      averageStarRating: number | null;
      reviewCount: number;
      is_ongoing_weekly: boolean;
      isClub: boolean;
      esaStatus: EsaStatus;
      duration_weeks: number | null;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      size_max: number | null;
      allows_recurring_payment: boolean | null;
      approvalStatus: {
        __typename?: "ActivityApprovalStatus";
        action: string;
        message_to_teacher: string | null;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
        created_at: any | null;
        photo: string | null;
        suspended_at: any | null;
        cityState: string | null;
        socialOptOut: boolean;
        is_crosslister: boolean | null;
        timeZone: string | null;
        hasAvailabilitySet: boolean;
        approved: boolean;
        hasBackgroundCheckExpired: boolean | null;
        reviewCount: number;
        averageActivityStarRating: number | null;
        hasListings: boolean;
        details: {
          __typename?: "LeaderDetails";
          about: string | null;
          headline: string | null;
          headlineTranslated: string | null;
          countryOfResidence: string | null;
        };
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        teacherForSellerOrg: {
          __typename?: "SellerOrg";
          uid: string;
          name: string;
        } | null;
      };
      attributes: Array<{
        __typename?: "EntityAttribute";
        key: string;
        value: string;
        entity_type: string;
      }>;
      externalResources: Array<{
        __typename?: "ExternalResource";
        uid: string;
        name: string;
      }>;
      details: {
        __typename?: "ActivityDetails";
        asyncTime: string | null;
        photo: string | null;
      };
      clubGuidelines: {
        __typename?: "WelcomeMessage";
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
        creditDescription: string | null;
      };
      enrollableTimes: Array<{
        __typename?: "EnrollableTime";
        startTime: any;
        endTime: any;
        teacher: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          hasBackgroundCheckExpired: boolean | null;
          teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        };
      }>;
    };
    currentUserPublicReview: {
      __typename?: "PublicReview";
      uid: string;
      section_uid: string;
      currentUserCanEdit: boolean;
      currentUserCanRespond: boolean;
      activity_star_rating: number | null;
      currentUserMarkedHelpful: boolean | null;
      markedHelpfulCount: number;
      created_at: any;
      teacher_response: string | null;
      teacher_responded_at: any | null;
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      details: {
        __typename?: "PublicReviewDetails";
        publicReview: string | null;
      } | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        isClub: boolean;
        slug_id: string;
        title: string;
        user_uid: string;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          leader_link: string | null;
          photo: string | null;
        };
      } | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      timeZone: string | null;
      details: { __typename?: "LeaderDetails"; headline: string | null };
    };
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    details: {
      __typename?: "SectionDetails";
      autoScheduledDraft: boolean | null;
      onlineClassroom: {
        __typename?: "OnlineClassroom";
        type: string | null;
        url: string | null;
      } | null;
    };
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
    }>;
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      published_at: string | null;
    } | null;
  } | null;
};

export type UserWithGiftCardsQueryVariables = Exact<{
  uid: Scalars["String"];
}>;

export type UserWithGiftCardsQuery = {
  __typename?: "Query";
  user: {
    __typename?: "User";
    name: string | null;
    giftCardsPurchased: Array<{
      __typename?: "GiftCard";
      uid: string;
      createdAt: any;
      orderSlugId: string;
      amountCents: number;
      note: string | null;
      recipientName: string;
      recipientEmail: string;
      purchaserName: string;
      purchaserEmail: string;
      confirmedAt: any | null;
      redeemedAt: any | null;
      deliveredAt: any | null;
      deletedAt: any | null;
      redemptionCode: string | null;
      deliverAfter: any;
      deliveryTimezone: string;
      status: GiftCardStatus;
      isCancelable: boolean;
      isEditable: boolean;
    }>;
  } | null;
};

export type ChooseAPlanEnrollmentsQueryQueryVariables = Exact<{
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
}>;

export type ChooseAPlanEnrollmentsQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    activeEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      price_cents: number | null;
      ongoingStartTime: any | null;
      service_fee_percentage: number;
      section: {
        __typename?: "Section";
        uid: string;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          hasTeacherSchedule: boolean;
        };
        meetings: Array<{
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          paidAt: any | null;
        }>;
      } | null;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        scheduleType: PaymentScheduleTypes;
        amountCents: number | null;
      } | null;
    } | null>;
  } | null;
};

export type FeaturedTeachersQueryVariables = Exact<{ [key: string]: never }>;

export type FeaturedTeachersQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    popularTeachers: Array<{
      __typename?: "Leader";
      uid: string;
      name: string | null;
      leader_link: string | null;
      created_at: any | null;
      photo: string | null;
      suspended_at: any | null;
      cityState: string | null;
      socialOptOut: boolean;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      hasBackgroundCheckExpired: boolean | null;
      reviewCount: number;
      averageActivityStarRating: number | null;
      hasListings: boolean;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        headlineTranslated: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
    }> | null;
  };
};

export type LearnerVerificationQueryVariables = Exact<{
  start?: InputMaybe<Scalars["DateTime"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type LearnerVerificationQuery = {
  __typename?: "Query";
  unverifiedClubEnrollments: Array<{
    __typename?: "Enrollment";
    uid: string;
    createdAt: any;
    recordingForClubsVerificationFlaggedAt: any | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      recentAttendances: Array<{
        __typename?: "Attendance";
        uid: string;
      } | null>;
    } | null;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      learners_verified_at: any | null;
    };
    section: {
      __typename?: "Section";
      uid: string;
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        titleTranslated: string | null;
      };
    } | null;
    clubVerificationVideo: {
      __typename?: "Attachment";
      uid: string;
      created_at: any;
      file: { __typename?: "File"; url: string; thumbUrl: string | null };
    } | null;
  }>;
};

export type ApproveClubIntroductionMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
}>;

export type ApproveClubIntroductionMutation = {
  __typename?: "Mutation";
  approveClubIntroduction: boolean;
};

export type RejectClubIntroductionMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
}>;

export type RejectClubIntroductionMutation = {
  __typename?: "Mutation";
  rejectClubIntroduction: boolean;
};

export type FlagClubIntroductionMutationVariables = Exact<{
  enrollmentUid: Scalars["ID"];
  notes?: InputMaybe<Scalars["String"]>;
}>;

export type FlagClubIntroductionMutation = {
  __typename?: "Mutation";
  flagClubIntroduction: boolean;
};

export type KansasKeepInvoiceOrderQueryVariables = Exact<{
  orderSlugId: Scalars["ID"];
}>;

export type KansasKeepInvoiceOrderQuery = {
  __typename?: "Query";
  getFundingProgramInvoice: {
    __typename?: "FundingProgramInvoice";
    uid: string;
    childName: string;
    parentName: string;
    awardId: string;
    orderSlugId: string;
    createdAt: string;
  } | null;
  order: {
    __typename?: "Order";
    enrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      price_cents: number | null;
      currency_code: CurrencyCode;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          is_ongoing_weekly: boolean;
          duration_weeks: number | null;
          isClub: boolean;
          weekly_meetings: number | null;
          hasTeacherSchedule: boolean;
          price_cents: number | null;
          path: string;
          details: { __typename?: "ActivityDetails"; photo: string | null };
        };
      } | null;
      parent: { __typename?: "Parent"; uid: string; email: string | null };
    }>;
  };
};

export type LearnerPlanPricingBreakdownQueryVariables = Exact<{
  pricingBreakdownInputs: Array<PricingBreakdownInput> | PricingBreakdownInput;
  outschoolCouponCode?: InputMaybe<Scalars["String"]>;
}>;

export type LearnerPlanPricingBreakdownQuery = {
  __typename?: "Query";
  bulkPricingBreakdown: {
    __typename?: "BulkPricingBreakdown";
    originalTotalCents: number;
    totalCents: number;
    pricingOfferCents: number;
    totalPayUpfrontCents: number;
    totalWeeklyFirstPaymentCents: number;
    storedCreditCents: number;
    stripeCents: number;
    couponCents: number;
    couponState: CouponState;
    subscriptionCredits: number | null;
    subscriptionOutstandingBalanceCents: number | null;
    subscriptionOutstandingBalanceCredits: number | null;
    itemizedPricingBreakdown: Array<{
      __typename?: "PricingBreakdown";
      uid: string | null;
      totalCents: number;
      perEnrollmentUpfrontCents: number | null;
      perEnrollmentWeeklyCents: number | null;
      perEnrollmentTotalWeeklyCents: number | null;
      hasProratedEnrollments: boolean | null;
      couponCents: number;
      couponState: CouponState;
    }>;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
    } | null;
    weeklyPaymentsCharges: Array<{
      __typename?: "BulkWeeklyPricingBreakdown";
      priceCents: number;
      startOfWeek: any;
    }>;
  };
};

export type ValidateBulkEnrollmentsQueryVariables = Exact<{
  bulkOrderInput: BulkOrderInput;
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type ValidateBulkEnrollmentsQuery = {
  __typename?: "Query";
  validateBulkEnrollments: {
    __typename?: "ValidatedBulkEnrollment";
    validatedEnrollmentsStatus: Array<{
      __typename?: "ValidatedEnrollmentStatus";
      uid: string | null;
      activityUid: string;
      sectionUid: string | null;
      isOneOnOneInput: boolean | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      isWeeklyPayment: boolean | null;
      selfPacedStartTime: any | null;
      pricing_offer: string | null;
      isGift: boolean | null;
      canScheduleContinuously: boolean | null;
      meetingDurationWeeks: number | null;
      learner: {
        __typename?: "ValidatedBulkEnrollmentLearner";
        uid: string | null;
        name: string;
        age: number;
      } | null;
      enrollableMeetings: Array<{
        __typename?: "BulkFutureMeeting";
        startTime: any;
        endTime: any;
        isAvailable: boolean;
      }> | null;
      validationErrorStatus: {
        __typename?: "ValidationErrorStatus";
        message: string;
        isEsaError: boolean;
      } | null;
    }>;
  };
};

export type PlannerSaveLearnerPlanActivityMutationVariables = Exact<{
  learnerUid: Scalars["ID"];
  activityUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
  isSuggested?: InputMaybe<Scalars["Boolean"]>;
  isPending?: InputMaybe<Scalars["Boolean"]>;
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type PlannerSaveLearnerPlanActivityMutation = {
  __typename?: "Mutation";
  saveLearnerPlanActivity: Array<{
    __typename?: "LearnerPlanActivity";
    uid: string;
    learner_plan_uid: string;
    meeting_start_time: any | null;
    meeting_end_time: any | null;
    meeting_duration_weeks: number | null;
    created_at: any | null;
    canScheduleContinuously: boolean;
    is_pending: boolean | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      subject: string | null;
      homework: string | null;
      duration_weeks: number | null;
      duration_minutes: number | null;
      weekly_meetings: number | null;
      size_max: number | null;
      hasTeacherSchedule: boolean;
      isClub: boolean;
      isSelfPaced: boolean;
      isFlexSchedule: boolean;
      is_ongoing_weekly: boolean;
      path: string;
      price_cents: number | null;
      published_at: any | null;
      title: string;
      titleTranslated: string | null;
      allows_recurring_payment: boolean | null;
      slug_id: string;
      esaStatus: EsaStatus;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
      };
      details: { __typename?: "ActivityDetails"; photo: string | null };
      pricingInfo: {
        __typename?: "PricingInfo";
        totalPriceCentsForUpfrontPayment: number;
        totalPriceCentsForWeeklyPayments: number;
        weeklyPriceCentsForUpfrontPayment: number;
        weeklyPriceCentsForWeeklyPayments: number;
        upfrontDiscountPercentage: number;
        totalPriceCreditsForUpfrontPayment: number;
        weeklyPriceCreditsForUpfrontPayment: number;
        totalPriceCreditsForWeeklyPayments: number;
        weeklyPriceCreditsForWeeklyPayments: number;
      } | null;
    } | null;
    section: {
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
      price_cents: number | null;
      isPublished: boolean | null;
      allowsLateEnrollment: boolean;
      meetingCount: number;
      validEnrollmentCount: number;
      isBuyable: boolean;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
      };
      meetingTimes: Array<{
        __typename?: "MeetingTime";
        dayOfWeek: number;
        hour: number;
        minute: number;
      }>;
      meetings: Array<{
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
      }>;
      progress: {
        __typename?: "CourseProgress";
        pastMeetingCount: number;
        remainingMeetingCount: number;
        totalMeetingCount: number;
        nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
      } | null;
      prepaidOngoingPeriodOptions: Array<{
        __typename?: "Period";
        startOfIsoWeek: any;
        endOfIsoWeek: any;
      }> | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        titleTranslated: string | null;
        subject: string | null;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isClub: boolean;
        slug_id: string;
        esaStatus: EsaStatus;
        size_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        is_ongoing_weekly: boolean;
        hasTeacherSchedule: boolean;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          averageActivityStarRating: number | null;
          reviewCount: number;
        };
      };
    } | null;
    enrollment: {
      __typename?: "Enrollment";
      confirmedAt: any | null;
      isActiveOrUpcoming: boolean;
      isWithdrawn: boolean;
    } | null;
    enrollableMeetings: Array<{
      __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
      startTime: any;
      endTime: any;
      isAvailable: boolean;
    } | null> | null;
  }> | null;
};

export type PlannerSaveLearnerPlanActivityWithMeetingTimesMutationVariables =
  Exact<{
    learnerUid: Scalars["ID"];
    activityUid: Scalars["ID"];
    meetingStartTime: Scalars["DateTime"];
    meetingEndTime: Scalars["DateTime"];
    meetingDurationWeeks: Scalars["Int"];
    isSuggested?: InputMaybe<Scalars["Boolean"]>;
    isPending?: InputMaybe<Scalars["Boolean"]>;
    esaState?: InputMaybe<Scalars["String"]>;
  }>;

export type PlannerSaveLearnerPlanActivityWithMeetingTimesMutation = {
  __typename?: "Mutation";
  saveLearnerPlanActivityWithMeetingTimes: Array<{
    __typename?: "LearnerPlanActivity";
    uid: string;
    learner_plan_uid: string;
    meeting_start_time: any | null;
    meeting_end_time: any | null;
    meeting_duration_weeks: number | null;
    created_at: any | null;
    canScheduleContinuously: boolean;
    is_pending: boolean | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      subject: string | null;
      homework: string | null;
      duration_weeks: number | null;
      duration_minutes: number | null;
      weekly_meetings: number | null;
      size_max: number | null;
      hasTeacherSchedule: boolean;
      isClub: boolean;
      isSelfPaced: boolean;
      isFlexSchedule: boolean;
      is_ongoing_weekly: boolean;
      path: string;
      price_cents: number | null;
      published_at: any | null;
      title: string;
      titleTranslated: string | null;
      allows_recurring_payment: boolean | null;
      slug_id: string;
      esaStatus: EsaStatus;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
      };
      details: { __typename?: "ActivityDetails"; photo: string | null };
      pricingInfo: {
        __typename?: "PricingInfo";
        totalPriceCentsForUpfrontPayment: number;
        totalPriceCentsForWeeklyPayments: number;
        weeklyPriceCentsForUpfrontPayment: number;
        weeklyPriceCentsForWeeklyPayments: number;
        upfrontDiscountPercentage: number;
        totalPriceCreditsForUpfrontPayment: number;
        weeklyPriceCreditsForUpfrontPayment: number;
        totalPriceCreditsForWeeklyPayments: number;
        weeklyPriceCreditsForWeeklyPayments: number;
      } | null;
    } | null;
    section: {
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
      price_cents: number | null;
      isPublished: boolean | null;
      allowsLateEnrollment: boolean;
      meetingCount: number;
      validEnrollmentCount: number;
      isBuyable: boolean;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
      };
      meetingTimes: Array<{
        __typename?: "MeetingTime";
        dayOfWeek: number;
        hour: number;
        minute: number;
      }>;
      meetings: Array<{
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
      }>;
      progress: {
        __typename?: "CourseProgress";
        pastMeetingCount: number;
        remainingMeetingCount: number;
        totalMeetingCount: number;
        nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
      } | null;
      prepaidOngoingPeriodOptions: Array<{
        __typename?: "Period";
        startOfIsoWeek: any;
        endOfIsoWeek: any;
      }> | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        titleTranslated: string | null;
        subject: string | null;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isClub: boolean;
        slug_id: string;
        esaStatus: EsaStatus;
        size_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        is_ongoing_weekly: boolean;
        hasTeacherSchedule: boolean;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          averageActivityStarRating: number | null;
          reviewCount: number;
        };
      };
    } | null;
    enrollment: {
      __typename?: "Enrollment";
      confirmedAt: any | null;
      isActiveOrUpcoming: boolean;
      isWithdrawn: boolean;
    } | null;
    enrollableMeetings: Array<{
      __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
      startTime: any;
      endTime: any;
      isAvailable: boolean;
    } | null> | null;
  }> | null;
};

export type SaveLearnerPlanGoalMutationVariables = Exact<{
  learnerUid: Scalars["ID"];
  learnerPlanUid: Scalars["ID"];
  subject?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type SaveLearnerPlanGoalMutation = {
  __typename?: "Mutation";
  saveLearnerPlanGoal: {
    __typename?: "LearnerPlan";
    uid: string;
    name: string | null;
    start_date: any | null;
    end_date: any | null;
    created_from: string | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
    } | null;
    learnerPlanGoals: Array<{
      __typename?: "LearnerPlanGoal";
      uid: string;
      learner_plan_uid: string;
      created_at: any | null;
      learnerPlanGoalFilters: Array<{
        __typename?: "LearnerPlanGoalFilter";
        uid: string;
        type: string | null;
        value: string | null;
      }>;
      learnerPlanActivity: {
        __typename?: "LearnerPlanActivity";
        uid: string;
        learner_plan_uid: string;
        meeting_start_time: any | null;
        meeting_end_time: any | null;
        meeting_duration_weeks: number | null;
        created_at: any | null;
        canScheduleContinuously: boolean;
        is_pending: boolean | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          subject: string | null;
          homework: string | null;
          duration_weeks: number | null;
          duration_minutes: number | null;
          weekly_meetings: number | null;
          size_max: number | null;
          hasTeacherSchedule: boolean;
          isClub: boolean;
          isSelfPaced: boolean;
          isFlexSchedule: boolean;
          is_ongoing_weekly: boolean;
          path: string;
          price_cents: number | null;
          published_at: any | null;
          title: string;
          titleTranslated: string | null;
          allows_recurring_payment: boolean | null;
          slug_id: string;
          esaStatus: EsaStatus;
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            leader_link: string | null;
            averageActivityStarRating: number | null;
            reviewCount: number;
          };
          details: { __typename?: "ActivityDetails"; photo: string | null };
          pricingInfo: {
            __typename?: "PricingInfo";
            totalPriceCentsForUpfrontPayment: number;
            totalPriceCentsForWeeklyPayments: number;
            weeklyPriceCentsForUpfrontPayment: number;
            weeklyPriceCentsForWeeklyPayments: number;
            upfrontDiscountPercentage: number;
            totalPriceCreditsForUpfrontPayment: number;
            weeklyPriceCreditsForUpfrontPayment: number;
            totalPriceCreditsForWeeklyPayments: number;
            weeklyPriceCreditsForWeeklyPayments: number;
          } | null;
        } | null;
        section: {
          __typename?: "Section";
          uid: string;
          start_time: any | null;
          end_time: any | null;
          price_cents: number | null;
          isPublished: boolean | null;
          allowsLateEnrollment: boolean;
          meetingCount: number;
          validEnrollmentCount: number;
          isBuyable: boolean;
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            leader_link: string | null;
            averageActivityStarRating: number | null;
            reviewCount: number;
          };
          meetingTimes: Array<{
            __typename?: "MeetingTime";
            dayOfWeek: number;
            hour: number;
            minute: number;
          }>;
          meetings: Array<{
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
          }>;
          progress: {
            __typename?: "CourseProgress";
            pastMeetingCount: number;
            remainingMeetingCount: number;
            totalMeetingCount: number;
            nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
          } | null;
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
          activity: {
            __typename?: "Activity";
            uid: string;
            title: string;
            titleTranslated: string | null;
            subject: string | null;
            isFlexSchedule: boolean;
            isSelfPaced: boolean;
            isClub: boolean;
            slug_id: string;
            esaStatus: EsaStatus;
            size_max: number | null;
            duration_minutes: number | null;
            duration_weeks: number | null;
            weekly_meetings: number | null;
            price_cents: number | null;
            is_ongoing_weekly: boolean;
            hasTeacherSchedule: boolean;
            details: { __typename?: "ActivityDetails"; photo: string | null };
            leader: {
              __typename?: "Leader";
              uid: string;
              name: string | null;
              photo: string | null;
              averageActivityStarRating: number | null;
              reviewCount: number;
            };
          };
        } | null;
        enrollment: {
          __typename?: "Enrollment";
          confirmedAt: any | null;
          isActiveOrUpcoming: boolean;
          isWithdrawn: boolean;
        } | null;
        enrollableMeetings: Array<{
          __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
          startTime: any;
          endTime: any;
          isAvailable: boolean;
        } | null> | null;
      } | null;
    }>;
    entityAvailabilitySchedule: {
      __typename?: "EntityAvailabilitySchedule";
      uid: string;
    } | null;
  } | null;
};

export type SaveLearnerPlanGoalActivityMutationVariables = Exact<{
  learnerUid: Scalars["ID"];
  learnerPlanGoalUid: Scalars["ID"];
  activityUid: Scalars["ID"];
  sectionUid?: InputMaybe<Scalars["ID"]>;
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type SaveLearnerPlanGoalActivityMutation = {
  __typename?: "Mutation";
  saveLearnerPlanGoalActivity: { __typename?: "LearnerPlanGoal"; uid: string };
};

export type SaveLearnerPlanGoalFilterMutationVariables = Exact<{
  learnerUid: Scalars["ID"];
  learnerPlanGoalUid: Scalars["ID"];
  type?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
}>;

export type SaveLearnerPlanGoalFilterMutation = {
  __typename?: "Mutation";
  saveLearnerPlanGoalFilter: {
    __typename?: "LearnerPlanGoalFilter";
    uid: string;
  };
};

export type RemoveActivityFromAllLearnerPlanMutationVariables = Exact<{
  learnerUid: Scalars["ID"];
  learnerPlanUid: Scalars["ID"];
  activityUid: Scalars["ID"];
  sectionUid?: InputMaybe<Scalars["ID"]>;
  learnerPlanGoalUid?: InputMaybe<Scalars["ID"]>;
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type RemoveActivityFromAllLearnerPlanMutation = {
  __typename?: "Mutation";
  removeActivityFromAllLearnerPlan: {
    __typename?: "LearnerPlan";
    uid: string;
    name: string | null;
    start_date: any | null;
    end_date: any | null;
    created_from: string | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
    } | null;
    learnerPlanGoals: Array<{
      __typename?: "LearnerPlanGoal";
      uid: string;
      learner_plan_uid: string;
      created_at: any | null;
      learnerPlanGoalFilters: Array<{
        __typename?: "LearnerPlanGoalFilter";
        uid: string;
        type: string | null;
        value: string | null;
      }>;
      learnerPlanActivity: {
        __typename?: "LearnerPlanActivity";
        uid: string;
        learner_plan_uid: string;
        meeting_start_time: any | null;
        meeting_end_time: any | null;
        meeting_duration_weeks: number | null;
        created_at: any | null;
        canScheduleContinuously: boolean;
        is_pending: boolean | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          subject: string | null;
          homework: string | null;
          duration_weeks: number | null;
          duration_minutes: number | null;
          weekly_meetings: number | null;
          size_max: number | null;
          hasTeacherSchedule: boolean;
          isClub: boolean;
          isSelfPaced: boolean;
          isFlexSchedule: boolean;
          is_ongoing_weekly: boolean;
          path: string;
          price_cents: number | null;
          published_at: any | null;
          title: string;
          titleTranslated: string | null;
          allows_recurring_payment: boolean | null;
          slug_id: string;
          esaStatus: EsaStatus;
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            leader_link: string | null;
            averageActivityStarRating: number | null;
            reviewCount: number;
          };
          details: { __typename?: "ActivityDetails"; photo: string | null };
          pricingInfo: {
            __typename?: "PricingInfo";
            totalPriceCentsForUpfrontPayment: number;
            totalPriceCentsForWeeklyPayments: number;
            weeklyPriceCentsForUpfrontPayment: number;
            weeklyPriceCentsForWeeklyPayments: number;
            upfrontDiscountPercentage: number;
            totalPriceCreditsForUpfrontPayment: number;
            weeklyPriceCreditsForUpfrontPayment: number;
            totalPriceCreditsForWeeklyPayments: number;
            weeklyPriceCreditsForWeeklyPayments: number;
          } | null;
        } | null;
        section: {
          __typename?: "Section";
          uid: string;
          start_time: any | null;
          end_time: any | null;
          price_cents: number | null;
          isPublished: boolean | null;
          allowsLateEnrollment: boolean;
          meetingCount: number;
          validEnrollmentCount: number;
          isBuyable: boolean;
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            leader_link: string | null;
            averageActivityStarRating: number | null;
            reviewCount: number;
          };
          meetingTimes: Array<{
            __typename?: "MeetingTime";
            dayOfWeek: number;
            hour: number;
            minute: number;
          }>;
          meetings: Array<{
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
          }>;
          progress: {
            __typename?: "CourseProgress";
            pastMeetingCount: number;
            remainingMeetingCount: number;
            totalMeetingCount: number;
            nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
          } | null;
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
          activity: {
            __typename?: "Activity";
            uid: string;
            title: string;
            titleTranslated: string | null;
            subject: string | null;
            isFlexSchedule: boolean;
            isSelfPaced: boolean;
            isClub: boolean;
            slug_id: string;
            esaStatus: EsaStatus;
            size_max: number | null;
            duration_minutes: number | null;
            duration_weeks: number | null;
            weekly_meetings: number | null;
            price_cents: number | null;
            is_ongoing_weekly: boolean;
            hasTeacherSchedule: boolean;
            details: { __typename?: "ActivityDetails"; photo: string | null };
            leader: {
              __typename?: "Leader";
              uid: string;
              name: string | null;
              photo: string | null;
              averageActivityStarRating: number | null;
              reviewCount: number;
            };
          };
        } | null;
        enrollment: {
          __typename?: "Enrollment";
          confirmedAt: any | null;
          isActiveOrUpcoming: boolean;
          isWithdrawn: boolean;
        } | null;
        enrollableMeetings: Array<{
          __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
          startTime: any;
          endTime: any;
          isAvailable: boolean;
        } | null> | null;
      } | null;
    }>;
    entityAvailabilitySchedule: {
      __typename?: "EntityAvailabilitySchedule";
      uid: string;
    } | null;
  } | null;
};

export type RemoveLearnerPlanGoalMutationVariables = Exact<{
  learnerPlanGoalUid: Scalars["ID"];
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type RemoveLearnerPlanGoalMutation = {
  __typename?: "Mutation";
  removeLearnerPlanGoal: {
    __typename?: "LearnerPlan";
    uid: string;
    name: string | null;
    start_date: any | null;
    end_date: any | null;
    created_from: string | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
    } | null;
    learnerPlanGoals: Array<{
      __typename?: "LearnerPlanGoal";
      uid: string;
      learner_plan_uid: string;
      created_at: any | null;
      learnerPlanGoalFilters: Array<{
        __typename?: "LearnerPlanGoalFilter";
        uid: string;
        type: string | null;
        value: string | null;
      }>;
      learnerPlanActivity: {
        __typename?: "LearnerPlanActivity";
        uid: string;
        learner_plan_uid: string;
        meeting_start_time: any | null;
        meeting_end_time: any | null;
        meeting_duration_weeks: number | null;
        created_at: any | null;
        canScheduleContinuously: boolean;
        is_pending: boolean | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          subject: string | null;
          homework: string | null;
          duration_weeks: number | null;
          duration_minutes: number | null;
          weekly_meetings: number | null;
          size_max: number | null;
          hasTeacherSchedule: boolean;
          isClub: boolean;
          isSelfPaced: boolean;
          isFlexSchedule: boolean;
          is_ongoing_weekly: boolean;
          path: string;
          price_cents: number | null;
          published_at: any | null;
          title: string;
          titleTranslated: string | null;
          allows_recurring_payment: boolean | null;
          slug_id: string;
          esaStatus: EsaStatus;
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            leader_link: string | null;
            averageActivityStarRating: number | null;
            reviewCount: number;
          };
          details: { __typename?: "ActivityDetails"; photo: string | null };
          pricingInfo: {
            __typename?: "PricingInfo";
            totalPriceCentsForUpfrontPayment: number;
            totalPriceCentsForWeeklyPayments: number;
            weeklyPriceCentsForUpfrontPayment: number;
            weeklyPriceCentsForWeeklyPayments: number;
            upfrontDiscountPercentage: number;
            totalPriceCreditsForUpfrontPayment: number;
            weeklyPriceCreditsForUpfrontPayment: number;
            totalPriceCreditsForWeeklyPayments: number;
            weeklyPriceCreditsForWeeklyPayments: number;
          } | null;
        } | null;
        section: {
          __typename?: "Section";
          uid: string;
          start_time: any | null;
          end_time: any | null;
          price_cents: number | null;
          isPublished: boolean | null;
          allowsLateEnrollment: boolean;
          meetingCount: number;
          validEnrollmentCount: number;
          isBuyable: boolean;
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            leader_link: string | null;
            averageActivityStarRating: number | null;
            reviewCount: number;
          };
          meetingTimes: Array<{
            __typename?: "MeetingTime";
            dayOfWeek: number;
            hour: number;
            minute: number;
          }>;
          meetings: Array<{
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
          }>;
          progress: {
            __typename?: "CourseProgress";
            pastMeetingCount: number;
            remainingMeetingCount: number;
            totalMeetingCount: number;
            nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
          } | null;
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
          activity: {
            __typename?: "Activity";
            uid: string;
            title: string;
            titleTranslated: string | null;
            subject: string | null;
            isFlexSchedule: boolean;
            isSelfPaced: boolean;
            isClub: boolean;
            slug_id: string;
            esaStatus: EsaStatus;
            size_max: number | null;
            duration_minutes: number | null;
            duration_weeks: number | null;
            weekly_meetings: number | null;
            price_cents: number | null;
            is_ongoing_weekly: boolean;
            hasTeacherSchedule: boolean;
            details: { __typename?: "ActivityDetails"; photo: string | null };
            leader: {
              __typename?: "Leader";
              uid: string;
              name: string | null;
              photo: string | null;
              averageActivityStarRating: number | null;
              reviewCount: number;
            };
          };
        } | null;
        enrollment: {
          __typename?: "Enrollment";
          confirmedAt: any | null;
          isActiveOrUpcoming: boolean;
          isWithdrawn: boolean;
        } | null;
        enrollableMeetings: Array<{
          __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
          startTime: any;
          endTime: any;
          isAvailable: boolean;
        } | null> | null;
      } | null;
    }>;
    entityAvailabilitySchedule: {
      __typename?: "EntityAvailabilitySchedule";
      uid: string;
    } | null;
  } | null;
};

export type RemoveLearnerPlanGoalFilterMutationVariables = Exact<{
  learnerPlanGoalFilterUid: Scalars["ID"];
}>;

export type RemoveLearnerPlanGoalFilterMutation = {
  __typename?: "Mutation";
  removeLearnerPlanGoalFilter: boolean | null;
};

export type UpdateLearnerPlanGoalFilterMutationVariables = Exact<{
  learnerPlanGoalFilterUid: Scalars["ID"];
  type?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateLearnerPlanGoalFilterMutation = {
  __typename?: "Mutation";
  updateLearnerPlanGoalFilter: {
    __typename?: "LearnerPlanGoalFilter";
    uid: string;
  } | null;
};

export type UpdateLearnerPlanActivityMutationVariables = Exact<{
  learnerUid: Scalars["ID"];
  learnerPlanGoalUid: Scalars["ID"];
  activityUid: Scalars["ID"];
  oldSectionUid?: InputMaybe<Scalars["ID"]>;
  newSectionUid?: InputMaybe<Scalars["ID"]>;
  meetingStartTime?: InputMaybe<Scalars["DateTime"]>;
  meetingEndTime?: InputMaybe<Scalars["DateTime"]>;
  meetingDurationWeeks?: InputMaybe<Scalars["Int"]>;
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type UpdateLearnerPlanActivityMutation = {
  __typename?: "Mutation";
  updateLearnerPlanActivity: Array<{
    __typename?: "LearnerPlanActivity";
    uid: string;
    learner_plan_uid: string;
    meeting_start_time: any | null;
    meeting_end_time: any | null;
    meeting_duration_weeks: number | null;
    created_at: any | null;
    canScheduleContinuously: boolean;
    is_pending: boolean | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      subject: string | null;
      homework: string | null;
      duration_weeks: number | null;
      duration_minutes: number | null;
      weekly_meetings: number | null;
      size_max: number | null;
      hasTeacherSchedule: boolean;
      isClub: boolean;
      isSelfPaced: boolean;
      isFlexSchedule: boolean;
      is_ongoing_weekly: boolean;
      path: string;
      price_cents: number | null;
      published_at: any | null;
      title: string;
      titleTranslated: string | null;
      allows_recurring_payment: boolean | null;
      slug_id: string;
      esaStatus: EsaStatus;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
      };
      details: { __typename?: "ActivityDetails"; photo: string | null };
      pricingInfo: {
        __typename?: "PricingInfo";
        totalPriceCentsForUpfrontPayment: number;
        totalPriceCentsForWeeklyPayments: number;
        weeklyPriceCentsForUpfrontPayment: number;
        weeklyPriceCentsForWeeklyPayments: number;
        upfrontDiscountPercentage: number;
        totalPriceCreditsForUpfrontPayment: number;
        weeklyPriceCreditsForUpfrontPayment: number;
        totalPriceCreditsForWeeklyPayments: number;
        weeklyPriceCreditsForWeeklyPayments: number;
      } | null;
    } | null;
    section: {
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
      price_cents: number | null;
      isPublished: boolean | null;
      allowsLateEnrollment: boolean;
      meetingCount: number;
      validEnrollmentCount: number;
      isBuyable: boolean;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
      };
      meetingTimes: Array<{
        __typename?: "MeetingTime";
        dayOfWeek: number;
        hour: number;
        minute: number;
      }>;
      meetings: Array<{
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
      }>;
      progress: {
        __typename?: "CourseProgress";
        pastMeetingCount: number;
        remainingMeetingCount: number;
        totalMeetingCount: number;
        nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
      } | null;
      prepaidOngoingPeriodOptions: Array<{
        __typename?: "Period";
        startOfIsoWeek: any;
        endOfIsoWeek: any;
      }> | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        titleTranslated: string | null;
        subject: string | null;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isClub: boolean;
        slug_id: string;
        esaStatus: EsaStatus;
        size_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        is_ongoing_weekly: boolean;
        hasTeacherSchedule: boolean;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          averageActivityStarRating: number | null;
          reviewCount: number;
        };
      };
    } | null;
    enrollment: {
      __typename?: "Enrollment";
      confirmedAt: any | null;
      isActiveOrUpcoming: boolean;
      isWithdrawn: boolean;
    } | null;
    enrollableMeetings: Array<{
      __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
      startTime: any;
      endTime: any;
      isAvailable: boolean;
    } | null> | null;
  }> | null;
};

export type MarkLearnerPlanActivityAsConfirmedMutationVariables = Exact<{
  learnerPlanActivityUid: Scalars["ID"];
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type MarkLearnerPlanActivityAsConfirmedMutation = {
  __typename?: "Mutation";
  markLearnerPlanActivityAsConfirmed: {
    __typename?: "LearnerPlan";
    uid: string;
    name: string | null;
    start_date: any | null;
    end_date: any | null;
    created_from: string | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
    } | null;
    learnerPlanGoals: Array<{
      __typename?: "LearnerPlanGoal";
      uid: string;
      learner_plan_uid: string;
      created_at: any | null;
      learnerPlanGoalFilters: Array<{
        __typename?: "LearnerPlanGoalFilter";
        uid: string;
        type: string | null;
        value: string | null;
      }>;
      learnerPlanActivity: {
        __typename?: "LearnerPlanActivity";
        uid: string;
        learner_plan_uid: string;
        meeting_start_time: any | null;
        meeting_end_time: any | null;
        meeting_duration_weeks: number | null;
        created_at: any | null;
        canScheduleContinuously: boolean;
        is_pending: boolean | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          subject: string | null;
          homework: string | null;
          duration_weeks: number | null;
          duration_minutes: number | null;
          weekly_meetings: number | null;
          size_max: number | null;
          hasTeacherSchedule: boolean;
          isClub: boolean;
          isSelfPaced: boolean;
          isFlexSchedule: boolean;
          is_ongoing_weekly: boolean;
          path: string;
          price_cents: number | null;
          published_at: any | null;
          title: string;
          titleTranslated: string | null;
          allows_recurring_payment: boolean | null;
          slug_id: string;
          esaStatus: EsaStatus;
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            leader_link: string | null;
            averageActivityStarRating: number | null;
            reviewCount: number;
          };
          details: { __typename?: "ActivityDetails"; photo: string | null };
          pricingInfo: {
            __typename?: "PricingInfo";
            totalPriceCentsForUpfrontPayment: number;
            totalPriceCentsForWeeklyPayments: number;
            weeklyPriceCentsForUpfrontPayment: number;
            weeklyPriceCentsForWeeklyPayments: number;
            upfrontDiscountPercentage: number;
            totalPriceCreditsForUpfrontPayment: number;
            weeklyPriceCreditsForUpfrontPayment: number;
            totalPriceCreditsForWeeklyPayments: number;
            weeklyPriceCreditsForWeeklyPayments: number;
          } | null;
        } | null;
        section: {
          __typename?: "Section";
          uid: string;
          start_time: any | null;
          end_time: any | null;
          price_cents: number | null;
          isPublished: boolean | null;
          allowsLateEnrollment: boolean;
          meetingCount: number;
          validEnrollmentCount: number;
          isBuyable: boolean;
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            leader_link: string | null;
            averageActivityStarRating: number | null;
            reviewCount: number;
          };
          meetingTimes: Array<{
            __typename?: "MeetingTime";
            dayOfWeek: number;
            hour: number;
            minute: number;
          }>;
          meetings: Array<{
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
          }>;
          progress: {
            __typename?: "CourseProgress";
            pastMeetingCount: number;
            remainingMeetingCount: number;
            totalMeetingCount: number;
            nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
          } | null;
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
          activity: {
            __typename?: "Activity";
            uid: string;
            title: string;
            titleTranslated: string | null;
            subject: string | null;
            isFlexSchedule: boolean;
            isSelfPaced: boolean;
            isClub: boolean;
            slug_id: string;
            esaStatus: EsaStatus;
            size_max: number | null;
            duration_minutes: number | null;
            duration_weeks: number | null;
            weekly_meetings: number | null;
            price_cents: number | null;
            is_ongoing_weekly: boolean;
            hasTeacherSchedule: boolean;
            details: { __typename?: "ActivityDetails"; photo: string | null };
            leader: {
              __typename?: "Leader";
              uid: string;
              name: string | null;
              photo: string | null;
              averageActivityStarRating: number | null;
              reviewCount: number;
            };
          };
        } | null;
        enrollment: {
          __typename?: "Enrollment";
          confirmedAt: any | null;
          isActiveOrUpcoming: boolean;
          isWithdrawn: boolean;
        } | null;
        enrollableMeetings: Array<{
          __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
          startTime: any;
          endTime: any;
          isAvailable: boolean;
        } | null> | null;
      } | null;
    }>;
    entityAvailabilitySchedule: {
      __typename?: "EntityAvailabilitySchedule";
      uid: string;
    } | null;
  } | null;
};

export type LearnerPlanGoalFilterFragmentFragment = {
  __typename?: "LearnerPlanGoalFilter";
  uid: string;
  type: string | null;
  value: string | null;
};

export type LearnerPlanActivityFragmentFragment = {
  __typename?: "LearnerPlanActivity";
  uid: string;
  learner_plan_uid: string;
  meeting_start_time: any | null;
  meeting_end_time: any | null;
  meeting_duration_weeks: number | null;
  created_at: any | null;
  canScheduleContinuously: boolean;
  is_pending: boolean | null;
  activity: {
    __typename?: "Activity";
    uid: string;
    subject: string | null;
    homework: string | null;
    duration_weeks: number | null;
    duration_minutes: number | null;
    weekly_meetings: number | null;
    size_max: number | null;
    hasTeacherSchedule: boolean;
    isClub: boolean;
    isSelfPaced: boolean;
    isFlexSchedule: boolean;
    is_ongoing_weekly: boolean;
    path: string;
    price_cents: number | null;
    published_at: any | null;
    title: string;
    titleTranslated: string | null;
    allows_recurring_payment: boolean | null;
    slug_id: string;
    esaStatus: EsaStatus;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      averageActivityStarRating: number | null;
      reviewCount: number;
    };
    details: { __typename?: "ActivityDetails"; photo: string | null };
    pricingInfo: {
      __typename?: "PricingInfo";
      totalPriceCentsForUpfrontPayment: number;
      totalPriceCentsForWeeklyPayments: number;
      weeklyPriceCentsForUpfrontPayment: number;
      weeklyPriceCentsForWeeklyPayments: number;
      upfrontDiscountPercentage: number;
      totalPriceCreditsForUpfrontPayment: number;
      weeklyPriceCreditsForUpfrontPayment: number;
      totalPriceCreditsForWeeklyPayments: number;
      weeklyPriceCreditsForWeeklyPayments: number;
    } | null;
  } | null;
  section: {
    __typename?: "Section";
    uid: string;
    start_time: any | null;
    end_time: any | null;
    price_cents: number | null;
    isPublished: boolean | null;
    allowsLateEnrollment: boolean;
    meetingCount: number;
    validEnrollmentCount: number;
    isBuyable: boolean;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      averageActivityStarRating: number | null;
      reviewCount: number;
    };
    meetingTimes: Array<{
      __typename?: "MeetingTime";
      dayOfWeek: number;
      hour: number;
      minute: number;
    }>;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
    }>;
    progress: {
      __typename?: "CourseProgress";
      pastMeetingCount: number;
      remainingMeetingCount: number;
      totalMeetingCount: number;
      nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
    } | null;
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      titleTranslated: string | null;
      subject: string | null;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isClub: boolean;
      slug_id: string;
      esaStatus: EsaStatus;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      is_ongoing_weekly: boolean;
      hasTeacherSchedule: boolean;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
      };
    };
  } | null;
  enrollment: {
    __typename?: "Enrollment";
    confirmedAt: any | null;
    isActiveOrUpcoming: boolean;
    isWithdrawn: boolean;
  } | null;
  enrollableMeetings: Array<{
    __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
    startTime: any;
    endTime: any;
    isAvailable: boolean;
  } | null> | null;
};

export type LearnerPlanGoalFragmentFragment = {
  __typename?: "LearnerPlanGoal";
  uid: string;
  learner_plan_uid: string;
  created_at: any | null;
  learnerPlanGoalFilters: Array<{
    __typename?: "LearnerPlanGoalFilter";
    uid: string;
    type: string | null;
    value: string | null;
  }>;
  learnerPlanActivity: {
    __typename?: "LearnerPlanActivity";
    uid: string;
    learner_plan_uid: string;
    meeting_start_time: any | null;
    meeting_end_time: any | null;
    meeting_duration_weeks: number | null;
    created_at: any | null;
    canScheduleContinuously: boolean;
    is_pending: boolean | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      subject: string | null;
      homework: string | null;
      duration_weeks: number | null;
      duration_minutes: number | null;
      weekly_meetings: number | null;
      size_max: number | null;
      hasTeacherSchedule: boolean;
      isClub: boolean;
      isSelfPaced: boolean;
      isFlexSchedule: boolean;
      is_ongoing_weekly: boolean;
      path: string;
      price_cents: number | null;
      published_at: any | null;
      title: string;
      titleTranslated: string | null;
      allows_recurring_payment: boolean | null;
      slug_id: string;
      esaStatus: EsaStatus;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
      };
      details: { __typename?: "ActivityDetails"; photo: string | null };
      pricingInfo: {
        __typename?: "PricingInfo";
        totalPriceCentsForUpfrontPayment: number;
        totalPriceCentsForWeeklyPayments: number;
        weeklyPriceCentsForUpfrontPayment: number;
        weeklyPriceCentsForWeeklyPayments: number;
        upfrontDiscountPercentage: number;
        totalPriceCreditsForUpfrontPayment: number;
        weeklyPriceCreditsForUpfrontPayment: number;
        totalPriceCreditsForWeeklyPayments: number;
        weeklyPriceCreditsForWeeklyPayments: number;
      } | null;
    } | null;
    section: {
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
      price_cents: number | null;
      isPublished: boolean | null;
      allowsLateEnrollment: boolean;
      meetingCount: number;
      validEnrollmentCount: number;
      isBuyable: boolean;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
      };
      meetingTimes: Array<{
        __typename?: "MeetingTime";
        dayOfWeek: number;
        hour: number;
        minute: number;
      }>;
      meetings: Array<{
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
      }>;
      progress: {
        __typename?: "CourseProgress";
        pastMeetingCount: number;
        remainingMeetingCount: number;
        totalMeetingCount: number;
        nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
      } | null;
      prepaidOngoingPeriodOptions: Array<{
        __typename?: "Period";
        startOfIsoWeek: any;
        endOfIsoWeek: any;
      }> | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        titleTranslated: string | null;
        subject: string | null;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isClub: boolean;
        slug_id: string;
        esaStatus: EsaStatus;
        size_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        is_ongoing_weekly: boolean;
        hasTeacherSchedule: boolean;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          averageActivityStarRating: number | null;
          reviewCount: number;
        };
      };
    } | null;
    enrollment: {
      __typename?: "Enrollment";
      confirmedAt: any | null;
      isActiveOrUpcoming: boolean;
      isWithdrawn: boolean;
    } | null;
    enrollableMeetings: Array<{
      __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
      startTime: any;
      endTime: any;
      isAvailable: boolean;
    } | null> | null;
  } | null;
};

export type LearnerPlanFragmentFragment = {
  __typename?: "LearnerPlan";
  uid: string;
  name: string | null;
  start_date: any | null;
  end_date: any | null;
  created_from: string | null;
  learner: {
    __typename?: "Learner";
    uid: string;
    name: string | null;
    age: number | null;
  } | null;
  learnerPlanGoals: Array<{
    __typename?: "LearnerPlanGoal";
    uid: string;
    learner_plan_uid: string;
    created_at: any | null;
    learnerPlanGoalFilters: Array<{
      __typename?: "LearnerPlanGoalFilter";
      uid: string;
      type: string | null;
      value: string | null;
    }>;
    learnerPlanActivity: {
      __typename?: "LearnerPlanActivity";
      uid: string;
      learner_plan_uid: string;
      meeting_start_time: any | null;
      meeting_end_time: any | null;
      meeting_duration_weeks: number | null;
      created_at: any | null;
      canScheduleContinuously: boolean;
      is_pending: boolean | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        subject: string | null;
        homework: string | null;
        duration_weeks: number | null;
        duration_minutes: number | null;
        weekly_meetings: number | null;
        size_max: number | null;
        hasTeacherSchedule: boolean;
        isClub: boolean;
        isSelfPaced: boolean;
        isFlexSchedule: boolean;
        is_ongoing_weekly: boolean;
        path: string;
        price_cents: number | null;
        published_at: any | null;
        title: string;
        titleTranslated: string | null;
        allows_recurring_payment: boolean | null;
        slug_id: string;
        esaStatus: EsaStatus;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          leader_link: string | null;
          averageActivityStarRating: number | null;
          reviewCount: number;
        };
        details: { __typename?: "ActivityDetails"; photo: string | null };
        pricingInfo: {
          __typename?: "PricingInfo";
          totalPriceCentsForUpfrontPayment: number;
          totalPriceCentsForWeeklyPayments: number;
          weeklyPriceCentsForUpfrontPayment: number;
          weeklyPriceCentsForWeeklyPayments: number;
          upfrontDiscountPercentage: number;
          totalPriceCreditsForUpfrontPayment: number;
          weeklyPriceCreditsForUpfrontPayment: number;
          totalPriceCreditsForWeeklyPayments: number;
          weeklyPriceCreditsForWeeklyPayments: number;
        } | null;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        price_cents: number | null;
        isPublished: boolean | null;
        allowsLateEnrollment: boolean;
        meetingCount: number;
        validEnrollmentCount: number;
        isBuyable: boolean;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          leader_link: string | null;
          averageActivityStarRating: number | null;
          reviewCount: number;
        };
        meetingTimes: Array<{
          __typename?: "MeetingTime";
          dayOfWeek: number;
          hour: number;
          minute: number;
        }>;
        meetings: Array<{
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
        }>;
        progress: {
          __typename?: "CourseProgress";
          pastMeetingCount: number;
          remainingMeetingCount: number;
          totalMeetingCount: number;
          nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
        } | null;
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
          subject: string | null;
          isFlexSchedule: boolean;
          isSelfPaced: boolean;
          isClub: boolean;
          slug_id: string;
          esaStatus: EsaStatus;
          size_max: number | null;
          duration_minutes: number | null;
          duration_weeks: number | null;
          weekly_meetings: number | null;
          price_cents: number | null;
          is_ongoing_weekly: boolean;
          hasTeacherSchedule: boolean;
          details: { __typename?: "ActivityDetails"; photo: string | null };
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            averageActivityStarRating: number | null;
            reviewCount: number;
          };
        };
      } | null;
      enrollment: {
        __typename?: "Enrollment";
        confirmedAt: any | null;
        isActiveOrUpcoming: boolean;
        isWithdrawn: boolean;
      } | null;
      enrollableMeetings: Array<{
        __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
        startTime: any;
        endTime: any;
        isAvailable: boolean;
      } | null> | null;
    } | null;
  }>;
  entityAvailabilitySchedule: {
    __typename?: "EntityAvailabilitySchedule";
    uid: string;
  } | null;
};

export type LearnerPlansCurrentUserQueryQueryVariables = Exact<{
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type LearnerPlansCurrentUserQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    learnerPlans: Array<{
      __typename?: "LearnerPlan";
      uid: string;
      name: string | null;
      start_date: any | null;
      end_date: any | null;
      created_from: string | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
      } | null;
      learnerPlanGoals: Array<{
        __typename?: "LearnerPlanGoal";
        uid: string;
        learner_plan_uid: string;
        created_at: any | null;
        learnerPlanGoalFilters: Array<{
          __typename?: "LearnerPlanGoalFilter";
          uid: string;
          type: string | null;
          value: string | null;
        }>;
        learnerPlanActivity: {
          __typename?: "LearnerPlanActivity";
          uid: string;
          learner_plan_uid: string;
          meeting_start_time: any | null;
          meeting_end_time: any | null;
          meeting_duration_weeks: number | null;
          created_at: any | null;
          canScheduleContinuously: boolean;
          is_pending: boolean | null;
          activity: {
            __typename?: "Activity";
            uid: string;
            subject: string | null;
            homework: string | null;
            duration_weeks: number | null;
            duration_minutes: number | null;
            weekly_meetings: number | null;
            size_max: number | null;
            hasTeacherSchedule: boolean;
            isClub: boolean;
            isSelfPaced: boolean;
            isFlexSchedule: boolean;
            is_ongoing_weekly: boolean;
            path: string;
            price_cents: number | null;
            published_at: any | null;
            title: string;
            titleTranslated: string | null;
            allows_recurring_payment: boolean | null;
            slug_id: string;
            esaStatus: EsaStatus;
            leader: {
              __typename?: "Leader";
              uid: string;
              name: string | null;
              photo: string | null;
              leader_link: string | null;
              averageActivityStarRating: number | null;
              reviewCount: number;
            };
            details: { __typename?: "ActivityDetails"; photo: string | null };
            pricingInfo: {
              __typename?: "PricingInfo";
              totalPriceCentsForUpfrontPayment: number;
              totalPriceCentsForWeeklyPayments: number;
              weeklyPriceCentsForUpfrontPayment: number;
              weeklyPriceCentsForWeeklyPayments: number;
              upfrontDiscountPercentage: number;
              totalPriceCreditsForUpfrontPayment: number;
              weeklyPriceCreditsForUpfrontPayment: number;
              totalPriceCreditsForWeeklyPayments: number;
              weeklyPriceCreditsForWeeklyPayments: number;
            } | null;
          } | null;
          section: {
            __typename?: "Section";
            uid: string;
            start_time: any | null;
            end_time: any | null;
            price_cents: number | null;
            isPublished: boolean | null;
            allowsLateEnrollment: boolean;
            meetingCount: number;
            validEnrollmentCount: number;
            isBuyable: boolean;
            leader: {
              __typename?: "Leader";
              uid: string;
              name: string | null;
              photo: string | null;
              leader_link: string | null;
              averageActivityStarRating: number | null;
              reviewCount: number;
            };
            meetingTimes: Array<{
              __typename?: "MeetingTime";
              dayOfWeek: number;
              hour: number;
              minute: number;
            }>;
            meetings: Array<{
              __typename?: "Meeting";
              uid: string;
              start_time: any;
              end_time: any;
            }>;
            progress: {
              __typename?: "CourseProgress";
              pastMeetingCount: number;
              remainingMeetingCount: number;
              totalMeetingCount: number;
              nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
            } | null;
            prepaidOngoingPeriodOptions: Array<{
              __typename?: "Period";
              startOfIsoWeek: any;
              endOfIsoWeek: any;
            }> | null;
            activity: {
              __typename?: "Activity";
              uid: string;
              title: string;
              titleTranslated: string | null;
              subject: string | null;
              isFlexSchedule: boolean;
              isSelfPaced: boolean;
              isClub: boolean;
              slug_id: string;
              esaStatus: EsaStatus;
              size_max: number | null;
              duration_minutes: number | null;
              duration_weeks: number | null;
              weekly_meetings: number | null;
              price_cents: number | null;
              is_ongoing_weekly: boolean;
              hasTeacherSchedule: boolean;
              details: { __typename?: "ActivityDetails"; photo: string | null };
              leader: {
                __typename?: "Leader";
                uid: string;
                name: string | null;
                photo: string | null;
                averageActivityStarRating: number | null;
                reviewCount: number;
              };
            };
          } | null;
          enrollment: {
            __typename?: "Enrollment";
            confirmedAt: any | null;
            isActiveOrUpcoming: boolean;
            isWithdrawn: boolean;
          } | null;
          enrollableMeetings: Array<{
            __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
            startTime: any;
            endTime: any;
            isAvailable: boolean;
          } | null> | null;
        } | null;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        uid: string;
      } | null;
    }>;
  } | null;
};

export type LearnerPlansQueryVariables = Exact<{
  learnerUid: Scalars["ID"];
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type LearnerPlansQuery = {
  __typename?: "Query";
  learnerPlans: Array<{
    __typename?: "LearnerPlan";
    uid: string;
    name: string | null;
    start_date: any | null;
    end_date: any | null;
    created_from: string | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
    } | null;
    learnerPlanGoals: Array<{
      __typename?: "LearnerPlanGoal";
      uid: string;
      learner_plan_uid: string;
      created_at: any | null;
      learnerPlanGoalFilters: Array<{
        __typename?: "LearnerPlanGoalFilter";
        uid: string;
        type: string | null;
        value: string | null;
      }>;
      learnerPlanActivity: {
        __typename?: "LearnerPlanActivity";
        uid: string;
        learner_plan_uid: string;
        meeting_start_time: any | null;
        meeting_end_time: any | null;
        meeting_duration_weeks: number | null;
        created_at: any | null;
        canScheduleContinuously: boolean;
        is_pending: boolean | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          subject: string | null;
          homework: string | null;
          duration_weeks: number | null;
          duration_minutes: number | null;
          weekly_meetings: number | null;
          size_max: number | null;
          hasTeacherSchedule: boolean;
          isClub: boolean;
          isSelfPaced: boolean;
          isFlexSchedule: boolean;
          is_ongoing_weekly: boolean;
          path: string;
          price_cents: number | null;
          published_at: any | null;
          title: string;
          titleTranslated: string | null;
          allows_recurring_payment: boolean | null;
          slug_id: string;
          esaStatus: EsaStatus;
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            leader_link: string | null;
            averageActivityStarRating: number | null;
            reviewCount: number;
          };
          details: { __typename?: "ActivityDetails"; photo: string | null };
          pricingInfo: {
            __typename?: "PricingInfo";
            totalPriceCentsForUpfrontPayment: number;
            totalPriceCentsForWeeklyPayments: number;
            weeklyPriceCentsForUpfrontPayment: number;
            weeklyPriceCentsForWeeklyPayments: number;
            upfrontDiscountPercentage: number;
            totalPriceCreditsForUpfrontPayment: number;
            weeklyPriceCreditsForUpfrontPayment: number;
            totalPriceCreditsForWeeklyPayments: number;
            weeklyPriceCreditsForWeeklyPayments: number;
          } | null;
        } | null;
        section: {
          __typename?: "Section";
          uid: string;
          start_time: any | null;
          end_time: any | null;
          price_cents: number | null;
          isPublished: boolean | null;
          allowsLateEnrollment: boolean;
          meetingCount: number;
          validEnrollmentCount: number;
          isBuyable: boolean;
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            leader_link: string | null;
            averageActivityStarRating: number | null;
            reviewCount: number;
          };
          meetingTimes: Array<{
            __typename?: "MeetingTime";
            dayOfWeek: number;
            hour: number;
            minute: number;
          }>;
          meetings: Array<{
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
          }>;
          progress: {
            __typename?: "CourseProgress";
            pastMeetingCount: number;
            remainingMeetingCount: number;
            totalMeetingCount: number;
            nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
          } | null;
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
          activity: {
            __typename?: "Activity";
            uid: string;
            title: string;
            titleTranslated: string | null;
            subject: string | null;
            isFlexSchedule: boolean;
            isSelfPaced: boolean;
            isClub: boolean;
            slug_id: string;
            esaStatus: EsaStatus;
            size_max: number | null;
            duration_minutes: number | null;
            duration_weeks: number | null;
            weekly_meetings: number | null;
            price_cents: number | null;
            is_ongoing_weekly: boolean;
            hasTeacherSchedule: boolean;
            details: { __typename?: "ActivityDetails"; photo: string | null };
            leader: {
              __typename?: "Leader";
              uid: string;
              name: string | null;
              photo: string | null;
              averageActivityStarRating: number | null;
              reviewCount: number;
            };
          };
        } | null;
        enrollment: {
          __typename?: "Enrollment";
          confirmedAt: any | null;
          isActiveOrUpcoming: boolean;
          isWithdrawn: boolean;
        } | null;
        enrollableMeetings: Array<{
          __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
          startTime: any;
          endTime: any;
          isAvailable: boolean;
        } | null> | null;
      } | null;
    }>;
    entityAvailabilitySchedule: {
      __typename?: "EntityAvailabilitySchedule";
      uid: string;
    } | null;
  }>;
};

export type LearnerPlanRejectedSuggestionsForLearnerQueryQueryVariables =
  Exact<{
    learnerUid: Scalars["ID"];
  }>;

export type LearnerPlanRejectedSuggestionsForLearnerQueryQuery = {
  __typename?: "Query";
  learnerPlanRejectedSuggestionsForLearner: Array<{
    __typename?: "LearnerPlanRejectedSuggestion";
    uid: string;
    activity: { __typename?: "Activity"; uid: string } | null;
  }>;
};

export type PlannerCalendar_MeetingFragment = {
  __typename?: "Meeting";
  uid: string;
  start_time: any;
  end_time: any;
  sectionSequenceNumber: number;
};

export type PlannerCalendar_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  title: string;
  titleTranslated: string | null;
  subject: string | null;
  isFlexSchedule: boolean;
  isSelfPaced: boolean;
  isClub: boolean;
  slug_id: string;
  esaStatus: EsaStatus;
  size_max: number | null;
  duration_minutes: number | null;
  duration_weeks: number | null;
  weekly_meetings: number | null;
  price_cents: number | null;
  is_ongoing_weekly: boolean;
  hasTeacherSchedule: boolean;
  details: { __typename?: "ActivityDetails"; photo: string | null };
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    photo: string | null;
    averageActivityStarRating: number | null;
    reviewCount: number;
  };
};

export type PlannerCalendar_SectionFragment = {
  __typename?: "Section";
  uid: string;
  start_time: any | null;
  end_time: any | null;
  meetingCount: number;
  validEnrollmentCount: number;
  isBuyable: boolean;
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    titleTranslated: string | null;
    subject: string | null;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isClub: boolean;
    slug_id: string;
    esaStatus: EsaStatus;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    is_ongoing_weekly: boolean;
    hasTeacherSchedule: boolean;
    details: { __typename?: "ActivityDetails"; photo: string | null };
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      averageActivityStarRating: number | null;
      reviewCount: number;
    };
  };
  meetingTimes: Array<{
    __typename?: "MeetingTime";
    dayOfWeek: number;
    hour: number;
    minute: number;
  }>;
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    photo: string | null;
    averageActivityStarRating: number | null;
    reviewCount: number;
  };
};

export type PlannerCalendar_LearnerFragment = {
  __typename?: "Learner";
  uid: string;
  name: string | null;
  age: number | null;
  avatar: string | null;
  pronoun: string | null;
  intro: string | null;
  deleted_at: any | null;
  created_at: any;
  isVerified: boolean;
};

export type PlannerCalendar_EnrollmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  confirmedAt: any | null;
  withdrawnAt: any | null;
  ongoingStartTime: any | null;
  ongoingEndTime: any | null;
  pauseWindow: {
    __typename?: "EnrollmentPauseWindow";
    uid: string;
    startDate: any;
    endDate: any;
  } | null;
  learner: {
    __typename?: "Learner";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    created_at: any;
    isVerified: boolean;
  } | null;
  section: {
    __typename?: "Section";
    uid: string;
    start_time: any | null;
    end_time: any | null;
    meetingCount: number;
    validEnrollmentCount: number;
    isBuyable: boolean;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      sectionSequenceNumber: number;
    }>;
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      titleTranslated: string | null;
      subject: string | null;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isClub: boolean;
      slug_id: string;
      esaStatus: EsaStatus;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      is_ongoing_weekly: boolean;
      hasTeacherSchedule: boolean;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
      };
    };
    meetingTimes: Array<{
      __typename?: "MeetingTime";
      dayOfWeek: number;
      hour: number;
      minute: number;
    }>;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      averageActivityStarRating: number | null;
      reviewCount: number;
    };
  } | null;
};

export type PlannerCalendarEnrollmentsQueryVariables = Exact<{
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type PlannerCalendarEnrollmentsQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    activeEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        created_at: any;
        isVerified: boolean;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        meetingCount: number;
        validEnrollmentCount: number;
        isBuyable: boolean;
        meetings: Array<{
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          sectionSequenceNumber: number;
        }>;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
          subject: string | null;
          isFlexSchedule: boolean;
          isSelfPaced: boolean;
          isClub: boolean;
          slug_id: string;
          esaStatus: EsaStatus;
          size_max: number | null;
          duration_minutes: number | null;
          duration_weeks: number | null;
          weekly_meetings: number | null;
          price_cents: number | null;
          is_ongoing_weekly: boolean;
          hasTeacherSchedule: boolean;
          details: { __typename?: "ActivityDetails"; photo: string | null };
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            averageActivityStarRating: number | null;
            reviewCount: number;
          };
        };
        meetingTimes: Array<{
          __typename?: "MeetingTime";
          dayOfWeek: number;
          hour: number;
          minute: number;
        }>;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          averageActivityStarRating: number | null;
          reviewCount: number;
        };
      } | null;
    } | null>;
  } | null;
};

export type PlannerSectionCard_LeaderFragment = {
  __typename?: "Leader";
  uid: string;
  name: string | null;
  photo: string | null;
  averageActivityStarRating: number | null;
  reviewCount: number;
};

export type PlannerSectionCard_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  title: string;
  subject: string | null;
  isSelfPaced: boolean;
  isClub: boolean;
  esaStatus: EsaStatus;
  size_max: number | null;
  duration_minutes: number | null;
  duration_weeks: number | null;
  weekly_meetings: number | null;
  price_cents: number | null;
  is_ongoing_weekly: boolean;
  hasTeacherSchedule: boolean;
  details: { __typename?: "ActivityDetails"; photo: string | null };
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    photo: string | null;
    averageActivityStarRating: number | null;
    reviewCount: number;
  };
};

export type PlannerSectionCard_SectionFragment = {
  __typename?: "Section";
  uid: string;
  start_time: any | null;
  end_time: any | null;
  isBuyable: boolean;
  meetingTimes: Array<{
    __typename?: "MeetingTime";
    dayOfWeek: number;
    hour: number;
    minute: number;
  }>;
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    photo: string | null;
    averageActivityStarRating: number | null;
    reviewCount: number;
  };
};

export type WaitlistSeatsForParentLearningPlannerQueryVariables = Exact<{
  parentUid: Scalars["ID"];
}>;

export type WaitlistSeatsForParentLearningPlannerQuery = {
  __typename?: "Query";
  waitlistSeatsForParent: Array<{
    __typename?: "WaitlistSeat";
    uid: string;
    createdAt: any | null;
    section: {
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
      meetingCount: number;
      validEnrollmentCount: number;
      isBuyable: boolean;
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        subject: string | null;
        isSelfPaced: boolean;
        isClub: boolean;
        size_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        is_ongoing_weekly: boolean;
        hasTeacherSchedule: boolean;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          averageActivityStarRating: number | null;
          reviewCount: number;
        };
      };
      meetingTimes: Array<{
        __typename?: "MeetingTime";
        dayOfWeek: number;
        hour: number;
        minute: number;
      }>;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
      };
    };
  } | null>;
};

export type LearnerPlannerCardMeetingsQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  startAfter: Scalars["DateTime"];
  startBefore: Scalars["DateTime"];
  includeRecurringSchedule: Scalars["Boolean"];
}>;

export type LearnerPlannerCardMeetingsQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      recurringSchedule?: {
        __typename?: "RecurringSchedule";
        uid: string;
        end_at: any | null;
      } | null;
    }>;
  } | null;
};

export type OnboardTeachersPage_UserFragment = {
  __typename?: "User";
  uid: string;
  name: string | null;
  adminActions: Array<{
    __typename?: "AdminUserAction";
    uid: string;
    created_at: any;
    action: string;
    notes: string | null;
    customValue: string | null;
    admin: { __typename?: "User"; uid: string; name: string | null };
  }>;
  latestTeacherApplication: {
    __typename?: "TeacherApplication";
    uid: string;
    legal_name: string | null;
    status: TeacherApplicationStatus;
    submitted_at: any | null;
    reviewed_at: any | null;
    teaching_experience: string | null;
    teaching_credentials: string | null;
    class_types: string | null;
    online_profiles: string | null;
    sample_classes: string | null;
    class_subjects: Array<string | null> | null;
    age_ranges_taught: Array<string | null> | null;
    years_of_experience: string | null;
    how_did_you_hear_about_us: string | null;
    experience_types: Array<string | null> | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  } | null;
};

export type OnboardTeachersPageQueryQueryVariables = Exact<{
  uid: Scalars["String"];
}>;

export type OnboardTeachersPageQueryQuery = {
  __typename?: "Query";
  user: {
    __typename?: "User";
    uid: string;
    name: string | null;
    adminActions: Array<{
      __typename?: "AdminUserAction";
      uid: string;
      created_at: any;
      action: string;
      notes: string | null;
      customValue: string | null;
      admin: { __typename?: "User"; uid: string; name: string | null };
    }>;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
  } | null;
};

export type OnboardTeachersAddAdminUserActionMutationVariables = Exact<{
  userUid: Scalars["ID"];
  action: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
}>;

export type OnboardTeachersAddAdminUserActionMutation = {
  __typename?: "Mutation";
  addAdminUserAction: {
    __typename?: "AdminUserAction";
    uid: string;
    created_at: any;
    action: string;
    notes: string | null;
    customValue: string | null;
    admin: { __typename?: "User"; uid: string; name: string | null };
    user: {
      __typename?: "User";
      uid: string;
      name: string | null;
      adminActions: Array<{
        __typename?: "AdminUserAction";
        uid: string;
        created_at: any;
        action: string;
        notes: string | null;
        customValue: string | null;
        admin: { __typename?: "User"; uid: string; name: string | null };
      }>;
      latestTeacherApplication: {
        __typename?: "TeacherApplication";
        uid: string;
        legal_name: string | null;
        status: TeacherApplicationStatus;
        submitted_at: any | null;
        reviewed_at: any | null;
        teaching_experience: string | null;
        teaching_credentials: string | null;
        class_types: string | null;
        online_profiles: string | null;
        sample_classes: string | null;
        class_subjects: Array<string | null> | null;
        age_ranges_taught: Array<string | null> | null;
        years_of_experience: string | null;
        how_did_you_hear_about_us: string | null;
        experience_types: Array<string | null> | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      } | null;
    };
  };
};

export type ParentProfileQueryVariables = Exact<{
  uid?: InputMaybe<Scalars["ID"]>;
}>;

export type ParentProfileQuery = {
  __typename?: "Query";
  publicProfile: {
    __typename?: "PublicProfile";
    parent: {
      __typename?: "Parent";
      slug_id: string | null;
      reviewCount: number;
      uniqueActivityCount: number;
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
      consent_to_share_class_history: boolean | null;
      consent_to_share_favourites: boolean | null;
      sectionsWithCurrentUser: Array<{
        __typename?: "Section";
        filledSpaceCount: number;
        uid: string;
        activity_uid: string;
        start_time: any | null;
        end_time: any | null;
        startsAtNight: boolean | null;
        is_cross_listed: boolean;
        size_max: number | null;
        size_min: number | null;
        isPublished: boolean | null;
        price_cents: number | null;
        priceCredits: number | null;
        published_at: any | null;
        updated_at: any;
        canceled_at: any | null;
        deleted_at: any | null;
        ongoing_stopped_at: any | null;
        duration_minutes: number | null;
        usesOutschoolVideoChat: boolean;
        usersCanSendPrivateMessage: boolean;
        currentUserCanManage: boolean;
        isBuyable: boolean;
        allowsTeacherTransfer: boolean;
        meetings: Array<{
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        }>;
        activity: {
          __typename?: "Activity";
          uid: string;
          slug_id: string;
          user_uid: string;
          title: string;
          titleTranslated: string | null;
          url: string;
          path: string;
          class_experience: string | null;
          learning_goals: string | null;
          learningGoals: Array<string>;
          assessment: string | null;
          homework: string | null;
          grading_policy: string | null;
          prerequisites: string | null;
          published_at: any | null;
          is_online: boolean;
          age_min: number | null;
          age_max: number | null;
          english_proficiency_level: EnglishProficiencyLevel | null;
          grade_level_min: string | null;
          grade_level_max: string | null;
          size_min: number | null;
          size_max: number | null;
          duration_minutes: number | null;
          duration_weeks: number | null;
          weekly_meetings: number | null;
          price_cents: number | null;
          priceCredits: number | null;
          summary: string | null;
          summaryTranslated: string | null;
          is_ongoing_weekly: boolean;
          subject: string | null;
          languageOfInstruction: any | null;
          summaryForLearners: string | null;
          allowWaitlist: boolean | null;
          isClub: boolean;
          isAutoSchedulingEnabled: boolean | null;
          autoSchedulingDaysNoticeMin: number | null;
          autoSchedulingDaysNoticeMax: number | null;
          allows_recurring_payment: boolean | null;
          allows_late_enrollments_for_fixed_length_classes: boolean;
          has_building_content: boolean | null;
          hasTeacherSchedule: boolean;
          isFlexSchedule: boolean;
          isSelfPaced: boolean;
          isOneTime: boolean;
          classFormat: EducatorSelectedFormat;
          classroomType: ClassroomType;
          strictAgeRange: {
            __typename?: "AgeRangeType";
            min: number;
            max: number;
          } | null;
          proficiency_level: {
            __typename?: "ProficiencyLevel";
            level: Array<string | null> | null;
            text: string | null;
          } | null;
          refundPolicy: {
            __typename?: "RefundPolicy";
            name: string;
            description: string;
            type: RefundPolicyType;
          };
          details: {
            __typename?: "ActivityDetails";
            photo: string | null;
            photoSquare: string | null;
          };
        };
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          leader_link: string | null;
          created_at: any | null;
          photo: string | null;
          suspended_at: any | null;
          cityState: string | null;
          socialOptOut: boolean;
          is_crosslister: boolean | null;
          timeZone: string | null;
          hasAvailabilitySet: boolean;
          approved: boolean;
          hasBackgroundCheckExpired: boolean | null;
          reviewCount: number;
          averageActivityStarRating: number | null;
          hasListings: boolean;
          details: {
            __typename?: "LeaderDetails";
            about: string | null;
            headline: string | null;
            headlineTranslated: string | null;
            countryOfResidence: string | null;
          };
          ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
          teacherForSellerOrg: {
            __typename?: "SellerOrg";
            uid: string;
            name: string;
          } | null;
        };
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
        };
        prepaidOngoingPeriodOptions: Array<{
          __typename?: "Period";
          startOfIsoWeek: any;
          endOfIsoWeek: any;
        }> | null;
      }>;
    } | null;
  } | null;
};

export type PostPurchasePageRecommendationsQueryVariables = Exact<{
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type PostPurchasePageRecommendationsQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    personalizedRecommendations: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
      activity: {
        __typename?: "Activity";
        uid: string;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        price_cents: number | null;
        isClub: boolean;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        isOneTime: boolean;
        hasTeacherSchedule: boolean;
        size_max: number | null;
        user_uid: string;
        slug_id: string;
        title: string;
        titleTranslated: string | null;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        is_ongoing_weekly: boolean;
        subject: string | null;
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
      } | null;
    }>;
  };
};

export type PrivateClassMessagesPageQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
  hasLearner: Scalars["Boolean"];
}>;

export type PrivateClassMessagesPageQuery = {
  __typename?: "Query";
  learner?: {
    __typename?: "Learner";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    isVerified: boolean;
    isBlocked: boolean | null;
    blockedByUserUid: string | null;
    blockedAt: any | null;
  } | null;
  section: {
    __typename?: "Section";
    filledSpaceCount: number;
    uid: string;
    activity_uid: string;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_cross_listed: boolean;
    size_max: number | null;
    size_min: number | null;
    isPublished: boolean | null;
    price_cents: number | null;
    priceCredits: number | null;
    published_at: any | null;
    updated_at: any;
    canceled_at: any | null;
    deleted_at: any | null;
    ongoing_stopped_at: any | null;
    duration_minutes: number | null;
    usesOutschoolVideoChat: boolean;
    usersCanSendPrivateMessage: boolean;
    currentUserCanManage: boolean;
    isBuyable: boolean;
    allowsTeacherTransfer: boolean;
    currentUserEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      section_uid: string | null;
      price_cents: number | null;
      priceCents: number | null;
      created_at: any | null;
      confirmed_at: any | null;
      confirmedAt: any | null;
      withdrawn_at: any | null;
      ongoing_stopped_at: any | null;
      currency_code: CurrencyCode;
      exchange_rate: number;
      amount_in_local_currency: number;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      withdrawnAt: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      isPaused: boolean;
      isEnded: boolean;
      isPrepay: boolean;
      refundableAmountCents: number;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      usersCanCommunicate: boolean;
      purchasedAsGift: boolean;
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        type: RefundPolicyType;
        name: string;
        description: string;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      siblings: Array<{
        __typename?: "Sibling";
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
          age: number | null;
          avatar: string | null;
          pronoun: string | null;
          intro: string | null;
          deleted_at: any | null;
          isVerified: boolean;
          isBlocked: boolean | null;
          blockedByUserUid: string | null;
          blockedAt: any | null;
        };
        currentOrLastSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          uid: string;
          activity_uid: string;
          start_time: any | null;
          end_time: any | null;
          startsAtNight: boolean | null;
          is_cross_listed: boolean;
          size_max: number | null;
          size_min: number | null;
          isPublished: boolean | null;
          price_cents: number | null;
          priceCredits: number | null;
          published_at: any | null;
          updated_at: any;
          canceled_at: any | null;
          deleted_at: any | null;
          ongoing_stopped_at: any | null;
          duration_minutes: number | null;
          usesOutschoolVideoChat: boolean;
          usersCanSendPrivateMessage: boolean;
          currentUserCanManage: boolean;
          isBuyable: boolean;
          allowsTeacherTransfer: boolean;
          nextOngoingMeeting: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
            deleted_at: any | null;
            created_at: any | null;
          } | null;
          leader: { __typename?: "Leader"; uid: string; name: string | null };
          details: {
            __typename?: "SectionDetails";
            autoScheduledDraft: boolean | null;
          };
          prepaidOngoingPeriodOptions: Array<{
            __typename?: "Period";
            startOfIsoWeek: any;
            endOfIsoWeek: any;
          }> | null;
        };
      }>;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
        endTime: any | null;
        scheduleType: PaymentScheduleTypes;
        amountCents: number | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
    }>;
    activity: {
      __typename?: "Activity";
      uid: string;
      slug_id: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      class_experience: string | null;
      learning_goals: string | null;
      learningGoals: Array<string>;
      assessment: string | null;
      homework: string | null;
      grading_policy: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      grade_level_min: string | null;
      grade_level_max: string | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      priceCredits: number | null;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      allowWaitlist: boolean | null;
      isClub: boolean;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      allows_recurring_payment: boolean | null;
      allows_late_enrollments_for_fixed_length_classes: boolean;
      has_building_content: boolean | null;
      hasTeacherSchedule: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      classFormat: EducatorSelectedFormat;
      classroomType: ClassroomType;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
        created_at: any | null;
        photo: string | null;
        suspended_at: any | null;
        cityState: string | null;
        socialOptOut: boolean;
        is_crosslister: boolean | null;
        timeZone: string | null;
        hasAvailabilitySet: boolean;
        approved: boolean;
        hasBackgroundCheckExpired: boolean | null;
        reviewCount: number;
        averageActivityStarRating: number | null;
        hasListings: boolean;
        details: {
          __typename?: "LeaderDetails";
          about: string | null;
          headline: string | null;
          headlineTranslated: string | null;
          countryOfResidence: string | null;
        };
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        teacherForSellerOrg: {
          __typename?: "SellerOrg";
          uid: string;
          name: string;
        } | null;
      };
      strictAgeRange: {
        __typename?: "AgeRangeType";
        min: number;
        max: number;
      } | null;
      proficiency_level: {
        __typename?: "ProficiencyLevel";
        level: Array<string | null> | null;
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
      };
      details: {
        __typename?: "ActivityDetails";
        photo: string | null;
        photoSquare: string | null;
      };
    };
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
    details: {
      __typename?: "SectionDetails";
      autoScheduledDraft: boolean | null;
      onlineClassroom: {
        __typename?: "OnlineClassroom";
        type: string | null;
        url: string | null;
      } | null;
    };
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
  } | null;
};

export type CurrentUserLearnerUidQueryVariables = Exact<{
  enrollmentUid: Scalars["String"];
}>;

export type CurrentUserLearnerUidQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    enrollment: {
      __typename?: "Enrollment";
      uid: string;
      learner: { __typename?: "Learner"; uid: string } | null;
    };
  } | null;
};

export type AddAttachmentMutationVariables = Exact<{
  file: FileInput;
  convert?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AddAttachmentMutation = {
  __typename?: "Mutation";
  addAttachment: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  };
};

export type CreateReviewMutationVariables = Exact<{
  feedbackInput: FeedbackInput;
}>;

export type CreateReviewMutation = {
  __typename?: "Mutation";
  createOrUpdateFeedback: { __typename?: "Feedback"; uid: string };
};

export type ReviewSectionQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type ReviewSectionQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      slug_id: string;
      isClub: boolean;
    };
    currentUserFeedback: {
      __typename?: "Feedback";
      uid: string;
      publicReview: string | null;
      activity_star_rating: number | null;
      leaderFeedback: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        file: { __typename?: "File"; filename: string; url: string };
      }>;
    } | null;
  } | null;
};

export type ReviewTeacherPageQueryVariables = Exact<{
  uid: Scalars["String"];
}>;

export type ReviewTeacherPageQuery = {
  __typename?: "Query";
  user: {
    __typename?: "User";
    uid: string;
    teacher_approved_at: any | null;
    teacher_featured_at: any | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    location: { __typename?: "Location"; address: string | null } | null;
    details: { __typename?: "UserDetails"; browserTimeZone: string | null };
    adminActions: Array<{
      __typename?: "AdminUserAction";
      uid: string;
      created_at: any;
      action: string;
      notes: string | null;
      customValue: string | null;
      admin: { __typename?: "User"; uid: string; name: string | null };
    }>;
    leaderActivities: Array<{
      __typename?: "Activity";
      uid: string;
      title: string;
    }>;
    approvalCriteria: Array<{
      __typename?: "ApprovalCriterion";
      description: string;
      value: ApprovalCriterionValue;
    }>;
    leader: {
      __typename?: "Leader";
      uid: string;
      leader_link: string | null;
      name: string | null;
      photo: string | null;
      reviewCount: number;
      successfulClassCount: number;
      video: {
        __typename?: "Attachment";
        file: { __typename?: "File"; url: string; thumbUrl: string | null };
      } | null;
      details: {
        __typename?: "LeaderDetails";
        headline: string | null;
        about: string | null;
      };
      tags: {
        __typename?: "TeacherTagsConnection";
        edges: Array<{
          __typename?: "TeacherTagEdge";
          node: { __typename?: "Tag"; uid: string; key: string; value: string };
        }>;
      };
    } | null;
  } | null;
};

export type NextTeacherToReviewQueryVariables = Exact<{ [key: string]: never }>;

export type NextTeacherToReviewQuery = {
  __typename?: "Query";
  nextTeacherToReview: { __typename?: "User"; uid: string } | null;
};

export type AddAdminUserActionReviewTeacherMutationVariables = Exact<{
  userUid: Scalars["ID"];
  action: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
}>;

export type AddAdminUserActionReviewTeacherMutation = {
  __typename?: "Mutation";
  addAdminUserAction: {
    __typename?: "AdminUserAction";
    uid: string;
    created_at: any;
    action: string;
    notes: string | null;
    customValue: string | null;
  };
};

export type SectionAdminQueryQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  enrollmentsLimit?: InputMaybe<Scalars["Int"]>;
  enrollmentsOffset?: InputMaybe<Scalars["Int"]>;
  meetingsLimit?: InputMaybe<Scalars["Int"]>;
  meetingsOffset?: InputMaybe<Scalars["Int"]>;
  meetingsSortOrder?: InputMaybe<SortOrder>;
  meetingsStartBefore?: InputMaybe<Scalars["DateTime"]>;
}>;

export type SectionAdminQueryQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    filledSpaceCount: number;
    uid: string;
    start_time: any | null;
    end_time: any | null;
    activity_uid: string;
    startsAtNight: boolean | null;
    is_cross_listed: boolean;
    size_max: number | null;
    size_min: number | null;
    isPublished: boolean | null;
    price_cents: number | null;
    priceCredits: number | null;
    published_at: any | null;
    updated_at: any;
    canceled_at: any | null;
    deleted_at: any | null;
    ongoing_stopped_at: any | null;
    duration_minutes: number | null;
    usesOutschoolVideoChat: boolean;
    usersCanSendPrivateMessage: boolean;
    currentUserCanManage: boolean;
    isBuyable: boolean;
    allowsTeacherTransfer: boolean;
    created_at: any;
    validEnrollmentCount: number;
    isAutoCanceled: boolean;
    currentUserIsOwner: boolean;
    paidAmount: number | null;
    notes: string | null;
    standardPayoutCents: number;
    is_self_paced: boolean;
    leader: {
      __typename?: "Leader";
      name: string | null;
      uid: string;
      leader_link: string | null;
      created_at: any | null;
      photo: string | null;
      suspended_at: any | null;
      cityState: string | null;
      socialOptOut: boolean;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      hasBackgroundCheckExpired: boolean | null;
      reviewCount: number;
      averageActivityStarRating: number | null;
      hasListings: boolean;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        headlineTranslated: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
    };
    activity: {
      __typename?: "Activity";
      uid: string;
      slug_id: string;
      subject: string | null;
      title: string;
      titleTranslated: string | null;
      price_cents: number | null;
      isClub: boolean;
      duration_weeks: number | null;
      is_ongoing_weekly: boolean;
      weekly_meetings: number | null;
      user_uid: string;
      url: string;
      path: string;
      class_experience: string | null;
      learning_goals: string | null;
      learningGoals: Array<string>;
      assessment: string | null;
      homework: string | null;
      grading_policy: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      english_proficiency_level: EnglishProficiencyLevel | null;
      grade_level_min: string | null;
      grade_level_max: string | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      priceCredits: number | null;
      summary: string | null;
      summaryTranslated: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      allowWaitlist: boolean | null;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      allows_recurring_payment: boolean | null;
      allows_late_enrollments_for_fixed_length_classes: boolean;
      has_building_content: boolean | null;
      hasTeacherSchedule: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      classFormat: EducatorSelectedFormat;
      classroomType: ClassroomType;
      saveCount: number | null;
      archived_at: any | null;
      upsell_activity_uid: string | null;
      why_me: string | null;
      parental_guidance: string | null;
      sources: string | null;
      materials: string | null;
      hasSections: boolean;
      has_external_resources: boolean | null;
      details: {
        __typename?: "ActivityDetails";
        photo: string | null;
        photoSquare: string | null;
        asyncTime: string | null;
      };
      strictAgeRange: {
        __typename?: "AgeRangeType";
        min: number;
        max: number;
      } | null;
      proficiency_level: {
        __typename?: "ProficiencyLevel";
        level: Array<string | null> | null;
        text: string | null;
      } | null;
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
        type: RefundPolicyType;
      };
      leaderDetails: {
        __typename?: "ActivityLeaderDetails";
        uid: string;
        requestedListingAt: any | null;
        welcomeMessage: {
          __typename?: "WelcomeMessage";
          text: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
            };
          }> | null;
          video: {
            __typename?: "Attachment";
            uid: string;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
            };
          } | null;
        } | null;
      };
      externalResources: Array<{
        __typename?: "ExternalResource";
        uid: string;
        name: string;
        url: string | null;
      }>;
    };
    feedbacks: Array<{
      __typename?: "FeedbackForLeader";
      uid: string;
      activity_star_rating: number | null;
      created_at: any;
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        email: string | null;
        photo: string | null;
        timeZone: string | null;
      };
      details: {
        __typename?: "FeedbackForLeaderDetails";
        leaderFeedback: string | null;
      };
    }>;
    messages: Array<{
      __typename?: "Message";
      uid: string;
      subject: string | null;
      action: string | null;
      content: string | null;
      created_at: any;
      activity_uid: string | null;
      section_uid: string | null;
      thread_uid: string;
      sender: {
        __typename?: "Sender";
        uid: string;
        name: string | null;
        photo: string | null;
      };
      participants: Array<{
        __typename?: "Participant";
        uid: string;
        name: string | null;
        photo: string | null;
        timeZone: string | null;
        headline: string | null;
        leader_link: string | null;
        isLearner: boolean;
        role: ParticipantRole | null;
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      }>;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      } | null;
    }>;
    pendingOneOnOneMeetingOrdersForAdmin: Array<{
      __typename?: "EnrollmentMeetingPurchaseInfoForOrderSlug";
      confirmedAt: any | null;
      createdAt: any;
      orderSlugId: string;
      startTime: any;
      endTime: any;
      weeks: number;
    }>;
    enrollments: {
      __typename?: "EnrollmentsForAdmin";
      total: number;
      data: Array<{
        __typename?: "Enrollment";
        refundableAmountCents: number;
        uid: string;
        ongoingStartTime: any | null;
        ongoing_start_time: any | null;
        ongoingEndTime: any | null;
        ongoing_end_time: any | null;
        ongoingStoppedAt: any | null;
        ongoing_stopped_at: any | null;
        orderSlugId: string | null;
        isEnded: boolean;
        isPaused: boolean;
        isPrepay: boolean;
        created_at: any | null;
        createdAt: any;
        withdrawn_at: any | null;
        withdrawnAt: any | null;
        confirmed_at: any | null;
        confirmedAt: any | null;
        currency_code: CurrencyCode;
        exchange_rate: number;
        hasPaymentSchedule: boolean;
        isPendingEsaOrder: boolean;
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
          age: number | null;
        } | null;
        parent: {
          __typename?: "Parent";
          uid: string;
          name: string | null;
          email: string | null;
          photo: string | null;
          timeZone: string | null;
        };
        payments: Array<{
          __typename?: "EnrollmentPayment";
          uid: string;
          paid_at: any | null;
          purchase_order_id: string | null;
          esa_order_id: string | null;
          amount_cents: number | null;
          amount_credits: number | null;
          order_slug_id: string | null;
          intercom_url: string | null;
          disbursed_at: any | null;
          payout_uid: string | null;
          start_time: any | null;
          end_time: any | null;
          unrefundedCents: number;
          unrefundedCredits: number | null;
          voucher_name: string | null;
          last4: string | null;
          stripe_charge_id: string | null;
          credit_transaction_uid: string | null;
          credit_account_type: string | null;
          coupon_code_uid: string | null;
          pricing_offer_uid: string | null;
          invoice_uid: string | null;
          currency_code: CurrencyCode | null;
          exchange_rate: number | null;
          funding_program_invoice_uid: string | null;
          service_fee_percentage: number | null;
          oneOnOneMeeting: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
          } | null;
          organization: {
            __typename?: "Organization";
            uid: string;
            name: string;
            state: string | null;
            logoUrl: string | null;
            urlName: string | null;
          } | null;
          refunds: Array<{
            __typename?: "EnrollmentPaymentRefund";
            uid: string;
            user_uid: string;
            amount_cents: number;
            amount_credits: number | null;
            refunded_at: any | null;
            reason: string | null;
            user_role: string | null;
            note: string | null;
            reduces_teacher_payout: boolean;
            amount_in_local_currency: number | null;
            currency_code: CurrencyCode | null;
            exchange_rate: number | null;
            addOutschoolCreditTransaction: {
              __typename?: "CreditTransaction";
              uid: string;
              amount_cents: number;
              expiresAt: any | null;
              unspentCents: number | null;
            } | null;
          }>;
          couponCode: {
            __typename?: "CouponCode";
            code: string;
            coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
          } | null;
        }>;
        lastSectionTransfer: {
          __typename?: "SectionTransfer";
          timestamp: any;
          fromSection: {
            __typename?: "Section";
            uid: string;
            start_time: any | null;
            end_time: any | null;
          } | null;
        } | null;
        ongoingSectionTransferredFrom: {
          __typename?: "SectionTransfer";
          timestamp: any;
          fromSection: {
            __typename?: "Section";
            uid: string;
            start_time: any | null;
            end_time: any | null;
          } | null;
        } | null;
        refundPolicy: { __typename?: "RefundPolicy"; name: string } | null;
        details: { __typename?: "EnrollmentDetails"; message: string | null };
        fundingProgramInvoice: {
          __typename?: "FundingProgramInvoice";
          uid: string;
          awardId: string;
        } | null;
      }>;
    };
    unclaimedGiftEnrollments: Array<{
      __typename?: "GiftEnrollment";
      uid: string;
      giftClaimedAt: any | null;
      createdAt: any;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      withdrawn_at: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      ongoing_stopped_at: any | null;
      currency_code: CurrencyCode;
      exchange_rate: number;
      amount_in_local_currency: number;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      hasPaymentSchedule: boolean;
      isEnded: boolean;
      isPaused: boolean;
      orderSlugId: string | null;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      priceCents: number | null;
      currentUserIsPurchaser: boolean;
      refundableAmountCents: number;
      payments: Array<{
        __typename?: "EnrollmentPayment";
        uid: string;
        amount_cents: number | null;
        amount_credits: number | null;
        order_slug_id: string | null;
        intercom_url: string | null;
        paid_at: any | null;
        disbursed_at: any | null;
        payout_uid: string | null;
        start_time: any | null;
        end_time: any | null;
        unrefundedCents: number;
        unrefundedCredits: number | null;
        voucher_name: string | null;
        last4: string | null;
        stripe_charge_id: string | null;
        purchase_order_id: string | null;
        credit_transaction_uid: string | null;
        credit_account_type: string | null;
        coupon_code_uid: string | null;
        pricing_offer_uid: string | null;
        esa_order_id: string | null;
        invoice_uid: string | null;
        currency_code: CurrencyCode | null;
        exchange_rate: number | null;
        funding_program_invoice_uid: string | null;
        service_fee_percentage: number | null;
        created_at: any | null;
        isOngoingRefundable: boolean;
        oneOnOneMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        } | null;
        organization: {
          __typename?: "Organization";
          uid: string;
          name: string;
          state: string | null;
          logoUrl: string | null;
          urlName: string | null;
        } | null;
        refunds: Array<{
          __typename?: "EnrollmentPaymentRefund";
          uid: string;
          user_uid: string;
          amount_cents: number;
          amount_credits: number | null;
          refunded_at: any | null;
          reason: string | null;
          user_role: string | null;
          note: string | null;
          reduces_teacher_payout: boolean;
          amount_in_local_currency: number | null;
          currency_code: CurrencyCode | null;
          exchange_rate: number | null;
          addOutschoolCreditTransaction: {
            __typename?: "CreditTransaction";
            uid: string;
            amount_cents: number;
            expiresAt: any | null;
            unspentCents: number | null;
          } | null;
        }>;
        couponCode: {
          __typename?: "CouponCode";
          code: string;
          coupon: { __typename?: "Coupon"; couponType: CouponType } | null;
        } | null;
        pricingOffer: {
          __typename?: "PricingOffer";
          name: string;
          buyerOrgName: string | null;
          isFinancialAid: boolean;
        } | null;
      }>;
      lastSectionTransfer: {
        __typename?: "SectionTransfer";
        timestamp: any;
        fromSection: {
          __typename?: "Section";
          uid: string;
          start_time: any | null;
          end_time: any | null;
        } | null;
      } | null;
      ongoingSectionTransferredFrom: {
        __typename?: "SectionTransfer";
        timestamp: any;
        fromSection: {
          __typename?: "Section";
          uid: string;
          start_time: any | null;
          end_time: any | null;
        } | null;
      } | null;
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      } | null;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      } | null;
      buyer: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
      pricingOffer: {
        __typename?: "PricingOffer";
        name: string;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
      } | null;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
      refundPolicy: {
        __typename?: "RefundPolicy";
        type: RefundPolicyType;
        name: string;
        description: string;
      } | null;
      paymentSchedule: {
        __typename?: "PaymentSchedule";
        uid: string;
        stoppedAt: any | null;
        endTime: any | null;
      } | null;
      pauseWindow: {
        __typename?: "EnrollmentPauseWindow";
        uid: string;
        startDate: any;
        endDate: any;
        canBeCanceled: boolean;
        pauseReason: string;
      } | null;
    }>;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
      teacherAttendance: {
        __typename?: "TeacherAttendance";
        uid: string;
        created_at: any;
      } | null;
      attendances: Array<{
        __typename?: "Attendance";
        uid: string;
        created_at: any;
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
          age: number | null;
          avatar: string | null;
          pronoun: string | null;
          intro: string | null;
          deleted_at: any | null;
          isVerified: boolean;
          isBlocked: boolean | null;
          blockedByUserUid: string | null;
          blockedAt: any | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            name: string | null;
            email: string | null;
            photo: string | null;
            timeZone: string | null;
          };
        };
      }>;
    }>;
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    details: {
      __typename?: "SectionDetails";
      autoScheduledDraft: boolean | null;
      onlineClassroom: {
        __typename?: "OnlineClassroom";
        type: string | null;
        url: string | null;
      } | null;
    };
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
    checklist: {
      __typename?: "SectionChecklist";
      autoCanceledAt: any | null;
      grandfatheredAt2017Rate: boolean | null;
    };
    zoomMeeting: { __typename?: "ZoomMeeting"; zoom_id: string | null } | null;
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
    }>;
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      published_at: string | null;
    } | null;
  } | null;
};

export type AttendanceQueryQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  meetingsLimit?: InputMaybe<Scalars["Int"]>;
  meetingsOffset?: InputMaybe<Scalars["Int"]>;
  meetingsSortOrder?: InputMaybe<SortOrder>;
  meetingsStartBefore?: InputMaybe<Scalars["DateTime"]>;
}>;

export type AttendanceQueryQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
      teacherAttendance: {
        __typename?: "TeacherAttendance";
        uid: string;
        created_at: any;
      } | null;
      attendances: Array<{
        __typename?: "Attendance";
        uid: string;
        created_at: any;
        learner: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
          age: number | null;
          avatar: string | null;
          pronoun: string | null;
          intro: string | null;
          deleted_at: any | null;
          isVerified: boolean;
          isBlocked: boolean | null;
          blockedByUserUid: string | null;
          blockedAt: any | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            name: string | null;
            email: string | null;
            photo: string | null;
            timeZone: string | null;
          };
        };
      }>;
    }>;
  } | null;
};

export type RecreateZoomMeetingMutationVariables = Exact<{
  sectionUid: Scalars["String"];
}>;

export type RecreateZoomMeetingMutation = {
  __typename?: "Mutation";
  recreateZoomMeeting: boolean;
};

export type Section_LessonPostFragmentFragment = {
  __typename?: "LessonPost";
  uid: string;
  title: string | null;
  lessonUid: string;
  orderInLesson: number;
  deleted_at: any | null;
  is_posted_for_enrollments: boolean;
  is_posted_for_subscriptions: boolean;
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type Section_AssignmentLessonPostFragmentFragment = {
  __typename?: "AssignmentLessonPost";
  uid: string;
  title: string | null;
  lessonUid: string;
  orderInLesson: number;
  deleted_at: any | null;
  submissionRequirement: SubmissionRequirementType;
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type Section_AssignmentClassPostFragment = {
  __typename?: "AssignmentClassPost";
  uid: string;
  title: string;
  created_at: any;
  deleted_at: any | null;
  publishAt: any;
  dueDate: any | null;
  submissionRequirement: SubmissionRequirementType;
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
  lesson: { __typename?: "Lesson"; uid: string; title: string | null } | null;
  lessonPost: {
    __typename?: "AssignmentLessonPost";
    uid: string;
    title: string | null;
    lessonUid: string;
    orderInLesson: number;
    deleted_at: any | null;
    submissionRequirement: SubmissionRequirementType;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  } | null;
};

export type ClassPost_LessonFragment = {
  __typename?: "Lesson";
  uid: string;
  title: string | null;
  description: string | null;
  week_number: number;
  order_within_week: number;
  lessonNumber: number | null;
  unit: {
    __typename?: "Unit";
    uid: string;
    title: string | null;
    unitNumber: number | null;
  } | null;
};

export type SectionPosts_LessonFragment = {
  __typename?: "Lesson";
  uid: string;
  title: string | null;
  description: string | null;
  week_number: number;
  order_within_week: number;
  lessonNumber: number | null;
  unlocksAt: any | null;
  meetings: Array<{
    __typename?: "Meeting";
    uid: string;
    start_time: any;
  }> | null;
  unit: {
    __typename?: "Unit";
    uid: string;
    title: string | null;
    unitNumber: number | null;
  } | null;
  lessonPosts: Array<{
    __typename?: "LessonPost";
    uid: string;
    title: string | null;
    lessonUid: string;
    orderInLesson: number;
    deleted_at: any | null;
    is_posted_for_enrollments: boolean;
    is_posted_for_subscriptions: boolean;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  }>;
  classPosts: Array<{
    __typename?: "ClassPost";
    publishAt: any;
    uid: string;
    isWelcomePost: boolean;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    lessonPost: {
      __typename?: "LessonPost";
      uid: string;
      title: string | null;
      lessonUid: string;
      orderInLesson: number;
      deleted_at: any | null;
      is_posted_for_enrollments: boolean;
      is_posted_for_subscriptions: boolean;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    } | null;
    lesson: {
      __typename?: "Lesson";
      uid: string;
      title: string | null;
      description: string | null;
      week_number: number;
      order_within_week: number;
      lessonNumber: number | null;
      unit: {
        __typename?: "Unit";
        uid: string;
        title: string | null;
        unitNumber: number | null;
      } | null;
    } | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  }> | null;
  assignmentClassPosts: Array<{
    __typename?: "AssignmentClassPost";
    uid: string;
    title: string;
    created_at: any;
    deleted_at: any | null;
    publishAt: any;
    dueDate: any | null;
    submissionRequirement: SubmissionRequirementType;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    lesson: { __typename?: "Lesson"; uid: string; title: string | null } | null;
    lessonPost: {
      __typename?: "AssignmentLessonPost";
      uid: string;
      title: string | null;
      lessonUid: string;
      orderInLesson: number;
      deleted_at: any | null;
      submissionRequirement: SubmissionRequirementType;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    } | null;
  }>;
  assignmentLessonPosts: Array<{
    __typename?: "AssignmentLessonPost";
    uid: string;
    title: string | null;
    lessonUid: string;
    orderInLesson: number;
    deleted_at: any | null;
    submissionRequirement: SubmissionRequirementType;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  }>;
};

export type SectionPostManagementQueryQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SectionPostManagementQueryQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    hasFrozenSyllabus: boolean;
    uid: string;
    published_at: any | null;
    deleted_at: any | null;
    canceled_at: any | null;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_self_paced: boolean;
    currentUserCanManage: boolean;
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      published_at: string | null;
      currentLesson: {
        __typename?: "Lesson";
        uid: string;
        title: string | null;
        description: string | null;
        week_number: number;
        order_within_week: number;
        lessonNumber: number | null;
        unlocksAt: any | null;
        meetings: Array<{
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        }> | null;
        unit: {
          __typename?: "Unit";
          uid: string;
          title: string | null;
          unitNumber: number | null;
        } | null;
        lessonPosts: Array<{
          __typename?: "LessonPost";
          uid: string;
          title: string | null;
          lessonUid: string;
          orderInLesson: number;
          deleted_at: any | null;
          is_posted_for_enrollments: boolean;
          is_posted_for_subscriptions: boolean;
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
        classPosts: Array<{
          __typename?: "ClassPost";
          publishAt: any;
          uid: string;
          isWelcomePost: boolean;
          title: string | null;
          promotedAt: any | null;
          pinnedAt: any | null;
          mentionedEnrollmentUids: Array<string>;
          backgroundColor: string | null;
          score: number | null;
          lessonPost: {
            __typename?: "LessonPost";
            uid: string;
            title: string | null;
            lessonUid: string;
            orderInLesson: number;
            deleted_at: any | null;
            is_posted_for_enrollments: boolean;
            is_posted_for_subscriptions: boolean;
            messageContent: {
              __typename?: "MessageContent";
              uid: string;
              text: string | null;
              originalMimeType: TextMimeType;
              editedAt: any | null;
              htmlText: string | null;
              attachments: Array<{
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              }>;
              video: {
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              } | null;
            };
          } | null;
          lesson: {
            __typename?: "Lesson";
            uid: string;
            title: string | null;
            description: string | null;
            week_number: number;
            order_within_week: number;
            lessonNumber: number | null;
            unit: {
              __typename?: "Unit";
              uid: string;
              title: string | null;
              unitNumber: number | null;
            } | null;
          } | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
          commentsPreview: {
            __typename?: "CommentsPreview";
            remainingCommentsCount: number;
            comments: Array<{
              __typename?: "ClassPostComment";
              uid: string;
              sendAt: any;
              mentionedEnrollmentUids: Array<string>;
              mentionedTeacherUid: string | null;
              backgroundColor: string | null;
              sender: {
                __typename?: "MessageSender";
                uid: string;
                name: string | null;
                photo: string | null;
                avatar: string | null;
                isLearner: boolean;
              };
              messageContent: {
                __typename?: "MessageContent";
                uid: string;
                text: string | null;
                originalMimeType: TextMimeType;
                editedAt: any | null;
                htmlText: string | null;
                attachments: Array<{
                  __typename?: "Attachment";
                  uid: string;
                  conversionStatus: string | null;
                  created_at: any;
                  file: {
                    __typename?: "File";
                    filename: string;
                    mimetype: string | null;
                    size: number;
                    url: string;
                    thumbUrl: string | null;
                  };
                }>;
                video: {
                  __typename?: "Attachment";
                  uid: string;
                  conversionStatus: string | null;
                  created_at: any;
                  file: {
                    __typename?: "File";
                    filename: string;
                    mimetype: string | null;
                    size: number;
                    url: string;
                    thumbUrl: string | null;
                  };
                } | null;
              };
            }>;
          };
        }> | null;
        assignmentClassPosts: Array<{
          __typename?: "AssignmentClassPost";
          uid: string;
          title: string;
          created_at: any;
          deleted_at: any | null;
          publishAt: any;
          dueDate: any | null;
          submissionRequirement: SubmissionRequirementType;
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
          lesson: {
            __typename?: "Lesson";
            uid: string;
            title: string | null;
          } | null;
          lessonPost: {
            __typename?: "AssignmentLessonPost";
            uid: string;
            title: string | null;
            lessonUid: string;
            orderInLesson: number;
            deleted_at: any | null;
            submissionRequirement: SubmissionRequirementType;
            messageContent: {
              __typename?: "MessageContent";
              uid: string;
              text: string | null;
              originalMimeType: TextMimeType;
              editedAt: any | null;
              htmlText: string | null;
              attachments: Array<{
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              }>;
              video: {
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              } | null;
            };
          } | null;
        }>;
        assignmentLessonPosts: Array<{
          __typename?: "AssignmentLessonPost";
          uid: string;
          title: string | null;
          lessonUid: string;
          orderInLesson: number;
          deleted_at: any | null;
          submissionRequirement: SubmissionRequirementType;
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      } | null;
      units: Array<{
        __typename?: "Unit";
        uid: string;
        title: string | null;
        unitNumber: number | null;
      }>;
      lessons: Array<{
        __typename?: "Lesson";
        uid: string;
        title: string | null;
        description: string | null;
        week_number: number;
        order_within_week: number;
        lessonNumber: number | null;
        unlocksAt: any | null;
        meetings: Array<{
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        }> | null;
        unit: {
          __typename?: "Unit";
          uid: string;
          title: string | null;
          unitNumber: number | null;
        } | null;
        lessonPosts: Array<{
          __typename?: "LessonPost";
          uid: string;
          title: string | null;
          lessonUid: string;
          orderInLesson: number;
          deleted_at: any | null;
          is_posted_for_enrollments: boolean;
          is_posted_for_subscriptions: boolean;
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
        classPosts: Array<{
          __typename?: "ClassPost";
          publishAt: any;
          uid: string;
          isWelcomePost: boolean;
          title: string | null;
          promotedAt: any | null;
          pinnedAt: any | null;
          mentionedEnrollmentUids: Array<string>;
          backgroundColor: string | null;
          score: number | null;
          lessonPost: {
            __typename?: "LessonPost";
            uid: string;
            title: string | null;
            lessonUid: string;
            orderInLesson: number;
            deleted_at: any | null;
            is_posted_for_enrollments: boolean;
            is_posted_for_subscriptions: boolean;
            messageContent: {
              __typename?: "MessageContent";
              uid: string;
              text: string | null;
              originalMimeType: TextMimeType;
              editedAt: any | null;
              htmlText: string | null;
              attachments: Array<{
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              }>;
              video: {
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              } | null;
            };
          } | null;
          lesson: {
            __typename?: "Lesson";
            uid: string;
            title: string | null;
            description: string | null;
            week_number: number;
            order_within_week: number;
            lessonNumber: number | null;
            unit: {
              __typename?: "Unit";
              uid: string;
              title: string | null;
              unitNumber: number | null;
            } | null;
          } | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
          commentsPreview: {
            __typename?: "CommentsPreview";
            remainingCommentsCount: number;
            comments: Array<{
              __typename?: "ClassPostComment";
              uid: string;
              sendAt: any;
              mentionedEnrollmentUids: Array<string>;
              mentionedTeacherUid: string | null;
              backgroundColor: string | null;
              sender: {
                __typename?: "MessageSender";
                uid: string;
                name: string | null;
                photo: string | null;
                avatar: string | null;
                isLearner: boolean;
              };
              messageContent: {
                __typename?: "MessageContent";
                uid: string;
                text: string | null;
                originalMimeType: TextMimeType;
                editedAt: any | null;
                htmlText: string | null;
                attachments: Array<{
                  __typename?: "Attachment";
                  uid: string;
                  conversionStatus: string | null;
                  created_at: any;
                  file: {
                    __typename?: "File";
                    filename: string;
                    mimetype: string | null;
                    size: number;
                    url: string;
                    thumbUrl: string | null;
                  };
                }>;
                video: {
                  __typename?: "Attachment";
                  uid: string;
                  conversionStatus: string | null;
                  created_at: any;
                  file: {
                    __typename?: "File";
                    filename: string;
                    mimetype: string | null;
                    size: number;
                    url: string;
                    thumbUrl: string | null;
                  };
                } | null;
              };
            }>;
          };
        }> | null;
        assignmentClassPosts: Array<{
          __typename?: "AssignmentClassPost";
          uid: string;
          title: string;
          created_at: any;
          deleted_at: any | null;
          publishAt: any;
          dueDate: any | null;
          submissionRequirement: SubmissionRequirementType;
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
          lesson: {
            __typename?: "Lesson";
            uid: string;
            title: string | null;
          } | null;
          lessonPost: {
            __typename?: "AssignmentLessonPost";
            uid: string;
            title: string | null;
            lessonUid: string;
            orderInLesson: number;
            deleted_at: any | null;
            submissionRequirement: SubmissionRequirementType;
            messageContent: {
              __typename?: "MessageContent";
              uid: string;
              text: string | null;
              originalMimeType: TextMimeType;
              editedAt: any | null;
              htmlText: string | null;
              attachments: Array<{
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              }>;
              video: {
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              } | null;
            };
          } | null;
        }>;
        assignmentLessonPosts: Array<{
          __typename?: "AssignmentLessonPost";
          uid: string;
          title: string | null;
          lessonUid: string;
          orderInLesson: number;
          deleted_at: any | null;
          submissionRequirement: SubmissionRequirementType;
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      }>;
    } | null;
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      duration_weeks: number | null;
      isSelfPaced: boolean;
      isFlexSchedule: boolean;
      archived_at: any | null;
      published_at: any | null;
      requested_listing_at: any | null;
      user_uid: string;
      titleTranslated: string | null;
      is_ongoing_weekly: boolean;
      weekly_meetings: number | null;
      isClub: boolean;
      hasTeacherSchedule: boolean;
      approvalStatus: {
        __typename?: "ActivityApprovalStatus";
        action: string;
        message_to_teacher: string | null;
      } | null;
    };
    sectionLessons: Array<{
      __typename?: "SectionLesson";
      uid: string;
      type: SectionLessonType;
      title: string;
      start_time: any | null;
      lesson: {
        __typename?: "Lesson";
        uid: string;
        title: string | null;
        description: string | null;
        week_number: number;
        order_within_week: number;
        lessonNumber: number | null;
        unlocksAt: any | null;
        meetings: Array<{
          __typename?: "Meeting";
          uid: string;
          start_time: any;
        }> | null;
        unit: {
          __typename?: "Unit";
          uid: string;
          title: string | null;
          unitNumber: number | null;
        } | null;
        lessonPosts: Array<{
          __typename?: "LessonPost";
          uid: string;
          title: string | null;
          lessonUid: string;
          orderInLesson: number;
          deleted_at: any | null;
          is_posted_for_enrollments: boolean;
          is_posted_for_subscriptions: boolean;
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
        classPosts: Array<{
          __typename?: "ClassPost";
          publishAt: any;
          uid: string;
          isWelcomePost: boolean;
          title: string | null;
          promotedAt: any | null;
          pinnedAt: any | null;
          mentionedEnrollmentUids: Array<string>;
          backgroundColor: string | null;
          score: number | null;
          lessonPost: {
            __typename?: "LessonPost";
            uid: string;
            title: string | null;
            lessonUid: string;
            orderInLesson: number;
            deleted_at: any | null;
            is_posted_for_enrollments: boolean;
            is_posted_for_subscriptions: boolean;
            messageContent: {
              __typename?: "MessageContent";
              uid: string;
              text: string | null;
              originalMimeType: TextMimeType;
              editedAt: any | null;
              htmlText: string | null;
              attachments: Array<{
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              }>;
              video: {
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              } | null;
            };
          } | null;
          lesson: {
            __typename?: "Lesson";
            uid: string;
            title: string | null;
            description: string | null;
            week_number: number;
            order_within_week: number;
            lessonNumber: number | null;
            unit: {
              __typename?: "Unit";
              uid: string;
              title: string | null;
              unitNumber: number | null;
            } | null;
          } | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
          commentsPreview: {
            __typename?: "CommentsPreview";
            remainingCommentsCount: number;
            comments: Array<{
              __typename?: "ClassPostComment";
              uid: string;
              sendAt: any;
              mentionedEnrollmentUids: Array<string>;
              mentionedTeacherUid: string | null;
              backgroundColor: string | null;
              sender: {
                __typename?: "MessageSender";
                uid: string;
                name: string | null;
                photo: string | null;
                avatar: string | null;
                isLearner: boolean;
              };
              messageContent: {
                __typename?: "MessageContent";
                uid: string;
                text: string | null;
                originalMimeType: TextMimeType;
                editedAt: any | null;
                htmlText: string | null;
                attachments: Array<{
                  __typename?: "Attachment";
                  uid: string;
                  conversionStatus: string | null;
                  created_at: any;
                  file: {
                    __typename?: "File";
                    filename: string;
                    mimetype: string | null;
                    size: number;
                    url: string;
                    thumbUrl: string | null;
                  };
                }>;
                video: {
                  __typename?: "Attachment";
                  uid: string;
                  conversionStatus: string | null;
                  created_at: any;
                  file: {
                    __typename?: "File";
                    filename: string;
                    mimetype: string | null;
                    size: number;
                    url: string;
                    thumbUrl: string | null;
                  };
                } | null;
              };
            }>;
          };
        }> | null;
        assignmentClassPosts: Array<{
          __typename?: "AssignmentClassPost";
          uid: string;
          title: string;
          created_at: any;
          deleted_at: any | null;
          publishAt: any;
          dueDate: any | null;
          submissionRequirement: SubmissionRequirementType;
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
          lesson: {
            __typename?: "Lesson";
            uid: string;
            title: string | null;
          } | null;
          lessonPost: {
            __typename?: "AssignmentLessonPost";
            uid: string;
            title: string | null;
            lessonUid: string;
            orderInLesson: number;
            deleted_at: any | null;
            submissionRequirement: SubmissionRequirementType;
            messageContent: {
              __typename?: "MessageContent";
              uid: string;
              text: string | null;
              originalMimeType: TextMimeType;
              editedAt: any | null;
              htmlText: string | null;
              attachments: Array<{
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              }>;
              video: {
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              } | null;
            };
          } | null;
        }>;
        assignmentLessonPosts: Array<{
          __typename?: "AssignmentLessonPost";
          uid: string;
          title: string | null;
          lessonUid: string;
          orderInLesson: number;
          deleted_at: any | null;
          submissionRequirement: SubmissionRequirementType;
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      } | null;
    }> | null;
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
    } | null;
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
    }>;
  } | null;
};

export type ActivityFromSectionQueryVariables = Exact<{
  sectionUid?: InputMaybe<Scalars["ID"]>;
}>;

export type ActivityFromSectionQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    title: string;
    isClub: boolean;
  };
};

export type SellerOrgDashboardQueryQueryVariables = Exact<{
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
}>;

export type SellerOrgDashboardQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    followerCount: number;
    name: string | null;
    introduction: string | null;
    created_at: any;
    photo: string | null;
    leader_link: string | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
    } | null;
    conversations: Array<
      | {
          __typename?: "MessageThread";
          uid: string;
          messages: Array<{
            __typename?: "Message";
            uid: string;
            created_at: any;
            subject: string | null;
            content: string | null;
            sender: {
              __typename?: "Sender";
              uid: string;
              name: string | null;
              photo: string | null;
              headline: string | null;
              leader_link: string | null;
              timeZone: string | null;
            };
          }>;
        }
      | { __typename?: "PrivateClassThread" }
    >;
    leader: {
      __typename?: "Leader";
      publishedClassCount: number;
      uid: string;
      metrics: {
        __typename?: "LeaderMetrics";
        activeSections: number;
        repurchaseRate: number | null;
        averageActivityStarRating: number | null;
        learnersTaught: number;
      };
      listings: Array<{
        __typename?: "Listing";
        activity: { __typename?: "Activity"; uid: string; canEnroll: boolean };
      }>;
    } | null;
    teacherMeetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      sectionSequenceNumber: number;
      section: {
        __typename?: "Section";
        uid: string;
        filledSpaceCount: number;
        meetingCount: number;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
          details: { __typename?: "ActivityDetails"; photo: string | null };
        };
      };
    }>;
  } | null;
};

export type SellerOrgTeacherDetailsQueryVariables = Exact<{
  startAfter: Scalars["DateTime"];
}>;

export type SellerOrgTeacherDetailsQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      teachers: Array<{
        __typename?: "User";
        uid: string;
        name: string | null;
        email: string | null;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          orgTeacherMetrics: {
            __typename?: "OrgTeacherMetrics";
            averageRating: number | null;
            totalSections: number;
            pastSections: number;
            futureSections: number;
            totalMeetings: number;
            attendedMeetings: number;
            lateMeetings: number;
            unattendedMeetings: number;
            totalEnrollments: number;
            totalReviews: number;
            firstSectionStart: any | null;
            latestSectionStart: any | null;
          };
        } | null;
      }>;
    } | null;
  } | null;
};

export type SellerOrgClassStartTimesQueryVariables = Exact<{
  startAfter: Scalars["DateTime"];
  startBefore: Scalars["DateTime"];
  endAfter?: InputMaybe<Scalars["DateTime"]>;
  endBefore?: InputMaybe<Scalars["DateTime"]>;
}>;

export type SellerOrgClassStartTimesQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      meetings: {
        __typename?: "MeetingConnection";
        edges: Array<{
          __typename?: "MeetingEdge";
          node: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
            startStatus: MeetingStartStatus;
            section: {
              __typename?: "Section";
              uid: string;
              activity: {
                __typename?: "Activity";
                uid: string;
                title: string;
                titleTranslated: string | null;
              };
              leader: {
                __typename?: "Leader";
                uid: string;
                name: string | null;
              };
            };
            teacherAttendance: {
              __typename?: "TeacherAttendance";
              uid: string;
              attendedAt: any;
            } | null;
          };
        }>;
      };
    } | null;
  } | null;
};

export type SellerOrgTeacherClubsQueryVariables = Exact<{
  filter?: InputMaybe<Scalars["String"]>;
  activityType?: InputMaybe<CreateActivityType>;
}>;

export type SellerOrgTeacherClubsQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    sections: Array<{
      __typename?: "Section";
      uid: string;
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        titleTranslated: string | null;
        saveCount: number | null;
        archived_at: any | null;
        published_at: any | null;
        requested_listing_at: any | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        approvalStatus: {
          __typename?: "ActivityApprovalStatus";
          action: string;
          message_to_teacher: string | null;
        } | null;
      };
    }>;
  } | null;
};

export type LearnerPlanActivitiesExistQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LearnerPlanActivitiesExistQuery = {
  __typename?: "Query";
  learnerPlanActivitiesExist: boolean;
};

export type CheckoutSectionFragmentFragment = {
  __typename?: "Section";
  uid: string;
  start_time: any | null;
  end_time: any | null;
  price_cents: number | null;
  isPublished: boolean | null;
  allowsLateEnrollment: boolean;
  meetingCount: number;
  isBuyable: boolean;
  validEnrollmentCount: number;
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    photo: string | null;
    leader_link: string | null;
    averageActivityStarRating: number | null;
    reviewCount: number;
  };
  meetingTimes: Array<{
    __typename?: "MeetingTime";
    dayOfWeek: number;
    hour: number;
    minute: number;
  }>;
  meetings: Array<{
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
  }>;
  progress: {
    __typename?: "CourseProgress";
    pastMeetingCount: number;
    remainingMeetingCount: number;
    totalMeetingCount: number;
    nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
  } | null;
  prepaidOngoingPeriodOptions: Array<{
    __typename?: "Period";
    startOfIsoWeek: any;
    endOfIsoWeek: any;
  }> | null;
};

export type CheckoutActivityFragmentFragment = {
  __typename?: "Activity";
  uid: string;
  slug_id: string;
  subject: string | null;
  homework: string | null;
  duration_weeks: number | null;
  duration_minutes: number | null;
  weekly_meetings: number | null;
  size_max: number | null;
  hasTeacherSchedule: boolean;
  isClub: boolean;
  isSelfPaced: boolean;
  isFlexSchedule: boolean;
  is_ongoing_weekly: boolean;
  path: string;
  price_cents: number | null;
  published_at: any | null;
  title: string;
  titleTranslated: string | null;
  allows_recurring_payment: boolean | null;
  canScheduleContinuously: boolean;
  esaStatus: EsaStatus;
  futureEnrollmentMeetingAvailability: Array<{
    __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
    startTime: any;
    endTime: any;
    isAvailable: boolean;
  }>;
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    photo: string | null;
    leader_link: string | null;
    averageActivityStarRating: number | null;
    reviewCount: number;
  };
  details: { __typename?: "ActivityDetails"; photo: string | null };
  pricingInfo: {
    __typename?: "PricingInfo";
    totalPriceCentsForUpfrontPayment: number;
    totalPriceCentsForWeeklyPayments: number;
    weeklyPriceCentsForUpfrontPayment: number;
    weeklyPriceCentsForWeeklyPayments: number;
    upfrontDiscountPercentage: number;
    totalPriceCreditsForUpfrontPayment: number;
    totalPriceCreditsForWeeklyPayments: number;
    weeklyPriceCreditsForUpfrontPayment: number;
    weeklyPriceCreditsForWeeklyPayments: number;
  } | null;
};

export type CheckoutActivityQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  meetingStartTime?: InputMaybe<Scalars["DateTime"]>;
  meetingEndTime?: InputMaybe<Scalars["DateTime"]>;
}>;

export type CheckoutActivityQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    subject: string | null;
    homework: string | null;
    duration_weeks: number | null;
    duration_minutes: number | null;
    weekly_meetings: number | null;
    size_max: number | null;
    hasTeacherSchedule: boolean;
    isClub: boolean;
    isSelfPaced: boolean;
    isFlexSchedule: boolean;
    is_ongoing_weekly: boolean;
    path: string;
    price_cents: number | null;
    published_at: any | null;
    title: string;
    titleTranslated: string | null;
    allows_recurring_payment: boolean | null;
    canScheduleContinuously: boolean;
    esaStatus: EsaStatus;
    futureEnrollmentMeetingAvailability: Array<{
      __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
      startTime: any;
      endTime: any;
      isAvailable: boolean;
    }>;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      averageActivityStarRating: number | null;
      reviewCount: number;
    };
    details: { __typename?: "ActivityDetails"; photo: string | null };
    pricingInfo: {
      __typename?: "PricingInfo";
      totalPriceCentsForUpfrontPayment: number;
      totalPriceCentsForWeeklyPayments: number;
      weeklyPriceCentsForUpfrontPayment: number;
      weeklyPriceCentsForWeeklyPayments: number;
      upfrontDiscountPercentage: number;
      totalPriceCreditsForUpfrontPayment: number;
      totalPriceCreditsForWeeklyPayments: number;
      weeklyPriceCreditsForUpfrontPayment: number;
      weeklyPriceCreditsForWeeklyPayments: number;
    } | null;
  };
};

export type CheckoutSectionQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  meetingStartTime?: InputMaybe<Scalars["DateTime"]>;
  meetingEndTime?: InputMaybe<Scalars["DateTime"]>;
}>;

export type CheckoutSectionQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    start_time: any | null;
    end_time: any | null;
    price_cents: number | null;
    isPublished: boolean | null;
    allowsLateEnrollment: boolean;
    meetingCount: number;
    isBuyable: boolean;
    validEnrollmentCount: number;
    activity: {
      __typename?: "Activity";
      uid: string;
      slug_id: string;
      subject: string | null;
      homework: string | null;
      duration_weeks: number | null;
      duration_minutes: number | null;
      weekly_meetings: number | null;
      size_max: number | null;
      hasTeacherSchedule: boolean;
      isClub: boolean;
      isSelfPaced: boolean;
      isFlexSchedule: boolean;
      is_ongoing_weekly: boolean;
      path: string;
      price_cents: number | null;
      published_at: any | null;
      title: string;
      titleTranslated: string | null;
      allows_recurring_payment: boolean | null;
      canScheduleContinuously: boolean;
      esaStatus: EsaStatus;
      futureEnrollmentMeetingAvailability: Array<{
        __typename?: "EnrollmentMeetingPurchaseMeetingAvailability";
        startTime: any;
        endTime: any;
        isAvailable: boolean;
      }>;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
      };
      details: { __typename?: "ActivityDetails"; photo: string | null };
      pricingInfo: {
        __typename?: "PricingInfo";
        totalPriceCentsForUpfrontPayment: number;
        totalPriceCentsForWeeklyPayments: number;
        weeklyPriceCentsForUpfrontPayment: number;
        weeklyPriceCentsForWeeklyPayments: number;
        upfrontDiscountPercentage: number;
        totalPriceCreditsForUpfrontPayment: number;
        totalPriceCreditsForWeeklyPayments: number;
        weeklyPriceCreditsForUpfrontPayment: number;
        weeklyPriceCreditsForWeeklyPayments: number;
      } | null;
    };
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      averageActivityStarRating: number | null;
      reviewCount: number;
    };
    meetingTimes: Array<{
      __typename?: "MeetingTime";
      dayOfWeek: number;
      hour: number;
      minute: number;
    }>;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
    }>;
    progress: {
      __typename?: "CourseProgress";
      pastMeetingCount: number;
      remainingMeetingCount: number;
      totalMeetingCount: number;
      nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
    } | null;
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
  } | null;
};

export type ShoppingCartItemQueryQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  skipSection: Scalars["Boolean"];
  sectionUid: Scalars["ID"];
}>;

export type ShoppingCartItemQueryQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    subject: string | null;
    esaStatus: EsaStatus;
    isClub: boolean;
    isSelfPaced: boolean;
    is_ongoing_weekly: boolean;
    price_cents: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    hasTeacherSchedule: boolean;
    size_max: number | null;
    details: { __typename?: "ActivityDetails"; photo: string | null };
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      averageActivityStarRating: number | null;
      reviewCount: number;
    };
  };
  section?: {
    __typename?: "Section";
    uid: string;
    start_time: any | null;
    end_time: any | null;
    isBuyable: boolean;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      averageActivityStarRating: number | null;
      reviewCount: number;
    };
    meetingTimes: Array<{
      __typename?: "MeetingTime";
      dayOfWeek: number;
      hour: number;
      minute: number;
    }>;
  } | null;
};

export type SinglePostQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  classPostUid: Scalars["ID"];
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SinglePostQuery = {
  __typename?: "Query";
  classroom: {
    __typename?: "Classroom";
    uid: string;
    usersCanPost: boolean;
    usersCanComment: boolean;
    currentUserEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      usersCanCommunicate: boolean;
      learner: { __typename?: "Learner"; uid: string } | null;
    }>;
    section: {
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
      currentUserCanManage: boolean;
      filledSpaceCount: number;
      usesOutschoolVideoChat: boolean;
      published_at: any | null;
      deleted_at: any | null;
      canceled_at: any | null;
      startsAtNight: boolean | null;
      is_self_paced: boolean;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
      } | null;
      assignmentClassPosts: Array<{
        __typename?: "AssignmentClassPost";
        uid: string;
      }>;
      syllabus: {
        __typename?: "Syllabus";
        uid: string;
        published_at: string | null;
      } | null;
    };
    postByUid: {
      __typename?: "ClassPost";
      uid: string;
      publishAt: any;
      isWelcomePost: boolean;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    } | null;
    activity: {
      __typename?: "Activity";
      archived_at: any | null;
      published_at: any | null;
      requested_listing_at: any | null;
      uid: string;
      user_uid: string;
      title: string;
      titleTranslated: string | null;
      is_ongoing_weekly: boolean;
      weekly_meetings: number | null;
      duration_weeks: number | null;
      isClub: boolean;
      hasTeacherSchedule: boolean;
      leader: {
        __typename?: "Leader";
        uid: string;
        leader_link: string | null;
      };
      approvalStatus: {
        __typename?: "ActivityApprovalStatus";
        action: string;
        message_to_teacher: string | null;
      } | null;
    };
    mentionNames: Array<{
      __typename?: "MentionName";
      id: string;
      display: string;
      avatar: string | null;
    }>;
  };
};

export type UpdateLessonMutationVariables = Exact<{
  lessonUid: Scalars["ID"];
  updates: LessonInput;
}>;

export type UpdateLessonMutation = {
  __typename?: "Mutation";
  updateLesson: {
    __typename?: "Syllabus";
    uid: string;
    lessons: Array<{
      __typename?: "Lesson";
      uid: string;
      title: string | null;
      description: string | null;
      unit: { __typename?: "Unit"; uid: string; title: string | null } | null;
    }>;
  };
};

export type AddLessonMutationVariables = Exact<{
  syllabusUid: Scalars["ID"];
  previousLessonUid: Scalars["ID"];
}>;

export type AddLessonMutation = {
  __typename?: "Mutation";
  addLesson: {
    __typename?: "Syllabus";
    uid: string;
    lessons: Array<{
      __typename?: "Lesson";
      uid: string;
      week_number: number;
      order_within_week: number;
      unit: { __typename?: "Unit"; uid: string } | null;
    }>;
  };
};

export type DeleteLessonMutationVariables = Exact<{
  lessonUid: Scalars["ID"];
}>;

export type DeleteLessonMutation = {
  __typename?: "Mutation";
  deleteLesson: {
    __typename?: "Syllabus";
    uid: string;
    lessons: Array<{ __typename?: "Lesson"; order_within_week: number }>;
  };
};

export type UpdateUnitMutationVariables = Exact<{
  unitUid: Scalars["ID"];
  updates: UnitInput;
}>;

export type UpdateUnitMutation = {
  __typename?: "Mutation";
  updateUnit: {
    __typename?: "Syllabus";
    uid: string;
    units: Array<{ __typename?: "Unit"; title: string | null }>;
  };
};

export type DeleteUnitMutationVariables = Exact<{
  syllabusUid: Scalars["ID"];
  unitUid: Scalars["ID"];
}>;

export type DeleteUnitMutation = {
  __typename?: "Mutation";
  deleteUnit: {
    __typename?: "Syllabus";
    uid: string;
    units: Array<{ __typename?: "Unit"; uid: string }>;
  };
};

export type RemoveUnitsMutationVariables = Exact<{
  syllabusUid: Scalars["ID"];
}>;

export type RemoveUnitsMutation = {
  __typename?: "Mutation";
  removeUnits: {
    __typename?: "Syllabus";
    uid: string;
    lessons: Array<{
      __typename?: "Lesson";
      unit: { __typename?: "Unit"; uid: string } | null;
    }>;
    units: Array<{ __typename?: "Unit"; uid: string }>;
  };
};

export type PublishSyllabusMutationVariables = Exact<{
  syllabusUid: Scalars["ID"];
}>;

export type PublishSyllabusMutation = {
  __typename?: "Mutation";
  publishSyllabus: { __typename?: "Syllabus"; uid: string };
};

export type IntroduceUnitsMutationVariables = Exact<{
  syllabusUid: Scalars["ID"];
}>;

export type IntroduceUnitsMutation = {
  __typename?: "Mutation";
  introduceUnits: {
    __typename?: "Syllabus";
    uid: string;
    lessons: Array<{
      __typename?: "Lesson";
      uid: string;
      unit: { __typename?: "Unit"; uid: string } | null;
    }>;
    units: Array<{ __typename?: "Unit"; uid: string }>;
  };
};

export type AddUnitMutationVariables = Exact<{
  syllabusUid: Scalars["ID"];
}>;

export type AddUnitMutation = {
  __typename?: "Mutation";
  addUnit: {
    __typename?: "Syllabus";
    uid: string;
    units: Array<{
      __typename?: "Unit";
      uid: string;
      learning_syllabus_uid: string;
    }>;
  };
};

export type CreateNewVersionMutationVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type CreateNewVersionMutation = {
  __typename?: "Mutation";
  createSyllabus: { __typename?: "Syllabus"; uid: string };
};

export type DeleteVersionOfSyllabusMutationVariables = Exact<{
  activityUid: Scalars["ID"];
  syllabusVersionUid?: InputMaybe<Scalars["ID"]>;
}>;

export type DeleteVersionOfSyllabusMutation = {
  __typename?: "Mutation";
  deleteSyllabus: { __typename?: "Syllabus"; uid: string };
};

export type SyllabusEditPageQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type SyllabusEditPageQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    user_uid: string;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    duration_minutes: number | null;
    title: string;
    isClub: boolean;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isPublished: boolean;
    wasPublishedInThePast: boolean;
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      published_at: string | null;
      isFrozen: boolean;
      numVersions: number;
      lessons: Array<{
        __typename?: "Lesson";
        uid: string;
        order_within_week: number;
        week_number: number;
        title: string | null;
        description: string | null;
        lessonNumber: number | null;
        unit: { __typename?: "Unit"; uid: string; title: string | null } | null;
      }>;
      units: Array<{
        __typename?: "Unit";
        uid: string;
        title: string | null;
        unitNumber: number | null;
        learning_syllabus_uid: string;
      }>;
    } | null;
    selfPacedMigration: {
      __typename?: "SelfPacedMigrationStatus";
      hasConsented: boolean;
      consentedAt: any | null;
    } | null;
    leader: { __typename?: "Leader"; uid: string; publishedClassCount: number };
  };
};

export type AcceptInviteToSellerOrgMutationVariables = Exact<{
  userUid: Scalars["ID"];
  token: Scalars["String"];
  password: Scalars["String"];
}>;

export type AcceptInviteToSellerOrgMutation = {
  __typename?: "Mutation";
  acceptInviteToSellerOrg: {
    __typename?: "AuthenticationV2";
    sessionToken: string;
    refreshToken: string;
  };
};

export type TeacherAvailabilityQueryVariables = Exact<{
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
}>;

export type TeacherAvailabilityQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    teacherAvailability: {
      __typename?: "Availability";
      updated_at: any | null;
      hourlyTimetable: {
        __typename?: "HourlyTimetable";
        mon: Array<number>;
        tue: Array<number>;
        wed: Array<number>;
        thu: Array<number>;
        fri: Array<number>;
        sat: Array<number>;
        sun: Array<number>;
      } | null;
    } | null;
    teacherUnavailableBlocks: Array<{
      __typename?: "UnavailableBlock";
      uid: string;
      startTime: any;
      endTime: any;
    }>;
    leader: {
      __typename?: "Leader";
      uid: string;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingBufferMinutes: number | null;
    } | null;
  } | null;
};

export type AddTeacherUnavailableBlockMutationMutationVariables = Exact<{
  unavailableBlock: UnavailableBlockInput;
}>;

export type AddTeacherUnavailableBlockMutationMutation = {
  __typename?: "Mutation";
  addTeacherUnavailableBlock: {
    __typename?: "UnavailableBlock";
    uid: string;
    startTime: any;
    endTime: any;
  };
};

export type DeleteTeacherUnavailableBlockMutationMutationVariables = Exact<{
  unavailableBlockUid: Scalars["ID"];
}>;

export type DeleteTeacherUnavailableBlockMutationMutation = {
  __typename?: "Mutation";
  deleteTeacherUnavailableBlock: boolean;
};

export type AutoScheduleSettingsClassesListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AutoScheduleSettingsClassesListQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    activities: Array<{
      __typename?: "Activity";
      uid: string;
      title: string;
      age_max: number | null;
      age_min: number | null;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      weekly_meetings: number | null;
      duration_minutes: number | null;
      isClub: boolean;
      duration_weeks: number | null;
      is_ongoing_weekly: boolean;
      size_max: number | null;
      hasTeacherSchedule: boolean;
    }>;
  } | null;
};

export type BulkSendMessageToUserMutationVariables = Exact<{
  messageInput: MessageInput;
  recipientUids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type BulkSendMessageToUserMutation = {
  __typename?: "Mutation";
  sendMessage: { __typename?: "Message"; uid: string };
};

export type TeacherDashboardQueryQueryVariables = Exact<{
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
}>;

export type TeacherDashboardQueryQuery = {
  __typename?: "Query";
  teacherCredentials: Array<{
    __typename?: "TeacherCredential";
    uid: string;
    certificateExpireDate: any | null;
    certificateDoesNotExpire: boolean | null;
    credentialType: CredentialType;
  } | null> | null;
  currentUser: {
    __typename?: "User";
    uid: string;
    isTeacherUnlisted: boolean;
    teacher_approved_at: any | null;
    confirmedEnrollmentCount: number;
    publicSectionCount: number;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    backgroundChecked: boolean;
    background_check_started_at: any | null;
    background_checked_at: any | null;
    idVerificationStatus: IdvStatus;
    canTeacherStartBackgroundCheck: boolean;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    latestLegalName: string | null;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    roles: Array<string> | null;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    consented_to_coppa_notice_at: any | null;
    hasVerifiedIdentity: boolean | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    upgradablePackages: Array<number | null> | null;
    followerCount: number;
    publicSectionCountIncludingUnpublished: number;
    details: {
      __typename?: "UserDetails";
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    leaderActivities: Array<{
      __typename?: "Activity";
      uid: string;
      published_at: any | null;
      isSelfPaced: boolean;
      hasTeacherSchedule: boolean;
      size_max: number | null;
      requested_listing_at: any | null;
      approvalStatus: {
        __typename?: "ActivityApprovalStatus";
        action: string;
      } | null;
    }>;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      status: TeacherApplicationStatus;
      legal_name: string | null;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    location: {
      __typename?: "Location";
      timeZone: string | null;
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      state: string | null;
    } | null;
    conversations: Array<
      | {
          __typename?: "MessageThread";
          uid: string;
          messages: Array<{
            __typename?: "Message";
            uid: string;
            created_at: any;
            subject: string | null;
            content: string | null;
            sender: {
              __typename?: "Sender";
              uid: string;
              name: string | null;
              photo: string | null;
              headline: string | null;
              leader_link: string | null;
              timeZone: string | null;
            };
          }>;
        }
      | { __typename?: "PrivateClassThread" }
    >;
    teacherMeetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      sectionSequenceNumber: number;
      section: {
        __typename?: "Section";
        uid: string;
        filledSpaceCount: number;
        meetingCount: number;
        pausedLearnerCount: number;
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          titleTranslated: string | null;
          details: { __typename?: "ActivityDetails"; photo: string | null };
        };
      };
    }>;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
      metrics: {
        __typename?: "LeaderMetrics";
        activeSections: number;
        repurchaseRate: number | null;
        averageActivityStarRating: number | null;
        learnersTaught: number;
      };
      listings: Array<{
        __typename?: "Listing";
        activity: { __typename?: "Activity"; uid: string; canEnroll: boolean };
      }>;
    } | null;
  } | null;
};

export type UserProfessionalLearningQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserProfessionalLearningQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    completed_trainings: Array<string> | null;
  } | null;
};

export type MarkExternalActivityCompleteMutationVariables = Exact<{
  externalActivityId: Scalars["String"];
  source: ExternalActivitySource;
}>;

export type MarkExternalActivityCompleteMutation = {
  __typename?: "Mutation";
  markExternalActivityComplete: boolean | null;
};

export type TeacherPublicProfileQueryQueryVariables = Exact<{
  uid?: InputMaybe<Scalars["ID"]>;
  leader_link?: InputMaybe<Scalars["String"]>;
  userTimeZone: Scalars["String"];
  skipRating: Scalars["Boolean"];
}>;

export type TeacherPublicProfileQueryQuery = {
  __typename?: "Query";
  publicProfile: {
    __typename?: "PublicProfile";
    uid: string;
    leader: {
      __typename?: "Leader";
      showCalendar: boolean | null;
      teacher_approved_at: any | null;
      reviewCount: number;
      averageActivityStarRating: number | null;
      successfulClassCount: number;
      educatorTiers: Array<EducatorTier>;
      uid: string;
      name: string | null;
      leader_link: string | null;
      created_at: any | null;
      photo: string | null;
      suspended_at: any | null;
      cityState: string | null;
      socialOptOut: boolean;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      hasBackgroundCheckExpired: boolean | null;
      hasListings: boolean;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      classOfferingTimeRange: {
        __typename?: "ClassOfferingTimeRange";
        start: string;
        end: string;
      } | null;
      listings: Array<{
        __typename?: "Listing";
        insertionId: string | null;
        activity: {
          __typename?: "Activity";
          hasTeacherSchedule: boolean;
          uid: string;
          user_uid: string;
          title: string;
          url: string;
          path: string;
          summary: string | null;
          summaryTranslated: string | null;
          weekly_meetings: number | null;
          duration_weeks: number | null;
          is_ongoing_weekly: boolean;
          isClub: boolean;
          published_at: any | null;
          size_max: number | null;
          size_min: number | null;
          allows_recurring_payment: boolean | null;
          averageStarRating?: number | null;
          reviewCount: number;
          age_min: number | null;
          age_max: number | null;
          duration_minutes: number | null;
          price_cents: number | null;
          priceCreditsPerMeeting: number | null;
          isFlexSchedule: boolean;
          isSelfPaced: boolean;
          canEnroll: boolean;
          isOneTime: boolean;
          titleTranslated: string | null;
          slug_id: string;
          subject: string | null;
          syllabus: {
            __typename?: "Syllabus";
            uid: string;
            lessons: Array<{ __typename?: "Lesson"; uid: string }>;
          } | null;
          clubSection: {
            __typename?: "Section";
            filledSpaceCount: number;
            clubPostCount: number;
          } | null;
          details: { __typename?: "ActivityDetails"; photo: string | null };
          savedClassLists: Array<{
            __typename?: "SavedClassList";
            uid: string;
            lastAddedToAt: any | null;
          }>;
        };
        sections: Array<{
          __typename?: "Section";
          start_time: any | null;
          end_time: any | null;
        }>;
        nextSection: {
          __typename?: "Section";
          uid: string;
          size_max: number | null;
          filledSpaceCount: number;
          start_time: any | null;
          end_time: any | null;
          nextOngoingMeeting: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
          } | null;
        } | null;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          leader_link: string | null;
          averageActivityStarRating: number | null;
          reviewCount: number;
          ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        };
      }>;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        headlineTranslated: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
    } | null;
  } | null;
};

export type TranscriptsQueryQueryVariables = Exact<{
  end?: InputMaybe<Scalars["DateTime"]>;
  learnerUid?: InputMaybe<Scalars["String"]>;
}>;

export type TranscriptsQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    learners: Array<{
      __typename?: "Learner";
      uid: string;
      name: string | null;
    }>;
    enrollmentsForTranscript: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
      withdrawn_at: any | null;
      ongoing_start_time: any | null;
      ongoingEndTime: any | null;
      completedEnrolledWeeks: number;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
      } | null;
      section: {
        __typename?: "Section";
        uid: string;
        canceled_at: any | null;
        start_time: any | null;
        end_time: any | null;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          leader_link: string | null;
        };
        activity: {
          __typename?: "Activity";
          uid: string;
          duration_weeks: number | null;
          title: string;
          titleTranslated: string | null;
          subject: string | null;
          slug_id: string;
          summary: string | null;
        };
        currentUserPublicReview: {
          __typename?: "PublicReview";
          uid: string;
          currentUserCanEdit: boolean;
        } | null;
        meetings: Array<{ __typename?: "Meeting"; end_time: any }>;
      } | null;
    } | null>;
  } | null;
};

export type TutorMatchListingsQueryVariables = Exact<{
  searchFilters: SearchFilters;
  skipRating: Scalars["Boolean"];
  matchNames?: InputMaybe<Scalars["Boolean"]>;
  referrer?: InputMaybe<Scalars["String"]>;
  promotedRankingsEnabled?: InputMaybe<Scalars["Boolean"]>;
  promotedExperimentGroup?: InputMaybe<PromotedExperimentGroup>;
  includeMatchingSections?: InputMaybe<Scalars["Boolean"]>;
  source?: InputMaybe<Scalars["Boolean"]>;
  anonymousId?: InputMaybe<Scalars["String"]>;
  searchUid?: InputMaybe<Scalars["String"]>;
  pageImpressionId?: InputMaybe<Scalars["String"]>;
  isFuzzySearchEnabled?: InputMaybe<Scalars["Boolean"]>;
  useCase?: InputMaybe<SearchUseCase>;
  searchObjective?: InputMaybe<SearchObjective>;
  disableAutoApplyFilterTypes?: InputMaybe<
    Array<InputMaybe<AutoAppliedFilterType>> | InputMaybe<AutoAppliedFilterType>
  >;
  withRecentReviews: Scalars["Boolean"];
}>;

export type TutorMatchListingsQuery = {
  __typename?: "Query";
  tutorMatchListings: {
    __typename?: "TutorMatchListingsConnection";
    badgedEducatorListings: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      activity: {
        __typename?: "Activity";
        uid: string;
        user_uid: string;
        title: string;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        weekly_meetings: number | null;
        duration_weeks: number | null;
        is_ongoing_weekly: boolean;
        isClub: boolean;
        published_at: any | null;
        size_max: number | null;
        size_min: number | null;
        hasTeacherSchedule: boolean;
        allows_recurring_payment: boolean | null;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        price_cents: number | null;
        priceCreditsPerMeeting: number | null;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        canEnroll: boolean;
        isOneTime: boolean;
        titleTranslated: string | null;
        slug_id: string;
        subject: string | null;
        syllabus: {
          __typename?: "Syllabus";
          uid: string;
          lessons: Array<{ __typename?: "Lesson"; uid: string }>;
        } | null;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
        } | null;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
    }>;
    richContentsActivityListings: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      activity: {
        __typename?: "Activity";
        uid: string;
        user_uid: string;
        title: string;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        weekly_meetings: number | null;
        duration_weeks: number | null;
        is_ongoing_weekly: boolean;
        isClub: boolean;
        published_at: any | null;
        size_max: number | null;
        size_min: number | null;
        hasTeacherSchedule: boolean;
        allows_recurring_payment: boolean | null;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        price_cents: number | null;
        priceCreditsPerMeeting: number | null;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        canEnroll: boolean;
        isOneTime: boolean;
        titleTranslated: string | null;
        slug_id: string;
        subject: string | null;
        syllabus: {
          __typename?: "Syllabus";
          uid: string;
          lessons: Array<{ __typename?: "Lesson"; uid: string }>;
        } | null;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
        } | null;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
    }>;
    highQualityActivityListings: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      activity: {
        __typename?: "Activity";
        uid: string;
        user_uid: string;
        title: string;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        weekly_meetings: number | null;
        duration_weeks: number | null;
        is_ongoing_weekly: boolean;
        isClub: boolean;
        published_at: any | null;
        size_max: number | null;
        size_min: number | null;
        hasTeacherSchedule: boolean;
        allows_recurring_payment: boolean | null;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        price_cents: number | null;
        priceCreditsPerMeeting: number | null;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        canEnroll: boolean;
        isOneTime: boolean;
        titleTranslated: string | null;
        slug_id: string;
        subject: string | null;
        syllabus: {
          __typename?: "Syllabus";
          uid: string;
          lessons: Array<{ __typename?: "Lesson"; uid: string }>;
        } | null;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
        } | null;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
    }>;
    generalEducatorListings: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      activity: {
        __typename?: "Activity";
        uid: string;
        user_uid: string;
        title: string;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        weekly_meetings: number | null;
        duration_weeks: number | null;
        is_ongoing_weekly: boolean;
        isClub: boolean;
        published_at: any | null;
        size_max: number | null;
        size_min: number | null;
        hasTeacherSchedule: boolean;
        allows_recurring_payment: boolean | null;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        price_cents: number | null;
        priceCreditsPerMeeting: number | null;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        canEnroll: boolean;
        isOneTime: boolean;
        titleTranslated: string | null;
        slug_id: string;
        subject: string | null;
        syllabus: {
          __typename?: "Syllabus";
          uid: string;
          lessons: Array<{ __typename?: "Lesson"; uid: string }>;
        } | null;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
        } | null;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
    }>;
  };
};

export type UnfollowConfirmationQueryVariables = Exact<{
  leaderUid?: InputMaybe<Scalars["ID"]>;
}>;

export type UnfollowConfirmationQuery = {
  __typename?: "Query";
  publicProfile: {
    __typename?: "PublicProfile";
    uid: string;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      leader_link: string | null;
      created_at: any | null;
      photo: string | null;
      suspended_at: any | null;
      cityState: string | null;
      socialOptOut: boolean;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      hasBackgroundCheckExpired: boolean | null;
      reviewCount: number;
      averageActivityStarRating: number | null;
      hasListings: boolean;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        headlineTranslated: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
    } | null;
  } | null;
};

export type UnscheduleRequestConfirmationQueryVariables = Exact<{
  activityUid?: InputMaybe<Scalars["ID"]>;
}>;

export type UnscheduleRequestConfirmationQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    user_uid: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    learningGoals: Array<string>;
    assessment: string | null;
    homework: string | null;
    grading_policy: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    english_proficiency_level: EnglishProficiencyLevel | null;
    grade_level_min: string | null;
    grade_level_max: string | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    priceCredits: number | null;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    allowWaitlist: boolean | null;
    isClub: boolean;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    allows_recurring_payment: boolean | null;
    allows_late_enrollments_for_fixed_length_classes: boolean;
    has_building_content: boolean | null;
    hasTeacherSchedule: boolean;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isOneTime: boolean;
    classFormat: EducatorSelectedFormat;
    classroomType: ClassroomType;
    strictAgeRange: {
      __typename?: "AgeRangeType";
      min: number;
      max: number;
    } | null;
    proficiency_level: {
      __typename?: "ProficiencyLevel";
      level: Array<string | null> | null;
      text: string | null;
    } | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      description: string;
      type: RefundPolicyType;
    };
    details: {
      __typename?: "ActivityDetails";
      photo: string | null;
      photoSquare: string | null;
    };
  };
};

export type UserUpdateAdminMutationVariables = Exact<{
  uid: Scalars["ID"];
  updates: UserAdminInput;
}>;

export type UserUpdateAdminMutation = {
  __typename?: "Mutation";
  updateUserAdmin: {
    __typename?: "User";
    roles: Array<string> | null;
    uid: string;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    latestLegalName: string | null;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    teacher_approved_at: any | null;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    consented_to_coppa_notice_at: any | null;
    hasVerifiedIdentity: boolean | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    upgradablePackages: Array<number | null> | null;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    details: {
      __typename?: "UserDetails";
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    location: {
      __typename?: "Location";
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      timeZone: string | null;
      state: string | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
    } | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
    } | null;
  };
};

export type UserAdminLinkQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type UserAdminLinkQuery = {
  __typename?: "Query";
  switchUserLink: string;
};

export type BulkCreateAccountsMutationVariables = Exact<{
  organizationUid: Scalars["ID"];
  data: Array<CreateAccountInput> | CreateAccountInput;
  options?: InputMaybe<BulkCreateAccountOptions>;
}>;

export type BulkCreateAccountsMutation = {
  __typename?: "Mutation";
  bulkCreateAccounts: Array<{
    __typename?: "CreateAccountResult";
    parentName: string | null;
    parentEmail: string | null;
    learnerName: string | null;
    learnerAge: number | null;
    learnerEmail: string | null;
    comments: string | null;
  }>;
};

export type SubmitClubIntroductionMutationVariables = Exact<{
  sectionUid: Scalars["ID"];
  videoUid: Scalars["ID"];
}>;

export type SubmitClubIntroductionMutation = {
  __typename?: "Mutation";
  submitClubIntroduction: boolean;
};

export type ClubMustSubmitIntroQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type ClubMustSubmitIntroQuery = {
  __typename?: "Query";
  club: {
    __typename?: "Club";
    uid: string;
    currentLearnerHasSubmittedIntroductionForClub: boolean;
  };
};

export type AcceptGuidelinesMutationVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type AcceptGuidelinesMutation = {
  __typename?: "Mutation";
  acceptGuidelinesForSection: boolean;
};

export type ClubGuidelinesQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type ClubGuidelinesQuery = {
  __typename?: "Query";
  club: {
    __typename?: "Club";
    uid: string;
    currentLearnerMustAcceptGuidelines: boolean;
  };
};

export type LoginLearnerWithAppleMutationVariables = Exact<{
  code: Scalars["String"];
}>;

export type LoginLearnerWithAppleMutation = {
  __typename?: "Mutation";
  loginOrCreateAccountWithAppleV2:
    | { __typename: "LoginError"; error: CreateOrLoginError }
    | {
        __typename: "LoginResultV2";
        isNewUser: boolean;
        authentication: {
          __typename?: "AuthenticationV2";
          sessionToken: string;
          refreshToken: string;
        };
      };
};

export type LearnerActivityLeaderQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type LearnerActivityLeaderQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    activity: {
      __typename?: "Activity";
      uid: string;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
      };
    };
  } | null;
};

export type SetLearnerInterfacePreferenceMutationVariables = Exact<{
  name: Scalars["String"];
  value: Scalars["JSON"];
}>;

export type SetLearnerInterfacePreferenceMutation = {
  __typename?: "Mutation";
  setLearnerInterfacePreference: boolean;
};

export type CurrentLearnerRecommendedTopicsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentLearnerRecommendedTopicsQuery = {
  __typename?: "Query";
  currentLearnerRecommendedTopics: Array<{
    __typename?: "Topic";
    uid: string;
    label: string;
    image_url: string;
  }>;
};

export type EnrollmentToReviewSoonFragmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  section: {
    __typename?: "Section";
    uid: string;
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      subject: string | null;
      is_ongoing_weekly: boolean;
    };
  } | null;
};

export type SubmitInterestedInTakingMoreClassesAboutTopicMutationVariables =
  Exact<{
    learnerSectionReviewInput: LearnerSectionReviewInput;
  }>;

export type SubmitInterestedInTakingMoreClassesAboutTopicMutation = {
  __typename?: "Mutation";
  createOrUpdateLearnerSectionReview: {
    __typename?: "LearnerSectionReview";
    uid: string;
    name: ReviewName | null;
    response: {
      __typename?: "LearnerReviewResponse";
      vote: string | null;
    } | null;
  };
};

export type MarkClubPostViewedMutationVariables = Exact<{
  classPostUid: Scalars["ID"];
}>;

export type MarkClubPostViewedMutation = {
  __typename?: "Mutation";
  markClubPostViewed: boolean;
};

export type ReviewActivityQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type ReviewActivityQuery = {
  __typename?: "Query";
  activityBySectionUidIfLatestMeetingNotReviewed: {
    __typename?: "Activity";
    uid: string;
    titleTranslated: string | null;
    leader: {
      __typename?: "Leader";
      name: string | null;
      photo: string | null;
    };
    details: { __typename?: "ActivityDetails"; photo: string | null };
  } | null;
};

export type LearnerBannerQueryVariables = Exact<{ [key: string]: never }>;

export type LearnerBannerQuery = {
  __typename?: "Query";
  contentfulLearnerBannerCollection: {
    __typename?: "ContentfulLearnerBannerCollection";
    items: Array<{
      __typename?: "ContentfulLearnerBanner";
      name: string | null;
      title: string | null;
      subtitle: string | null;
      ctaText: string | null;
      ctaUrl: string | null;
      campaign: {
        __typename?: "ContentfulCampaign";
        name: string | null;
        startAt: any | null;
        endAt: any | null;
      } | null;
      image: {
        __typename?: "ContentfulAsset";
        title: string | null;
        url: string | null;
      } | null;
    } | null>;
  } | null;
};

export type MeetingReviewMutationMutationVariables = Exact<{
  sectionUid: Scalars["ID"];
  rating: Scalars["Int"];
}>;

export type MeetingReviewMutationMutation = {
  __typename?: "Mutation";
  createMeetingReview: {
    __typename?: "MeetingReview";
    uid: string;
    learnerUid: string;
    rating: number;
    meetingUid: string;
    sectionUid: string;
  };
};

export type CurrentLearnerFragmentFragment = {
  __typename?: "CurrentLearner";
  uid: string;
  name: string | null;
  email: string | null;
  username: string | null;
  age: number | null;
  avatar: string | null;
  unlockedAvatars: Array<string>;
  pronoun: string | null;
  accessLevel: LearnerAccessLevel;
  intro: string | null;
  timeZone: string;
  optedOutOfPersonalizedRecommendationsAt: any | null;
  hasUpcomingScheduleEvent: boolean;
  isLoginEnabled: boolean;
  isVerified: boolean;
  parent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
  };
  siblings: Array<{
    __typename?: "Child";
    uid: string;
    name: string | null;
    avatar: string | null;
    age: number | null;
  }>;
  enrollmentToReviewSoon: {
    __typename?: "Enrollment";
    uid: string;
    section: {
      __typename?: "Section";
      uid: string;
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        subject: string | null;
        is_ongoing_weekly: boolean;
      };
    } | null;
  } | null;
  experiments: Array<{
    __typename?: "LearnerExperiment";
    name: string;
    variant: string;
  }>;
  interfacePreferences: Array<{
    __typename?: "LearnerInterfacePreference";
    name: string;
    value: any;
  }>;
};

export type CurrentLearnerQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentLearnerQuery = {
  __typename?: "Query";
  currentLearner: {
    __typename?: "CurrentLearner";
    uid: string;
    name: string | null;
    email: string | null;
    username: string | null;
    age: number | null;
    avatar: string | null;
    unlockedAvatars: Array<string>;
    pronoun: string | null;
    accessLevel: LearnerAccessLevel;
    intro: string | null;
    timeZone: string;
    optedOutOfPersonalizedRecommendationsAt: any | null;
    hasUpcomingScheduleEvent: boolean;
    isLoginEnabled: boolean;
    isVerified: boolean;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    };
    siblings: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      avatar: string | null;
      age: number | null;
    }>;
    enrollmentToReviewSoon: {
      __typename?: "Enrollment";
      uid: string;
      section: {
        __typename?: "Section";
        uid: string;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          subject: string | null;
          is_ongoing_weekly: boolean;
        };
      } | null;
    } | null;
    experiments: Array<{
      __typename?: "LearnerExperiment";
      name: string;
      variant: string;
    }>;
    interfacePreferences: Array<{
      __typename?: "LearnerInterfacePreference";
      name: string;
      value: any;
    }>;
  };
};

export type LearnerAppActivityFragmentFragment = {
  __typename?: "Activity";
  uid: string;
  slug_id: string;
  user_uid: string;
  title: string;
  class_experience: string | null;
  learning_goals: string | null;
  assessment: string | null;
  homework: string | null;
  prerequisites: string | null;
  published_at: any | null;
  is_online: boolean;
  age_min: number | null;
  age_max: number | null;
  size_min: number | null;
  size_max: number | null;
  duration_minutes: number | null;
  duration_weeks: number | null;
  weekly_meetings: number | null;
  price_cents: number | null;
  summary: string | null;
  is_ongoing_weekly: boolean;
  subject: string | null;
  languageOfInstruction: any | null;
  summaryForLearners: string | null;
  isClub: boolean;
  isAutoSchedulingEnabled: boolean | null;
  autoSchedulingDaysNoticeMin: number | null;
  autoSchedulingDaysNoticeMax: number | null;
  hasTeacherSchedule: boolean;
  refundPolicy: {
    __typename?: "RefundPolicy";
    name: string;
    description: string;
  };
  details: { __typename?: "ActivityDetails"; photo: string | null };
};

export type ClubOnboardingBannerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ClubOnboardingBannerQuery = {
  __typename?: "Query";
  currentLearner: {
    __typename?: "CurrentLearner";
    uid: string;
    about_me: string | null;
    hasWrittenClubPost: boolean | null;
    hasWrittenClubPostComment: boolean | null;
  };
};

export type LearnerSearchActivityByTopicSearchResultsQueryVariables = Exact<{
  topicUid?: InputMaybe<Scalars["ID"]>;
  cursor?: InputMaybe<Scalars["String"]>;
  activityExperiences?: InputMaybe<
    Array<ActivityExperience> | ActivityExperience
  >;
}>;

export type LearnerSearchActivityByTopicSearchResultsQuery = {
  __typename?: "Query";
  learnerSearchActivityByTopic: {
    __typename?: "LearnerActivityByTopicSearch";
    cursor: string | null;
    topicLabel: string | null;
    activities: Array<{
      __typename?: "Activity";
      uid: string;
      title: string;
      slug_id: string;
      price_cents: number | null;
      age_max: number | null;
      age_min: number | null;
      isClub: boolean;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
      };
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      videoForLearners: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    }>;
  };
};

export type LearnerActivityDetailsQueryVariables = Exact<{
  uid?: InputMaybe<Scalars["ID"]>;
  slugId?: InputMaybe<Scalars["String"]>;
}>;

export type LearnerActivityDetailsQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    class_experience: string | null;
    summary: string | null;
    summaryForLearners: string | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    age_min: number | null;
    age_max: number | null;
    size_max: number | null;
    hasTeacherSchedule: boolean;
    price_cents: number | null;
    weekly_meetings: number | null;
    is_ongoing_weekly: boolean;
    isClub: boolean;
    slug_id: string;
    promotableSections: Array<{
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
      price_cents: number | null;
    }>;
    details: { __typename?: "ActivityDetails"; photo: string | null };
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    videoForLearners: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
      };
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    sampleClubPosts: Array<{
      __typename?: "SampleClubPost";
      text: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      }> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      } | null;
      comments: Array<{
        __typename?: "SampleClubPostComment";
        text: string | null;
      }>;
    }> | null;
  };
};

export type LearnerListingRecommendationsQueryVariables = Exact<{
  shuffle?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type LearnerListingRecommendationsQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    perLearnerRecommendations: Array<{
      __typename?: "Listing";
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        slug_id: string;
        price_cents: number | null;
        age_max: number | null;
        age_min: number | null;
        isClub: boolean;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
        };
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
        videoForLearners: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    }>;
  };
};

export type LearnerActivitySearchResultFragmentFragment = {
  __typename?: "Activity";
  uid: string;
  title: string;
  slug_id: string;
  price_cents: number | null;
  age_max: number | null;
  age_min: number | null;
  isClub: boolean;
  details: { __typename?: "ActivityDetails"; photo: string | null };
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    photo: string | null;
  };
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
  videoForLearners: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
};

export type LearnerClassroomAssignmentClassPostsBySectionAndLessonQueryVariables =
  Exact<{
    sectionUid: Scalars["ID"];
    lessonUid: Scalars["ID"];
  }>;

export type LearnerClassroomAssignmentClassPostsBySectionAndLessonQuery = {
  __typename?: "Query";
  assignmentClassPosts: Array<{
    __typename?: "AssignmentClassPost";
    uid: string;
    title: string;
    submissionRequirement: SubmissionRequirementType;
    publishAt: any;
    dueDate: any | null;
    user: { __typename?: "User"; uid: string; name: string | null };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    comments: Array<{
      __typename?: "AssignmentClassPostComment";
      uid: string;
      sentAt: any;
      isSubmission: boolean | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    }>;
  }> | null;
};

export type LearnerClassroomAssignmentClassPostsBySectionQueryVariables =
  Exact<{
    sectionUid: Scalars["ID"];
  }>;

export type LearnerClassroomAssignmentClassPostsBySectionQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
      title: string;
      submissionRequirement: SubmissionRequirementType;
      publishAt: any;
      dueDate: any | null;
      user: { __typename?: "User"; uid: string; name: string | null };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      comments: Array<{
        __typename?: "AssignmentClassPostComment";
        uid: string;
        sentAt: any;
        threadForLearnerUid: string;
        isSubmission: boolean | null;
        replies: Array<{
          __typename?: "AssignmentClassPostComment";
          uid: string;
          sentAt: any;
          threadForLearnerUid: string;
          isSubmission: boolean | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }> | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
      lesson: {
        __typename?: "Lesson";
        uid: string;
        lessonNumber: number | null;
      } | null;
    }>;
  } | null;
};

export type LearnerCommentOnAssignmentMutationVariables = Exact<{
  assignmentClassPostUid: Scalars["ID"];
  messageContentInput: MessageContentInput;
}>;

export type LearnerCommentOnAssignmentMutation = {
  __typename?: "Mutation";
  addAssignmentClassPostComment: {
    __typename?: "AssignmentClassPostComment";
    uid: string;
    sentAt: any;
    isSubmission: boolean | null;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type LearnerClassroomPostsQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  start?: InputMaybe<Scalars["DateTime"]>;
  end?: InputMaybe<Scalars["DateTime"]>;
  plainText?: InputMaybe<Scalars["Boolean"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  postsFilter?: InputMaybe<ClassroomPostFilter>;
}>;

export type LearnerClassroomPostsQuery = {
  __typename?: "Query";
  classroom: {
    __typename?: "Classroom";
    uid: string;
    usersCanPost: boolean;
    usersCanComment: boolean;
    posts: Array<{
      __typename?: "ClassPost";
      uid: string;
      isWelcomePost: boolean;
      publishAt: any;
      title: string | null;
      promotedAt: any | null;
      pinnedAt: any | null;
      mentionedEnrollmentUids: Array<string>;
      backgroundColor: string | null;
      score: number | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      commentsPreview: {
        __typename?: "CommentsPreview";
        remainingCommentsCount: number;
        comments: Array<{
          __typename?: "ClassPostComment";
          uid: string;
          sendAt: any;
          mentionedEnrollmentUids: Array<string>;
          mentionedTeacherUid: string | null;
          backgroundColor: string | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      };
    }>;
    unpublishedPosts: Array<{
      __typename?: "ClassPost";
      uid: string;
      isWelcomePost: boolean;
      publishAt: any;
      title: string | null;
      promotedAt: any | null;
      pinnedAt: any | null;
      mentionedEnrollmentUids: Array<string>;
      backgroundColor: string | null;
      score: number | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      commentsPreview: {
        __typename?: "CommentsPreview";
        remainingCommentsCount: number;
        comments: Array<{
          __typename?: "ClassPostComment";
          uid: string;
          sendAt: any;
          mentionedEnrollmentUids: Array<string>;
          mentionedTeacherUid: string | null;
          backgroundColor: string | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      };
    }>;
    activity: {
      __typename?: "Activity";
      user_uid: string;
      leader: {
        __typename?: "Leader";
        uid: string;
        leader_link: string | null;
        name: string | null;
      };
    };
    section: {
      __typename?: "Section";
      currentUserCanManage: boolean;
      uid: string;
      meetings: Array<{
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        recordingAvailability: RecordingAvailability;
        recordingRequests: Array<{
          __typename?: "RecordingRequest";
          requestedAt: any;
          message: string;
          requestedBy: {
            __typename?: "Learner";
            uid: string;
            name: string | null;
          };
        }>;
        classRecordings: Array<
          | {
              __typename?: "ClassRecording";
              uid: string;
              startTime: any;
              endTime: any;
            }
          | {
              __typename?: "ZoomRecording";
              uid: string;
              startTime: any;
              endTime: any;
            }
        >;
        attendances: Array<{
          __typename?: "Attendance";
          learner: { __typename?: "Learner"; name: string | null };
        }>;
      }>;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
    };
    mentionNames: Array<{
      __typename?: "MentionName";
      id: string;
      display: string;
      avatar: string | null;
      startTime: any | null;
    }>;
  };
};

export type LearnerClassroomSectionLessonFragmentFragment = {
  __typename?: "SectionLesson";
  uid: string;
  type: SectionLessonType;
  orderInSection: number;
  isCompleted: boolean;
  isLocked: boolean;
  lessonStatus: LearningStatementsVerb;
  isCancelled: boolean;
  title: string;
  description: string | null;
  start_time: any | null;
  lesson: { __typename?: "Lesson"; uid: string; isMissed: boolean } | null;
  unit: {
    __typename?: "Unit";
    uid: string;
    title: string | null;
    unitNumber: number | null;
  } | null;
  liveMeetingDetails: {
    __typename?: "LessonLiveMeeting";
    uid: string;
    enrollmentUid: string | null;
    classRecordingUids: Array<string | null>;
    sectionStartTime: any | null;
    meetingStartTime: any | null;
    meetingEndTime: any | null;
    meetingsCount: number;
    usersCanHaveLiveMeeting: boolean | null;
  } | null;
  assignmentClassPosts: Array<{
    __typename?: "AssignmentClassPost";
    uid: string;
  }>;
  classPosts: Array<{ __typename?: "ClassPost"; uid: string }>;
};

export type LearnerClassroomLessonFragmentFragment = {
  __typename?: "Lesson";
  uid: string;
  title: string | null;
  description: string | null;
  week_number: number;
  order_within_week: number;
  lessonNumber: number | null;
  lessonStatus: LearningStatementsVerb | null;
  isCompleted: boolean;
  isLocked: boolean;
  isMissed: boolean;
  unit: {
    __typename?: "Unit";
    uid: string;
    title: string | null;
    unitNumber: number | null;
  } | null;
  meetings: Array<{
    __typename?: "Meeting";
    uid: string;
    start_time: any;
  }> | null;
};

export type LearnerClassroomSyllabusFragmentFragment = {
  __typename?: "Syllabus";
  uid: string;
  published_at: string | null;
  currentLesson: {
    __typename?: "Lesson";
    uid: string;
    title: string | null;
    description: string | null;
    week_number: number;
    order_within_week: number;
    lessonNumber: number | null;
    lessonStatus: LearningStatementsVerb | null;
    isCompleted: boolean;
    isLocked: boolean;
    isMissed: boolean;
    unit: {
      __typename?: "Unit";
      uid: string;
      title: string | null;
      unitNumber: number | null;
    } | null;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
    }> | null;
  } | null;
  lessons: Array<{
    __typename?: "Lesson";
    uid: string;
    lessonNumber: number | null;
    title: string | null;
    description: string | null;
    week_number: number;
    order_within_week: number;
    lessonStatus: LearningStatementsVerb | null;
    isCompleted: boolean;
    isLocked: boolean;
    isMissed: boolean;
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
      title: string;
      submissionRequirement: SubmissionRequirementType;
      dueDate: any | null;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      comments: Array<{
        __typename?: "AssignmentClassPostComment";
        uid: string;
        sentAt: any;
        isSubmission: boolean | null;
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    }>;
    unit: {
      __typename?: "Unit";
      uid: string;
      title: string | null;
      unitNumber: number | null;
    } | null;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
    }> | null;
  }>;
};

export type LearnerClassroomSectionFragmentFragment = {
  __typename?: "Section";
  uid: string;
  start_time: any | null;
  end_time: any | null;
  canceled_at: any | null;
  is_self_paced: boolean;
  filledSpaceCount: number;
  usesOutschoolVideoChat: boolean;
  currentUserHasAttendances: boolean;
  nextOngoingMeeting: {
    __typename?: "Meeting";
    uid: string;
    start_time: any;
  } | null;
  leader: { __typename?: "Leader"; uid: string; name: string | null };
  assignmentClassPosts: Array<{
    __typename?: "AssignmentClassPost";
    uid: string;
    title: string;
    submissionRequirement: SubmissionRequirementType;
    dueDate: any | null;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    comments: Array<{
      __typename?: "AssignmentClassPostComment";
      uid: string;
      sentAt: any;
      isSubmission: boolean | null;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    }>;
  }>;
  currentSectionLesson: {
    __typename?: "SectionLesson";
    uid: string;
    type: SectionLessonType;
    orderInSection: number;
    isCompleted: boolean;
    isLocked: boolean;
    lessonStatus: LearningStatementsVerb;
    isCancelled: boolean;
    title: string;
    description: string | null;
    start_time: any | null;
    lesson: { __typename?: "Lesson"; uid: string; isMissed: boolean } | null;
    unit: {
      __typename?: "Unit";
      uid: string;
      title: string | null;
      unitNumber: number | null;
    } | null;
    liveMeetingDetails: {
      __typename?: "LessonLiveMeeting";
      uid: string;
      enrollmentUid: string | null;
      classRecordingUids: Array<string | null>;
      sectionStartTime: any | null;
      meetingStartTime: any | null;
      meetingEndTime: any | null;
      meetingsCount: number;
      usersCanHaveLiveMeeting: boolean | null;
    } | null;
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
    }>;
    classPosts: Array<{ __typename?: "ClassPost"; uid: string }>;
  } | null;
  sectionLessons: Array<{
    __typename?: "SectionLesson";
    uid: string;
    type: SectionLessonType;
    orderInSection: number;
    isCompleted: boolean;
    isLocked: boolean;
    lessonStatus: LearningStatementsVerb;
    isCancelled: boolean;
    title: string;
    description: string | null;
    start_time: any | null;
    lesson: { __typename?: "Lesson"; uid: string; isMissed: boolean } | null;
    unit: {
      __typename?: "Unit";
      uid: string;
      title: string | null;
      unitNumber: number | null;
    } | null;
    liveMeetingDetails: {
      __typename?: "LessonLiveMeeting";
      uid: string;
      enrollmentUid: string | null;
      classRecordingUids: Array<string | null>;
      sectionStartTime: any | null;
      meetingStartTime: any | null;
      meetingEndTime: any | null;
      meetingsCount: number;
      usersCanHaveLiveMeeting: boolean | null;
    } | null;
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
    }>;
    classPosts: Array<{ __typename?: "ClassPost"; uid: string }>;
  }> | null;
  syllabus: {
    __typename?: "Syllabus";
    uid: string;
    published_at: string | null;
    currentLesson: {
      __typename?: "Lesson";
      uid: string;
      title: string | null;
      description: string | null;
      week_number: number;
      order_within_week: number;
      lessonNumber: number | null;
      lessonStatus: LearningStatementsVerb | null;
      isCompleted: boolean;
      isLocked: boolean;
      isMissed: boolean;
      unit: {
        __typename?: "Unit";
        uid: string;
        title: string | null;
        unitNumber: number | null;
      } | null;
      meetings: Array<{
        __typename?: "Meeting";
        uid: string;
        start_time: any;
      }> | null;
    } | null;
    lessons: Array<{
      __typename?: "Lesson";
      uid: string;
      lessonNumber: number | null;
      title: string | null;
      description: string | null;
      week_number: number;
      order_within_week: number;
      lessonStatus: LearningStatementsVerb | null;
      isCompleted: boolean;
      isLocked: boolean;
      isMissed: boolean;
      assignmentClassPosts: Array<{
        __typename?: "AssignmentClassPost";
        uid: string;
        title: string;
        submissionRequirement: SubmissionRequirementType;
        dueDate: any | null;
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
        comments: Array<{
          __typename?: "AssignmentClassPostComment";
          uid: string;
          sentAt: any;
          isSubmission: boolean | null;
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      }>;
      unit: {
        __typename?: "Unit";
        uid: string;
        title: string | null;
        unitNumber: number | null;
      } | null;
      meetings: Array<{
        __typename?: "Meeting";
        uid: string;
        start_time: any;
      }> | null;
    }>;
  } | null;
};

export type LearnerClassroomActivityFragmentFragment = {
  __typename?: "Activity";
  uid: string;
  user_uid: string;
  title: string;
  is_ongoing_weekly: boolean;
  weekly_meetings: number | null;
  is_online: boolean;
  duration_weeks: number | null;
  isClub: boolean;
  published_at: any | null;
  isSelfPaced: boolean;
  details: { __typename?: "ActivityDetails"; photo: string | null };
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    leader_link: string | null;
  };
};

export type LearnerClassroomQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
}>;

export type LearnerClassroomQuery = {
  __typename?: "Query";
  classroom: {
    __typename?: "Classroom";
    uid: string;
    usersCanHaveLiveMeeting: boolean;
    currentLearnerEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      isPaused: boolean;
    }>;
    learners: Array<{
      __typename?: "LearnerProfile";
      uid: string;
      name: string | null;
      avatar: string | null;
    }>;
    section: {
      __typename?: "Section";
      uid: string;
      start_time: any | null;
      end_time: any | null;
      canceled_at: any | null;
      is_self_paced: boolean;
      filledSpaceCount: number;
      usesOutschoolVideoChat: boolean;
      currentUserHasAttendances: boolean;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      assignmentClassPosts: Array<{
        __typename?: "AssignmentClassPost";
        uid: string;
        title: string;
        submissionRequirement: SubmissionRequirementType;
        dueDate: any | null;
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
        comments: Array<{
          __typename?: "AssignmentClassPostComment";
          uid: string;
          sentAt: any;
          isSubmission: boolean | null;
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      }>;
      currentSectionLesson: {
        __typename?: "SectionLesson";
        uid: string;
        type: SectionLessonType;
        orderInSection: number;
        isCompleted: boolean;
        isLocked: boolean;
        lessonStatus: LearningStatementsVerb;
        isCancelled: boolean;
        title: string;
        description: string | null;
        start_time: any | null;
        lesson: {
          __typename?: "Lesson";
          uid: string;
          isMissed: boolean;
        } | null;
        unit: {
          __typename?: "Unit";
          uid: string;
          title: string | null;
          unitNumber: number | null;
        } | null;
        liveMeetingDetails: {
          __typename?: "LessonLiveMeeting";
          uid: string;
          enrollmentUid: string | null;
          classRecordingUids: Array<string | null>;
          sectionStartTime: any | null;
          meetingStartTime: any | null;
          meetingEndTime: any | null;
          meetingsCount: number;
          usersCanHaveLiveMeeting: boolean | null;
        } | null;
        assignmentClassPosts: Array<{
          __typename?: "AssignmentClassPost";
          uid: string;
        }>;
        classPosts: Array<{ __typename?: "ClassPost"; uid: string }>;
      } | null;
      sectionLessons: Array<{
        __typename?: "SectionLesson";
        uid: string;
        type: SectionLessonType;
        orderInSection: number;
        isCompleted: boolean;
        isLocked: boolean;
        lessonStatus: LearningStatementsVerb;
        isCancelled: boolean;
        title: string;
        description: string | null;
        start_time: any | null;
        lesson: {
          __typename?: "Lesson";
          uid: string;
          isMissed: boolean;
        } | null;
        unit: {
          __typename?: "Unit";
          uid: string;
          title: string | null;
          unitNumber: number | null;
        } | null;
        liveMeetingDetails: {
          __typename?: "LessonLiveMeeting";
          uid: string;
          enrollmentUid: string | null;
          classRecordingUids: Array<string | null>;
          sectionStartTime: any | null;
          meetingStartTime: any | null;
          meetingEndTime: any | null;
          meetingsCount: number;
          usersCanHaveLiveMeeting: boolean | null;
        } | null;
        assignmentClassPosts: Array<{
          __typename?: "AssignmentClassPost";
          uid: string;
        }>;
        classPosts: Array<{ __typename?: "ClassPost"; uid: string }>;
      }> | null;
      syllabus: {
        __typename?: "Syllabus";
        uid: string;
        published_at: string | null;
        currentLesson: {
          __typename?: "Lesson";
          uid: string;
          title: string | null;
          description: string | null;
          week_number: number;
          order_within_week: number;
          lessonNumber: number | null;
          lessonStatus: LearningStatementsVerb | null;
          isCompleted: boolean;
          isLocked: boolean;
          isMissed: boolean;
          unit: {
            __typename?: "Unit";
            uid: string;
            title: string | null;
            unitNumber: number | null;
          } | null;
          meetings: Array<{
            __typename?: "Meeting";
            uid: string;
            start_time: any;
          }> | null;
        } | null;
        lessons: Array<{
          __typename?: "Lesson";
          uid: string;
          lessonNumber: number | null;
          title: string | null;
          description: string | null;
          week_number: number;
          order_within_week: number;
          lessonStatus: LearningStatementsVerb | null;
          isCompleted: boolean;
          isLocked: boolean;
          isMissed: boolean;
          assignmentClassPosts: Array<{
            __typename?: "AssignmentClassPost";
            uid: string;
            title: string;
            submissionRequirement: SubmissionRequirementType;
            dueDate: any | null;
            messageContent: {
              __typename?: "MessageContent";
              uid: string;
              text: string | null;
              originalMimeType: TextMimeType;
              editedAt: any | null;
              htmlText: string | null;
              attachments: Array<{
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              }>;
              video: {
                __typename?: "Attachment";
                uid: string;
                conversionStatus: string | null;
                created_at: any;
                file: {
                  __typename?: "File";
                  filename: string;
                  mimetype: string | null;
                  size: number;
                  url: string;
                  thumbUrl: string | null;
                };
              } | null;
            };
            comments: Array<{
              __typename?: "AssignmentClassPostComment";
              uid: string;
              sentAt: any;
              isSubmission: boolean | null;
              messageContent: {
                __typename?: "MessageContent";
                uid: string;
                text: string | null;
                originalMimeType: TextMimeType;
                editedAt: any | null;
                htmlText: string | null;
                attachments: Array<{
                  __typename?: "Attachment";
                  uid: string;
                  conversionStatus: string | null;
                  created_at: any;
                  file: {
                    __typename?: "File";
                    filename: string;
                    mimetype: string | null;
                    size: number;
                    url: string;
                    thumbUrl: string | null;
                  };
                }>;
                video: {
                  __typename?: "Attachment";
                  uid: string;
                  conversionStatus: string | null;
                  created_at: any;
                  file: {
                    __typename?: "File";
                    filename: string;
                    mimetype: string | null;
                    size: number;
                    url: string;
                    thumbUrl: string | null;
                  };
                } | null;
              };
            }>;
          }>;
          unit: {
            __typename?: "Unit";
            uid: string;
            title: string | null;
            unitNumber: number | null;
          } | null;
          meetings: Array<{
            __typename?: "Meeting";
            uid: string;
            start_time: any;
          }> | null;
        }>;
      } | null;
    };
    activity: {
      __typename?: "Activity";
      uid: string;
      user_uid: string;
      title: string;
      is_ongoing_weekly: boolean;
      weekly_meetings: number | null;
      is_online: boolean;
      duration_weeks: number | null;
      isClub: boolean;
      published_at: any | null;
      isSelfPaced: boolean;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
      };
    };
  };
};

export type LearnerDashboard_SectionFragment = {
  __typename?: "Section";
  uid: string;
  start_time: any | null;
  end_time: any | null;
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    subject: string | null;
    duration_weeks: number | null;
    summary: string | null;
    weekly_meetings: number | null;
    is_ongoing_weekly: boolean;
    isClub: boolean;
    isFlexSchedule: boolean;
    details: { __typename?: "ActivityDetails"; photo: string | null };
  };
  leader: { __typename?: "Leader"; uid: string; name: string | null };
};

export type LearnerKeywordSearchResultsQueryVariables = Exact<{
  query?: InputMaybe<Scalars["String"]>;
  cursor?: InputMaybe<Scalars["String"]>;
  activityExperiences?: InputMaybe<
    Array<ActivityExperience> | ActivityExperience
  >;
}>;

export type LearnerKeywordSearchResultsQuery = {
  __typename?: "Query";
  learnerSearchKeyword: {
    __typename?: "LearnerKeywordSearch";
    cursor: string | null;
    activities: Array<{
      __typename?: "Activity";
      uid: string;
      title: string;
      slug_id: string;
      price_cents: number | null;
      age_max: number | null;
      age_min: number | null;
      isClub: boolean;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
      };
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      videoForLearners: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    }>;
  };
};

export type LearnerLeaderFragmentFragment = {
  __typename?: "Leader";
  uid: string;
  name: string | null;
  photo: string | null;
  leader_link: string | null;
  details: {
    __typename?: "LeaderDetails";
    about: string | null;
    headline: string | null;
  };
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
};

export type MarkLearnerNotificationsReadMutationVariables = Exact<{
  uids: Array<Scalars["ID"]> | Scalars["ID"];
  after: Scalars["DateTime"];
}>;

export type MarkLearnerNotificationsReadMutation = {
  __typename?: "Mutation";
  markNotificationRead: {
    __typename?: "MarkNotificationReadResult";
    learnerHasUnreadNotifications: boolean;
  };
};

type NotificationFragment_AssignmentNotification_Fragment = {
  __typename: "AssignmentNotification";
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

type NotificationFragment_ClassPostNotification_Fragment = {
  __typename: "ClassPostNotification";
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

type NotificationFragment_ClubNotification_Fragment = {
  __typename: "ClubNotification";
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

type NotificationFragment_PrivateClassMessageNotification_Fragment = {
  __typename: "PrivateClassMessageNotification";
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

export type NotificationFragmentFragment =
  | NotificationFragment_AssignmentNotification_Fragment
  | NotificationFragment_ClassPostNotification_Fragment
  | NotificationFragment_ClubNotification_Fragment
  | NotificationFragment_PrivateClassMessageNotification_Fragment;

export type PrivateClassMessageNotificationFragmentFragment = {
  __typename: "PrivateClassMessageNotification";
  isClub: boolean;
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  privateClassMessageSectionUid: string;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

export type ClassPostNotificationFragmentFragment = {
  __typename: "ClassPostNotification";
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  classPostNotificationAction: ClassPostNotificationAction;
  classroomClassPostUid: string;
  classroomSectionUid: string;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

export type AssignmentNotificationFragmentFragment = {
  __typename: "AssignmentNotification";
  assignmentUid: string;
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  assignmentNotificationAction: AssignmentNotificationAction;
  assignmentSectionUid: string;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

export type ClubNotificationFragmentFragment = {
  __typename: "ClubNotification";
  classPostCommentUid: string | null;
  threadParentCommentUid: string | null;
  uid: string;
  createdAt: any;
  status: NotificationStatus;
  clubNotificationAction: ClubNotificationAction;
  clubClassPostUid: string;
  clubSectionUid: string;
  sender:
    | {
        __typename?: "LearnerSender";
        uid: string;
        name: string;
        avatar: string | null;
      }
    | {
        __typename?: "UserSender";
        uid: string;
        name: string;
        photo: string | null;
      };
};

export type LearnerNotificationsQueryVariables = Exact<{
  direction: CursorDirection;
  cursor?: InputMaybe<Scalars["DateTime"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  after: Scalars["DateTime"];
  skipHasUnreadNotifications: Scalars["Boolean"];
}>;

export type LearnerNotificationsQuery = {
  __typename?: "Query";
  learnerHasUnreadNotifications?: boolean;
  learnerNotifications: {
    __typename?: "LearnerNotificationConnection";
    pageInfo: {
      __typename?: "LearnerNotificationsPageInfo";
      hasNextPage: boolean;
      direction: CursorDirection;
    };
    results: Array<
      | {
          __typename: "AssignmentNotification";
          assignmentUid: string;
          uid: string;
          createdAt: any;
          status: NotificationStatus;
          assignmentNotificationAction: AssignmentNotificationAction;
          assignmentSectionUid: string;
          sender:
            | {
                __typename?: "LearnerSender";
                uid: string;
                name: string;
                avatar: string | null;
              }
            | {
                __typename?: "UserSender";
                uid: string;
                name: string;
                photo: string | null;
              };
        }
      | {
          __typename: "ClassPostNotification";
          uid: string;
          createdAt: any;
          status: NotificationStatus;
          classPostNotificationAction: ClassPostNotificationAction;
          classroomClassPostUid: string;
          classroomSectionUid: string;
          sender:
            | {
                __typename?: "LearnerSender";
                uid: string;
                name: string;
                avatar: string | null;
              }
            | {
                __typename?: "UserSender";
                uid: string;
                name: string;
                photo: string | null;
              };
        }
      | {
          __typename: "ClubNotification";
          classPostCommentUid: string | null;
          threadParentCommentUid: string | null;
          uid: string;
          createdAt: any;
          status: NotificationStatus;
          clubNotificationAction: ClubNotificationAction;
          clubClassPostUid: string;
          clubSectionUid: string;
          sender:
            | {
                __typename?: "LearnerSender";
                uid: string;
                name: string;
                avatar: string | null;
              }
            | {
                __typename?: "UserSender";
                uid: string;
                name: string;
                photo: string | null;
              };
        }
      | {
          __typename: "PrivateClassMessageNotification";
          isClub: boolean;
          uid: string;
          createdAt: any;
          status: NotificationStatus;
          privateClassMessageSectionUid: string;
          sender:
            | {
                __typename?: "LearnerSender";
                uid: string;
                name: string;
                avatar: string | null;
              }
            | {
                __typename?: "UserSender";
                uid: string;
                name: string;
                photo: string | null;
              };
        }
    >;
  };
};

export type CurrentLearnerPastClassesQueryVariables = Exact<{
  start: Scalars["DateTime"];
  end?: InputMaybe<Scalars["DateTime"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type CurrentLearnerPastClassesQuery = {
  __typename?: "Query";
  currentLearnerPastClasses: {
    __typename?: "PastClasses";
    pastEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      confirmedAt: any | null;
      withdrawnAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        meetings: Array<{ __typename?: "Meeting"; end_time: any }>;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          subject: string | null;
          duration_weeks: number | null;
          summary: string | null;
          weekly_meetings: number | null;
          is_ongoing_weekly: boolean;
          isClub: boolean;
          isFlexSchedule: boolean;
          details: { __typename?: "ActivityDetails"; photo: string | null };
        };
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      } | null;
    }>;
  };
};

export type CurrentLearnerRecommendedTeachersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type CurrentLearnerRecommendedTeachersQuery = {
  __typename?: "Query";
  currentLearnerRecommendedTeachers: {
    __typename?: "RecommendedTeachers";
    recommendationType: TeacherRecommendationType | null;
    teachers: Array<{
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
      };
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    }>;
  };
};

export type CurrentLearnerSavedActivitiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentLearnerSavedActivitiesQuery = {
  __typename?: "Query";
  currentLearner: {
    __typename?: "CurrentLearner";
    uid: string;
    savedActivities: Array<{
      __typename?: "SavedActivity";
      uid: string;
      activity_uid: string;
      created_at: any;
      listing: {
        __typename?: "Listing";
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          slug_id: string;
          price_cents: number | null;
          age_max: number | null;
          age_min: number | null;
          isClub: boolean;
          details: { __typename?: "ActivityDetails"; photo: string | null };
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
          };
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
          videoForLearners: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      };
    }>;
  };
};

export type LearnerSaveActivityMutationVariables = Exact<{
  activityUid: Scalars["ID"];
  referer?: InputMaybe<Scalars["String"]>;
  attribution?: InputMaybe<Attribution>;
}>;

export type LearnerSaveActivityMutation = {
  __typename?: "Mutation";
  saveActivity: {
    __typename?: "SavedActivity";
    uid: string;
    activity_uid: string;
    created_at: any;
    listing: {
      __typename?: "Listing";
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        slug_id: string;
        price_cents: number | null;
        age_max: number | null;
        age_min: number | null;
        isClub: boolean;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
        };
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
        videoForLearners: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    };
  };
};

export type LearnerUnsaveActivityMutationVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type LearnerUnsaveActivityMutation = {
  __typename?: "Mutation";
  unsaveActivity: boolean;
};

export type CurrentLearnerScheduleEventsQueryVariables = Exact<{
  start: Scalars["DateTime"];
  end?: InputMaybe<Scalars["DateTime"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  withPaused?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CurrentLearnerScheduleEventsQuery = {
  __typename?: "Query";
  currentLearnerScheduleEvents: {
    __typename?: "ScheduleEvents";
    events: Array<{
      __typename?: "ScheduleEvent";
      uid: string;
      endTime: any;
      startTime: any;
      section: {
        __typename?: "Section";
        uid: string;
        start_time: any | null;
        end_time: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          subject: string | null;
          duration_weeks: number | null;
          summary: string | null;
          weekly_meetings: number | null;
          is_ongoing_weekly: boolean;
          isClub: boolean;
          isFlexSchedule: boolean;
          details: { __typename?: "ActivityDetails"; photo: string | null };
        };
        leader: { __typename?: "Leader"; uid: string; name: string | null };
      };
      enrollment: {
        __typename?: "Enrollment";
        uid: string;
        isPaused: boolean;
      } | null;
    }>;
  };
};

export type LearnerSimilarListingsByTfIdfQueryVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type LearnerSimilarListingsByTfIdfQuery = {
  __typename?: "Query";
  listing: {
    __typename?: "Listing";
    similarListings: {
      __typename?: "SimilarListings";
      tfidf: Array<{
        __typename?: "Listing";
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          slug_id: string;
          price_cents: number | null;
          age_max: number | null;
          age_min: number | null;
          isClub: boolean;
          details: { __typename?: "ActivityDetails"; photo: string | null };
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
          };
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
          videoForLearners: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  } | null;
};

export type LearnerSinglePostQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  classPostUid: Scalars["ID"];
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type LearnerSinglePostQuery = {
  __typename?: "Query";
  classroom: {
    __typename?: "Classroom";
    uid: string;
    usersCanComment: boolean;
    postByUid: {
      __typename?: "ClassPost";
      uid: string;
      publishAt: any;
      isWelcomePost: boolean;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    } | null;
    mentionNames: Array<{
      __typename?: "MentionName";
      id: string;
      display: string;
      avatar: string | null;
      startTime: any | null;
    }>;
  };
};

export type LearnerSupportTopicsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LearnerSupportTopicsQuery = {
  __typename?: "Query";
  learnerSupportTopics: Array<{
    __typename?: "LearnerSupportTopic";
    title: string;
    description: string;
    type: LearnerSupportType;
    prompt: string | null;
    placeholder: string;
  }>;
};

export type SubmitLearnerSupportIssueMutationVariables = Exact<{
  issueType: LearnerSupportType;
  text?: InputMaybe<Scalars["String"]>;
}>;

export type SubmitLearnerSupportIssueMutation = {
  __typename?: "Mutation";
  submitLearnerSupportIssue: boolean;
};

export type GetLearnerToParentTransferTokenMutationVariables = Exact<{
  password?: InputMaybe<Scalars["String"]>;
}>;

export type GetLearnerToParentTransferTokenMutation = {
  __typename?: "Mutation";
  learnerToParentTransferToken: {
    __typename?: "AuthTransfer";
    transferToken: string | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
  };
};

export type GetLearnerToSiblingTransferTokenMutationVariables = Exact<{
  siblingUid: Scalars["ID"];
}>;

export type GetLearnerToSiblingTransferTokenMutation = {
  __typename?: "Mutation";
  learnerToSiblingTransferToken: {
    __typename?: "AuthTransferToken";
    transferToken: string;
  };
};

export type LearnerPrivateClassMessagesPageQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type LearnerPrivateClassMessagesPageQuery = {
  __typename?: "Query";
  currentLearner: {
    __typename?: "CurrentLearner";
    uid: string;
    name: string | null;
    email: string | null;
    username: string | null;
    age: number | null;
    avatar: string | null;
    unlockedAvatars: Array<string>;
    pronoun: string | null;
    accessLevel: LearnerAccessLevel;
    intro: string | null;
    timeZone: string;
    optedOutOfPersonalizedRecommendationsAt: any | null;
    hasUpcomingScheduleEvent: boolean;
    isLoginEnabled: boolean;
    isVerified: boolean;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    };
    siblings: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      avatar: string | null;
      age: number | null;
    }>;
    enrollmentToReviewSoon: {
      __typename?: "Enrollment";
      uid: string;
      section: {
        __typename?: "Section";
        uid: string;
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          subject: string | null;
          is_ongoing_weekly: boolean;
        };
      } | null;
    } | null;
    experiments: Array<{
      __typename?: "LearnerExperiment";
      name: string;
      variant: string;
    }>;
    interfacePreferences: Array<{
      __typename?: "LearnerInterfacePreference";
      name: string;
      value: any;
    }>;
  };
  section: {
    __typename?: "Section";
    filledSpaceCount: number;
    uid: string;
    activity_uid: string;
    start_time: any | null;
    end_time: any | null;
    startsAtNight: boolean | null;
    is_cross_listed: boolean;
    size_max: number | null;
    size_min: number | null;
    isPublished: boolean | null;
    price_cents: number | null;
    published_at: any | null;
    updated_at: any;
    canceled_at: any | null;
    deleted_at: any | null;
    ongoing_stopped_at: any | null;
    duration_minutes: number | null;
    usesOutschoolVideoChat: boolean;
    usersCanSendPrivateMessage: boolean;
    currentUserCanManage: boolean;
    isBuyable: boolean;
    allowsTeacherTransfer: boolean;
    currentLearnerEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      section_uid: string | null;
      created_at: any | null;
      confirmed_at: any | null;
      confirmedAt: any | null;
      withdrawn_at: any | null;
      withdrawnAt: any | null;
      usersCanCommunicate: boolean;
      details: {
        __typename?: "EnrollmentDetails";
        name: string | null;
        age: string | null;
        attendedAt: any | null;
        message: string | null;
      };
    };
    activity: {
      __typename?: "Activity";
      uid: string;
      slug_id: string;
      user_uid: string;
      title: string;
      class_experience: string | null;
      learning_goals: string | null;
      assessment: string | null;
      homework: string | null;
      prerequisites: string | null;
      published_at: any | null;
      is_online: boolean;
      age_min: number | null;
      age_max: number | null;
      size_min: number | null;
      size_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      summary: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      languageOfInstruction: any | null;
      summaryForLearners: string | null;
      isClub: boolean;
      isAutoSchedulingEnabled: boolean | null;
      autoSchedulingDaysNoticeMin: number | null;
      autoSchedulingDaysNoticeMax: number | null;
      hasTeacherSchedule: boolean;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
        created_at: any | null;
        photo: string | null;
        suspended_at: any | null;
        cityState: string | null;
        socialOptOut: boolean;
        is_crosslister: boolean | null;
        timeZone: string | null;
        hasAvailabilitySet: boolean;
        approved: boolean;
        hasBackgroundCheckExpired: boolean | null;
        details: {
          __typename?: "LeaderDetails";
          about: string | null;
          headline: string | null;
          headlineTranslated: string | null;
          countryOfResidence: string | null;
        };
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        teacherForSellerOrg: {
          __typename?: "SellerOrg";
          uid: string;
          name: string;
        } | null;
      };
      refundPolicy: {
        __typename?: "RefundPolicy";
        name: string;
        description: string;
      };
      details: { __typename?: "ActivityDetails"; photo: string | null };
    };
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
    details: {
      __typename?: "SectionDetails";
      autoScheduledDraft: boolean | null;
      onlineClassroom: {
        __typename?: "OnlineClassroom";
        type: string | null;
        url: string | null;
      } | null;
    };
    prepaidOngoingPeriodOptions: Array<{
      __typename?: "Period";
      startOfIsoWeek: any;
      endOfIsoWeek: any;
    }> | null;
  } | null;
};

export type LearnerSectionFragmentFragment = {
  __typename?: "Section";
  uid: string;
  activity_uid: string;
  start_time: any | null;
  end_time: any | null;
  startsAtNight: boolean | null;
  is_cross_listed: boolean;
  size_max: number | null;
  size_min: number | null;
  isPublished: boolean | null;
  price_cents: number | null;
  published_at: any | null;
  updated_at: any;
  canceled_at: any | null;
  deleted_at: any | null;
  ongoing_stopped_at: any | null;
  duration_minutes: number | null;
  usesOutschoolVideoChat: boolean;
  usersCanSendPrivateMessage: boolean;
  currentUserCanManage: boolean;
  isBuyable: boolean;
  allowsTeacherTransfer: boolean;
  nextOngoingMeeting: {
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
  } | null;
  leader: { __typename?: "Leader"; uid: string; name: string | null };
  details: {
    __typename?: "SectionDetails";
    autoScheduledDraft: boolean | null;
  };
  prepaidOngoingPeriodOptions: Array<{
    __typename?: "Period";
    startOfIsoWeek: any;
    endOfIsoWeek: any;
  }> | null;
};

export type LearnerSearchTeacherActivitiesQueryVariables = Exact<{
  teacherUid: Scalars["ID"];
}>;

export type LearnerSearchTeacherActivitiesQuery = {
  __typename?: "Query";
  learnerSearchTeacherActivities: {
    __typename?: "LearnerSearchTeacherActivities";
    cursor: string | null;
    activities: Array<{
      __typename?: "Activity";
      uid: string;
      title: string;
      slug_id: string;
      price_cents: number | null;
      age_max: number | null;
      age_min: number | null;
      isClub: boolean;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
      };
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      videoForLearners: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    }>;
  };
};

export type LearnerTeacherPublicProfileQueryVariables = Exact<{
  leaderLink?: InputMaybe<Scalars["String"]>;
}>;

export type LearnerTeacherPublicProfileQuery = {
  __typename?: "Query";
  publicProfileNew: {
    __typename?: "PublicProfile";
    uid: string;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      details: { __typename?: "LeaderDetails"; about: string | null };
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
  } | null;
};

export type VerifyLearnerTransferMutationVariables = Exact<{
  token: Scalars["String"];
  isLearnerLogin: Scalars["Boolean"];
}>;

export type VerifyLearnerTransferMutation = {
  __typename?: "Mutation";
  verifyLearnerTransfer:
    | {
        __typename?: "AuthenticationV2";
        refreshToken: string;
        sessionToken: string;
      }
    | { __typename?: "AuthenticationV1"; sessionToken: string };
};

export type LearnerClassPostList_ClassroomFragment = {
  __typename?: "Classroom";
  usersCanPost: boolean;
  usersCanComment: boolean;
  posts: Array<{
    __typename?: "ClassPost";
    uid: string;
    isWelcomePost: boolean;
    publishAt: any;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  }>;
  unpublishedPosts: Array<{
    __typename?: "ClassPost";
    uid: string;
    isWelcomePost: boolean;
    publishAt: any;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  }>;
  activity: {
    __typename?: "Activity";
    user_uid: string;
    leader: {
      __typename?: "Leader";
      uid: string;
      leader_link: string | null;
      name: string | null;
    };
  };
  section: {
    __typename?: "Section";
    currentUserCanManage: boolean;
    uid: string;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      recordingAvailability: RecordingAvailability;
      recordingRequests: Array<{
        __typename?: "RecordingRequest";
        requestedAt: any;
        message: string;
        requestedBy: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
        };
      }>;
      classRecordings: Array<
        | {
            __typename?: "ClassRecording";
            uid: string;
            startTime: any;
            endTime: any;
          }
        | {
            __typename?: "ZoomRecording";
            uid: string;
            startTime: any;
            endTime: any;
          }
      >;
      attendances: Array<{
        __typename?: "Attendance";
        learner: { __typename?: "Learner"; name: string | null };
      }>;
    }>;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
  };
  mentionNames: Array<{
    __typename?: "MentionName";
    id: string;
    display: string;
    avatar: string | null;
    startTime: any | null;
  }>;
};

export type MarkLessonCompleteMutationVariables = Exact<{
  recordingUid: Scalars["ID"];
}>;

export type MarkLessonCompleteMutation = {
  __typename?: "Mutation";
  markLessonCompleteFromClassRecording: boolean;
};

export type MarkLessonCompleteButtonMutationVariables = Exact<{
  lessonUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
}>;

export type MarkLessonCompleteButtonMutation = {
  __typename?: "Mutation";
  markLessonCompleteForLearner: boolean;
};

export type ClassCardContents_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  title: string;
  weekly_meetings: number | null;
  duration_weeks: number | null;
  is_ongoing_weekly: boolean;
  isClub: boolean;
  details: { __typename?: "ActivityDetails"; photo: string | null };
};

export type ClassCardContents_ScheduleEventFragment = {
  __typename?: "ScheduleEvent";
  uid: string;
  startTime: any;
  endTime: any;
  section: { __typename?: "Section"; uid: string };
  enrollment: {
    __typename?: "Enrollment";
    uid: string;
    isPaused: boolean;
  } | null;
};

export type ClubCardContents_ClubFragment = {
  __typename?: "Club";
  uid: string;
  currentLearnerPostCount: number;
  currentLearnerPostReplyCount: number;
  promotedPostCount: number;
  activity: {
    __typename?: "Activity";
    uid: string;
    title: string;
    details: { __typename?: "ActivityDetails"; photo: string | null };
  };
};

export type ListLearnerClubsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  countCommentsAfter?: InputMaybe<Scalars["DateTime"]>;
}>;

export type ListLearnerClubsQuery = {
  __typename?: "Query";
  currentLearnerClubs: Array<{
    __typename?: "Club";
    uid: string;
    currentLearnerPostCount: number;
    currentLearnerPostReplyCount: number;
    promotedPostCount: number;
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      details: { __typename?: "ActivityDetails"; photo: string | null };
    };
  }>;
};

export type EventTime_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  isClub: boolean;
  weekly_meetings: number | null;
  duration_weeks: number | null;
};

export type EventCard_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  title: string;
  isClub: boolean;
  weekly_meetings: number | null;
  duration_weeks: number | null;
  details: { __typename?: "ActivityDetails"; photo: string | null };
};

export type SaveNewLearnerPasswordMutationVariables = Exact<{
  currentPassword: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type SaveNewLearnerPasswordMutation = {
  __typename?: "Mutation";
  changeLoggedInLearnerPassword: boolean;
};

export type ReplyClassInquiry_TestMutationVariables = Exact<{
  teacherUid: Scalars["ID"];
  classInquiryUid: Scalars["ID"];
  relevantActivityUid: Scalars["ID"];
  replyText: Scalars["String"];
}>;

export type ReplyClassInquiry_TestMutation = {
  __typename?: "Mutation";
  replyClassInquiry: {
    __typename?: "ClassInquiryTeacher";
    uid: string;
    reply_message_uid: string;
  };
};

export type GetCouponQueryVariables = Exact<{
  couponID: Scalars["String"];
}>;

export type GetCouponQuery = {
  __typename?: "Query";
  coupon: { __typename?: "Coupon"; name: string; createdBy: string | null };
};

export type ActivityFragmentFragment = {
  __typename?: "Activity";
  uid: string;
  slug_id: string;
  user_uid: string;
  title: string;
  titleTranslated: string | null;
  url: string;
  path: string;
  class_experience: string | null;
  learning_goals: string | null;
  learningGoals: Array<string>;
  assessment: string | null;
  homework: string | null;
  grading_policy: string | null;
  prerequisites: string | null;
  published_at: any | null;
  is_online: boolean;
  age_min: number | null;
  age_max: number | null;
  english_proficiency_level: EnglishProficiencyLevel | null;
  grade_level_min: string | null;
  grade_level_max: string | null;
  size_min: number | null;
  size_max: number | null;
  duration_minutes: number | null;
  duration_weeks: number | null;
  weekly_meetings: number | null;
  price_cents: number | null;
  priceCredits: number | null;
  summary: string | null;
  summaryTranslated: string | null;
  is_ongoing_weekly: boolean;
  subject: string | null;
  languageOfInstruction: any | null;
  summaryForLearners: string | null;
  allowWaitlist: boolean | null;
  isClub: boolean;
  isAutoSchedulingEnabled: boolean | null;
  autoSchedulingDaysNoticeMin: number | null;
  autoSchedulingDaysNoticeMax: number | null;
  allows_recurring_payment: boolean | null;
  allows_late_enrollments_for_fixed_length_classes: boolean;
  has_building_content: boolean | null;
  hasTeacherSchedule: boolean;
  isFlexSchedule: boolean;
  isSelfPaced: boolean;
  isOneTime: boolean;
  classFormat: EducatorSelectedFormat;
  classroomType: ClassroomType;
  strictAgeRange: {
    __typename?: "AgeRangeType";
    min: number;
    max: number;
  } | null;
  proficiency_level: {
    __typename?: "ProficiencyLevel";
    level: Array<string | null> | null;
    text: string | null;
  } | null;
  refundPolicy: {
    __typename?: "RefundPolicy";
    name: string;
    description: string;
    type: RefundPolicyType;
  };
  details: {
    __typename?: "ActivityDetails";
    photo: string | null;
    photoSquare: string | null;
  };
};

export type AddClassPostMutationVariables = Exact<{
  classPostInput: ClassPostInput;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AddClassPostMutation = {
  __typename?: "Mutation";
  addClassPost: {
    __typename?: "ClassPost";
    uid: string;
    isWelcomePost: boolean;
    publishAt: any;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  };
};

export type AddClassPostForLessonMutationVariables = Exact<{
  classPostForLessonInput: ClassPostForLessonInput;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AddClassPostForLessonMutation = {
  __typename?: "Mutation";
  addClassPostForLesson: {
    __typename?: "ClassPost";
    uid: string;
    isWelcomePost: boolean;
    publishAt: any;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  };
};

export type AddAssignmentClassPostMutationVariables = Exact<{
  createAssignmentClassPostInput: CreateAssignmentClassPostInput;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AddAssignmentClassPostMutation = {
  __typename?: "Mutation";
  addAssignmentClassPost: {
    __typename?: "AssignmentClassPost";
    uid: string;
    title: string;
    created_at: any;
    deleted_at: any | null;
    publishAt: any;
    dueDate: any | null;
    submissionRequirement: SubmissionRequirementType;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    lesson: { __typename?: "Lesson"; uid: string; title: string | null } | null;
    lessonPost: {
      __typename?: "AssignmentLessonPost";
      uid: string;
      title: string | null;
      lessonUid: string;
      orderInLesson: number;
      deleted_at: any | null;
      submissionRequirement: SubmissionRequirementType;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    } | null;
  };
};

export type DeleteAssignmentClassPostMutationVariables = Exact<{
  uid: Scalars["ID"];
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type DeleteAssignmentClassPostMutation = {
  __typename?: "Mutation";
  deleteAssignmentClassPost: {
    __typename?: "AssignmentClassPost";
    uid: string;
    title: string;
    created_at: any;
    deleted_at: any | null;
    publishAt: any;
    dueDate: any | null;
    submissionRequirement: SubmissionRequirementType;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    lesson: { __typename?: "Lesson"; uid: string; title: string | null } | null;
    lessonPost: {
      __typename?: "AssignmentLessonPost";
      uid: string;
      title: string | null;
      lessonUid: string;
      orderInLesson: number;
      deleted_at: any | null;
      submissionRequirement: SubmissionRequirementType;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    } | null;
  };
};

export type UpdateAssignmentClassPostMutationVariables = Exact<{
  uid: Scalars["ID"];
  updateAssignmentClassPostInput: UpdateAssignmentClassPostInput;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateAssignmentClassPostMutation = {
  __typename?: "Mutation";
  updateAssignmentClassPost: {
    __typename?: "AssignmentClassPost";
    uid: string;
    title: string;
    created_at: any;
    deleted_at: any | null;
    publishAt: any;
    dueDate: any | null;
    submissionRequirement: SubmissionRequirementType;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    lesson: { __typename?: "Lesson"; uid: string; title: string | null } | null;
    lessonPost: {
      __typename?: "AssignmentLessonPost";
      uid: string;
      title: string | null;
      lessonUid: string;
      orderInLesson: number;
      deleted_at: any | null;
      submissionRequirement: SubmissionRequirementType;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    } | null;
  };
};

export type DeleteClassPostMutationVariables = Exact<{
  classPostUid: Scalars["ID"];
}>;

export type DeleteClassPostMutation = {
  __typename?: "Mutation";
  deleteClassPost: boolean | null;
};

export type RequestPasswordChangeMutationVariables = Exact<{
  emailOrUsername: Scalars["String"];
}>;

export type RequestPasswordChangeMutation = {
  __typename?: "Mutation";
  requestPasswordChange: boolean;
};

export type RequestPinChangeMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type RequestPinChangeMutation = {
  __typename?: "Mutation";
  requestPinChange: boolean;
};

export type VerifyParentTransferMutationVariables = Exact<{
  token: Scalars["String"];
  isParentLogin: Scalars["Boolean"];
}>;

export type VerifyParentTransferMutation = {
  __typename?: "Mutation";
  verifyParentTransfer:
    | {
        __typename?: "AuthenticationV2";
        refreshToken: string;
        sessionToken: string;
      }
    | { __typename?: "AuthenticationV1"; sessionToken: string };
};

export type ClassPostFragmentFragment = {
  __typename?: "ClassPost";
  uid: string;
  isWelcomePost: boolean;
  publishAt: any;
  sender: {
    __typename?: "MessageSender";
    uid: string;
    name: string | null;
    photo: string | null;
    avatar: string | null;
    isLearner: boolean;
  };
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type SearchSuggestionsQueryVariables = Exact<{
  query: Scalars["String"];
  size?: InputMaybe<Scalars["Int"]>;
}>;

export type SearchSuggestionsQuery = {
  __typename?: "Query";
  searchSuggestions: {
    __typename?: "SearchSuggestions";
    topicSuggestions: Array<{
      __typename?: "Topic";
      uid: string;
      label: string;
      category: string | null;
    }>;
    teacherSuggestions: Array<{
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
    }>;
  };
};

export type SectionRecordingsQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type SectionRecordingsQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    classRecordings: Array<
      | {
          __typename?: "ClassRecording";
          uid: string;
          startTime: any;
          endTime: any;
        }
      | {
          __typename?: "ZoomRecording";
          uid: string;
          startTime: any;
          endTime: any;
        }
    >;
  } | null;
};

export type CurrentUserAttachmentFragmentFragment = {
  __typename?: "Attachment";
  uid: string;
  conversionStatus: string | null;
  created_at: any;
  file: {
    __typename?: "File";
    filename: string;
    mimetype: string | null;
    size: number;
    url: string;
    thumbUrl: string | null;
  };
};

export type CurrentUserTeacherApplicationFragmentFragment = {
  __typename?: "TeacherApplication";
  uid: string;
  legal_name: string | null;
  status: TeacherApplicationStatus;
  submitted_at: any | null;
  reviewed_at: any | null;
  teaching_experience: string | null;
  teaching_credentials: string | null;
  class_types: string | null;
  online_profiles: string | null;
  sample_classes: string | null;
  class_subjects: Array<string | null> | null;
  age_ranges_taught: Array<string | null> | null;
  years_of_experience: string | null;
  how_did_you_hear_about_us: string | null;
  experience_types: Array<string | null> | null;
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
};

export type ChildFragmentFragment = {
  __typename?: "Child";
  uid: string;
  name: string | null;
  age: number | null;
  birthMonth: number | null;
  date_of_birth: any | null;
  birthYear: number | null;
  email: string | null;
  avatar: string | null;
  unlockedAvatars: Array<string>;
  pronoun: string | null;
  intro: string | null;
  deleted_at: any | null;
  email_confirmed_at: any | null;
  optedOutOfPersonalizedRecommendationsAt: any | null;
  isLoginEnabled: boolean;
  username: string | null;
  hasPassword: boolean;
  attributes: Array<{
    __typename?: "EntityAttribute";
    uid: string;
    key: string;
    value: string;
  }>;
  entityAvailabilitySchedule: {
    __typename?: "EntityAvailabilitySchedule";
    entityAvailabilities: Array<{
      __typename?: "EntityAvailability";
      startHour: number;
      startMinute: number;
      day: number;
      endHour: number;
      endMinute: number;
      uid: string;
    }>;
  } | null;
};

export type CurrentUserChildFragmentFragment = {
  __typename?: "Child";
  uid: string;
  name: string | null;
  age: number | null;
  birthMonth: number | null;
  date_of_birth: any | null;
  birthYear: number | null;
  email: string | null;
  avatar: string | null;
  unlockedAvatars: Array<string>;
  pronoun: string | null;
  intro: string | null;
  deleted_at: any | null;
  email_confirmed_at: any | null;
  optedOutOfPersonalizedRecommendationsAt: any | null;
  isLoginEnabled: boolean;
  username: string | null;
  hasPassword: boolean;
  attributes: Array<{
    __typename?: "EntityAttribute";
    uid: string;
    key: string;
    value: string;
  }>;
  entityAvailabilitySchedule: {
    __typename?: "EntityAvailabilitySchedule";
    entityAvailabilities: Array<{
      __typename?: "EntityAvailability";
      startHour: number;
      startMinute: number;
      day: number;
      endHour: number;
      endMinute: number;
      uid: string;
    }>;
  } | null;
};

export type PricingOfferFragmentFragment = {
  __typename?: "PricingOffer";
  uid: string;
  name: string;
  userLimitCents: number;
  purchaseLimitCents: number | null;
  totalCapCents: number;
  startAfter: any;
  endBy: any;
  buyerOrgName: string | null;
  isTotalCapReached: boolean;
  remainingCapCents: number | null;
  requirePaymentMethod: boolean;
  isOfferLimitReached: boolean;
  userOfferEmail: string | null;
  discount: number | null;
  isFinancialAid: boolean;
};

export type DefaultScheduleFiltersFragmentFragment = {
  __typename?: "UserScheduleFilters";
  startAfter: string | null;
  endBy: string | null;
  startAfterTime: number | null;
  endByTime: number | null;
  dow: string | null;
};

export type InterfacePreferencesFragmentFragment = {
  __typename?: "InterfacePreferences";
  activityFormatDescriptionFlexHidden: boolean;
  activityFormatDescriptionOngoingHidden: boolean;
  activityFormatDescriptionOneTimeHidden: boolean;
  activityFormatDescriptionMultiDayHidden: boolean;
  defaultScheduleFilters: {
    __typename?: "UserScheduleFilters";
    startAfter: string | null;
    endBy: string | null;
    startAfterTime: number | null;
    endByTime: number | null;
    dow: string | null;
  };
};

export type FullCurrentUserFragmentFragment = {
  __typename?: "User";
  uid: string;
  slug_id: string;
  name: string | null;
  email: string | null;
  isLearnerModeEnabled: boolean | null;
  confirmed_at: any | null;
  subscribed_at: any | null;
  email_bounced_at: any | null;
  default_currency: string | null;
  phone: string | null;
  photo: string | null;
  leader_subscribed_at: any | null;
  calendar_uid: string;
  intercomUserHash: string | null;
  payout_email: string | null;
  owner_of_seller_org_uid: string | null;
  admin_for_organization_uid: string | null;
  do_not_sell_my_personal_info: boolean;
  background_checked_at: any | null;
  background_check_started_at: any | null;
  interview_scheduled_at: any | null;
  interviewed_at: any | null;
  classroom_training_scheduled_at: any | null;
  classroom_trained_at: any | null;
  latestLegalName: string | null;
  hasCompletedClassContentPoliciesTraining: boolean;
  hasCompletedSafetyAndPrivacyTraining: boolean;
  hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
  hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
  teacher_approved_at: any | null;
  is_crosslister: boolean | null;
  introduction: string | null;
  created_at: any;
  updated_at: any;
  deleted_at: any | null;
  suspended_at: any | null;
  is_admin: boolean | null;
  learner_uses_parent_mode: boolean;
  leader_link: string | null;
  unreadMessageThreadsCount: number;
  unreadPrivateClassMessageThreadsCount: number;
  roles: Array<string> | null;
  locale: UserLocale;
  isRecordingAutopostEnabled: boolean | null;
  learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
  anonymousId: string | null;
  wasReferred: boolean;
  isEducatorBetaTester: boolean | null;
  consented_to_coppa_notice_at: any | null;
  hasVerifiedIdentity: boolean | null;
  consent_to_share_class_history: boolean | null;
  consent_to_share_favourites: boolean | null;
  privacy_state: string | null;
  profile_link_uid: string | null;
  hasSubscription: boolean;
  canPayWithSubscriptionCredits: boolean;
  upgradablePackages: Array<number | null> | null;
  otherEmailDetails: Array<{
    __typename?: "OtherEmailDetails";
    uid: string;
    email: string;
    confirmed_at: any | null;
  } | null> | null;
  firstPaidEnrollment: {
    __typename?: "Enrollment";
    uid: string;
    confirmed_at: any | null;
  } | null;
  details: {
    __typename?: "UserDetails";
    about: string | null;
    headline: string | null;
    countryOfResidence: string | null;
    introduction: string | null;
    browserTimeZone: string | null;
    timeRangeAfterSchool: boolean | null;
    timeRangeSchool: boolean | null;
    timeRangeWeekend: boolean | null;
    socialOptOut: boolean | null;
    howFoundOutschool: string | null;
    howFoundOutschoolOther: string | null;
    educationApproach: string | null;
    adminDashboardUrl: string | null;
    experiments: Array<string | null> | null;
  };
  location: {
    __typename?: "Location";
    address: string | null;
    city: string | null;
    lat: number | null;
    lng: number | null;
    timeZone: string | null;
    state: string | null;
  } | null;
  organizationAdmin: {
    __typename?: "OrganizationAdmin";
    organization: {
      __typename?: "Organization";
      uid: string;
      name: string;
      type: OrganizationType;
    };
  } | null;
  children: Array<{
    __typename?: "Child";
    uid: string;
    name: string | null;
    age: number | null;
    birthMonth: number | null;
    date_of_birth: any | null;
    birthYear: number | null;
    email: string | null;
    avatar: string | null;
    unlockedAvatars: Array<string>;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    email_confirmed_at: any | null;
    optedOutOfPersonalizedRecommendationsAt: any | null;
    isLoginEnabled: boolean;
    username: string | null;
    hasPassword: boolean;
    attributes: Array<{
      __typename?: "EntityAttribute";
      uid: string;
      key: string;
      value: string;
    }>;
    entityAvailabilitySchedule: {
      __typename?: "EntityAvailabilitySchedule";
      entityAvailabilities: Array<{
        __typename?: "EntityAvailability";
        startHour: number;
        startMinute: number;
        day: number;
        endHour: number;
        endMinute: number;
        uid: string;
      }>;
    } | null;
  }>;
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
  interfacePreferences: {
    __typename?: "InterfacePreferences";
    activityFormatDescriptionFlexHidden: boolean;
    activityFormatDescriptionOngoingHidden: boolean;
    activityFormatDescriptionOneTimeHidden: boolean;
    activityFormatDescriptionMultiDayHidden: boolean;
    defaultScheduleFilters: {
      __typename?: "UserScheduleFilters";
      startAfter: string | null;
      endBy: string | null;
      startAfterTime: number | null;
      endByTime: number | null;
      dow: string | null;
    };
  };
  schoolClosure: {
    __typename?: "SchoolClosure";
    application_status: FfapApplicationStatus | null;
    needs_assistance: boolean | null;
  } | null;
  pricingOffer: {
    __typename?: "PricingOffer";
    uid: string;
    name: string;
    userLimitCents: number;
    purchaseLimitCents: number | null;
    totalCapCents: number;
    startAfter: any;
    endBy: any;
    buyerOrgName: string | null;
    isTotalCapReached: boolean;
    remainingCapCents: number | null;
    requirePaymentMethod: boolean;
    isOfferLimitReached: boolean;
    userOfferEmail: string | null;
    discount: number | null;
    isFinancialAid: boolean;
  } | null;
  sellerOrg: {
    __typename?: "SellerOrg";
    uid: string;
    name: string;
    leader_link: string | null;
    currentUserIsOwner: boolean | null;
    currentUserIsTeacher: boolean | null;
    hasApprovedTeacherAccountForOwner: boolean | null;
  } | null;
  latestSellerOrgApplication: {
    __typename?: "SellerOrgApplication";
    uid: string;
    status: SellerOrgApplicationStatus;
    approvedAt: any | null;
    rejectedAt: any | null;
    contractSignedAt: any | null;
    adminResponse: string | null;
  } | null;
  latestTeacherApplication: {
    __typename?: "TeacherApplication";
    uid: string;
    legal_name: string | null;
    status: TeacherApplicationStatus;
    submitted_at: any | null;
    reviewed_at: any | null;
    teaching_experience: string | null;
    teaching_credentials: string | null;
    class_types: string | null;
    online_profiles: string | null;
    sample_classes: string | null;
    class_subjects: Array<string | null> | null;
    age_ranges_taught: Array<string | null> | null;
    years_of_experience: string | null;
    how_did_you_hear_about_us: string | null;
    experience_types: Array<string | null> | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  } | null;
  leader: {
    __typename?: "Leader";
    uid: string;
    showCalendar: boolean | null;
    mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
  } | null;
};

export type CurrentUserQueryQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    slug_id: string;
    name: string | null;
    email: string | null;
    isLearnerModeEnabled: boolean | null;
    confirmed_at: any | null;
    subscribed_at: any | null;
    email_bounced_at: any | null;
    default_currency: string | null;
    phone: string | null;
    photo: string | null;
    leader_subscribed_at: any | null;
    calendar_uid: string;
    intercomUserHash: string | null;
    payout_email: string | null;
    owner_of_seller_org_uid: string | null;
    admin_for_organization_uid: string | null;
    do_not_sell_my_personal_info: boolean;
    background_checked_at: any | null;
    background_check_started_at: any | null;
    interview_scheduled_at: any | null;
    interviewed_at: any | null;
    classroom_training_scheduled_at: any | null;
    classroom_trained_at: any | null;
    latestLegalName: string | null;
    hasCompletedClassContentPoliciesTraining: boolean;
    hasCompletedSafetyAndPrivacyTraining: boolean;
    hasCompletedClassContentPoliciesOrgAdminTraining: boolean;
    hasCompletedSafetyAndPrivacyOrgAdminTraining: boolean;
    teacher_approved_at: any | null;
    is_crosslister: boolean | null;
    introduction: string | null;
    created_at: any;
    updated_at: any;
    deleted_at: any | null;
    suspended_at: any | null;
    is_admin: boolean | null;
    learner_uses_parent_mode: boolean;
    leader_link: string | null;
    unreadMessageThreadsCount: number;
    unreadPrivateClassMessageThreadsCount: number;
    roles: Array<string> | null;
    locale: UserLocale;
    isRecordingAutopostEnabled: boolean | null;
    learnerModeSwitchAuthRequirement: LearnerModeSwitchAuthRequirement | null;
    anonymousId: string | null;
    wasReferred: boolean;
    isEducatorBetaTester: boolean | null;
    consented_to_coppa_notice_at: any | null;
    hasVerifiedIdentity: boolean | null;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
    privacy_state: string | null;
    profile_link_uid: string | null;
    hasSubscription: boolean;
    canPayWithSubscriptionCredits: boolean;
    upgradablePackages: Array<number | null> | null;
    otherEmailDetails: Array<{
      __typename?: "OtherEmailDetails";
      uid: string;
      email: string;
      confirmed_at: any | null;
    } | null> | null;
    firstPaidEnrollment: {
      __typename?: "Enrollment";
      uid: string;
      confirmed_at: any | null;
    } | null;
    details: {
      __typename?: "UserDetails";
      about: string | null;
      headline: string | null;
      countryOfResidence: string | null;
      introduction: string | null;
      browserTimeZone: string | null;
      timeRangeAfterSchool: boolean | null;
      timeRangeSchool: boolean | null;
      timeRangeWeekend: boolean | null;
      socialOptOut: boolean | null;
      howFoundOutschool: string | null;
      howFoundOutschoolOther: string | null;
      educationApproach: string | null;
      adminDashboardUrl: string | null;
      experiments: Array<string | null> | null;
    };
    location: {
      __typename?: "Location";
      address: string | null;
      city: string | null;
      lat: number | null;
      lng: number | null;
      timeZone: string | null;
      state: string | null;
    } | null;
    organizationAdmin: {
      __typename?: "OrganizationAdmin";
      organization: {
        __typename?: "Organization";
        uid: string;
        name: string;
        type: OrganizationType;
      };
    } | null;
    children: Array<{
      __typename?: "Child";
      uid: string;
      name: string | null;
      age: number | null;
      birthMonth: number | null;
      date_of_birth: any | null;
      birthYear: number | null;
      email: string | null;
      avatar: string | null;
      unlockedAvatars: Array<string>;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      email_confirmed_at: any | null;
      optedOutOfPersonalizedRecommendationsAt: any | null;
      isLoginEnabled: boolean;
      username: string | null;
      hasPassword: boolean;
      attributes: Array<{
        __typename?: "EntityAttribute";
        uid: string;
        key: string;
        value: string;
      }>;
      entityAvailabilitySchedule: {
        __typename?: "EntityAvailabilitySchedule";
        entityAvailabilities: Array<{
          __typename?: "EntityAvailability";
          startHour: number;
          startMinute: number;
          day: number;
          endHour: number;
          endMinute: number;
          uid: string;
        }>;
      } | null;
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    interfacePreferences: {
      __typename?: "InterfacePreferences";
      activityFormatDescriptionFlexHidden: boolean;
      activityFormatDescriptionOngoingHidden: boolean;
      activityFormatDescriptionOneTimeHidden: boolean;
      activityFormatDescriptionMultiDayHidden: boolean;
      defaultScheduleFilters: {
        __typename?: "UserScheduleFilters";
        startAfter: string | null;
        endBy: string | null;
        startAfterTime: number | null;
        endByTime: number | null;
        dow: string | null;
      };
    };
    schoolClosure: {
      __typename?: "SchoolClosure";
      application_status: FfapApplicationStatus | null;
      needs_assistance: boolean | null;
    } | null;
    pricingOffer: {
      __typename?: "PricingOffer";
      uid: string;
      name: string;
      userLimitCents: number;
      purchaseLimitCents: number | null;
      totalCapCents: number;
      startAfter: any;
      endBy: any;
      buyerOrgName: string | null;
      isTotalCapReached: boolean;
      remainingCapCents: number | null;
      requirePaymentMethod: boolean;
      isOfferLimitReached: boolean;
      userOfferEmail: string | null;
      discount: number | null;
      isFinancialAid: boolean;
    } | null;
    sellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
      leader_link: string | null;
      currentUserIsOwner: boolean | null;
      currentUserIsTeacher: boolean | null;
      hasApprovedTeacherAccountForOwner: boolean | null;
    } | null;
    latestSellerOrgApplication: {
      __typename?: "SellerOrgApplication";
      uid: string;
      status: SellerOrgApplicationStatus;
      approvedAt: any | null;
      rejectedAt: any | null;
      contractSignedAt: any | null;
      adminResponse: string | null;
    } | null;
    latestTeacherApplication: {
      __typename?: "TeacherApplication";
      uid: string;
      legal_name: string | null;
      status: TeacherApplicationStatus;
      submitted_at: any | null;
      reviewed_at: any | null;
      teaching_experience: string | null;
      teaching_credentials: string | null;
      class_types: string | null;
      online_profiles: string | null;
      sample_classes: string | null;
      class_subjects: Array<string | null> | null;
      age_ranges_taught: Array<string | null> | null;
      years_of_experience: string | null;
      how_did_you_hear_about_us: string | null;
      experience_types: Array<string | null> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      showCalendar: boolean | null;
      mainToolbarPinnedTabs: Array<MainToolbarPinnedTabs> | null;
    } | null;
  } | null;
};

type LoginErrorOrResultFragment_LoginError_Fragment = {
  __typename: "LoginError";
  error: CreateOrLoginError;
};

type LoginErrorOrResultFragment_LoginResult_Fragment = {
  __typename: "LoginResult";
  isNewUser: boolean;
  authentication: { __typename?: "AuthenticationV1"; sessionToken: string };
};

export type LoginErrorOrResultFragmentFragment =
  | LoginErrorOrResultFragment_LoginError_Fragment
  | LoginErrorOrResultFragment_LoginResult_Fragment;

type LoginErrorOrResultFragmentV2_LoginError_Fragment = {
  __typename: "LoginError";
  error: CreateOrLoginError;
};

type LoginErrorOrResultFragmentV2_LoginResultV2_Fragment = {
  __typename: "LoginResultV2";
  isNewUser: boolean;
  authentication: {
    __typename?: "AuthenticationV2";
    sessionToken: string;
    refreshToken: string;
  };
};

export type LoginErrorOrResultFragmentV2Fragment =
  | LoginErrorOrResultFragmentV2_LoginError_Fragment
  | LoginErrorOrResultFragmentV2_LoginResultV2_Fragment;

export type LoginOrCreateAccountWithAppleV2MutationVariables = Exact<{
  code: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  createOptions?: InputMaybe<CreateAccountWithProviderInput>;
  osRef?: InputMaybe<OsRefInput>;
}>;

export type LoginOrCreateAccountWithAppleV2Mutation = {
  __typename?: "Mutation";
  loginOrCreateAccountWithAppleV2:
    | { __typename: "LoginError"; error: CreateOrLoginError }
    | {
        __typename: "LoginResultV2";
        isNewUser: boolean;
        authentication: {
          __typename?: "AuthenticationV2";
          sessionToken: string;
          refreshToken: string;
        };
      };
};

export type LoginWithGoogleOneTapMutationVariables = Exact<{
  idToken: Scalars["String"];
  createOptions?: InputMaybe<CreateAccountWithProviderInput>;
  osRef?: InputMaybe<OsRefInput>;
}>;

export type LoginWithGoogleOneTapMutation = {
  __typename?: "Mutation";
  loginOrCreateAccountWithGoogle:
    | { __typename: "LoginError"; error: CreateOrLoginError }
    | {
        __typename: "LoginResult";
        isNewUser: boolean;
        authentication: {
          __typename?: "AuthenticationV1";
          sessionToken: string;
        };
      };
};

export type LoginWithGoogleOneTapV2MutationVariables = Exact<{
  idToken: Scalars["String"];
  createOptions?: InputMaybe<CreateAccountWithProviderInput>;
  osRef?: InputMaybe<OsRefInput>;
}>;

export type LoginWithGoogleOneTapV2Mutation = {
  __typename?: "Mutation";
  loginOrCreateAccountWithGoogleV2:
    | { __typename: "LoginError"; error: CreateOrLoginError }
    | {
        __typename: "LoginResultV2";
        isNewUser: boolean;
        authentication: {
          __typename?: "AuthenticationV2";
          sessionToken: string;
          refreshToken: string;
        };
      };
};

export type SessionRefreshV2MutationVariables = Exact<{
  refreshToken?: InputMaybe<Scalars["String"]>;
  browserTimeZone?: InputMaybe<Scalars["String"]>;
  doNotTrack?: InputMaybe<Scalars["Boolean"]>;
  refreshSource?: InputMaybe<TokenRefreshSource>;
}>;

export type SessionRefreshV2Mutation = {
  __typename?: "Mutation";
  refreshV2: {
    __typename: "AuthenticationV2";
    refreshToken: string;
    sessionToken: string;
  };
};

export type SignupModalQueryVariables = Exact<{
  activityUid?: InputMaybe<Scalars["ID"]>;
  activitySlugId?: InputMaybe<Scalars["String"]>;
  skipActivity: Scalars["Boolean"];
  referrerSlugId?: InputMaybe<Scalars["String"]>;
  skipReferrer: Scalars["Boolean"];
  leaderUid?: InputMaybe<Scalars["ID"]>;
  leaderLink?: InputMaybe<Scalars["String"]>;
  skipLeader: Scalars["Boolean"];
}>;

export type SignupModalQuery = {
  __typename?: "Query";
  activity?: {
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    user_uid: string;
    title: string;
    url: string;
    path: string;
    class_experience: string | null;
    learning_goals: string | null;
    assessment: string | null;
    homework: string | null;
    prerequisites: string | null;
    published_at: any | null;
    is_online: boolean;
    age_min: number | null;
    age_max: number | null;
    size_min: number | null;
    size_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    summary: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    languageOfInstruction: any | null;
    summaryForLearners: string | null;
    isClub: boolean;
    isAutoSchedulingEnabled: boolean | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    hasTeacherSchedule: boolean;
    allows_recurring_payment: boolean | null;
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      description: string;
    };
    details: { __typename?: "ActivityDetails"; photo: string | null };
  };
  leader: {
    __typename?: "PublicProfile";
    uid: string;
    leader: { __typename?: "Leader"; name: string | null } | null;
  } | null;
  referrer: {
    __typename?: "PublicProfile";
    uid: string;
    parent: {
      __typename?: "Parent";
      uid: string;
      name: string | null;
      photo: string | null;
      publicName: string | null;
      introduction: string | null;
      created_at: any | null;
      deleted_at: any | null;
      timeZone: string | null;
      socialOptOut: boolean;
    } | null;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      leader_link: string | null;
      created_at: any | null;
      photo: string | null;
      cityState: string | null;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      socialOptOut: boolean;
      hasBackgroundCheckExpired: boolean | null;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
    } | null;
  } | null;
};

export type ListingPhoto_ActivityFragment = {
  __typename?: "Activity";
  isClub: boolean;
  details: { __typename?: "ActivityDetails"; photo: string | null };
  clubSection: {
    __typename?: "Section";
    filledSpaceCount: number;
    clubPostCount: number;
  } | null;
};

export type TeacherMeetingRecapEnabledSettingQueryVariables = Exact<{
  [key: string]: never;
}>;

export type TeacherMeetingRecapEnabledSettingQuery = {
  __typename?: "Query";
  teacherMarketingSettings: {
    __typename?: "TeacherMarketingSettings";
    ai_meeting_summary_is_on: boolean;
  } | null;
};

export type GetMeetingRecapQueryVariables = Exact<{
  meetingUid: Scalars["ID"];
}>;

export type GetMeetingRecapQuery = {
  __typename?: "Query";
  meetingAiSummary: {
    __typename?: "MeetingAiSummary";
    meeting_uid: string;
    ai_summary_text: string;
    edited_summary_text: string | null;
    is_skipped: boolean;
    sent_at: any | null;
    deleted_at: any | null;
  } | null;
};

export type UpdateMeetingRecapMutationVariables = Exact<{
  meetingUid: Scalars["ID"];
  input: MeetingAiSummaryPatchInput;
}>;

export type UpdateMeetingRecapMutation = {
  __typename?: "Mutation";
  updateMeetingAiSummary: {
    __typename?: "MeetingAiSummary";
    edited_summary_text: string | null;
    is_skipped: boolean;
  } | null;
};

export type ClassRecordingStatusQueryVariables = Exact<{
  recordingUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
}>;

export type ClassRecordingStatusQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    classRecording:
      | {
          __typename?: "ClassRecording";
          readyToPlay: boolean | null;
          isExpired: boolean;
          uid: string;
          playUrl: string | null;
        }
      | { __typename?: "ZoomRecording"; uid: string; playUrl: string };
  } | null;
};

export type AssignmentCommentFragment = {
  __typename?: "AssignmentClassPostComment";
  uid: string;
  sentAt: any;
  threadForLearnerUid: string;
  isSubmission: boolean | null;
  sender: {
    __typename?: "MessageSender";
    uid: string;
    name: string | null;
    photo: string | null;
    avatar: string | null;
    isLearner: boolean;
  };
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type AssignmentCommentThreadFragment = {
  __typename?: "AssignmentClassPostComment";
  uid: string;
  sentAt: any;
  threadForLearnerUid: string;
  isSubmission: boolean | null;
  replies: Array<{
    __typename?: "AssignmentClassPostComment";
    uid: string;
    sentAt: any;
    threadForLearnerUid: string;
    isSubmission: boolean | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  }> | null;
  sender: {
    __typename?: "MessageSender";
    uid: string;
    name: string | null;
    photo: string | null;
    avatar: string | null;
    isLearner: boolean;
  };
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type AssignmentSummaryAssignmentTitleFragment = {
  __typename?: "AssignmentClassPost";
  title: string;
  publishAt: any;
  submissionRequirement: SubmissionRequirementType;
  dueDate: any | null;
  lesson: {
    __typename?: "Lesson";
    uid: string;
    lessonNumber: number | null;
  } | null;
};

export type AssignmentSummaryAssignmentDescriptionFragment = {
  __typename?: "AssignmentClassPost";
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type AssignmentSummaryCardAssignmentFragment = {
  __typename?: "AssignmentClassPost";
  uid: string;
  title: string;
  publishAt: any;
  submissionRequirement: SubmissionRequirementType;
  dueDate: any | null;
  lesson: {
    __typename?: "Lesson";
    uid: string;
    lessonNumber: number | null;
  } | null;
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type AssignmentTrackerAssignmentFragment = {
  __typename?: "AssignmentClassPost";
  uid: string;
  title: string;
  submissionRequirement: SubmissionRequirementType;
  dueDate: any | null;
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
  comments: Array<{
    __typename?: "AssignmentClassPostComment";
    uid: string;
    sentAt: any;
    isSubmission: boolean | null;
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  }>;
};

export type AssignmentTrackerSyllabusFragment = {
  __typename?: "Syllabus";
  uid: string;
  published_at: string | null;
  lessons: Array<{
    __typename?: "Lesson";
    uid: string;
    lessonNumber: number | null;
    assignmentClassPosts: Array<{
      __typename?: "AssignmentClassPost";
      uid: string;
      title: string;
      submissionRequirement: SubmissionRequirementType;
      dueDate: any | null;
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      comments: Array<{
        __typename?: "AssignmentClassPostComment";
        uid: string;
        sentAt: any;
        isSubmission: boolean | null;
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    }>;
  }>;
};

export type CurrentLearnerAvatarFragmentFragment = {
  __typename: "CurrentLearner";
  avatar: string | null;
};

export type ActivityOverview_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  completedEnrollmentCount: number;
  reviewCount: number;
  averageStarRating: number | null;
  isClub: boolean;
  title: string;
};

export type ActivityOverview_LeaderFragment = {
  __typename?: "Leader";
  uid: string;
  name: string | null;
  suspended_at: any | null;
  teacher_approved_at: any | null;
  reviewCount: number;
  averageActivityStarRating: number | null;
  photo: string | null;
  leader_link: string | null;
  educatorTiers: Array<EducatorTier>;
  details: {
    __typename?: "LeaderDetails";
    headline: string | null;
    about: string | null;
  };
  video: {
    __typename?: "Attachment";
    file: { __typename?: "File"; url: string; thumbUrl: string | null };
  } | null;
  credentials: Array<{
    __typename?: "TeacherCredential";
    uid: string;
    degreeType: DegreeType | null;
    degreeSubject: DegreeSubject | null;
    certificateDiscipline: TeacherCertificateDiscipline | null;
    otherType: string | null;
    certificateState: TeacherCertificateState | null;
    name: string | null;
    degreeInstitution: string | null;
    degreeAdditionalInfo: string | null;
  }>;
  ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
};

export type AttachmentFragmentFragment = {
  __typename?: "Attachment";
  uid: string;
  conversionStatus: string | null;
  created_at: any;
  file: {
    __typename?: "File";
    filename: string;
    mimetype: string | null;
    size: number;
    url: string;
    thumbUrl: string | null;
  };
};

export type AttendanceFragmentFragment = {
  __typename?: "Attendance";
  uid: string;
  created_at: any;
};

export type MeetingPost_ActivityFragment = {
  __typename?: "Activity";
  leader: { __typename?: "Leader"; uid: string; name: string | null };
};

export type MeetingPost_SectionFragment = {
  __typename?: "Section";
  uid: string;
  leader: { __typename?: "Leader"; uid: string; name: string | null };
};

export type MeetingPost_MeetingFragment = {
  __typename?: "Meeting";
  uid: string;
  start_time: any;
  end_time: any;
  recordingAvailability: RecordingAvailability;
  recordingRequests: Array<{
    __typename?: "RecordingRequest";
    requestedAt: any;
    message: string;
    requestedBy: { __typename?: "Learner"; uid: string; name: string | null };
  }>;
  classRecordings: Array<
    | {
        __typename?: "ClassRecording";
        uid: string;
        startTime: any;
        endTime: any;
      }
    | {
        __typename?: "ZoomRecording";
        uid: string;
        startTime: any;
        endTime: any;
      }
  >;
  attendances: Array<{
    __typename?: "Attendance";
    learner: { __typename?: "Learner"; name: string | null };
  }>;
};

export type MeetingPost_LearnerFragment = {
  __typename?: "Learner";
  uid: string;
};

export type ClassPost_AttachmentFragment = {
  __typename?: "Attachment";
  uid: string;
  conversionStatus: string | null;
  created_at: any;
  file: {
    __typename?: "File";
    filename: string;
    mimetype: string | null;
    size: number;
    url: string;
    thumbUrl: string | null;
  };
};

export type ClassPost_SenderFragment = {
  __typename?: "MessageSender";
  uid: string;
  name: string | null;
  photo: string | null;
  avatar: string | null;
  isLearner: boolean;
};

export type ClassPost_MessageContentFragment = {
  __typename?: "MessageContent";
  uid: string;
  text: string | null;
  originalMimeType: TextMimeType;
  editedAt: any | null;
  htmlText: string | null;
  attachments: Array<{
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  }>;
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
};

export type ClassPost_CommentFragment = {
  __typename?: "ClassPostComment";
  uid: string;
  sendAt: any;
  mentionedEnrollmentUids: Array<string>;
  mentionedTeacherUid: string | null;
  backgroundColor: string | null;
  sender: {
    __typename?: "MessageSender";
    uid: string;
    name: string | null;
    photo: string | null;
    avatar: string | null;
    isLearner: boolean;
  };
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type ClassPost_ClassPostWithOnlyCommentsFragment = {
  __typename?: "ClassPost";
  uid: string;
  comments: Array<{
    __typename?: "ClassPostComment";
    uid: string;
    sendAt: any;
    mentionedEnrollmentUids: Array<string>;
    mentionedTeacherUid: string | null;
    backgroundColor: string | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  }>;
};

export type ClassPost_ClassPostWithCommentsFragment = {
  __typename?: "ClassPost";
  publishAt: any;
  isWelcomePost: boolean;
  uid: string;
  sender: {
    __typename?: "MessageSender";
    uid: string;
    name: string | null;
    photo: string | null;
    avatar: string | null;
    isLearner: boolean;
  };
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
  comments: Array<{
    __typename?: "ClassPostComment";
    uid: string;
    sendAt: any;
    mentionedEnrollmentUids: Array<string>;
    mentionedTeacherUid: string | null;
    backgroundColor: string | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  }>;
};

export type ClassPost_LearnerFragment = {
  __typename?: "Learner";
  uid: string;
  name: string | null;
};

export type ClassPost_ClassPostFragment = {
  __typename?: "ClassPost";
  uid: string;
  isWelcomePost: boolean;
  publishAt: any;
  title: string | null;
  promotedAt: any | null;
  pinnedAt: any | null;
  mentionedEnrollmentUids: Array<string>;
  backgroundColor: string | null;
  score: number | null;
  sender: {
    __typename?: "MessageSender";
    uid: string;
    name: string | null;
    photo: string | null;
    avatar: string | null;
    isLearner: boolean;
  };
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
  commentsPreview: {
    __typename?: "CommentsPreview";
    remainingCommentsCount: number;
    comments: Array<{
      __typename?: "ClassPostComment";
      uid: string;
      sendAt: any;
      mentionedEnrollmentUids: Array<string>;
      mentionedTeacherUid: string | null;
      backgroundColor: string | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
    }>;
  };
};

export type ClassPost_ActivityFragment = {
  __typename?: "Activity";
  user_uid: string;
  leader: { __typename?: "Leader"; uid: string; leader_link: string | null };
};

export type ClassPost_SectionFragment = {
  __typename?: "Section";
  uid: string;
  currentUserCanManage: boolean;
  leader: { __typename?: "Leader"; uid: string };
};

export type ClassPost_MentionNamesFragment = {
  __typename?: "MentionName";
  id: string;
  display: string;
  avatar: string | null;
  startTime: any | null;
};

export type ClassPostList_MeetingFragment = {
  __typename?: "Meeting";
  uid: string;
  start_time: any;
  end_time: any;
  recordingAvailability: RecordingAvailability;
  recordingRequests: Array<{
    __typename?: "RecordingRequest";
    requestedAt: any;
    message: string;
    requestedBy: { __typename?: "Learner"; uid: string; name: string | null };
  }>;
  classRecordings: Array<
    | {
        __typename?: "ClassRecording";
        uid: string;
        startTime: any;
        endTime: any;
      }
    | {
        __typename?: "ZoomRecording";
        uid: string;
        startTime: any;
        endTime: any;
      }
  >;
  attendances: Array<{
    __typename?: "Attendance";
    learner: { __typename?: "Learner"; name: string | null };
  }>;
};

export type ClassPostList_ClassroomFragment = {
  __typename?: "Classroom";
  usersCanPost: boolean;
  usersCanComment: boolean;
  posts: Array<{
    __typename?: "ClassPost";
    uid: string;
    isWelcomePost: boolean;
    publishAt: any;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  }>;
  unpublishedPosts: Array<{
    __typename?: "ClassPost";
    uid: string;
    isWelcomePost: boolean;
    publishAt: any;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  }>;
  activity: {
    __typename?: "Activity";
    user_uid: string;
    leader: {
      __typename?: "Leader";
      uid: string;
      leader_link: string | null;
      name: string | null;
    };
  };
  section: {
    __typename?: "Section";
    currentUserCanManage: boolean;
    uid: string;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      recordingAvailability: RecordingAvailability;
      recordingRequests: Array<{
        __typename?: "RecordingRequest";
        requestedAt: any;
        message: string;
        requestedBy: {
          __typename?: "Learner";
          uid: string;
          name: string | null;
        };
      }>;
      classRecordings: Array<
        | {
            __typename?: "ClassRecording";
            uid: string;
            startTime: any;
            endTime: any;
          }
        | {
            __typename?: "ZoomRecording";
            uid: string;
            startTime: any;
            endTime: any;
          }
      >;
      attendances: Array<{
        __typename?: "Attendance";
        learner: { __typename?: "Learner"; name: string | null };
      }>;
    }>;
    leader: { __typename?: "Leader"; uid: string; name: string | null };
  };
  mentionNames: Array<{
    __typename?: "MentionName";
    id: string;
    display: string;
    avatar: string | null;
    startTime: any | null;
  }>;
};

export type ClassPostList_LearnerFragment = {
  __typename?: "Learner";
  uid: string;
  name: string | null;
};

export type ClassroomLearnerList_ProfileFragment = {
  __typename?: "LearnerProfile";
  uid: string;
  name: string | null;
  avatar: string | null;
};

export type LearnerFragmentFragment = {
  __typename?: "Learner";
  uid: string;
  name: string | null;
  age: number | null;
  avatar: string | null;
  pronoun: string | null;
  intro: string | null;
  deleted_at: any | null;
  isVerified: boolean;
  isBlocked: boolean | null;
  blockedByUserUid: string | null;
  blockedAt: any | null;
};

export type MeetingFragmentFragment = {
  __typename?: "Meeting";
  uid: string;
  start_time: any;
  end_time: any;
  deleted_at: any | null;
  created_at: any | null;
};

export type OneOnOneMeetingFragmentFragment = {
  __typename?: "Meeting";
  refundableAmountCents: number;
  paidAt: any | null;
  recurring: boolean | null;
};

export type MeetingWithAttendancesAndRecordingsFragment = {
  __typename?: "Meeting";
  recordingAvailability: RecordingAvailability;
  uid: string;
  start_time: any;
  end_time: any;
  deleted_at: any | null;
  created_at: any | null;
  attendances: Array<{
    __typename?: "Attendance";
    uid: string;
    created_at: any;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    };
  }>;
  classRecordings: Array<
    | {
        __typename?: "ClassRecording";
        uid: string;
        recordingId: string;
        startTime: any;
        endTime: any;
      }
    | {
        __typename?: "ZoomRecording";
        uid: string;
        recordingId: string;
        startTime: any;
        endTime: any;
      }
  >;
  recordingRequests: Array<{
    __typename?: "RecordingRequest";
    requestedAt: any;
    message: string;
    requestedBy: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
      age: number | null;
      avatar: string | null;
      pronoun: string | null;
      intro: string | null;
      deleted_at: any | null;
      isVerified: boolean;
      isBlocked: boolean | null;
      blockedByUserUid: string | null;
      blockedAt: any | null;
    };
  }>;
};

export type MessageContentFragmentFragment = {
  __typename?: "MessageContent";
  uid: string;
  text: string | null;
  originalMimeType: TextMimeType;
  editedAt: any | null;
  htmlText: string | null;
  attachments: Array<{
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  }>;
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
};

export type MessageContentWithMentionsFragmentFragment = {
  __typename?: "MessageContent";
  uid: string;
  text: string | null;
  originalMimeType: TextMimeType;
  htmlText: string | null;
  attachments: Array<{
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  }>;
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
};

export type MessageSenderFragmentFragment = {
  __typename?: "MessageSender";
  uid: string;
  name: string | null;
  photo: string | null;
  avatar: string | null;
  isLearner: boolean;
};

export type PrivateClassMessageFragmentFragment = {
  __typename?: "PrivateClassMessage";
  uid: string;
  thread_uid: string;
  sentAt: any;
  sender: {
    __typename?: "MessageSender";
    uid: string;
    name: string | null;
    photo: string | null;
    avatar: string | null;
    isLearner: boolean;
  };
  messageContent: {
    __typename?: "MessageContent";
    uid: string;
    text: string | null;
    originalMimeType: TextMimeType;
    editedAt: any | null;
    htmlText: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    }>;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
  };
};

export type RecordingRequestFragmentFragment = {
  __typename?: "RecordingRequest";
  requestedAt: any;
  message: string;
  requestedBy: {
    __typename?: "Learner";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    isVerified: boolean;
    isBlocked: boolean | null;
    blockedByUserUid: string | null;
    blockedAt: any | null;
  };
};

export type DeleteAssignmentClassPostCommentMutationVariables = Exact<{
  commentUid: Scalars["ID"];
}>;

export type DeleteAssignmentClassPostCommentMutation = {
  __typename?: "Mutation";
  deleteAssignmentClassPostComment: {
    __typename?: "AssignmentClassPostComment";
    uid: string;
  };
};

export type UpdateClassPostInClubMutationVariables = Exact<{
  classPostUid: Scalars["ID"];
  messageContentInput?: InputMaybe<MessageContentInput>;
  title?: InputMaybe<Scalars["String"]>;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateClassPostInClubMutation = {
  __typename?: "Mutation";
  updateClassPost: {
    __typename?: "ClassPost";
    uid: string;
    isWelcomePost: boolean;
    publishAt: any;
    title: string | null;
    promotedAt: any | null;
    pinnedAt: any | null;
    mentionedEnrollmentUids: Array<string>;
    backgroundColor: string | null;
    score: number | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    commentsPreview: {
      __typename?: "CommentsPreview";
      remainingCommentsCount: number;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    };
  };
};

export type UpdateClassPostCommentMutationVariables = Exact<{
  classPostCommentUid: Scalars["ID"];
  messageContentInput?: InputMaybe<MessageContentInput>;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UpdateClassPostCommentMutation = {
  __typename?: "Mutation";
  updateClassPostComment: {
    __typename?: "ClassPostComment";
    uid: string;
    sendAt: any;
    mentionedEnrollmentUids: Array<string>;
    mentionedTeacherUid: string | null;
    backgroundColor: string | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type AddClassPostCommentMutationVariables = Exact<{
  classPostCommentInput: ClassPostCommentInput;
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AddClassPostCommentMutation = {
  __typename?: "Mutation";
  addClassPostComment: {
    __typename?: "ClassPostComment";
    uid: string;
    sendAt: any;
    mentionedEnrollmentUids: Array<string>;
    mentionedTeacherUid: string | null;
    backgroundColor: string | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type DeleteClassPostCommentMutationVariables = Exact<{
  classPostCommentUid: Scalars["ID"];
}>;

export type DeleteClassPostCommentMutation = {
  __typename?: "Mutation";
  deleteClassPostComment: boolean | null;
};

export type AddClassPostCommentReplyMutationVariables = Exact<{
  classPostCommentReplyInput: ClassPostCommentReplyInput;
}>;

export type AddClassPostCommentReplyMutation = {
  __typename?: "Mutation";
  addClassPostCommentReply: {
    __typename?: "ClassPostComment";
    uid: string;
    sendAt: any;
    mentionedEnrollmentUids: Array<string>;
    mentionedTeacherUid: string | null;
    backgroundColor: string | null;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type PromoteClassPostMutationVariables = Exact<{
  classPostUid: Scalars["ID"];
}>;

export type PromoteClassPostMutation = {
  __typename?: "Mutation";
  promoteClassPost: boolean | null;
};

export type PinClassPostMutationVariables = Exact<{
  classPostUid: Scalars["ID"];
}>;

export type PinClassPostMutation = {
  __typename?: "Mutation";
  pinClassPost: boolean;
};

export type UnpinClassPostMutationVariables = Exact<{
  classPostUid: Scalars["ID"];
}>;

export type UnpinClassPostMutation = {
  __typename?: "Mutation";
  unpinClassPost: boolean;
};

export type SetMeetingRecordingsShownMutationVariables = Exact<{
  meetingUid: Scalars["ID"];
  isShown: Scalars["Boolean"];
  reason?: InputMaybe<Scalars["String"]>;
}>;

export type SetMeetingRecordingsShownMutation = {
  __typename?: "Mutation";
  setMeetingRecordingsShown: {
    __typename?: "Meeting";
    recordingAvailability: RecordingAvailability;
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
    attendances: Array<{
      __typename?: "Attendance";
      uid: string;
      created_at: any;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
    }>;
    classRecordings: Array<
      | {
          __typename?: "ClassRecording";
          uid: string;
          recordingId: string;
          startTime: any;
          endTime: any;
        }
      | {
          __typename?: "ZoomRecording";
          uid: string;
          recordingId: string;
          startTime: any;
          endTime: any;
        }
    >;
    recordingRequests: Array<{
      __typename?: "RecordingRequest";
      requestedAt: any;
      message: string;
      requestedBy: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
    }>;
  };
};

export type RequestMeetingRecordingsShownMutationVariables = Exact<{
  meetingUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
}>;

export type RequestMeetingRecordingsShownMutation = {
  __typename?: "Mutation";
  requestMeetingRecordingsShown: {
    __typename?: "Meeting";
    recordingAvailability: RecordingAvailability;
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
    attendances: Array<{
      __typename?: "Attendance";
      uid: string;
      created_at: any;
      learner: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
    }>;
    classRecordings: Array<
      | {
          __typename?: "ClassRecording";
          uid: string;
          recordingId: string;
          startTime: any;
          endTime: any;
        }
      | {
          __typename?: "ZoomRecording";
          uid: string;
          recordingId: string;
          startTime: any;
          endTime: any;
        }
    >;
    recordingRequests: Array<{
      __typename?: "RecordingRequest";
      requestedAt: any;
      message: string;
      requestedBy: {
        __typename?: "Learner";
        uid: string;
        name: string | null;
        age: number | null;
        avatar: string | null;
        pronoun: string | null;
        intro: string | null;
        deleted_at: any | null;
        isVerified: boolean;
        isBlocked: boolean | null;
        blockedByUserUid: string | null;
        blockedAt: any | null;
      };
    }>;
  };
};

export type UpdateFilestackMetadataMutationVariables = Exact<{
  handle: Scalars["String"];
  osS3Path: Scalars["String"];
}>;

export type UpdateFilestackMetadataMutation = {
  __typename?: "Mutation";
  updateFilestackMetadata: boolean;
};

export type AddPrivateClassMessageMutationVariables = Exact<{
  privateClassMessageInput: PrivateClassMessageInput;
}>;

export type AddPrivateClassMessageMutation = {
  __typename?: "Mutation";
  addPrivateClassMessage: {
    __typename?: "PrivateClassMessage";
    uid: string;
    thread_uid: string;
    sentAt: any;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  };
};

export type MarkPrivateClassMessageThreadAsReadMutationVariables = Exact<{
  threadUid: Scalars["ID"];
}>;

export type MarkPrivateClassMessageThreadAsReadMutation = {
  __typename?: "Mutation";
  markPrivateClassMessageThreadAsRead: boolean;
};

export type ClubPostQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  classPostUid: Scalars["ID"];
  plainText?: InputMaybe<Scalars["Boolean"]>;
  creationDateSortOrder?: InputMaybe<SortOrder>;
}>;

export type ClubPostQuery = {
  __typename?: "Query";
  club: {
    __typename?: "Club";
    uid: string;
    currentLearnerIsVerified: boolean;
    currentLearnerMustAcceptGuidelines: boolean;
    currentLearnerHasSubmittedIntroductionForClub: boolean;
    currentUserCanParticipate: boolean;
    section: {
      __typename?: "Section";
      uid: string;
      currentUserCanManage: boolean;
    };
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      titleTranslated: string | null;
      user_uid: string;
      isClub: boolean;
      videoForLearners: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      clubGuidelines: {
        __typename?: "WelcomeMessage";
        text: string | null;
      } | null;
    };
    postByUid: {
      __typename?: "ClassPost";
      uid: string;
      title: string | null;
      promotedAt: any | null;
      pinnedAt: any | null;
      backgroundColor: string | null;
      mentionedEnrollmentUids: Array<string>;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        replies: Array<{
          __typename?: "ClassPostComment";
          uid: string;
          sendAt: any;
          mentionedEnrollmentUids: Array<string>;
          mentionedTeacherUid: string | null;
          backgroundColor: string | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    } | null;
    mentionNames: Array<{
      __typename?: "MentionName";
      id: string;
      display: string;
      avatar: string | null;
      startTime: any | null;
    }>;
  };
};

export type ClubPostAndCommentBackgroundColorsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ClubPostAndCommentBackgroundColorsQuery = {
  __typename?: "Query";
  clubPostAndCommentBackgroundColors: Array<string>;
};

export type AllClassPostCommentsQueryQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  classPostUid: Scalars["ID"];
  plainText?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AllClassPostCommentsQueryQuery = {
  __typename?: "Query";
  classroom: {
    __typename?: "Classroom";
    uid: string;
    postByUid: {
      __typename?: "ClassPost";
      uid: string;
      comments: Array<{
        __typename?: "ClassPostComment";
        uid: string;
        sendAt: any;
        mentionedEnrollmentUids: Array<string>;
        mentionedTeacherUid: string | null;
        backgroundColor: string | null;
        sender: {
          __typename?: "MessageSender";
          uid: string;
          name: string | null;
          photo: string | null;
          avatar: string | null;
          isLearner: boolean;
        };
        messageContent: {
          __typename?: "MessageContent";
          uid: string;
          text: string | null;
          originalMimeType: TextMimeType;
          editedAt: any | null;
          htmlText: string | null;
          attachments: Array<{
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          }>;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
            };
          } | null;
        };
      }>;
    } | null;
  };
};

export type PrivateClassMessagesQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
}>;

export type PrivateClassMessagesQuery = {
  __typename?: "Query";
  privateClassMessages: Array<{
    __typename?: "PrivateClassMessage";
    uid: string;
    thread_uid: string;
    sentAt: any;
    sender: {
      __typename?: "MessageSender";
      uid: string;
      name: string | null;
      photo: string | null;
      avatar: string | null;
      isLearner: boolean;
    };
    messageContent: {
      __typename?: "MessageContent";
      uid: string;
      text: string | null;
      originalMimeType: TextMimeType;
      editedAt: any | null;
      htmlText: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
  }>;
};

export type LiveMeetingButtonQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  learnerUid?: InputMaybe<Scalars["ID"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
}>;

export type LiveMeetingButtonQuery = {
  __typename?: "Query";
  joinMeeting: {
    __typename?: "JoinMeeting";
    uid: string;
    canManage: boolean;
    meetingStartTime: any | null;
    meetingEndTime: any | null;
    usersCanHaveLiveMeeting: boolean | null;
    isFlexSchedule: boolean;
    meetingsCount: number;
    sectionStartTime: any | null;
    enrollmentUid: string | null;
    isSellerOrg: boolean | null;
  };
};

export type LearnerLiveMeetingQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  learnerUid: Scalars["ID"];
  lessonUid: Scalars["ID"];
}>;

export type LearnerLiveMeetingQuery = {
  __typename?: "Query";
  lessonLiveMeeting: {
    __typename?: "LessonLiveMeeting";
    uid: string;
    enrollmentUid: string | null;
    classRecordingUids: Array<string | null>;
    sectionStartTime: any | null;
    meetingStartTime: any | null;
    meetingEndTime: any | null;
    meetingsCount: number;
    usersCanHaveLiveMeeting: boolean | null;
  };
};

export type JoinNextMeetingQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  enrollmentUid?: InputMaybe<Scalars["ID"]>;
}>;

export type JoinNextMeetingQuery = {
  __typename?: "Query";
  joinNextMeeting: {
    __typename?: "NextMeeting";
    uid: string;
    currentUserCanManage: boolean;
    url: string;
    shouldMarkAttendance: boolean;
  };
};

export type LearnerProfileQueryVariables = Exact<{
  learnerUid: Scalars["ID"];
}>;

export type LearnerProfileQuery = {
  __typename?: "Query";
  learnerProfile: {
    __typename?: "LearnerProfile";
    uid: string;
    name: string | null;
    age: number | null;
    avatar: string | null;
    unlockedAvatars: Array<string>;
    pronoun: string | null;
    aboutMe: string | null;
    isProfileAgeHidden: boolean;
    isProfileLocationHidden: boolean;
    location: {
      __typename?: "ApproximateLocation";
      state: string | null;
      country: string | null;
    } | null;
    favoritedClassList: {
      __typename?: "SavedClassList";
      listings: Array<{
        __typename?: "Listing";
        activity: {
          __typename?: "Activity";
          uid: string;
          title: string;
          slug_id: string;
          age_min: number | null;
          age_max: number | null;
          isClub: boolean;
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            photo: string | null;
            leader_link: string | null;
            details: { __typename?: "LeaderDetails"; headline: string | null };
          };
          details: { __typename?: "ActivityDetails"; photo: string | null };
          video: {
            __typename?: "Attachment";
            uid: string;
            file: { __typename?: "File"; url: string };
          } | null;
          videoForLearners: {
            __typename?: "Attachment";
            uid: string;
            file: { __typename?: "File"; url: string };
          } | null;
        };
      } | null>;
    } | null;
  };
};

export type CurrentLearnerPastActivitiesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type CurrentLearnerPastActivitiesQuery = {
  __typename?: "Query";
  currentLearnerPastActivities: {
    __typename?: "PaginatedPastActivities";
    totalResults: number;
    activities: Array<{
      __typename?: "Activity";
      uid: string;
      title: string;
      slug_id: string;
      age_min: number | null;
      age_max: number | null;
      isClub: boolean;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        details: { __typename?: "LeaderDetails"; headline: string | null };
      };
      details: { __typename?: "ActivityDetails"; photo: string | null };
      video: {
        __typename?: "Attachment";
        uid: string;
        file: { __typename?: "File"; url: string };
      } | null;
      videoForLearners: {
        __typename?: "Attachment";
        uid: string;
        file: { __typename?: "File"; url: string };
      } | null;
    }>;
  };
};

export type SaveFavoriteClassToProfileMutationVariables = Exact<{
  activityUids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type SaveFavoriteClassToProfileMutation = {
  __typename?: "Mutation";
  saveLearnerProfileClasses: {
    __typename?: "SavedClassList";
    listings: Array<{
      __typename?: "Listing";
      activity: {
        __typename?: "Activity";
        uid: string;
        title: string;
        age_min: number | null;
        age_max: number | null;
        isClub: boolean;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          leader_link: string | null;
          details: { __typename?: "LeaderDetails"; headline: string | null };
        };
        details: { __typename?: "ActivityDetails"; photo: string | null };
        video: {
          __typename?: "Attachment";
          uid: string;
          file: { __typename?: "File"; url: string };
        } | null;
        videoForLearners: {
          __typename?: "Attachment";
          uid: string;
          file: { __typename?: "File"; url: string };
        } | null;
      };
    } | null>;
  };
};

export type SaveLearnerProfileMutationVariables = Exact<{
  profileInput: LearnerProfileInput;
}>;

export type SaveLearnerProfileMutation = {
  __typename?: "Mutation";
  saveLearnerProfile: {
    __typename?: "LearnerProfile";
    uid: string;
    avatar: string | null;
    aboutMe: string | null;
  };
};

export type SaveLearnerProfileSettingsMutationVariables = Exact<{
  learnerProfileSettingsInput: LearnerProfileSettingsInput;
}>;

export type SaveLearnerProfileSettingsMutation = {
  __typename?: "Mutation";
  saveLearnerProfileSettings: boolean;
};

export type ClubQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  start?: InputMaybe<Scalars["DateTime"]>;
  end?: InputMaybe<Scalars["DateTime"]>;
  score?: InputMaybe<Scalars["Float"]>;
  plainText?: InputMaybe<Scalars["Boolean"]>;
  hasCurrentUserEnrollments: Scalars["Boolean"];
  postLimit?: InputMaybe<Scalars["Int"]>;
  scoreFrom?: InputMaybe<Scalars["DateTime"]>;
}>;

export type ClubQuery = {
  __typename?: "Query";
  club: {
    __typename?: "Club";
    uid: string;
    currentLearnerIsVerified: boolean;
    currentLearnerMustAcceptGuidelines: boolean;
    currentLearnerHasSubmittedIntroductionForClub: boolean;
    currentUserCanParticipate: boolean;
    section: {
      __typename?: "Section";
      uid: string;
      published_at: any | null;
      deleted_at: any | null;
      canceled_at: any | null;
      start_time: any | null;
      end_time: any | null;
      size_max: number | null;
      currentUserCanManage: boolean;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
      } | null;
    };
    activity: {
      __typename?: "Activity";
      uid: string;
      title: string;
      titleTranslated: string | null;
      description: string | null;
      user_uid: string;
      isClub: boolean;
      published_at: any | null;
      is_ongoing_weekly: boolean;
      weekly_meetings: number | null;
      videoForLearners: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      details: { __typename?: "ActivityDetails"; photo: string | null };
      clubGuidelines: {
        __typename?: "WelcomeMessage";
        text: string | null;
      } | null;
      clubBannerImage: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      clubWelcomeVideo: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
    };
    pinnedPosts: Array<{
      __typename?: "ClassPost";
      uid: string;
      isWelcomePost: boolean;
      publishAt: any;
      title: string | null;
      promotedAt: any | null;
      pinnedAt: any | null;
      mentionedEnrollmentUids: Array<string>;
      backgroundColor: string | null;
      score: number | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      commentsPreview: {
        __typename?: "CommentsPreview";
        remainingCommentsCount: number;
        comments: Array<{
          __typename?: "ClassPostComment";
          uid: string;
          sendAt: any;
          mentionedEnrollmentUids: Array<string>;
          mentionedTeacherUid: string | null;
          backgroundColor: string | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      };
    }>;
    posts: Array<{
      __typename?: "ClassPost";
      uid: string;
      isWelcomePost: boolean;
      publishAt: any;
      title: string | null;
      promotedAt: any | null;
      pinnedAt: any | null;
      mentionedEnrollmentUids: Array<string>;
      backgroundColor: string | null;
      score: number | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      commentsPreview: {
        __typename?: "CommentsPreview";
        remainingCommentsCount: number;
        comments: Array<{
          __typename?: "ClassPostComment";
          uid: string;
          sendAt: any;
          mentionedEnrollmentUids: Array<string>;
          mentionedTeacherUid: string | null;
          backgroundColor: string | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      };
    }>;
    mentionNames: Array<{
      __typename?: "MentionName";
      id: string;
      display: string;
      avatar: string | null;
      startTime: any | null;
    }>;
    currentUserEnrollments?: Array<{
      __typename?: "Enrollment";
      uid: string;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      isPaused: boolean;
      isEnded: boolean;
      usersCanCommunicate: boolean;
      learner: { __typename?: "Learner"; uid: string } | null;
    }>;
  };
};

export type ClubPostsQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  start?: InputMaybe<Scalars["DateTime"]>;
  end?: InputMaybe<Scalars["DateTime"]>;
  plainText?: InputMaybe<Scalars["Boolean"]>;
  postLimit?: InputMaybe<Scalars["Int"]>;
  score?: InputMaybe<Scalars["Float"]>;
  scoreFrom?: InputMaybe<Scalars["DateTime"]>;
}>;

export type ClubPostsQuery = {
  __typename?: "Query";
  club: {
    __typename?: "Club";
    posts: Array<{
      __typename?: "ClassPost";
      uid: string;
      isWelcomePost: boolean;
      publishAt: any;
      title: string | null;
      promotedAt: any | null;
      pinnedAt: any | null;
      mentionedEnrollmentUids: Array<string>;
      backgroundColor: string | null;
      score: number | null;
      sender: {
        __typename?: "MessageSender";
        uid: string;
        name: string | null;
        photo: string | null;
        avatar: string | null;
        isLearner: boolean;
      };
      messageContent: {
        __typename?: "MessageContent";
        uid: string;
        text: string | null;
        originalMimeType: TextMimeType;
        editedAt: any | null;
        htmlText: string | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        } | null;
      };
      commentsPreview: {
        __typename?: "CommentsPreview";
        remainingCommentsCount: number;
        comments: Array<{
          __typename?: "ClassPostComment";
          uid: string;
          sendAt: any;
          mentionedEnrollmentUids: Array<string>;
          mentionedTeacherUid: string | null;
          backgroundColor: string | null;
          sender: {
            __typename?: "MessageSender";
            uid: string;
            name: string | null;
            photo: string | null;
            avatar: string | null;
            isLearner: boolean;
          };
          messageContent: {
            __typename?: "MessageContent";
            uid: string;
            text: string | null;
            originalMimeType: TextMimeType;
            editedAt: any | null;
            htmlText: string | null;
            attachments: Array<{
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            }>;
            video: {
              __typename?: "Attachment";
              uid: string;
              conversionStatus: string | null;
              created_at: any;
              file: {
                __typename?: "File";
                filename: string;
                mimetype: string | null;
                size: number;
                url: string;
                thumbUrl: string | null;
              };
            } | null;
          };
        }>;
      };
    }>;
  };
};

export type AutoMarkAttendanceMutationVariables = Exact<{
  sectionUid?: InputMaybe<Scalars["ID"]>;
}>;

export type AutoMarkAttendanceMutation = {
  __typename?: "Mutation";
  markAttended: boolean;
};

export type AutoMarkTeacherAttendanceMutationVariables = Exact<{
  sectionUid: Scalars["ID"];
}>;

export type AutoMarkTeacherAttendanceMutation = {
  __typename?: "Mutation";
  markTeacherAttended: boolean;
};

export type MuxClassroomRecordingQueryVariables = Exact<{
  sectionUid: Scalars["ID"];
  recordingUid: Scalars["ID"];
}>;

export type MuxClassroomRecordingQuery = {
  __typename?: "Query";
  classroom: {
    __typename?: "Classroom";
    uid: string;
    usersCanPost: boolean;
    activity: {
      __typename?: "Activity";
      uid: string;
      user_uid: string;
      title: string;
      weekly_meetings: number | null;
      duration_weeks: number | null;
      is_ongoing_weekly: boolean;
      slug_id: string;
      isClub: boolean;
      published_at: any | null;
      hasTeacherSchedule: boolean;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
    };
    section: {
      __typename?: "Section";
      uid: string;
      currentUserCanManage: boolean;
      start_time: any | null;
      end_time: any | null;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
      } | null;
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      classRecording:
        | {
            __typename?: "ClassRecording";
            isExpired: boolean;
            playUrl: string | null;
            uid: string;
            startTime: any;
            endTime: any;
            meeting: {
              __typename?: "Meeting";
              uid: string;
              start_time: any;
              end_time: any;
              recordingAvailability: RecordingAvailability;
              recordingRequests: Array<{
                __typename?: "RecordingRequest";
                requestedAt: any;
                message: string;
                requestedBy: {
                  __typename?: "Learner";
                  uid: string;
                  name: string | null;
                };
              }>;
              classRecordings: Array<
                | {
                    __typename?: "ClassRecording";
                    uid: string;
                    startTime: any;
                    endTime: any;
                  }
                | {
                    __typename?: "ZoomRecording";
                    uid: string;
                    startTime: any;
                    endTime: any;
                  }
              >;
              attendances: Array<{
                __typename?: "Attendance";
                learner: { __typename?: "Learner"; name: string | null };
              }>;
            } | null;
          }
        | {
            __typename?: "ZoomRecording";
            uid: string;
            startTime: any;
            endTime: any;
            meeting: {
              __typename?: "Meeting";
              uid: string;
              start_time: any;
              end_time: any;
              recordingAvailability: RecordingAvailability;
              recordingRequests: Array<{
                __typename?: "RecordingRequest";
                requestedAt: any;
                message: string;
                requestedBy: {
                  __typename?: "Learner";
                  uid: string;
                  name: string | null;
                };
              }>;
              classRecordings: Array<
                | {
                    __typename?: "ClassRecording";
                    uid: string;
                    startTime: any;
                    endTime: any;
                  }
                | {
                    __typename?: "ZoomRecording";
                    uid: string;
                    startTime: any;
                    endTime: any;
                  }
              >;
              attendances: Array<{
                __typename?: "Attendance";
                learner: { __typename?: "Learner"; name: string | null };
              }>;
            } | null;
          };
    };
  };
};

export type ListingsPromoBannerButtonFragment = {
  __typename?: "ContentfulListingsPromoBannerButton";
  buttonText: string | null;
  exploreMoreButtonText: string | null;
  buttonSearchFilters: {
    __typename?: "ContentfulSearchFilters";
    adminTags: Array<string | null> | null;
    ages: Array<string | null> | null;
    capacityMax: number | null;
    capacityMin: number | null;
    deliveryTypes: Array<string | null> | null;
    durationWeeksMax: number | null;
    durationWeeksMin: number | null;
    endBy: any | null;
    formats: Array<string | null> | null;
    fundingPrograms: Array<string | null> | null;
    gradeLevel: Array<string | null> | null;
    hasFilledOutUniqueLearningNeeds: boolean | null;
    pricePerCourseMax: number | null;
    pricePerCourseMin: number | null;
    pricePerMeetingMax: number | null;
    pricePerMeetingMin: number | null;
    multiTermQuery: string | null;
    query: string | null;
    startAfter: any | null;
    startBefore: any | null;
    subject: string | null;
    uniqueLearningNeeds: string | null;
    weeklyMeetingsMin: number | null;
    weeklyMeetingsMax: number | null;
  } | null;
};

export type ListingsPromoBannerFragment = {
  __typename?: "ContentfulListingsPromoBanner";
  name: string | null;
  experimentVersion: number | null;
  headerTitle: string | null;
  includeControlGroup: boolean | null;
  headerSubtitle: string | null;
  sidebarTitle: string | null;
  sys: { __typename?: "ContentfulSys"; publishedVersion: number | null };
  headerTitleFontDefinition: {
    __typename?: "ContentfulListingsPromoBannerHeaderTitleFontDefinition";
    fontFamily: string | null;
  } | null;
  backgroundImageLeft: {
    __typename?: "ContentfulAsset";
    url: string | null;
  } | null;
  backgroundImageRight: {
    __typename?: "ContentfulAsset";
    url: string | null;
  } | null;
  backgroundImageMobile: {
    __typename?: "ContentfulAsset";
    url: string | null;
  } | null;
  buttonsCollection: {
    __typename?: "ContentfulListingsPromoBannerButtonsCollection";
    items: Array<{
      __typename?: "ContentfulListingsPromoBannerButton";
      buttonText: string | null;
      exploreMoreButtonText: string | null;
      buttonSearchFilters: {
        __typename?: "ContentfulSearchFilters";
        adminTags: Array<string | null> | null;
        ages: Array<string | null> | null;
        capacityMax: number | null;
        capacityMin: number | null;
        deliveryTypes: Array<string | null> | null;
        durationWeeksMax: number | null;
        durationWeeksMin: number | null;
        endBy: any | null;
        formats: Array<string | null> | null;
        fundingPrograms: Array<string | null> | null;
        gradeLevel: Array<string | null> | null;
        hasFilledOutUniqueLearningNeeds: boolean | null;
        pricePerCourseMax: number | null;
        pricePerCourseMin: number | null;
        pricePerMeetingMax: number | null;
        pricePerMeetingMin: number | null;
        multiTermQuery: string | null;
        query: string | null;
        startAfter: any | null;
        startBefore: any | null;
        subject: string | null;
        uniqueLearningNeeds: string | null;
        weeklyMeetingsMin: number | null;
        weeklyMeetingsMax: number | null;
      } | null;
    } | null>;
  } | null;
};

export type ListingsPromoBannerQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["String"]>;
  preview: Scalars["Boolean"];
  isLiveOnSearch: Scalars["Boolean"];
}>;

export type ListingsPromoBannerQuery = {
  __typename?: "Query";
  contentfulListingsPromoBannerCollection: {
    __typename?: "ContentfulListingsPromoBannerCollection";
    items: Array<{
      __typename?: "ContentfulListingsPromoBanner";
      name: string | null;
      experimentVersion: number | null;
      headerTitle: string | null;
      includeControlGroup: boolean | null;
      headerSubtitle: string | null;
      sidebarTitle: string | null;
      sys: { __typename?: "ContentfulSys"; publishedVersion: number | null };
      headerTitleFontDefinition: {
        __typename?: "ContentfulListingsPromoBannerHeaderTitleFontDefinition";
        fontFamily: string | null;
      } | null;
      backgroundImageLeft: {
        __typename?: "ContentfulAsset";
        url: string | null;
      } | null;
      backgroundImageRight: {
        __typename?: "ContentfulAsset";
        url: string | null;
      } | null;
      backgroundImageMobile: {
        __typename?: "ContentfulAsset";
        url: string | null;
      } | null;
      buttonsCollection: {
        __typename?: "ContentfulListingsPromoBannerButtonsCollection";
        items: Array<{
          __typename?: "ContentfulListingsPromoBannerButton";
          buttonText: string | null;
          exploreMoreButtonText: string | null;
          buttonSearchFilters: {
            __typename?: "ContentfulSearchFilters";
            adminTags: Array<string | null> | null;
            ages: Array<string | null> | null;
            capacityMax: number | null;
            capacityMin: number | null;
            deliveryTypes: Array<string | null> | null;
            durationWeeksMax: number | null;
            durationWeeksMin: number | null;
            endBy: any | null;
            formats: Array<string | null> | null;
            fundingPrograms: Array<string | null> | null;
            gradeLevel: Array<string | null> | null;
            hasFilledOutUniqueLearningNeeds: boolean | null;
            pricePerCourseMax: number | null;
            pricePerCourseMin: number | null;
            pricePerMeetingMax: number | null;
            pricePerMeetingMin: number | null;
            multiTermQuery: string | null;
            query: string | null;
            startAfter: any | null;
            startBefore: any | null;
            subject: string | null;
            uniqueLearningNeeds: string | null;
            weeklyMeetingsMin: number | null;
            weeklyMeetingsMax: number | null;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type SectionRibbon_SectionFragment = {
  __typename?: "Section";
  size_max: number | null;
  filledSpaceCount: number;
};

export type SectionRibbon_ActivityFragment = {
  __typename?: "Activity";
  clubSection: {
    __typename?: "Section";
    filledSpaceCount: number;
    clubPostCount: number;
  } | null;
};

export type SampleClubPost_WelcomeMessageFragment = {
  __typename?: "WelcomeMessage";
  text: string | null;
  attachments: Array<{
    __typename?: "Attachment";
    uid: string;
    file: { __typename?: "File"; url: string; mimetype: string | null };
  }> | null;
  video: {
    __typename?: "Attachment";
    uid: string;
    file: { __typename?: "File"; url: string; mimetype: string | null };
  } | null;
};

export type LightActivityFragmentFragment = {
  __typename?: "Activity";
  uid: string;
  user_uid: string;
  slug_id: string;
  title: string;
  titleTranslated: string | null;
  url: string;
  path: string;
  summary: string | null;
  summaryTranslated: string | null;
  is_ongoing_weekly: boolean;
  isClub: boolean;
  details: { __typename?: "ActivityDetails"; photo: string | null };
};

export type ContentfulAnnouncementBannerQueryVariables = Exact<{
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ContentfulAnnouncementBannerQuery = {
  __typename?: "Query";
  contentfulAnnouncementBannerCollection: {
    __typename?: "ContentfulAnnouncementBannerCollection";
    items: Array<{
      __typename?: "ContentfulAnnouncementBanner";
      name: string | null;
      copy: string | null;
      linkCopy: string | null;
      linkUrl: string | null;
      dateToRemove: any | null;
    } | null>;
  } | null;
};

export type CanUserRedeemCouponQueryVariables = Exact<{
  couponUid: Scalars["String"];
}>;

export type CanUserRedeemCouponQuery = {
  __typename?: "Query";
  coupon: {
    __typename?: "Coupon";
    uid: string;
    expiresAt: any | null;
    userIsEligibleToApplyCoupon: {
      __typename?: "UserIsEligibleToApplyCoupon";
      isEligible: boolean;
      reason: string;
    };
  };
};

export type GetBundleSharedQueryVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type GetBundleSharedQuery = {
  __typename?: "Query";
  bundle: {
    __typename?: "Bundle";
    uid: string;
    title: string;
    description: string | null;
    isOrdered: boolean;
    thumbnail: string | null;
    discount: number | null;
    bundleElements: Array<{
      __typename?: "BundleElement";
      uid: string;
      orderInBundle: number;
      activity: {
        __typename?: "Activity";
        uid: string;
        user_uid: string;
        title: string;
        summary: string | null;
        size_min: number | null;
        size_max: number | null;
        price_cents: number | null;
        canEnroll: boolean;
        duration_weeks: number | null;
        weekly_meetings: number | null;
        duration_minutes: number | null;
        isClub: boolean;
        is_ongoing_weekly: boolean;
        hasTeacherSchedule: boolean;
        isSelfPaced: boolean;
        isFlexSchedule: boolean;
        allows_recurring_payment: boolean | null;
        subject: string | null;
        age_min: number | null;
        age_max: number | null;
        titleTranslated: string | null;
        slug_id: string;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
    }>;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      suspended_at: any | null;
      teacher_approved_at: any | null;
      reviewCount: number;
      averageActivityStarRating: number | null;
      photo: string | null;
      leader_link: string | null;
      educatorTiers: Array<EducatorTier>;
      leaderClassLinks: Array<{
        __typename?: "Bundle";
        uid: string;
        title: string;
        isOrdered: boolean;
        discount: number | null;
        thumbnail: string | null;
        bundleElements: Array<{
          __typename?: "BundleElement";
          activity: {
            __typename?: "Activity";
            canEnroll: boolean;
            age_min: number | null;
            age_max: number | null;
            details: { __typename?: "ActivityDetails"; photo: string | null };
          };
        }>;
      }>;
      details: {
        __typename?: "LeaderDetails";
        headline: string | null;
        about: string | null;
      };
      video: {
        __typename?: "Attachment";
        file: { __typename?: "File"; url: string; thumbUrl: string | null };
      } | null;
      credentials: Array<{
        __typename?: "TeacherCredential";
        uid: string;
        degreeType: DegreeType | null;
        degreeSubject: DegreeSubject | null;
        certificateDiscipline: TeacherCertificateDiscipline | null;
        otherType: string | null;
        certificateState: TeacherCertificateState | null;
        name: string | null;
        degreeInstitution: string | null;
        degreeAdditionalInfo: string | null;
      }>;
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    };
  };
};

export type AddNewChildForCartMutationVariables = Exact<{
  childInput: ChildInput;
}>;

export type AddNewChildForCartMutation = {
  __typename?: "Mutation";
  addChild: {
    __typename?: "Child";
    uid: string;
    name: string | null;
    age: number | null;
    birthMonth: number | null;
    date_of_birth: any | null;
    birthYear: number | null;
    email: string | null;
    avatar: string | null;
    unlockedAvatars: Array<string>;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    email_confirmed_at: any | null;
    optedOutOfPersonalizedRecommendationsAt: any | null;
    isLoginEnabled: boolean;
    username: string | null;
    hasPassword: boolean;
    attributes: Array<{
      __typename?: "EntityAttribute";
      uid: string;
      key: string;
      value: string;
    }>;
  };
};

export type RandomCategoriesByFacetQueryVariables = Exact<{
  facet?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type RandomCategoriesByFacetQuery = {
  __typename?: "Query";
  randomCategoriesByFacet: Array<{
    __typename?: "CategoryPage";
    uid: string;
    basePath: string;
    nameTitleCased: string;
    slug: string;
  } | null> | null;
};

export type ActivityDetailDescription_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  assessment: string | null;
  class_experience: string | null;
  has_external_resources: boolean | null;
  homework: string | null;
  learning_goals: string | null;
  materials: string | null;
  parental_guidance: string | null;
  sources: string | null;
  title: string;
  titleTranslated: string | null;
  weekly_meetings: number | null;
  welcomeAttachmentCount: number | null;
  why_me: string | null;
  duration_minutes: number | null;
  suggestedWeeklyMeetings: number | null;
  english_proficiency_level: EnglishProficiencyLevel | null;
  externalResources: Array<{
    __typename?: "ExternalResource";
    uid: string;
    name: string;
  }>;
  details: { __typename?: "ActivityDetails"; asyncTime: string | null };
  clubGuidelines: { __typename?: "WelcomeMessage"; text: string | null } | null;
};

export type ActivityEnrollableTimesModal_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  hasTeacherSchedule: boolean;
  autoSchedulingDaysNoticeMin: number | null;
  autoSchedulingDaysNoticeMax: number | null;
  esaStatus: EsaStatus;
  is_ongoing_weekly: boolean;
  isClub: boolean;
  isSelfPaced: boolean;
  duration_weeks: number | null;
  enrollableTimes: Array<{
    __typename?: "EnrollableTime";
    startTime: any;
    endTime: any;
    teacher: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      hasBackgroundCheckExpired: boolean | null;
      teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    };
  }>;
};

export type ClassDetailsQuery_MeetingFragment = {
  __typename?: "Meeting";
  uid: string;
  start_time: any;
  end_time: any;
  deleted_at: any | null;
  created_at: any | null;
};

export type ClassDetailsQuery_SectionFragment = {
  __typename?: "Section";
  uid: string;
  activity_uid: string;
  allowsLateEnrollment: boolean;
  canceled_at: any | null;
  created_at: any;
  currentUserCanManage: boolean;
  currentUserIsOwner: boolean;
  deleted_at: any | null;
  duration_minutes: number | null;
  end_time: any | null;
  filledSpaceCount: number;
  isBuyable: boolean;
  isInProgress: boolean;
  isPublished: boolean | null;
  ongoing_stopped_at: any | null;
  price_cents: number | null;
  published_at: any | null;
  start_time: any | null;
  size_max: number | null;
  size_min: number | null;
  updated_at: any;
  usesOutschoolVideoChat: boolean;
  validEnrollmentCount: number;
  details: {
    __typename?: "SectionDetails";
    autoScheduledDraft: boolean | null;
    onlineClassroom: {
      __typename?: "OnlineClassroom";
      type: string | null;
      url: string | null;
    } | null;
  };
  leader: { __typename?: "Leader"; uid: string; name: string | null };
  meetings: Array<{
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
  }>;
  meetingTimes: Array<{
    __typename?: "MeetingTime";
    dayOfWeek: number;
    hour: number;
    minute: number;
  }>;
  nextOngoingMeeting: {
    __typename?: "Meeting";
    uid: string;
    start_time: any;
    end_time: any;
    deleted_at: any | null;
    created_at: any | null;
  } | null;
  progress: {
    __typename?: "CourseProgress";
    currentWeek: number;
    pastMeetingCount: number;
    remainingMeetingCount: number;
    totalMeetingCount: number;
    nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
  } | null;
};

export type ClassDetailsQuery_AttachmentFragment = {
  __typename?: "Attachment";
  uid: string;
  conversionStatus: string | null;
  created_at: any;
  file: {
    __typename?: "File";
    filename: string;
    mimetype: string | null;
    size: number;
    url: string;
    thumbUrl: string | null;
  };
};

export type ClassDetailsQuery_LeaderFragment = {
  __typename?: "Leader";
  uid: string;
  approved: boolean;
  averageActivityStarRating: number | null;
  cityState: string | null;
  created_at: any | null;
  educatorTiers: Array<EducatorTier>;
  hasAvailabilitySet: boolean;
  hasBackgroundCheckExpired: boolean | null;
  is_crosslister: boolean | null;
  leader_link: string | null;
  name: string | null;
  photo: string | null;
  publishedClassCount: number;
  reviewCount: number;
  socialOptOut: boolean;
  successfulClassCount: number;
  suspended_at: any | null;
  teacher_approved_at: any | null;
  timeZone: string | null;
  details: {
    __typename?: "LeaderDetails";
    about: string | null;
    countryOfResidence: string | null;
    headline: string | null;
    headlineTranslated: string | null;
  };
  ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
  teacherForSellerOrg: {
    __typename?: "SellerOrg";
    uid: string;
    name: string;
  } | null;
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
  credentials: Array<{
    __typename?: "TeacherCredential";
    uid: string;
    degreeType: DegreeType | null;
    degreeSubject: DegreeSubject | null;
    certificateDiscipline: TeacherCertificateDiscipline | null;
    otherType: string | null;
    certificateState: TeacherCertificateState | null;
    name: string | null;
    degreeInstitution: string | null;
    degreeAdditionalInfo: string | null;
  }>;
  leaderClassLinks: Array<{
    __typename?: "Bundle";
    uid: string;
    title: string;
    isOrdered: boolean;
    bundleElements: Array<{
      __typename?: "BundleElement";
      orderInBundle: number;
      activity: { __typename?: "Activity"; uid: string };
    }>;
  }>;
};

export type ClassDetailsQuery_SampleClubPostFragment = {
  __typename?: "SampleClubPost";
  text: string | null;
  attachments: Array<{
    __typename?: "Attachment";
    uid: string;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
    };
  }> | null;
  video: {
    __typename?: "Attachment";
    uid: string;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
    };
  } | null;
  comments: Array<{
    __typename?: "SampleClubPostComment";
    text: string | null;
  }>;
};

export type ClassDetailsQuery_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  age_max: number | null;
  age_min: number | null;
  grade_level_max: string | null;
  grade_level_min: string | null;
  url: string;
  path: string;
  allows_late_enrollments_for_fixed_length_classes: boolean;
  allows_recurring_payment: boolean | null;
  has_building_content: boolean | null;
  archived_at: any | null;
  assessment: string | null;
  autoSchedulingDaysNoticeMin: number | null;
  autoSchedulingDaysNoticeMax: number | null;
  averageStarRating: number | null;
  canEnroll: boolean;
  classFormat: EducatorSelectedFormat;
  class_experience: string | null;
  completedEnrollmentCount: number;
  disclaimer: string | null;
  duration_minutes: number | null;
  duration_weeks: number | null;
  english_proficiency_level: EnglishProficiencyLevel | null;
  esaStatus: EsaStatus;
  first_listed_at: any | null;
  has_external_resources: boolean | null;
  hasTeacherSchedule: boolean;
  homework: string | null;
  grading_policy: string | null;
  is_ongoing_weekly: boolean;
  is_online: boolean;
  isAutoSchedulingEnabled: boolean | null;
  allowWaitlist: boolean | null;
  isClub: boolean;
  isFlexSchedule: boolean;
  isOneTime: boolean;
  isPublished: boolean;
  isRestricted: boolean;
  isSelfPaced: boolean;
  isSoldOut: boolean;
  languageOfInstruction: any | null;
  learning_goals: string | null;
  learningGoals: Array<string>;
  materials: string | null;
  meetingCount: number | null;
  parental_guidance: string | null;
  prerequisites: string | null;
  price_cents: number | null;
  priceCredits: number | null;
  perWeeklyCourseCredits: number | null;
  published_at: any | null;
  requested_listing_at: any | null;
  reviewCount: number;
  size_max: number | null;
  size_min: number | null;
  slug_id: string;
  sources: string | null;
  subject: string | null;
  suggestedWeeklyMeetings: number | null;
  summary: string | null;
  summaryTranslated: string | null;
  summaryForLearners: string | null;
  title: string;
  titleTranslated: string | null;
  upcomingAvailableSectionCount: number;
  upcomingSectionCount: number;
  activeSections: number;
  upsell_activity_uid: string | null;
  user_uid: string;
  wasPublishedInThePast: boolean;
  weekly_meetings: number | null;
  welcomeAttachmentCount: number | null;
  why_me: string | null;
  approvalStatus: {
    __typename?: "ActivityApprovalStatus";
    action: string;
    message_to_teacher: string | null;
  } | null;
  attributes: Array<{
    __typename?: "EntityAttribute";
    key: string;
    value: string;
  }>;
  clubGuidelines: { __typename?: "WelcomeMessage"; text: string | null } | null;
  clubSection: {
    __typename?: "Section";
    uid: string;
    validEnrollmentCount: number;
    isBuyable: boolean;
  } | null;
  details: {
    __typename?: "ActivityDetails";
    asyncTime: string | null;
    photo: string | null;
  };
  externalResources: Array<{
    __typename?: "ExternalResource";
    uid: string;
    name: string;
    url: string | null;
  }>;
  leader: {
    __typename?: "Leader";
    uid: string;
    approved: boolean;
    averageActivityStarRating: number | null;
    cityState: string | null;
    created_at: any | null;
    educatorTiers: Array<EducatorTier>;
    hasAvailabilitySet: boolean;
    hasBackgroundCheckExpired: boolean | null;
    is_crosslister: boolean | null;
    leader_link: string | null;
    name: string | null;
    photo: string | null;
    publishedClassCount: number;
    reviewCount: number;
    socialOptOut: boolean;
    successfulClassCount: number;
    suspended_at: any | null;
    teacher_approved_at: any | null;
    timeZone: string | null;
    details: {
      __typename?: "LeaderDetails";
      about: string | null;
      countryOfResidence: string | null;
      headline: string | null;
      headlineTranslated: string | null;
    };
    ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    teacherForSellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
    } | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    credentials: Array<{
      __typename?: "TeacherCredential";
      uid: string;
      degreeType: DegreeType | null;
      degreeSubject: DegreeSubject | null;
      certificateDiscipline: TeacherCertificateDiscipline | null;
      otherType: string | null;
      certificateState: TeacherCertificateState | null;
      name: string | null;
      degreeInstitution: string | null;
      degreeAdditionalInfo: string | null;
    }>;
    leaderClassLinks: Array<{
      __typename?: "Bundle";
      uid: string;
      title: string;
      isOrdered: boolean;
      bundleElements: Array<{
        __typename?: "BundleElement";
        orderInBundle: number;
        activity: { __typename?: "Activity"; uid: string };
      }>;
    }>;
  };
  refundPolicy: {
    __typename?: "RefundPolicy";
    name: string;
    description: string;
    type: RefundPolicyType;
  };
  relatedCategories: Array<{
    __typename?: "CategoryPage";
    uid: string;
    slug: string;
    basePath: string;
    nameTitleCased: string;
  } | null> | null;
  sampleClubPosts: Array<{
    __typename?: "SampleClubPost";
    text: string | null;
    attachments: Array<{
      __typename?: "Attachment";
      uid: string;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
      };
    }> | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
      };
    } | null;
    comments: Array<{
      __typename?: "SampleClubPostComment";
      text: string | null;
    }>;
  }> | null;
  savedClassLists: Array<{
    __typename?: "SavedClassList";
    uid: string;
    lastAddedToAt: any | null;
  }>;
  syllabus: {
    __typename?: "Syllabus";
    uid: string;
    published_at: string | null;
    lessons: Array<{
      __typename?: "Lesson";
      uid: string;
      title: string | null;
      description: string | null;
      week_number: number;
      order_within_week: number;
      lessonNumber: number | null;
      videoDurationSeconds: number;
      assignmentsCount: number;
      unit: {
        __typename?: "Unit";
        uid: string;
        title: string | null;
        unitNumber: number | null;
      } | null;
    }>;
  } | null;
  video: {
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  } | null;
  proficiency_level: {
    __typename?: "ProficiencyLevel";
    level: Array<string | null> | null;
    text: string | null;
  } | null;
};

export type ClassDetailsNoSectionsQueryVariables = Exact<{
  uid?: InputMaybe<Scalars["ID"]>;
  slugId?: InputMaybe<Scalars["String"]>;
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type ClassDetailsNoSectionsQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    age_max: number | null;
    age_min: number | null;
    grade_level_max: string | null;
    grade_level_min: string | null;
    url: string;
    path: string;
    allows_late_enrollments_for_fixed_length_classes: boolean;
    allows_recurring_payment: boolean | null;
    has_building_content: boolean | null;
    archived_at: any | null;
    assessment: string | null;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    averageStarRating: number | null;
    canEnroll: boolean;
    classFormat: EducatorSelectedFormat;
    class_experience: string | null;
    completedEnrollmentCount: number;
    disclaimer: string | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    english_proficiency_level: EnglishProficiencyLevel | null;
    esaStatus: EsaStatus;
    first_listed_at: any | null;
    has_external_resources: boolean | null;
    hasTeacherSchedule: boolean;
    homework: string | null;
    grading_policy: string | null;
    is_ongoing_weekly: boolean;
    is_online: boolean;
    isAutoSchedulingEnabled: boolean | null;
    allowWaitlist: boolean | null;
    isClub: boolean;
    isFlexSchedule: boolean;
    isOneTime: boolean;
    isPublished: boolean;
    isRestricted: boolean;
    isSelfPaced: boolean;
    isSoldOut: boolean;
    languageOfInstruction: any | null;
    learning_goals: string | null;
    learningGoals: Array<string>;
    materials: string | null;
    meetingCount: number | null;
    parental_guidance: string | null;
    prerequisites: string | null;
    price_cents: number | null;
    priceCredits: number | null;
    perWeeklyCourseCredits: number | null;
    published_at: any | null;
    requested_listing_at: any | null;
    reviewCount: number;
    size_max: number | null;
    size_min: number | null;
    slug_id: string;
    sources: string | null;
    subject: string | null;
    suggestedWeeklyMeetings: number | null;
    summary: string | null;
    summaryTranslated: string | null;
    summaryForLearners: string | null;
    title: string;
    titleTranslated: string | null;
    upcomingAvailableSectionCount: number;
    upcomingSectionCount: number;
    activeSections: number;
    upsell_activity_uid: string | null;
    user_uid: string;
    wasPublishedInThePast: boolean;
    weekly_meetings: number | null;
    welcomeAttachmentCount: number | null;
    why_me: string | null;
    approvalStatus: {
      __typename?: "ActivityApprovalStatus";
      action: string;
      message_to_teacher: string | null;
    } | null;
    attributes: Array<{
      __typename?: "EntityAttribute";
      key: string;
      value: string;
    }>;
    clubGuidelines: {
      __typename?: "WelcomeMessage";
      text: string | null;
    } | null;
    clubSection: {
      __typename?: "Section";
      uid: string;
      validEnrollmentCount: number;
      isBuyable: boolean;
    } | null;
    details: {
      __typename?: "ActivityDetails";
      asyncTime: string | null;
      photo: string | null;
    };
    externalResources: Array<{
      __typename?: "ExternalResource";
      uid: string;
      name: string;
      url: string | null;
    }>;
    leader: {
      __typename?: "Leader";
      uid: string;
      approved: boolean;
      averageActivityStarRating: number | null;
      cityState: string | null;
      created_at: any | null;
      educatorTiers: Array<EducatorTier>;
      hasAvailabilitySet: boolean;
      hasBackgroundCheckExpired: boolean | null;
      is_crosslister: boolean | null;
      leader_link: string | null;
      name: string | null;
      photo: string | null;
      publishedClassCount: number;
      reviewCount: number;
      socialOptOut: boolean;
      successfulClassCount: number;
      suspended_at: any | null;
      teacher_approved_at: any | null;
      timeZone: string | null;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        countryOfResidence: string | null;
        headline: string | null;
        headlineTranslated: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      } | null;
      credentials: Array<{
        __typename?: "TeacherCredential";
        uid: string;
        degreeType: DegreeType | null;
        degreeSubject: DegreeSubject | null;
        certificateDiscipline: TeacherCertificateDiscipline | null;
        otherType: string | null;
        certificateState: TeacherCertificateState | null;
        name: string | null;
        degreeInstitution: string | null;
        degreeAdditionalInfo: string | null;
      }>;
      leaderClassLinks: Array<{
        __typename?: "Bundle";
        uid: string;
        title: string;
        isOrdered: boolean;
        bundleElements: Array<{
          __typename?: "BundleElement";
          orderInBundle: number;
          activity: { __typename?: "Activity"; uid: string };
        }>;
      }>;
    };
    refundPolicy: {
      __typename?: "RefundPolicy";
      name: string;
      description: string;
      type: RefundPolicyType;
    };
    relatedCategories: Array<{
      __typename?: "CategoryPage";
      uid: string;
      slug: string;
      basePath: string;
      nameTitleCased: string;
    } | null> | null;
    sampleClubPosts: Array<{
      __typename?: "SampleClubPost";
      text: string | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      }> | null;
      video: {
        __typename?: "Attachment";
        uid: string;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
        };
      } | null;
      comments: Array<{
        __typename?: "SampleClubPostComment";
        text: string | null;
      }>;
    }> | null;
    savedClassLists: Array<{
      __typename?: "SavedClassList";
      uid: string;
      lastAddedToAt: any | null;
    }>;
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      published_at: string | null;
      lessons: Array<{
        __typename?: "Lesson";
        uid: string;
        title: string | null;
        description: string | null;
        week_number: number;
        order_within_week: number;
        lessonNumber: number | null;
        videoDurationSeconds: number;
        assignmentsCount: number;
        unit: {
          __typename?: "Unit";
          uid: string;
          title: string | null;
          unitNumber: number | null;
        } | null;
      }>;
    } | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
      };
    } | null;
    proficiency_level: {
      __typename?: "ProficiencyLevel";
      level: Array<string | null> | null;
      text: string | null;
    } | null;
  };
};

export type ClassDetailsSectionQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
}>;

export type ClassDetailsSectionQuery = {
  __typename?: "Query";
  activitySection: {
    __typename?: "Section";
    uid: string;
    activity_uid: string;
    allowsLateEnrollment: boolean;
    canceled_at: any | null;
    created_at: any;
    currentUserCanManage: boolean;
    currentUserIsOwner: boolean;
    deleted_at: any | null;
    duration_minutes: number | null;
    end_time: any | null;
    filledSpaceCount: number;
    isBuyable: boolean;
    isInProgress: boolean;
    isPublished: boolean | null;
    ongoing_stopped_at: any | null;
    price_cents: number | null;
    published_at: any | null;
    start_time: any | null;
    size_max: number | null;
    size_min: number | null;
    updated_at: any;
    usesOutschoolVideoChat: boolean;
    validEnrollmentCount: number;
    details: {
      __typename?: "SectionDetails";
      autoScheduledDraft: boolean | null;
      onlineClassroom: {
        __typename?: "OnlineClassroom";
        type: string | null;
        url: string | null;
      } | null;
    };
    leader: { __typename?: "Leader"; uid: string; name: string | null };
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    }>;
    meetingTimes: Array<{
      __typename?: "MeetingTime";
      dayOfWeek: number;
      hour: number;
      minute: number;
    }>;
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
      deleted_at: any | null;
      created_at: any | null;
    } | null;
    progress: {
      __typename?: "CourseProgress";
      currentWeek: number;
      pastMeetingCount: number;
      remainingMeetingCount: number;
      totalMeetingCount: number;
      nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
    } | null;
  } | null;
};

export type SubjectLink_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  subject: string | null;
};

export type ActivityHeader_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  isClub: boolean;
  summary: string | null;
  summaryTranslated: string | null;
  title: string;
  titleTranslated: string | null;
  slug_id: string;
  isSoldOut: boolean;
  completedEnrollmentCount: number;
  reviewCount: number;
  averageStarRating: number | null;
  subject: string | null;
  price_cents: number | null;
  video: {
    __typename?: "Attachment";
    file: { __typename?: "File"; url: string; thumbUrl: string | null };
  } | null;
  sections: Array<{
    __typename?: "Section";
    uid: string;
    filledSpaceCount: number;
  }>;
  details: { __typename?: "ActivityDetails"; photo: string | null };
  savedClassLists: Array<{
    __typename?: "SavedClassList";
    uid: string;
    lastAddedToAt: any | null;
  }>;
};

export type ActivityHeader_LeaderFragment = {
  __typename?: "Leader";
  uid: string;
  publishedClassCount: number;
  name: string | null;
  suspended_at: any | null;
  teacher_approved_at: any | null;
  reviewCount: number;
  averageActivityStarRating: number | null;
  photo: string | null;
  leader_link: string | null;
  educatorTiers: Array<EducatorTier>;
  details: {
    __typename?: "LeaderDetails";
    headline: string | null;
    about: string | null;
  };
  video: {
    __typename?: "Attachment";
    file: { __typename?: "File"; url: string; thumbUrl: string | null };
  } | null;
  credentials: Array<{
    __typename?: "TeacherCredential";
    uid: string;
    degreeType: DegreeType | null;
    degreeSubject: DegreeSubject | null;
    certificateDiscipline: TeacherCertificateDiscipline | null;
    otherType: string | null;
    certificateState: TeacherCertificateState | null;
    name: string | null;
    degreeInstitution: string | null;
    degreeAdditionalInfo: string | null;
  }>;
  ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
};

export type ScheduleSuggestionsQueryVariables = Exact<{
  uid: Scalars["ID"];
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  endAtDate?: InputMaybe<Scalars["DateTime"]>;
  startAfterTime?: InputMaybe<Scalars["Int"]>;
  endByTime?: InputMaybe<Scalars["Int"]>;
  dow?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type ScheduleSuggestionsQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    scheduleSuggestions: Array<
      Array<{
        __typename?: "MeetingSuggestion";
        startTime: any | null;
        endTime: any | null;
      }>
    > | null;
  };
};

export type SignupButton_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  slug_id: string;
  title: string;
  isClub: boolean;
  isSelfPaced: boolean;
  isSoldOut: boolean;
  wasPublishedInThePast: boolean;
  canEnroll: boolean;
  allows_late_enrollments_for_fixed_length_classes: boolean;
  hasTeacherSchedule: boolean;
};

export type TeacherVideoModal_TeacherFragment = {
  __typename?: "Leader";
  uid: string;
  name: string | null;
  photo: string | null;
  leader_link: string | null;
  video: {
    __typename?: "Attachment";
    file: { __typename?: "File"; url: string; thumbUrl: string | null };
  } | null;
};

export type ClassDetailsSections_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  is_ongoing_weekly: boolean;
  paginatedFilteredSections: {
    __typename?: "SectionList";
    totalCount: number;
    data: Array<{
      __typename: "Section";
      uid: string;
      activity_uid: string;
      allowsLateEnrollment: boolean;
      canceled_at: any | null;
      created_at: any;
      currentUserCanManage: boolean;
      currentUserIsOwner: boolean;
      deleted_at: any | null;
      duration_minutes: number | null;
      end_time: any | null;
      filledSpaceCount: number;
      isBuyable: boolean;
      isInProgress: boolean;
      isPublished: boolean | null;
      ongoing_stopped_at: any | null;
      price_cents: number | null;
      published_at: any | null;
      start_time: any | null;
      size_max: number | null;
      size_min: number | null;
      updated_at: any;
      usesOutschoolVideoChat: boolean;
      validEnrollmentCount: number;
      details: {
        __typename?: "SectionDetails";
        autoScheduledDraft: boolean | null;
        onlineClassroom: {
          __typename?: "OnlineClassroom";
          type: string | null;
          url: string | null;
        } | null;
      };
      leader: { __typename?: "Leader"; uid: string; name: string | null };
      meetings: Array<{
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        deleted_at: any | null;
        created_at: any | null;
      }>;
      meetingTimes: Array<{
        __typename?: "MeetingTime";
        dayOfWeek: number;
        hour: number;
        minute: number;
      }>;
      nextOngoingMeeting: {
        __typename?: "Meeting";
        uid: string;
        start_time: any;
        end_time: any;
        deleted_at: any | null;
        created_at: any | null;
      } | null;
      progress: {
        __typename?: "CourseProgress";
        currentWeek: number;
        pastMeetingCount: number;
        remainingMeetingCount: number;
        totalMeetingCount: number;
        nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
      } | null;
    }>;
  };
};

export type ClassDetailsSectionsQueryVariables = Exact<{
  uid?: InputMaybe<Scalars["ID"]>;
  slugId?: InputMaybe<Scalars["String"]>;
  sectionFilter?: InputMaybe<SectionFilter>;
  esaBlackoutLengthDays?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type ClassDetailsSectionsQuery = {
  __typename?: "Query";
  activity: {
    __typename: "Activity";
    uid: string;
    is_ongoing_weekly: boolean;
    paginatedFilteredSections: {
      __typename?: "SectionList";
      totalCount: number;
      data: Array<{
        __typename: "Section";
        uid: string;
        activity_uid: string;
        allowsLateEnrollment: boolean;
        canceled_at: any | null;
        created_at: any;
        currentUserCanManage: boolean;
        currentUserIsOwner: boolean;
        deleted_at: any | null;
        duration_minutes: number | null;
        end_time: any | null;
        filledSpaceCount: number;
        isBuyable: boolean;
        isInProgress: boolean;
        isPublished: boolean | null;
        ongoing_stopped_at: any | null;
        price_cents: number | null;
        published_at: any | null;
        start_time: any | null;
        size_max: number | null;
        size_min: number | null;
        updated_at: any;
        usesOutschoolVideoChat: boolean;
        validEnrollmentCount: number;
        details: {
          __typename?: "SectionDetails";
          autoScheduledDraft: boolean | null;
          onlineClassroom: {
            __typename?: "OnlineClassroom";
            type: string | null;
            url: string | null;
          } | null;
        };
        leader: { __typename?: "Leader"; uid: string; name: string | null };
        meetings: Array<{
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        }>;
        meetingTimes: Array<{
          __typename?: "MeetingTime";
          dayOfWeek: number;
          hour: number;
          minute: number;
        }>;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
          deleted_at: any | null;
          created_at: any | null;
        } | null;
        progress: {
          __typename?: "CourseProgress";
          currentWeek: number;
          pastMeetingCount: number;
          remainingMeetingCount: number;
          totalMeetingCount: number;
          nextMeeting: { __typename?: "Meeting"; start_time: any } | null;
        } | null;
      }>;
    };
  };
};

export type MagnetPageItemsFragment = {
  __typename?: "ContentfulMagnetPage";
  magnetPageSlug: string | null;
  title: string | null;
  categoryLabel: {
    __typename?: "ContentfulCategoryLabel";
    value: string | null;
  } | null;
  author: {
    __typename?: "ContentfulAuthor";
    name: string | null;
    profileImage: { __typename?: "ContentfulAsset"; url: string | null } | null;
  } | null;
  headerImage: { __typename?: "ContentfulAsset"; url: string | null } | null;
};

export type GetArticlesBySlugQueryVariables = Exact<{
  categoryLabel: Scalars["String"];
}>;

export type GetArticlesBySlugQuery = {
  __typename?: "Query";
  contentfulMagnetPageCollection: {
    __typename?: "ContentfulMagnetPageCollection";
    items: Array<{
      __typename?: "ContentfulMagnetPage";
      magnetPageSlug: string | null;
      title: string | null;
      categoryLabel: {
        __typename?: "ContentfulCategoryLabel";
        value: string | null;
      } | null;
      author: {
        __typename?: "ContentfulAuthor";
        name: string | null;
        profileImage: {
          __typename?: "ContentfulAsset";
          url: string | null;
        } | null;
      } | null;
      headerImage: {
        __typename?: "ContentfulAsset";
        url: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetArticlesByIdQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetArticlesByIdQuery = {
  __typename?: "Query";
  contentfulFeaturedPagesCollection: {
    __typename?: "ContentfulFeaturedPagesCollection";
    items: Array<{
      __typename?: "ContentfulFeaturedPages";
      pagesCollection: {
        __typename?: "ContentfulFeaturedPagesPagesCollection";
        items: Array<{
          __typename?: "ContentfulMagnetPage";
          magnetPageSlug: string | null;
          title: string | null;
          categoryLabel: {
            __typename?: "ContentfulCategoryLabel";
            value: string | null;
          } | null;
          author: {
            __typename?: "ContentfulAuthor";
            name: string | null;
            profileImage: {
              __typename?: "ContentfulAsset";
              url: string | null;
            } | null;
          } | null;
          headerImage: {
            __typename?: "ContentfulAsset";
            url: string | null;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type FollowedUserFragmentFragment = {
  __typename?: "FollowedUser";
  uid: string;
  followed_uid: string;
  created_at: any;
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    leader_link: string | null;
    created_at: any | null;
    photo: string | null;
    suspended_at: any | null;
    cityState: string | null;
    socialOptOut: boolean;
    is_crosslister: boolean | null;
    timeZone: string | null;
    hasAvailabilitySet: boolean;
    approved: boolean;
    hasBackgroundCheckExpired: boolean | null;
    details: {
      __typename?: "LeaderDetails";
      about: string | null;
      headline: string | null;
      headlineTranslated: string | null;
      countryOfResidence: string | null;
    };
    ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
    teacherForSellerOrg: {
      __typename?: "SellerOrg";
      uid: string;
      name: string;
    } | null;
  };
};

export type FollowedTeachersQueryVariables = Exact<{ [key: string]: never }>;

export type FollowedTeachersQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    followedUsers: Array<{
      __typename?: "FollowedUser";
      uid: string;
      followed_uid: string;
      created_at: any;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        leader_link: string | null;
        created_at: any | null;
        photo: string | null;
        suspended_at: any | null;
        cityState: string | null;
        socialOptOut: boolean;
        is_crosslister: boolean | null;
        timeZone: string | null;
        hasAvailabilitySet: boolean;
        approved: boolean;
        hasBackgroundCheckExpired: boolean | null;
        details: {
          __typename?: "LeaderDetails";
          about: string | null;
          headline: string | null;
          headlineTranslated: string | null;
          countryOfResidence: string | null;
        };
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        teacherForSellerOrg: {
          __typename?: "SellerOrg";
          uid: string;
          name: string;
        } | null;
      };
    }>;
  } | null;
};

export type FollowUserMutationVariables = Exact<{
  userUid: Scalars["ID"];
  referer?: InputMaybe<Scalars["String"]>;
  attribution?: InputMaybe<Attribution>;
}>;

export type FollowUserMutation = {
  __typename?: "Mutation";
  followUser: {
    __typename?: "FollowedUser";
    uid: string;
    followed_uid: string;
    created_at: any;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      leader_link: string | null;
      created_at: any | null;
      photo: string | null;
      suspended_at: any | null;
      cityState: string | null;
      socialOptOut: boolean;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      hasBackgroundCheckExpired: boolean | null;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        headlineTranslated: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
    };
  };
};

export type ContentfulHomePage2QueryVariables = Exact<{
  locale?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ContentfulHomePage2Query = {
  __typename?: "Query";
  contentfulHomePage2Collection: {
    __typename?: "ContentfulHomePage2Collection";
    items: Array<{
      __typename?: "ContentfulHomePage2";
      title: string | null;
      heroSlidesCollection: {
        __typename?: "ContentfulHomePage2HeroSlidesCollection";
        items: Array<{
          __typename?: "ContentfulHeroSlide";
          title: string | null;
          image: { __typename?: "ContentfulAsset"; url: string | null } | null;
        } | null>;
      } | null;
      popularSearchLinksCollection: {
        __typename?: "ContentfulHomePage2PopularSearchLinksCollection";
        items: Array<{
          __typename?: "ContentfulVisualLink";
          text: string | null;
          path: string | null;
        } | null>;
      } | null;
      valuePropsCollection: {
        __typename?: "ContentfulHomePage2ValuePropsCollection";
        items: Array<{
          __typename?: "ContentfulValueProp";
          title: string | null;
          icon: { __typename?: "ContentfulAsset"; url: string | null } | null;
        } | null>;
      } | null;
      visualLinkGridsCollection: {
        __typename?: "ContentfulHomePage2VisualLinkGridsCollection";
        items: Array<{
          __typename?: "ContentfulVisualLinkGrid";
          title: string | null;
          style: string | null;
          visualLinksCollection: {
            __typename?: "ContentfulVisualLinkGridVisualLinksCollection";
            items: Array<{
              __typename?: "ContentfulVisualLink";
              text: string | null;
              path: string | null;
              imageAltText: string | null;
              imageAspectRatio: string | null;
              image: {
                __typename?: "ContentfulAsset";
                url: string | null;
              } | null;
            } | null>;
          } | null;
        } | null>;
      } | null;
    } | null>;
  } | null;
};

export type FeaturedTeacherCard_LeaderFragment = {
  __typename?: "Leader";
  uid: string;
  name: string | null;
  photo: string | null;
  leader_link: string | null;
  details: {
    __typename?: "LeaderDetails";
    headline: string | null;
    headlineTranslated: string | null;
  };
};

export type HomeWithNoTopicsQueryVariables = Exact<{ [key: string]: never }>;

export type HomeWithNoTopicsQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    popularTeachers: Array<{
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      details: {
        __typename?: "LeaderDetails";
        headline: string | null;
        headlineTranslated: string | null;
      };
    }> | null;
  };
};

export type AddChildrenMutationVariables = Exact<{
  childInputs: Array<ChildInput> | ChildInput;
}>;

export type AddChildrenMutation = {
  __typename?: "Mutation";
  addChildren: Array<{
    __typename?: "Child";
    uid: string;
    name: string | null;
    age: number | null;
    birthMonth: number | null;
    date_of_birth: any | null;
    birthYear: number | null;
    email: string | null;
    avatar: string | null;
    unlockedAvatars: Array<string>;
    pronoun: string | null;
    intro: string | null;
    deleted_at: any | null;
    email_confirmed_at: any | null;
    optedOutOfPersonalizedRecommendationsAt: any | null;
    isLoginEnabled: boolean;
    username: string | null;
    hasPassword: boolean;
    interestTopics: {
      __typename?: "TopicsConnection";
      topics: Array<{ __typename?: "Topic"; uid: string; label: string }>;
    };
    attributes: Array<{
      __typename?: "EntityAttribute";
      uid: string;
      key: string;
      value: string;
    }>;
    entityAvailabilitySchedule: {
      __typename?: "EntityAvailabilitySchedule";
      entityAvailabilities: Array<{
        __typename?: "EntityAvailability";
        startHour: number;
        startMinute: number;
        day: number;
        endHour: number;
        endMinute: number;
        uid: string;
      }>;
    } | null;
  }>;
};

export type AddToPlannerCdpButton_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  isSelfPaced: boolean;
  duration_weeks: number | null;
};

export type SaveLearnerPlanActivityMutationVariables = Exact<{
  learnerUid: Scalars["ID"];
  activityUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
  isSuggested?: InputMaybe<Scalars["Boolean"]>;
  isPending?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SaveLearnerPlanActivityMutation = {
  __typename?: "Mutation";
  saveLearnerPlanActivity: Array<{
    __typename?: "LearnerPlanActivity";
    uid: string;
    learner_plan_uid: string;
    subject: string | null;
  }> | null;
};

export type SaveLearnerPlanActivityWithMeetingTimesMutationVariables = Exact<{
  learnerUid: Scalars["ID"];
  activityUid: Scalars["ID"];
  meetingStartTime: Scalars["DateTime"];
  meetingEndTime: Scalars["DateTime"];
  meetingDurationWeeks: Scalars["Int"];
}>;

export type SaveLearnerPlanActivityWithMeetingTimesMutation = {
  __typename?: "Mutation";
  saveLearnerPlanActivityWithMeetingTimes: Array<{
    __typename?: "LearnerPlanActivity";
    uid: string;
    learner_plan_uid: string;
    subject: string | null;
  }> | null;
};

export type AddToPlannerEnrollmentFragmentFragment = {
  __typename?: "Enrollment";
  uid: string;
  section_uid: string | null;
  price_cents: number | null;
  priceCents: number | null;
  created_at: any | null;
  ongoing_start_time: any | null;
  ongoing_end_time: any | null;
  withdrawnAt: any | null;
  ongoingStoppedAt: any | null;
  ongoingStartTime: any | null;
  ongoingEndTime: any | null;
  canBeWithdrawn: boolean;
  canBeStopped: boolean;
  canBePaused: boolean;
};

export type AddToPlannerCreateEnrollmentsMutationVariables = Exact<{
  orderInput: OrderInput;
  esaState?: InputMaybe<Scalars["String"]>;
}>;

export type AddToPlannerCreateEnrollmentsMutation = {
  __typename?: "Mutation";
  createEnrollments: {
    __typename?: "Order";
    orderSlugId: string;
    enrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      section_uid: string | null;
      price_cents: number | null;
      priceCents: number | null;
      created_at: any | null;
      ongoing_start_time: any | null;
      ongoing_end_time: any | null;
      withdrawnAt: any | null;
      ongoingStoppedAt: any | null;
      ongoingStartTime: any | null;
      ongoingEndTime: any | null;
      canBeWithdrawn: boolean;
      canBeStopped: boolean;
      canBePaused: boolean;
      pricingOffer: { __typename?: "PricingOffer"; uid: string } | null;
    }>;
  };
};

export type LearnerPlanForGoalQueryVariables = Exact<{
  learnerPlanGoalUid: Scalars["ID"];
}>;

export type LearnerPlanForGoalQuery = {
  __typename?: "Query";
  learnerPlanForGoal: {
    __typename?: "LearnerPlan";
    uid: string;
    name: string | null;
    start_date: any | null;
    end_date: any | null;
    learner: {
      __typename?: "Learner";
      uid: string;
      name: string | null;
    } | null;
  } | null;
};

export type LearnerPlanRejectedSuggestionsForLearnerQueryVariables = Exact<{
  learnerUid: Scalars["ID"];
}>;

export type LearnerPlanRejectedSuggestionsForLearnerQuery = {
  __typename?: "Query";
  learnerPlanRejectedSuggestionsForLearner: Array<{
    __typename?: "LearnerPlanRejectedSuggestion";
    uid: string;
    activity: { __typename?: "Activity"; uid: string } | null;
  }>;
};

export type ListingCarousel_ListingFragment = {
  __typename?: "Listing";
  insertionId: string | null;
  sectionCount: number;
  openSectionCount: number;
  upcomingSectionCount: number;
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    photo: string | null;
    leader_link: string | null;
    averageActivityStarRating: number | null;
    reviewCount: number;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
        fileSize: number | null;
      };
    } | null;
    credentials: Array<{
      __typename?: "TeacherCredential";
      uid: string;
      degreeType: DegreeType | null;
      degreeSubject: DegreeSubject | null;
    }>;
  };
  activity: {
    __typename?: "Activity";
    uid: string;
    averageStarRating?: number | null;
    reviewCount: number;
    age_min: number | null;
    age_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    isClub: boolean;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isOneTime: boolean;
    hasTeacherSchedule: boolean;
    size_max: number | null;
    user_uid: string;
    slug_id: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    recentReviews?: Array<{
      __typename?: "PublicReview";
      uid: string;
      activity_star_rating: number | null;
      details: {
        __typename?: "PublicReviewDetails";
        publicReview: string | null;
      } | null;
      parent: { __typename?: "Parent"; uid: string; publicName: string | null };
    }>;
    clubSection: {
      __typename?: "Section";
      filledSpaceCount: number;
      clubPostCount: number;
    } | null;
    details: { __typename?: "ActivityDetails"; photo: string | null };
    savedClassLists: Array<{
      __typename?: "SavedClassList";
      uid: string;
      lastAddedToAt: any | null;
    }>;
  };
  nextSection: {
    __typename?: "Section";
    uid: string;
    size_max: number | null;
    filledSpaceCount: number;
    start_time: any | null;
    end_time: any | null;
  } | null;
};

export type ListingsByActivityUidsQueryVariables = Exact<{
  activityUids: Array<Scalars["ID"]> | Scalars["ID"];
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type ListingsByActivityUidsQuery = {
  __typename?: "Query";
  listingsByActivityUids: Array<{
    __typename?: "Listing";
    insertionId: string | null;
    sectionCount: number;
    openSectionCount: number;
    upcomingSectionCount: number;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
      averageActivityStarRating: number | null;
      reviewCount: number;
      video: {
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
          fileSize: number | null;
        };
      } | null;
      credentials: Array<{
        __typename?: "TeacherCredential";
        uid: string;
        degreeType: DegreeType | null;
        degreeSubject: DegreeSubject | null;
      }>;
    };
    activity: {
      __typename?: "Activity";
      uid: string;
      averageStarRating?: number | null;
      reviewCount: number;
      age_min: number | null;
      age_max: number | null;
      duration_minutes: number | null;
      duration_weeks: number | null;
      weekly_meetings: number | null;
      price_cents: number | null;
      isClub: boolean;
      isFlexSchedule: boolean;
      isSelfPaced: boolean;
      isOneTime: boolean;
      hasTeacherSchedule: boolean;
      size_max: number | null;
      user_uid: string;
      slug_id: string;
      title: string;
      titleTranslated: string | null;
      url: string;
      path: string;
      summary: string | null;
      summaryTranslated: string | null;
      is_ongoing_weekly: boolean;
      subject: string | null;
      recentReviews?: Array<{
        __typename?: "PublicReview";
        uid: string;
        activity_star_rating: number | null;
        details: {
          __typename?: "PublicReviewDetails";
          publicReview: string | null;
        } | null;
        parent: {
          __typename?: "Parent";
          uid: string;
          publicName: string | null;
        };
      }>;
      clubSection: {
        __typename?: "Section";
        filledSpaceCount: number;
        clubPostCount: number;
      } | null;
      details: { __typename?: "ActivityDetails"; photo: string | null };
      savedClassLists: Array<{
        __typename?: "SavedClassList";
        uid: string;
        lastAddedToAt: any | null;
      }>;
    };
    nextSection: {
      __typename?: "Section";
      uid: string;
      size_max: number | null;
      filledSpaceCount: number;
      start_time: any | null;
      end_time: any | null;
    } | null;
  }>;
};

export type OtherListingsByTeacherQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type OtherListingsByTeacherQuery = {
  __typename?: "Query";
  listing: {
    __typename?: "Listing";
    similarListings: {
      __typename?: "SimilarListings";
      sameTeacher: Array<{
        __typename?: "Listing";
        insertionId: string | null;
        sectionCount: number;
        openSectionCount: number;
        upcomingSectionCount: number;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          leader_link: string | null;
          averageActivityStarRating: number | null;
          reviewCount: number;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
              fileSize: number | null;
            };
          } | null;
          credentials: Array<{
            __typename?: "TeacherCredential";
            uid: string;
            degreeType: DegreeType | null;
            degreeSubject: DegreeSubject | null;
          }>;
        };
        activity: {
          __typename?: "Activity";
          uid: string;
          averageStarRating?: number | null;
          reviewCount: number;
          age_min: number | null;
          age_max: number | null;
          duration_minutes: number | null;
          duration_weeks: number | null;
          weekly_meetings: number | null;
          price_cents: number | null;
          isClub: boolean;
          isFlexSchedule: boolean;
          isSelfPaced: boolean;
          isOneTime: boolean;
          hasTeacherSchedule: boolean;
          size_max: number | null;
          user_uid: string;
          slug_id: string;
          title: string;
          titleTranslated: string | null;
          url: string;
          path: string;
          summary: string | null;
          summaryTranslated: string | null;
          is_ongoing_weekly: boolean;
          subject: string | null;
          recentReviews?: Array<{
            __typename?: "PublicReview";
            uid: string;
            activity_star_rating: number | null;
            details: {
              __typename?: "PublicReviewDetails";
              publicReview: string | null;
            } | null;
            parent: {
              __typename?: "Parent";
              uid: string;
              publicName: string | null;
            };
          }>;
          clubSection: {
            __typename?: "Section";
            filledSpaceCount: number;
            clubPostCount: number;
          } | null;
          details: { __typename?: "ActivityDetails"; photo: string | null };
          savedClassLists: Array<{
            __typename?: "SavedClassList";
            uid: string;
            lastAddedToAt: any | null;
          }>;
        };
        nextSection: {
          __typename?: "Section";
          uid: string;
          size_max: number | null;
          filledSpaceCount: number;
          start_time: any | null;
          end_time: any | null;
        } | null;
      }>;
    };
  } | null;
};

export type OtherListingsByTeacherMobileQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  skipRating: Scalars["Boolean"];
}>;

export type OtherListingsByTeacherMobileQuery = {
  __typename?: "Query";
  listing: {
    __typename?: "Listing";
    similarListings: {
      __typename?: "SimilarListings";
      sameTeacher: Array<{
        __typename?: "Listing";
        insertionId: string | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          user_uid: string;
          title: string;
          url: string;
          path: string;
          summary: string | null;
          summaryTranslated: string | null;
          weekly_meetings: number | null;
          duration_weeks: number | null;
          is_ongoing_weekly: boolean;
          isClub: boolean;
          published_at: any | null;
          size_max: number | null;
          size_min: number | null;
          hasTeacherSchedule: boolean;
          allows_recurring_payment: boolean | null;
          averageStarRating?: number | null;
          reviewCount: number;
          age_min: number | null;
          age_max: number | null;
          duration_minutes: number | null;
          price_cents: number | null;
          priceCreditsPerMeeting: number | null;
          isFlexSchedule: boolean;
          isSelfPaced: boolean;
          canEnroll: boolean;
          isOneTime: boolean;
          titleTranslated: string | null;
          slug_id: string;
          subject: string | null;
          syllabus: {
            __typename?: "Syllabus";
            uid: string;
            lessons: Array<{ __typename?: "Lesson"; uid: string }>;
          } | null;
          clubSection: {
            __typename?: "Section";
            filledSpaceCount: number;
            clubPostCount: number;
          } | null;
          details: { __typename?: "ActivityDetails"; photo: string | null };
          savedClassLists: Array<{
            __typename?: "SavedClassList";
            uid: string;
            lastAddedToAt: any | null;
          }>;
        };
        nextSection: {
          __typename?: "Section";
          uid: string;
          size_max: number | null;
          filledSpaceCount: number;
          start_time: any | null;
          end_time: any | null;
          nextOngoingMeeting: {
            __typename?: "Meeting";
            uid: string;
            start_time: any;
            end_time: any;
          } | null;
        } | null;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          leader_link: string | null;
          averageActivityStarRating: number | null;
          reviewCount: number;
          ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        };
      }>;
    };
  } | null;
};

export type SimilarListingsQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type SimilarListingsQuery = {
  __typename?: "Query";
  listing: {
    __typename?: "Listing";
    similarListings: {
      __typename?: "SimilarListings";
      popular: Array<{
        __typename?: "Listing";
        insertionId: string | null;
        sectionCount: number;
        openSectionCount: number;
        upcomingSectionCount: number;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          leader_link: string | null;
          averageActivityStarRating: number | null;
          reviewCount: number;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
              fileSize: number | null;
            };
          } | null;
          credentials: Array<{
            __typename?: "TeacherCredential";
            uid: string;
            degreeType: DegreeType | null;
            degreeSubject: DegreeSubject | null;
          }>;
        };
        activity: {
          __typename?: "Activity";
          uid: string;
          averageStarRating?: number | null;
          reviewCount: number;
          age_min: number | null;
          age_max: number | null;
          duration_minutes: number | null;
          duration_weeks: number | null;
          weekly_meetings: number | null;
          price_cents: number | null;
          isClub: boolean;
          isFlexSchedule: boolean;
          isSelfPaced: boolean;
          isOneTime: boolean;
          hasTeacherSchedule: boolean;
          size_max: number | null;
          user_uid: string;
          slug_id: string;
          title: string;
          titleTranslated: string | null;
          url: string;
          path: string;
          summary: string | null;
          summaryTranslated: string | null;
          is_ongoing_weekly: boolean;
          subject: string | null;
          recentReviews?: Array<{
            __typename?: "PublicReview";
            uid: string;
            activity_star_rating: number | null;
            details: {
              __typename?: "PublicReviewDetails";
              publicReview: string | null;
            } | null;
            parent: {
              __typename?: "Parent";
              uid: string;
              publicName: string | null;
            };
          }>;
          clubSection: {
            __typename?: "Section";
            filledSpaceCount: number;
            clubPostCount: number;
          } | null;
          details: { __typename?: "ActivityDetails"; photo: string | null };
          savedClassLists: Array<{
            __typename?: "SavedClassList";
            uid: string;
            lastAddedToAt: any | null;
          }>;
        };
        nextSection: {
          __typename?: "Section";
          uid: string;
          size_max: number | null;
          filledSpaceCount: number;
          start_time: any | null;
          end_time: any | null;
        } | null;
      }>;
    };
  } | null;
};

export type SimilarListingsElasticsearchQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type SimilarListingsElasticsearchQuery = {
  __typename?: "Query";
  listing: {
    __typename?: "Listing";
    similarListings: {
      __typename?: "SimilarListings";
      popularElasticsearch: Array<{
        __typename?: "Listing";
        insertionId: string | null;
        sectionCount: number;
        openSectionCount: number;
        upcomingSectionCount: number;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          leader_link: string | null;
          averageActivityStarRating: number | null;
          reviewCount: number;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
              fileSize: number | null;
            };
          } | null;
          credentials: Array<{
            __typename?: "TeacherCredential";
            uid: string;
            degreeType: DegreeType | null;
            degreeSubject: DegreeSubject | null;
          }>;
        };
        activity: {
          __typename?: "Activity";
          uid: string;
          averageStarRating?: number | null;
          reviewCount: number;
          age_min: number | null;
          age_max: number | null;
          duration_minutes: number | null;
          duration_weeks: number | null;
          weekly_meetings: number | null;
          price_cents: number | null;
          isClub: boolean;
          isFlexSchedule: boolean;
          isSelfPaced: boolean;
          isOneTime: boolean;
          hasTeacherSchedule: boolean;
          size_max: number | null;
          user_uid: string;
          slug_id: string;
          title: string;
          titleTranslated: string | null;
          url: string;
          path: string;
          summary: string | null;
          summaryTranslated: string | null;
          is_ongoing_weekly: boolean;
          subject: string | null;
          recentReviews?: Array<{
            __typename?: "PublicReview";
            uid: string;
            activity_star_rating: number | null;
            details: {
              __typename?: "PublicReviewDetails";
              publicReview: string | null;
            } | null;
            parent: {
              __typename?: "Parent";
              uid: string;
              publicName: string | null;
            };
          }>;
          clubSection: {
            __typename?: "Section";
            filledSpaceCount: number;
            clubPostCount: number;
          } | null;
          details: { __typename?: "ActivityDetails"; photo: string | null };
          savedClassLists: Array<{
            __typename?: "SavedClassList";
            uid: string;
            lastAddedToAt: any | null;
          }>;
        };
        nextSection: {
          __typename?: "Section";
          uid: string;
          size_max: number | null;
          filledSpaceCount: number;
          start_time: any | null;
          end_time: any | null;
        } | null;
      }>;
    };
  } | null;
};

export type SimilarListingsMbaQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  skipRating: Scalars["Boolean"];
  withRecentReviews: Scalars["Boolean"];
}>;

export type SimilarListingsMbaQuery = {
  __typename?: "Query";
  listing: {
    __typename?: "Listing";
    similarListings: {
      __typename?: "SimilarListings";
      popularMBA: Array<{
        __typename?: "Listing";
        insertionId: string | null;
        sectionCount: number;
        openSectionCount: number;
        upcomingSectionCount: number;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          leader_link: string | null;
          averageActivityStarRating: number | null;
          reviewCount: number;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
              fileSize: number | null;
            };
          } | null;
          credentials: Array<{
            __typename?: "TeacherCredential";
            uid: string;
            degreeType: DegreeType | null;
            degreeSubject: DegreeSubject | null;
          }>;
        };
        activity: {
          __typename?: "Activity";
          uid: string;
          averageStarRating?: number | null;
          reviewCount: number;
          age_min: number | null;
          age_max: number | null;
          duration_minutes: number | null;
          duration_weeks: number | null;
          weekly_meetings: number | null;
          price_cents: number | null;
          isClub: boolean;
          isFlexSchedule: boolean;
          isSelfPaced: boolean;
          isOneTime: boolean;
          hasTeacherSchedule: boolean;
          size_max: number | null;
          user_uid: string;
          slug_id: string;
          title: string;
          titleTranslated: string | null;
          url: string;
          path: string;
          summary: string | null;
          summaryTranslated: string | null;
          is_ongoing_weekly: boolean;
          subject: string | null;
          recentReviews?: Array<{
            __typename?: "PublicReview";
            uid: string;
            activity_star_rating: number | null;
            details: {
              __typename?: "PublicReviewDetails";
              publicReview: string | null;
            } | null;
            parent: {
              __typename?: "Parent";
              uid: string;
              publicName: string | null;
            };
          }>;
          clubSection: {
            __typename?: "Section";
            filledSpaceCount: number;
            clubPostCount: number;
          } | null;
          details: { __typename?: "ActivityDetails"; photo: string | null };
          savedClassLists: Array<{
            __typename?: "SavedClassList";
            uid: string;
            lastAddedToAt: any | null;
          }>;
        };
        nextSection: {
          __typename?: "Section";
          uid: string;
          size_max: number | null;
          filledSpaceCount: number;
          start_time: any | null;
          end_time: any | null;
        } | null;
      }>;
    };
  } | null;
};

export type SimilarListingsByTfIdfQueryVariables = Exact<{
  activityUid: Scalars["ID"];
  skipRating: Scalars["Boolean"];
  limit?: InputMaybe<Scalars["Int"]>;
  withRecentReviews: Scalars["Boolean"];
}>;

export type SimilarListingsByTfIdfQuery = {
  __typename?: "Query";
  listing: {
    __typename?: "Listing";
    similarListings: {
      __typename?: "SimilarListings";
      tfidf: Array<{
        __typename?: "Listing";
        insertionId: string | null;
        sectionCount: number;
        openSectionCount: number;
        upcomingSectionCount: number;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          photo: string | null;
          leader_link: string | null;
          averageActivityStarRating: number | null;
          reviewCount: number;
          video: {
            __typename?: "Attachment";
            uid: string;
            conversionStatus: string | null;
            created_at: any;
            file: {
              __typename?: "File";
              filename: string;
              mimetype: string | null;
              size: number;
              url: string;
              thumbUrl: string | null;
              fileSize: number | null;
            };
          } | null;
          credentials: Array<{
            __typename?: "TeacherCredential";
            uid: string;
            degreeType: DegreeType | null;
            degreeSubject: DegreeSubject | null;
          }>;
        };
        activity: {
          __typename?: "Activity";
          uid: string;
          averageStarRating?: number | null;
          reviewCount: number;
          age_min: number | null;
          age_max: number | null;
          duration_minutes: number | null;
          duration_weeks: number | null;
          weekly_meetings: number | null;
          price_cents: number | null;
          isClub: boolean;
          isFlexSchedule: boolean;
          isSelfPaced: boolean;
          isOneTime: boolean;
          hasTeacherSchedule: boolean;
          size_max: number | null;
          user_uid: string;
          slug_id: string;
          title: string;
          titleTranslated: string | null;
          url: string;
          path: string;
          summary: string | null;
          summaryTranslated: string | null;
          is_ongoing_weekly: boolean;
          subject: string | null;
          recentReviews?: Array<{
            __typename?: "PublicReview";
            uid: string;
            activity_star_rating: number | null;
            details: {
              __typename?: "PublicReviewDetails";
              publicReview: string | null;
            } | null;
            parent: {
              __typename?: "Parent";
              uid: string;
              publicName: string | null;
            };
          }>;
          clubSection: {
            __typename?: "Section";
            filledSpaceCount: number;
            clubPostCount: number;
          } | null;
          details: { __typename?: "ActivityDetails"; photo: string | null };
          savedClassLists: Array<{
            __typename?: "SavedClassList";
            uid: string;
            lastAddedToAt: any | null;
          }>;
        };
        nextSection: {
          __typename?: "Section";
          uid: string;
          size_max: number | null;
          filledSpaceCount: number;
          start_time: any | null;
          end_time: any | null;
        } | null;
      }>;
    };
  } | null;
};

export type LightListingCard_ListingFragment = {
  __typename?: "Listing";
  insertionId: string | null;
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    photo: string | null;
    averageActivityStarRating: number | null;
    reviewCount: number;
    leader_link: string | null;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
        fileSize: number | null;
      };
    } | null;
    credentials: Array<{
      __typename?: "TeacherCredential";
      uid: string;
      degreeType: DegreeType | null;
      degreeSubject: DegreeSubject | null;
    }>;
  };
  activity: {
    __typename?: "Activity";
    reviewCount: number;
    isClub: boolean;
    uid: string;
    averageStarRating?: number | null;
    age_min: number | null;
    age_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isOneTime: boolean;
    hasTeacherSchedule: boolean;
    size_max: number | null;
    user_uid: string;
    slug_id: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    clubSection: {
      __typename?: "Section";
      filledSpaceCount: number;
      clubPostCount: number;
    } | null;
    details: { __typename?: "ActivityDetails"; photo: string | null };
    recentReviews?: Array<{
      __typename?: "PublicReview";
      uid: string;
      activity_star_rating: number | null;
      details: {
        __typename?: "PublicReviewDetails";
        publicReview: string | null;
      } | null;
      parent: { __typename?: "Parent"; uid: string; publicName: string | null };
    }>;
    savedClassLists: Array<{
      __typename?: "SavedClassList";
      uid: string;
      lastAddedToAt: any | null;
    }>;
  };
};

export type PitchListingCard_ListingFragment = {
  __typename?: "Listing";
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    photo: string | null;
    leader_link: string | null;
    averageActivityStarRating: number | null;
    reviewCount: number;
    video: {
      __typename?: "Attachment";
      uid: string;
      file: { __typename?: "File"; url: string; thumbUrl: string | null };
    } | null;
  };
  activity: {
    __typename?: "Activity";
    uid: string;
    slug_id: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    averageStarRating: number | null;
    reviewCount: number;
    isSelfPaced: boolean;
    isFlexSchedule: boolean;
    size_max: number | null;
    isClub: boolean;
    weekly_meetings: number | null;
    duration_weeks: number | null;
    duration_minutes: number | null;
    price_cents: number | null;
    is_ongoing_weekly: boolean;
    hasTeacherSchedule: boolean;
    completedEnrollmentCount: number;
    details: { __typename?: "ActivityDetails"; photo: string | null };
    video: {
      __typename?: "Attachment";
      uid: string;
      file: { __typename?: "File"; url: string; thumbUrl: string | null };
    } | null;
    videoForLearners: {
      __typename?: "Attachment";
      uid: string;
      file: { __typename?: "File"; url: string; thumbUrl: string | null };
    } | null;
    recentReviews: Array<{
      __typename?: "PublicReview";
      uid: string;
      activity_star_rating: number | null;
      parent: { __typename?: "Parent"; uid: string; publicName: string | null };
      details: {
        __typename?: "PublicReviewDetails";
        publicReview: string | null;
      } | null;
    }>;
  };
};

export type ListingCard_ListingFragment = {
  __typename?: "Listing";
  insertionId: string | null;
  sectionCount: number;
  openSectionCount: number;
  upcomingSectionCount: number;
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    photo: string | null;
    leader_link: string | null;
    averageActivityStarRating: number | null;
    reviewCount: number;
    video: {
      __typename?: "Attachment";
      uid: string;
      conversionStatus: string | null;
      created_at: any;
      file: {
        __typename?: "File";
        filename: string;
        mimetype: string | null;
        size: number;
        url: string;
        thumbUrl: string | null;
        fileSize: number | null;
      };
    } | null;
    credentials: Array<{
      __typename?: "TeacherCredential";
      uid: string;
      degreeType: DegreeType | null;
      degreeSubject: DegreeSubject | null;
    }>;
  };
  activity: {
    __typename?: "Activity";
    uid: string;
    averageStarRating?: number | null;
    reviewCount: number;
    age_min: number | null;
    age_max: number | null;
    duration_minutes: number | null;
    duration_weeks: number | null;
    weekly_meetings: number | null;
    price_cents: number | null;
    isClub: boolean;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    isOneTime: boolean;
    hasTeacherSchedule: boolean;
    size_max: number | null;
    user_uid: string;
    slug_id: string;
    title: string;
    titleTranslated: string | null;
    url: string;
    path: string;
    summary: string | null;
    summaryTranslated: string | null;
    is_ongoing_weekly: boolean;
    subject: string | null;
    recentReviews?: Array<{
      __typename?: "PublicReview";
      uid: string;
      activity_star_rating: number | null;
      details: {
        __typename?: "PublicReviewDetails";
        publicReview: string | null;
      } | null;
      parent: { __typename?: "Parent"; uid: string; publicName: string | null };
    }>;
    clubSection: {
      __typename?: "Section";
      filledSpaceCount: number;
      clubPostCount: number;
    } | null;
    details: { __typename?: "ActivityDetails"; photo: string | null };
    savedClassLists: Array<{
      __typename?: "SavedClassList";
      uid: string;
      lastAddedToAt: any | null;
    }>;
  };
  nextSection: {
    __typename?: "Section";
    uid: string;
    size_max: number | null;
    filledSpaceCount: number;
    start_time: any | null;
    end_time: any | null;
  } | null;
};

export type ActivityListing_ListingFragment = {
  __typename?: "Listing";
  insertionId: string | null;
  activity: {
    __typename?: "Activity";
    uid: string;
    user_uid: string;
    title: string;
    url: string;
    path: string;
    summary: string | null;
    summaryTranslated: string | null;
    weekly_meetings: number | null;
    duration_weeks: number | null;
    is_ongoing_weekly: boolean;
    isClub: boolean;
    published_at: any | null;
    size_max: number | null;
    size_min: number | null;
    hasTeacherSchedule: boolean;
    allows_recurring_payment: boolean | null;
    averageStarRating?: number | null;
    reviewCount: number;
    age_min: number | null;
    age_max: number | null;
    duration_minutes: number | null;
    price_cents: number | null;
    priceCreditsPerMeeting: number | null;
    isFlexSchedule: boolean;
    isSelfPaced: boolean;
    canEnroll: boolean;
    isOneTime: boolean;
    titleTranslated: string | null;
    slug_id: string;
    subject: string | null;
    syllabus: {
      __typename?: "Syllabus";
      uid: string;
      lessons: Array<{ __typename?: "Lesson"; uid: string }>;
    } | null;
    clubSection: {
      __typename?: "Section";
      filledSpaceCount: number;
      clubPostCount: number;
    } | null;
    details: { __typename?: "ActivityDetails"; photo: string | null };
    savedClassLists: Array<{
      __typename?: "SavedClassList";
      uid: string;
      lastAddedToAt: any | null;
    }>;
  };
  nextSection: {
    __typename?: "Section";
    uid: string;
    size_max: number | null;
    filledSpaceCount: number;
    start_time: any | null;
    end_time: any | null;
    nextOngoingMeeting: {
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
    } | null;
  } | null;
  leader: {
    __typename?: "Leader";
    uid: string;
    name: string | null;
    photo: string | null;
    leader_link: string | null;
    averageActivityStarRating: number | null;
    reviewCount: number;
    ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
  };
};

export type SendMessageToOutschoolMutationVariables = Exact<{
  messageInput: MessageInput;
}>;

export type SendMessageToOutschoolMutation = {
  __typename?: "Mutation";
  sendMessageToOutschool: boolean;
};

export type ActivityRatingAndReviewsQueryVariables = Exact<{
  uid: Scalars["ID"];
  offset: Scalars["Int"];
  limit: Scalars["Int"];
  skipAggregates: Scalars["Boolean"];
  summaryVariant?: InputMaybe<Scalars["String"]>;
}>;

export type ActivityRatingAndReviewsQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    averageStarRating?: number | null;
    reviewCount?: number;
    reviewsWithTextCount?: number;
    recentReviews: Array<{
      __typename?: "PublicReview";
      uid: string;
      section_uid: string;
      currentUserCanEdit: boolean;
      currentUserCanRespond: boolean;
      activity_star_rating: number | null;
      currentUserMarkedHelpful: boolean | null;
      markedHelpfulCount: number;
      created_at: any;
      teacher_response: string | null;
      teacher_responded_at: any | null;
      details: {
        __typename?: "PublicReviewDetails";
        publicReview: string | null;
      } | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        isClub: boolean;
        slug_id: string;
        title: string;
        user_uid: string;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          leader_link: string | null;
          photo: string | null;
        };
      } | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
    }>;
    reviewsWithAttachments: Array<{
      __typename?: "PublicReview";
      uid: string;
      section_uid: string;
      currentUserCanEdit: boolean;
      currentUserCanRespond: boolean;
      activity_star_rating: number | null;
      currentUserMarkedHelpful: boolean | null;
      markedHelpfulCount: number;
      created_at: any;
      teacher_response: string | null;
      teacher_responded_at: any | null;
      details: {
        __typename?: "PublicReviewDetails";
        publicReview: string | null;
      } | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        isClub: boolean;
        slug_id: string;
        title: string;
        user_uid: string;
        leader: {
          __typename?: "Leader";
          uid: string;
          name: string | null;
          leader_link: string | null;
          photo: string | null;
        };
      } | null;
      attachments: Array<{
        __typename?: "Attachment";
        uid: string;
        conversionStatus: string | null;
        created_at: any;
        file: {
          __typename?: "File";
          filename: string;
          mimetype: string | null;
          size: number;
          url: string;
          thumbUrl: string | null;
        };
      }>;
      parent: {
        __typename?: "Parent";
        uid: string;
        name: string | null;
        photo: string | null;
        publicName: string | null;
        introduction: string | null;
        created_at: any | null;
        deleted_at: any | null;
        timeZone: string | null;
        socialOptOut: boolean;
        consent_to_share_class_history: boolean | null;
        consent_to_share_favourites: boolean | null;
      };
    }>;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      leader_link: string | null;
      created_at: any | null;
      photo: string | null;
      cityState: string | null;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      hasBackgroundCheckExpired: boolean | null;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
    };
    feedbackSummary?: {
      __typename?: "TeacherFeedbackSummary";
      uid: string | null;
      pros: string | null;
    } | null;
  };
};

export type PublicReviewFragmentFragment = {
  __typename?: "PublicReview";
  uid: string;
  section_uid: string;
  currentUserCanEdit: boolean;
  currentUserCanRespond: boolean;
  activity_star_rating: number | null;
  currentUserMarkedHelpful: boolean | null;
  markedHelpfulCount: number;
  created_at: any;
  teacher_response: string | null;
  teacher_responded_at: any | null;
  details: {
    __typename?: "PublicReviewDetails";
    publicReview: string | null;
  } | null;
  activity: {
    __typename?: "Activity";
    uid: string;
    isClub: boolean;
    slug_id: string;
    title: string;
    user_uid: string;
    leader: {
      __typename?: "Leader";
      uid: string;
      name: string | null;
      leader_link: string | null;
      photo: string | null;
    };
  } | null;
  attachments: Array<{
    __typename?: "Attachment";
    uid: string;
    conversionStatus: string | null;
    created_at: any;
    file: {
      __typename?: "File";
      filename: string;
      mimetype: string | null;
      size: number;
      url: string;
      thumbUrl: string | null;
    };
  }>;
  parent: {
    __typename?: "Parent";
    uid: string;
    name: string | null;
    photo: string | null;
    publicName: string | null;
    introduction: string | null;
    created_at: any | null;
    deleted_at: any | null;
    timeZone: string | null;
    socialOptOut: boolean;
    consent_to_share_class_history: boolean | null;
    consent_to_share_favourites: boolean | null;
  };
};

export type PublicReviewsOfLeaderQueryVariables = Exact<{
  leaderUid?: InputMaybe<Scalars["ID"]>;
  offset: Scalars["Int"];
  limit: Scalars["Int"];
  skipAggregates: Scalars["Boolean"];
  includeSummary: Scalars["Boolean"];
}>;

export type PublicReviewsOfLeaderQuery = {
  __typename?: "Query";
  publicProfile: {
    __typename?: "PublicProfile";
    uid: string;
    leader: {
      __typename?: "Leader";
      uid: string;
      averageActivityStarRating: number | null;
      reviewCount: number;
      reviewsWithTextCount?: number;
      name: string | null;
      leader_link: string | null;
      created_at: any | null;
      photo: string | null;
      suspended_at: any | null;
      cityState: string | null;
      socialOptOut: boolean;
      is_crosslister: boolean | null;
      timeZone: string | null;
      hasAvailabilitySet: boolean;
      approved: boolean;
      hasBackgroundCheckExpired: boolean | null;
      hasListings: boolean;
      recentReviews: Array<{
        __typename?: "PublicReview";
        uid: string;
        section_uid: string;
        currentUserCanEdit: boolean;
        currentUserCanRespond: boolean;
        activity_star_rating: number | null;
        currentUserMarkedHelpful: boolean | null;
        markedHelpfulCount: number;
        created_at: any;
        teacher_response: string | null;
        teacher_responded_at: any | null;
        activity: {
          __typename?: "Activity";
          uid: string;
          isClub: boolean;
          slug_id: string;
          title: string;
          user_uid: string;
          titleTranslated: string | null;
          url: string;
          path: string;
          summary: string | null;
          summaryTranslated: string | null;
          is_ongoing_weekly: boolean;
          leader: {
            __typename?: "Leader";
            uid: string;
            name: string | null;
            leader_link: string | null;
            photo: string | null;
          };
          details: { __typename?: "ActivityDetails"; photo: string | null };
        } | null;
        details: {
          __typename?: "PublicReviewDetails";
          publicReview: string | null;
        } | null;
        attachments: Array<{
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
          };
        }>;
        parent: {
          __typename?: "Parent";
          uid: string;
          name: string | null;
          photo: string | null;
          publicName: string | null;
          introduction: string | null;
          created_at: any | null;
          deleted_at: any | null;
          timeZone: string | null;
          socialOptOut: boolean;
          consent_to_share_class_history: boolean | null;
          consent_to_share_favourites: boolean | null;
        };
      }>;
      feedbackSummary?: {
        __typename?: "TeacherFeedbackSummary";
        uid: string | null;
        pros: string | null;
      } | null;
      details: {
        __typename?: "LeaderDetails";
        about: string | null;
        headline: string | null;
        headlineTranslated: string | null;
        countryOfResidence: string | null;
      };
      ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      teacherForSellerOrg: {
        __typename?: "SellerOrg";
        uid: string;
        name: string;
      } | null;
    } | null;
  } | null;
};

export type CreateNewCollectionMutationVariables = Exact<{
  name: Scalars["String"];
}>;

export type CreateNewCollectionMutation = {
  __typename?: "Mutation";
  createSavedClassList: {
    __typename?: "SavedClassList";
    uid: string;
    name: string;
  };
};

export type SavedActivityFragmentFragment = {
  __typename?: "SavedActivity";
  uid: string;
  activity_uid: string;
  created_at: any;
};

export type AddToCollectionModal_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  savedClassLists: Array<{
    __typename?: "SavedClassList";
    uid: string;
    lastAddedToAt: any | null;
  }>;
};

export type TrackSaveClass_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  slug_id: string;
  title: string;
  titleTranslated: string | null;
  price_cents: number | null;
  subject: string | null;
};

export type SaveButton_ActivityFragment = {
  __typename?: "Activity";
  uid: string;
  title: string;
  titleTranslated: string | null;
  isClub: boolean;
  slug_id: string;
  price_cents: number | null;
  subject: string | null;
  details: { __typename?: "ActivityDetails"; photo: string | null };
  savedClassLists: Array<{
    __typename?: "SavedClassList";
    uid: string;
    lastAddedToAt: any | null;
  }>;
};

export type SaveActivityMutationVariables = Exact<{
  activityUid: Scalars["ID"];
  referer?: InputMaybe<Scalars["String"]>;
  attribution?: InputMaybe<Attribution>;
}>;

export type SaveActivityMutation = {
  __typename?: "Mutation";
  saveActivity: {
    __typename?: "SavedActivity";
    uid: string;
    activity_uid: string;
    created_at: any;
  };
};

export type UnsaveActivityMutationVariables = Exact<{
  activityUid: Scalars["ID"];
}>;

export type UnsaveActivityMutation = {
  __typename?: "Mutation";
  unsaveActivity: boolean;
};

export type SaveToCollectionsMutationVariables = Exact<{
  activityUid: Scalars["ID"];
  referer?: InputMaybe<Scalars["String"]>;
  attribution?: InputMaybe<Attribution>;
  savedClassListUids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type SaveToCollectionsMutation = {
  __typename?: "Mutation";
  saveActivity: { __typename?: "SavedActivity"; uid: string };
};

export type UnsaveToCollectionsMutationVariables = Exact<{
  activityUid: Scalars["ID"];
  savedClassListUids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type UnsaveToCollectionsMutation = {
  __typename?: "Mutation";
  unsaveActivity: boolean;
};

export type RenameSavedClassListMutationVariables = Exact<{
  uid: Scalars["ID"];
  name: Scalars["String"];
}>;

export type RenameSavedClassListMutation = {
  __typename?: "Mutation";
  renameSavedClassList: boolean;
};

export type DeleteSavedClassListMutationVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type DeleteSavedClassListMutation = {
  __typename?: "Mutation";
  deleteSavedClassList: boolean;
};

export type UpdateLastAddedToAtFragment = {
  __typename?: "SavedClassList";
  uid: string;
  lastAddedToAt: any | null;
};

export type RenameSavedClassListFragment = {
  __typename?: "SavedClassList";
  name: string;
};

export type SavedClassListQueryQueryVariables = Exact<{
  listUid?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["String"]>;
  searchFilters: SearchFilters;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  skipRating: Scalars["Boolean"];
  skipSavedActivities?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SavedClassListQueryQuery = {
  __typename?: "Query";
  savedClassList: {
    __typename?: "SavedClassList";
    uid: string;
    name: string;
    privacyStatus: SavedClassListStatus;
    ownerUid: string;
    activityCount: number;
    listings: Array<{
      __typename?: "Listing";
      insertionId: string | null;
      activity: {
        __typename?: "Activity";
        uid: string;
        user_uid: string;
        title: string;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        weekly_meetings: number | null;
        duration_weeks: number | null;
        is_ongoing_weekly: boolean;
        isClub: boolean;
        published_at: any | null;
        size_max: number | null;
        size_min: number | null;
        hasTeacherSchedule: boolean;
        allows_recurring_payment: boolean | null;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        price_cents: number | null;
        priceCreditsPerMeeting: number | null;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        canEnroll: boolean;
        isOneTime: boolean;
        titleTranslated: string | null;
        slug_id: string;
        subject: string | null;
        syllabus: {
          __typename?: "Syllabus";
          uid: string;
          lessons: Array<{ __typename?: "Lesson"; uid: string }>;
        } | null;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
        } | null;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      };
    } | null>;
    owner: {
      __typename?: "User";
      uid: string;
      publicName: string | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        leader_link: string | null;
      } | null;
    };
  } | null;
  currentUser: {
    __typename?: "User";
    uid: string;
    learners: Array<{
      __typename?: "Learner";
      uid: string;
      name: string | null;
      savedClassList: { __typename?: "SavedClassList"; uid: string } | null;
    }>;
  } | null;
};

export type CurrentUserSavedActivitiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentUserSavedActivitiesQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    savedActivities: Array<{
      __typename?: "SavedActivity";
      uid: string;
      activity_uid: string;
      created_at: any;
    }>;
  } | null;
};

export type ListCurrentUserSavedClassListsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListCurrentUserSavedClassListsQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    savedClassLists: Array<{
      __typename?: "SavedClassList";
      uid: string;
      name: string;
      lastAddedToAt: any | null;
    }>;
  } | null;
};

export type ListSavedClassesWithPhotosQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListSavedClassesWithPhotosQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    savedClassLists: Array<{
      __typename: "SavedClassList";
      uid: string;
      name: string;
      lastAddedToAt: any | null;
      listings: Array<{
        __typename?: "Listing";
        activity: {
          __typename?: "Activity";
          details: { __typename?: "ActivityDetails"; photo: string | null };
        };
      } | null>;
    }>;
  } | null;
  savedClassList: {
    __typename: "SavedClassList";
    uid: string;
    lastAddedToAt: any | null;
    listings: Array<{
      __typename?: "Listing";
      activity: {
        __typename?: "Activity";
        details: { __typename?: "ActivityDetails"; photo: string | null };
      };
    } | null>;
  } | null;
};

export type SavedSearchFragmentFragment = {
  __typename?: "SavedSearch";
  uid: string;
  createdAt: any;
  filters: {
    __typename?: "SavedSearchFilters";
    adminTags: Array<string> | null;
    age: string | null;
    capacityMax: number | null;
    capacityMin: number | null;
    curriculums: Array<string> | null;
    delivery: string | null;
    dow: string | null;
    enabledBooleanFilters: string | null;
    endBy: string | null;
    endByTime: number | null;
    englishProficiencyLevel: EnglishProficiencyLevel | null;
    format: string | null;
    fundingPrograms: Array<string> | null;
    gradeLevel: string | null;
    hasFilledOutUniqueLearningNeeds: boolean | null;
    includeInProgressFixedLengthLiveFormat: boolean | null;
    languageOfInstruction: string | null;
    multiTermQuery: Array<string> | null;
    order: string | null;
    pricePerMeetingMax: number | null;
    pricePerMeetingMin: number | null;
    q: string | null;
    standards: Array<string> | null;
    startAfter: string | null;
    startAfterTime: number | null;
    startBefore: string | null;
    theme: string | null;
    time: string | null;
    timeOfDay: string | null;
    timeZone: string | null;
    uniqueLearningNeeds: string | null;
    userName: string | null;
    userUid: string | null;
  };
};

export type SavedSearchesQueryQueryVariables = Exact<{ [key: string]: never }>;

export type SavedSearchesQueryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    savedSearches: Array<{
      __typename?: "SavedSearch";
      uid: string;
      createdAt: any;
      filters: {
        __typename?: "SavedSearchFilters";
        adminTags: Array<string> | null;
        age: string | null;
        capacityMax: number | null;
        capacityMin: number | null;
        curriculums: Array<string> | null;
        delivery: string | null;
        dow: string | null;
        enabledBooleanFilters: string | null;
        endBy: string | null;
        endByTime: number | null;
        englishProficiencyLevel: EnglishProficiencyLevel | null;
        format: string | null;
        fundingPrograms: Array<string> | null;
        gradeLevel: string | null;
        hasFilledOutUniqueLearningNeeds: boolean | null;
        includeInProgressFixedLengthLiveFormat: boolean | null;
        languageOfInstruction: string | null;
        multiTermQuery: Array<string> | null;
        order: string | null;
        pricePerMeetingMax: number | null;
        pricePerMeetingMin: number | null;
        q: string | null;
        standards: Array<string> | null;
        startAfter: string | null;
        startAfterTime: number | null;
        startBefore: string | null;
        theme: string | null;
        time: string | null;
        timeOfDay: string | null;
        timeZone: string | null;
        uniqueLearningNeeds: string | null;
        userName: string | null;
        userUid: string | null;
      };
    }>;
  } | null;
};

export type DeleteSavedSearchMutationVariables = Exact<{
  uid: Scalars["ID"];
}>;

export type DeleteSavedSearchMutation = {
  __typename?: "Mutation";
  deleteSavedSearch: {
    __typename?: "DeleteSavedSearchPayload";
    deletedSavedSearchUid: string;
  };
};

export type SaveSearchMutationMutationVariables = Exact<{
  filters: SearchFilters;
}>;

export type SaveSearchMutationMutation = {
  __typename?: "Mutation";
  saveSearch: {
    __typename?: "SavedSearch";
    uid: string;
    createdAt: any;
    filters: {
      __typename?: "SavedSearchFilters";
      adminTags: Array<string> | null;
      age: string | null;
      capacityMax: number | null;
      capacityMin: number | null;
      curriculums: Array<string> | null;
      delivery: string | null;
      dow: string | null;
      enabledBooleanFilters: string | null;
      endBy: string | null;
      endByTime: number | null;
      englishProficiencyLevel: EnglishProficiencyLevel | null;
      format: string | null;
      fundingPrograms: Array<string> | null;
      gradeLevel: string | null;
      hasFilledOutUniqueLearningNeeds: boolean | null;
      includeInProgressFixedLengthLiveFormat: boolean | null;
      languageOfInstruction: string | null;
      multiTermQuery: Array<string> | null;
      order: string | null;
      pricePerMeetingMax: number | null;
      pricePerMeetingMin: number | null;
      q: string | null;
      standards: Array<string> | null;
      startAfter: string | null;
      startAfterTime: number | null;
      startBefore: string | null;
      theme: string | null;
      time: string | null;
      timeOfDay: string | null;
      timeZone: string | null;
      uniqueLearningNeeds: string | null;
      userName: string | null;
      userUid: string | null;
    };
  };
};

export type ParentSearchSuggestionsQueryVariables = Exact<{
  query: Scalars["String"];
  ignore: Array<Scalars["String"]> | Scalars["String"];
  size?: InputMaybe<Scalars["Int"]>;
}>;

export type ParentSearchSuggestionsQuery = {
  __typename?: "Query";
  searchSuggestions: {
    __typename?: "SearchSuggestions";
    popularTermSuggestions: Array<{
      __typename?: "PopularTerms";
      uid: string;
      category: string;
      subCategory: string;
    }>;
    topicSuggestions: Array<{
      __typename?: "Topic";
      uid: string;
      label: string;
      category: string | null;
    }>;
    teacherSuggestions: Array<{
      __typename?: "Leader";
      uid: string;
      name: string | null;
      photo: string | null;
      leader_link: string | null;
    }>;
  };
};

export type SearchListingsQueryVariables = Exact<{
  searchFilters: SearchFilters;
  offset?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  cursor?: InputMaybe<Scalars["String"]>;
  skipRating: Scalars["Boolean"];
  matchNames?: InputMaybe<Scalars["Boolean"]>;
  referrer?: InputMaybe<Scalars["String"]>;
  promotedRankingsEnabled?: InputMaybe<Scalars["Boolean"]>;
  promotedExperimentGroup?: InputMaybe<PromotedExperimentGroup>;
  includeMatchingSections?: InputMaybe<Scalars["Boolean"]>;
  source?: InputMaybe<Scalars["Boolean"]>;
  anonymousId?: InputMaybe<Scalars["String"]>;
  searchUid?: InputMaybe<Scalars["String"]>;
  pageImpressionId?: InputMaybe<Scalars["String"]>;
  isFuzzySearchEnabled?: InputMaybe<Scalars["Boolean"]>;
  pageLocation?: InputMaybe<Scalars["String"]>;
  useCase?: InputMaybe<SearchUseCase>;
  searchObjective?: InputMaybe<SearchObjective>;
  disableAutoApplyFilterTypes?: InputMaybe<
    Array<InputMaybe<AutoAppliedFilterType>> | InputMaybe<AutoAppliedFilterType>
  >;
  learnerPlanGoalUid?: InputMaybe<Scalars["String"]>;
  withRecentReviews: Scalars["Boolean"];
}>;

export type SearchListingsQuery = {
  __typename?: "Query";
  searchListings: {
    __typename?: "SearchListingsConnection";
    cursor: string | null;
    total: number | null;
    listings: Array<{
      __typename?: "Listing";
      isAvailable: boolean | null;
      score: number | null;
      strategyScore: number | null;
      strategy: string | null;
      insertionId: string | null;
      sectionCount: number;
      openSectionCount: number;
      upcomingSectionCount: number;
      activity: {
        __typename?: "Activity";
        uid: string;
        user_uid: string;
        title: string;
        url: string;
        path: string;
        summary: string | null;
        summaryTranslated: string | null;
        weekly_meetings: number | null;
        duration_weeks: number | null;
        is_ongoing_weekly: boolean;
        isClub: boolean;
        published_at: any | null;
        size_max: number | null;
        size_min: number | null;
        hasTeacherSchedule: boolean;
        allows_recurring_payment: boolean | null;
        averageStarRating?: number | null;
        reviewCount: number;
        age_min: number | null;
        age_max: number | null;
        duration_minutes: number | null;
        price_cents: number | null;
        priceCreditsPerMeeting: number | null;
        isFlexSchedule: boolean;
        isSelfPaced: boolean;
        canEnroll: boolean;
        isOneTime: boolean;
        titleTranslated: string | null;
        slug_id: string;
        subject: string | null;
        syllabus: {
          __typename?: "Syllabus";
          uid: string;
          lessons: Array<{ __typename?: "Lesson"; uid: string }>;
        } | null;
        clubSection: {
          __typename?: "Section";
          filledSpaceCount: number;
          clubPostCount: number;
        } | null;
        details: { __typename?: "ActivityDetails"; photo: string | null };
        recentReviews?: Array<{
          __typename?: "PublicReview";
          uid: string;
          activity_star_rating: number | null;
          details: {
            __typename?: "PublicReviewDetails";
            publicReview: string | null;
          } | null;
          parent: {
            __typename?: "Parent";
            uid: string;
            publicName: string | null;
          };
        }>;
        savedClassLists: Array<{
          __typename?: "SavedClassList";
          uid: string;
          lastAddedToAt: any | null;
        }>;
      };
      nextSection: {
        __typename?: "Section";
        uid: string;
        size_max: number | null;
        filledSpaceCount: number;
        start_time: any | null;
        end_time: any | null;
        nextOngoingMeeting: {
          __typename?: "Meeting";
          uid: string;
          start_time: any;
          end_time: any;
        } | null;
      } | null;
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        averageActivityStarRating: number | null;
        reviewCount: number;
        ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
        video: {
          __typename?: "Attachment";
          uid: string;
          conversionStatus: string | null;
          created_at: any;
          file: {
            __typename?: "File";
            filename: string;
            mimetype: string | null;
            size: number;
            url: string;
            thumbUrl: string | null;
            fileSize: number | null;
          };
        } | null;
        credentials: Array<{
          __typename?: "TeacherCredential";
          uid: string;
          degreeType: DegreeType | null;
          degreeSubject: DegreeSubject | null;
        }>;
      };
    }>;
    autoAppliedFilters: {
      __typename?: "AutoAppliedFilters";
      gradeLevels: Array<string> | null;
    } | null;
  };
};

export type TrackSubscriptionInterestMutationVariables = Exact<{
  properties: TrackSubscriptionInterestProperties;
}>;

export type TrackSubscriptionInterestMutation = {
  __typename?: "Mutation";
  trackSubscriptionInterest: boolean | null;
};

export type LeaderFragmentFragment = {
  __typename?: "Leader";
  uid: string;
  name: string | null;
  leader_link: string | null;
  created_at: any | null;
  photo: string | null;
  suspended_at: any | null;
  cityState: string | null;
  socialOptOut: boolean;
  is_crosslister: boolean | null;
  timeZone: string | null;
  hasAvailabilitySet: boolean;
  approved: boolean;
  hasBackgroundCheckExpired: boolean | null;
  reviewCount: number;
  averageActivityStarRating: number | null;
  hasListings: boolean;
  details: {
    __typename?: "LeaderDetails";
    about: string | null;
    headline: string | null;
    headlineTranslated: string | null;
    countryOfResidence: string | null;
  };
  ownerOfSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
  teacherForSellerOrg: {
    __typename?: "SellerOrg";
    uid: string;
    name: string;
  } | null;
};

export type TutorVideoCarouselQueryVariables = Exact<{ [key: string]: never }>;

export type TutorVideoCarouselQuery = {
  __typename?: "Query";
  featured: {
    __typename?: "Featured";
    popularTutorListings: Array<{
      __typename?: "Listing";
      leader: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        photo: string | null;
        leader_link: string | null;
        video: {
          __typename?: "Attachment";
          file: { __typename?: "File"; url: string; thumbUrl: string | null };
        } | null;
      };
    }>;
  };
};

export type CurrentUserEnrolledClassesQueryVariables = Exact<{
  start: Scalars["DateTime"];
  end: Scalars["DateTime"];
}>;

export type CurrentUserEnrolledClassesQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    activeEnrollments: Array<{
      __typename?: "Enrollment";
      uid: string;
      learner: { __typename?: "Learner"; uid: string } | null;
      section: {
        __typename?: "Section";
        uid: string;
        activity: { __typename?: "Activity"; uid: string; title: string };
      } | null;
    } | null>;
  } | null;
};

export type ActivityEnrollableTimesQueryVariables = Exact<{
  uid: Scalars["ID"];
  esaState?: InputMaybe<Scalars["String"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  endBy?: InputMaybe<Scalars["DateTime"]>;
  filterByDow?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]>> | InputMaybe<Scalars["Int"]>
  >;
  recurringOnly?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ActivityEnrollableTimesQuery = {
  __typename?: "Query";
  activity: {
    __typename?: "Activity";
    uid: string;
    hasTeacherSchedule: boolean;
    autoSchedulingDaysNoticeMin: number | null;
    autoSchedulingDaysNoticeMax: number | null;
    esaStatus: EsaStatus;
    is_ongoing_weekly: boolean;
    isClub: boolean;
    isSelfPaced: boolean;
    duration_weeks: number | null;
    enrollableTimes: Array<{
      __typename?: "EnrollableTime";
      startTime: any;
      endTime: any;
      teacher: {
        __typename?: "Leader";
        uid: string;
        name: string | null;
        hasBackgroundCheckExpired: boolean | null;
        teacherForSellerOrg: { __typename?: "SellerOrg"; uid: string } | null;
      };
    }>;
  };
};

export type FetchSectionMeetingsQueryVariables = Exact<{
  uid: Scalars["ID"];
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type FetchSectionMeetingsQuery = {
  __typename?: "Query";
  section: {
    __typename?: "Section";
    uid: string;
    meetings: Array<{
      __typename?: "Meeting";
      uid: string;
      start_time: any;
      end_time: any;
    }>;
  } | null;
};

export type TrackClassPageImpressionMutationVariables = Exact<{
  input: TrackClassPageImpressionInput;
}>;

export type TrackClassPageImpressionMutation = {
  __typename?: "Mutation";
  trackClassPageImpression: boolean | null;
};

export type UserWalletSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type UserWalletSummaryQuery = {
  __typename?: "Query";
  currentUser: {
    __typename?: "User";
    uid: string;
    walletSummary: {
      __typename?: "UserWalletSummary";
      pricingOffersTotalCents: number;
      creditAccountBalanceCents: number;
      totalBalanceCents: number;
      pricingOffers: Array<{
        __typename?: "PricingOfferSummary";
        uid: string;
        remainingCapCents: number;
        buyerOrgName: string | null;
        isFinancialAid: boolean;
        organizationUid: string | null;
      }>;
    };
  } | null;
};

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Date: any;
  /** A field whose value is an existing 2 or 3 letter ISO 639 language code https://iso639-3.sil.org/code_tables/639/data */
  Iso639: any;
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z,
   *     compliant with the 'date-time' format outlined in section 5.6 of
   *     the RFC 3339 profile of the ISO 8601 standard for representation
   *     of dates and times using the Gregorian calendar.
   */
  ContentfulDateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  ContentfulJSON: any;
  /** The 'HexColor' type represents color in `rgb:ffffff` string format. */
  ContentfulHexColor: any;
  /** The 'Dimension' type represents dimensions as whole numeric values between `1` and `4000`. */
  ContentfulDimension: any;
  /** The 'Quality' type represents quality as whole numeric values between `1` and `100`. */
  ContentfulQuality: any;
  JSON: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  Url: any;
};

export enum LearnerModeSwitchAuthRequirement {
  Password = "password",
  Pin = "pin",
}

export enum LearningStatementsVerb {
  Completed = "Completed",
  NotStarted = "NotStarted",
  Started = "Started",
  Submitted = "Submitted",
  Viewed = "Viewed",
  Voided = "Voided",
}

export enum TextMimeType {
  TextHtml = "TEXT_HTML",
  TextMarkdown = "TEXT_MARKDOWN",
  TextPlain = "TEXT_PLAIN",
  TextPlainWithMentions = "TEXT_PLAIN_WITH_MENTIONS",
}

export enum SubmissionRequirementType {
  Optional = "optional",
  Recommended = "recommended",
  Required = "required",
}

export type CommentsFilterInput = {
  creationDateSortOrder?: InputMaybe<SortOrder>;
};

export enum SortOrder {
  Ascending = "ascending",
  Descending = "descending",
}

export type SearchFilters = {
  activityRatingMin?: InputMaybe<Scalars["Float"]>;
  /**
   * A list of strings that are matched without analysis to normalized values
   * from the activities.tags column
   */
  adminTags?: InputMaybe<Array<Scalars["String"]>>;
  age?: InputMaybe<Scalars["String"]>;
  availableTimes?: InputMaybe<Array<SearchFilterExcludeTimeWindow>>;
  capacityMax?: InputMaybe<Scalars["Int"]>;
  capacityMin?: InputMaybe<Scalars["Int"]>;
  curriculums?: InputMaybe<Array<Scalars["String"]>>;
  delivery?: InputMaybe<Scalars["String"]>;
  dow?: InputMaybe<Scalars["String"]>;
  durationSessionMin?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin?: InputMaybe<Scalars["Int"]>;
  enabledBooleanFilters?: InputMaybe<Scalars["String"]>;
  endBy?: InputMaybe<Scalars["String"]>;
  endByTime?: InputMaybe<Scalars["Float"]>;
  englishProficiencyLevel?: InputMaybe<EnglishProficiencyLevel>;
  /**
   * Boolean to exclude activities with the new 1-1 format in search results
   * Default: false
   */
  excludeNewOneOnOneFormat?: InputMaybe<Scalars["Boolean"]>;
  /** only returns recurring 1 on 1 classes */
  excludeNonOngoingOneOnOne?: InputMaybe<Scalars["Boolean"]>;
  excludeTimeWindows?: InputMaybe<Array<SearchFilterExcludeTimeWindow>>;
  format?: InputMaybe<Scalars["String"]>;
  fundingPrograms?: InputMaybe<Array<Scalars["String"]>>;
  gradeLevel?: InputMaybe<Scalars["String"]>;
  hasAssessment?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds?: InputMaybe<Scalars["Boolean"]>;
  hasGrades?: InputMaybe<Scalars["Boolean"]>;
  hasHomework?: InputMaybe<Scalars["Boolean"]>;
  includeInProgressFixedLengthLiveFormat?: InputMaybe<Scalars["Boolean"]>;
  includeSelfPaced?: InputMaybe<Scalars["Boolean"]>;
  isAvailable?: InputMaybe<Scalars["Boolean"]>;
  languageOfInstruction?: InputMaybe<Scalars["String"]>;
  listUid?: InputMaybe<Scalars["String"]>;
  /**
   * A list of strings, to be matched against the activity title.
   *
   * Example: to search for winter holiday classes, a multiTermQuery could be:
   * ["Hanukkah", "Christmas", "Winter Solstice"]
   */
  multiTermQuery?: InputMaybe<Array<Scalars["String"]>>;
  order?: InputMaybe<Scalars["String"]>;
  priceCreditsPerCourseMax?: InputMaybe<Scalars["Int"]>;
  priceCreditsPerCourseMin?: InputMaybe<Scalars["Int"]>;
  priceCreditsPerMeetingMax?: InputMaybe<Scalars["Int"]>;
  priceCreditsPerMeetingMin?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin?: InputMaybe<Scalars["Int"]>;
  /** A string query, matched against various fields in the activites ES index */
  q?: InputMaybe<Scalars["String"]>;
  /**
   * A number used to be able to reproduce randomized search results on
   * no query searches
   */
  seed?: InputMaybe<Scalars["Int"]>;
  showListings?: InputMaybe<Scalars["Int"]>;
  standards?: InputMaybe<Array<Scalars["String"]>>;
  starred?: InputMaybe<Scalars["Boolean"]>;
  startAfter?: InputMaybe<Scalars["String"]>;
  startAfterTime?: InputMaybe<Scalars["Float"]>;
  startBefore?: InputMaybe<Scalars["String"]>;
  teacherRatingMin?: InputMaybe<Scalars["Float"]>;
  theme?: InputMaybe<Scalars["String"]>;
  time?: InputMaybe<Scalars["String"]>;
  timeOfDay?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
  topics?: InputMaybe<Array<Scalars["String"]>>;
  uniqueLearningNeeds?: InputMaybe<Scalars["String"]>;
  userName?: InputMaybe<Scalars["String"]>;
  userUid?: InputMaybe<Scalars["String"]>;
  weeklyMeetingsMax?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin?: InputMaybe<Scalars["Int"]>;
};

export type SearchFilterExcludeTimeWindow = {
  dow: Scalars["Int"];
  endHour: Scalars["Int"];
  endMinute: Scalars["Int"];
  startHour: Scalars["Int"];
  startMinute: Scalars["Int"];
};

export enum EnglishProficiencyLevel {
  A0 = "A0",
  A05 = "A05",
  A1 = "A1",
  A2 = "A2",
  B1 = "B1",
  B2 = "B2",
  PreA1 = "PRE_A1",
}

export enum TeacherCertificateDiscipline {
  ElementaryEducation = "ElementaryEducation",
  EnglishOrLanguageArts = "EnglishOrLanguageArts",
  EnglishToSpeakersOfOtherLanguages = "EnglishToSpeakersOfOtherLanguages",
  ForeignLanguage = "ForeignLanguage",
  HealthOrPe = "HealthOrPE",
  Mathematics = "Mathematics",
  MusicOrTheaterOrArts = "MusicOrTheaterOrArts",
  Other = "Other",
  Science = "Science",
  SecondaryEducation = "SecondaryEducation",
  SocialStudiesOrHistory = "SocialStudiesOrHistory",
  SpecialEducation = "SpecialEducation",
}

export enum TeacherCertificateState {
  Alabama = "Alabama",
  Alaska = "Alaska",
  Arizona = "Arizona",
  Arkansas = "Arkansas",
  California = "California",
  Colorado = "Colorado",
  Connecticut = "Connecticut",
  Delaware = "Delaware",
  DistrictOfColumbia = "DistrictOfColumbia",
  Florida = "Florida",
  Georgia = "Georgia",
  Hawaii = "Hawaii",
  Idaho = "Idaho",
  Illinois = "Illinois",
  Indiana = "Indiana",
  Iowa = "Iowa",
  Kansas = "Kansas",
  Kentucky = "Kentucky",
  Louisiana = "Louisiana",
  Maine = "Maine",
  Maryland = "Maryland",
  Massachusetts = "Massachusetts",
  Michigan = "Michigan",
  Minnesota = "Minnesota",
  Mississippi = "Mississippi",
  Missouri = "Missouri",
  Montana = "Montana",
  Nebraska = "Nebraska",
  Nevada = "Nevada",
  NewHampshire = "NewHampshire",
  NewJersey = "NewJersey",
  NewMexico = "NewMexico",
  NewYork = "NewYork",
  NorthCarolina = "NorthCarolina",
  NorthDakota = "NorthDakota",
  NotInUs = "NotInUS",
  Ohio = "Ohio",
  Oklahoma = "Oklahoma",
  Oregon = "Oregon",
  Pennsylvania = "Pennsylvania",
  RhodeIsland = "RhodeIsland",
  SouthCarolina = "SouthCarolina",
  SouthDakota = "SouthDakota",
  Tennessee = "Tennessee",
  Texas = "Texas",
  Utah = "Utah",
  Vermont = "Vermont",
  Virginia = "Virginia",
  Washington = "Washington",
  WestVirginia = "WestVirginia",
  Wisconsin = "Wisconsin",
  Wyoming = "Wyoming",
}

export enum CredentialType {
  CollegeDegree = "CollegeDegree",
  Other = "Other",
  TeachingCertificate = "TeachingCertificate",
}

export enum DegreeSubject {
  Accounting = "Accounting",
  ActuarialScience = "ActuarialScience",
  Advertising = "Advertising",
  AerospaceEngineering = "AerospaceEngineering",
  Agriculture = "Agriculture",
  AmericanStudies = "AmericanStudies",
  AnimalScience = "AnimalScience",
  Anthropology = "Anthropology",
  Architecture = "Architecture",
  ArtAndDesign = "ArtAndDesign",
  ArtHistoryCriticismAndConservation = "ArtHistoryCriticismAndConservation",
  AsianStudiesCivilization = "AsianStudiesCivilization",
  BehavioralScience = "BehavioralScience",
  Biochemistry = "Biochemistry",
  BiologyBiologicalSciences = "BiologyBiologicalSciences",
  BiomedicalSciences = "BiomedicalSciences",
  BotanyPlantBiology = "BotanyPlantBiology",
  BusinessAdministrationAndManagement = "BusinessAdministrationAndManagement",
  BusinessCommerce = "BusinessCommerce",
  ChemicalEngineering = "ChemicalEngineering",
  Chemistry = "Chemistry",
  CivilEngineering = "CivilEngineering",
  Communications = "Communications",
  ComputerEngineering = "ComputerEngineering",
  ComputerScience = "ComputerScience",
  ConstructionManagement = "ConstructionManagement",
  CriminalJustice = "CriminalJustice",
  Dance = "Dance",
  EarlyChildhoodEducation = "EarlyChildhoodEducation",
  Economics = "Economics",
  Education = "Education",
  ElectricalEngineering = "ElectricalEngineering",
  ElementaryEducation = "ElementaryEducation",
  EngineeringGeneral = "EngineeringGeneral",
  English = "English",
  EnglishLanguageAndLiterature = "EnglishLanguageAndLiterature",
  EnvironmentalScience = "EnvironmentalScience",
  EthnicStudies = "EthnicStudies",
  ExerciseScience = "ExerciseScience",
  FamilyAndConsumerSciencesHumanSciences = "FamilyAndConsumerSciencesHumanSciences",
  FashionMerchandising = "FashionMerchandising",
  FilmCinemaVideoStudies = "FilmCinemaVideoStudies",
  Finance = "Finance",
  FineStudioArts = "FineStudioArts",
  ForeignLanguage = "ForeignLanguage",
  ForensicScienceAndTechnology = "ForensicScienceAndTechnology",
  Forestry = "Forestry",
  Genetics = "Genetics",
  GeologyEarthScience = "GeologyEarthScience",
  GraphicDesign = "GraphicDesign",
  HealthAndPhysicalEducationFitness = "HealthAndPhysicalEducationFitness",
  HealthOrPe = "HealthOrPE",
  HealthProfessionsAndRelatedPrograms = "HealthProfessionsAndRelatedPrograms",
  HealthServicesAlliedHealthHealthSciences = "HealthServicesAlliedHealthHealthSciences",
  History = "History",
  Horticulture = "Horticulture",
  HospitalAndHealthCareFacilitiesAdministrationManagement = "HospitalAndHealthCareFacilitiesAdministrationManagement",
  HospitalityManagement = "HospitalityManagement",
  HumanDevelopmentFamilyStudies = "HumanDevelopmentFamilyStudies",
  HumanResourcesManagement = "HumanResourcesManagement",
  InformationTechnology = "InformationTechnology",
  InteriorDesign = "InteriorDesign",
  InternationalBusiness = "InternationalBusiness",
  InternationalRelations = "InternationalRelations",
  Journalism = "Journalism",
  Kinesiology = "Kinesiology",
  LiberalArtsAndSciences = "LiberalArtsAndSciences",
  Linguistics = "Linguistics",
  ManagementInformationSystems = "ManagementInformationSystems",
  MarineBiology = "MarineBiology",
  Marketing = "Marketing",
  MaterialsScience = "MaterialsScience",
  Math = "Math",
  MechanicalEngineering = "MechanicalEngineering",
  MolecularBiology = "MolecularBiology",
  Music = "Music",
  MusicOrTheaterOrArts = "MusicOrTheaterOrArts",
  Nursing = "Nursing",
  NutritionSciences = "NutritionSciences",
  OccupationalTherapy = "OccupationalTherapy",
  OrganizationalLeadership = "OrganizationalLeadership",
  Other = "Other",
  Philosophy = "Philosophy",
  Physics = "Physics",
  PoliticalScienceAndGovernment = "PoliticalScienceAndGovernment",
  PreMedicinePreMedicalStudies = "PreMedicinePreMedicalStudies",
  Psychology = "Psychology",
  PublicAdministration = "PublicAdministration",
  PublicHealth = "PublicHealth",
  PublicRelations = "PublicRelations",
  RealEstate = "RealEstate",
  ReligiousStudies = "ReligiousStudies",
  Science = "Science",
  SecondaryEducation = "SecondaryEducation",
  SecondarySchoolAdministrationPrincipalship = "SecondarySchoolAdministrationPrincipalship",
  SocialSciences = "SocialSciences",
  SocialWork = "SocialWork",
  Sociology = "Sociology",
  SpanishLanguageAndLiterature = "SpanishLanguageAndLiterature",
  SpecialEducation = "SpecialEducation",
  SpeechCommunicationAndRhetoric = "SpeechCommunicationAndRhetoric",
  SpeechLanguagePathologyPathologist = "SpeechLanguagePathologyPathologist",
  SportsManagement = "SportsManagement",
  SupplyChainManagementLogistics = "SupplyChainManagementLogistics",
  TheatreArts = "TheatreArts",
  UrbanAndRegionalPlanning = "UrbanAndRegionalPlanning",
  UrbanStudies = "UrbanStudies",
  VeterinaryTechnology = "VeterinaryTechnology",
  ZoologyAnimalBiology = "ZoologyAnimalBiology",
}

export enum DegreeType {
  AssociatesDegree = "AssociatesDegree",
  BachelorsDegree = "BachelorsDegree",
  DoctoralDegree = "DoctoralDegree",
  DoctorOfMedicine = "DoctorOfMedicine",
  JurisDoctor = "JurisDoctor",
  MastersDegree = "MastersDegree",
}

export enum EducatorTier {
  NewOnOutschool = "new_on_outschool",
  Popular = "popular",
  RisingStar = "rising_star",
  Star = "star",
}

export enum MainToolbarPinnedTabs {
  Availability = "availability",
  Classes = "classes",
  Conversations = "conversations",
  Coupons = "coupons",
  Credentials = "credentials",
  Groups = "groups",
  Insights = "insights",
  Marketing = "marketing",
  Profile = "profile",
  Schedule = "schedule",
  Sections = "sections",
}

export enum ClassFormat {
  Club = "CLUB",
  Flex = "FLEX",
  MultiDay = "MULTI_DAY",
  OneOnOne = "ONE_ON_ONE",
  OneTime = "ONE_TIME",
  Ongoing = "ONGOING",
  SelfPaced = "SELF_PACED",
}

export enum SavedClassListStatus {
  LearnerProfile = "learnerProfile",
  Private = "private",
  Unlisted = "unlisted",
}

export enum EntityAvailabilityEntityType {
  Learner = "learner",
}

export type TopicsConnectionCursorInput = {
  created_at: Scalars["DateTime"];
  popularity: Scalars["Int"];
  uid: Scalars["ID"];
};

export enum RaceEthnicityType {
  African = "AFRICAN",
  AfricanAmericanOrCaribbean = "AFRICAN_AMERICAN_OR_CARIBBEAN",
  ApacHawaiian = "APAC_HAWAIIAN",
  EastAsian = "EAST_ASIAN",
  European = "EUROPEAN",
  HispanicLatin = "HISPANIC_LATIN",
  IndigenousAmerican = "INDIGENOUS_AMERICAN",
  MiddleEastern = "MIDDLE_EASTERN",
  Other = "OTHER",
  PreferNotToAnswer = "PREFER_NOT_TO_ANSWER",
  SouthAsian = "SOUTH_ASIAN",
  SoutheastAsian = "SOUTHEAST_ASIAN",
  White = "WHITE",
}

/** Type of enrollment/order payment. Keep this in sync with knex migration constraint. */
export enum TenderType {
  AppleIap = "apple_iap",
  ClassWallet = "class_wallet",
  Coupon = "coupon",
  FundingProgramInvoice = "funding_program_invoice",
  PricingOffer = "pricing_offer",
  PurchaseOrder = "purchase_order",
  StoredCredit = "stored_credit",
  Stripe = "stripe",
  StripeCoupon = "stripe_coupon",
  SubscriptionCredit = "subscription_credit",
}

export enum RecordingAvailability {
  Hidden = "hidden",
  Processing = "processing",
  Requestable = "requestable",
  Requested = "requested",
  Shown = "shown",
  Unavailable = "unavailable",
}

export enum MeetingRequestStatus {
  /** The request has been approved by the parent */
  Confirmed = "Confirmed",
  /** The request has been declined by the parent */
  Declined = "Declined",
  /** The request expired before parent approved or declined */
  Expired = "Expired",
  /** The request must be approved by the parent */
  Pending = "Pending",
}

export enum MeetingStartStatus {
  LateNoShow = "LateNoShow",
  NotStarted = "NotStarted",
  StartedLate = "StartedLate",
  StartedOnTime = "StartedOnTime",
  Unknown = "Unknown",
}

export enum SectionLessonType {
  Lesson = "Lesson",
  Meeting = "Meeting",
  MeetingWithLesson = "MeetingWithLesson",
}

export enum CurrencyCode {
  Aud = "AUD",
  Cad = "CAD",
  Eur = "EUR",
  Gbp = "GBP",
  Hkd = "HKD",
  Inr = "INR",
  Jpy = "JPY",
  Krw = "KRW",
  Mxn = "MXN",
  Nzd = "NZD",
  Thb = "THB",
  Twd = "TWD",
  Usd = "USD",
}

export enum CouponClassFormats {
  Course = "Course",
  EnrichmentOrClub = "EnrichmentOrClub",
  Group = "Group",
  MultiDay = "MultiDay",
  OneOnOne = "OneOnOne",
  OneTime = "OneTime",
  Ongoing = "Ongoing",
  SelfPaced = "SelfPaced",
  Semester = "Semester",
}

export enum CouponType {
  Outschool = "outschool",
  Teacher = "teacher",
}

export enum PricingOfferAdminFilter {
  Active = "Active",
  All = "All",
  Deleted = "Deleted",
}

export enum OrganizationType {
  CharterSchool = "charter_school",
  CommunityPartner = "community_partner",
  Company = "company",
  CreativeSchoolingOption = "creative_schooling_option",
  EsaOrMicrogrant = "esa_or_microgrant",
  OrgCommunityPartner = "org_community_partner",
  School = "school",
}

export enum GiftCardStatus {
  Canceled = "Canceled",
  Incomplete = "Incomplete",
  Purchased = "Purchased",
  Redeemed = "Redeemed",
  Sent = "Sent",
}

export enum CreditReferralTransactionType {
  UserWasReferred = "UserWasReferred",
  UserWasReferrer = "UserWasReferrer",
}

export enum CreditTransactionType {
  Adjustment = "adjustment",
  Admin = "admin",
  AllowanceWithdrawal = "allowance_withdrawal",
  Purchase = "purchase",
  Redemption = "redemption",
  Referral = "referral",
  Refund = "refund",
  Subscription = "subscription",
  SubscriptionDiscardCancelation = "subscription_discard_cancelation",
  SubscriptionDiscardRollover = "subscription_discard_rollover",
  Urbansitter = "urbansitter",
  Voucher = "voucher",
}

export enum PaymentScheduleTypes {
  MonthlyFirst = "MonthlyFirst",
  WeeklySunday = "WeeklySunday",
}

export enum PaymentScheduleStatus {
  AlreadyPaid = "AlreadyPaid",
  AwaitingPayment = "AwaitingPayment",
  Ended = "Ended",
  NotYetStarted = "NotYetStarted",
}

export enum RefundPolicyType {
  Flexible = "flexible",
  Limited = "limited",
  Moderate = "moderate",
  Variable = "variable",
}

export enum EnrollmentFilter {
  OneOnOne = "OneOnOne",
}

export enum EnrollmentStatus {
  Absent = "Absent",
  Attended = "Attended",
  Confirmed = "Confirmed",
  Ended = "Ended",
  Enrolled = "Enrolled",
  Paused = "Paused",
  Waitlisted = "Waitlisted",
  Withdrawn = "Withdrawn",
}

export type MeetingRequestFilters = {
  /**
   * Only return meeting requests whose start_time is after Date.
   * Defaults to "now()"
   */
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  /**
   * Only return meeting requests whose start_time before Date
   * Defaults to no filter
   */
  startBefore?: InputMaybe<Scalars["DateTime"]>;
  /**
   * Only return meetings whose status matches this.
   * N.B., Meetings are always "confirmed" so other statuses will exclude all Meetings
   */
  status?: InputMaybe<MeetingRequestStatus>;
};

export enum MeetingFilter {
  All = "All",
  Enrolled = "Enrolled",
}

export enum ParticipantRole {
  /** The learner */
  Learner = "Learner",
  /** None of the above; possibly an org Group moderator */
  Other = "Other",
  /** The parent */
  Parent = "Parent",
  /** The owner of the seller org this message's section belongs to, if any */
  SellerOrgOwner = "SellerOrgOwner",
  /** The teacher of this message's section */
  Teacher = "Teacher",
}

export enum WaitlistSource {
  UnpauseFailure = "unpause_failure",
  UserInitiated = "user_initiated",
}

export enum ApprovalCriterionValue {
  Negative = "negative",
  Neutral = "neutral",
  Positive = "positive",
}

export enum Audience {
  Learners = "learners",
  Parents = "parents",
}

export type ConversationFiltersInput = {
  activityUid?: InputMaybe<Scalars["ID"]>;
  participantUids?: InputMaybe<Array<Scalars["ID"]>>;
  searchTerms?: InputMaybe<Array<Scalars["String"]>>;
};

export enum MessageSelection {
  All = "all",
  Spam = "spam",
  Unread = "unread",
}

export enum CreditAccountType {
  OsCash = "OSCash",
  Subscription = "Subscription",
}

export enum CreditAccountStatus {
  Available = "Available",
  InvalidSharedPaymentMethod = "InvalidSharedPaymentMethod",
  RequiresConfirmedPrimaryEmail = "RequiresConfirmedPrimaryEmail",
}

export enum UserEnrollmentsFilters {
  All = "All",
  Confirmed = "Confirmed",
}

export enum IdvStatus {
  Accept = "ACCEPT",
  NotStarted = "NOT_STARTED",
  Reject = "REJECT",
  RejectMany = "REJECT_MANY",
  Review = "REVIEW",
  Started = "STARTED",
}

export enum SellerOrgApplicationStatus {
  Applied = "applied",
  Approved = "approved",
  Interested = "interested",
  Rejected = "rejected",
}

export enum TeacherApplicationStatus {
  Approved = "approved",
  Created = "created",
  Declined = "declined",
  Submitted = "submitted",
}

export enum CreateActivityType {
  Class = "Class",
  Club = "Club",
}

export enum UserLocale {
  En = "en",
  Es = "es",
  /** @deprecated Use es instead */
  Es_419 = "es_419",
  Ja = "ja",
  Ko = "ko",
  ZhTw = "zh_TW",
}

export enum PaymentMethodType {
  Card = "Card",
}

export enum ParentReferralStatus {
  CreditClaimed = "credit_claimed",
  CreditEarned = "credit_earned",
  SignedUp = "signed_up",
}

export enum TeacherReferralStatus {
  ApplicationDeclined = "application_declined",
  EligibleForPayOut = "eligible_for_pay_out",
  PaidOut = "paid_out",
  TeacherApplied = "teacher_applied",
  TeacherApproved = "teacher_approved",
}

export enum FfapApplicationStatus {
  Approved = "approved",
  Declined = "declined",
  Pending = "pending",
}

export enum EducatorSelectedFormat {
  Course = "Course",
  EnrichmentOrClub = "EnrichmentOrClub",
  OneTime = "OneTime",
  Tutoring = "Tutoring",
}

/** Defines how the classroom appears */
export enum ClassroomType {
  Group = "Group",
  OneOnOne = "OneOnOne",
  SelfPaced = "SelfPaced",
}

export enum DeliveryType {
  Camp = "CAMP",
  Club = "CLUB",
  OneOnOneClass = "ONE_ON_ONE_CLASS",
  OneTimeClass = "ONE_TIME_CLASS",
  OngoingClass = "ONGOING_CLASS",
  SemesterCourse = "SEMESTER_COURSE",
  ShortCourse = "SHORT_COURSE",
}

export type EnrollmentParamsInput = {
  isActive?: InputMaybe<Scalars["Boolean"]>;
  orderByNextMeeting?: InputMaybe<Scalars["Boolean"]>;
};

export enum EsaStatus {
  Approved = "Approved",
  NotApproved = "NotApproved",
}

export enum ExternalResourcePolicy {
  /** Allowed for all ages without parental consent */
  Allowed = "ALLOWED",
  /** Allowed for all ages, parental consent required under age 13 */
  AllowedWithConsent = "ALLOWED_WITH_CONSENT",
  /** Allowed for all ages, parental consent required under age 16 */
  AllowedWithConsentUnder_16 = "ALLOWED_WITH_CONSENT_UNDER_16",
  /** Disallowed for all ages */
  Disallowed = "DISALLOWED",
  /** Allowed for ages 13+, disallowed under age 13 */
  DisallowedUnder_13 = "DISALLOWED_UNDER_13",
  /** Allowed for ages 16+, disallowed under age 16 */
  DisallowedUnder_16 = "DISALLOWED_UNDER_16",
  /** This resource hasn't been reviewed */
  Unvetted = "UNVETTED",
}

export enum SectionFilter {
  Bookable = "Bookable",
  BookableOrSoldOut = "BookableOrSoldOut",
  Cancelled = "Cancelled",
  Past = "Past",
  Promotable = "Promotable",
  Upcoming = "Upcoming",
}

export type SectionSearchInput = {
  daysOfWeek?: InputMaybe<Array<DaysOfWeek>>;
  endBy?: InputMaybe<Scalars["DateTime"]>;
  endByTime?: InputMaybe<Scalars["Float"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  startAfterTime?: InputMaybe<Scalars["Float"]>;
  startBefore?: InputMaybe<Scalars["DateTime"]>;
  userTimeZone?: InputMaybe<Scalars["String"]>;
};

export enum DaysOfWeek {
  Fri = "Fri",
  Mon = "Mon",
  Sat = "Sat",
  Sun = "Sun",
  Thu = "Thu",
  Tue = "Tue",
  Wed = "Wed",
}

export enum ModalType {
  Welcome = "WELCOME",
}

export type PricingBreakdownInput = {
  activityUid?: InputMaybe<Scalars["String"]>;
  bulkMeetingDurationWeeks?: InputMaybe<Scalars["Int"]>;
  buyingLearnerUids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buyingUserUid?: InputMaybe<Scalars["String"]>;
  canScheduleContinuously?: InputMaybe<Scalars["Boolean"]>;
  charterAdminEnrollmentInput?: InputMaybe<CharterAdminEnrollmentInput>;
  couponCode?: InputMaybe<Scalars["String"]>;
  donationCents?: InputMaybe<Scalars["Int"]>;
  enrollableMeetings?: InputMaybe<Array<BulkFutureMeetingInput>>;
  enrollmentCount?: InputMaybe<Scalars["Int"]>;
  isAcceptingGiftEnrollment?: InputMaybe<Scalars["Boolean"]>;
  isUsingPurchaseOrder?: InputMaybe<Scalars["Boolean"]>;
  isWeeklyPayment?: InputMaybe<Scalars["Boolean"]>;
  numCredits?: InputMaybe<Scalars["Int"]>;
  ongoingEndTime?: InputMaybe<Scalars["DateTime"]>;
  ongoingStartTime?: InputMaybe<Scalars["DateTime"]>;
  paymentOption?: InputMaybe<PaymentOption>;
  prepayDurationWeeks?: InputMaybe<Scalars["Int"]>;
  purchaseOrderCoverage?: InputMaybe<Scalars["DateTime"]>;
  sectionUid?: InputMaybe<Scalars["String"]>;
  teacherUid?: InputMaybe<Scalars["String"]>;
  uid?: InputMaybe<Scalars["String"]>;
};

export type CharterAdminEnrollmentInput = {
  email: Scalars["String"];
  learnerName: Scalars["String"];
  name: Scalars["String"];
};

export type BulkFutureMeetingInput = {
  endTime: Scalars["DateTime"];
  isAvailable: Scalars["Boolean"];
  startTime: Scalars["DateTime"];
};

export type PaymentOption = {
  pricingOfferName?: InputMaybe<Scalars["String"]>;
  type: TenderType;
};

export enum CouponState {
  InvalidActivityFormatNotAllowed = "InvalidActivityFormatNotAllowed",
  InvalidActivityNotEligible = "InvalidActivityNotEligible",
  InvalidCode = "InvalidCode",
  InvalidExpired = "InvalidExpired",
  InvalidNoDiscount = "InvalidNoDiscount",
  InvalidNotStarted = "InvalidNotStarted",
  InvalidTooManyCouponCodeUses = "InvalidTooManyCouponCodeUses",
  InvalidTooManyUsesByUser = "InvalidTooManyUsesByUser",
  InvalidTooManyUsesTotal = "InvalidTooManyUsesTotal",
  InvalidUserNotConfirmed = "InvalidUserNotConfirmed",
  InvalidUserNotEligible = "InvalidUserNotEligible",
  InvalidUserNotEligibleClassFormat = "InvalidUserNotEligibleClassFormat",
  NoCoupon = "NoCoupon",
  Valid = "Valid",
}

export enum CreditAccountState {
  InvalidNoCreditAccount = "InvalidNoCreditAccount",
  InvalidNoDiscount = "InvalidNoDiscount",
  InvalidNoOpenBalance = "InvalidNoOpenBalance",
  InvalidSharedPaymentMethod = "InvalidSharedPaymentMethod",
  InvalidSubscriptionNotActive = "InvalidSubscriptionNotActive",
  InvalidUnconfirmedEmail = "InvalidUnconfirmedEmail",
  InvalidWhenAcceptingGiftEnrollment = "InvalidWhenAcceptingGiftEnrollment",
  Valid = "Valid",
}

export enum PricingOfferState {
  InvalidBuyoutSection = "InvalidBuyoutSection",
  InvalidDate = "InvalidDate",
  InvalidEducator = "InvalidEducator",
  InvalidNoDiscount = "InvalidNoDiscount",
  InvalidOverTotalCap = "InvalidOverTotalCap",
  InvalidOverUserCap = "InvalidOverUserCap",
  InvalidUnconfirmedEmail = "InvalidUnconfirmedEmail",
  InvalidWhenAcceptingGiftEnrollment = "InvalidWhenAcceptingGiftEnrollment",
  InvalidWithDonationPurchase = "InvalidWithDonationPurchase",
  NoPricingOffer = "NoPricingOffer",
  ValidBuyerOrg = "ValidBuyerOrg",
  ValidFinancialAid = "ValidFinancialAid",
}

export enum BulkPricingBreakdownItemStatus {
  ErrorInvalidActivityType = "ERROR_INVALID_ACTIVITY_TYPE",
  ErrorNotAvailable = "ERROR_NOT_AVAILABLE",
  ErrorUnknown = "ERROR_UNKNOWN",
  Success = "SUCCESS",
}

export type MeetingConflictsQueryInput = {
  ignoreMeetingUids?: InputMaybe<Array<Scalars["ID"]>>;
  range: StartEndTime;
  /** A unique ID that the client can use to identify which range matched this */
  uid: Scalars["ID"];
};

export type StartEndTime = {
  end_time: Scalars["DateTime"];
  start_time: Scalars["DateTime"];
};

export enum ClassroomPostFilter {
  /** Both posts associated with lessons and not */
  All = "All",
  /** Only posts not associated with a lesson */
  Announcements = "Announcements",
  /** Only posts associated with a lesson */
  OnlyForLessons = "OnlyForLessons",
}

export enum ClassroomPostOrder {
  Chronological = "Chronological",
  ReverseChronological = "ReverseChronological",
}

export enum ContentfulAuthorLinkingCollectionsMagnetPageCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulAuthorFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulAuthorFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  description?: InputMaybe<Scalars["String"]>;
  description_contains?: InputMaybe<Scalars["String"]>;
  description_exists?: InputMaybe<Scalars["Boolean"]>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description_not?: InputMaybe<Scalars["String"]>;
  description_not_contains?: InputMaybe<Scalars["String"]>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  isTeacher?: InputMaybe<Scalars["Boolean"]>;
  isTeacher_exists?: InputMaybe<Scalars["Boolean"]>;
  isTeacher_not?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulAuthorFilter>>>;
  profileImage_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  uid?: InputMaybe<Scalars["String"]>;
  uid_contains?: InputMaybe<Scalars["String"]>;
  uid_exists?: InputMaybe<Scalars["Boolean"]>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uid_not?: InputMaybe<Scalars["String"]>;
  uid_not_contains?: InputMaybe<Scalars["String"]>;
  uid_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulContentfulMetadataFilter = {
  tags?: InputMaybe<ContentfulContentfulMetadataTagsFilter>;
  tags_exists?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulSysFilter = {
  firstPublishedAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_exists?: InputMaybe<Scalars["Boolean"]>;
  firstPublishedAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  firstPublishedAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  firstPublishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  publishedAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_exists?: InputMaybe<Scalars["Boolean"]>;
  publishedAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  publishedAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  publishedVersion?: InputMaybe<Scalars["Float"]>;
  publishedVersion_exists?: InputMaybe<Scalars["Boolean"]>;
  publishedVersion_gt?: InputMaybe<Scalars["Float"]>;
  publishedVersion_gte?: InputMaybe<Scalars["Float"]>;
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  publishedVersion_lt?: InputMaybe<Scalars["Float"]>;
  publishedVersion_lte?: InputMaybe<Scalars["Float"]>;
  publishedVersion_not?: InputMaybe<Scalars["Float"]>;
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
};

export type ContentfulCategoryLabelFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulCategoryLabelFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulCategoryLabelFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  value?: InputMaybe<Scalars["String"]>;
  value_contains?: InputMaybe<Scalars["String"]>;
  value_exists?: InputMaybe<Scalars["Boolean"]>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  value_not?: InputMaybe<Scalars["String"]>;
  value_not_contains?: InputMaybe<Scalars["String"]>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulCategoryLabelLinkingCollectionsClpEmailCaptureCollectionOrder {
  CallToActionTextAsc = "callToActionText_ASC",
  CallToActionTextDesc = "callToActionText_DESC",
  ErrorMessageAsc = "errorMessage_ASC",
  ErrorMessageDesc = "errorMessage_DESC",
  IterableLinkAsc = "iterableLink_ASC",
  IterableLinkDesc = "iterableLink_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  ProdIterableAudienceIdAsc = "prodIterableAudienceId_ASC",
  ProdIterableAudienceIdDesc = "prodIterableAudienceId_DESC",
  StagingIterableAudienceIdAsc = "stagingIterableAudienceId_ASC",
  StagingIterableAudienceIdDesc = "stagingIterableAudienceId_DESC",
  SuccessMessageAsc = "successMessage_ASC",
  SuccessMessageDesc = "successMessage_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulClpEmailCaptureClpPagesCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  ValueAsc = "value_ASC",
  ValueDesc = "value_DESC",
}

export enum ContentfulClpEmailCaptureLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageSectionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageSectionsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageSectionsFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulLandingPageClassCarouselLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulSearchFiltersFilter = {
  adminTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_exists?: InputMaybe<Scalars["Boolean"]>;
  ages_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_exists?: InputMaybe<Scalars["Boolean"]>;
  AND?: InputMaybe<Array<InputMaybe<ContentfulSearchFiltersFilter>>>;
  capacityMax?: InputMaybe<Scalars["Int"]>;
  capacityMax_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMax_gt?: InputMaybe<Scalars["Int"]>;
  capacityMax_gte?: InputMaybe<Scalars["Int"]>;
  capacityMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMax_lt?: InputMaybe<Scalars["Int"]>;
  capacityMax_lte?: InputMaybe<Scalars["Int"]>;
  capacityMax_not?: InputMaybe<Scalars["Int"]>;
  capacityMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin?: InputMaybe<Scalars["Int"]>;
  capacityMin_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMin_gt?: InputMaybe<Scalars["Int"]>;
  capacityMin_gte?: InputMaybe<Scalars["Int"]>;
  capacityMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin_lt?: InputMaybe<Scalars["Int"]>;
  capacityMin_lte?: InputMaybe<Scalars["Int"]>;
  capacityMin_not?: InputMaybe<Scalars["Int"]>;
  capacityMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  deliveryTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  deliveryTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMax?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMax_gt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_gte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMax_lt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_lte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_not?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMin?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMin_gt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_gte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMin_lt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_lte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_not?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  endBy?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_exists?: InputMaybe<Scalars["Boolean"]>;
  endBy_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  endBy_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  filtersName?: InputMaybe<Scalars["String"]>;
  filtersName_contains?: InputMaybe<Scalars["String"]>;
  filtersName_exists?: InputMaybe<Scalars["Boolean"]>;
  filtersName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  filtersName_not?: InputMaybe<Scalars["String"]>;
  filtersName_not_contains?: InputMaybe<Scalars["String"]>;
  filtersName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_exists?: InputMaybe<Scalars["Boolean"]>;
  fundingPrograms_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_exists?: InputMaybe<Scalars["Boolean"]>;
  gradeLevel_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_not?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery?: InputMaybe<Scalars["String"]>;
  multiTermQuery_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_exists?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  multiTermQuery_not?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulSearchFiltersFilter>>>;
  pricePerCourseMax?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerCourseMax_gt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_gte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMax_lt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_lte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_not?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMin?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerCourseMin_gt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_gte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMin_lt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_lte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_not?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMax?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerMeetingMax_gt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_gte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMax_lt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_lte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_not?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMin?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerMeetingMin_gt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_gte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMin_lt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_lte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_not?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  query?: InputMaybe<Scalars["String"]>;
  query_contains?: InputMaybe<Scalars["String"]>;
  query_exists?: InputMaybe<Scalars["Boolean"]>;
  query_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query_not?: InputMaybe<Scalars["String"]>;
  query_not_contains?: InputMaybe<Scalars["String"]>;
  query_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startAfter?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_exists?: InputMaybe<Scalars["Boolean"]>;
  startAfter_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startAfter_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  startBefore?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_exists?: InputMaybe<Scalars["Boolean"]>;
  startBefore_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startBefore_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  subject?: InputMaybe<Scalars["String"]>;
  subject_contains?: InputMaybe<Scalars["String"]>;
  subject_exists?: InputMaybe<Scalars["Boolean"]>;
  subject_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject_not?: InputMaybe<Scalars["String"]>;
  subject_not_contains?: InputMaybe<Scalars["String"]>;
  subject_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  uniqueLearningNeeds?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  uniqueLearningNeeds_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uniqueLearningNeeds_not?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  weeklyMeetingsMax?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_exists?: InputMaybe<Scalars["Boolean"]>;
  weeklyMeetingsMax_gt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_gte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMax_lt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_lte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_not?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMin?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_exists?: InputMaybe<Scalars["Boolean"]>;
  weeklyMeetingsMin_gt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_gte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMin_lt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_lte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_not?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export enum ContentfulSearchFiltersLinkingCollectionsLandingPageClassCarouselCollectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulSearchFiltersLinkingCollectionsListingsPromoBannerButtonCollectionOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  ExploreMoreButtonTextAsc = "exploreMoreButtonText_ASC",
  ExploreMoreButtonTextDesc = "exploreMoreButtonText_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulListingsPromoBannerButtonLinkingCollectionsListingsPromoBannerCollectionOrder {
  ExperimentVersionAsc = "experimentVersion_ASC",
  ExperimentVersionDesc = "experimentVersion_DESC",
  HeaderSubtitleAsc = "headerSubtitle_ASC",
  HeaderSubtitleDesc = "headerSubtitle_DESC",
  HeaderTitleAsc = "headerTitle_ASC",
  HeaderTitleDesc = "headerTitle_DESC",
  IncludeControlGroupAsc = "includeControlGroup_ASC",
  IncludeControlGroupDesc = "includeControlGroup_DESC",
  IsLiveOnSearchAsc = "isLiveOnSearch_ASC",
  IsLiveOnSearchDesc = "isLiveOnSearch_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SidebarTitleAsc = "sidebarTitle_ASC",
  SidebarTitleDesc = "sidebarTitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulListingsPromoBannerButtonsCollectionOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  ExploreMoreButtonTextAsc = "exploreMoreButtonText_ASC",
  ExploreMoreButtonTextDesc = "exploreMoreButtonText_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulListingsPromoBannerButtonFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulListingsPromoBannerButtonFilter>>
  >;
  buttonSearchFilters?: InputMaybe<ContentfulcfSearchFiltersNestedFilter>;
  buttonSearchFilters_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText?: InputMaybe<Scalars["String"]>;
  buttonText_contains?: InputMaybe<Scalars["String"]>;
  buttonText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonText_not?: InputMaybe<Scalars["String"]>;
  buttonText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  exploreMoreButtonText?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_contains?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_exists?: InputMaybe<Scalars["Boolean"]>;
  exploreMoreButtonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  exploreMoreButtonText_not?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_not_contains?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  OR?: InputMaybe<Array<InputMaybe<ContentfulListingsPromoBannerButtonFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfSearchFiltersNestedFilter = {
  adminTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_exists?: InputMaybe<Scalars["Boolean"]>;
  ages_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_exists?: InputMaybe<Scalars["Boolean"]>;
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfSearchFiltersNestedFilter>>>;
  capacityMax?: InputMaybe<Scalars["Int"]>;
  capacityMax_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMax_gt?: InputMaybe<Scalars["Int"]>;
  capacityMax_gte?: InputMaybe<Scalars["Int"]>;
  capacityMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMax_lt?: InputMaybe<Scalars["Int"]>;
  capacityMax_lte?: InputMaybe<Scalars["Int"]>;
  capacityMax_not?: InputMaybe<Scalars["Int"]>;
  capacityMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin?: InputMaybe<Scalars["Int"]>;
  capacityMin_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMin_gt?: InputMaybe<Scalars["Int"]>;
  capacityMin_gte?: InputMaybe<Scalars["Int"]>;
  capacityMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin_lt?: InputMaybe<Scalars["Int"]>;
  capacityMin_lte?: InputMaybe<Scalars["Int"]>;
  capacityMin_not?: InputMaybe<Scalars["Int"]>;
  capacityMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  deliveryTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  deliveryTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMax?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMax_gt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_gte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMax_lt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_lte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_not?: InputMaybe<Scalars["Int"]>;
  durationWeeksMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMin?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_exists?: InputMaybe<Scalars["Boolean"]>;
  durationWeeksMin_gt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_gte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  durationWeeksMin_lt?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_lte?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_not?: InputMaybe<Scalars["Int"]>;
  durationWeeksMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  endBy?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_exists?: InputMaybe<Scalars["Boolean"]>;
  endBy_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  endBy_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endBy_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  filtersName?: InputMaybe<Scalars["String"]>;
  filtersName_contains?: InputMaybe<Scalars["String"]>;
  filtersName_exists?: InputMaybe<Scalars["Boolean"]>;
  filtersName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  filtersName_not?: InputMaybe<Scalars["String"]>;
  filtersName_not_contains?: InputMaybe<Scalars["String"]>;
  filtersName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_exists?: InputMaybe<Scalars["Boolean"]>;
  fundingPrograms_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_exists?: InputMaybe<Scalars["Boolean"]>;
  gradeLevel_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_not?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery?: InputMaybe<Scalars["String"]>;
  multiTermQuery_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_exists?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  multiTermQuery_not?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfSearchFiltersNestedFilter>>>;
  pricePerCourseMax?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerCourseMax_gt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_gte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMax_lt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_lte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_not?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMin?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerCourseMin_gt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_gte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerCourseMin_lt?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_lte?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_not?: InputMaybe<Scalars["Int"]>;
  pricePerCourseMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMax?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerMeetingMax_gt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_gte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMax_lt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_lte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_not?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMin?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_exists?: InputMaybe<Scalars["Boolean"]>;
  pricePerMeetingMin_gt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_gte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  pricePerMeetingMin_lt?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_lte?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_not?: InputMaybe<Scalars["Int"]>;
  pricePerMeetingMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  query?: InputMaybe<Scalars["String"]>;
  query_contains?: InputMaybe<Scalars["String"]>;
  query_exists?: InputMaybe<Scalars["Boolean"]>;
  query_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query_not?: InputMaybe<Scalars["String"]>;
  query_not_contains?: InputMaybe<Scalars["String"]>;
  query_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startAfter?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_exists?: InputMaybe<Scalars["Boolean"]>;
  startAfter_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startAfter_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAfter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  startBefore?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_exists?: InputMaybe<Scalars["Boolean"]>;
  startBefore_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startBefore_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startBefore_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  subject?: InputMaybe<Scalars["String"]>;
  subject_contains?: InputMaybe<Scalars["String"]>;
  subject_exists?: InputMaybe<Scalars["Boolean"]>;
  subject_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject_not?: InputMaybe<Scalars["String"]>;
  subject_not_contains?: InputMaybe<Scalars["String"]>;
  subject_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  uniqueLearningNeeds?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  uniqueLearningNeeds_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uniqueLearningNeeds_not?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  weeklyMeetingsMax?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_exists?: InputMaybe<Scalars["Boolean"]>;
  weeklyMeetingsMax_gt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_gte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMax_lt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_lte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_not?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMin?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_exists?: InputMaybe<Scalars["Boolean"]>;
  weeklyMeetingsMin_gt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_gte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  weeklyMeetingsMin_lt?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_lte?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_not?: InputMaybe<Scalars["Int"]>;
  weeklyMeetingsMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type ContentfulListingsPromoBannerHeaderTitleFontDefinitionFilter = {
  AND?: InputMaybe<
    Array<
      InputMaybe<ContentfulListingsPromoBannerHeaderTitleFontDefinitionFilter>
    >
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  fontFamily?: InputMaybe<Scalars["String"]>;
  fontFamily_contains?: InputMaybe<Scalars["String"]>;
  fontFamily_exists?: InputMaybe<Scalars["Boolean"]>;
  fontFamily_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fontFamily_not?: InputMaybe<Scalars["String"]>;
  fontFamily_not_contains?: InputMaybe<Scalars["String"]>;
  fontFamily_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fontUrl?: InputMaybe<Scalars["String"]>;
  fontUrl_contains?: InputMaybe<Scalars["String"]>;
  fontUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  fontUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fontUrl_not?: InputMaybe<Scalars["String"]>;
  fontUrl_not_contains?: InputMaybe<Scalars["String"]>;
  fontUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<
      InputMaybe<ContentfulListingsPromoBannerHeaderTitleFontDefinitionFilter>
    >
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulListingsPromoBannerHeaderTitleFontDefinitionLinkingCollectionsListingsPromoBannerCollectionOrder {
  ExperimentVersionAsc = "experimentVersion_ASC",
  ExperimentVersionDesc = "experimentVersion_DESC",
  HeaderSubtitleAsc = "headerSubtitle_ASC",
  HeaderSubtitleDesc = "headerSubtitle_DESC",
  HeaderTitleAsc = "headerTitle_ASC",
  HeaderTitleDesc = "headerTitle_DESC",
  IncludeControlGroupAsc = "includeControlGroup_ASC",
  IncludeControlGroupDesc = "includeControlGroup_DESC",
  IsLiveOnSearchAsc = "isLiveOnSearch_ASC",
  IsLiveOnSearchDesc = "isLiveOnSearch_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SidebarTitleAsc = "sidebarTitle_ASC",
  SidebarTitleDesc = "sidebarTitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulLandingPageHeroSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulLandingPageIconInfoSectionIconInfosCollectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulLandingPageIconInfoFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageIconInfoFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  iconImage_exists?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageIconInfoFilter>>>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulLandingPageIconInfoLinkingCollectionsLandingPageIconInfoSectionCollectionOrder {
  DisplayOnMobileAsc = "displayOnMobile_ASC",
  DisplayOnMobileDesc = "displayOnMobile_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulLandingPageIconInfoSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulLandingPageImageTextCtaSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulLandingPageJourneySectionJourneyItemsCollectionOrder {
  AnimationUrlAsc = "animationUrl_ASC",
  AnimationUrlDesc = "animationUrl_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NumberColorAsc = "numberColor_ASC",
  NumberColorDesc = "numberColor_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageJourneyItemFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageJourneyItemFilter>>>;
  animationUrl?: InputMaybe<Scalars["String"]>;
  animationUrl_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  animationUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  animationUrl_not?: InputMaybe<Scalars["String"]>;
  animationUrl_not_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  numberColor?: InputMaybe<Scalars["String"]>;
  numberColor_contains?: InputMaybe<Scalars["String"]>;
  numberColor_exists?: InputMaybe<Scalars["Boolean"]>;
  numberColor_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  numberColor_not?: InputMaybe<Scalars["String"]>;
  numberColor_not_contains?: InputMaybe<Scalars["String"]>;
  numberColor_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageJourneyItemFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageJourneyItemLinkingCollectionsLandingPageJourneySectionCollectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulLandingPageJourneySectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulLandingPageLessonPreviewSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulLandingPageSingleReviewSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulLandingPageStatBoxSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulLandingPageStatBoxSectionStatBoxesCollectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageStatBoxFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageStatBoxFilter>>>;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  hoverBackgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageStatBoxFilter>>>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageStatBoxLinkingCollectionsLandingPageStatBoxSectionCollectionOrder {
  DisplayOnMobileAsc = "displayOnMobile_ASC",
  DisplayOnMobileDesc = "displayOnMobile_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulLandingPageTextOnlyStackedCtaSectionLinkingCollectionsLandingPageCollectionOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulCategoryLabelLinkingCollectionsMagnetPageCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulMagnetPageLinkingCollectionsCategoryPageCollectionOrder {
  BelowTheFoldTitleAsc = "belowTheFoldTitle_ASC",
  BelowTheFoldTitleDesc = "belowTheFoldTitle_DESC",
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  FeaturedClassesOverrideSlugAsc = "featuredClassesOverrideSlug_ASC",
  FeaturedClassesOverrideSlugDesc = "featuredClassesOverrideSlug_DESC",
  FooterCtaPathAsc = "footerCtaPath_ASC",
  FooterCtaPathDesc = "footerCtaPath_DESC",
  FooterCtaTitleAsc = "footerCtaTitle_ASC",
  FooterCtaTitleDesc = "footerCtaTitle_DESC",
  HeroTitleAsc = "heroTitle_ASC",
  HeroTitleDesc = "heroTitle_DESC",
  JumpPillsTitleAsc = "jumpPillsTitle_ASC",
  JumpPillsTitleDesc = "jumpPillsTitle_DESC",
  ShowFeaturedClassesAsc = "showFeaturedClasses_ASC",
  ShowFeaturedClassesDesc = "showFeaturedClasses_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulCategoryPageJumpPillLinksCollectionOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC",
}

export type ContentfulVisualLinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulVisualLinkFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAltText?: InputMaybe<Scalars["String"]>;
  imageAltText_contains?: InputMaybe<Scalars["String"]>;
  imageAltText_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAltText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAltText_not?: InputMaybe<Scalars["String"]>;
  imageAltText_not_contains?: InputMaybe<Scalars["String"]>;
  imageAltText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAspectRatio?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_contains?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAspectRatio_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAspectRatio_not?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_not_contains?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulVisualLinkFilter>>>;
  path?: InputMaybe<Scalars["String"]>;
  path_contains?: InputMaybe<Scalars["String"]>;
  path_exists?: InputMaybe<Scalars["Boolean"]>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  path_not?: InputMaybe<Scalars["String"]>;
  path_not_contains?: InputMaybe<Scalars["String"]>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathB?: InputMaybe<Scalars["String"]>;
  pathB_contains?: InputMaybe<Scalars["String"]>;
  pathB_exists?: InputMaybe<Scalars["Boolean"]>;
  pathB_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathB_not?: InputMaybe<Scalars["String"]>;
  pathB_not_contains?: InputMaybe<Scalars["String"]>;
  pathB_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  text?: InputMaybe<Scalars["String"]>;
  text_contains?: InputMaybe<Scalars["String"]>;
  text_exists?: InputMaybe<Scalars["Boolean"]>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  text_not?: InputMaybe<Scalars["String"]>;
  text_not_contains?: InputMaybe<Scalars["String"]>;
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulVisualLinkLinkingCollectionsCategoryPageCollectionOrder {
  BelowTheFoldTitleAsc = "belowTheFoldTitle_ASC",
  BelowTheFoldTitleDesc = "belowTheFoldTitle_DESC",
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  FeaturedClassesOverrideSlugAsc = "featuredClassesOverrideSlug_ASC",
  FeaturedClassesOverrideSlugDesc = "featuredClassesOverrideSlug_DESC",
  FooterCtaPathAsc = "footerCtaPath_ASC",
  FooterCtaPathDesc = "footerCtaPath_DESC",
  FooterCtaTitleAsc = "footerCtaTitle_ASC",
  FooterCtaTitleDesc = "footerCtaTitle_DESC",
  HeroTitleAsc = "heroTitle_ASC",
  HeroTitleDesc = "heroTitle_DESC",
  JumpPillsTitleAsc = "jumpPillsTitle_ASC",
  JumpPillsTitleDesc = "jumpPillsTitle_DESC",
  ShowFeaturedClassesAsc = "showFeaturedClasses_ASC",
  ShowFeaturedClassesDesc = "showFeaturedClasses_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulVisualLinkLinkingCollectionsHomePage2CollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulHomePage2HeroSlidesCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulHeroSlideFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulHeroSlideFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulHeroSlideFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulHeroSlideLinkingCollectionsHomePage2CollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulHomePage2PopularSearchLinksCollectionOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC",
}

export enum ContentfulHomePage2ValuePropsCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulValuePropFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulValuePropFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulValuePropFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulValuePropLinkingCollectionsHomePage2CollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulHomePage2VisualLinkGridsCollectionOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulVisualLinkGridFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulVisualLinkGridFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulVisualLinkGridFilter>>>;
  style?: InputMaybe<Scalars["String"]>;
  style_contains?: InputMaybe<Scalars["String"]>;
  style_exists?: InputMaybe<Scalars["Boolean"]>;
  style_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  style_not?: InputMaybe<Scalars["String"]>;
  style_not_contains?: InputMaybe<Scalars["String"]>;
  style_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  visualLinks?: InputMaybe<ContentfulcfVisualLinkNestedFilter>;
  visualLinksCollection_exists?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulcfVisualLinkNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfVisualLinkNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAltText?: InputMaybe<Scalars["String"]>;
  imageAltText_contains?: InputMaybe<Scalars["String"]>;
  imageAltText_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAltText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAltText_not?: InputMaybe<Scalars["String"]>;
  imageAltText_not_contains?: InputMaybe<Scalars["String"]>;
  imageAltText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAspectRatio?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_contains?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_exists?: InputMaybe<Scalars["Boolean"]>;
  imageAspectRatio_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  imageAspectRatio_not?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_not_contains?: InputMaybe<Scalars["String"]>;
  imageAspectRatio_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfVisualLinkNestedFilter>>>;
  path?: InputMaybe<Scalars["String"]>;
  path_contains?: InputMaybe<Scalars["String"]>;
  path_exists?: InputMaybe<Scalars["Boolean"]>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  path_not?: InputMaybe<Scalars["String"]>;
  path_not_contains?: InputMaybe<Scalars["String"]>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathB?: InputMaybe<Scalars["String"]>;
  pathB_contains?: InputMaybe<Scalars["String"]>;
  pathB_exists?: InputMaybe<Scalars["Boolean"]>;
  pathB_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathB_not?: InputMaybe<Scalars["String"]>;
  pathB_not_contains?: InputMaybe<Scalars["String"]>;
  pathB_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  text?: InputMaybe<Scalars["String"]>;
  text_contains?: InputMaybe<Scalars["String"]>;
  text_exists?: InputMaybe<Scalars["Boolean"]>;
  text_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  text_not?: InputMaybe<Scalars["String"]>;
  text_not_contains?: InputMaybe<Scalars["String"]>;
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulVisualLinkGridLinkingCollectionsHomePage2CollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulVisualLinkGridLinkingCollectionsTutoringPageCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulTutoringPageArticlesCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulMagnetPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulMagnetPageFilter>>>;
  author?: InputMaybe<ContentfulcfAuthorNestedFilter>;
  author_exists?: InputMaybe<Scalars["Boolean"]>;
  body_contains?: InputMaybe<Scalars["String"]>;
  body_exists?: InputMaybe<Scalars["Boolean"]>;
  body_not_contains?: InputMaybe<Scalars["String"]>;
  categoryLabel?: InputMaybe<ContentfulcfCategoryLabelNestedFilter>;
  categoryLabel_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  headerImage_exists?: InputMaybe<Scalars["Boolean"]>;
  magnetPageSlug?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_contains?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_exists?: InputMaybe<Scalars["Boolean"]>;
  magnetPageSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  magnetPageSlug_not?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_not_contains?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulMagnetPageFilter>>>;
  summary_contains?: InputMaybe<Scalars["String"]>;
  summary_exists?: InputMaybe<Scalars["Boolean"]>;
  summary_not_contains?: InputMaybe<Scalars["String"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellClassCarousel?: InputMaybe<ContentfulcfUpsellClassCardNestedFilter>;
  upsellClassCarousel_exists?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulcfAuthorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfAuthorNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  description?: InputMaybe<Scalars["String"]>;
  description_contains?: InputMaybe<Scalars["String"]>;
  description_exists?: InputMaybe<Scalars["Boolean"]>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description_not?: InputMaybe<Scalars["String"]>;
  description_not_contains?: InputMaybe<Scalars["String"]>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  isTeacher?: InputMaybe<Scalars["Boolean"]>;
  isTeacher_exists?: InputMaybe<Scalars["Boolean"]>;
  isTeacher_not?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfAuthorNestedFilter>>>;
  profileImage_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  uid?: InputMaybe<Scalars["String"]>;
  uid_contains?: InputMaybe<Scalars["String"]>;
  uid_exists?: InputMaybe<Scalars["Boolean"]>;
  uid_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uid_not?: InputMaybe<Scalars["String"]>;
  uid_not_contains?: InputMaybe<Scalars["String"]>;
  uid_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfCategoryLabelNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfCategoryLabelNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfCategoryLabelNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  value?: InputMaybe<Scalars["String"]>;
  value_contains?: InputMaybe<Scalars["String"]>;
  value_exists?: InputMaybe<Scalars["Boolean"]>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  value_not?: InputMaybe<Scalars["String"]>;
  value_not_contains?: InputMaybe<Scalars["String"]>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfUpsellClassCardNestedFilter = {
  adminTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_exists?: InputMaybe<Scalars["Boolean"]>;
  ages_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_exists?: InputMaybe<Scalars["Boolean"]>;
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfUpsellClassCardNestedFilter>>>;
  callToActionText?: InputMaybe<Scalars["String"]>;
  callToActionText_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_exists?: InputMaybe<Scalars["Boolean"]>;
  callToActionText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionText_not?: InputMaybe<Scalars["String"]>;
  callToActionText_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionUrl?: InputMaybe<Scalars["String"]>;
  callToActionUrl_contains?: InputMaybe<Scalars["String"]>;
  callToActionUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  callToActionUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionUrl_not?: InputMaybe<Scalars["String"]>;
  callToActionUrl_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  capacityMax?: InputMaybe<Scalars["Int"]>;
  capacityMax_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMax_gt?: InputMaybe<Scalars["Int"]>;
  capacityMax_gte?: InputMaybe<Scalars["Int"]>;
  capacityMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMax_lt?: InputMaybe<Scalars["Int"]>;
  capacityMax_lte?: InputMaybe<Scalars["Int"]>;
  capacityMax_not?: InputMaybe<Scalars["Int"]>;
  capacityMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin?: InputMaybe<Scalars["Int"]>;
  capacityMin_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMin_gt?: InputMaybe<Scalars["Int"]>;
  capacityMin_gte?: InputMaybe<Scalars["Int"]>;
  capacityMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin_lt?: InputMaybe<Scalars["Int"]>;
  capacityMin_lte?: InputMaybe<Scalars["Int"]>;
  capacityMin_not?: InputMaybe<Scalars["Int"]>;
  capacityMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  category_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  deliveryTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  deliveryTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_exists?: InputMaybe<Scalars["Boolean"]>;
  formats_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_exists?: InputMaybe<Scalars["Boolean"]>;
  fundingPrograms_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_exists?: InputMaybe<Scalars["Boolean"]>;
  gradeLevel_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_not?: InputMaybe<Scalars["Boolean"]>;
  maxPriceCents?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_exists?: InputMaybe<Scalars["Boolean"]>;
  maxPriceCents_gt?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_gte?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  maxPriceCents_lt?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_lte?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_not?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minPriceCents?: InputMaybe<Scalars["Int"]>;
  minPriceCents_exists?: InputMaybe<Scalars["Boolean"]>;
  minPriceCents_gt?: InputMaybe<Scalars["Int"]>;
  minPriceCents_gte?: InputMaybe<Scalars["Int"]>;
  minPriceCents_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minPriceCents_lt?: InputMaybe<Scalars["Int"]>;
  minPriceCents_lte?: InputMaybe<Scalars["Int"]>;
  minPriceCents_not?: InputMaybe<Scalars["Int"]>;
  minPriceCents_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  multiTermQuery?: InputMaybe<Scalars["String"]>;
  multiTermQuery_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_exists?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  multiTermQuery_not?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfUpsellClassCardNestedFilter>>>;
  query?: InputMaybe<Scalars["String"]>;
  query_contains?: InputMaybe<Scalars["String"]>;
  query_exists?: InputMaybe<Scalars["Boolean"]>;
  query_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query_not?: InputMaybe<Scalars["String"]>;
  query_not_contains?: InputMaybe<Scalars["String"]>;
  query_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uniqueLearningNeeds?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  uniqueLearningNeeds_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uniqueLearningNeeds_not?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellName?: InputMaybe<Scalars["String"]>;
  upsellName_contains?: InputMaybe<Scalars["String"]>;
  upsellName_exists?: InputMaybe<Scalars["Boolean"]>;
  upsellName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellName_not?: InputMaybe<Scalars["String"]>;
  upsellName_not_contains?: InputMaybe<Scalars["String"]>;
  upsellName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulTutoringPageCarouselSubcategoriesCollectionOrder {
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  NameTitleCasedAsc = "nameTitleCased_ASC",
  NameTitleCasedDesc = "nameTitleCased_DESC",
  SearchPathAsc = "searchPath_ASC",
  SearchPathDesc = "searchPath_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SubjectAsc = "subject_ASC",
  SubjectDesc = "subject_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulTopicFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulTopicFilter>>>;
  categorySlug?: InputMaybe<Scalars["String"]>;
  categorySlug_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_exists?: InputMaybe<Scalars["Boolean"]>;
  categorySlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categorySlug_not?: InputMaybe<Scalars["String"]>;
  categorySlug_not_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  nameTitleCased?: InputMaybe<Scalars["String"]>;
  nameTitleCased_contains?: InputMaybe<Scalars["String"]>;
  nameTitleCased_exists?: InputMaybe<Scalars["Boolean"]>;
  nameTitleCased_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  nameTitleCased_not?: InputMaybe<Scalars["String"]>;
  nameTitleCased_not_contains?: InputMaybe<Scalars["String"]>;
  nameTitleCased_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulTopicFilter>>>;
  searchPath?: InputMaybe<Scalars["String"]>;
  searchPath_contains?: InputMaybe<Scalars["String"]>;
  searchPath_exists?: InputMaybe<Scalars["Boolean"]>;
  searchPath_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  searchPath_not?: InputMaybe<Scalars["String"]>;
  searchPath_not_contains?: InputMaybe<Scalars["String"]>;
  searchPath_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug?: InputMaybe<Scalars["String"]>;
  slug_contains?: InputMaybe<Scalars["String"]>;
  slug_exists?: InputMaybe<Scalars["Boolean"]>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug_not?: InputMaybe<Scalars["String"]>;
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject?: InputMaybe<Scalars["String"]>;
  subject_contains?: InputMaybe<Scalars["String"]>;
  subject_exists?: InputMaybe<Scalars["Boolean"]>;
  subject_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject_not?: InputMaybe<Scalars["String"]>;
  subject_not_contains?: InputMaybe<Scalars["String"]>;
  subject_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulTopicLinkingCollectionsTutoringPageCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulTutoringPagePopularSearchLinksCollectionOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC",
}

export enum ContentfulTutoringPagePopularTutoringSubjectsCollectionOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC",
}

export enum ContentfulTutoringPageTutoringCarouselCollectionOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulTutoringPageVisualLinkGridsCollectionOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulVisualLinkGridVisualLinksCollectionOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC",
}

export enum ContentfulVisualLinkLinkingCollectionsTutoringPageCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulVisualLinkLinkingCollectionsVisualLinkGridCollectionOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulCategoryPageJumpPillsCollectionOrder {
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulJumpPillsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulJumpPillsFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulJumpPillsFilter>>>;
  path?: InputMaybe<Scalars["String"]>;
  path_contains?: InputMaybe<Scalars["String"]>;
  path_exists?: InputMaybe<Scalars["Boolean"]>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  path_not?: InputMaybe<Scalars["String"]>;
  path_not_contains?: InputMaybe<Scalars["String"]>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulJumpPillsLinkingCollectionsCategoryPageCollectionOrder {
  BelowTheFoldTitleAsc = "belowTheFoldTitle_ASC",
  BelowTheFoldTitleDesc = "belowTheFoldTitle_DESC",
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  FeaturedClassesOverrideSlugAsc = "featuredClassesOverrideSlug_ASC",
  FeaturedClassesOverrideSlugDesc = "featuredClassesOverrideSlug_DESC",
  FooterCtaPathAsc = "footerCtaPath_ASC",
  FooterCtaPathDesc = "footerCtaPath_DESC",
  FooterCtaTitleAsc = "footerCtaTitle_ASC",
  FooterCtaTitleDesc = "footerCtaTitle_DESC",
  HeroTitleAsc = "heroTitle_ASC",
  HeroTitleDesc = "heroTitle_DESC",
  JumpPillsTitleAsc = "jumpPillsTitle_ASC",
  JumpPillsTitleDesc = "jumpPillsTitle_DESC",
  ShowFeaturedClassesAsc = "showFeaturedClasses_ASC",
  ShowFeaturedClassesDesc = "showFeaturedClasses_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulCategoryPageMagnetPagesCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulCategoryPageRelatedCategoriesCollectionOrder {
  CallToActionTextAsc = "callToActionText_ASC",
  CallToActionTextDesc = "callToActionText_DESC",
  CallToActionUrlAsc = "callToActionUrl_ASC",
  CallToActionUrlDesc = "callToActionUrl_DESC",
  CapacityMaxAsc = "capacityMax_ASC",
  CapacityMaxDesc = "capacityMax_DESC",
  CapacityMinAsc = "capacityMin_ASC",
  CapacityMinDesc = "capacityMin_DESC",
  HasFilledOutUniqueLearningNeedsAsc = "hasFilledOutUniqueLearningNeeds_ASC",
  HasFilledOutUniqueLearningNeedsDesc = "hasFilledOutUniqueLearningNeeds_DESC",
  MaxPriceCentsAsc = "maxPriceCents_ASC",
  MaxPriceCentsDesc = "maxPriceCents_DESC",
  MinPriceCentsAsc = "minPriceCents_ASC",
  MinPriceCentsDesc = "minPriceCents_DESC",
  MultiTermQueryAsc = "multiTermQuery_ASC",
  MultiTermQueryDesc = "multiTermQuery_DESC",
  QueryAsc = "query_ASC",
  QueryDesc = "query_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UniqueLearningNeedsAsc = "uniqueLearningNeeds_ASC",
  UniqueLearningNeedsDesc = "uniqueLearningNeeds_DESC",
  UpsellNameAsc = "upsellName_ASC",
  UpsellNameDesc = "upsellName_DESC",
}

export type ContentfulUpsellClassCardFilter = {
  adminTags_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  adminTags_exists?: InputMaybe<Scalars["Boolean"]>;
  ages_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ages_exists?: InputMaybe<Scalars["Boolean"]>;
  AND?: InputMaybe<Array<InputMaybe<ContentfulUpsellClassCardFilter>>>;
  callToActionText?: InputMaybe<Scalars["String"]>;
  callToActionText_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_exists?: InputMaybe<Scalars["Boolean"]>;
  callToActionText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionText_not?: InputMaybe<Scalars["String"]>;
  callToActionText_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionUrl?: InputMaybe<Scalars["String"]>;
  callToActionUrl_contains?: InputMaybe<Scalars["String"]>;
  callToActionUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  callToActionUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionUrl_not?: InputMaybe<Scalars["String"]>;
  callToActionUrl_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  capacityMax?: InputMaybe<Scalars["Int"]>;
  capacityMax_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMax_gt?: InputMaybe<Scalars["Int"]>;
  capacityMax_gte?: InputMaybe<Scalars["Int"]>;
  capacityMax_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMax_lt?: InputMaybe<Scalars["Int"]>;
  capacityMax_lte?: InputMaybe<Scalars["Int"]>;
  capacityMax_not?: InputMaybe<Scalars["Int"]>;
  capacityMax_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin?: InputMaybe<Scalars["Int"]>;
  capacityMin_exists?: InputMaybe<Scalars["Boolean"]>;
  capacityMin_gt?: InputMaybe<Scalars["Int"]>;
  capacityMin_gte?: InputMaybe<Scalars["Int"]>;
  capacityMin_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  capacityMin_lt?: InputMaybe<Scalars["Int"]>;
  capacityMin_lte?: InputMaybe<Scalars["Int"]>;
  capacityMin_not?: InputMaybe<Scalars["Int"]>;
  capacityMin_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  category_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  category_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  deliveryTypes_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  deliveryTypes_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  deliveryTypes_exists?: InputMaybe<Scalars["Boolean"]>;
  formats_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  formats_exists?: InputMaybe<Scalars["Boolean"]>;
  fundingPrograms_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  fundingPrograms_exists?: InputMaybe<Scalars["Boolean"]>;
  gradeLevel_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  gradeLevel_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  hasFilledOutUniqueLearningNeeds_not?: InputMaybe<Scalars["Boolean"]>;
  maxPriceCents?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_exists?: InputMaybe<Scalars["Boolean"]>;
  maxPriceCents_gt?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_gte?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  maxPriceCents_lt?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_lte?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_not?: InputMaybe<Scalars["Int"]>;
  maxPriceCents_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minPriceCents?: InputMaybe<Scalars["Int"]>;
  minPriceCents_exists?: InputMaybe<Scalars["Boolean"]>;
  minPriceCents_gt?: InputMaybe<Scalars["Int"]>;
  minPriceCents_gte?: InputMaybe<Scalars["Int"]>;
  minPriceCents_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minPriceCents_lt?: InputMaybe<Scalars["Int"]>;
  minPriceCents_lte?: InputMaybe<Scalars["Int"]>;
  minPriceCents_not?: InputMaybe<Scalars["Int"]>;
  minPriceCents_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  multiTermQuery?: InputMaybe<Scalars["String"]>;
  multiTermQuery_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_exists?: InputMaybe<Scalars["Boolean"]>;
  multiTermQuery_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  multiTermQuery_not?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_contains?: InputMaybe<Scalars["String"]>;
  multiTermQuery_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulUpsellClassCardFilter>>>;
  query?: InputMaybe<Scalars["String"]>;
  query_contains?: InputMaybe<Scalars["String"]>;
  query_exists?: InputMaybe<Scalars["Boolean"]>;
  query_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  query_not?: InputMaybe<Scalars["String"]>;
  query_not_contains?: InputMaybe<Scalars["String"]>;
  query_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uniqueLearningNeeds?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_exists?: InputMaybe<Scalars["Boolean"]>;
  uniqueLearningNeeds_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  uniqueLearningNeeds_not?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_contains?: InputMaybe<Scalars["String"]>;
  uniqueLearningNeeds_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellName?: InputMaybe<Scalars["String"]>;
  upsellName_contains?: InputMaybe<Scalars["String"]>;
  upsellName_exists?: InputMaybe<Scalars["Boolean"]>;
  upsellName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellName_not?: InputMaybe<Scalars["String"]>;
  upsellName_not_contains?: InputMaybe<Scalars["String"]>;
  upsellName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulUpsellClassCardLinkingCollectionsCategoryPageCollectionOrder {
  BelowTheFoldTitleAsc = "belowTheFoldTitle_ASC",
  BelowTheFoldTitleDesc = "belowTheFoldTitle_DESC",
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  FeaturedClassesOverrideSlugAsc = "featuredClassesOverrideSlug_ASC",
  FeaturedClassesOverrideSlugDesc = "featuredClassesOverrideSlug_DESC",
  FooterCtaPathAsc = "footerCtaPath_ASC",
  FooterCtaPathDesc = "footerCtaPath_DESC",
  FooterCtaTitleAsc = "footerCtaTitle_ASC",
  FooterCtaTitleDesc = "footerCtaTitle_DESC",
  HeroTitleAsc = "heroTitle_ASC",
  HeroTitleDesc = "heroTitle_DESC",
  JumpPillsTitleAsc = "jumpPillsTitle_ASC",
  JumpPillsTitleDesc = "jumpPillsTitle_DESC",
  ShowFeaturedClassesAsc = "showFeaturedClasses_ASC",
  ShowFeaturedClassesDesc = "showFeaturedClasses_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulUpsellClassCardLinkingCollectionsMagnetPageCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulMagnetPageLinkingCollectionsFeaturedPagesCollectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulFeaturedPagesPagesCollectionOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulMagnetPageLinkingCollectionsTutoringPageCollectionOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulMagnetPageOrder {
  MagnetPageSlugAsc = "magnetPageSlug_ASC",
  MagnetPageSlugDesc = "magnetPageSlug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulCampaignFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulCampaignFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  country?: InputMaybe<ContentfulcfCampaignCountryNestedFilter>;
  countryCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  endAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_exists?: InputMaybe<Scalars["Boolean"]>;
  endAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  endAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  maxAge?: InputMaybe<Scalars["Int"]>;
  maxAge_exists?: InputMaybe<Scalars["Boolean"]>;
  maxAge_gt?: InputMaybe<Scalars["Int"]>;
  maxAge_gte?: InputMaybe<Scalars["Int"]>;
  maxAge_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  maxAge_lt?: InputMaybe<Scalars["Int"]>;
  maxAge_lte?: InputMaybe<Scalars["Int"]>;
  maxAge_not?: InputMaybe<Scalars["Int"]>;
  maxAge_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minAge?: InputMaybe<Scalars["Int"]>;
  minAge_exists?: InputMaybe<Scalars["Boolean"]>;
  minAge_gt?: InputMaybe<Scalars["Int"]>;
  minAge_gte?: InputMaybe<Scalars["Int"]>;
  minAge_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minAge_lt?: InputMaybe<Scalars["Int"]>;
  minAge_lte?: InputMaybe<Scalars["Int"]>;
  minAge_not?: InputMaybe<Scalars["Int"]>;
  minAge_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  onlyForTreatmentInExperiment?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_contains?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_exists?: InputMaybe<Scalars["Boolean"]>;
  onlyForTreatmentInExperiment_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  onlyForTreatmentInExperiment_not?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_not_contains?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  OR?: InputMaybe<Array<InputMaybe<ContentfulCampaignFilter>>>;
  schoolingApproaches_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_exists?: InputMaybe<Scalars["Boolean"]>;
  startAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_exists?: InputMaybe<Scalars["Boolean"]>;
  startAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  stateOrProvince?: InputMaybe<ContentfulcfCampaignStateOrProvinceNestedFilter>;
  stateOrProvinceCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfCampaignCountryNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfCampaignCountryNestedFilter>>>;
  code?: InputMaybe<Scalars["String"]>;
  code_contains?: InputMaybe<Scalars["String"]>;
  code_exists?: InputMaybe<Scalars["Boolean"]>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code_not?: InputMaybe<Scalars["String"]>;
  code_not_contains?: InputMaybe<Scalars["String"]>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  matchingText_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_exists?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfCampaignCountryNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfCampaignStateOrProvinceNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfCampaignStateOrProvinceNestedFilter>>
  >;
  code?: InputMaybe<Scalars["String"]>;
  code_contains?: InputMaybe<Scalars["String"]>;
  code_exists?: InputMaybe<Scalars["Boolean"]>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code_not?: InputMaybe<Scalars["String"]>;
  code_not_contains?: InputMaybe<Scalars["String"]>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulcfCampaignStateOrProvinceNestedFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulCampaignCountryCollectionOrder {
  CodeAsc = "code_ASC",
  CodeDesc = "code_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulCampaignCountryFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulCampaignCountryFilter>>>;
  code?: InputMaybe<Scalars["String"]>;
  code_contains?: InputMaybe<Scalars["String"]>;
  code_exists?: InputMaybe<Scalars["Boolean"]>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code_not?: InputMaybe<Scalars["String"]>;
  code_not_contains?: InputMaybe<Scalars["String"]>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  matchingText_contains_all?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_contains_none?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_contains_some?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  matchingText_exists?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulCampaignCountryFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulCampaignCountryLinkingCollectionsCampaignCollectionOrder {
  EndAtAsc = "endAt_ASC",
  EndAtDesc = "endAt_DESC",
  MaxAgeAsc = "maxAge_ASC",
  MaxAgeDesc = "maxAge_DESC",
  MinAgeAsc = "minAge_ASC",
  MinAgeDesc = "minAge_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  OnlyForTreatmentInExperimentAsc = "onlyForTreatmentInExperiment_ASC",
  OnlyForTreatmentInExperimentDesc = "onlyForTreatmentInExperiment_DESC",
  StartAtAsc = "startAt_ASC",
  StartAtDesc = "startAt_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulCampaignLinkingCollectionsBannerCollectionOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UrlAsc = "url_ASC",
  UrlDesc = "url_DESC",
}

export enum ContentfulCampaignLinkingCollectionsLearnerBannerCollectionOrder {
  CtaTextAsc = "ctaText_ASC",
  CtaTextDesc = "ctaText_DESC",
  CtaUrlAsc = "ctaUrl_ASC",
  CtaUrlDesc = "ctaUrl_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulCampaignStateOrProvinceCollectionOrder {
  CodeAsc = "code_ASC",
  CodeDesc = "code_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulCampaignStateOrProvinceFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulCampaignStateOrProvinceFilter>>>;
  code?: InputMaybe<Scalars["String"]>;
  code_contains?: InputMaybe<Scalars["String"]>;
  code_exists?: InputMaybe<Scalars["Boolean"]>;
  code_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  code_not?: InputMaybe<Scalars["String"]>;
  code_not_contains?: InputMaybe<Scalars["String"]>;
  code_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulCampaignStateOrProvinceFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulCampaignStateOrProvinceLinkingCollectionsCampaignCollectionOrder {
  EndAtAsc = "endAt_ASC",
  EndAtDesc = "endAt_DESC",
  MaxAgeAsc = "maxAge_ASC",
  MaxAgeDesc = "maxAge_DESC",
  MinAgeAsc = "minAge_ASC",
  MinAgeDesc = "minAge_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  OnlyForTreatmentInExperimentAsc = "onlyForTreatmentInExperiment_ASC",
  OnlyForTreatmentInExperimentDesc = "onlyForTreatmentInExperiment_DESC",
  StartAtAsc = "startAt_ASC",
  StartAtDesc = "startAt_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulBannerLinkingCollectionsBannerPlacementCollectionOrder {
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulBannerPlacementBannersCollectionOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UrlAsc = "url_ASC",
  UrlDesc = "url_DESC",
}

export type ContentfulBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulBannerFilter>>>;
  buttonText?: InputMaybe<Scalars["String"]>;
  buttonText_contains?: InputMaybe<Scalars["String"]>;
  buttonText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonText_not?: InputMaybe<Scalars["String"]>;
  buttonText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  campaign?: InputMaybe<ContentfulcfCampaignNestedFilter>;
  campaign_exists?: InputMaybe<Scalars["Boolean"]>;
  content_contains?: InputMaybe<Scalars["String"]>;
  content_exists?: InputMaybe<Scalars["Boolean"]>;
  content_not_contains?: InputMaybe<Scalars["String"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulBannerFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url?: InputMaybe<Scalars["String"]>;
  url_contains?: InputMaybe<Scalars["String"]>;
  url_exists?: InputMaybe<Scalars["Boolean"]>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url_not?: InputMaybe<Scalars["String"]>;
  url_not_contains?: InputMaybe<Scalars["String"]>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfCampaignNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfCampaignNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  countryCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  endAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_exists?: InputMaybe<Scalars["Boolean"]>;
  endAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  endAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  endAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  maxAge?: InputMaybe<Scalars["Int"]>;
  maxAge_exists?: InputMaybe<Scalars["Boolean"]>;
  maxAge_gt?: InputMaybe<Scalars["Int"]>;
  maxAge_gte?: InputMaybe<Scalars["Int"]>;
  maxAge_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  maxAge_lt?: InputMaybe<Scalars["Int"]>;
  maxAge_lte?: InputMaybe<Scalars["Int"]>;
  maxAge_not?: InputMaybe<Scalars["Int"]>;
  maxAge_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minAge?: InputMaybe<Scalars["Int"]>;
  minAge_exists?: InputMaybe<Scalars["Boolean"]>;
  minAge_gt?: InputMaybe<Scalars["Int"]>;
  minAge_gte?: InputMaybe<Scalars["Int"]>;
  minAge_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  minAge_lt?: InputMaybe<Scalars["Int"]>;
  minAge_lte?: InputMaybe<Scalars["Int"]>;
  minAge_not?: InputMaybe<Scalars["Int"]>;
  minAge_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  onlyForTreatmentInExperiment?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_contains?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_exists?: InputMaybe<Scalars["Boolean"]>;
  onlyForTreatmentInExperiment_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  onlyForTreatmentInExperiment_not?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_not_contains?: InputMaybe<Scalars["String"]>;
  onlyForTreatmentInExperiment_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfCampaignNestedFilter>>>;
  schoolingApproaches_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  schoolingApproaches_exists?: InputMaybe<Scalars["Boolean"]>;
  startAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_exists?: InputMaybe<Scalars["Boolean"]>;
  startAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  startAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  startAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  stateOrProvinceCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars["ContentfulHexColor"]>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars["Int"]>;
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ContentfulImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars["ContentfulDimension"]>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars["ContentfulQuality"]>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ContentfulImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ContentfulImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars["ContentfulDimension"]>;
};

export enum ContentfulImageFormat {
  Avif = "AVIF",
  /** JPG image format. */
  Jpg = "JPG",
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = "JPG_PROGRESSIVE",
  /** PNG image format */
  Png = "PNG",
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = "PNG8",
  /** WebP image format. */
  Webp = "WEBP",
}

export enum ContentfulImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = "BOTTOM",
  /** Focus the resizing on the bottom left. */
  BottomLeft = "BOTTOM_LEFT",
  /** Focus the resizing on the bottom right. */
  BottomRight = "BOTTOM_RIGHT",
  /** Focus the resizing on the center. */
  Center = "CENTER",
  /** Focus the resizing on the largest face. */
  Face = "FACE",
  /** Focus the resizing on the area containing all the faces. */
  Faces = "FACES",
  /** Focus the resizing on the left. */
  Left = "LEFT",
  /** Focus the resizing on the right. */
  Right = "RIGHT",
  /** Focus the resizing on the top. */
  Top = "TOP",
  /** Focus the resizing on the top left. */
  TopLeft = "TOP_LEFT",
  /** Focus the resizing on the top right. */
  TopRight = "TOP_RIGHT",
}

export enum ContentfulImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = "CROP",
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = "FILL",
  /** Resizes the image to fit into the specified dimensions. */
  Fit = "FIT",
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = "PAD",
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = "SCALE",
  /** Creates a thumbnail from the image. */
  Thumb = "THUMB",
}

export enum ContentfulAccordionPageSectionsCollectionOrder {
  HeadingAsc = "heading_ASC",
  HeadingDesc = "heading_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulAccordionSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulAccordionSectionFilter>>>;
  body_contains?: InputMaybe<Scalars["String"]>;
  body_exists?: InputMaybe<Scalars["Boolean"]>;
  body_not_contains?: InputMaybe<Scalars["String"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars["String"]>;
  heading_contains?: InputMaybe<Scalars["String"]>;
  heading_exists?: InputMaybe<Scalars["Boolean"]>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  heading_not?: InputMaybe<Scalars["String"]>;
  heading_not_contains?: InputMaybe<Scalars["String"]>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulAccordionSectionFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulAccordionSectionLinkingCollectionsAccordionPageCollectionOrder {
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  ShowTocAsc = "showToc_ASC",
  ShowTocDesc = "showToc_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulAccordionPageOrder {
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  ShowTocAsc = "showToc_ASC",
  ShowTocDesc = "showToc_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulAccordionPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulAccordionPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  headerImage_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulAccordionPageFilter>>>;
  preamble_contains?: InputMaybe<Scalars["String"]>;
  preamble_exists?: InputMaybe<Scalars["Boolean"]>;
  preamble_not_contains?: InputMaybe<Scalars["String"]>;
  publishedAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_exists?: InputMaybe<Scalars["Boolean"]>;
  publishedAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  publishedAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  sections?: InputMaybe<ContentfulcfAccordionSectionNestedFilter>;
  sectionsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  showToc?: InputMaybe<Scalars["Boolean"]>;
  showToc_exists?: InputMaybe<Scalars["Boolean"]>;
  showToc_not?: InputMaybe<Scalars["Boolean"]>;
  slug?: InputMaybe<Scalars["String"]>;
  slug_contains?: InputMaybe<Scalars["String"]>;
  slug_exists?: InputMaybe<Scalars["Boolean"]>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug_not?: InputMaybe<Scalars["String"]>;
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfAccordionSectionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfAccordionSectionNestedFilter>>>;
  body_contains?: InputMaybe<Scalars["String"]>;
  body_exists?: InputMaybe<Scalars["Boolean"]>;
  body_not_contains?: InputMaybe<Scalars["String"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  heading?: InputMaybe<Scalars["String"]>;
  heading_contains?: InputMaybe<Scalars["String"]>;
  heading_exists?: InputMaybe<Scalars["Boolean"]>;
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  heading_not?: InputMaybe<Scalars["String"]>;
  heading_not_contains?: InputMaybe<Scalars["String"]>;
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfAccordionSectionNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulAccordionSectionOrder {
  HeadingAsc = "heading_ASC",
  HeadingDesc = "heading_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulAnnouncementBannerOrder {
  CopyAsc = "copy_ASC",
  CopyDesc = "copy_DESC",
  DateToRemoveAsc = "dateToRemove_ASC",
  DateToRemoveDesc = "dateToRemove_DESC",
  LinkCopyAsc = "linkCopy_ASC",
  LinkCopyDesc = "linkCopy_DESC",
  LinkUrlAsc = "linkUrl_ASC",
  LinkUrlDesc = "linkUrl_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulAnnouncementBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulAnnouncementBannerFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  copy?: InputMaybe<Scalars["String"]>;
  copy_contains?: InputMaybe<Scalars["String"]>;
  copy_exists?: InputMaybe<Scalars["Boolean"]>;
  copy_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  copy_not?: InputMaybe<Scalars["String"]>;
  copy_not_contains?: InputMaybe<Scalars["String"]>;
  copy_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  dateToRemove?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_exists?: InputMaybe<Scalars["Boolean"]>;
  dateToRemove_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  dateToRemove_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  dateToRemove_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  linkCopy?: InputMaybe<Scalars["String"]>;
  linkCopy_contains?: InputMaybe<Scalars["String"]>;
  linkCopy_exists?: InputMaybe<Scalars["Boolean"]>;
  linkCopy_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  linkCopy_not?: InputMaybe<Scalars["String"]>;
  linkCopy_not_contains?: InputMaybe<Scalars["String"]>;
  linkCopy_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  linkUrl?: InputMaybe<Scalars["String"]>;
  linkUrl_contains?: InputMaybe<Scalars["String"]>;
  linkUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  linkUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  linkUrl_not?: InputMaybe<Scalars["String"]>;
  linkUrl_not_contains?: InputMaybe<Scalars["String"]>;
  linkUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulAnnouncementBannerFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulAssetOrder {
  ContentTypeAsc = "contentType_ASC",
  ContentTypeDesc = "contentType_DESC",
  FileNameAsc = "fileName_ASC",
  FileNameDesc = "fileName_DESC",
  HeightAsc = "height_ASC",
  HeightDesc = "height_DESC",
  SizeAsc = "size_ASC",
  SizeDesc = "size_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  UrlAsc = "url_ASC",
  UrlDesc = "url_DESC",
  WidthAsc = "width_ASC",
  WidthDesc = "width_DESC",
}

export type ContentfulAssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulAssetFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  contentType?: InputMaybe<Scalars["String"]>;
  contentType_contains?: InputMaybe<Scalars["String"]>;
  contentType_exists?: InputMaybe<Scalars["Boolean"]>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentType_not?: InputMaybe<Scalars["String"]>;
  contentType_not_contains?: InputMaybe<Scalars["String"]>;
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description?: InputMaybe<Scalars["String"]>;
  description_contains?: InputMaybe<Scalars["String"]>;
  description_exists?: InputMaybe<Scalars["Boolean"]>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description_not?: InputMaybe<Scalars["String"]>;
  description_not_contains?: InputMaybe<Scalars["String"]>;
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fileName?: InputMaybe<Scalars["String"]>;
  fileName_contains?: InputMaybe<Scalars["String"]>;
  fileName_exists?: InputMaybe<Scalars["Boolean"]>;
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  fileName_not?: InputMaybe<Scalars["String"]>;
  fileName_not_contains?: InputMaybe<Scalars["String"]>;
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  height?: InputMaybe<Scalars["Int"]>;
  height_exists?: InputMaybe<Scalars["Boolean"]>;
  height_gt?: InputMaybe<Scalars["Int"]>;
  height_gte?: InputMaybe<Scalars["Int"]>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  height_lt?: InputMaybe<Scalars["Int"]>;
  height_lte?: InputMaybe<Scalars["Int"]>;
  height_not?: InputMaybe<Scalars["Int"]>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulAssetFilter>>>;
  size?: InputMaybe<Scalars["Int"]>;
  size_exists?: InputMaybe<Scalars["Boolean"]>;
  size_gt?: InputMaybe<Scalars["Int"]>;
  size_gte?: InputMaybe<Scalars["Int"]>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  size_lt?: InputMaybe<Scalars["Int"]>;
  size_lte?: InputMaybe<Scalars["Int"]>;
  size_not?: InputMaybe<Scalars["Int"]>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url?: InputMaybe<Scalars["String"]>;
  url_contains?: InputMaybe<Scalars["String"]>;
  url_exists?: InputMaybe<Scalars["Boolean"]>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url_not?: InputMaybe<Scalars["String"]>;
  url_not_contains?: InputMaybe<Scalars["String"]>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  width?: InputMaybe<Scalars["Int"]>;
  width_exists?: InputMaybe<Scalars["Boolean"]>;
  width_gt?: InputMaybe<Scalars["Int"]>;
  width_gte?: InputMaybe<Scalars["Int"]>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  width_lt?: InputMaybe<Scalars["Int"]>;
  width_lte?: InputMaybe<Scalars["Int"]>;
  width_not?: InputMaybe<Scalars["Int"]>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export enum ContentfulAuthorOrder {
  DescriptionAsc = "description_ASC",
  DescriptionDesc = "description_DESC",
  IsTeacherAsc = "isTeacher_ASC",
  IsTeacherDesc = "isTeacher_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  UidAsc = "uid_ASC",
  UidDesc = "uid_DESC",
}

export enum ContentfulBannerOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UrlAsc = "url_ASC",
  UrlDesc = "url_DESC",
}

export enum ContentfulBannerPlacementOrder {
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulBannerPlacementFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulBannerPlacementFilter>>>;
  banners?: InputMaybe<ContentfulcfBannerNestedFilter>;
  bannersCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulBannerPlacementFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfBannerNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfBannerNestedFilter>>>;
  buttonText?: InputMaybe<Scalars["String"]>;
  buttonText_contains?: InputMaybe<Scalars["String"]>;
  buttonText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonText_not?: InputMaybe<Scalars["String"]>;
  buttonText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  campaign_exists?: InputMaybe<Scalars["Boolean"]>;
  content_contains?: InputMaybe<Scalars["String"]>;
  content_exists?: InputMaybe<Scalars["Boolean"]>;
  content_not_contains?: InputMaybe<Scalars["String"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfBannerNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url?: InputMaybe<Scalars["String"]>;
  url_contains?: InputMaybe<Scalars["String"]>;
  url_exists?: InputMaybe<Scalars["Boolean"]>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  url_not?: InputMaybe<Scalars["String"]>;
  url_not_contains?: InputMaybe<Scalars["String"]>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulBasicPageOrder {
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulBasicPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulBasicPageFilter>>>;
  body_contains?: InputMaybe<Scalars["String"]>;
  body_exists?: InputMaybe<Scalars["Boolean"]>;
  body_not_contains?: InputMaybe<Scalars["String"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  headerImage_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulBasicPageFilter>>>;
  publishedAt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_exists?: InputMaybe<Scalars["Boolean"]>;
  publishedAt_gt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_gte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["ContentfulDateTime"]>>>;
  publishedAt_lt?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_lte?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not?: InputMaybe<Scalars["ContentfulDateTime"]>;
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["ContentfulDateTime"]>>
  >;
  slug?: InputMaybe<Scalars["String"]>;
  slug_contains?: InputMaybe<Scalars["String"]>;
  slug_exists?: InputMaybe<Scalars["Boolean"]>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug_not?: InputMaybe<Scalars["String"]>;
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulCampaignOrder {
  EndAtAsc = "endAt_ASC",
  EndAtDesc = "endAt_DESC",
  MaxAgeAsc = "maxAge_ASC",
  MaxAgeDesc = "maxAge_DESC",
  MinAgeAsc = "minAge_ASC",
  MinAgeDesc = "minAge_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  OnlyForTreatmentInExperimentAsc = "onlyForTreatmentInExperiment_ASC",
  OnlyForTreatmentInExperimentDesc = "onlyForTreatmentInExperiment_DESC",
  StartAtAsc = "startAt_ASC",
  StartAtDesc = "startAt_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulCampaignCountryOrder {
  CodeAsc = "code_ASC",
  CodeDesc = "code_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulCampaignStateOrProvinceOrder {
  CodeAsc = "code_ASC",
  CodeDesc = "code_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulCategoryLabelOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  ValueAsc = "value_ASC",
  ValueDesc = "value_DESC",
}

export enum ContentfulCategoryPageOrder {
  BelowTheFoldTitleAsc = "belowTheFoldTitle_ASC",
  BelowTheFoldTitleDesc = "belowTheFoldTitle_DESC",
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  FeaturedClassesOverrideSlugAsc = "featuredClassesOverrideSlug_ASC",
  FeaturedClassesOverrideSlugDesc = "featuredClassesOverrideSlug_DESC",
  FooterCtaPathAsc = "footerCtaPath_ASC",
  FooterCtaPathDesc = "footerCtaPath_DESC",
  FooterCtaTitleAsc = "footerCtaTitle_ASC",
  FooterCtaTitleDesc = "footerCtaTitle_DESC",
  HeroTitleAsc = "heroTitle_ASC",
  HeroTitleDesc = "heroTitle_DESC",
  JumpPillsTitleAsc = "jumpPillsTitle_ASC",
  JumpPillsTitleDesc = "jumpPillsTitle_DESC",
  ShowFeaturedClassesAsc = "showFeaturedClasses_ASC",
  ShowFeaturedClassesDesc = "showFeaturedClasses_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulCategoryPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulCategoryPageFilter>>>;
  belowTheFoldDescription_contains?: InputMaybe<Scalars["String"]>;
  belowTheFoldDescription_exists?: InputMaybe<Scalars["Boolean"]>;
  belowTheFoldDescription_not_contains?: InputMaybe<Scalars["String"]>;
  belowTheFoldTitle?: InputMaybe<Scalars["String"]>;
  belowTheFoldTitle_contains?: InputMaybe<Scalars["String"]>;
  belowTheFoldTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  belowTheFoldTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  belowTheFoldTitle_not?: InputMaybe<Scalars["String"]>;
  belowTheFoldTitle_not_contains?: InputMaybe<Scalars["String"]>;
  belowTheFoldTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categorySlug?: InputMaybe<Scalars["String"]>;
  categorySlug_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_exists?: InputMaybe<Scalars["Boolean"]>;
  categorySlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categorySlug_not?: InputMaybe<Scalars["String"]>;
  categorySlug_not_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  featuredClassesOverrideSlug?: InputMaybe<Scalars["String"]>;
  featuredClassesOverrideSlug_contains?: InputMaybe<Scalars["String"]>;
  featuredClassesOverrideSlug_exists?: InputMaybe<Scalars["Boolean"]>;
  featuredClassesOverrideSlug_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  featuredClassesOverrideSlug_not?: InputMaybe<Scalars["String"]>;
  featuredClassesOverrideSlug_not_contains?: InputMaybe<Scalars["String"]>;
  featuredClassesOverrideSlug_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  footerCtaPath?: InputMaybe<Scalars["String"]>;
  footerCtaPath_contains?: InputMaybe<Scalars["String"]>;
  footerCtaPath_exists?: InputMaybe<Scalars["Boolean"]>;
  footerCtaPath_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  footerCtaPath_not?: InputMaybe<Scalars["String"]>;
  footerCtaPath_not_contains?: InputMaybe<Scalars["String"]>;
  footerCtaPath_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  footerCtaTitle?: InputMaybe<Scalars["String"]>;
  footerCtaTitle_contains?: InputMaybe<Scalars["String"]>;
  footerCtaTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  footerCtaTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  footerCtaTitle_not?: InputMaybe<Scalars["String"]>;
  footerCtaTitle_not_contains?: InputMaybe<Scalars["String"]>;
  footerCtaTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  heroDescription_contains?: InputMaybe<Scalars["String"]>;
  heroDescription_exists?: InputMaybe<Scalars["Boolean"]>;
  heroDescription_not_contains?: InputMaybe<Scalars["String"]>;
  heroImage_exists?: InputMaybe<Scalars["Boolean"]>;
  heroTitle?: InputMaybe<Scalars["String"]>;
  heroTitle_contains?: InputMaybe<Scalars["String"]>;
  heroTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  heroTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  heroTitle_not?: InputMaybe<Scalars["String"]>;
  heroTitle_not_contains?: InputMaybe<Scalars["String"]>;
  heroTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  heroValueProps_contains?: InputMaybe<Scalars["String"]>;
  heroValueProps_exists?: InputMaybe<Scalars["Boolean"]>;
  heroValueProps_not_contains?: InputMaybe<Scalars["String"]>;
  jumpPillLinks?: InputMaybe<ContentfulcfVisualLinkNestedFilter>;
  jumpPillLinksCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  jumpPills?: InputMaybe<ContentfulcfJumpPillsNestedFilter>;
  jumpPillsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  jumpPillsTitle?: InputMaybe<Scalars["String"]>;
  jumpPillsTitle_contains?: InputMaybe<Scalars["String"]>;
  jumpPillsTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  jumpPillsTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  jumpPillsTitle_not?: InputMaybe<Scalars["String"]>;
  jumpPillsTitle_not_contains?: InputMaybe<Scalars["String"]>;
  jumpPillsTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  magnetPages?: InputMaybe<ContentfulcfMagnetPageNestedFilter>;
  magnetPagesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulCategoryPageFilter>>>;
  relatedCategories?: InputMaybe<ContentfulcfUpsellClassCardNestedFilter>;
  relatedCategoriesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  showFeaturedClasses?: InputMaybe<Scalars["Boolean"]>;
  showFeaturedClasses_exists?: InputMaybe<Scalars["Boolean"]>;
  showFeaturedClasses_not?: InputMaybe<Scalars["Boolean"]>;
  startAfter?: InputMaybe<Scalars["String"]>;
  startAfter_contains?: InputMaybe<Scalars["String"]>;
  startAfter_exists?: InputMaybe<Scalars["Boolean"]>;
  startAfter_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  startAfter_not?: InputMaybe<Scalars["String"]>;
  startAfter_not_contains?: InputMaybe<Scalars["String"]>;
  startAfter_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfJumpPillsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfJumpPillsNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfJumpPillsNestedFilter>>>;
  path?: InputMaybe<Scalars["String"]>;
  path_contains?: InputMaybe<Scalars["String"]>;
  path_exists?: InputMaybe<Scalars["Boolean"]>;
  path_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  path_not?: InputMaybe<Scalars["String"]>;
  path_not_contains?: InputMaybe<Scalars["String"]>;
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfMagnetPageNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfMagnetPageNestedFilter>>>;
  author_exists?: InputMaybe<Scalars["Boolean"]>;
  body_contains?: InputMaybe<Scalars["String"]>;
  body_exists?: InputMaybe<Scalars["Boolean"]>;
  body_not_contains?: InputMaybe<Scalars["String"]>;
  categoryLabel_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  headerImage_exists?: InputMaybe<Scalars["Boolean"]>;
  magnetPageSlug?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_contains?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_exists?: InputMaybe<Scalars["Boolean"]>;
  magnetPageSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  magnetPageSlug_not?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_not_contains?: InputMaybe<Scalars["String"]>;
  magnetPageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfMagnetPageNestedFilter>>>;
  summary_contains?: InputMaybe<Scalars["String"]>;
  summary_exists?: InputMaybe<Scalars["Boolean"]>;
  summary_not_contains?: InputMaybe<Scalars["String"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  upsellClassCarousel_exists?: InputMaybe<Scalars["Boolean"]>;
};

export enum ContentfulClpEmailCaptureOrder {
  CallToActionTextAsc = "callToActionText_ASC",
  CallToActionTextDesc = "callToActionText_DESC",
  ErrorMessageAsc = "errorMessage_ASC",
  ErrorMessageDesc = "errorMessage_DESC",
  IterableLinkAsc = "iterableLink_ASC",
  IterableLinkDesc = "iterableLink_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  ProdIterableAudienceIdAsc = "prodIterableAudienceId_ASC",
  ProdIterableAudienceIdDesc = "prodIterableAudienceId_DESC",
  StagingIterableAudienceIdAsc = "stagingIterableAudienceId_ASC",
  StagingIterableAudienceIdDesc = "stagingIterableAudienceId_DESC",
  SuccessMessageAsc = "successMessage_ASC",
  SuccessMessageDesc = "successMessage_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulClpEmailCaptureFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulClpEmailCaptureFilter>>>;
  bodyContent_contains?: InputMaybe<Scalars["String"]>;
  bodyContent_exists?: InputMaybe<Scalars["Boolean"]>;
  bodyContent_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionText?: InputMaybe<Scalars["String"]>;
  callToActionText_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_exists?: InputMaybe<Scalars["Boolean"]>;
  callToActionText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  callToActionText_not?: InputMaybe<Scalars["String"]>;
  callToActionText_not_contains?: InputMaybe<Scalars["String"]>;
  callToActionText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  clpPages?: InputMaybe<ContentfulcfCategoryLabelNestedFilter>;
  clpPagesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  errorMessage?: InputMaybe<Scalars["String"]>;
  errorMessage_contains?: InputMaybe<Scalars["String"]>;
  errorMessage_exists?: InputMaybe<Scalars["Boolean"]>;
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  errorMessage_not?: InputMaybe<Scalars["String"]>;
  errorMessage_not_contains?: InputMaybe<Scalars["String"]>;
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  iterableLink?: InputMaybe<Scalars["String"]>;
  iterableLink_contains?: InputMaybe<Scalars["String"]>;
  iterableLink_exists?: InputMaybe<Scalars["Boolean"]>;
  iterableLink_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  iterableLink_not?: InputMaybe<Scalars["String"]>;
  iterableLink_not_contains?: InputMaybe<Scalars["String"]>;
  iterableLink_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulClpEmailCaptureFilter>>>;
  prodIterableAudienceId?: InputMaybe<Scalars["String"]>;
  prodIterableAudienceId_contains?: InputMaybe<Scalars["String"]>;
  prodIterableAudienceId_exists?: InputMaybe<Scalars["Boolean"]>;
  prodIterableAudienceId_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  prodIterableAudienceId_not?: InputMaybe<Scalars["String"]>;
  prodIterableAudienceId_not_contains?: InputMaybe<Scalars["String"]>;
  prodIterableAudienceId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  stagingIterableAudienceId?: InputMaybe<Scalars["String"]>;
  stagingIterableAudienceId_contains?: InputMaybe<Scalars["String"]>;
  stagingIterableAudienceId_exists?: InputMaybe<Scalars["Boolean"]>;
  stagingIterableAudienceId_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  stagingIterableAudienceId_not?: InputMaybe<Scalars["String"]>;
  stagingIterableAudienceId_not_contains?: InputMaybe<Scalars["String"]>;
  stagingIterableAudienceId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  successMessage?: InputMaybe<Scalars["String"]>;
  successMessage_contains?: InputMaybe<Scalars["String"]>;
  successMessage_exists?: InputMaybe<Scalars["Boolean"]>;
  successMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  successMessage_not?: InputMaybe<Scalars["String"]>;
  successMessage_not_contains?: InputMaybe<Scalars["String"]>;
  successMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulEntryOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulEntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulEntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulEntryFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulFeaturedPagesOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulFeaturedPagesFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulFeaturedPagesFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulFeaturedPagesFilter>>>;
  pages?: InputMaybe<ContentfulcfMagnetPageNestedFilter>;
  pagesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulHeroSlideOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulHomePage2Order {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulHomePage2Filter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulHomePage2Filter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  heroSlides?: InputMaybe<ContentfulcfHeroSlideNestedFilter>;
  heroSlidesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulHomePage2Filter>>>;
  popularSearchLinks?: InputMaybe<ContentfulcfVisualLinkNestedFilter>;
  popularSearchLinksCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  valueProps?: InputMaybe<ContentfulcfValuePropNestedFilter>;
  valuePropsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  visualLinkGrids?: InputMaybe<ContentfulcfVisualLinkGridNestedFilter>;
  visualLinkGridsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulcfHeroSlideNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfHeroSlideNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfHeroSlideNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfValuePropNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfValuePropNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  icon_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfValuePropNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfVisualLinkGridNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfVisualLinkGridNestedFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfVisualLinkGridNestedFilter>>>;
  style?: InputMaybe<Scalars["String"]>;
  style_contains?: InputMaybe<Scalars["String"]>;
  style_exists?: InputMaybe<Scalars["Boolean"]>;
  style_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  style_not?: InputMaybe<Scalars["String"]>;
  style_not_contains?: InputMaybe<Scalars["String"]>;
  style_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  visualLinksCollection_exists?: InputMaybe<Scalars["Boolean"]>;
};

export enum ContentfulJumpPillsOrder {
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulLandingPageClassCarouselOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageClassCarouselFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageClassCarouselFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageClassCarouselFilter>>>;
  searchFilters?: InputMaybe<ContentfulcfSearchFiltersNestedFilter>;
  searchFilters_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageOrder {
  MetaTagCanonicalUrlAsc = "metaTagCanonicalUrl_ASC",
  MetaTagCanonicalUrlDesc = "metaTagCanonicalUrl_DESC",
  MetaTagDescriptionAsc = "metaTagDescription_ASC",
  MetaTagDescriptionDesc = "metaTagDescription_DESC",
  NavbarCtaTextAsc = "navbarCtaText_ASC",
  NavbarCtaTextDesc = "navbarCtaText_DESC",
  NavbarCtaUrlAsc = "navbarCtaUrl_ASC",
  NavbarCtaUrlDesc = "navbarCtaUrl_DESC",
  NavbarCtaUrlLoggedInAsc = "navbarCtaUrlLoggedIn_ASC",
  NavbarCtaUrlLoggedInDesc = "navbarCtaUrlLoggedIn_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  metaTagCanonicalUrl?: InputMaybe<Scalars["String"]>;
  metaTagCanonicalUrl_contains?: InputMaybe<Scalars["String"]>;
  metaTagCanonicalUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  metaTagCanonicalUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  metaTagCanonicalUrl_not?: InputMaybe<Scalars["String"]>;
  metaTagCanonicalUrl_not_contains?: InputMaybe<Scalars["String"]>;
  metaTagCanonicalUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  metaTagDescription?: InputMaybe<Scalars["String"]>;
  metaTagDescription_contains?: InputMaybe<Scalars["String"]>;
  metaTagDescription_exists?: InputMaybe<Scalars["Boolean"]>;
  metaTagDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  metaTagDescription_not?: InputMaybe<Scalars["String"]>;
  metaTagDescription_not_contains?: InputMaybe<Scalars["String"]>;
  metaTagDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  metaTagSharedLinkRichPreviewImage_exists?: InputMaybe<Scalars["Boolean"]>;
  navbarCtaText?: InputMaybe<Scalars["String"]>;
  navbarCtaText_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaText_exists?: InputMaybe<Scalars["Boolean"]>;
  navbarCtaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navbarCtaText_not?: InputMaybe<Scalars["String"]>;
  navbarCtaText_not_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navbarCtaUrl?: InputMaybe<Scalars["String"]>;
  navbarCtaUrl_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  navbarCtaUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navbarCtaUrl_not?: InputMaybe<Scalars["String"]>;
  navbarCtaUrl_not_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navbarCtaUrlLoggedIn?: InputMaybe<Scalars["String"]>;
  navbarCtaUrlLoggedIn_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaUrlLoggedIn_exists?: InputMaybe<Scalars["Boolean"]>;
  navbarCtaUrlLoggedIn_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  navbarCtaUrlLoggedIn_not?: InputMaybe<Scalars["String"]>;
  navbarCtaUrlLoggedIn_not_contains?: InputMaybe<Scalars["String"]>;
  navbarCtaUrlLoggedIn_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageFilter>>>;
  sections?: InputMaybe<ContentfulcfsectionsMultiTypeNestedFilter>;
  sectionsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  slug?: InputMaybe<Scalars["String"]>;
  slug_contains?: InputMaybe<Scalars["String"]>;
  slug_exists?: InputMaybe<Scalars["Boolean"]>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug_not?: InputMaybe<Scalars["String"]>;
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfsectionsMultiTypeNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfsectionsMultiTypeNestedFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfsectionsMultiTypeNestedFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulLandingPageHeroSectionOrder {
  AnimationUrlAsc = "animationUrl_ASC",
  AnimationUrlDesc = "animationUrl_DESC",
  ButtonCtaTextAsc = "buttonCtaText_ASC",
  ButtonCtaTextDesc = "buttonCtaText_DESC",
  ButtonUrlAsc = "buttonUrl_ASC",
  ButtonUrlDesc = "buttonUrl_DESC",
  ButtonUrlLoggedInAsc = "buttonUrlLoggedIn_ASC",
  ButtonUrlLoggedInDesc = "buttonUrlLoggedIn_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  ShouldShowCtaAsc = "shouldShowCta_ASC",
  ShouldShowCtaDesc = "shouldShowCta_DESC",
  ShouldShowTrustPilotAsc = "shouldShowTrustPilot_ASC",
  ShouldShowTrustPilotDesc = "shouldShowTrustPilot_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulLandingPageHeroSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLandingPageHeroSectionFilter>>>;
  animationCssDesktop_exists?: InputMaybe<Scalars["Boolean"]>;
  animationCssMobile_exists?: InputMaybe<Scalars["Boolean"]>;
  animationUrl?: InputMaybe<Scalars["String"]>;
  animationUrl_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  animationUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  animationUrl_not?: InputMaybe<Scalars["String"]>;
  animationUrl_not_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonCtaText?: InputMaybe<Scalars["String"]>;
  buttonCtaText_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonCtaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonCtaText_not?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl?: InputMaybe<Scalars["String"]>;
  buttonUrl_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl_not?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrlLoggedIn_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn_not?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  ID?: InputMaybe<Scalars["String"]>;
  ID_contains?: InputMaybe<Scalars["String"]>;
  ID_exists?: InputMaybe<Scalars["Boolean"]>;
  ID_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ID_not?: InputMaybe<Scalars["String"]>;
  ID_not_contains?: InputMaybe<Scalars["String"]>;
  ID_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  mainImage_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageHeroSectionFilter>>>;
  shouldShowCta?: InputMaybe<Scalars["Boolean"]>;
  shouldShowCta_exists?: InputMaybe<Scalars["Boolean"]>;
  shouldShowCta_not?: InputMaybe<Scalars["Boolean"]>;
  shouldShowTrustPilot?: InputMaybe<Scalars["Boolean"]>;
  shouldShowTrustPilot_exists?: InputMaybe<Scalars["Boolean"]>;
  shouldShowTrustPilot_not?: InputMaybe<Scalars["Boolean"]>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageIconInfoOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulLandingPageIconInfoSectionOrder {
  DisplayOnMobileAsc = "displayOnMobile_ASC",
  DisplayOnMobileDesc = "displayOnMobile_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulLandingPageIconInfoSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageIconInfoSectionFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayOnMobile?: InputMaybe<Scalars["Boolean"]>;
  displayOnMobile_exists?: InputMaybe<Scalars["Boolean"]>;
  displayOnMobile_not?: InputMaybe<Scalars["Boolean"]>;
  iconInfos?: InputMaybe<ContentfulcfLandingPageIconInfoNestedFilter>;
  iconInfosCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageIconInfoSectionFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfLandingPageIconInfoNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageIconInfoNestedFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  iconImage_exists?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageIconInfoNestedFilter>>
  >;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulLandingPageImageTextCtaSectionOrder {
  ButtonCtaTextAsc = "buttonCtaText_ASC",
  ButtonCtaTextDesc = "buttonCtaText_DESC",
  ButtonUrlAsc = "buttonUrl_ASC",
  ButtonUrlDesc = "buttonUrl_DESC",
  ButtonUrlLoggedInAsc = "buttonUrlLoggedIn_ASC",
  ButtonUrlLoggedInDesc = "buttonUrlLoggedIn_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleEndColorAsc = "titleEndColor_ASC",
  TitleEndColorDesc = "titleEndColor_DESC",
  TitleEndTextAsc = "titleEndText_ASC",
  TitleEndTextDesc = "titleEndText_DESC",
  TitleStartColorAsc = "titleStartColor_ASC",
  TitleStartColorDesc = "titleStartColor_DESC",
  TitleStartTextAsc = "titleStartText_ASC",
  TitleStartTextDesc = "titleStartText_DESC",
}

export type ContentfulLandingPageImageTextCtaSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageImageTextCtaSectionFilter>>
  >;
  buttonCtaText?: InputMaybe<Scalars["String"]>;
  buttonCtaText_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonCtaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonCtaText_not?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl?: InputMaybe<Scalars["String"]>;
  buttonUrl_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl_not?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrlLoggedIn_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn_not?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageImageTextCtaSectionFilter>>
  >;
  subtitleRt_contains?: InputMaybe<Scalars["String"]>;
  subtitleRt_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitleRt_not_contains?: InputMaybe<Scalars["String"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  titleEndColor?: InputMaybe<Scalars["String"]>;
  titleEndColor_contains?: InputMaybe<Scalars["String"]>;
  titleEndColor_exists?: InputMaybe<Scalars["Boolean"]>;
  titleEndColor_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleEndColor_not?: InputMaybe<Scalars["String"]>;
  titleEndColor_not_contains?: InputMaybe<Scalars["String"]>;
  titleEndColor_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleEndText?: InputMaybe<Scalars["String"]>;
  titleEndText_contains?: InputMaybe<Scalars["String"]>;
  titleEndText_exists?: InputMaybe<Scalars["Boolean"]>;
  titleEndText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleEndText_not?: InputMaybe<Scalars["String"]>;
  titleEndText_not_contains?: InputMaybe<Scalars["String"]>;
  titleEndText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleStartColor?: InputMaybe<Scalars["String"]>;
  titleStartColor_contains?: InputMaybe<Scalars["String"]>;
  titleStartColor_exists?: InputMaybe<Scalars["Boolean"]>;
  titleStartColor_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleStartColor_not?: InputMaybe<Scalars["String"]>;
  titleStartColor_not_contains?: InputMaybe<Scalars["String"]>;
  titleStartColor_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleStartText?: InputMaybe<Scalars["String"]>;
  titleStartText_contains?: InputMaybe<Scalars["String"]>;
  titleStartText_exists?: InputMaybe<Scalars["Boolean"]>;
  titleStartText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  titleStartText_not?: InputMaybe<Scalars["String"]>;
  titleStartText_not_contains?: InputMaybe<Scalars["String"]>;
  titleStartText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageJourneyItemOrder {
  AnimationUrlAsc = "animationUrl_ASC",
  AnimationUrlDesc = "animationUrl_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NumberColorAsc = "numberColor_ASC",
  NumberColorDesc = "numberColor_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulLandingPageJourneySectionOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageJourneySectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageJourneySectionFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  journeyItems?: InputMaybe<ContentfulcfLandingPageJourneyItemNestedFilter>;
  journeyItemsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageJourneySectionFilter>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type ContentfulcfLandingPageJourneyItemNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageJourneyItemNestedFilter>>
  >;
  animationUrl?: InputMaybe<Scalars["String"]>;
  animationUrl_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  animationUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  animationUrl_not?: InputMaybe<Scalars["String"]>;
  animationUrl_not_contains?: InputMaybe<Scalars["String"]>;
  animationUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  numberColor?: InputMaybe<Scalars["String"]>;
  numberColor_contains?: InputMaybe<Scalars["String"]>;
  numberColor_exists?: InputMaybe<Scalars["Boolean"]>;
  numberColor_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  numberColor_not?: InputMaybe<Scalars["String"]>;
  numberColor_not_contains?: InputMaybe<Scalars["String"]>;
  numberColor_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageJourneyItemNestedFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageLessonPreviewSectionOrder {
  CtaTextAsc = "ctaText_ASC",
  CtaTextDesc = "ctaText_DESC",
  CtaUrlAsc = "ctaUrl_ASC",
  CtaUrlDesc = "ctaUrl_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageLessonPreviewSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageLessonPreviewSectionFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  ctaText?: InputMaybe<Scalars["String"]>;
  ctaText_contains?: InputMaybe<Scalars["String"]>;
  ctaText_exists?: InputMaybe<Scalars["Boolean"]>;
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ctaText_not?: InputMaybe<Scalars["String"]>;
  ctaText_not_contains?: InputMaybe<Scalars["String"]>;
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ctaUrl?: InputMaybe<Scalars["String"]>;
  ctaUrl_contains?: InputMaybe<Scalars["String"]>;
  ctaUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ctaUrl_not?: InputMaybe<Scalars["String"]>;
  ctaUrl_not_contains?: InputMaybe<Scalars["String"]>;
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  description_contains?: InputMaybe<Scalars["String"]>;
  description_exists?: InputMaybe<Scalars["Boolean"]>;
  description_not_contains?: InputMaybe<Scalars["String"]>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageLessonPreviewSectionFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  video_exists?: InputMaybe<Scalars["Boolean"]>;
};

export enum ContentfulLandingPageSingleReviewSectionOrder {
  ClassNameAsc = "className_ASC",
  ClassNameDesc = "className_DESC",
  ClassUrlAsc = "classUrl_ASC",
  ClassUrlDesc = "classUrl_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  ReviewerNameAsc = "reviewerName_ASC",
  ReviewerNameDesc = "reviewerName_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulLandingPageSingleReviewSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageSingleReviewSectionFilter>>
  >;
  className?: InputMaybe<Scalars["String"]>;
  className_contains?: InputMaybe<Scalars["String"]>;
  className_exists?: InputMaybe<Scalars["Boolean"]>;
  className_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  className_not?: InputMaybe<Scalars["String"]>;
  className_not_contains?: InputMaybe<Scalars["String"]>;
  className_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  classUrl?: InputMaybe<Scalars["String"]>;
  classUrl_contains?: InputMaybe<Scalars["String"]>;
  classUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  classUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  classUrl_not?: InputMaybe<Scalars["String"]>;
  classUrl_not_contains?: InputMaybe<Scalars["String"]>;
  classUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  imageMobile_exists?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageSingleReviewSectionFilter>>
  >;
  reviewerName?: InputMaybe<Scalars["String"]>;
  reviewerName_contains?: InputMaybe<Scalars["String"]>;
  reviewerName_exists?: InputMaybe<Scalars["Boolean"]>;
  reviewerName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  reviewerName_not?: InputMaybe<Scalars["String"]>;
  reviewerName_not_contains?: InputMaybe<Scalars["String"]>;
  reviewerName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  reviewImageBackgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  reviewText_contains?: InputMaybe<Scalars["String"]>;
  reviewText_exists?: InputMaybe<Scalars["Boolean"]>;
  reviewText_not_contains?: InputMaybe<Scalars["String"]>;
  reviewTextBackgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulLandingPageStatBoxOrder {
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulLandingPageStatBoxSectionOrder {
  DisplayOnMobileAsc = "displayOnMobile_ASC",
  DisplayOnMobileDesc = "displayOnMobile_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulLandingPageStatBoxSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageStatBoxSectionFilter>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  displayOnMobile?: InputMaybe<Scalars["Boolean"]>;
  displayOnMobile_exists?: InputMaybe<Scalars["Boolean"]>;
  displayOnMobile_not?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLandingPageStatBoxSectionFilter>>>;
  statBoxes?: InputMaybe<ContentfulcfLandingPageStatBoxNestedFilter>;
  statBoxesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfLandingPageStatBoxNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageStatBoxNestedFilter>>
  >;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  hoverBackgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulcfLandingPageStatBoxNestedFilter>>
  >;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLandingPageTextOnlyStackedCtaSectionOrder {
  ButtonCtaTextAsc = "buttonCtaText_ASC",
  ButtonCtaTextDesc = "buttonCtaText_DESC",
  ButtonUrlAsc = "buttonUrl_ASC",
  ButtonUrlDesc = "buttonUrl_DESC",
  ButtonUrlLoggedInAsc = "buttonUrlLoggedIn_ASC",
  ButtonUrlLoggedInDesc = "buttonUrlLoggedIn_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IncludeGradientBackgroundAsc = "includeGradientBackground_ASC",
  IncludeGradientBackgroundDesc = "includeGradientBackground_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLandingPageTextOnlyStackedCtaSectionFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageTextOnlyStackedCtaSectionFilter>>
  >;
  backgroundImage_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonCtaText?: InputMaybe<Scalars["String"]>;
  buttonCtaText_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonCtaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonCtaText_not?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl?: InputMaybe<Scalars["String"]>;
  buttonUrl_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrl_not?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonUrlLoggedIn_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonUrlLoggedIn_not?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_contains?: InputMaybe<Scalars["String"]>;
  buttonUrlLoggedIn_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  id?: InputMaybe<Scalars["String"]>;
  id_contains?: InputMaybe<Scalars["String"]>;
  id_exists?: InputMaybe<Scalars["Boolean"]>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  id_not?: InputMaybe<Scalars["String"]>;
  id_not_contains?: InputMaybe<Scalars["String"]>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  includeGradientBackground?: InputMaybe<Scalars["Boolean"]>;
  includeGradientBackground_exists?: InputMaybe<Scalars["Boolean"]>;
  includeGradientBackground_not?: InputMaybe<Scalars["Boolean"]>;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulLandingPageTextOnlyStackedCtaSectionFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulLearnerBannerOrder {
  CtaTextAsc = "ctaText_ASC",
  CtaTextDesc = "ctaText_DESC",
  CtaUrlAsc = "ctaUrl_ASC",
  CtaUrlDesc = "ctaUrl_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulLearnerBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulLearnerBannerFilter>>>;
  campaign?: InputMaybe<ContentfulcfCampaignNestedFilter>;
  campaign_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  ctaText?: InputMaybe<Scalars["String"]>;
  ctaText_contains?: InputMaybe<Scalars["String"]>;
  ctaText_exists?: InputMaybe<Scalars["Boolean"]>;
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ctaText_not?: InputMaybe<Scalars["String"]>;
  ctaText_not_contains?: InputMaybe<Scalars["String"]>;
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ctaUrl?: InputMaybe<Scalars["String"]>;
  ctaUrl_contains?: InputMaybe<Scalars["String"]>;
  ctaUrl_exists?: InputMaybe<Scalars["Boolean"]>;
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  ctaUrl_not?: InputMaybe<Scalars["String"]>;
  ctaUrl_not_contains?: InputMaybe<Scalars["String"]>;
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  image_exists?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulLearnerBannerFilter>>>;
  subtitle?: InputMaybe<Scalars["String"]>;
  subtitle_contains?: InputMaybe<Scalars["String"]>;
  subtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subtitle_not?: InputMaybe<Scalars["String"]>;
  subtitle_not_contains?: InputMaybe<Scalars["String"]>;
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export enum ContentfulListingsPromoBannerButtonOrder {
  ButtonTextAsc = "buttonText_ASC",
  ButtonTextDesc = "buttonText_DESC",
  ExploreMoreButtonTextAsc = "exploreMoreButtonText_ASC",
  ExploreMoreButtonTextDesc = "exploreMoreButtonText_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulListingsPromoBannerOrder {
  ExperimentVersionAsc = "experimentVersion_ASC",
  ExperimentVersionDesc = "experimentVersion_DESC",
  HeaderSubtitleAsc = "headerSubtitle_ASC",
  HeaderSubtitleDesc = "headerSubtitle_DESC",
  HeaderTitleAsc = "headerTitle_ASC",
  HeaderTitleDesc = "headerTitle_DESC",
  IncludeControlGroupAsc = "includeControlGroup_ASC",
  IncludeControlGroupDesc = "includeControlGroup_DESC",
  IsLiveOnSearchAsc = "isLiveOnSearch_ASC",
  IsLiveOnSearchDesc = "isLiveOnSearch_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  SidebarTitleAsc = "sidebarTitle_ASC",
  SidebarTitleDesc = "sidebarTitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export type ContentfulListingsPromoBannerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulListingsPromoBannerFilter>>>;
  backgroundImageLeft_exists?: InputMaybe<Scalars["Boolean"]>;
  backgroundImageMobile_exists?: InputMaybe<Scalars["Boolean"]>;
  backgroundImageRight_exists?: InputMaybe<Scalars["Boolean"]>;
  buttons?: InputMaybe<ContentfulcfListingsPromoBannerButtonNestedFilter>;
  buttonsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  experimentVersion?: InputMaybe<Scalars["Int"]>;
  experimentVersion_exists?: InputMaybe<Scalars["Boolean"]>;
  experimentVersion_gt?: InputMaybe<Scalars["Int"]>;
  experimentVersion_gte?: InputMaybe<Scalars["Int"]>;
  experimentVersion_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  experimentVersion_lt?: InputMaybe<Scalars["Int"]>;
  experimentVersion_lte?: InputMaybe<Scalars["Int"]>;
  experimentVersion_not?: InputMaybe<Scalars["Int"]>;
  experimentVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  headerSubtitle?: InputMaybe<Scalars["String"]>;
  headerSubtitle_contains?: InputMaybe<Scalars["String"]>;
  headerSubtitle_exists?: InputMaybe<Scalars["Boolean"]>;
  headerSubtitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  headerSubtitle_not?: InputMaybe<Scalars["String"]>;
  headerSubtitle_not_contains?: InputMaybe<Scalars["String"]>;
  headerSubtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  headerTitle?: InputMaybe<Scalars["String"]>;
  headerTitle_contains?: InputMaybe<Scalars["String"]>;
  headerTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  headerTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  headerTitle_not?: InputMaybe<Scalars["String"]>;
  headerTitle_not_contains?: InputMaybe<Scalars["String"]>;
  headerTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  headerTitleFontDefinition?: InputMaybe<ContentfulcfListingsPromoBannerHeaderTitleFontDefinitionNestedFilter>;
  headerTitleFontDefinition_exists?: InputMaybe<Scalars["Boolean"]>;
  includeControlGroup?: InputMaybe<Scalars["Boolean"]>;
  includeControlGroup_exists?: InputMaybe<Scalars["Boolean"]>;
  includeControlGroup_not?: InputMaybe<Scalars["Boolean"]>;
  isLiveOnSearch?: InputMaybe<Scalars["Boolean"]>;
  isLiveOnSearch_exists?: InputMaybe<Scalars["Boolean"]>;
  isLiveOnSearch_not?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_exists?: InputMaybe<Scalars["Boolean"]>;
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulListingsPromoBannerFilter>>>;
  sidebarTitle?: InputMaybe<Scalars["String"]>;
  sidebarTitle_contains?: InputMaybe<Scalars["String"]>;
  sidebarTitle_exists?: InputMaybe<Scalars["Boolean"]>;
  sidebarTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sidebarTitle_not?: InputMaybe<Scalars["String"]>;
  sidebarTitle_not_contains?: InputMaybe<Scalars["String"]>;
  sidebarTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfListingsPromoBannerButtonNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<ContentfulcfListingsPromoBannerButtonNestedFilter>>
  >;
  buttonSearchFilters_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText?: InputMaybe<Scalars["String"]>;
  buttonText_contains?: InputMaybe<Scalars["String"]>;
  buttonText_exists?: InputMaybe<Scalars["Boolean"]>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  buttonText_not?: InputMaybe<Scalars["String"]>;
  buttonText_not_contains?: InputMaybe<Scalars["String"]>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  exploreMoreButtonText?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_contains?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_exists?: InputMaybe<Scalars["Boolean"]>;
  exploreMoreButtonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  exploreMoreButtonText_not?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_not_contains?: InputMaybe<Scalars["String"]>;
  exploreMoreButtonText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]>>
  >;
  OR?: InputMaybe<
    Array<InputMaybe<ContentfulcfListingsPromoBannerButtonNestedFilter>>
  >;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export type ContentfulcfListingsPromoBannerHeaderTitleFontDefinitionNestedFilter =
  {
    AND?: InputMaybe<
      Array<
        InputMaybe<ContentfulcfListingsPromoBannerHeaderTitleFontDefinitionNestedFilter>
      >
    >;
    contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
    fontFamily?: InputMaybe<Scalars["String"]>;
    fontFamily_contains?: InputMaybe<Scalars["String"]>;
    fontFamily_exists?: InputMaybe<Scalars["Boolean"]>;
    fontFamily_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    fontFamily_not?: InputMaybe<Scalars["String"]>;
    fontFamily_not_contains?: InputMaybe<Scalars["String"]>;
    fontFamily_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    fontUrl?: InputMaybe<Scalars["String"]>;
    fontUrl_contains?: InputMaybe<Scalars["String"]>;
    fontUrl_exists?: InputMaybe<Scalars["Boolean"]>;
    fontUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    fontUrl_not?: InputMaybe<Scalars["String"]>;
    fontUrl_not_contains?: InputMaybe<Scalars["String"]>;
    fontUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
    OR?: InputMaybe<
      Array<
        InputMaybe<ContentfulcfListingsPromoBannerHeaderTitleFontDefinitionNestedFilter>
      >
    >;
    sys?: InputMaybe<ContentfulSysFilter>;
  };

export enum ContentfulListingsPromoBannerHeaderTitleFontDefinitionOrder {
  FontFamilyAsc = "fontFamily_ASC",
  FontFamilyDesc = "fontFamily_DESC",
  FontUrlAsc = "fontUrl_ASC",
  FontUrlDesc = "fontUrl_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulSearchFiltersOrder {
  CapacityMaxAsc = "capacityMax_ASC",
  CapacityMaxDesc = "capacityMax_DESC",
  CapacityMinAsc = "capacityMin_ASC",
  CapacityMinDesc = "capacityMin_DESC",
  DurationWeeksMaxAsc = "durationWeeksMax_ASC",
  DurationWeeksMaxDesc = "durationWeeksMax_DESC",
  DurationWeeksMinAsc = "durationWeeksMin_ASC",
  DurationWeeksMinDesc = "durationWeeksMin_DESC",
  EndByAsc = "endBy_ASC",
  EndByDesc = "endBy_DESC",
  FiltersNameAsc = "filtersName_ASC",
  FiltersNameDesc = "filtersName_DESC",
  HasFilledOutUniqueLearningNeedsAsc = "hasFilledOutUniqueLearningNeeds_ASC",
  HasFilledOutUniqueLearningNeedsDesc = "hasFilledOutUniqueLearningNeeds_DESC",
  MultiTermQueryAsc = "multiTermQuery_ASC",
  MultiTermQueryDesc = "multiTermQuery_DESC",
  PricePerCourseMaxAsc = "pricePerCourseMax_ASC",
  PricePerCourseMaxDesc = "pricePerCourseMax_DESC",
  PricePerCourseMinAsc = "pricePerCourseMin_ASC",
  PricePerCourseMinDesc = "pricePerCourseMin_DESC",
  PricePerMeetingMaxAsc = "pricePerMeetingMax_ASC",
  PricePerMeetingMaxDesc = "pricePerMeetingMax_DESC",
  PricePerMeetingMinAsc = "pricePerMeetingMin_ASC",
  PricePerMeetingMinDesc = "pricePerMeetingMin_DESC",
  QueryAsc = "query_ASC",
  QueryDesc = "query_DESC",
  StartAfterAsc = "startAfter_ASC",
  StartAfterDesc = "startAfter_DESC",
  StartBeforeAsc = "startBefore_ASC",
  StartBeforeDesc = "startBefore_DESC",
  SubjectAsc = "subject_ASC",
  SubjectDesc = "subject_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  UniqueLearningNeedsAsc = "uniqueLearningNeeds_ASC",
  UniqueLearningNeedsDesc = "uniqueLearningNeeds_DESC",
  WeeklyMeetingsMaxAsc = "weeklyMeetingsMax_ASC",
  WeeklyMeetingsMaxDesc = "weeklyMeetingsMax_DESC",
  WeeklyMeetingsMinAsc = "weeklyMeetingsMin_ASC",
  WeeklyMeetingsMinDesc = "weeklyMeetingsMin_DESC",
}

export enum ContentfulTopicOrder {
  CategorySlugAsc = "categorySlug_ASC",
  CategorySlugDesc = "categorySlug_DESC",
  NameTitleCasedAsc = "nameTitleCased_ASC",
  NameTitleCasedDesc = "nameTitleCased_DESC",
  SearchPathAsc = "searchPath_ASC",
  SearchPathDesc = "searchPath_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  SubjectAsc = "subject_ASC",
  SubjectDesc = "subject_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
}

export enum ContentfulTutoringPageOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type ContentfulTutoringPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulTutoringPageFilter>>>;
  articles?: InputMaybe<ContentfulcfMagnetPageNestedFilter>;
  articlesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  carouselSubcategories?: InputMaybe<ContentfulcfTopicNestedFilter>;
  carouselSubcategoriesCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulTutoringPageFilter>>>;
  popularSearchLinks?: InputMaybe<ContentfulcfVisualLinkNestedFilter>;
  popularSearchLinksCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  popularTutoringSubjects?: InputMaybe<ContentfulcfVisualLinkNestedFilter>;
  popularTutoringSubjectsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  sys?: InputMaybe<ContentfulSysFilter>;
  title?: InputMaybe<Scalars["String"]>;
  title_contains?: InputMaybe<Scalars["String"]>;
  title_exists?: InputMaybe<Scalars["Boolean"]>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  title_not?: InputMaybe<Scalars["String"]>;
  title_not_contains?: InputMaybe<Scalars["String"]>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  tutoringCarousel?: InputMaybe<ContentfulcfVisualLinkGridNestedFilter>;
  tutoringCarouselCollection_exists?: InputMaybe<Scalars["Boolean"]>;
  visualLinkGrids?: InputMaybe<ContentfulcfVisualLinkGridNestedFilter>;
  visualLinkGridsCollection_exists?: InputMaybe<Scalars["Boolean"]>;
};

export type ContentfulcfTopicNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentfulcfTopicNestedFilter>>>;
  categorySlug?: InputMaybe<Scalars["String"]>;
  categorySlug_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_exists?: InputMaybe<Scalars["Boolean"]>;
  categorySlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  categorySlug_not?: InputMaybe<Scalars["String"]>;
  categorySlug_not_contains?: InputMaybe<Scalars["String"]>;
  categorySlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  contentfulMetadata?: InputMaybe<ContentfulContentfulMetadataFilter>;
  nameTitleCased?: InputMaybe<Scalars["String"]>;
  nameTitleCased_contains?: InputMaybe<Scalars["String"]>;
  nameTitleCased_exists?: InputMaybe<Scalars["Boolean"]>;
  nameTitleCased_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  nameTitleCased_not?: InputMaybe<Scalars["String"]>;
  nameTitleCased_not_contains?: InputMaybe<Scalars["String"]>;
  nameTitleCased_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentfulcfTopicNestedFilter>>>;
  searchPath?: InputMaybe<Scalars["String"]>;
  searchPath_contains?: InputMaybe<Scalars["String"]>;
  searchPath_exists?: InputMaybe<Scalars["Boolean"]>;
  searchPath_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  searchPath_not?: InputMaybe<Scalars["String"]>;
  searchPath_not_contains?: InputMaybe<Scalars["String"]>;
  searchPath_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug?: InputMaybe<Scalars["String"]>;
  slug_contains?: InputMaybe<Scalars["String"]>;
  slug_exists?: InputMaybe<Scalars["Boolean"]>;
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  slug_not?: InputMaybe<Scalars["String"]>;
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject?: InputMaybe<Scalars["String"]>;
  subject_contains?: InputMaybe<Scalars["String"]>;
  subject_exists?: InputMaybe<Scalars["Boolean"]>;
  subject_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  subject_not?: InputMaybe<Scalars["String"]>;
  subject_not_contains?: InputMaybe<Scalars["String"]>;
  subject_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  sys?: InputMaybe<ContentfulSysFilter>;
};

export enum ContentfulUpsellClassCardOrder {
  CallToActionTextAsc = "callToActionText_ASC",
  CallToActionTextDesc = "callToActionText_DESC",
  CallToActionUrlAsc = "callToActionUrl_ASC",
  CallToActionUrlDesc = "callToActionUrl_DESC",
  CapacityMaxAsc = "capacityMax_ASC",
  CapacityMaxDesc = "capacityMax_DESC",
  CapacityMinAsc = "capacityMin_ASC",
  CapacityMinDesc = "capacityMin_DESC",
  HasFilledOutUniqueLearningNeedsAsc = "hasFilledOutUniqueLearningNeeds_ASC",
  HasFilledOutUniqueLearningNeedsDesc = "hasFilledOutUniqueLearningNeeds_DESC",
  MaxPriceCentsAsc = "maxPriceCents_ASC",
  MaxPriceCentsDesc = "maxPriceCents_DESC",
  MinPriceCentsAsc = "minPriceCents_ASC",
  MinPriceCentsDesc = "minPriceCents_DESC",
  MultiTermQueryAsc = "multiTermQuery_ASC",
  MultiTermQueryDesc = "multiTermQuery_DESC",
  QueryAsc = "query_ASC",
  QueryDesc = "query_DESC",
  SubtitleAsc = "subtitle_ASC",
  SubtitleDesc = "subtitle_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UniqueLearningNeedsAsc = "uniqueLearningNeeds_ASC",
  UniqueLearningNeedsDesc = "uniqueLearningNeeds_DESC",
  UpsellNameAsc = "upsellName_ASC",
  UpsellNameDesc = "upsellName_DESC",
}

export enum ContentfulValuePropOrder {
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export enum ContentfulVisualLinkOrder {
  ImageAltTextAsc = "imageAltText_ASC",
  ImageAltTextDesc = "imageAltText_DESC",
  ImageAspectRatioAsc = "imageAspectRatio_ASC",
  ImageAspectRatioDesc = "imageAspectRatio_DESC",
  PathAsc = "path_ASC",
  PathDesc = "path_DESC",
  PathBAsc = "pathB_ASC",
  PathBDesc = "pathB_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC",
}

export enum ContentfulVisualLinkGridOrder {
  StyleAsc = "style_ASC",
  StyleDesc = "style_DESC",
  SysFirstPublishedAtAsc = "sys_firstPublishedAt_ASC",
  SysFirstPublishedAtDesc = "sys_firstPublishedAt_DESC",
  SysIdAsc = "sys_id_ASC",
  SysIdDesc = "sys_id_DESC",
  SysPublishedAtAsc = "sys_publishedAt_ASC",
  SysPublishedAtDesc = "sys_publishedAt_DESC",
  SysPublishedVersionAsc = "sys_publishedVersion_ASC",
  SysPublishedVersionDesc = "sys_publishedVersion_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
}

export type CouponCodeListFilter = {
  code?: InputMaybe<Scalars["String"]>;
  couponType: CouponType;
  couponUid?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<CouponListFilterStatus>;
};

export enum CouponListFilterStatus {
  Active = "Active",
  All = "All",
  Inactive = "Inactive",
}

export type CouponListFilter = {
  couponType: CouponType;
  createdBy?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<CouponListFilterStatus>;
};

export enum LearnerAccessLevel {
  Full = "Full",
  Limited = "Limited",
  Restricted = "Restricted",
}

export enum TeacherRecommendationType {
  CurrentLearnersFavoriteTeachers = "CurrentLearnersFavoriteTeachers",
  PopularAndCurrentLearnerFavoriteTeachers = "PopularAndCurrentLearnerFavoriteTeachers",
  PopularTeachers = "PopularTeachers",
  SimilarTeachers = "SimilarTeachers",
}

export type InterestTopicSearchFilters = {
  startAfter?: InputMaybe<Scalars["String"]>;
  startBefore?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
};

export type GetPayoutsCsvUrlInput = {
  leaderUserId?: InputMaybe<Scalars["String"]>;
  payoutMonth: Scalars["Int"];
  payoutYear: Scalars["Int"];
};

export enum TnsAccountStatus {
  Open = "Open",
  OtherSuspended = "OtherSuspended",
  TnsSuspended = "TnsSuspended",
}

export enum TnsAction {
  ImmediateRemoval = "ImmediateRemoval",
  RemoveStrike = "RemoveStrike",
  Strike = "Strike",
  ToWatchTrend = "ToWatchTrend",
  UndoImmediateRemoval = "UndoImmediateRemoval",
  Warning = "Warning",
}

export enum TnsActionCategory {
  ClassroomSecurityIssues = "ClassroomSecurityIssues",
  ContentManagementPii = "ContentManagementPii",
  TeacherDangerousBehavior = "TeacherDangerousBehavior",
  TeacherMisbehaviorFraud = "TeacherMisbehaviorFraud",
}

/**
 * Which direction to paginate in.
 * Past returns notifications between cursor and the start of time
 * Future returns notifications between cursor and the end of time
 */
export enum CursorDirection {
  Future = "Future",
  Past = "Past",
}

/** The status of the notification.  "Seen" is currently unused */
export enum NotificationStatus {
  New = "New",
  Read = "Read",
  Seen = "Seen",
}

/** Represents what type of class post notification happened */
export enum AssignmentNotificationAction {
  NewAssignment = "NewAssignment",
  NewAssignmentComment = "NewAssignmentComment",
}

/** Represents what type of class post notification happened */
export enum ClassPostNotificationAction {
  Mentioned = "Mentioned",
  NewClassPost = "NewClassPost",
  NewClassPostComment = "NewClassPostComment",
}

/** Represents what type of club notification happened */
export enum ClubNotificationAction {
  Mentioned = "Mentioned",
  PostPromoted = "PostPromoted",
  RepliedToComment = "RepliedToComment",
  RepliedToPost = "RepliedToPost",
}

export type LearnerActivityByTopicSearchInput = {
  activityExperiences?: InputMaybe<Array<ActivityExperience>>;
  topicUid?: InputMaybe<Scalars["ID"]>;
};

export enum ActivityExperience {
  Classroom = "Classroom",
  Club = "Club",
}

export type LearnerKeywordSearchInput = {
  activityExperiences?: InputMaybe<Array<ActivityExperience>>;
  query?: InputMaybe<Scalars["String"]>;
};

export type LearnerSearchSuggestionsInput = {
  query: Scalars["String"];
};

export enum LearnerSupportType {
  Glitch = "GLITCH",
  IFeelUnsafe = "I_FEEL_UNSAFE",
  OtherLearnerBadBehavior = "OTHER_LEARNER_BAD_BEHAVIOR",
  OtherLearnerNeedsHelp = "OTHER_LEARNER_NEEDS_HELP",
}

export enum MessageThreadAction {
  ParentToClass = "parentToClass",
  ParentToParent = "parentToParent",
  ParentToTeacher = "parentToTeacher",
  PrivateClassMessage = "privateClassMessage",
  TeacherToParent = "teacherToParent",
  TeacherToTeacher = "teacherToTeacher",
  Unknown = "unknown",
}

export enum ActivityReviewMode {
  Orgs = "orgs",
  Regular = "regular",
  Skipped = "skipped",
  Subject = "subject",
}

export enum OrganizationAdminFilter {
  Active = "Active",
  All = "All",
  Deleted = "Deleted",
}

export type DateFilterInput = {
  fromDate?: InputMaybe<Scalars["DateTime"]>;
  toDate?: InputMaybe<Scalars["DateTime"]>;
};

export type SearchSuggestionsInput = {
  query: Scalars["String"];
};

export enum ConversationFilterType {
  Activity = "activity",
  Participant = "participant",
  Search = "search",
  Thread = "thread",
}

export enum CustomSearchUseCase {
  LearnerPlannerSearch = "LEARNER_PLANNER_SEARCH",
  PitchPageSearch = "PITCH_PAGE_SEARCH",
  TeacherPullSearch = "TEACHER_PULL_SEARCH",
  TutorMatchSearch = "TUTOR_MATCH_SEARCH",
}

/**
 * Experimental
 * Different types of filters that could be applied on the server-side.
 * Used by the client to signal which types of auto applied filters
 * it does not want the server-side to apply.
 */
export enum AutoAppliedFilterType {
  GradeLevels = "GradeLevels",
}

export enum PromotedExperimentGroup {
  Control = "control",
  Holdout = "holdout",
  Treatment = "treatment",
}

export enum SearchObjective {
  AvailableListings = "available_listings",
  UnavailableListings = "unavailable_listings",
}

export enum SearchUseCase {
  CategoryContent = "CATEGORY_CONTENT",
  CdpAsClp = "CDP_AS_CLP",
  Custom = "CUSTOM",
  Discover = "DISCOVER",
  Feed = "FEED",
  Search = "SEARCH",
}

/**
 * Experimental
 * Only spelling suggestions for user search queries is currently supported
 */
export enum SpellingSuggestionType {
  SearchQuery = "SearchQuery",
}

export enum TransactionType {
  ClassWallet = "ClassWallet",
  Credit = "Credit",
  Order = "Order",
}

export type BulkOrderInput = {
  attribution?: InputMaybe<Attribution>;
  cartUid?: InputMaybe<Scalars["String"]>;
  charterParentInput?: InputMaybe<EnrollmentCharterParentInput>;
  currency_code?: InputMaybe<CurrencyCode>;
  enrollmentInputs: Array<BulkEnrollmentInput>;
  exchange_rate?: InputMaybe<Scalars["Float"]>;
  impressionId?: InputMaybe<Scalars["String"]>;
  learnerPlanUid?: InputMaybe<Scalars["ID"]>;
  message?: InputMaybe<Scalars["String"]>;
  organizationUid?: InputMaybe<Scalars["ID"]>;
  purchaseOrderCoverage?: InputMaybe<Scalars["DateTime"]>;
};

export type Attribution = {
  activity_uid?: InputMaybe<Scalars["String"]>;
  addressBar?: InputMaybe<Scalars["Boolean"]>;
  addressBarUserSlugId?: InputMaybe<Scalars["String"]>;
  anonymousId?: InputMaybe<Scalars["String"]>;
  awc?: InputMaybe<Scalars["String"]>;
  campaign?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["DateTime"]>;
  landingPage?: InputMaybe<Scalars["String"]>;
  manualReferralType?: InputMaybe<Scalars["String"]>;
  medium?: InputMaybe<Scalars["String"]>;
  referer?: InputMaybe<Scalars["String"]>;
  ruid?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  term?: InputMaybe<Scalars["String"]>;
  usid?: InputMaybe<Scalars["String"]>;
};

export type EnrollmentCharterParentInput = {
  email: Scalars["String"];
  name: Scalars["String"];
};

export type BulkEnrollmentInput = {
  activityUid: Scalars["ID"];
  canScheduleContinuously?: InputMaybe<Scalars["Boolean"]>;
  endTime?: InputMaybe<Scalars["DateTime"]>;
  enrollableMeetings?: InputMaybe<Array<BulkFutureMeetingInput>>;
  isGift?: InputMaybe<Scalars["Boolean"]>;
  isOneOnOneInput?: InputMaybe<Scalars["Boolean"]>;
  isWeeklyPayment?: InputMaybe<Scalars["Boolean"]>;
  learner?: InputMaybe<EnrollmentLearnerInput>;
  meetingDurationWeeks?: InputMaybe<Scalars["Int"]>;
  oneOnOneDuration?: InputMaybe<Scalars["DateTime"]>;
  ongoingEndTime?: InputMaybe<Scalars["DateTime"]>;
  ongoingStartTime?: InputMaybe<Scalars["DateTime"]>;
  orgTeacherUid?: InputMaybe<Scalars["ID"]>;
  pricing_offer?: InputMaybe<Scalars["String"]>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
  selfPacedStartTime?: InputMaybe<Scalars["DateTime"]>;
  startTime?: InputMaybe<Scalars["DateTime"]>;
  uid?: InputMaybe<Scalars["ID"]>;
};

export type EnrollmentLearnerInput = {
  age: Scalars["Int"];
  date_of_birth?: InputMaybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  uid?: InputMaybe<Scalars["ID"]>;
};

export type CouponOrderInput = {
  activityUid: Scalars["ID"];
};

export type FundingProgramInvoiceInput = {
  awardId: Scalars["String"];
  childFirstName: Scalars["String"];
  childLastName: Scalars["String"];
  parentFirstName: Scalars["String"];
  parentLastName: Scalars["String"];
};

export type MeetingSuggestionInput = {
  endTime: Scalars["DateTime"];
  startTime: Scalars["DateTime"];
};

export type DeviceActivationInfo = {
  /** The app version of the device */
  appVersion: Scalars["String"];
  /** The token which authorizes us to send push notifications to this device */
  deviceToken: Scalars["String"];
  /**
   * If auth token is for a learner, you cannot set this value.  Learner uid is
   * implied based on the auth token provided.
   *
   * If auth token is for a user (parent) and learnerUid is set, the device
   * is associated with a specific learner, and we will only push notifications
   * for a given learner.  If it is not set, we will treat it as a "shared"
   * device and send notifications for all learners.
   */
  learnerUid?: InputMaybe<Scalars["ID"]>;
  /** The OS version of the device */
  osVersion: Scalars["String"];
  /** Which platform this device is on.  One of iOS or Android. */
  platform: DevicePlatform;
  /**
   * What categories of notifications are supported by this device.
   * The backend will only send categories of notifications that are in the provided array.
   * Defaults to [PrivateClassMessage, ClassPost, ClassPostComment, Meeting]
   */
  supportedNotifications?: InputMaybe<Array<SupportedNotifications>>;
};

export enum DevicePlatform {
  /** Device is Android, and we should use FCM */
  Android = "Android",
  /** Device is iOS, and we should use APNs */
  IOs = "iOS",
}

export enum SupportedNotifications {
  AssignmentPost = "AssignmentPost",
  AssignmentPostComment = "AssignmentPostComment",
  ClassPost = "ClassPost",
  ClassPostComment = "ClassPostComment",
  Meeting = "Meeting",
  PrivateClassMessage = "PrivateClassMessage",
  UserMessage = "UserMessage",
}

export type CreateAssignmentClassPostInput = {
  dueDate?: InputMaybe<Scalars["DateTime"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContent: MessageContentInput;
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  sectionUid: Scalars["ID"];
  submissionRequirement?: InputMaybe<SubmissionRequirementType>;
  title: Scalars["String"];
};

export type MessageContentInput = {
  attachmentUids?: InputMaybe<Array<Scalars["ID"]>>;
  mentionsUids?: InputMaybe<Array<Scalars["String"]>>;
  text?: InputMaybe<Scalars["String"]>;
  textMimeType?: InputMaybe<TextMimeType>;
  videoUid?: InputMaybe<Scalars["ID"]>;
};

export type CreateAssignmentLessonPostInput = {
  lessonUid: Scalars["ID"];
  messageContent: MessageContentInput;
  orderInLesson: Scalars["Int"];
  title: Scalars["String"];
};

export type FileInput = {
  filename?: InputMaybe<Scalars["String"]>;
  handle?: InputMaybe<Scalars["ID"]>;
  mimetype?: InputMaybe<Scalars["String"]>;
  originalPath?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
  source?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type BulkGiftCardsInput = {
  amountCentsPerGiftCard: Scalars["Int"];
  numGiftCards: Scalars["Int"];
  paidForBy: Scalars["String"];
  purchasedForReason: Scalars["String"];
};

export type ChildInput = {
  age?: InputMaybe<Scalars["Int"]>;
  attributes?: InputMaybe<Array<LearnerAttribute>>;
  availableTimes?: InputMaybe<Array<EntityAvailabilityInput>>;
  avatar?: InputMaybe<Scalars["String"]>;
  birthMonth?: InputMaybe<Scalars["Int"]>;
  birthYear?: InputMaybe<Scalars["Int"]>;
  date_of_birth?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  interestTopics?: InputMaybe<Array<Scalars["ID"]>>;
  intro?: InputMaybe<Scalars["String"]>;
  isLoginEnabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  optedOutOfPersonalizedRecommendationsAt?: InputMaybe<Scalars["DateTime"]>;
  password?: InputMaybe<Scalars["String"]>;
  pronoun?: InputMaybe<Scalars["String"]>;
  raceEthnicity?: InputMaybe<Array<RaceEthnicityType>>;
  uid?: InputMaybe<Scalars["ID"]>;
  username?: InputMaybe<Scalars["String"]>;
};

export type LearnerAttribute = {
  key?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

export type EntityAvailabilityInput = {
  day: Scalars["Int"];
  endHour: Scalars["Int"];
  endMinute: Scalars["Int"];
  startHour: Scalars["Int"];
  startMinute: Scalars["Int"];
  uid?: InputMaybe<Scalars["ID"]>;
};

export type ClassPostInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  messageContent: MessageContentInput;
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  sectionUid: Scalars["ID"];
  title?: InputMaybe<Scalars["String"]>;
};

export type ClassPostCommentInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  classPostUid: Scalars["ID"];
  learnerUid?: InputMaybe<Scalars["ID"]>;
  messageContent: MessageContentInput;
};

export type ClassPostCommentReplyInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  classPostUid: Scalars["ID"];
  messageContentInput: MessageContentInput;
  threadParentCommentUid: Scalars["ID"];
};

export type ClassPostForLessonInput = {
  backgroundColor?: InputMaybe<Scalars["String"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContent: MessageContentInput;
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  sectionUid: Scalars["ID"];
  title?: InputMaybe<Scalars["String"]>;
};

export type DonationInput = {
  amountCents: Scalars["Int"];
  orderSlugId: Scalars["ID"];
};

export type ExternalResourceInput = {
  name?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type GiftCardInputWithDeliveryDate = {
  amountCents: Scalars["Int"];
  deliverAfter?: InputMaybe<Scalars["DateTime"]>;
  deliveryTimezone: Scalars["String"];
  note?: InputMaybe<Scalars["String"]>;
  orderSlugId: Scalars["ID"];
  recipientEmail: Scalars["String"];
  recipientName: Scalars["String"];
  senderEmail: Scalars["String"];
  senderName: Scalars["String"];
};

export type CreateLessonPostInput = {
  lessonUid: Scalars["ID"];
  messageContent: MessageContentInput;
  orderInLesson: Scalars["Int"];
  title: Scalars["String"];
};

export type PrivateClassMessageInput = {
  learnerUid: Scalars["ID"];
  messageContentInput: MessageContentInput;
  sectionUid: Scalars["ID"];
  threadUid?: InputMaybe<Scalars["ID"]>;
};

export type UserQuizTypeAndValueInput = {
  type: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
  /**
   * example:
   * [
   *   [{type: "gradeLevel", value: 9}, {type: "subject", value: "math"}],
   *   [{type: "gradeLevel", value: 9}, {type: "subject", value: "english"}]
   * ]
   */
  valueLists?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<UserQuizTypeAndValue>>>>
  >;
};

export type UserQuizTypeAndValue = {
  type: Scalars["String"];
  value: Scalars["String"];
};

export type UnavailableBlockInput = {
  endTime: Scalars["DateTime"];
  startTime: Scalars["DateTime"];
};

export type TeacherTagInput = {
  uid: Scalars["ID"];
};

export type CreateAccountInput = {
  learnerAge?: InputMaybe<Scalars["Int"]>;
  learnerEmail?: InputMaybe<Scalars["String"]>;
  learnerName?: InputMaybe<Scalars["String"]>;
  learnerPassword?: InputMaybe<Scalars["String"]>;
  parentEmail?: InputMaybe<Scalars["String"]>;
  parentName?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
};

export type BulkCreateAccountOptions = {
  defaultTimeZone?: InputMaybe<Scalars["String"]>;
  learnerUsesParentMode?: InputMaybe<Scalars["Boolean"]>;
  persistChanges?: InputMaybe<Scalars["Boolean"]>;
  sendCoppaEmails?: InputMaybe<Scalars["Boolean"]>;
  unsubscribe?: InputMaybe<Scalars["Boolean"]>;
};

export type OsRefInput = {
  a8?: InputMaybe<Scalars["String"]>;
  anonymousId?: InputMaybe<Scalars["String"]>;
  attributionVersionNumber?: InputMaybe<Scalars["Int"]>;
  cookieAddressBarTrackingId?: InputMaybe<Scalars["String"]>;
  cookieAddressBarUserSlugId?: InputMaybe<Scalars["String"]>;
  couponCode?: InputMaybe<Scalars["String"]>;
  currentUrl?: InputMaybe<Scalars["String"]>;
  landingPage?: InputMaybe<Scalars["String"]>;
  noResetReason?: InputMaybe<Scalars["String"]>;
  originalReferrer?: InputMaybe<Scalars["String"]>;
  otherAddressBarTrackingId?: InputMaybe<Scalars["String"]>;
  otherAddressBarUserSlugId?: InputMaybe<Scalars["String"]>;
  pageViewNumberOfSession?: InputMaybe<Scalars["Int"]>;
  previousUrl?: InputMaybe<Scalars["String"]>;
  resetReason?: InputMaybe<Scalars["String"]>;
  resetTimestamp?: InputMaybe<Scalars["String"]>;
  ruid?: InputMaybe<Scalars["String"]>;
  trackingSessionUid?: InputMaybe<Scalars["String"]>;
  usid?: InputMaybe<Scalars["String"]>;
  utm_campaign?: InputMaybe<Scalars["String"]>;
  utm_content?: InputMaybe<Scalars["String"]>;
  utm_medium?: InputMaybe<Scalars["String"]>;
  utm_source?: InputMaybe<Scalars["String"]>;
  utm_term?: InputMaybe<Scalars["String"]>;
};

export type TimetableInput = {
  fri: TimetableTimesInput;
  mon: TimetableTimesInput;
  sat: TimetableTimesInput;
  sun: TimetableTimesInput;
  thu: TimetableTimesInput;
  tue: TimetableTimesInput;
  wed: TimetableTimesInput;
};

export type TimetableTimesInput = {
  f12_4: Scalars["Boolean"];
  f4_8: Scalars["Boolean"];
  f8_12: Scalars["Boolean"];
};

export type CouponActivityConstraintsInput = {
  activitiesUids: Array<Scalars["ID"]>;
};

export type CouponCreateInput = {
  classFormatsAllowed?: InputMaybe<Array<InputMaybe<CouponClassFormats>>>;
  couponType: CouponType;
  description?: InputMaybe<Scalars["String"]>;
  expiresAt: Scalars["DateTime"];
  isBuyerFirstPurchaseOfClassFormatOnly?: InputMaybe<Scalars["Boolean"]>;
  isFirstPurchaseOnly: Scalars["Boolean"];
  maxAmountCentsPerUse?: InputMaybe<Scalars["Int"]>;
  maxUses?: InputMaybe<Scalars["Int"]>;
  maxUsesPerUser?: InputMaybe<Scalars["Int"]>;
  name: Scalars["String"];
  percentOff?: InputMaybe<Scalars["Int"]>;
  startsAt: Scalars["DateTime"];
  subjectsAllowed?: InputMaybe<Array<Scalars["String"]>>;
};

export type CouponCodeCreateInput = {
  code: Scalars["String"];
};

export type CreateCreditPurchaseOrderInput = {
  amountCents: Scalars["Int"];
  amountGrantedCents: Scalars["Int"];
};

export type EnrollmentMeetingPurchaseOrderInput = {
  activityUid: Scalars["ID"];
  attribution?: InputMaybe<Attribution>;
  charterParentInput?: InputMaybe<EnrollmentCharterParentInput>;
  currency_code?: InputMaybe<CurrencyCode>;
  endTime?: InputMaybe<Scalars["DateTime"]>;
  enrollmentInputs: Array<EnrollmentInput>;
  enrollmentUid?: InputMaybe<Scalars["ID"]>;
  exchange_rate?: InputMaybe<Scalars["Float"]>;
  ignoresTeacherAvailability?: InputMaybe<Scalars["Boolean"]>;
  impressionId?: InputMaybe<Scalars["String"]>;
  meetingRequestUids?: InputMaybe<Array<Scalars["String"]>>;
  message?: InputMaybe<Scalars["String"]>;
  orgTeacherUid?: InputMaybe<Scalars["String"]>;
  prepayDurationWeeks?: InputMaybe<Scalars["Int"]>;
  prepayMeetings?: InputMaybe<Array<FutureMeetingInputInfo>>;
  startTime?: InputMaybe<Scalars["DateTime"]>;
};

export type EnrollmentInput = {
  amountInLocalCurrency?: InputMaybe<Scalars["Int"]>;
  isGift?: InputMaybe<Scalars["Boolean"]>;
  learner?: InputMaybe<EnrollmentLearnerInput>;
  price_cents: Scalars["Int"];
  pricing_offer?: InputMaybe<Scalars["String"]>;
};

export type FutureMeetingInputInfo = {
  end_time: Scalars["DateTime"];
  start_time: Scalars["DateTime"];
};

export type OrderInput = {
  activityUid: Scalars["ID"];
  amount_in_local_currency?: InputMaybe<Scalars["Int"]>;
  attribution?: InputMaybe<Attribution>;
  charterParentInput?: InputMaybe<EnrollmentCharterParentInput>;
  charterSchoolUid?: InputMaybe<Scalars["ID"]>;
  currency_code?: InputMaybe<CurrencyCode>;
  enrollmentInputs: Array<EnrollmentInput>;
  exchange_rate?: InputMaybe<Scalars["Float"]>;
  impressionId?: InputMaybe<Scalars["String"]>;
  isWeeklyPayment?: InputMaybe<Scalars["Boolean"]>;
  message?: InputMaybe<Scalars["String"]>;
  ongoingEndTime?: InputMaybe<Scalars["DateTime"]>;
  ongoingStartTime?: InputMaybe<Scalars["DateTime"]>;
  organizationUid?: InputMaybe<Scalars["ID"]>;
  purchaseOrderCoverage?: InputMaybe<Scalars["DateTime"]>;
  schoolClosure?: InputMaybe<SchoolClosureInput>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
  selfPacedStartTime?: InputMaybe<Scalars["DateTime"]>;
};

export type SchoolClosureInput = {
  affected?: InputMaybe<Scalars["Boolean"]>;
  city: Scalars["String"];
  covid: Scalars["Boolean"];
  household_size: Scalars["Int"];
  income: Scalars["String"];
  lunch: Scalars["Boolean"];
  name: Scalars["String"];
  noschool: Scalars["Boolean"];
  school_closed?: InputMaybe<Scalars["Boolean"]>;
  state: Scalars["String"];
};

export type CreateLearnerLearningStatementsInput = {
  learnerUid: Scalars["ID"];
  objectType: LearningStatementsObjectType;
  objectUid: Scalars["String"];
  verb: LearningStatementsVerb;
};

export enum LearningStatementsObjectType {
  AssignmentClassPost = "AssignmentClassPost",
  LearningStatement = "LearningStatement",
  Lesson = "Lesson",
}

export type CreateLearnerPlanScheduleInput = {
  entityAvailabilities?: InputMaybe<Array<EntityAvailabilityInput>>;
  learnerPlanUid: Scalars["ID"];
};

export type MeetingDetails = {
  end_time: Scalars["DateTime"];
  lesson_uid?: InputMaybe<Scalars["ID"]>;
  start_time: Scalars["DateTime"];
};

export type CreateBundleInput = {
  bundleElements: Array<NewBundleElementInput>;
  description?: InputMaybe<Scalars["String"]>;
  isOrdered: Scalars["Boolean"];
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  thumbnail?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type NewBundleElementInput = {
  activityUid: Scalars["ID"];
  orderInBundle: Scalars["Int"];
};

export type CreateBundleElementInput = {
  activityUid: Scalars["ID"];
  bundleUid: Scalars["ID"];
  orderInBundle: Scalars["Int"];
};

export type CreateOrganizationInput = {
  address?: InputMaybe<Scalars["String"]>;
  invoiceEmail?: InputMaybe<Scalars["String"]>;
  logoUrl?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  techSupportEmail?: InputMaybe<Scalars["String"]>;
  totalCapCents?: InputMaybe<Scalars["Int"]>;
  trustSafetyEmail?: InputMaybe<Scalars["String"]>;
  type: OrganizationType;
  urlName?: InputMaybe<Scalars["String"]>;
};

export type FeedbackInput = {
  activity_star_rating: Scalars["Int"];
  attachments?: InputMaybe<Array<Scalars["ID"]>>;
  attachmentUids?: InputMaybe<Array<Scalars["ID"]>>;
  attribution?: InputMaybe<Attribution>;
  leaderFeedback?: InputMaybe<Scalars["String"]>;
  outschool_rating?: InputMaybe<Scalars["Int"]>;
  outschoolFeedback?: InputMaybe<Scalars["String"]>;
  publicReview?: InputMaybe<Scalars["String"]>;
  section_uid: Scalars["ID"];
};

export type LearnerSectionReviewInput = {
  attribution?: InputMaybe<Attribution>;
  enrollmentUid: Scalars["ID"];
  name: ReviewName;
  response: LearnerReviewResponseInput;
};

export enum ReviewName {
  InterestedInTakingMoreClassesAboutTopic = "interestedInTakingMoreClassesAboutTopic",
  SoughtOutWaysToLearnMoreAboutTopic = "soughtOutWaysToLearnMoreAboutTopic",
}

export type LearnerReviewResponseInput = {
  vote?: InputMaybe<Scalars["String"]>;
};

export type CreatePaymentScheduleInput = {
  initialPaymentAmountCents?: InputMaybe<Scalars["Int"]>;
  initialPaymentRelativePeriod?: InputMaybe<RelativePeriodOptions>;
  lineItemUid: Scalars["String"];
  orderSlugId?: InputMaybe<Scalars["String"]>;
  recurringPaymentAmountCents: Scalars["Int"];
  scheduledPaymentLineItemType: ScheduledPaymentLineItemType;
  scheduleEndTime?: InputMaybe<Scalars["DateTime"]>;
  scheduleStartTime: Scalars["DateTime"];
  scheduleType: PaymentScheduleTypes;
};

export enum RelativePeriodOptions {
  Current = "Current",
  Next = "Next",
  Previous = "Previous",
}

export enum ScheduledPaymentLineItemType {
  ClubSubscriptions = "ClubSubscriptions",
  OneOnOneSchedules = "OneOnOneSchedules",
  WeeklyClassPayments = "WeeklyClassPayments",
}

export type CreatePricingOfferInput = {
  buyerOrgName: Scalars["String"];
  discount?: InputMaybe<Scalars["Float"]>;
  eligibleEmailDomains: Array<Scalars["String"]>;
  endBy: Scalars["DateTime"];
  excludedEmailAddresses: Array<Scalars["String"]>;
  includedEmailAddresses: Array<Scalars["String"]>;
  isFinancialAid: Scalars["Boolean"];
  organizationUid?: InputMaybe<Scalars["ID"]>;
  requirePaymentMethod: Scalars["Boolean"];
  startAfter: Scalars["DateTime"];
  totalCapCents: Scalars["Int"];
  userLimitCents: Scalars["Int"];
};

export type CollegeDegreeCredentialInput = {
  degreeAdditionalInfo?: InputMaybe<Scalars["String"]>;
  degreeInstitution: Scalars["String"];
  degreeIssueDate?: InputMaybe<Scalars["DateTime"]>;
  degreeSubject: DegreeSubject;
  degreeType: DegreeType;
};

export type CredentialFileInput = {
  filename?: InputMaybe<Scalars["String"]>;
  handle?: InputMaybe<Scalars["ID"]>;
  mimetype?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Int"]>;
  source?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type OtherCredentialInput = {
  otherDetails: Scalars["String"];
  otherType: Scalars["String"];
};

export type TeacherCertificateCredentialInput = {
  certificateDiscipline: TeacherCertificateDiscipline;
  certificateDoesNotExpire?: InputMaybe<Scalars["Boolean"]>;
  certificateExpireDate?: InputMaybe<Scalars["DateTime"]>;
  certificateIssueDate?: InputMaybe<Scalars["DateTime"]>;
  certificateNumber: Scalars["String"];
  certificateSecondaryDiscipline?: InputMaybe<TeacherCertificateDiscipline>;
  certificateState: TeacherCertificateState;
};

export type CreateWaitlistSeatInput = {
  learner: WaitlistLearner;
  parentUid: Scalars["ID"];
  sectionUid: Scalars["ID"];
  waitlistSource?: InputMaybe<WaitlistSource>;
};

export type WaitlistLearner = {
  age: Scalars["Int"];
  date_of_birth?: InputMaybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  uid?: InputMaybe<Scalars["String"]>;
};

export enum ReportLength {
  /** Two or three paragraphs "long" */
  FewParagraphs = "FewParagraphs",
  /** Only one paragraph "short" */
  OneParagraph = "OneParagraph",
}

export type PostLoginOption = {
  defaultLearnerAge?: InputMaybe<Scalars["Int"]>;
  isOnboardingSignup?: InputMaybe<Scalars["Boolean"]>;
  shouldCreateDefaultLearner?: InputMaybe<Scalars["Boolean"]>;
};

/** Which client is attempting to log in */
export enum LoginClient {
  AndroidLearnerApp = "androidLearnerApp",
  IOsLearnerApp = "iOSLearnerApp",
  Web = "Web",
}

export type CreateAccountWithProviderInput = {
  attribution?: InputMaybe<Attribution>;
  browserTimeZone?: InputMaybe<Scalars["String"]>;
  defaultLearnerAge?: InputMaybe<Scalars["Int"]>;
  isGiftCardSignup?: InputMaybe<Scalars["Boolean"]>;
  isLeader?: InputMaybe<Scalars["Boolean"]>;
  isOnboardingSignup?: InputMaybe<Scalars["Boolean"]>;
  locale?: InputMaybe<UserLocale>;
  shouldCreateDefaultLearner?: InputMaybe<Scalars["Boolean"]>;
  subscribe?: InputMaybe<Scalars["Boolean"]>;
};

export enum CreateOrLoginError {
  AccountAlreadyExists = "AccountAlreadyExists",
  AccountAlreadyLinked = "AccountAlreadyLinked",
  AccountCreationNotAllowed = "AccountCreationNotAllowed",
  AccountLocked = "AccountLocked",
  AccountNotFound = "AccountNotFound",
  AccountSuspended = "AccountSuspended",
  IncorrectApp = "IncorrectApp",
  InvalidEmail = "InvalidEmail",
  InvalidName = "InvalidName",
  InvalidPermissions = "InvalidPermissions",
  LearnerAccountLinkFailed = "LearnerAccountLinkFailed",
  SocialLoginNotAllowed = "SocialLoginNotAllowed",
  UnknownError = "UnknownError",
}

export enum ExternalActivitySource {
  Mux = "mux",
}

export type PauseOngoingEnrollmentInput = {
  dates: Array<DateRange>;
  enrollmentUid: Scalars["ID"];
};

export type DateRange = {
  endDate: Scalars["DateTime"];
  startDate: Scalars["DateTime"];
};

export enum TokenRefreshSource {
  Apollo = "Apollo",
  Client = "Client",
  LearnerApp = "LearnerApp",
}

export enum RefundType {
  AddOutschoolCredit = "AddOutschoolCredit",
  StandardRefund = "StandardRefund",
}

export type LearnerProfileInput = {
  aboutMe?: InputMaybe<Scalars["String"]>;
  avatar?: InputMaybe<Scalars["String"]>;
};

export type LearnerProfileSettingsInput = {
  isProfileAgeHidden?: InputMaybe<Scalars["Boolean"]>;
  isProfileLocationHidden?: InputMaybe<Scalars["Boolean"]>;
};

export type ChatConversationMessageInput = {
  content: Scalars["String"];
  role: Role;
};

export enum Role {
  Assistant = "assistant",
  System = "system",
  User = "user",
}

export type MessageInput = {
  activityUid?: InputMaybe<Scalars["ID"]>;
  bulkGroupUid?: InputMaybe<Scalars["ID"]>;
  content?: InputMaybe<Scalars["String"]>;
  details: MessageInputDetails;
  messageContentInput?: InputMaybe<MessageContentInput>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
};

export type MessageInputDetails = {
  action: Scalars["String"];
  activityTitle?: InputMaybe<Scalars["String"]>;
  activityUrl?: InputMaybe<Scalars["String"]>;
  classScheduleRequestUid?: InputMaybe<Scalars["ID"]>;
  leaderName?: InputMaybe<Scalars["ID"]>;
  leaderUid?: InputMaybe<Scalars["ID"]>;
  parentName?: InputMaybe<Scalars["String"]>;
  parentUid?: InputMaybe<Scalars["ID"]>;
  section?: InputMaybe<Scalars["String"]>;
  sectionUrl?: InputMaybe<Scalars["String"]>;
  studentName?: InputMaybe<Scalars["String"]>;
  subject?: InputMaybe<Scalars["String"]>;
  teacherAdminUrl?: InputMaybe<Scalars["String"]>;
};

export type ProgressReportDetailsInput = {
  didChangeGeneratedReport: Scalars["Boolean"];
  didGenerateReport: Scalars["Boolean"];
  originalNotes: Scalars["String"];
  reportLength: ReportLength;
};

export type BulkPurchaseRecurringMeetingsInfo = {
  durationWeeks?: InputMaybe<Scalars["Int"]>;
  enrollmentUid: Scalars["ID"];
};

export type PurchaseRecurringMeetingsInfo = {
  /**
   * The total number of weeks this enrollment time should repeat. If omitted, the enrollment
   * time will be considered continuous and repeated until either the parent or teacher
   * cancel the enrollment time.
   */
  durationWeeks?: InputMaybe<Scalars["Int"]>;
};

export type StopPaymentScheduleInput = {
  scheduledPaymentLineItemType: ScheduledPaymentLineItemType;
  scheduleUid: Scalars["String"];
  stoppedReason: Scalars["String"];
};

export type UserSavedActivity = {
  activityUid: Scalars["ID"];
  savedAt: Scalars["DateTime"];
};

export enum SurpriseMeVariants {
  Random = "Random",
  Recommendations = "Recommendations",
}

export type TrackAnalyticsInitializedProperties = {
  analyticsLoadDuration?: InputMaybe<Scalars["Int"]>;
  analyticsMode?: InputMaybe<Scalars["String"]>;
  analyticsModeSource?: InputMaybe<Scalars["String"]>;
  analyticsModeSourceOverride?: InputMaybe<Scalars["String"]>;
  analyticsStatus?: InputMaybe<Scalars["String"]>;
  app?: InputMaybe<Scalars["String"]>;
  botCheck?: InputMaybe<Scalars["Boolean"]>;
  cloudflareBotScore?: InputMaybe<Scalars["Int"]>;
  cloudflareBotVerified?: InputMaybe<Scalars["Boolean"]>;
  cloudflareThreatScore?: InputMaybe<Scalars["Int"]>;
  deviceUid?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Int"]>;
  initializationDuration?: InputMaybe<Scalars["Int"]>;
  isBot?: InputMaybe<Scalars["Boolean"]>;
  isMobile?: InputMaybe<Scalars["Boolean"]>;
  modeResolutionDuration?: InputMaybe<Scalars["Int"]>;
  pageCategory?: InputMaybe<Scalars["String"]>;
  pageImpressionId?: InputMaybe<Scalars["String"]>;
  pageLoadId?: InputMaybe<Scalars["String"]>;
  pageName?: InputMaybe<Scalars["String"]>;
  pagePath?: InputMaybe<Scalars["String"]>;
  pluginLoadDuration?: InputMaybe<Scalars["Int"]>;
  width?: InputMaybe<Scalars["Int"]>;
};

export type TrackCheckoutSessionCompletedProperties = {
  checkoutSessionId?: InputMaybe<Scalars["String"]>;
  osRef?: InputMaybe<OsRefInput>;
};

export type TrackClassPageImpressionInput = {
  activityUid: Scalars["String"];
  sectionUid?: InputMaybe<Scalars["String"]>;
};

export type TrackNewBuyerAcquiredProperties = {
  amount: Scalars["Int"];
  fbp?: InputMaybe<Scalars["String"]>;
  osRef?: InputMaybe<OsRefInput>;
};

export type TrackOrderCompletedProperties = {
  cartUid?: InputMaybe<Scalars["String"]>;
  enrollmentConfirmedAt: Scalars["DateTime"];
  fbp?: InputMaybe<Scalars["String"]>;
  isBulkCheckout?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  orderId: Scalars["String"];
  osRef?: InputMaybe<OsRefInput>;
  price: Scalars["Int"];
  productId: Scalars["String"];
  quantity: Scalars["Int"];
  total: Scalars["Int"];
};

export type TrackPageLoadProperties = {
  anonymousId?: InputMaybe<Scalars["String"]>;
  app?: InputMaybe<Scalars["String"]>;
  cloudflare_bot_score?: InputMaybe<Scalars["Int"]>;
  cloudflare_bot_verified?: InputMaybe<Scalars["Boolean"]>;
  cloudflare_threat_score?: InputMaybe<Scalars["Int"]>;
  is_bot?: InputMaybe<Scalars["Boolean"]>;
  isMobile?: InputMaybe<Scalars["Boolean"]>;
  loggedInUserExperimentUid?: InputMaybe<Scalars["String"]>;
  loggedOutUserExperimentUid?: InputMaybe<Scalars["String"]>;
  pageLoadId?: InputMaybe<Scalars["String"]>;
  pageName?: InputMaybe<Scalars["String"]>;
  pagePath?: InputMaybe<Scalars["String"]>;
};

export type TrackSubscriptionInterestProperties = {
  email: Scalars["String"];
  osRef?: InputMaybe<OsRefInput>;
  package: Scalars["String"];
};

export type TrackUserAttributionSurveyResponseInput = {
  response: Scalars["String"];
};

export type TrackUserCreatedProperties = {
  fbp?: InputMaybe<Scalars["String"]>;
  osRef?: InputMaybe<OsRefInput>;
};

export type UnpauseOngoingEnrollmentsPausedForPaymentFailureInput = {
  enrollmentUids: Array<Scalars["ID"]>;
  pauseEndDate?: InputMaybe<Scalars["Date"]>;
};

export type ActivityDetailsInput = {
  asyncTime?: InputMaybe<Scalars["String"]>;
  materials?: InputMaybe<Scalars["String"]>;
  photo?: InputMaybe<Scalars["String"]>;
  photoSquare?: InputMaybe<Scalars["String"]>;
  unpublishReason?: InputMaybe<Scalars["String"]>;
};

export type ActivityInput = {
  age_max?: InputMaybe<Scalars["Int"]>;
  age_min?: InputMaybe<Scalars["Int"]>;
  allows_late_enrollment?: InputMaybe<Scalars["Boolean"]>;
  allows_late_enrollments_for_fixed_length_classes?: InputMaybe<
    Scalars["Boolean"]
  >;
  allows_recurring_payment?: InputMaybe<Scalars["Boolean"]>;
  assessment?: InputMaybe<Scalars["String"]>;
  attributes?: InputMaybe<Array<EntityAttributeInput>>;
  auto_scheduling_days_notice_max?: InputMaybe<Scalars["Int"]>;
  auto_scheduling_days_notice_min?: InputMaybe<Scalars["Int"]>;
  class_experience?: InputMaybe<Scalars["String"]>;
  classFormat?: InputMaybe<EducatorSelectedFormat>;
  club_banner_image_uid?: InputMaybe<Scalars["ID"]>;
  club_welcome_video_uid?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  disclaimer?: InputMaybe<Scalars["String"]>;
  duration_minutes?: InputMaybe<Scalars["Int"]>;
  duration_weeks?: InputMaybe<Scalars["Int"]>;
  english_proficiency_level?: InputMaybe<EnglishProficiencyLevel>;
  grade_level_max?: InputMaybe<Scalars["String"]>;
  grade_level_min?: InputMaybe<Scalars["String"]>;
  grading_policy?: InputMaybe<Scalars["String"]>;
  has_building_content?: InputMaybe<Scalars["Boolean"]>;
  homework?: InputMaybe<Scalars["String"]>;
  is_auto_scheduling_enabled?: InputMaybe<Scalars["Boolean"]>;
  is_ongoing_weekly?: InputMaybe<Scalars["Boolean"]>;
  is_online?: InputMaybe<Scalars["Boolean"]>;
  is_waitlist_allowed?: InputMaybe<Scalars["Boolean"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  isSelfPaced?: InputMaybe<Scalars["Boolean"]>;
  isSelfPacedEnrollmentEnabled?: InputMaybe<Scalars["Boolean"]>;
  language_of_instruction?: InputMaybe<Scalars["Iso639"]>;
  learning_goals?: InputMaybe<Scalars["String"]>;
  parental_guidance?: InputMaybe<Scalars["String"]>;
  photo?: InputMaybe<Scalars["String"]>;
  prerequisites?: InputMaybe<Scalars["String"]>;
  price_cents?: InputMaybe<Scalars["Int"]>;
  pricingAdjustments?: InputMaybe<PricingAdjustmentInput>;
  proficiency_level?: InputMaybe<ProficiencyLevelInput>;
  refund_policy_type?: InputMaybe<RefundPolicyType>;
  sample_club_post?: InputMaybe<WelcomeMessageInput>;
  sampleClubPosts?: InputMaybe<Array<SampleClubPostInput>>;
  selfPacedMigration?: InputMaybe<SelfPacedMigrationStatusInput>;
  size_max?: InputMaybe<Scalars["Int"]>;
  size_min?: InputMaybe<Scalars["Int"]>;
  sources?: InputMaybe<Scalars["String"]>;
  strictAgeRange?: InputMaybe<AgeRangeInput>;
  subject?: InputMaybe<Scalars["String"]>;
  suggestedWeeklyMeetings?: InputMaybe<Scalars["Int"]>;
  summary?: InputMaybe<Scalars["String"]>;
  summary_for_learners?: InputMaybe<Scalars["String"]>;
  tags?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  upsell_activity_uid?: InputMaybe<Scalars["ID"]>;
  video_for_learners_uid?: InputMaybe<Scalars["ID"]>;
  video_uid?: InputMaybe<Scalars["ID"]>;
  weekly_meetings?: InputMaybe<Scalars["Int"]>;
  welcome_message?: InputMaybe<WelcomeMessageInput>;
  why_me?: InputMaybe<Scalars["String"]>;
};

export type EntityAttributeInput = {
  key: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
};

export type PricingAdjustmentInput = {
  weeklySurchargePercentage?: InputMaybe<Scalars["Float"]>;
};

export type ProficiencyLevelInput = {
  level?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  text?: InputMaybe<Scalars["String"]>;
};

export type WelcomeMessageInput = {
  attachments?: InputMaybe<Array<Scalars["ID"]>>;
  text?: InputMaybe<Scalars["String"]>;
  video?: InputMaybe<Scalars["ID"]>;
};

export type SampleClubPostInput = {
  attachments?: InputMaybe<Array<Scalars["ID"]>>;
  comments?: InputMaybe<Array<SampleClubPostCommentInput>>;
  text?: InputMaybe<Scalars["String"]>;
  video?: InputMaybe<Scalars["ID"]>;
};

export type SampleClubPostCommentInput = {
  text: Scalars["String"];
};

export type SelfPacedMigrationStatusInput = {
  gitCommitSha: Scalars["String"];
  hasConsented: Scalars["Boolean"];
};

export type AgeRangeInput = {
  max: Scalars["Int"];
  min: Scalars["Int"];
};

export type UpdateAssignmentClassPostInput = {
  dueDate?: InputMaybe<Scalars["DateTime"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContentInput?: InputMaybe<MessageContentInput>;
  publishAt?: InputMaybe<Scalars["DateTime"]>;
  sectionUid?: InputMaybe<Scalars["ID"]>;
  submissionRequirement?: InputMaybe<SubmissionRequirementType>;
  title?: InputMaybe<Scalars["String"]>;
};

export type UpdateAssignmentLessonPostInput = {
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContent?: InputMaybe<MessageContentInput>;
  orderInLesson?: InputMaybe<Scalars["Int"]>;
  submissionRequirement?: InputMaybe<SubmissionRequirementType>;
  title?: InputMaybe<Scalars["String"]>;
};

export type UpdateBundleInput = {
  description?: InputMaybe<Scalars["String"]>;
  discount?: InputMaybe<Scalars["Float"]>;
  isOrdered?: InputMaybe<Scalars["Boolean"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  thumbnail?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  /**
   * Some context for expected fields inside of updatedBundleElements:
   * if a BundleElement existed before this update mutation expects the object to have a uid, a activityUid and orderInBundle
   * if a BundleElement is new: the mutation expects the object to have a activityUid and orderInBundle
   */
  updatedBundleElements: Array<UpdateBundleElementInput>;
};

export type UpdateBundleElementInput = {
  activityUid: Scalars["ID"];
  orderInBundle: Scalars["Int"];
  uid?: InputMaybe<Scalars["ID"]>;
};

export type CouponUpdateInput = {
  deletedAt?: InputMaybe<Scalars["DateTime"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UserDetailsInput = {
  about?: InputMaybe<Scalars["String"]>;
  browserTimeZone?: InputMaybe<Scalars["String"]>;
  countryOfResidence?: InputMaybe<Scalars["String"]>;
  headline?: InputMaybe<Scalars["String"]>;
  howFoundOutschool?: InputMaybe<Scalars["String"]>;
  howFoundOutschoolOther?: InputMaybe<Scalars["String"]>;
  introduction?: InputMaybe<Scalars["String"]>;
  socialOptOut?: InputMaybe<Scalars["Boolean"]>;
  timeRangeAfterSchool?: InputMaybe<Scalars["Boolean"]>;
  timeRangeSchool?: InputMaybe<Scalars["Boolean"]>;
  timeRangeWeekend?: InputMaybe<Scalars["Boolean"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type LocationInput = {
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  geo?: InputMaybe<Scalars["String"]>;
  lat?: InputMaybe<Scalars["Float"]>;
  lng?: InputMaybe<Scalars["Float"]>;
  placeDescription?: InputMaybe<Scalars["String"]>;
  placeName?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
};

export type TeacherAttributesInput = {
  autoSchedulingBufferMinutes?: InputMaybe<Scalars["Int"]>;
  isAutoSchedulingEnabled?: InputMaybe<Scalars["Boolean"]>;
  mainToolbarPinnedTabs?: InputMaybe<Array<MainToolbarPinnedTabs>>;
  showCalendar?: InputMaybe<Scalars["Boolean"]>;
};

export type AdminEnrollmentPaymentRefundInput = {
  action: AdminEnrollmentPaymentRefundActionType;
  adminNoteType?: InputMaybe<AdminEnrollmentPaymentRefundNoteType>;
  notes?: InputMaybe<Scalars["String"]>;
};

export enum AdminEnrollmentPaymentRefundActionType {
  ReducesTeacherPayoutFalse = "REDUCES_TEACHER_PAYOUT_FALSE",
  ReducesTeacherPayoutTrue = "REDUCES_TEACHER_PAYOUT_TRUE",
}

export enum AdminEnrollmentPaymentRefundNoteType {
  RefundUpdateAuditLog = "REFUND_UPDATE_AUDIT_LOG",
}

export type EnrollmentPaymentRefundInput = {
  reason: Scalars["String"];
  reduces_teacher_payout: Scalars["Boolean"];
  refunded_at?: InputMaybe<Scalars["DateTime"]>;
};

/** uid - entity availability schedule uid */
export type UpdateEntityAvailabilityScheduleInput = {
  entityAvailabilities: Array<EntityAvailabilityInput>;
  uid: Scalars["ID"];
};

export type GiftCardUpdateInput = {
  deliverAfter?: InputMaybe<Scalars["DateTime"]>;
  note?: InputMaybe<Scalars["String"]>;
  recipientEmail?: InputMaybe<Scalars["String"]>;
  recipientName?: InputMaybe<Scalars["String"]>;
  senderEmail?: InputMaybe<Scalars["String"]>;
  senderName?: InputMaybe<Scalars["String"]>;
};

export type LessonInput = {
  description?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  unitUid?: InputMaybe<Scalars["ID"]>;
};

export type UpdateLessonPostInput = {
  isPostedForEnrollments?: InputMaybe<Scalars["Boolean"]>;
  isPostedForSubscriptions?: InputMaybe<Scalars["Boolean"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  messageContent?: InputMaybe<MessageContentInput>;
  orderInLesson?: InputMaybe<Scalars["Int"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type MeetingAiSummaryPatchInput = {
  deleted_at?: InputMaybe<Scalars["DateTime"]>;
  edited_summary_text?: InputMaybe<Scalars["String"]>;
  is_skipped?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateMeetingInput = {
  endTime?: InputMaybe<Scalars["DateTime"]>;
  lessonUid?: InputMaybe<Scalars["ID"]>;
  meetingUid: Scalars["ID"];
  startTime?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdateMeetingLessonInput = {
  lessonUid?: InputMaybe<Scalars["ID"]>;
  meetingUid: Scalars["ID"];
};

export type UpdateOrganizationInput = {
  address?: InputMaybe<Scalars["String"]>;
  invoiceEmail?: InputMaybe<Scalars["String"]>;
  logoUrl?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  techSupportEmail?: InputMaybe<Scalars["String"]>;
  totalCapCents?: InputMaybe<Scalars["Int"]>;
  trustSafetyEmail?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<OrganizationType>;
  urlName?: InputMaybe<Scalars["String"]>;
};

export type UpdatePricingOfferInput = {
  buyerOrgName?: InputMaybe<Scalars["String"]>;
  discount?: InputMaybe<Scalars["Float"]>;
  eligibleEmailDomains?: InputMaybe<Array<Scalars["String"]>>;
  endBy?: InputMaybe<Scalars["DateTime"]>;
  excludedEmailAddresses?: InputMaybe<Array<Scalars["String"]>>;
  includedEmailAddresses?: InputMaybe<Array<Scalars["String"]>>;
  isFinancialAid?: InputMaybe<Scalars["Boolean"]>;
  organizationUid?: InputMaybe<Scalars["ID"]>;
  requirePaymentMethod?: InputMaybe<Scalars["Boolean"]>;
  startAfter?: InputMaybe<Scalars["DateTime"]>;
  totalCapCents?: InputMaybe<Scalars["Int"]>;
  userLimitCents?: InputMaybe<Scalars["Int"]>;
};

export type ActivityScheduleSettingsInput = {
  maximumNotice?: InputMaybe<Scalars["String"]>;
  mininumNotice?: InputMaybe<Scalars["String"]>;
  suggestedWeeklyMeetings?: InputMaybe<Scalars["Int"]>;
};

export type LeaderSectionDetailInput = {
  date?: InputMaybe<Scalars["String"]>;
  originalEndTime?: InputMaybe<Scalars["DateTime"]>;
};

export type OnlineClassroomInput = {
  type?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type LeaderSectionInput = {
  end_time?: InputMaybe<Scalars["DateTime"]>;
  isCanceled?: InputMaybe<Scalars["Boolean"]>;
  isDeleted?: InputMaybe<Scalars["Boolean"]>;
  isPublished?: InputMaybe<Scalars["Boolean"]>;
  price_cents?: InputMaybe<Scalars["Int"]>;
  size_max?: InputMaybe<Scalars["Int"]>;
  start_time?: InputMaybe<Scalars["DateTime"]>;
  user_uid?: InputMaybe<Scalars["ID"]>;
};

export type SectionAdminInput = {
  notes?: InputMaybe<Scalars["String"]>;
};

export type UpdateSellerOrgAgreementInput = {
  agreementTemplateUid?: InputMaybe<Scalars["ID"]>;
  contractSignedBy?: InputMaybe<Scalars["String"]>;
  contractSignerTitle?: InputMaybe<Scalars["String"]>;
  legalAddress?: InputMaybe<Scalars["String"]>;
  legalEntityType?: InputMaybe<Scalars["String"]>;
  legalName?: InputMaybe<Scalars["String"]>;
  legalState?: InputMaybe<Scalars["String"]>;
  ownsBackgroundChecks?: InputMaybe<Scalars["Boolean"]>;
};

export type SellerOrgApplicationInput = {
  additionalInfo?: InputMaybe<Scalars["String"]>;
  alreadyApprovedTeacher?: InputMaybe<Scalars["Boolean"]>;
  alreadyApprovedTeacherEmail?: InputMaybe<Scalars["String"]>;
  classesInfo?: InputMaybe<Scalars["String"]>;
  countries?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  firstClassDescription?: InputMaybe<Scalars["String"]>;
  learnersInfo?: InputMaybe<Scalars["String"]>;
  learnerTypes?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  numberOfClassesPerWeek?: InputMaybe<Scalars["String"]>;
  numberOfStudentsOnAverage?: InputMaybe<Scalars["String"]>;
  numberOfTeachers?: InputMaybe<Scalars["String"]>;
  organizationPhone?: InputMaybe<Scalars["String"]>;
  primaryContactName?: InputMaybe<Scalars["String"]>;
  primaryContactTitle?: InputMaybe<Scalars["String"]>;
  publicReviews?: InputMaybe<Scalars["String"]>;
  sourcedFrom?: InputMaybe<Scalars["String"]>;
  teachersInfo?: InputMaybe<Scalars["String"]>;
  timeInBusiness?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  typeDetail?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
  whenFounded?: InputMaybe<Scalars["String"]>;
};

export type TeacherApplicationInput = {
  age_ranges_taught?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  class_subjects?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  class_types?: InputMaybe<Scalars["String"]>;
  experience_types?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  how_did_you_hear_about_us?: InputMaybe<Scalars["String"]>;
  legal_name?: InputMaybe<Scalars["String"]>;
  online_profiles?: InputMaybe<Scalars["String"]>;
  sample_classes?: InputMaybe<Scalars["String"]>;
  teaching_credentials?: InputMaybe<Scalars["String"]>;
  teaching_experience?: InputMaybe<Scalars["String"]>;
  teaching_motivation?: InputMaybe<Scalars["String"]>;
  video_uid?: InputMaybe<Scalars["ID"]>;
  years_of_experience?: InputMaybe<Scalars["String"]>;
};

export type AvailabilityInput = {
  hourlyTimetable: HourlyTimetableInput;
};

export type HourlyTimetableInput = {
  fri: Array<Scalars["Int"]>;
  mon: Array<Scalars["Int"]>;
  sat: Array<Scalars["Int"]>;
  sun: Array<Scalars["Int"]>;
  thu: Array<Scalars["Int"]>;
  tue: Array<Scalars["Int"]>;
  wed: Array<Scalars["Int"]>;
};

export type ScheduleInput = {
  bufferTime?: InputMaybe<Scalars["String"]>;
  meetingsStartTimeIncrementsInMinutes?: InputMaybe<Scalars["Int"]>;
};

export type AvailableTimesInput = {
  endTime: Scalars["DateTime"];
  isRecurring: Scalars["Boolean"];
  isUnavailable: Scalars["Boolean"];
  startTime: Scalars["DateTime"];
};

export type UnitInput = {
  title?: InputMaybe<Scalars["String"]>;
};

export type UserAdminInput = {
  is_crosslister?: InputMaybe<Scalars["Boolean"]>;
  leader_link?: InputMaybe<Scalars["String"]>;
};

export enum ValidateMeetingRequestsForPurchaseError {
  /** Cannot accept reschedule requests via Mutation.settleOrderEnrollmentMeetingPurchase.  Use Mutation.acceptMeetingReschedule */
  CannotAcceptRescheduleRequestsForPurchase = "CannotAcceptRescheduleRequestsForPurchase",
  /** Enrollment meeting purchase record not found */
  EnrollmentMeetingPurchaseRecordNotFound = "EnrollmentMeetingPurchaseRecordNotFound",
  /** One or more meeting requests have been deleted */
  MeetingRequestDeleted = "MeetingRequestDeleted",
  /** Meeting requests do not belong to purchased activity */
  MeetingRequestDoesNotBelongToActivity = "MeetingRequestDoesNotBelongToActivity",
  /** Meeting requests do not belong to purchased section */
  MeetingRequestDoesNotBelongToSection = "MeetingRequestDoesNotBelongToSection",
  /** Meeting requests are not pending */
  MeetingRequestIsNotPending = "MeetingRequestIsNotPending",
  /** No meeting tied to enrollment meeting purchase */
  NoMeetingFoundForEnrollmentMeetingPurchase = "NoMeetingFoundForEnrollmentMeetingPurchase",
  /** Only some meeting requests in batch are recurring */
  OnlySomeMeetingRequestsAreRecurring = "OnlySomeMeetingRequestsAreRecurring",
  /** Not supported needs fixing: See https://outschool.atlassian.net/browse/ESP-2819 */
  RecurringMeetingRequestsNotSupported = "RecurringMeetingRequestsNotSupported",
  /** Purchased time slot was not the first request chronologically */
  TimeSlotChronologyMismatch = "TimeSlotChronologyMismatch",
  /** Did not purchase a time slot that is the same as one of the meeting requests */
  TimeSlotMeetingRequestMismatch = "TimeSlotMeetingRequestMismatch",
}

export type AdminUserActionInput = {
  action: Scalars["String"];
  customValue?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  user_uid: Scalars["ID"];
};

export type AdminUserIncidentInput = {
  category?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
};

export enum CacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export enum EnrollmentPauseWindowReason {
  PaymentFailure = "payment_failure",
  UserAction = "user_action",
}

export type InterfacePreferencesInput = {
  activityFormatDescriptionFlexHidden?: InputMaybe<Scalars["Boolean"]>;
  activityFormatDescriptionMultiDayHidden?: InputMaybe<Scalars["Boolean"]>;
  activityFormatDescriptionOneTimeHidden?: InputMaybe<Scalars["Boolean"]>;
  activityFormatDescriptionOngoingHidden?: InputMaybe<Scalars["Boolean"]>;
  defaultScheduleFilters?: InputMaybe<UserScheduleFiltersInput>;
};

export type UserScheduleFiltersInput = {
  dow?: InputMaybe<Scalars["String"]>;
  endBy?: InputMaybe<Scalars["String"]>;
  endByTime?: InputMaybe<Scalars["Float"]>;
  startAfter?: InputMaybe<Scalars["String"]>;
  startAfterTime?: InputMaybe<Scalars["Float"]>;
  startBefore?: InputMaybe<Scalars["String"]>;
};

export type PhotoDimensions = {
  height?: InputMaybe<Scalars["Int"]>;
  width?: InputMaybe<Scalars["Int"]>;
};

export type SchoolClosureInputV2 = {
  affected: Scalars["Boolean"];
  application_status?: InputMaybe<FfapApplicationStatus>;
  hispanic_latino: Scalars["Boolean"];
  household_size: Scalars["Int"];
  household_size_3_to_18: Scalars["Int"];
  how_did_you_hear: Scalars["String"];
  income: Scalars["String"];
  interested_in_research: Scalars["Boolean"];
  interested_in_sharing: Scalars["Boolean"];
  learning_environments: Array<Scalars["String"]>;
  primary_language: Scalars["String"];
  race_ethnicity: Array<Scalars["String"]>;
  reliable_internet: Scalars["Boolean"];
  zip_code: Scalars["String"];
};

export enum SignupInitiator {
  User = "user",
}

export enum SuspendedReason {
  AccountTermination = "AccountTermination",
  EducatorRemovalRequest = "EducatorRemovalRequest",
  FamilyTermsofServiceViolation = "FamilyTermsofServiceViolation",
  InactiveAccount = "InactiveAccount",
  PolicyViolation = "PolicyViolation",
  SuspiciousActivity = "SuspiciousActivity",
  TemporarySuspension = "TemporarySuspension",
  TooManyFailedLoginAttempts = "TooManyFailedLoginAttempts",
  TrustAndSafetySuspension = "TrustAndSafetySuspension",
  UserRequestFullDeletion = "UserRequestFullDeletion",
}

export type TagInput = {
  key: Scalars["String"];
  value: Scalars["String"];
};
