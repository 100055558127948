import { dayjs, formatAbsoluteTime } from "@outschool/time";

import ldIsEqual from "lodash/isEqual";
import ldSlice from "lodash/slice";

export const DATE_FILTERS = ["startAfter", "endBy", "startBefore"];

export const START_END_DATE_FORMAT = "YYYY-MM-DD";
const START_MONTH_RANGE = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

// Matches dayjs 'ddd' display format: https://day.js.org/docs/en/display/format
export const WEEKDAYS = ["Mon", "Tue", "Wed", "Thu", "Fri"];
export const WEEKEND_DAYS = ["Sun", "Sat"];
// Our chosen display order
export const DAYS_OF_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export enum RelativeDateFilter {
  /** Classes starting on or after today
   *  Two strings to differentiate between new or old relative choices
   */
  ThisWeek = "ThisWeek",
  AllUpcoming = "AllUpcoming",
  /** Classes starting on or after next Monday */
  NextWeek = "NextWeek",
  /** Classes starting on or after the first day of the next month */
  NextMonth = "NextMonth",
  /** Classes starting today */
  Today = "Today",
  /** Classes starting in the next 7 days */
  SevenDays = "SevenDays",
  /** Classes starting in the next 14 days */
  FourteenDays = "FourteenDays",
  /** Classes starting today or June 1 and ending August 31 */
  Summer = "Summer",
  /** Classes starting today or September 1 and ending November 30 */
  Fall = "Fall",
  /** Classes starting in January */
  January = "January",
}

export const RELATIVE_DATE_FILTERS = [
  RelativeDateFilter.Today,
  RelativeDateFilter.SevenDays,
  RelativeDateFilter.FourteenDays,
  RelativeDateFilter.Summer,
  RelativeDateFilter.Fall,
  RelativeDateFilter.January,
  RelativeDateFilter.AllUpcoming,
];

/**
 * @param noOfDays
 * @returns {number} - number of months rounded up. ei 45 days = 2 months
 */
const convertDaysToMonth = (noOfDays: number): number => {
  const today = dayjs();
  const end = dayjs().add(noOfDays, "days");
  const yearsApart = end.diff(today, "year");
  today.add(yearsApart, "years");

  const monthsApart = end.diff(today, "months");
  today.add(monthsApart, "months");

  const daysApart = end.diff(today, "days");

  if (daysApart > 0) {
    return yearsApart * 12 + monthsApart + 1;
  } else {
    return yearsApart * 12 + monthsApart;
  }
};

export function getStartAfterDateOptions(
  daysNotice: number,
  daysNoticeMax: number,
  timeZone: string
): {
  startAfter: dayjs.Dayjs;
  label: string;
  value: string;
}[] {
  const earliestStartDate = dayjs()
    .tz(timeZone)
    .add(daysNotice, "days")
    .startOf("day");

  const options = START_MONTH_RANGE.map(monthIndex => {
    if (monthIndex === 0) {
      const startAfter = dayjs(earliestStartDate);
      // Add startAfter value as first option.
      return {
        startAfter: startAfter,
        label: startAfter.format("[From] MMMM D, YYYY"),
        value: startAfter.format(START_END_DATE_FORMAT),
      };
    }

    const monthStart = dayjs(earliestStartDate)
      .startOf("month")
      .add(monthIndex, "months");

    return {
      startAfter: monthStart,
      label: monthStart.format("[From] MMMM YYYY"),
      value: monthStart.format(START_END_DATE_FORMAT),
    };
  });

  return options.slice(0, convertDaysToMonth(daysNoticeMax));
}

export const dowIndex = (dow: string) => DAYS_OF_WEEK.indexOf(dow);
export const sortDow = (daysOfWeek: string[]) =>
  daysOfWeek.sort((a, b) => dowIndex(a) - dowIndex(b));

const dateFormatRegex = new RegExp(/\d{4}-\d{2}-\d{2}/);

export function dateFilterToDayjs(dateFilterValue?: string | null) {
  if (!dateFilterValue || !dateFormatRegex.test(dateFilterValue)) {
    return undefined;
  }
  const dateFilterDayjs = dayjs(dateFilterValue);
  if (dateFilterDayjs && dateFilterDayjs.isValid()) {
    return dateFilterDayjs;
  }
  return undefined;
}

export function displayTimeFromCardinalHour(
  cardinalHour: number,
  alwaysShowMinutes = false
) {
  const hour = Math.floor(cardinalHour);
  const minutes = Math.round((cardinalHour - hour) * 60);
  return formatAbsoluteTime(hour, minutes, alwaysShowMinutes);
}

export function convertTimeToInteger(time: string) {
  const [hour, minute] = time.split(":");
  return (Number.parseInt(hour) + Number.parseInt(minute) / 60).toFixed(2);
}

export function formatSearchFiltersDate(date?: dayjs.Dayjs | null) {
  return !!date ? dayjs(date).format(START_END_DATE_FORMAT) : null;
}

export function isDateAfterOtherDates(
  date: dayjs.Dayjs,
  otherDates: (undefined | dayjs.Dayjs)[]
) {
  return !otherDates
    .filter(otherDate => !!otherDate)
    .some(otherDate => date.isSameOrBefore(otherDate));
}

export function dowStringToArray(dowString: string) {
  return dowString
    .trim()
    .split(",")
    .map(d => d.trim())
    .filter(d => d !== "");
}

export function dowStringToIndexArray(dowString: string) {
  return dowStringToArray(dowString).map(dow => dowIndex(dow));
}

export function dowAreConsecutive(dowArray: string[]) {
  const start = DAYS_OF_WEEK.indexOf(dowArray[0]);
  return ldIsEqual(
    dowArray,
    ldSlice(DAYS_OF_WEEK, start, start + dowArray.length)
  );
}
