import pkg from "../lib/pkg";

import {
  Integration,
  IntegrationCategory,
} from "../providers/AnalyticsContext";
import { AnalyticsPlugin, AnalyticsPluginType } from ".";
import type { Context } from "../types";
import { getSessionId } from "@outschool/attribution";
import Cookies from "js-cookie";
import {
  formatLocationInfo,
  getLocalStorageItemWithTTL,
  injectScript,
  logDebugEvent,
  setLocalStorageItemWithTTL,
} from "../lib/util";
import { CookieKeys } from "@outschool/data-schemas";
import { IPContext } from "@outschool/iplookup-client";

declare global {
  interface Window {
    sessionReplay?: {
      init: (_: string, obj: any) => void;
      getSessionReplayProperties: () => any;
      setSessionId: (_: number) => void;
      shutdown: () => void;
    };
  }
}
/*
 * This plugin is for session Replay function of Amplitude
 * is aim to use when Amplitude is tracked
 */

export const AMPLITUDE_SESSION_REPLAY_START_PATHS = [
  "/teach/apply",
  "/classes/create",
  "/teach",
];
const BANNED_SUBDIVISIONS = ["ca", "pa", "il"];
export default class AmplitudeSessionReplayPlugin implements AnalyticsPlugin {
  name = Integration.AmplitudeSessionReplay;
  category = IntegrationCategory.Functional;
  type = AnalyticsPluginType.enrichment;
  version = "0.1.0";
  scriptId = "amplitudeSessionReplay";
  scriptSrc =
    "https://cdn.amplitude.com/libs/session-replay-browser-1.19.0-min.js.gz";
  sampleRate = 0.01;
  sessionFlag = "IS_SESSION_REPLAY_ACTIVE";
  sessionExpiry = 7 * 24 * 60 * 60 * 1000; // 7 days
  locationInfo: IPContext;

  #isSessionReplayInitialized = false;

  constructor({ locationInfo }: { locationInfo: IPContext }) {
    this.locationInfo = locationInfo;
  }
  isLoadable() {
    const formatIpInfo = formatLocationInfo(this.locationInfo?.ipInfo);
    const shouldDisable =
      this.locationInfo.isInCA ||
      (!!formatIpInfo.ip_subdivision &&
        BANNED_SUBDIVISIONS.includes(
          formatIpInfo.ip_subdivision.toLowerCase()
        ));

    return pkg.isProduction && !shouldDisable;
  }

  async load() {
    if (!pkg.amplitudeKey || typeof window === "undefined") {
      return;
    }

    await injectScript(this.scriptId, this.scriptSrc);

    if (!window?.sessionReplay) {
      return;
    }

    const isSessionActive =
      getLocalStorageItemWithTTL(this.sessionFlag) === "true";
    const pagePath = window?.location?.pathname;

    // loadable will remove the whole plugin, so it won't call page function when it change page,
    // so we need to conditionally init session replay here
    if (
      isSessionActive ||
      AMPLITUDE_SESSION_REPLAY_START_PATHS.includes(pagePath)
    ) {
      window?.sessionReplay.init(pkg.amplitudeKey, {
        // the amplitude's device id is mapped to segment's anonymousId
        deviceId: Cookies.get(CookieKeys.AnonymousId),
        sessionId: getSessionId() || Date.now(),
        sampleRate: this.sampleRate,
      });
      this.#isSessionReplayInitialized = true;
    }
  }
  async unload() {
    if (!this.isLoaded()) {
      return;
    }
    window.sessionReplay?.shutdown();
    const script = document.getElementById(this.scriptId);
    script?.remove();
    window.sessionReplay = undefined;
  }

  isLoaded() {
    if (typeof window === "undefined") {
      return false;
    }

    return !!window?.sessionReplay;
  }

  page(context: Context) {
    const pageProperties = context.event.properties;

    const pagePath = pageProperties?.path || window?.location?.pathname;

    if (!pagePath) {
      return context;
    }

    const isSessionActive =
      getLocalStorageItemWithTTL(this.sessionFlag) === "true";

    if (AMPLITUDE_SESSION_REPLAY_START_PATHS.includes(pagePath)) {
      if (!isSessionActive) {
        setLocalStorageItemWithTTL(
          this.sessionFlag,
          "true",
          this.sessionExpiry
        );
      }

      if (!this.#isSessionReplayInitialized) {
        window.sessionReplay?.init(pkg.amplitudeKey, {
          deviceId: Cookies.get(CookieKeys.AnonymousId),
          sessionId: getSessionId() || Date.now(),
          sampleRate: this.sampleRate,
        });
        this.#isSessionReplayInitialized = true;
      }
    } else {
      if (!isSessionActive && this.#isSessionReplayInitialized) {
        window.sessionReplay?.shutdown();
        this.#isSessionReplayInitialized = false;
      }
    }

    return context;
  }
  track(context: Context): Context {
    const pageProperties = context.event.properties;
    const pagePath = pageProperties?.path || window?.location?.pathname;

    const isSessionActive =
      getLocalStorageItemWithTTL(this.sessionFlag) === "true";
    if (
      !!window?.sessionReplay &&
      (isSessionActive ||
        AMPLITUDE_SESSION_REPLAY_START_PATHS.includes(pagePath))
    ) {
      const { properties } = context.event;
      const sessionReplayProperties =
        window.sessionReplay.getSessionReplayProperties();
      context.event = {
        ...context.event,
        properties: { ...properties, ...sessionReplayProperties },
      };

      logDebugEvent(context.event);
    }
    return context;
  }
}
